import {
  IonIcon,
  IonLabel,
  IonRouterLink,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";
import { useSelector, useDispatch } from "react-redux";
import searchIcon from "../../../../assets/svg/searchnormal.svg";
import filtersIcon from "../../../../assets/svg/filters.svg";
import drawIcon from "../../../../assets/svg/draw.svg";
import schoolsIcon from "../../../../assets/svg/schoolMap.svg";
import locationIcon from "../../../../assets/svg/nearby.svg";
import satelliteIcon from "../../../../assets/svg/satellite-view.svg";
import mapIcon from "../../../../assets/svg/map-view.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/svg/close.svg";
import { ReactComponent as SearchIcon } from "../../../../assets/svg/search.svg";
import styles from "./app-map-controls.module.scss";
import withFilters from "../../../../HOC/withFilters/with-filters";
import moment from "moment";
import Button from "../../../Form/Button/button.component";
import { homeOutline } from "ionicons/icons";
import Fade from "react-reveal/Fade";
import withClient from "../../../../HOC/withClient/with-client";
import {
  selectIsSchoolsActive,
  selectSchools,
} from "../../../../redux/schools/schools.selectors";
import { toggleFilterListings } from "../../../../redux/schools/schools.actions";
import { useHistory } from "react-router-dom";
import { fastFadeAnimation } from "../../../../utils/animations";
import { updateSelectedListing } from "../../../../redux/map/map.actions";

const AppMapControls = ({
  view,
  setView,
  mapType,
  mapTypeHandler,
  loading,
  totalListings,
  currentLocationHandler,
  drawMode,
  drawHandler,
  activeListing,
  clearOutline,
  activeFilters,
  filters,
  updateMultipleFilters,
  status,
  currentClient,
  isCentered,
  favouritesOnly,
  handleModals,
  disableDrawing,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isSchoolsActive = useSelector(selectIsSchoolsActive);
  const { filterListings } = useSelector(selectSchools);

  const handleStatus = ({ detail: { value } }) => {
    if (value === "active") {
      dispatch(updateSelectedListing(null));
      updateMultipleFilters({
        type: filters.type.toLowerCase() === "sale" ? "sale" : "lease",
        status: "A",
        lastStatus: null,
        displayPublic: "Y",
        displayAddressOnInternet: "Y",
        minListDate: null,
        minSoldDate: null,
        maxSoldDate: null,
      });
    } else {
      if (!currentClient) {
        history.push("/join", { direction: "none" });
      } else {
        dispatch(updateSelectedListing(null));
        updateMultipleFilters({
          type: filters.type.toLowerCase() === "sale" ? "sale" : "lease",
          status: "U",
          lastStatus: filters.type.toLowerCase() === "sale" ? "Sld" : "Lsd",
          displayPublic: null,
          displayAddressOnInternet: null,
          minListDate: null,
          maxSoldDate: null,
          minSoldDate: moment().subtract(30, "days").format("YYYY-MM-DD"),
        });
      }
    }
  };

  return (
    <>
      {drawMode.active && drawMode.ref && (
        <Fade duration={500}>
          <span className={styles.drawBtns}>
            <Button
              type="darkPurpleApp"
              title="view all listings"
              onClick={setView}
              style={{
                width: "100%",
                height: "3rem",
                fontSize: "1.2rem",
                fontWeight: 300,
              }}
              mapIcon
              loading={loading}
              disabled={loading || !totalListings}
            >
              <IonIcon icon={homeOutline} />
            </Button>
            <span className={styles.row}>
              <Button
                type="darkPurpleApp"
                title="save search"
                onClick={() => handleModals("savedSearch")}
                disabled={status === "U"}
                style={{
                  width: "100%",
                  height: "3rem",
                  fontSize: "1.2rem",
                  marginRight: "2rem",
                  fontWeight: 300,
                }}
                smallIcon
              >
                <SearchIcon />
              </Button>
              <Button
                type="darkPurpleApp"
                title="clear outline"
                onClick={() => {
                  if (view === "list") setView();
                  clearOutline();
                }}
                style={{
                  width: "100%",
                  height: "3rem",
                  fontSize: "1.2rem",
                  fontWeight: 300,
                }}
              >
                <CloseIcon />
              </Button>
            </span>
          </span>
        </Fade>
      )}
      {filterListings.active && filterListings.schools.length > 0 && (
        <Fade duration={500}>
          <span className={styles.drawBtns}>
            <Button
              type="darkGreenApp"
              title="view boundary listings"
              onClick={setView}
              style={{
                width: "100%",
                height: "3rem",
                fontSize: "1.2rem",
                fontWeight: 300,
              }}
              mapIcon
              loading={loading}
              disabled={loading || !totalListings}
            >
              <IonIcon icon={homeOutline} />
            </Button>
            <span className={styles.row}>
              <Button
                type="darkGreenApp"
                title="save search"
                onClick={() => handleModals("savedSearch")}
                disabled={status === "U"}
                style={{
                  width: "100%",
                  height: "3rem",
                  fontSize: "1.2rem",
                  marginRight: "2rem",
                  fontWeight: 300,
                }}
                smallIcon
              >
                <SearchIcon />
              </Button>
              <Button
                type="darkGreenApp"
                title="clear outline"
                onClick={() => {
                  if (view === "list") setView();
                  dispatch(toggleFilterListings());
                }}
                style={{
                  width: "100%",
                  height: "3rem",
                  fontSize: "1.2rem",
                  fontWeight: 300,
                }}
              >
                <CloseIcon />
              </Button>
            </span>
          </span>
        </Fade>
      )}
      <div className={styles.top}>
        <div className={styles.searchFilters}>
          <span
            className={styles.search}
            onClick={() => handleModals("search")}
          >
            <IonIcon icon={searchIcon} />
            Search
          </span>
          <IonRouterLink
            routerLink="/filters"
            routerAnimation={fastFadeAnimation}
          >
            <span
              className={`${styles.filters} ${
                activeFilters && activeFilters.length > 0 && styles.badge
              }`}
            >
              <IonIcon icon={filtersIcon} />
            </span>
          </IonRouterLink>
        </div>
        <div className={styles.status}>
          <IonSegment
            mode="ios"
            className={styles.segment}
            onIonChange={handleStatus}
            value={filters.status.toLowerCase() === "a" ? "active" : "inactive"}
          >
            <IonSegmentButton
              mode="ios"
              value="active"
              className={`${styles.btn} ${styles.leftSegment}`}
              // style={{ "--cl": isAndroid() ? "#33244C" : "white" }}
            >
              <IonLabel>Active</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton
              mode="ios"
              value="inactive"
              className={`${styles.btn} ${styles.rightSegment}`}
              // style={{ "--cl": isAndroid() ? "#33244C" : "white" }}
            >
              <IonLabel>{filters.type === "sale" ? "Sold" : "Leased"}</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </div>
      </div>
      {!favouritesOnly && (
        <Fade duration={500}>
          <div className={styles.right}>
            <span
              className={`${styles.btn} ${isSchoolsActive && styles.activeBtn}`}
              onClick={() => handleModals("schools")}
            >
              <IonIcon icon={schoolsIcon} />
            </span>
            <span
              className={`${styles.btn} ${
                drawMode.active && styles.activeBtn
              } ${disableDrawing && styles.disabled}`}
              onClick={() => !disableDrawing && drawHandler()}
            >
              <IonIcon icon={drawIcon} />
            </span>
            <span className={styles.btn} onClick={mapTypeHandler}>
              <IonIcon
                icon={mapType === "satellite" ? mapIcon : satelliteIcon}
              />
            </span>
            <span
              className={`${styles.btn} ${isCentered && styles.activeBtn}`}
              onClick={currentLocationHandler}
            >
              <IonIcon icon={locationIcon} />
            </span>
          </div>
        </Fade>
      )}
    </>
  );
};

export default withClient(withFilters(AppMapControls));
