import { useState } from "react";
import styles from "./tree-item.module.scss";
import { ReactComponent as ToggleIcon } from "../../../assets/svg/chevronDown.svg";
import Radio from "../../Form/Radio/radio.component";

const TreeItem = ({
  item,
  selected: { area, city, neighborhood },
  onAreaClick,
  onCityClick,
  onNeighbourhoodClick,
  locationIndex,
}) => {
  const [currentBranch, setCurrentBranch] = useState("");
  const [toggleArea, setToggleArea] = useState(true);

  const onBranchHandler = (branch) => {
    branch === currentBranch ? setCurrentBranch("") : setCurrentBranch(branch);
  };

  const countArea = () => {
    if (!city && area && area[0] === item.name && !neighborhood) {
      return "(all)";
    }
    //if (city && area[0] === item.name) return `(${city.length})`;

    return null;
  };

  const count = (name, index) => {
    if (city && city.includes(name)) {
      return "(all)";
    }
    if (neighborhood && neighborhood.length) {
      const { neighborhoods } = item.value[0].cities[index];

      const total = neighborhoods.filter((n) =>
        neighborhood.includes(n.name)
      ).length;

      return total !== 0 ? `(${total})` : null;
    }
  };

  return (
    <div className={styles.item}>
      <div className={styles.radioContainer}>
        <Radio
          selected={area && area.includes(item.name)}
          onClick={() => {
            if (!toggleArea) setToggleArea(true);
            onAreaClick(item);
          }}
        />
        <label
          className={styles.option}
          onClick={(e) => {
            e.preventDefault();
            if (area && area.includes(item.name)) {
              setToggleArea(!toggleArea);
            } else {
              onAreaClick(item);
            }
          }}
        >
          <span className={styles.label}>{item.name}</span>
          <span className={styles.count}>{countArea()}</span>
        </label>
        <ToggleIcon
          className={`${styles.icon} ${
            area && area.includes(item.name) && toggleArea
              ? styles.iconReversed
              : ""
          }`}
          onClick={(e) => {
            e.preventDefault();
            if (area && area.includes(item.name)) {
              setToggleArea(!toggleArea);
            } else {
              onAreaClick(item);
            }
          }}
        />
      </div>
      {area && area.includes(item.name) && toggleArea && (
        <div className={styles.branches}>
          {item.value[0].cities.map((c, index) => (
            <div className={styles.branch} key={c.name}>
              <label className={styles.checkbox}>
                <input
                  onChange={() =>
                    onCityClick({
                      city: {
                        name: c.name,
                        areaIndex: locationIndex,
                        cityIndex: index,
                        location: c.location,
                      },
                    })
                  }
                  type="checkbox"
                  checked={city ? city.includes(c.name) : false}
                />
                <span className={styles.item}>
                  {c.name}
                  <span className={styles.count}>{count(c.name, index)}</span>
                </span>
                {c && c.neighborhoods.length && (
                  <ToggleIcon
                    className={`${styles.icon} ${
                      currentBranch === c.name ? styles.iconReversed : ""
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      onBranchHandler(c.name);
                    }}
                  />
                )}
              </label>
              {c.neighborhoods.length && currentBranch === c.name && (
                <div className={styles.subBranches}>
                  {c.neighborhoods.map((n) => (
                    <label className={styles.checkbox} key={n.name}>
                      <input
                        type="checkbox"
                        checked={
                          neighborhood ? neighborhood.includes(n.name) : false
                        }
                        onChange={() =>
                          onNeighbourhoodClick({
                            name: n.name,
                            city: c.name,
                            location: n.location,
                            coordinates: n.coordinates,
                          })
                        }
                      />
                      <span className={styles.item}>{n.name}</span>
                    </label>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TreeItem;
