import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { chevronForwardOutline } from "ionicons/icons";
import { IonIcon, IonLoading, IonSkeletonText } from "@ionic/react";
import styles from "./tour-details.module.scss";
import ShowingDetails from "./component/ShowingDetails/showing-details.component";
import Overview from "./component/Overview/overview.component";
import Photos from "../../components/Photos/photos.component";
import Notes from "../../components/Notes/notes.component";
import Recordings from "../../components/Recordings/recordings.component";
import LoadingFullPage from "../../components/Loading/loading-full-page.component";
import {
  removePhoto,
  getSummaryForTour,
  getSingleTour,
  getSingleTourItem,
  updateTourListingItem,
  removeTourListingItem,
  removeRecording,
} from "../../amplify/graphql.utils";
import { getListing } from "../../api/repliers";
import { buildAddress, hapticsImpact } from "../../utils/functions";
import { updateToast } from "../../redux/ui/ui.actions";
import withAuthentication from "../../HOC/withAuthentication/with-authentication";
import { isAgent } from "../../amplify/auth.utils";

const TourDetails = ({
  match: {
    params: { tourItemId },
  },
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [tour, setTour] = useState();
  const [tourSummary, setTourSummary] = useState();
  const [tourItem, setTourItem] = useState();
  const [listing, setListing] = useState();
  const [reviews, setReviews] = useState();
  const [showPhotos, setShowPhotos] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [showRecordings, setShowRecordings] = useState(false);
  const [playing, setPlaying] = useState();

  const refresh = async () => {
    const item = await getSingleTourItem(tourItemId);
    setTourItem({ ...item });
  };
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const agent = await isAgent();
        if (!agent) {
          history.replace("/tours");
          return;
        }
        const item = await getSingleTourItem(tourItemId);
        setTourItem(item);
        const membersData = JSON.parse(item.membersData).filter(
          (m) => m.role === "Client" || m.role === "Additional Guest"
        );
        const allReviews = [];
        membersData.forEach((m) => {
          const review = item.reviews.items.find((r) => r.owner === m.id);
          allReviews.push({
            name: m.givenName,
            rate: review ? review.rate : undefined,
          });
        });
        setReviews([...allReviews]);
        const tourSummary = await getSummaryForTour(item.tourId);
        setTourSummary(tourSummary);
        const tour = await getSingleTour(tourSummary.id);
        setTour(tour);
        const listing = await getListing(item.mlsNumber);
        setListing(listing);
      } catch (err) {
        history.replace("/tours");
      }
      setLoading(false);
    };
    fetchData();
  }, [history, tourItemId]);

  const updateDetails = async (updates) => {
    setLoading(true);
    try {
      const item = await updateTourListingItem(updates);
      setTourItem(item);
      dispatch(
        updateToast({
          open: true,
          type: "success",
          message: "Showing successfully updated.",
        })
      );
    } catch (err) {
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message: "Something went wrong!",
        })
      );
    }
    setLoading(false);
  };

  const removeShowing = async () => {
    setLoading(true);
    try {
      await removeTourListingItem(tourItem.id);
      dispatch(
        updateToast({
          open: true,
          type: "success",
          message: "Showing successfully removed.",
        })
      );
      history.replace(`/tours/${tourSummary.id}?reload`);
    } catch (err) {
      console.log(err);
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message: "Something went wrong!",
        })
      );
    }
    setLoading(false);
  };

  const handleDelete = async ({ id, path, type }) => {
    try {
      await hapticsImpact();
      setUploading(true);
      if (type === "photo") await removePhoto({ id, path });
      if (type === "recording") await removeRecording({ id, path });
      refresh(tourItem.id);
      setUploading(false);
    } catch (err) {
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message: "Something went wrong!",
        })
      );
    }
  };

  return (
    <div className={styles.tourDetails}>
      <IonLoading isOpen={loading} />
      {!tour || !tourSummary || !tourItem || !listing ? (
        <div className={styles.loading}>
          <LoadingFullPage />
        </div>
      ) : (
        <>
          <div className={styles.nav}>
            <span onClick={() => history.push("/tours")}>All tours</span>
            <span className={styles.icon}>
              <IonIcon icon={chevronForwardOutline} />
            </span>
            <span onClick={() => history.push(`/tours/${tourSummary.id}`)}>
              {tourSummary ? (
                tourSummary.title
              ) : (
                <IonSkeletonText style={{ width: "8rem" }} animated={true} />
              )}
            </span>
            <span className={styles.icon}>
              <IonIcon icon={chevronForwardOutline} />
            </span>
            <span className={styles.bold}>
              {listing ? (
                buildAddress(listing.address)
              ) : (
                <IonSkeletonText style={{ width: "12rem" }} animated={true} />
              )}
            </span>
          </div>
          <div className={styles.details}>
            <div>
              <ShowingDetails
                //tour={tourItem}
                listing={listing}
                details={{ tourItem, listing }}
                update={(updates) => updateDetails(updates)}
                tour={tour}
              />
              <Overview
                listing={listing}
                reviews={reviews}
                remove={removeShowing}
                tour={tour}
              />
            </div>
            <div key={tourItem?.id}>
              <Photos
                photoItems={tourItem.photos.items}
                tourItem={tourItem}
                listing={listing}
                isOpen={showPhotos}
                setIsOpen={setShowPhotos}
                handleDelete={({ id, path }) =>
                  handleDelete({ id, path, type: "photo" })
                }
                tour={tour}
              />
              <Notes
                tourItem={tourItem}
                noteItems={tourItem.notes.items}
                listing={listing}
                isOpen={showNotes}
                setIsOpen={setShowNotes}
                tour={tour}
              />
              <Recordings
                recordingItems={tourItem.recordings.items}
                isOpen={showRecordings}
                setIsOpen={setShowRecordings}
                tourItem={tourItem}
                listing={listing}
                handleDelete={({ id, path }) =>
                  handleDelete({ id, path, type: "recording" })
                }
                playing={playing}
                setPlaying={setPlaying}
                tour={tour}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default withAuthentication(TourDetails);
