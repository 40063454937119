/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getBrokerage = /* GraphQL */ `
  query GetBrokerage($id: ID!) {
    getBrokerage(id: $id) {
      id
      name
      createdAt
      updatedAt
      clients {
        items {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          profilePicture
          agentId
          brokerageId
          subscribed
          currency
          measurement
          notifications
          deviceToken
          profileId
          requestedTour
          toursNote
          deactivated
          createdAt
          updatedAt
        }
        nextToken
      }
      owner
      agents {
        items {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          brokerageId
          currency
          measurement
          notifications
          deviceToken
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listBrokerages = /* GraphQL */ `
  query ListBrokerages(
    $filter: ModelBrokerageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBrokerages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
        clients {
          nextToken
        }
        owner
        agents {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getTourItem = /* GraphQL */ `
  query GetTourItem($id: ID!) {
    getTourItem(id: $id) {
      id
      creator
      agentId
      showingAgentId
      members
      membersData
      tourId
      mlsNumber
      status
      startTime
      endTime
      order
      showingNote
      entryInfo
      reviews {
        items {
          id
          tourItemId
          rate
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      notes {
        items {
          id
          content
          tourItemId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      photos {
        items {
          id
          path
          createdAt
          tourItemId
          updatedAt
          owner
        }
        nextToken
      }
      recordings {
        items {
          id
          path
          createdAt
          tourItemId
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTourItems = /* GraphQL */ `
  query ListTourItems(
    $filter: ModelTourItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTourItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        creator
        agentId
        showingAgentId
        members
        membersData
        tourId
        mlsNumber
        status
        startTime
        endTime
        order
        showingNote
        entryInfo
        reviews {
          nextToken
        }
        notes {
          nextToken
        }
        photos {
          nextToken
        }
        recordings {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReview = /* GraphQL */ `
  query GetReview($id: ID!) {
    getReview(id: $id) {
      id
      tourItemId
      rate
      createdAt
      updatedAt
      owner
    }
  }
`;
export const getTourItemsByOrder = /* GraphQL */ `
  query GetTourItemsByOrder(
    $order: Int
    $sortDirection: ModelSortDirection
    $filter: ModelTourItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTourItemsByOrder(
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        creator
        agentId
        showingAgentId
        members
        membersData
        tourId
        mlsNumber
        status
        startTime
        endTime
        order
        showingNote
        entryInfo
        reviews {
          nextToken
        }
        notes {
          nextToken
        }
        photos {
          nextToken
        }
        recordings {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFeatured = /* GraphQL */ `
  query GetFeatured($id: ID!) {
    getFeatured(id: $id) {
      id
      title
      subtitle
      filters
      isNearby
      isFavourite
      isSchool
      order
      status
      createdAt
      updatedAt
    }
  }
`;
export const listFeatureds = /* GraphQL */ `
  query ListFeatureds(
    $filter: ModelFeaturedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeatureds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        subtitle
        filters
        isNearby
        isFavourite
        isSchool
        order
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getHomeCard = /* GraphQL */ `
  query GetHomeCard($id: ID!) {
    getHomeCard(id: $id) {
      id
      title
      text
      image
      actionLabel
      actionLink
      inAppAction
      order
      status
      createdAt
      updatedAt
    }
  }
`;
export const listHomeCards = /* GraphQL */ `
  query ListHomeCards(
    $filter: ModelHomeCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHomeCards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        text
        image
        actionLabel
        actionLink
        inAppAction
        order
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProfile = /* GraphQL */ `
  query GetProfile($id: ID!) {
    getProfile(id: $id) {
      id
      clientId
      buyingSelling
      leasingRenting
      justLooking
      preConstruction
      isRealtor
      createdAt
      updatedAt
      owner
      client {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        profilePicture
        agentId
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        subscribed
        currency
        measurement
        notifications
        deviceToken
        profileId
        requestedTour
        tours {
          nextToken
        }
        toursNote
        deactivated
        createdAt
        updatedAt
        profile {
          id
          clientId
          buyingSelling
          leasingRenting
          justLooking
          preConstruction
          isRealtor
          createdAt
          updatedAt
          owner
        }
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        agent {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          brokerageId
          currency
          measurement
          notifications
          deviceToken
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listProfiles = /* GraphQL */ `
  query ListProfiles(
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clientId
        buyingSelling
        leasingRenting
        justLooking
        preConstruction
        isRealtor
        createdAt
        updatedAt
        owner
        client {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          profilePicture
          agentId
          brokerageId
          subscribed
          currency
          measurement
          notifications
          deviceToken
          profileId
          requestedTour
          toursNote
          deactivated
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getProfileByClient = /* GraphQL */ `
  query GetProfileByClient(
    $clientId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getProfileByClient(
      clientId: $clientId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        buyingSelling
        leasingRenting
        justLooking
        preConstruction
        isRealtor
        createdAt
        updatedAt
        owner
        client {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          profilePicture
          agentId
          brokerageId
          subscribed
          currency
          measurement
          notifications
          deviceToken
          profileId
          requestedTour
          toursNote
          deactivated
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getFavourite = /* GraphQL */ `
  query GetFavourite($id: ID!) {
    getFavourite(id: $id) {
      id
      repliersID
      mlsNumber
      listing
      listingUpdate
      clientId
      notification
      createdAt
      updatedAt
      owner
      client {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        profilePicture
        agentId
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        subscribed
        currency
        measurement
        notifications
        deviceToken
        profileId
        requestedTour
        tours {
          nextToken
        }
        toursNote
        deactivated
        createdAt
        updatedAt
        profile {
          id
          clientId
          buyingSelling
          leasingRenting
          justLooking
          preConstruction
          isRealtor
          createdAt
          updatedAt
          owner
        }
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        agent {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          brokerageId
          currency
          measurement
          notifications
          deviceToken
          createdAt
          updatedAt
        }
      }
      agent {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        website
        profilePicture
        licenseNumber
        areas
        availability
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        currency
        measurement
        notifications
        deviceToken
        createdAt
        updatedAt
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        clients {
          nextToken
        }
        tours {
          nextToken
        }
      }
    }
  }
`;
export const listFavourites = /* GraphQL */ `
  query ListFavourites(
    $filter: ModelFavouriteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFavourites(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        repliersID
        mlsNumber
        listing
        listingUpdate
        clientId
        notification
        createdAt
        updatedAt
        owner
        client {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          profilePicture
          agentId
          brokerageId
          subscribed
          currency
          measurement
          notifications
          deviceToken
          profileId
          requestedTour
          toursNote
          deactivated
          createdAt
          updatedAt
        }
        agent {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          brokerageId
          currency
          measurement
          notifications
          deviceToken
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getFavouriteByMlsNumber = /* GraphQL */ `
  query GetFavouriteByMlsNumber(
    $mlsNumber: String
    $sortDirection: ModelSortDirection
    $filter: ModelFavouriteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFavouriteByMlsNumber(
      mlsNumber: $mlsNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        repliersID
        mlsNumber
        listing
        listingUpdate
        clientId
        notification
        createdAt
        updatedAt
        owner
        client {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          profilePicture
          agentId
          brokerageId
          subscribed
          currency
          measurement
          notifications
          deviceToken
          profileId
          requestedTour
          toursNote
          deactivated
          createdAt
          updatedAt
        }
        agent {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          brokerageId
          currency
          measurement
          notifications
          deviceToken
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSearch = /* GraphQL */ `
  query GetSearch($id: ID!) {
    getSearch(id: $id) {
      id
      name
      repliersID
      value
      clientId
      notification
      createdAt
      updatedAt
      owner
      client {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        profilePicture
        agentId
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        subscribed
        currency
        measurement
        notifications
        deviceToken
        profileId
        requestedTour
        tours {
          nextToken
        }
        toursNote
        deactivated
        createdAt
        updatedAt
        profile {
          id
          clientId
          buyingSelling
          leasingRenting
          justLooking
          preConstruction
          isRealtor
          createdAt
          updatedAt
          owner
        }
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        agent {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          brokerageId
          currency
          measurement
          notifications
          deviceToken
          createdAt
          updatedAt
        }
      }
      agent {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        website
        profilePicture
        licenseNumber
        areas
        availability
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        currency
        measurement
        notifications
        deviceToken
        createdAt
        updatedAt
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        clients {
          nextToken
        }
        tours {
          nextToken
        }
      }
    }
  }
`;
export const listSearchs = /* GraphQL */ `
  query ListSearchs(
    $filter: ModelSearchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSearchs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        repliersID
        value
        clientId
        notification
        createdAt
        updatedAt
        owner
        client {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          profilePicture
          agentId
          brokerageId
          subscribed
          currency
          measurement
          notifications
          deviceToken
          profileId
          requestedTour
          toursNote
          deactivated
          createdAt
          updatedAt
        }
        agent {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          brokerageId
          currency
          measurement
          notifications
          deviceToken
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSearchByRepliersId = /* GraphQL */ `
  query GetSearchByRepliersId(
    $repliersID: String
    $sortDirection: ModelSortDirection
    $filter: ModelSearchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSearchByRepliersId(
      repliersID: $repliersID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        repliersID
        value
        clientId
        notification
        createdAt
        updatedAt
        owner
        client {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          profilePicture
          agentId
          brokerageId
          subscribed
          currency
          measurement
          notifications
          deviceToken
          profileId
          requestedTour
          toursNote
          deactivated
          createdAt
          updatedAt
        }
        agent {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          brokerageId
          currency
          measurement
          notifications
          deviceToken
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getClient = /* GraphQL */ `
  query GetClient($id: ID!) {
    getClient(id: $id) {
      id
      repliersID
      givenName
      familyName
      name
      email
      phone
      profilePicture
      agentId
      brokerage {
        id
        name
        createdAt
        updatedAt
        clients {
          nextToken
        }
        owner
        agents {
          nextToken
        }
      }
      brokerageId
      subscribed
      currency
      measurement
      notifications
      deviceToken
      profileId
      requestedTour
      tours {
        items {
          id
          creator
          agentId
          clientId
          tourId
          createdAt
          updatedAt
        }
        nextToken
      }
      toursNote
      deactivated
      createdAt
      updatedAt
      profile {
        id
        clientId
        buyingSelling
        leasingRenting
        justLooking
        preConstruction
        isRealtor
        createdAt
        updatedAt
        owner
        client {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          profilePicture
          agentId
          brokerageId
          subscribed
          currency
          measurement
          notifications
          deviceToken
          profileId
          requestedTour
          toursNote
          deactivated
          createdAt
          updatedAt
        }
      }
      favourites {
        items {
          id
          repliersID
          mlsNumber
          listing
          listingUpdate
          clientId
          notification
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      searches {
        items {
          id
          name
          repliersID
          value
          clientId
          notification
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      agent {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        website
        profilePicture
        licenseNumber
        areas
        availability
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        currency
        measurement
        notifications
        deviceToken
        createdAt
        updatedAt
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        clients {
          nextToken
        }
        tours {
          nextToken
        }
      }
    }
  }
`;
export const listClients = /* GraphQL */ `
  query ListClients(
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        profilePicture
        agentId
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        subscribed
        currency
        measurement
        notifications
        deviceToken
        profileId
        requestedTour
        tours {
          nextToken
        }
        toursNote
        deactivated
        createdAt
        updatedAt
        profile {
          id
          clientId
          buyingSelling
          leasingRenting
          justLooking
          preConstruction
          isRealtor
          createdAt
          updatedAt
          owner
        }
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        agent {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          brokerageId
          currency
          measurement
          notifications
          deviceToken
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getClientByEmail = /* GraphQL */ `
  query GetClientByEmail(
    $email: AWSEmail
    $sortDirection: ModelSortDirection
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getClientByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        profilePicture
        agentId
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        subscribed
        currency
        measurement
        notifications
        deviceToken
        profileId
        requestedTour
        tours {
          nextToken
        }
        toursNote
        deactivated
        createdAt
        updatedAt
        profile {
          id
          clientId
          buyingSelling
          leasingRenting
          justLooking
          preConstruction
          isRealtor
          createdAt
          updatedAt
          owner
        }
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        agent {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          brokerageId
          currency
          measurement
          notifications
          deviceToken
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getClientByName = /* GraphQL */ `
  query GetClientByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getClientByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        profilePicture
        agentId
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        subscribed
        currency
        measurement
        notifications
        deviceToken
        profileId
        requestedTour
        tours {
          nextToken
        }
        toursNote
        deactivated
        createdAt
        updatedAt
        profile {
          id
          clientId
          buyingSelling
          leasingRenting
          justLooking
          preConstruction
          isRealtor
          createdAt
          updatedAt
          owner
        }
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        agent {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          brokerageId
          currency
          measurement
          notifications
          deviceToken
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getClientByBrokerage = /* GraphQL */ `
  query GetClientByBrokerage(
    $brokerageId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getClientByBrokerage(
      brokerageId: $brokerageId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        profilePicture
        agentId
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        subscribed
        currency
        measurement
        notifications
        deviceToken
        profileId
        requestedTour
        tours {
          nextToken
        }
        toursNote
        deactivated
        createdAt
        updatedAt
        profile {
          id
          clientId
          buyingSelling
          leasingRenting
          justLooking
          preConstruction
          isRealtor
          createdAt
          updatedAt
          owner
        }
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        agent {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          brokerageId
          currency
          measurement
          notifications
          deviceToken
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getAgent = /* GraphQL */ `
  query GetAgent($id: ID!) {
    getAgent(id: $id) {
      id
      repliersID
      givenName
      familyName
      name
      email
      phone
      website
      profilePicture
      licenseNumber
      areas
      availability
      brokerage {
        id
        name
        createdAt
        updatedAt
        clients {
          nextToken
        }
        owner
        agents {
          nextToken
        }
      }
      brokerageId
      currency
      measurement
      notifications
      deviceToken
      createdAt
      updatedAt
      favourites {
        items {
          id
          repliersID
          mlsNumber
          listing
          listingUpdate
          clientId
          notification
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      searches {
        items {
          id
          name
          repliersID
          value
          clientId
          notification
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      clients {
        items {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          profilePicture
          agentId
          brokerageId
          subscribed
          currency
          measurement
          notifications
          deviceToken
          profileId
          requestedTour
          toursNote
          deactivated
          createdAt
          updatedAt
        }
        nextToken
      }
      tours {
        items {
          id
          title
          creator
          agentId
          showingAgentId
          members
          membersData
          status
          privateNote
          generalNote
          meetupLocation
          meetupDescription
          meetupTime
          startTime
          endTime
          date
          shared
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listAgents = /* GraphQL */ `
  query ListAgents(
    $filter: ModelAgentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAgents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        website
        profilePicture
        licenseNumber
        areas
        availability
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        currency
        measurement
        notifications
        deviceToken
        createdAt
        updatedAt
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        clients {
          nextToken
        }
        tours {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getAgentByEmail = /* GraphQL */ `
  query GetAgentByEmail(
    $email: AWSEmail
    $sortDirection: ModelSortDirection
    $filter: ModelAgentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAgentByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        website
        profilePicture
        licenseNumber
        areas
        availability
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        currency
        measurement
        notifications
        deviceToken
        createdAt
        updatedAt
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        clients {
          nextToken
        }
        tours {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getAgentByName = /* GraphQL */ `
  query GetAgentByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelAgentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAgentByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        website
        profilePicture
        licenseNumber
        areas
        availability
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        currency
        measurement
        notifications
        deviceToken
        createdAt
        updatedAt
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        clients {
          nextToken
        }
        tours {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getAgentsByBrokerage = /* GraphQL */ `
  query GetAgentsByBrokerage(
    $brokerageId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelAgentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAgentsByBrokerage(
      brokerageId: $brokerageId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        website
        profilePicture
        licenseNumber
        areas
        availability
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        currency
        measurement
        notifications
        deviceToken
        createdAt
        updatedAt
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        clients {
          nextToken
        }
        tours {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getTour = /* GraphQL */ `
  query GetTour($id: ID!) {
    getTour(id: $id) {
      id
      title
      creator
      agentId
      showingAgentId
      members
      membersData
      clients {
        items {
          id
          creator
          agentId
          clientId
          tourId
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      privateNote
      generalNote
      tourItems {
        items {
          id
          creator
          agentId
          showingAgentId
          members
          membersData
          tourId
          mlsNumber
          status
          startTime
          endTime
          order
          showingNote
          entryInfo
          createdAt
          updatedAt
        }
        nextToken
      }
      meetupLocation
      meetupDescription
      meetupTime
      startTime
      endTime
      date
      shared
      createdAt
      updatedAt
      agent {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        website
        profilePicture
        licenseNumber
        areas
        availability
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        currency
        measurement
        notifications
        deviceToken
        createdAt
        updatedAt
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        clients {
          nextToken
        }
        tours {
          nextToken
        }
      }
      showingAgent {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        website
        profilePicture
        licenseNumber
        areas
        availability
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        currency
        measurement
        notifications
        deviceToken
        createdAt
        updatedAt
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        clients {
          nextToken
        }
        tours {
          nextToken
        }
      }
    }
  }
`;
export const listTours = /* GraphQL */ `
  query ListTours(
    $filter: ModelTourFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTours(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        creator
        agentId
        showingAgentId
        members
        membersData
        clients {
          nextToken
        }
        status
        privateNote
        generalNote
        tourItems {
          nextToken
        }
        meetupLocation
        meetupDescription
        meetupTime
        startTime
        endTime
        date
        shared
        createdAt
        updatedAt
        agent {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          brokerageId
          currency
          measurement
          notifications
          deviceToken
          createdAt
          updatedAt
        }
        showingAgent {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          brokerageId
          currency
          measurement
          notifications
          deviceToken
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getToursByAgent = /* GraphQL */ `
  query GetToursByAgent(
    $agentId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTourFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getToursByAgent(
      agentId: $agentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        creator
        agentId
        showingAgentId
        members
        membersData
        clients {
          nextToken
        }
        status
        privateNote
        generalNote
        tourItems {
          nextToken
        }
        meetupLocation
        meetupDescription
        meetupTime
        startTime
        endTime
        date
        shared
        createdAt
        updatedAt
        agent {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          brokerageId
          currency
          measurement
          notifications
          deviceToken
          createdAt
          updatedAt
        }
        showingAgent {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          brokerageId
          currency
          measurement
          notifications
          deviceToken
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getToursByStatus = /* GraphQL */ `
  query GetToursByStatus(
    $status: TourStatus
    $sortDirection: ModelSortDirection
    $filter: ModelTourFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getToursByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        creator
        agentId
        showingAgentId
        members
        membersData
        clients {
          nextToken
        }
        status
        privateNote
        generalNote
        tourItems {
          nextToken
        }
        meetupLocation
        meetupDescription
        meetupTime
        startTime
        endTime
        date
        shared
        createdAt
        updatedAt
        agent {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          brokerageId
          currency
          measurement
          notifications
          deviceToken
          createdAt
          updatedAt
        }
        showingAgent {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          brokerageId
          currency
          measurement
          notifications
          deviceToken
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getToursByDate = /* GraphQL */ `
  query GetToursByDate(
    $date: AWSDate
    $sortDirection: ModelSortDirection
    $filter: ModelTourFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getToursByDate(
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        creator
        agentId
        showingAgentId
        members
        membersData
        clients {
          nextToken
        }
        status
        privateNote
        generalNote
        tourItems {
          nextToken
        }
        meetupLocation
        meetupDescription
        meetupTime
        startTime
        endTime
        date
        shared
        createdAt
        updatedAt
        agent {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          brokerageId
          currency
          measurement
          notifications
          deviceToken
          createdAt
          updatedAt
        }
        showingAgent {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          brokerageId
          currency
          measurement
          notifications
          deviceToken
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getToursByShareStatus = /* GraphQL */ `
  query GetToursByShareStatus(
    $shared: ShareStatus
    $sortDirection: ModelSortDirection
    $filter: ModelTourFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getToursByShareStatus(
      shared: $shared
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        creator
        agentId
        showingAgentId
        members
        membersData
        clients {
          nextToken
        }
        status
        privateNote
        generalNote
        tourItems {
          nextToken
        }
        meetupLocation
        meetupDescription
        meetupTime
        startTime
        endTime
        date
        shared
        createdAt
        updatedAt
        agent {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          brokerageId
          currency
          measurement
          notifications
          deviceToken
          createdAt
          updatedAt
        }
        showingAgent {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          brokerageId
          currency
          measurement
          notifications
          deviceToken
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSchool = /* GraphQL */ `
  query GetSchool($id: ID!) {
    getSchool(id: $id) {
      id
      objVer
      name
      isCatholic
      isPublic
      isUseSchool
      gradeFrom
      gradeEnd
      isElementary
      isMiddle
      isHigh
      isEnglish
      isFrenchImmersion
      isExtendedFrench
      isAP
      isArts
      isGifted
      isIB
      isSport
      g3Avg
      g3WeightedAvg
      g6Avg
      g6WeightedAvg
      g9Avg
      g9APMWeightedAvg
      g9ACMWeightedAvg
      g10Avg
      ettieOverallAvg
      g3Rank
      g3RankTotal
      g6Rank
      g6RankTotal
      g9Rank
      g9RankTotal
      g9ACRank
      g9ACTotalRank
      g9APRank
      g9APTotalRank
      g10Rank
      g10RankTotal
      EQAOScore
      yearMark
      province
      nation
      url
      schoolBoardId
      address
      city
      postalCode
      phone
      fax
      latitude
      longitude
      comments
      boundaryArray
      schoolsAssocationArray
      lastReviewDate
      createdAt
      updatedAt
      schoolBoard {
        id
        objVer
        name
        url
        boardType
        province
        nation
        address
        city
        postalCode
        phone
        fax
        createdAt
        updatedAt
        schools {
          nextToken
        }
        scores {
          nextToken
        }
      }
      ratings {
        items {
          id
          objVer
          schoolId
          yearMark
          year
          G3STU
          G3AR
          G3AW
          G3AM
          G3Rank
          G3Total
          G3FindSchoolRating
          G6STU
          G6AR
          G6AW
          G6AM
          G6Rank
          G6Total
          G6FindSchoolRating
          G9ACSTU
          G9ACM
          G9ACRank
          G9ACTotal
          G9APSTU
          G9APM
          G9APRank
          G9APTotal
          G9Rank
          G9Total
          G9FindSchoolRating
          G10OSSLTFSTUA
          G10OSSLTFSTUP
          G10OSSLTFSUCCESS
          G3Last5Avg
          G6Last5Avg
          G9Last5Avg
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listSchools = /* GraphQL */ `
  query ListSchools(
    $filter: ModelSchoolFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSchools(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        objVer
        name
        isCatholic
        isPublic
        isUseSchool
        gradeFrom
        gradeEnd
        isElementary
        isMiddle
        isHigh
        isEnglish
        isFrenchImmersion
        isExtendedFrench
        isAP
        isArts
        isGifted
        isIB
        isSport
        g3Avg
        g3WeightedAvg
        g6Avg
        g6WeightedAvg
        g9Avg
        g9APMWeightedAvg
        g9ACMWeightedAvg
        g10Avg
        ettieOverallAvg
        g3Rank
        g3RankTotal
        g6Rank
        g6RankTotal
        g9Rank
        g9RankTotal
        g9ACRank
        g9ACTotalRank
        g9APRank
        g9APTotalRank
        g10Rank
        g10RankTotal
        EQAOScore
        yearMark
        province
        nation
        url
        schoolBoardId
        address
        city
        postalCode
        phone
        fax
        latitude
        longitude
        comments
        boundaryArray
        schoolsAssocationArray
        lastReviewDate
        createdAt
        updatedAt
        schoolBoard {
          id
          objVer
          name
          url
          boardType
          province
          nation
          address
          city
          postalCode
          phone
          fax
          createdAt
          updatedAt
        }
        ratings {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getSchoolBoard = /* GraphQL */ `
  query GetSchoolBoard($id: ID!) {
    getSchoolBoard(id: $id) {
      id
      objVer
      name
      url
      boardType
      province
      nation
      address
      city
      postalCode
      phone
      fax
      createdAt
      updatedAt
      schools {
        items {
          id
          objVer
          name
          isCatholic
          isPublic
          isUseSchool
          gradeFrom
          gradeEnd
          isElementary
          isMiddle
          isHigh
          isEnglish
          isFrenchImmersion
          isExtendedFrench
          isAP
          isArts
          isGifted
          isIB
          isSport
          g3Avg
          g3WeightedAvg
          g6Avg
          g6WeightedAvg
          g9Avg
          g9APMWeightedAvg
          g9ACMWeightedAvg
          g10Avg
          ettieOverallAvg
          g3Rank
          g3RankTotal
          g6Rank
          g6RankTotal
          g9Rank
          g9RankTotal
          g9ACRank
          g9ACTotalRank
          g9APRank
          g9APTotalRank
          g10Rank
          g10RankTotal
          EQAOScore
          yearMark
          province
          nation
          url
          schoolBoardId
          address
          city
          postalCode
          phone
          fax
          latitude
          longitude
          comments
          boundaryArray
          schoolsAssocationArray
          lastReviewDate
          createdAt
          updatedAt
        }
        nextToken
      }
      scores {
        items {
          id
          schoolBoardId
          yearMark
          year
          totalAvg
          g3STU
          g6STU
          g9ACSTU
          g9APSTU
          g3TS
          g6TS
          g9TS
          g10OSSLTFSTUA
          g10OSSLTFSTUP
          g10OSSLTFSUCCESS
          g3AR
          g3AW
          g3AM
          g6AR
          g6AW
          g6AM
          g9APM
          g9ACM
          g3Last5Avg
          g6Last5Avg
          g9Last5Avg
          g3FindSchoolRating
          g6FindSchoolRating
          g9FindSchoolRating
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listSchoolBoards = /* GraphQL */ `
  query ListSchoolBoards(
    $filter: ModelSchoolBoardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSchoolBoards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        objVer
        name
        url
        boardType
        province
        nation
        address
        city
        postalCode
        phone
        fax
        createdAt
        updatedAt
        schools {
          nextToken
        }
        scores {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getSchoolBoardScore = /* GraphQL */ `
  query GetSchoolBoardScore($id: ID!) {
    getSchoolBoardScore(id: $id) {
      id
      schoolBoardId
      yearMark
      year
      totalAvg
      g3STU
      g6STU
      g9ACSTU
      g9APSTU
      g3TS
      g6TS
      g9TS
      g10OSSLTFSTUA
      g10OSSLTFSTUP
      g10OSSLTFSUCCESS
      g3AR
      g3AW
      g3AM
      g6AR
      g6AW
      g6AM
      g9APM
      g9ACM
      g3Last5Avg
      g6Last5Avg
      g9Last5Avg
      g3FindSchoolRating
      g6FindSchoolRating
      g9FindSchoolRating
      createdAt
      updatedAt
      schoolBoard {
        id
        objVer
        name
        url
        boardType
        province
        nation
        address
        city
        postalCode
        phone
        fax
        createdAt
        updatedAt
        schools {
          nextToken
        }
        scores {
          nextToken
        }
      }
    }
  }
`;
export const listSchoolBoardScores = /* GraphQL */ `
  query ListSchoolBoardScores(
    $filter: ModelSchoolBoardScoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSchoolBoardScores(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolBoardId
        yearMark
        year
        totalAvg
        g3STU
        g6STU
        g9ACSTU
        g9APSTU
        g3TS
        g6TS
        g9TS
        g10OSSLTFSTUA
        g10OSSLTFSTUP
        g10OSSLTFSUCCESS
        g3AR
        g3AW
        g3AM
        g6AR
        g6AW
        g6AM
        g9APM
        g9ACM
        g3Last5Avg
        g6Last5Avg
        g9Last5Avg
        g3FindSchoolRating
        g6FindSchoolRating
        g9FindSchoolRating
        createdAt
        updatedAt
        schoolBoard {
          id
          objVer
          name
          url
          boardType
          province
          nation
          address
          city
          postalCode
          phone
          fax
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSchoolRating = /* GraphQL */ `
  query GetSchoolRating($id: ID!) {
    getSchoolRating(id: $id) {
      id
      objVer
      schoolId
      yearMark
      year
      G3STU
      G3AR
      G3AW
      G3AM
      G3Rank
      G3Total
      G3FindSchoolRating
      G6STU
      G6AR
      G6AW
      G6AM
      G6Rank
      G6Total
      G6FindSchoolRating
      G9ACSTU
      G9ACM
      G9ACRank
      G9ACTotal
      G9APSTU
      G9APM
      G9APRank
      G9APTotal
      G9Rank
      G9Total
      G9FindSchoolRating
      G10OSSLTFSTUA
      G10OSSLTFSTUP
      G10OSSLTFSUCCESS
      G3Last5Avg
      G6Last5Avg
      G9Last5Avg
      createdAt
      updatedAt
      school {
        id
        objVer
        name
        isCatholic
        isPublic
        isUseSchool
        gradeFrom
        gradeEnd
        isElementary
        isMiddle
        isHigh
        isEnglish
        isFrenchImmersion
        isExtendedFrench
        isAP
        isArts
        isGifted
        isIB
        isSport
        g3Avg
        g3WeightedAvg
        g6Avg
        g6WeightedAvg
        g9Avg
        g9APMWeightedAvg
        g9ACMWeightedAvg
        g10Avg
        ettieOverallAvg
        g3Rank
        g3RankTotal
        g6Rank
        g6RankTotal
        g9Rank
        g9RankTotal
        g9ACRank
        g9ACTotalRank
        g9APRank
        g9APTotalRank
        g10Rank
        g10RankTotal
        EQAOScore
        yearMark
        province
        nation
        url
        schoolBoardId
        address
        city
        postalCode
        phone
        fax
        latitude
        longitude
        comments
        boundaryArray
        schoolsAssocationArray
        lastReviewDate
        createdAt
        updatedAt
        schoolBoard {
          id
          objVer
          name
          url
          boardType
          province
          nation
          address
          city
          postalCode
          phone
          fax
          createdAt
          updatedAt
        }
        ratings {
          nextToken
        }
      }
    }
  }
`;
export const listSchoolRatings = /* GraphQL */ `
  query ListSchoolRatings(
    $filter: ModelSchoolRatingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSchoolRatings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        objVer
        schoolId
        yearMark
        year
        G3STU
        G3AR
        G3AW
        G3AM
        G3Rank
        G3Total
        G3FindSchoolRating
        G6STU
        G6AR
        G6AW
        G6AM
        G6Rank
        G6Total
        G6FindSchoolRating
        G9ACSTU
        G9ACM
        G9ACRank
        G9ACTotal
        G9APSTU
        G9APM
        G9APRank
        G9APTotal
        G9Rank
        G9Total
        G9FindSchoolRating
        G10OSSLTFSTUA
        G10OSSLTFSTUP
        G10OSSLTFSUCCESS
        G3Last5Avg
        G6Last5Avg
        G9Last5Avg
        createdAt
        updatedAt
        school {
          id
          objVer
          name
          isCatholic
          isPublic
          isUseSchool
          gradeFrom
          gradeEnd
          isElementary
          isMiddle
          isHigh
          isEnglish
          isFrenchImmersion
          isExtendedFrench
          isAP
          isArts
          isGifted
          isIB
          isSport
          g3Avg
          g3WeightedAvg
          g6Avg
          g6WeightedAvg
          g9Avg
          g9APMWeightedAvg
          g9ACMWeightedAvg
          g10Avg
          ettieOverallAvg
          g3Rank
          g3RankTotal
          g6Rank
          g6RankTotal
          g9Rank
          g9RankTotal
          g9ACRank
          g9ACTotalRank
          g9APRank
          g9APTotalRank
          g10Rank
          g10RankTotal
          EQAOScore
          yearMark
          province
          nation
          url
          schoolBoardId
          address
          city
          postalCode
          phone
          fax
          latitude
          longitude
          comments
          boundaryArray
          schoolsAssocationArray
          lastReviewDate
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
