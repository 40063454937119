import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import numeral from "numeral";
import moment from "moment";
import millify from "millify";
import DetailPageDropdown from "../DetailPageDropdown/detail-page-dropdown.component";
import styles from "./detail-page-information.module.scss";
import { detailPageInformation } from "../../../../../../data/detailsPage";
import {
  openModal,
  closeModal,
  openAuthModal,
} from "../../../../../../redux/ui/ui.actions";
import Overview from "./components/Overview/overview.component";
import Rooms from "./components/Rooms/rooms.component";
import PriceHistory from "./components/PriceHistory/price-history.component";
import Calculators from "./components/Calculators/calculators.component";
import { selectCurrentClient } from "../../../../../../redux/client/client.selectors";
import { selectCurrencyRates } from "../../../../../../redux/currency/currency.selectors";
import Essentials from "./components/Essentials/essentials.component";
import _ from "lodash";
import Button from "../../../../../../components/Form/Button/button.component";
import { calculateCashback } from "../../../../../../utils/functions";
import Schools from "./components/Schools/schools.component";

const DetailPageInformation = ({
  listing,
  schools,
  schoolsLoading,
  user,
  language,
}) => {
  const client = useSelector(selectCurrentClient);
  const rates = useSelector(selectCurrencyRates);
  const dispatch = useDispatch();
  const location = useLocation();
  const [tabs, setTabs] = useState({
    essentials: true,
    overview: false,
    rooms: false,
    priceHistory: false,
    calculators: false,
    school: false,
  });
  const [feet, setFeet] = useState(
    client && client.measurement === "metric" ? false : true
  );

  let currency;
  if (client && rates) {
    if (client.currency !== "cad") currency = client.currency.toUpperCase();
  }

  const signInHandler = () => {
    dispatch(openAuthModal({ current: "login" }));
  };
  const signUpHandler = () => {
    dispatch(openAuthModal({ current: "register" }));
  };

  useEffect(() => {
    user && dispatch(closeModal());
  }, [user, dispatch]);

  const onToggle = () => {
    setFeet(!feet);
  };

  const toggleTabs = (tabName) => {
    setTabs({ ...tabs, [tabName]: !tabs[tabName] });
  };

  const roomDetails = () => {
    return Object.keys(listing.rooms).map((key) => {
      if (listing.rooms[key].description !== "") {
        return (
          <div className={styles.roomdetails} key={key}>
            <span>{listing.rooms[key].description}</span>
            <span>
              {listing.rooms[key].length && listing.rooms[key].width
                ? `${
                    feet
                      ? `${listing.rooms[key].length} ft`
                      : `${numeral(+listing.rooms[key].length * 0.3048).format(
                          "99.99"
                        )} m`
                  } x ${
                    feet
                      ? `${listing.rooms[key].width} ft`
                      : `${numeral(+listing.rooms[key].width * 0.3048).format(
                          "99.99"
                        )} m`
                  }`
                : "Measurement not provided"}
            </span>
            <span>{`${
              listing.rooms[key].features && listing.rooms[key].features
            }${
              listing.rooms[key].features2 &&
              ", " + listing.rooms[key].features2
            }${
              listing.rooms[key].features3 &&
              ", " + listing.rooms[key].features3
            } 
           `}</span>
          </div>
        );
      }
      return null;
    });
  };

  const historyDetails = () => {
    return listing.history && listing.history.length > 0 ? (
      _.sortBy(listing.history, "listDate").map((item) => {
        return (
          <div className={styles.priceDetails} key={item.mlsNumber}>
            <span>
              {" "}
              {item.listPrice
                ? currency
                  ? `${currency} ${millify(
                      (+item.listPrice / +rates.CAD.S) * +rates[currency].S
                    )}`
                  : numeral(item.listPrice).format("$1,000,000.00")
                : "-"}
            </span>
            <span>{moment(item.listDate).format("ll")}</span>
            <span>
              {item.soldDate
                ? moment(item.soldDate).format("ll") !== "Invalid date"
                  ? moment(item.soldDate).format("ll")
                  : "-"
                : "-"}
            </span>

            <span>
              {item.soldPrice
                ? currency
                  ? `${currency} ${millify(
                      (+item.soldPrice / +rates.CAD.S) * +rates[currency].S
                    )}`
                  : numeral(item.soldPrice).format("$1,000,000.00")
                : "-"}
            </span>
            <span>{item.lastStatus}</span>
            <span>
              {
                item.mlsNumber !== listing.mlsNumber && (
                  // location.state &&
                  // location.state.from ?
                  <Link
                    style={{ color: "#7158ad" }}
                    to={{
                      pathname: `/listings/${item.mlsNumber}`,
                      // state: {
                      //   from: [
                      //     ...location.state.from,
                      //     `/listings/${listing.mlsNumber}`,
                      //   ],
                      // },
                    }}
                  >
                    {item.mlsNumber}
                  </Link>
                )
                // : (
                //   item.mlsNumber
                // )
              }
            </span>
          </div>
        );
      })
    ) : (
      <div className={styles.noData}>
        {detailPageInformation[language].noPriceHistory}
      </div>
    );
  };

  const cashbackFormHandler = () => {
    dispatch(
      openModal({
        current: "requestForm",
        overflow: true,
        data: {
          cashback: true,
          isSale: listing.type.toLowerCase() === "sale",
        },
      })
    );
  };

  const getCashbackTitle = () => {
    if (listing.class === "CommercialProperty")
      return "Contact us for Ettie cashback offers!";
    if (listing.type.toLowerCase() === "sale") {
      return `Get up to $${calculateCashback(
        +listing.listPrice,
        "sale"
      )} cashback!`;
    } else {
      return `Get up to $${calculateCashback(
        +listing.listPrice,
        "lease"
      )} cashback!`;
    }
  };

  const { essentials, overview, rooms, priceHistory, calculators, school } =
    tabs;
  return (
    <div className={styles.container}>
      {/* <div className={styles.infoTitle}>
        {listing.status === "A" && (
          <Button
            title={getCashbackTitle()}
            type="green"
            border
            style={{ height: "5rem" }}
            onClick={cashbackFormHandler}
          />
        )}
        {detailPageInformation[language].title}
      </div> */}
      <DetailPageDropdown
        title={detailPageInformation[language].essentials}
        open={essentials}
        onClick={() => toggleTabs("essentials")}
      />
      {essentials && <Essentials listing={listing} language={language} />}
      <DetailPageDropdown
        title={detailPageInformation[language].details}
        open={overview}
        onClick={() => toggleTabs("overview")}
      />
      {overview && <Overview listing={listing} language={language} />}
      <DetailPageDropdown
        title={detailPageInformation[language].rooms}
        open={rooms}
        onClick={() => toggleTabs("rooms")}
      />
      {rooms && (
        <Rooms
          roomDetails={roomDetails}
          isFeet={feet}
          onToggle={onToggle}
          language={language}
        />
      )}
      <DetailPageDropdown
        title={detailPageInformation[language].priceHistory}
        open={priceHistory}
        onClick={() => toggleTabs("priceHistory")}
      />
      {priceHistory && (
        <PriceHistory
          user={user}
          historyDetails={historyDetails}
          signUpHandler={signUpHandler}
          signInHandler={signInHandler}
          language={language}
        />
      )}
      {listing.class !== "CommercialProperty" && (
        <>
          <DetailPageDropdown
            title={detailPageInformation[language].schools}
            open={school}
            onClick={() => toggleTabs("school")}
          />

          {school && (
            <Schools
              listing={listing}
              schools={schools}
              loading={schoolsLoading}
            />
          )}
        </>
      )}

      {listing.type === "Sale" && listing.class !== "CommercialProperty" && (
        <>
          <DetailPageDropdown
            title={detailPageInformation[language].calculators}
            open={calculators}
            onClick={() => toggleTabs("calculators")}
          />
          {calculators && <Calculators listing={listing} />}
        </>
      )}
    </div>
  );
};

export default DetailPageInformation;
