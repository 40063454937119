import { useState, useEffect, useRef } from "react";
import { Storage } from "aws-amplify";
import {
  IonModal,
  IonSlides,
  IonSlide,
  IonIcon,
  IonButton,
} from "@ionic/react";
import NoImage from "../NoImage/no-image.component";
import {
  chevronBackOutline,
  chevronForwardOutline,
  closeOutline,
  trashOutline,
} from "ionicons/icons";
import styles from "./photos-section.module.scss";
const PhotosSection = ({
  isCurrentUser,
  photos,
  memberData,
  handleDelete,
  tour,
  isApp,
}) => {
  const [modal, setModal] = useState(undefined);
  const slidesModalRef = useRef();
  const slidesRef = useRef();
  const handleError = (e) => (e.target.hidden = true);
  const SLIDES_OPTIONS = {
    spaceBetween: 2,
    slidesPerView: 1,
    initialSlide: 0,
    centeredSlides: true,
    loop: true,
  };
  const [loading, setLoading] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [s3Photos, setS3Photos] = useState([]);
  const handleDoubleTap = async (e) => {
    const swiper = await slidesRef.current.getSwiper();
    if (swiper.zoom.currentScale > 1) {
      swiper.zoom.out();
    } else {
      swiper.zoom.in();
    }
  };
  const handleLoad = async () => {
    if (slidesRef.current) {
      slidesRef.current.options = SLIDES_OPTIONS;
      const swiper = await slidesRef.current.getSwiper();
      swiper.slideTo(modal - 1);
    }
  };

  const handleRightClick = async () => {
    const swiper = await slidesRef.current.getSwiper();
    const isEnd = await swiper.isEnd;
    if (isEnd) {
      swiper.slideTo(0);
    } else {
      swiper.slideNext();
    }
  };

  const handleLeftClick = async () => {
    const swiper = await slidesRef.current.getSwiper();
    const isBeginning = await swiper.isBeginning;
    if (isBeginning) {
      const length = await slidesRef.current.length();
      swiper.slideTo(length - 1);
    } else {
      swiper.slidePrev();
    }
  };

  useEffect(() => {
    const fetchPhotos = async () => {
      setLoading(true);
      const urls = await Promise.all(
        photos.map(async (p) => {
          const img = await Storage.get(p.path, {
            download: false,
            level: "public",
          });
          return { src: img, id: p.id, path: p.path };
        })
      );
      setS3Photos(urls);
      setLoading(false);
    };
    if (photos && photos.length > 0) fetchPhotos();
  }, [photos]);
  return (
    <>
      <div className={styles.photosSection}>
        <IonModal
          ref={slidesModalRef}
          isOpen={modal !== undefined}
          onDidDismiss={() => setModal(undefined)}
          cssClass="fullScreenModal"
          animated={false}
          backdropDismiss={true}
          // style={{ height: "100%", width: "100%" }}
        >
          <div className={styles.container}>
            <IonIcon
              onClick={handleLeftClick}
              icon={chevronBackOutline}
              className={styles.leftIcon}
            />

            {photos && s3Photos.length > 0 ? (
              <IonSlides
                className={styles.slides}
                ref={slidesRef}
                onIonSlideDoubleTap={handleDoubleTap}
                onIonSlidesDidLoad={handleLoad}
              >
                {s3Photos.map((img, i) => (
                  <IonSlide key={i} className={styles.slide}>
                    <img
                      key={i}
                      src={img.src}
                      onError={handleError}
                      alt="photos"
                      onClick={(e) => e.stopPropagation()}
                    />
                  </IonSlide>
                ))}
              </IonSlides>
            ) : (
              <NoImage />
            )}
            <IonIcon
              onClick={handleRightClick}
              icon={chevronForwardOutline}
              className={styles.rightIcon}
            />

            <div className={styles.closeContainer}>
              <IonButton
                fill="outline"
                shape="round"
                className={styles.btn}
                onClick={() => setModal(undefined)}
              >
                <IonIcon icon={closeOutline} />
                close
              </IonButton>
            </div>
          </div>
        </IonModal>
        {isCurrentUser ? (
          <div className={styles.currentUser}>
            <div className={styles.title}>My photos</div>
            {isApp && photos && s3Photos.length > 0 && (
              <div
                className={
                  !tour || !tour.status || tour.status === "archived"
                    ? styles.disableDeleteMode
                    : styles.deleteMode
                }
                onClick={() =>
                  !tour || !tour.status || tour.status === "archived"
                    ? null
                    : setDeleteMode(!deleteMode)
                }
              >
                {deleteMode ? "Cancel" : "Delete photos"}
              </div>
            )}
          </div>
        ) : (
          <div className={styles.others}>
            <span>{`${memberData.givenName ? memberData.givenName : ""} ${
              memberData.familyName ? memberData.familyName : ""
            }`}</span>
            <span className={styles.role}>{memberData.role}</span>
          </div>
        )}
        <div className={styles.images}>
          {photos && s3Photos.length > 0 ? (
            s3Photos.map((img, index) => (
              <span key={index}>
                <img
                  alt="Tour"
                  src={img.src}
                  className={styles.img}
                  onClick={() => setModal(index + 1)}
                />
                {deleteMode && (
                  <IonIcon
                    icon={trashOutline}
                    className={styles.deleteIcon}
                    onClick={() => handleDelete({ id: img.id, path: img.path })}
                    // disabled={
                    //   !tour || !tour.status || tour.status === "archived"
                    // }
                  />
                )}
              </span>
            ))
          ) : (
            <NoImage />
          )}
        </div>
      </div>
    </>
  );
};
export default PhotosSection;
