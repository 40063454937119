import { useEffect, useState } from "react";
import moment from "moment";
import { IonLoading } from "@ionic/react";
import DropdownContainer from "../../../../components/Form/DropdownContainer/dropdown-container.component";
import Select from "../../../../components/Form/Select/select.component";
import CardContainer from "../../../Tours/component/CardContainer/card-container.component";
import useComponentVisible from "../../../../hooks/useComponentVisible";
import { ranges } from "../../../Tours/component/NewTour/component/utils";
import styles from "./showing-details.module.scss";
import Button from "../../../../components/Form/Button/button.component";
import { buildAddress, capitalize } from "../../../../utils/functions";
import Input from "../../../../components/Form/Input/input.component";

const ShowingDetails = ({ details: { tourItem, listing }, update, tour }) => {
  const [note, setNote] = useState();
  const [time, setTime] = useState({
    start: "",
    end: "",
  });
  const [status, setStatus] = useState("");
  const [entryInfo, setEntryInfo] = useState("");
  const [submitLoading, setSubmitLoading] = useState(true);

  const handleStatus = (newStatus) => {
    setStatus(newStatus);
    setIsOpenShowingStatus(false);
  };

  useEffect(() => {
    setNote(tourItem.showingNote ? tourItem.showingNote : "");
    setTime({ start: tourItem.startTime, end: tourItem.endTime });
    setStatus(tourItem.status);
    setEntryInfo(tourItem.entryInfo);
    setSubmitLoading(false);
  }, [tourItem]);

  const {
    ref: refShowingStatus,
    isOpen: isOpenShowingStatus,
    setIsOpen: setIsOpenShowingStatus,
  } = useComponentVisible(false);

  const {
    ref: refFrom,
    isOpen: isOpenFrom,
    setIsOpen: setIsOpenFrom,
  } = useComponentVisible(false);

  const {
    ref: refTo,
    isOpen: isOpenTo,
    setIsOpen: setIsOpenTo,
  } = useComponentVisible(false);

  return (
    <CardContainer>
      <IonLoading isOpen={submitLoading} />
      <div className={styles.showingDetails}>
        <div className={styles.title}>Showing details</div>
        <div className={styles.address}>
          <span className={styles.bold}>{buildAddress(listing.address)}</span>
          <span>
            {listing.address.zip}, {listing.address.city}
          </span>
        </div>

        <div className={styles.inputs}>
          <div ref={refShowingStatus} className={styles.container}>
            <Select
              label="Showing status"
              title={capitalize(status)}
              style={{ height: "4rem", width: "100%" }}
              buttonStyle={{ height: "100%" }}
              open={isOpenShowingStatus}
              onClick={() => setIsOpenShowingStatus(!isOpenShowingStatus)}
            />
            <DropdownContainer
              open={isOpenShowingStatus}
              style={{
                position: "absolute",
                top: "auto",
                left: "auto",
                height: "15rem",
              }}
            >
              <div className={styles.statusDropdown}>
                <div onClick={() => handleStatus("completed")}>Completed</div>
                <div onClick={() => handleStatus("confirmed")}>Confirmed</div>
                <div onClick={() => handleStatus("requested")}>Requested</div>
                <div onClick={() => handleStatus("skipped")}>Skipped</div>
                <div onClick={() => handleStatus("cancelled")}>Cancelled</div>
                <div onClick={() => handleStatus("rejected")}>Rejected</div>
              </div>
            </DropdownContainer>
          </div>
          <div className={styles.times}>
            <div ref={refFrom} className={styles.container}>
              <Select
                label=""
                title={time.start ? time.start : "Start time"}
                style={{ height: "4rem", width: "100%" }}
                buttonStyle={{ height: "100%" }}
                open={isOpenFrom}
                onClick={() => setIsOpenFrom(!isOpenFrom)}
              />
              <DropdownContainer
                open={isOpenFrom}
                style={{
                  position: "absolute",
                  top: "auto",
                  left: "auto",
                  height: "15rem",
                }}
              >
                {ranges.map((range, index) => (
                  <div
                    key={index}
                    className={styles.range}
                    onClick={() => {
                      setTime({
                        ...time,
                        start: moment(range, "HH:mm:ss a").format("HH:mm"),
                      });
                      setIsOpenFrom(false);
                    }}
                  >
                    {range}
                  </div>
                ))}
              </DropdownContainer>
            </div>
            <div className={styles.hyphen}>-</div>
            <div ref={refTo} className={styles.container}>
              <Select
                label=""
                title={time.end ? time.end : "End time"}
                style={{ height: "4rem", width: "100%" }}
                buttonStyle={{ height: "100%" }}
                open={isOpenTo}
                onClick={() => setIsOpenTo(!isOpenTo)}
              />
              <DropdownContainer
                open={isOpenTo}
                style={{
                  position: "absolute",
                  top: "auto",
                  left: "auto",
                  height: "15rem",
                }}
              >
                {ranges.map((range, index) => (
                  <div
                    key={index}
                    className={styles.range}
                    onClick={() => {
                      setTime({
                        ...time,
                        end: moment(range, "HH:mm:ss a").format("HH:mm"),
                      });
                      setIsOpenTo(false);
                    }}
                  >
                    {range}
                  </div>
                ))}
              </DropdownContainer>
            </div>
          </div>
          <div className={styles.inputContainer}>
            <Input
              name="entryInfo"
              label="Lockbox and entry info"
              placeholder="Lockbox and entry info"
              style={{ width: "100%" }}
              value={entryInfo}
              onChange={(value, name) => setEntryInfo(value)}
            />
          </div>

          <div className={styles.container}>
            <div className={styles.textareaLabel}>Showing notes for client</div>
            <textarea
              className={styles.textarea}
              name="note"
              placeholder="Agent tour notes"
              value={note}
              onChange={(event) => {
                setNote(event.target.value);
              }}
            ></textarea>
          </div>

          <Button
            title="Save changes"
            type="green"
            style={{
              border: "1px solid #1F7A60",
              width: "100%",
              height: "5rem",
            }}
            onClick={() =>
              update({
                id: tourItem.id,
                showingNote: note,
                startTime: time.start,
                endTime: time.end,
                status,
                entryInfo,
              })
            }
            disabled={!tour || !tour.status || tour.status === "archived"}
          />
        </div>
      </div>
    </CardContainer>
  );
};

export default ShowingDetails;
