import { IonIcon } from "@ionic/react";
import styles from "./zoom-button.module.scss";
import { addOutline, removeOutline } from "ionicons/icons";

const ZoomButton = ({ zoomIn, zoomOut, zoomInDisabled, zoomOutDisabled }) => (
  <div className={styles.buttonsContainer}>
    <div
      className={`${styles.zoomIn} ${zoomInDisabled && styles.disabled}`}
      onClick={zoomIn}
    >
      <IonIcon className={styles.icon} icon={addOutline} />
    </div>
    <div className={styles.line}>
      <hr />
    </div>

    <div
      className={`${styles.zoomOut} ${zoomOutDisabled && styles.disabled}`}
      onClick={zoomOut}
    >
      <IonIcon className={styles.icon} icon={removeOutline} />
    </div>
  </div>
);

export default ZoomButton;
