import { IonIcon } from "@ionic/react";
import styles from "./detail-page-button.module.scss";

const DetailPageButton = ({
  icon,
  label,
  imgwidth,
  imgheight,
  onClick,
  isImage = true,
  disable = false,
  iconStyle,
  labelStyle,
  ...rest
}) => (
  <div
    className={`${styles.btn} ${disable && styles.disable}`}
    onClick={onClick}
    {...rest}
  >
    <div className={styles.img}>
      {isImage ? (
        <img
          src={icon}
          alt=""
          height={imgheight}
          width={imgwidth}
          style={iconStyle}
        />
      ) : (
        <IonIcon src={icon} className={styles.icon} />
      )}
    </div>
    <div className={styles.label} style={labelStyle}>
      {label}
    </div>
  </div>
);

export default DetailPageButton;
