import styles from "./view-toggle.module.scss";
import { ReactComponent as MapIcon } from "../../assets/svg/map-view.svg";
import { ReactComponent as ListIcon } from "../../assets/svg/list-view.svg";

const ViewToggle = ({ view, setView }) => (
  <div className={`${styles.container} ${view === "map" && styles.mapView}`}>
    {view === "map" && (
      <div
        className={`${styles.button} ${view === "list" ? styles.selected : ""}`}
        onClick={() => setView("list")}
      >
        <ListIcon className={styles.icon} />
        <span className={styles.title}>list view</span>
      </div>
    )}
    {view === "list" && (
      <div
        className={`${styles.button} ${view === "map" ? styles.selected : ""}`}
        onClick={() => setView("map")}
      >
        <MapIcon className={styles.icon} />
        <span className={styles.title}>map view</span>
      </div>
    )}
  </div>
);

export default ViewToggle;
