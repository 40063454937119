import { useSelector, useDispatch } from "react-redux";
import {
  IonButton,
  IonRow,
  IonCol,
  IonGrid,
  IonRange,
  IonLabel,
  IonList,
  IonItem,
  IonCheckbox,
} from "@ionic/react";
import styles from "./school-filter.module.scss";
import { selectSchools } from "../../../../redux/schools/schools.selectors";
import {
  toggleSchools,
  updateSchoolsFilters,
} from "../../../../redux/schools/schools.actions";
import Checkbox from "../../../../components/Form/Checkbox/checkbox-component";
import AppHeader from "../../Header/header.component";
import { handleLinkClick } from "../../../../utils/functions";

import Toggle from "../../../../components/Form/Toggle/toggle-component";

const AppSchoolFilterModal = ({ close }) => {
  const dispatch = useDispatch();
  const schools = useSelector(selectSchools);

  const {
    active,
    filters: {
      rate,
      showUnrated,
      elementary,
      middle,
      high,
      public: pub,
      catholic,
      private: pri,
      frenchImmersion,
      ib,
      ap,
      gifted,
      sports,
      arts,
      showListingsWithin,
    },
  } = schools;

  const DEFAULT_FILTERS = {
    elementary: false,
    middle: false,
    high: false,
    public: false,
    catholic: false,
    private: false,
    english: false,
    frenchImmersion: false,
    ib: false,
    ap: false,
    gifted: false,
    sports: false,
    arts: false,
    rate: null,
    showUnrated: false,
    showListingsWithin: false,
  };

  const resetHandler = () => {
    dispatch(updateSchoolsFilters(DEFAULT_FILTERS));
  };

  const handleRateUpdate = ({ detail: { value } }) => {
    dispatch(updateSchoolsFilters({ rate: value }));
  };

  return (
    <>
      <AppHeader
        title="Filter schools"
        isFilters
        onClick={close}
        onReset={resetHandler}
        isModal
      />

      <div className={styles.content}>
        <IonGrid className={styles.filters}>
          <IonRow>
            <IonCol className={`${styles.toggleRow} ${styles.border}`}>
              <div className={styles.title}>Show schools</div>
              <div>
                <Toggle
                  active={active}
                  color="green"
                  hasLabel={false}
                  onToggle={() => dispatch(toggleSchools())}
                  style={{ height: "3rem", width: "6rem" }}
                  circleStyle={{
                    circle: { height: "2rem", width: "2rem" },
                    activeCircle: {
                      height: "2rem",
                      width: "2rem",
                      left: "60%",
                    },
                  }}
                />
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div className={`${styles.border} ${styles.subtitle}`}>Level</div>
              <div className={`${styles.border} ${styles.levelRow}`}>
                <IonList className={styles.list}>
                  <IonItem
                    className={styles.item}
                    disabled={!active}
                    lines="none"
                    onIonChange={() =>
                      dispatch(
                        updateSchoolsFilters({ elementary: !elementary })
                      )
                    }
                  >
                    <IonLabel>Elementary</IonLabel>
                    <IonCheckbox
                      slot="end"
                      className={styles.checkbox}
                      checked={elementary}
                    />
                  </IonItem>

                  <IonItem
                    className={styles.item}
                    disabled={!active}
                    lines="none"
                    onIonChange={() =>
                      dispatch(updateSchoolsFilters({ middle: !middle }))
                    }
                  >
                    <IonLabel>Middle School</IonLabel>
                    <IonCheckbox
                      slot="end"
                      className={styles.checkbox}
                      checked={middle}
                    />
                  </IonItem>

                  <IonItem
                    className={styles.item}
                    disabled={!active}
                    lines="none"
                    onIonChange={() =>
                      dispatch(updateSchoolsFilters({ high: !high }))
                    }
                  >
                    <IonLabel>High School</IonLabel>
                    <IonCheckbox
                      slot="end"
                      className={styles.checkbox}
                      checked={high}
                    />
                  </IonItem>
                </IonList>
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div className={`${styles.border} ${styles.subtitle}`}>Type</div>
              <div className={`${styles.border} ${styles.levelRow}`}>
                <IonList className={styles.list}>
                  <IonItem
                    className={styles.item}
                    disabled={!active}
                    lines="none"
                    onIonChange={() =>
                      dispatch(updateSchoolsFilters({ public: !pub }))
                    }
                  >
                    <IonLabel>Public</IonLabel>
                    <IonCheckbox
                      slot="end"
                      className={styles.checkbox}
                      checked={pub}
                    />
                  </IonItem>

                  <IonItem
                    className={styles.item}
                    disabled={!active}
                    lines="none"
                    onIonChange={() =>
                      dispatch(updateSchoolsFilters({ catholic: !catholic }))
                    }
                  >
                    <IonLabel>Catholic</IonLabel>
                    <IonCheckbox
                      slot="end"
                      className={styles.checkbox}
                      checked={catholic}
                    />
                  </IonItem>

                  <IonItem className={styles.item} disabled={true} lines="none">
                    <IonLabel>Private (coming soon)</IonLabel>
                    <IonCheckbox
                      slot="end"
                      className={styles.checkbox}
                      checked={pri}
                    />
                  </IonItem>
                </IonList>
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div className={`${styles.border} ${styles.subtitle}`}>
                Special programs
              </div>
              <div className={`${styles.border} ${styles.levelRow}`}>
                <IonList className={styles.list}>
                  <IonItem
                    className={styles.item}
                    disabled={!active}
                    lines="none"
                    onIonChange={() =>
                      dispatch(
                        updateSchoolsFilters({
                          frenchImmersion: !frenchImmersion,
                        })
                      )
                    }
                  >
                    <IonLabel>French Immersion</IonLabel>
                    <IonCheckbox
                      slot="end"
                      className={styles.checkbox}
                      checked={frenchImmersion}
                    />
                  </IonItem>

                  <IonItem
                    className={styles.item}
                    disabled={!active}
                    lines="none"
                    onIonChange={() =>
                      dispatch(updateSchoolsFilters({ ib: !ib }))
                    }
                  >
                    <IonLabel>International Baccalaureate</IonLabel>
                    <IonCheckbox
                      slot="end"
                      className={styles.checkbox}
                      checked={ib}
                    />
                  </IonItem>

                  <IonItem
                    className={styles.item}
                    disabled={!active}
                    lines="none"
                    onIonChange={() =>
                      dispatch(updateSchoolsFilters({ ap: !ap }))
                    }
                  >
                    <IonLabel>Advanced Placement</IonLabel>
                    <IonCheckbox
                      slot="end"
                      className={styles.checkbox}
                      checked={ap}
                    />
                  </IonItem>
                  <IonItem
                    className={styles.item}
                    disabled={!active}
                    lines="none"
                    onIonChange={() =>
                      dispatch(updateSchoolsFilters({ gifted: !gifted }))
                    }
                  >
                    <IonLabel>Gifted</IonLabel>
                    <IonCheckbox
                      slot="end"
                      className={styles.checkbox}
                      checked={gifted}
                    />
                  </IonItem>
                  <IonItem
                    className={styles.item}
                    disabled={!active}
                    lines="none"
                    onIonChange={() =>
                      dispatch(updateSchoolsFilters({ sports: !sports }))
                    }
                  >
                    <IonLabel>Sports</IonLabel>
                    <IonCheckbox
                      slot="end"
                      className={styles.checkbox}
                      checked={sports}
                    />
                  </IonItem>
                  <IonItem
                    className={styles.item}
                    disabled={!active}
                    lines="none"
                    onIonChange={() =>
                      dispatch(updateSchoolsFilters({ arts: !arts }))
                    }
                  >
                    <IonLabel>Arts</IonLabel>
                    <IonCheckbox
                      slot="end"
                      className={styles.checkbox}
                      checked={arts}
                    />
                  </IonItem>
                </IonList>
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div
                className={`${styles.border} ${styles.subtitle} ${styles.ratingHeader}`}
              >
                <span>EQAO Rating</span>
                <span>{rate ? (rate !== 10 ? `${rate}+` : 10) : ""}</span>
              </div>
              <IonRange
                disabled={!active}
                className={styles.range}
                value={rate ? rate : 0}
                min={0}
                max={10}
                step={0.5}
                snaps
                ticks
                onIonChange={handleRateUpdate}
              >
                <IonLabel slot="start">0</IonLabel>
                <IonLabel slot="end">10</IonLabel>
              </IonRange>
              <div className={`${styles.border} ${styles.levelRow}`}>
                <Checkbox
                  disabled={!active}
                  checked={showUnrated}
                  label="Show unrated schools"
                  onClick={() =>
                    dispatch(
                      updateSchoolsFilters({ showUnrated: !showUnrated })
                    )
                  }
                  color="green"
                />
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonButton
                shape="round"
                expand="block"
                className={styles.btn}
                onClick={close}
              >
                Apply
              </IonButton>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div className={styles.info}>
                School boundaries change often. Please <u>confirm boundaries</u>{" "}
                before making any purchasing decisions. School information
                supplied by{" "}
                <u onClick={() => handleLinkClick("https://eqao.com")}>EQAO.</u>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </>
  );
};

export default AppSchoolFilterModal;
