import { SchoolsActionTypes } from "./schools.types";

export const fetchSchoolsStart = (boundary) => ({
  type: SchoolsActionTypes.FETCH_SCHOOLS_START,
  payload: boundary,
});

export const fetchSchoolsSuccess = (schools) => ({
  type: SchoolsActionTypes.FETCH_SCHOOLS_SUCCESS,
  payload: schools,
});

export const fetchSchoolsFailure = (error) => ({
  type: SchoolsActionTypes.FETCH_SCHOOLS_FAILURE,
  payload: error,
});

export const updateSchoolsFilters = (filter) => ({
  type: SchoolsActionTypes.UPDATE_SCHOOLS_FILTERS,
  payload: filter,
});

export const updateSelectedSchool = (school) => ({
  type: SchoolsActionTypes.UPDATE_SELECTED_SCHOOL,
  payload: school,
});

export const toggleSchools = () => ({
  type: SchoolsActionTypes.TOGGLE_SCHOOLS,
});

export const toggleFilterListings = (payload) => ({
  type: SchoolsActionTypes.TOGGLE_FILTER_LISTINGS,
  payload: payload,
});

export const addSchoolBoundary = (payload) => ({
  type: SchoolsActionTypes.ADD_SCHOOL_BOUNDARY,
  payload: payload,
});

export const removeSchoolBoundary = (payload) => ({
  type: SchoolsActionTypes.REMOVE_SCHOOL_BOUNDARY,
  payload: payload,
});
