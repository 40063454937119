import { IonIcon } from "@ionic/react";
import { chevronForwardOutline, chevronBackOutline } from "ionicons/icons";
import styles from "./pagination.module.scss";

const Pagination = ({ current, total, perPage = 10, onChange }) => {
  const TOTAL_PAGES = Math.ceil(total / perPage);
  return (
    <div className={styles.container}>
      <IonIcon
        icon={chevronBackOutline}
        className={`${styles.icon} ${current === 1 && styles.disabled}`}
        onClick={() => current > 1 && onChange(current - 1)}
      />
      {current !== 1 && (
        <div className={styles.item} onClick={() => onChange(1)}>
          {1}
        </div>
      )}
      {current > 3 && <div className={styles.dots}>...</div>}
      {current - 1 > 1 && (
        <div className={styles.item} onClick={() => onChange(current - 1)}>
          {current - 1}
        </div>
      )}
      <div className={`${styles.item} ${styles.current}`}>{current}</div>
      {TOTAL_PAGES > current + 1 && (
        <div className={styles.item} onClick={() => onChange(current + 1)}>
          {current + 1}
        </div>
      )}
      {current !== TOTAL_PAGES &&
        current !== TOTAL_PAGES - 1 &&
        current !== TOTAL_PAGES - 2 && <div className={styles.dots}>...</div>}
      {current !== TOTAL_PAGES && (
        <div className={styles.item} onClick={() => onChange(TOTAL_PAGES)}>
          {TOTAL_PAGES}
        </div>
      )}
      <IonIcon
        icon={chevronForwardOutline}
        className={`${styles.icon} ${
          current === TOTAL_PAGES && styles.disabled
        }`}
        onClick={() => current < TOTAL_PAGES && onChange(current + 1)}
      />
    </div>
  );
};

export default Pagination;
