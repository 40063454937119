import React from "react";
import styles from "./button.module.scss";
import { IonSpinner } from "@ionic/react";

const Button = ({
  title,
  type,
  buttonType = "button",
  children = null,
  loading = false,
  disabled = false,
  shape = "round",
  border = false,
  mapIcon,
  smallIcon,
  ...rest
}) => {
  return (
    <button
      type={buttonType}
      className={`${styles[type]} ${styles.button} ${
        shape === "block" ? styles.block : ""
      } ${border ? styles.border : ""}`}
      disabled={disabled}
      {...rest}
    >
      {children &&
        React.cloneElement(children, {
          className: mapIcon
            ? styles.mapIcon
            : smallIcon
            ? styles.smallIcon
            : styles.icon,
        })}

      {type === "glass" && <div className={styles.background} />}
      {loading ? (
        <span className={styles.title}>
          <IonSpinner name="crescent" className={styles.spinner} />
          working on it!
        </span>
      ) : (
        <span
          className={`${styles.title} ${type === "glass" && styles.foreground}`}
        >
          {title}
        </span>
      )}
    </button>
  );
};

export default Button;
