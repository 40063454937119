import {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { v4 as uuidv4 } from "uuid";
import { IonIcon, IonChip } from "@ionic/react";
import {
  searchOutline,
  closeOutline,
  eyeOutline,
  eyeOffOutline,
  personAddOutline,
} from "ionicons/icons";
import styles from "./input.module.scss";
import { Keyboard } from "@ionic-native/keyboard";

const Input = (props, ref) => {
  const {
    name,
    label,
    clear,
    defaultValue = "",
    search = false,
    add = false,
    loading = false,
    type = "text",
    mode = "text",
    validation,
    onChange,
    showPlaceholder = false,
    isHomepage,
    prefix,
    postfix,
    format,
    tags,
    showLabel = true,
    submit,
    isApp = false,
    isAuth = false,
    isDefaultFocus = false,
    preserveColor = false,
    enterkeyhint,
    searchIconStyle,
    autoComplete = "off",
    isCurrency = false,
    isCutOff = false,
    ...rest
  } = props;
  const inputRef = useRef();
  const [showPassword, setShowPassword] = useState(false);
  const [value, setValue] = useState(defaultValue ? defaultValue : "");
  const [error, setError] = useState("");

  const inputId = uuidv4();

  const moveCursor = (el) => {
    const caret = el.selectionStart;
    window.requestAnimationFrame(() => {
      el.selectionStart = caret;
      el.selectionEnd = caret;
    });
  };

  const handleChange = (e) => {
    if (type === "text" || type === "password" || type === "currency") {
      let caret = e.target.selectionStart;
      const element = e.target;

      window.requestAnimationFrame(() => {
        element.selectionStart = caret;
        element.selectionEnd = caret;
      });

      if (isCurrency && e.target.value?.length > value?.length) caret++;
    }

    e.persist();
    setValue(e.target.value);
  };

  useEffect(() => {
    setError("");
    if (onChange) onChange(value, name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleClear = () => {
    if (value) {
      clear();
      setValue("");
    }
  };

  const validate = (isSubmit = false) => {
    if (validation) {
      const rules = validation.split("|");
      for (let i = 0; i < rules.length; i++) {
        const rule = rules[i];
        if (rule === "required") {
          if (!value) {
            if (isSubmit) setError("This field is required");
            return false;
          }
        }

        if (rule === "email" && value) {
          const re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
          if (!re.test(value.toLowerCase())) {
            setError("Enter a valid email address");
            return false;
          }
        }

        if (rule === "password" && value) {
          const re =
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-+_!@#$%^&*.,?]).{8,}$/;

          if (!re.test(value)) {
            setError(
              "Must be minimum 8 characters, contain a mix of upper and lower case letters, at least 1 number, and 1 special character."
            );
            return false;
          }
        }

        if (rule === "passwordNoError" && value) {
          const re =
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-+_!@#$%^&*.,?]).{8,}$/;

          if (!re.test(value)) {
            return false;
          }
        }

        if (rule === "phone" && value) {
          const re = /^\(?([0-9]{3})\)?([0-9]{3})([0-9]{4})$/;
          if (!re.test(value)) {
            setError("Use numbers only and no spaces");
            return false;
          }
        }

        if (rule.includes(":")) {
          const pair = rule.split(":");
          switch (pair[0]) {
            case "min":
              if (value.length < pair[1]) {
                setError(
                  `This field must be at least ${pair[1]} characters long`
                );
                return false;
              }
              break;
            case "max":
              if (value.length > pair[1]) {
                setError(
                  `This field must be no longer than ${pair[1]}characters long`
                );
                return false;
              }
              break;
            case "confirmPassword":
              if (pair[1] !== "" && value !== pair[1]) {
                setError("Password don't match");
                return false;
              }
              break;
            case "equal":
              if (pair[0] !== "" && value !== pair[1]) {
                setError(
                  "Please enter the text exactly as displayed to confirm"
                );
                return false;
              }
              break;
            case "regex":
              const regex = new RegExp(pair[1]);
              if (!regex.test(value)) {
                setError("Use letters and spaces only");
                return false;
              }
              break;
            default:
              break;
          }
        }
      }
      setError("");
      return true;
    } else {
      return true;
    }
  };

  useImperativeHandle(ref, () => {
    return {
      validate: (isSubmit) => validate(isSubmit),
      next: () => inputRef.current.focus(),
    };
  });

  return (
    <div
      className={styles.inputContainer}
      style={{ marginBottom: error ? "1.5rem" : "auto" }}
    >
      {add && <IonIcon icon={personAddOutline} className={`${styles.icon} `} />}
      <input
        inputMode={mode}
        onKeyPress={(e) => {
          if (e.key === "Enter" && submit) {
            submit();
            try {
              Keyboard.hide();
            } catch (err) {}
          }
        }}
        id={inputId}
        spellCheck={false}
        className={`${styles.input} ${loading ? styles.loading : ""} ${
          showPlaceholder && styles.showPlaceholder
        } ${prefix && styles.withPrefix} ${search && styles.search} ${
          !showLabel && styles.visiblePlaceholder
        } ${preserveColor && styles.preserveColor} ${
          isDefaultFocus && styles.focus + " " + styles.visiblePlaceholder
        } ${isHomepage && styles.homePlaceholder}`}
        value={value ? value : ""}
        onChange={handleChange}
        type={type === "password" ? (showPassword ? "text" : "password") : type}
        ref={inputRef}
        autoComplete={autoComplete}
        enterKeyHint={enterkeyhint}
        autoCorrect="false"
        {...rest}
      />
      {tags && (
        <div className={styles.chips}>
          {tags.map((t, i) => (
            <IonChip key={i}>{t.label}</IonChip>
          ))}
        </div>
      )}
      {prefix && <span className={styles.prefix}>{prefix}</span>}
      {postfix && <span className={styles.postfix}>{postfix}</span>}
      {showLabel && (
        <label
          className={`${styles.label} ${isApp && styles.appLabel} ${
            isAuth && styles.authLabel
          } ${isCutOff && styles.decreaseTop}`}
        >
          {label}
        </label>
      )}

      {search && (
        <IonIcon
          style={{ ...searchIconStyle }}
          className={`${styles.icon} ${value ? styles.actionIcon : ""}`}
          icon={value ? closeOutline : searchOutline}
          onClick={handleClear}
        />
      )}

      {type === "password" && value && (
        <IonIcon
          className={`${styles.icon} ${value ? styles.actionIcon : ""}`}
          style={{ padding: "1rem" }}
          icon={value ? (showPassword ? eyeOutline : eyeOffOutline) : ""}
          onClick={() => {
            setShowPassword(!showPassword);
            const el = document.getElementById(inputId);
            if (el) {
              el.focus();
              moveCursor(el);
            }
          }}
        />
      )}
      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
};

export default forwardRef(Input);
