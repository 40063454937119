import { createSelector } from "reselect";

const selectUser = (state) => state.user;

export const selectCurrentUser = createSelector(
  [selectUser],
  (user) => user.currentUser
);

export const selectUserState = createSelector(
  [selectUser],
  (user) => user.state
);

export const selectUserError = createSelector(
  [selectUser],
  (user) => user.error
);

export const selectGuest = createSelector([selectUser], (user) => user.guest);

export const selectIsAgent = createSelector(
  [selectUser],
  (user) => user.isAgent
);
