import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { cameraOutline } from "ionicons/icons";
import DetailsHeader from "../TourDetailsHeader/tour-details-header.module";
import CardContainer from "../../pages/Tours/component/CardContainer/card-container.component";
import PhotosSection from "./component/PhotosSection/photos-section.component";
import styles from "./photos.module.scss";
import { buildAddress } from "../../utils/functions";
import { selectCurrentClient } from "../../redux/client/client.selectors";
import _ from "lodash";

const Photos = ({
  photoItems,
  isOpen,
  setIsOpen,
  tourItem,
  listing,
  handleDelete,
  tour,
}) => {
  const client = useSelector(selectCurrentClient);
  const [photos, setPhotos] = useState();

  useEffect(() => {
    const groupPhotos = () =>
      _.mapValues(_.groupBy(photoItems, (photo) => photo.owner));
    if (photoItems && client) setPhotos(groupPhotos());
  }, [client, photoItems]);

  return client && tourItem ? (
    <CardContainer>
      <DetailsHeader
        title="All photos"
        icon={cameraOutline}
        hasTag={false}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      {isOpen && (
        <div className={styles.photoDetails}>
          <div className={styles.subtitle}>
            {tourItem.agentId === client.id ||
            tourItem.showingAgentId === client.id ||
            tourItem.members.includes(client.id)
              ? `All photos taken for ${buildAddress(
                  listing.address
                )}. by all tour
          attendees.`
              : "Only lead/showing agents can post a photo."}
          </div>

          {(tourItem.agentId === client.id ||
            tourItem.showingAgentId === client.id ||
            tourItem.members.includes(client.id)) && (
            <PhotosSection
              handleDelete={handleDelete}
              isCurrentUser={true}
              photos={photos ? photos[client.id] : []}
              memberData={JSON.parse(tourItem.membersData).find(
                (d) => d.id === client.id
              )}
              tour={tour}
              isApp={false}
            />
          )}

          {photos &&
            Object.keys(photos).map((key) => {
              if (photos[key][0].owner === client.id) {
                return null;
              } else {
                return (
                  <PhotosSection
                    key={key}
                    isCurrentUser={false}
                    photos={photos[key]}
                    memberData={JSON.parse(tourItem.membersData).find(
                      (d) => d.id === photos[key][0].owner
                    )}
                    tour={tour}
                    isApp={false}
                  />
                );
              }
            })}
        </div>
      )}
    </CardContainer>
  ) : null;
};

export default Photos;
