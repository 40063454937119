import Select from "../../Form/Select/select.component";
import withFilters from "../../../HOC/withFilters/with-filters";
import styles from "../filters-menu.module.scss";
import RadioFilter from "../../RadioFilter/radio-filter.component";
import { dates } from "../../../components/Filters/data/filters.data";
import moment from "moment";

const DateFilterItem = ({
  buttonStyle,
  filters: { type, status, minSoldDate, minListDate, maxSoldDate },
  updateMultipleFilters,
  tabName,
  tab,
  setTab,
  disabled,
}) => {
  const handleOnChange = ({ label, value }) => {
    if (status === "A") {
      updateMultipleFilters({
        minListDate: value
          ? moment().subtract(value, "days").format("YYYY-MM-DD")
          : null,
        minSoldDate: null,
        maxSoldDate: null,
      });
    } else {
      updateMultipleFilters({
        minListDate: null,
        minSoldDate: value
          ? value.toString().startsWith("y")
            ? `${value.replace("y", "")}-01-01`
            : moment().subtract(value, "days").format("YYYY-MM-DD")
          : null,
        maxSoldDate:
          value?.toString().startsWith("y") &&
          `${+value.replace("y", "")}-12-31`,
      });
    }
  };

  const getDateValue = () => {
    if (status === "A") {
      if (!minListDate) return "Any time";
      return dates.find(
        (opt) => opt.value === moment(new Date()).diff(minListDate, "days")
      )?.label;
    } else {
      if (minSoldDate && maxSoldDate) {
        return dates.find(
          (opt) => opt.value === `y${minSoldDate.substring(0, 4)}`
        )?.label;
      }
      if (!minSoldDate) return "Any time";
      return dates.find(
        (opt) => opt.value === moment(new Date()).diff(minSoldDate, "days")
      ).label;
    }
  };

  return (
    <div className={styles.item}>
      <Select
        buttonStyle={buttonStyle}
        title="Days on Market"
        open={tab === tabName}
        onClick={() => setTab(tabName)}
        dropdown={false}
        disabled={disabled}
      />
      <RadioFilter
        value={getDateValue()}
        options={dates.filter((opt) => {
          if (!opt.value) return true;
          if (status === "U") return true;
          if (typeof opt.value !== "string") return true;
          return false;
        })}
        open={tab === tabName}
        dropdown={false}
        onClick={handleOnChange}
      />
    </div>
  );
};

export default withFilters(DateFilterItem);
