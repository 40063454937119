import { IonIcon } from "@ionic/react";
import { helpCircleOutline } from "ionicons/icons";
import { userAccount } from "../../../../../data/userAccount";
import styles from "./account-input.module.scss";

const AccountInput = ({
  border = false,
  icon = false,
  label,
  value,
  clickHandler,
  type,
  verified,
  verifyHandler,
  language,
}) => {
  return (
    <div className={`${styles.rows} ${border && styles.border}`}>
      <div className={styles.firstColumn}>
        <div className={styles.label}>{label}</div>
        <div className={styles.value}>
          <div className={styles.title}>{value}</div>

          {icon ? (
            <div className={styles.edit} onClick={clickHandler}>
              <IonIcon icon={helpCircleOutline} />
            </div>
          ) : (
            <>
              {!verified && (
                <div
                  className={styles.verify}
                  onClick={() => verifyHandler(value)}
                >
                  {userAccount[language].verify}
                </div>
              )}
              <div className={styles.edit} onClick={clickHandler}>
                {userAccount[language].edit}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AccountInput;
