import { IonIcon } from "@ionic/react";
import { chevronDownOutline } from "ionicons/icons";
import { ReactComponent as SortIcon } from "../../../../assets/svg/sort.svg";
import styles from "./topbar.module.scss";
import useComponentVisible from "../../../../hooks/useComponentVisible";
import DropdownContainer from "../../../../components/Form/DropdownContainer/dropdown-container.component";

const Topbar = ({ type, setType, sortBy, setSortBy, isAgent = true }) => {
  const { ref, isOpen, setIsOpen } = useComponentVisible(false);

  const changeDirection = () => {
    if (sortBy.direction === "asc") {
      setSortBy({ ...sortBy, direction: "desc" });
    } else {
      setSortBy({ ...sortBy, direction: "asc" });
    }
  };

  const handleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const sort = (value) => {
    if (value !== sortBy.value) {
      setSortBy({ ...sortBy, value });
    }
    setIsOpen(false);
  };

  return (
    <div className={styles.topbar}>
      <div className={styles.category}>
        <div
          key="upcoming"
          className={`${type === "upcoming" && styles.bold}`}
          onClick={() => setType("upcoming")}
        >
          Upcoming tours
        </div>
        <div
          key="past"
          className={`${type === "past" && styles.bold}`}
          onClick={() => setType("past")}
        >
          Past tours
        </div>
        <div
          key="archived"
          className={`${type === "archived" && styles.bold}`}
          onClick={() => setType("archived")}
        >
          Archived tours
        </div>
      </div>

      {isAgent && (
        <div
          className={styles.filter}
          ref={ref}
          style={{ position: "relative" }}
        >
          <div className={styles.title}>Sort by </div>
          <div className={styles.value} onClick={handleDropdown}>
            {sortBy.value}
          </div>
          <IonIcon
            icon={chevronDownOutline}
            className={styles.icon}
            onClick={handleDropdown}
          />
          <DropdownContainer open={isOpen}>
            <div className={styles.item} onClick={() => sort("date")}>
              Date
            </div>
            <div className={styles.item} onClick={() => sort("status")}>
              Status
            </div>
            <div className={styles.item} onClick={() => sort("title")}>
              Title
            </div>
            <div className={styles.item} onClick={() => sort("client")}>
              Client
            </div>
            <div className={styles.item} onClick={() => sort("showing agent")}>
              Showing agent
            </div>
            <div className={styles.item} onClick={() => sort("stops")}>
              Number of stops
            </div>
          </DropdownContainer>

          <SortIcon
            onClick={changeDirection}
            className={`${styles.sorticon} ${
              sortBy.direction === "desc" ? "" : styles.reversedIcon
            }`}
          />
        </div>
      )}
    </div>
  );
};

export default Topbar;
