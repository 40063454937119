import { useState } from "react";
import { removeSearch } from "../../../amplify/graphql.utils";
import Button from "../../Form/Button/button.component";
import styles from "./delete-saved-search-modal.module.scss";

const DeleteSavedSearchModal = ({ close, data: { search, setIsUpdated } }) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    try {
      setLoading(true);
      await removeSearch(search);
      setIsUpdated(true);
      setTimeout(() => {
        close();
      }, 500);
    } catch (err) {
      setError("Something went wrong!");
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.header}>Delete saved search?</div>
      <div className={styles.subtitle}>
        <span>Are you sure you want to delete this saved search?</span>
      </div>

      {error && (
        <div className={styles.error}>
          <span>{error}</span>
        </div>
      )}
      <div className={styles.btns}>
        <Button
          title={"Cancel"}
          type="purple"
          style={{ height: "5rem", width: "24rem" }}
          onClick={close}
        />
        <Button
          title={"Delete search"}
          type="orange"
          style={{ height: "5rem", width: "24rem" }}
          onClick={handleDelete}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default DeleteSavedSearchModal;
