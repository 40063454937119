import { useState, useEffect, useRef, createRef } from "react";
import { useDispatch } from "react-redux";
import Input from "../../../../components/Form/Input/input.component";
import Button from "../../../../components/Form/Button/button.component";
import { ReactComponent as MailIcon } from "../../../../assets/svg/mail-open.svg";
import styles from "./forgot-password.module.scss";
import {
  resetPassword,
  sendForgotPasswordCode,
} from "../../../../amplify/auth.utils";
import { emailSignInStart } from "../../../../redux/user/user.actions";
import { forgotPassword } from "../../../../data/customModal";
import { updateToast } from "../../../../redux/ui/ui.actions";

const ForgotPassword = ({
  language = "en",
  setShowForgotPassModal,
  enteredEmail,
}) => {
  const dispatch = useDispatch();
  const [step, setStep] = useState("enterEmail");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  // const [emailError, setEmailError] = useState("");

  const inputRefs = useRef({
    email: createRef(),
    code: createRef(),
    password: createRef(),
    confirm_password: createRef(),
  });

  const [formData, setFormData] = useState({
    email: "",
    code: "",
    password: "",
    confirm_password: "",
  });

  useEffect(() => {
    const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-+_!@#$%^&*.,?]).{8,}$/;
    if (formData.password !== "" && !re.test(formData.password)) {
      setPasswordError(
        "Must contain minimum 8 characters, 1 number, 1 capital letter, 1 special character"
      );
    } else if (formData.password !== "" && re.test(formData.password)) {
      setPasswordError("");
    }

    if (formData.confirm_password === "" && formData.password === "") {
      setPasswordError("");
    } else if (
      formData.password !== "" &&
      formData.confirm_password !== "" &&
      re.test(formData.password)
    ) {
      if (formData.password !== formData.confirm_password) {
        setPasswordError("Password don't match");
      } else {
        setPasswordError("");
      }
    }
  }, [formData.password, formData.confirm_password]);

  const handleChange = (value, name) => {
    formData.email === "" && setError("");
    inputRefs.current[name].current.validate();
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const sendCode = async () => {
    if (inputRefs.current.email.current.validate(true)) {
      setLoading(true);
      setError("");
      try {
        await sendForgotPasswordCode(formData.email);
        setError("");
        setLoading(false);
        setStep("confirmCode");
      } catch (err) {
        setLoading(false);
        err.code === "UserNotFoundException"
          ? setError("Username / email not found")
          : setError(err.message);
      }
    }
  };

  const changePassword = async () => {
    if (
      inputRefs.current.code.current.validate(true) &&
      inputRefs.current.password.current.validate(true) &&
      inputRefs.current.confirm_password.current.validate(true) &&
      passwordError === ""
    ) {
      setLoading(true);
      setError("");
      const res = await resetPassword(
        formData.email,
        formData.code,
        formData.password
      );
      if (res && res.message) {
        setError(res.message);
      } else {
        dispatch(
          updateToast({
            open: true,
            type: "success",
            message:
              "Password reset successfully. You will be signed in a moment!",
          })
        );
        dispatch(
          emailSignInStart({
            username: formData.email,
            password: formData.password,
          })
        );
        setShowForgotPassModal(false);
      }

      setLoading(false);
    }
  };

  const handleResend = async () => {
    try {
      await sendForgotPasswordCode(formData.email);
      dispatch(
        updateToast({
          open: true,
          type: "success",
          message: "Code sent successfully.",
        })
      );
    } catch (err) {
      dispatch(
        updateToast({ open: true, type: "error", message: err.message })
      );
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {step === "confirmCode" && (
          <div className={styles.iconContainer}>
            <MailIcon className={styles.icon} />
          </div>
        )}

        <div className={styles.subtitle}>
          {step === "enterEmail"
            ? ""
            : step === "confirmCode"
            ? `Check your inbox`
            : `${forgotPassword[language].newPassHeader}`}
        </div>
        <div className={styles.text}>
          {step === "enterEmail" ? (
            <div className={styles.note}>
              {forgotPassword[language].enterEmailNote}
            </div>
          ) : step === "confirmCode" ? (
            <div className={styles.note}>
              Please enter the verification code sent to you.
            </div>
          ) : null}
        </div>
      </div>
      <div className={styles.content}>
        {step === "enterEmail" ? (
          <Input
            mode="email"
            name="email"
            label={forgotPassword[language].emailInput}
            placeholder={forgotPassword[language].emailInput}
            required
            ref={inputRefs.current.email}
            value={formData["email"]}
            onChange={(value, name) => handleChange(value, name)}
            validation="required|email"
            submit={sendCode}
            isApp
            enterkeyhint="go"
          />
        ) : step === "confirmCode" ? (
          <div className={styles.inputs}>
            <Input
              mode="numeric"
              name="code"
              label={forgotPassword[language].codeInput}
              placeholder={forgotPassword[language].codeInput}
              required
              type="number"
              ref={inputRefs.current.code}
              value={formData["code"]}
              onChange={(value, name) => handleChange(value, name)}
              validation="required"
              submit={() => inputRefs.current.password.current.next()}
              isApp
              enterkeyhint="next"
            />
            <div className={styles.passwordPolicy}>
              {forgotPassword[language].passwordPolicy}
            </div>
            <Input
              required
              name="password"
              label={forgotPassword[language].passwordInput}
              placeholder={forgotPassword[language].passwordInput}
              type="password"
              ref={inputRefs.current.password}
              value={formData["password"]}
              onChange={(value, name) => handleChange(value, name)}
              validation="required"
              submit={() => inputRefs.current.confirm_password.current.next()}
              isApp
              enterkeyhint="next"
            />
            <Input
              required
              name="confirm_password"
              label={forgotPassword[language].confirmPassInput}
              placeholder={forgotPassword[language].confirmPassInput}
              type="password"
              ref={inputRefs.current.confirm_password}
              value={formData["confirm_password"]}
              onChange={(value, name) => handleChange(value, name)}
              validation={"required"}
              submit={changePassword}
              isApp
              enterkeyhint="go"
            />
          </div>
        ) : null}
        {error && (
          <div className={styles.error}>
            <span>{error}</span>
          </div>
        )}
        {passwordError && (
          <div className={styles.passError}>
            <span>{passwordError}</span>
          </div>
        )}
      </div>
      <div className={styles.button}>
        {step === "enterEmail" ? (
          <Button
            title={forgotPassword[language].submitbtn}
            type="purple"
            style={{
              width: "90%",
              marginTop: "1rem",
              border: "1px solid #7158ad",
            }}
            onClick={sendCode}
            loading={loading}
          />
        ) : step === "confirmCode" ? (
          <>
            <Button
              title={forgotPassword[language].submitbtn}
              type="purple"
              style={{ width: "90%", border: "1px solid #7158ad" }}
              onClick={changePassword}
              loading={loading}
            />
            <Button
              title={forgotPassword[language].resendbtn}
              type="clear"
              style={{
                color: "#363636",
                marginTop: "0.5rem",
                width: "100%",
              }}
              onClick={handleResend}
            />
          </>
        ) : null}
      </div>
    </div>
  );
};

export default ForgotPassword;
