import { IonIcon, IonSkeletonText } from "@ionic/react";
import { arrowForwardCircleOutline } from "ionicons/icons";
import { numberWithCommas } from "../../../../utils/functions";
import CardContainer from "../../../Tours/component/CardContainer/card-container.component";
import styles from "./overview.module.scss";

const Overview = ({ listing }) => {
  return (
    <CardContainer>
      <div className={styles.overview}>
        <div className={styles.title}>Overview</div>
        <div className={styles.details}>
          <div className={styles.detail}>
            <span>Price</span>
            <span className={styles.bold}>
              {listing ? (
                `$${numberWithCommas(listing.listPrice)}`
              ) : (
                <IonSkeletonText animated />
              )}
            </span>
          </div>
          <div className={styles.detail}>
            <span>Property type</span>
            <span className={styles.bold}>
              {listing ? (
                listing.details.propertyType
              ) : (
                <IonSkeletonText animated />
              )}
            </span>
          </div>
          <div className={styles.detail}>
            <span>Beds</span>
            <span className={styles.bold}>
              {listing ? (
                listing.details.numBedrooms ? (
                  listing.details.numBedrooms
                ) : (
                  "-"
                )
              ) : (
                <IonSkeletonText animated />
              )}
            </span>
          </div>
          <div className={styles.detail}>
            <span>Baths</span>
            <span className={styles.bold}>
              {listing ? (
                listing.details.numBathrooms ? (
                  listing.details.numBathrooms
                ) : (
                  "-"
                )
              ) : (
                <IonSkeletonText animated />
              )}
            </span>
          </div>
          <div className={styles.detail}>
            <span>Parking</span>
            <span className={styles.bold}>
              {listing ? (
                listing.details.numParkingSpaces ? (
                  listing.details.numParkingSpaces
                ) : listing.details.numGarageSpaces ? (
                  listing.details.numGarageSpaces
                ) : (
                  "-"
                )
              ) : (
                <IonSkeletonText animated />
              )}
            </span>
          </div>
          <div className={styles.detail}>
            <span>MLS Number</span>
            <span
              className={styles.bold}
              style={{ cursor: "pointer" }}
              onClick={() =>
                window.open(`/listings/${listing.mlsNumber}`, "_blank")
              }
            >
              <span className={styles.underline}>
                {listing ? listing.mlsNumber : <IonSkeletonText animated />}
              </span>

              <IonIcon
                icon={arrowForwardCircleOutline}
                className={styles.icon}
              />
            </span>
          </div>
          <div className={`${styles.detail} ${styles.lastDetail}`}>
            <span>All details</span>
            <span
              className={styles.bold}
              style={{ cursor: "pointer" }}
              onClick={() =>
                window.open(`/listings/${listing.mlsNumber}`, "_blank")
              }
            >
              <span className={styles.underline}>View full listing</span>
              <IonIcon
                icon={arrowForwardCircleOutline}
                className={styles.icon}
              />
            </span>
          </div>
        </div>
      </div>
    </CardContainer>
  );
};

export default Overview;
