import { useState, useEffect, memo } from "react";
import {
  IonPage,
  IonContent,
  IonLabel,
  IonLoading,
  IonIcon,
} from "@ionic/react";
import { useSelector } from "react-redux";
import AppHeader from "../../components/Header/header.component";
import styles from "./searches.module.scss";
import { IonList, IonItem } from "@ionic/react";
import MobileEditBar from "../../components/MobileEditBar/mobile-edit-bar.component";
import AddSaveSearch from "../../../pages/SavedSearch/component/AddSaveSearch/add-save-search.component";
import { getSearchItems } from "../../../amplify/graphql.utils";
import { API, graphqlOperation } from "aws-amplify";
import {
  onCreateSearch,
  onDeleteSearch,
  onUpdateSearch,
} from "../../../graphql/subscriptions";
import { selectCurrentUser } from "../../../redux/user/user.selectors";
import { schoolOutline } from "ionicons/icons";
import JoinContent from "../../components/CustomModals/Join/join-content.component";

const AppSearchesPage = () => {
  const user = useSelector(selectCurrentUser);

  const [loading, setLoading] = useState(false);
  const [searches, setSearches] = useState();
  const [sortedSearches, setSortedSearches] = useState();
  const [sortBy, setSortBy] = useState({
    value: "date",
    direction: "desc",
  });

  useEffect(() => {
    if (searches && searches.length) {
      const temp = [...searches];
      switch (sortBy.value) {
        case "date":
          temp.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1));
          break;
        case "name":
          temp.sort((a, b) => (a.name > b.name ? 1 : -1));
          break;
        default:
          return;
      }

      sortBy.direction === "desc"
        ? setSortedSearches(temp.reverse())
        : setSortedSearches(temp);
    } else {
      setSortedSearches([]);
    }
  }, [searches, sortBy]);

  const fetchSearches = async () => {
    setLoading(true);
    try {
      const items = await getSearchItems();
      setSearches(items);
    } catch (err) {}
    setLoading(false);
  };

  useEffect(() => {
    let subscriptions = [];
    const subscribe = async () => {
      const update = await API.graphql(
        graphqlOperation(onUpdateSearch, { owner: user.username })
      ).subscribe({
        next: async () => {
          await fetchSearches();
        },
      });
      subscriptions.push(update);
      const remove = await API.graphql(
        graphqlOperation(onDeleteSearch, { owner: user.username })
      ).subscribe({
        next: async () => {
          await fetchSearches();
        },
      });
      subscriptions.push(remove);
      const create = await API.graphql(
        graphqlOperation(onCreateSearch, { owner: user.username })
      ).subscribe({
        next: async () => {
          await fetchSearches();
        },
      });
      subscriptions.push(create);
    };
    if (user) {
      subscribe();

      fetchSearches();
    }

    return () => {
      if (subscriptions.length) {
        subscriptions.forEach((s) => s.unsubscribe());
      }
    };
  }, [user]);

  return (
    <IonPage>
      <AppHeader title="Searches" backHref="/tabs/more" />
      <IonContent>
        {user ? (
          <>
            <IonLoading isOpen={loading} />
            <MobileEditBar
              sortBy={sortBy}
              setSortBy={setSortBy}
              mode="savedSearches"
              disabled={!sortedSearches || !sortedSearches.length}
              hideAdd
            />
            <div className={styles.content}>
              <div className={styles.header}></div>
              <IonList>
                {!loading && sortedSearches && sortedSearches.length > 0 ? (
                  sortedSearches.map((s) => (
                    <IonItem
                      className={styles.search}
                      key={s.id}
                      lines="full"
                      detail
                      routerLink={`/searches/${s.id}`}
                      routerDirection="forward"
                    >
                      {JSON.parse(s.value).schoolMode && (
                        <IonIcon
                          slot="end"
                          className={styles.icon}
                          icon={schoolOutline}
                        />
                      )}
                      <IonLabel className={styles.label}>{s.name}</IonLabel>
                    </IonItem>
                  ))
                ) : !loading ? (
                  <AddSaveSearch isApp />
                ) : null}
              </IonList>
            </div>
          </>
        ) : (
          <JoinContent />
        )}
      </IonContent>
    </IonPage>
  );
};

export default memo(AppSearchesPage);
