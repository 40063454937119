export const userAccount = {
  en: {
    heading: "My profile",
    member: "Member since ",
    currency: "Currency",
    measure: "Units of measure",
    emailNotification: "Notification email",
    phoneNotification: "Notification Phone number",
    notificationStyle: "Notification style",
    fullName: "Full name",
    profileType: "Profile Type",
    password: "Password",
    general: "General settings",
    generalSubHeading: "Customize your operational settings",
    alert: "Alert settings",
    alertSubHeading: "Notifications for favourites and saved searches",
    userId: "User ID",
    userIdMsg: "User ID cannot be modified.",
    deletebtn: "Delete account",
    logoutbtn: "Log out",
    edit: "Edit",
    verify: "Verify",
  },
};
