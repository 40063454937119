import { IonIcon } from "@ionic/react";
import { shareSocialOutline } from "ionicons/icons";
import { capitalize } from "lodash";
import Button from "../../../../../../../../components/Form/Button/button.component";
import { rightSection } from "../../../../../../../../data/detailsPage";
import { getTagTitle } from "../../../../../../../../utils/functions";
import styles from "./new-status.module.scss";

const NewStatus = ({
  type,
  lastStatus,
  listDate,
  listPrice,
  shareListingHandler,
  requestFormHandler,
  offerFormHandler,
  language,
}) => (
  <div className={styles.rightNavbar}>
    <Button
      title={rightSection[language].share}
      onClick={shareListingHandler}
      type="purple"
      style={{
        height: "5rem",
        width: "25.5rem",
        boxShadow: "1px 1px 5px #E9E3F2",
        border: "1px solid #E9E3F2",
        borderRadius: "25px",
        backgroundColor: "white",
        color: "#363636",
        marginBottom: "2rem",
        display: "grid",
        gridTemplateColumns: "max-content auto",
        justifyContent: "start",
      }}
    >
      <IonIcon
        icon={shareSocialOutline}
        style={{
          height: "2.4rem",
          widht: "2.4rem",
          color: "#7158AD",
          marginLeft: "1rem",
        }}
      />
    </Button>
    <div className={styles.info}>
      <div
        className={`${styles.status} ${
          ["Lc", "Sc"].includes(lastStatus) && styles.conditionalTag
        }`}
      >
        {["sale", "lease"].includes(getTagTitle(lastStatus, type).toLowerCase())
          ? `For ${getTagTitle(lastStatus, type)}`
          : capitalize(getTagTitle(lastStatus, type))}
      </div>
      <div className={styles.daysNum}>{listDate}</div>
    </div>
    <div className={styles.price}>{listPrice}</div>
    <Button
      title={rightSection[language].contact}
      type="purple"
      border={true}
      style={{
        height: "5rem",
        width: "25.5rem",
        marginBottom: "2rem",
      }}
      onClick={requestFormHandler}
    />
    {/* <Button
      title={rightSection[language].request}
      type="blue"
      border={true}
      style={{
        height: "5rem",
        width: "25.5rem",
        marginBottom: "2rem",
      }}
      onClick={requestFormHandler}
    /> */}
    <Button
      title={rightSection[language].offer}
      type="blue"
      border={true}
      style={{
        height: "5rem",
        width: "25.5rem",
      }}
      onClick={offerFormHandler}
    />
  </div>
);

export default NewStatus;
