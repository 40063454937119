import ReactDOM from "react-dom";
import { setupConfig } from "@ionic/react";
import App from "./App";
import { Provider } from "react-redux";
import Store from "./redux/store.js";
import rootSaga from "./redux/root-saga";
//@ts-ignore
import { PersistGate } from "redux-persist5/integration/react";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import Amplify from "aws-amplify";
import awsExports from "./aws-exports-custom";
// import awsExports from "./aws-exports-custom-dev";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

const { store, persistor, sagaMiddleware } = Store;

sagaMiddleware.run(rootSaga);

Amplify.configure(awsExports);

setupConfig({ swipeBackEnabled: false });

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={true} persistor={persistor}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <App />
      </MuiPickersUtilsProvider>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

serviceWorkerRegistration.unregister();

reportWebVitals();
