import withFilters from "../../../../../HOC/withFilters/with-filters";
import styles from "./basement-filter.module.scss";
import { IonCheckbox, IonItem, IonLabel, IonList } from "@ionic/react";

const BasementFilter = ({ extraFilters: { basement }, updateExtraFilters }) => {
  const handleOnChange = (value) => {
    if (value === "none") {
      updateExtraFilters({
        basement: {
          any: false,
          sepEntrance: false,
          walkOut: false,
          finished: false,
        },
      });
    } else {
      updateExtraFilters({
        basement: { ...basement, any: false, [value]: !basement[value] },
      });
    }
  };

  const handleAllChange = () => {
    updateExtraFilters({
      basement: {
        any: true,
        sepEntrance: false,
        walkOut: false,
        finished: false,
      },
    });
  };

  return (
    <IonList>
      <IonItem lines="none">
        <IonLabel className={styles.label}>Any</IonLabel>
        <IonCheckbox
          disabled={
            basement.any ||
            (!basement.sepEntrance && !basement.walkOut && !basement.finished)
          }
          onClick={handleAllChange}
          className={styles.checkbox}
          checked={
            basement.any ||
            (!basement.sepEntrance && !basement.walkOut && !basement.finished)
          }
        />
      </IonItem>
      <IonItem lines="none">
        <IonLabel
          className={`${styles.label} ${
            basement.sepEntrance && styles.selected
          }`}
        >
          Seperate entrace
        </IonLabel>
        <IonCheckbox
          onClick={() => handleOnChange("sepEntrance")}
          slot="end"
          value="sepEntrance"
          checked={basement.sepEntrance}
          className={styles.checkbox}
        />
      </IonItem>
      <IonItem lines="none">
        <IonLabel
          className={`${styles.label} ${basement.walkOut && styles.selected}`}
        >
          Walk out
        </IonLabel>
        <IonCheckbox
          onClick={() => handleOnChange("walkOut")}
          slot="end"
          value="walkOut"
          checked={basement.walkOut}
          className={styles.checkbox}
        />
      </IonItem>
      <IonItem lines="none">
        <IonLabel
          className={`${styles.label} ${basement.finished && styles.selected}`}
        >
          Finished
        </IonLabel>
        <IonCheckbox
          onClick={() => handleOnChange("finished")}
          slot="end"
          value="finished"
          checked={basement.finished}
          className={styles.checkbox}
        />
      </IonItem>
      <IonItem lines="none">
        <IonLabel
          className={`${styles.label} ${basement.finished && styles.selected}`}
        >
          None
        </IonLabel>
        <IonCheckbox
          onClick={() => handleOnChange("none")}
          slot="end"
          value="none"
          checked={
            !basement.any &&
            !basement.walkOut &&
            !basement.sepEntrance &&
            !basement.finished
          }
          className={styles.checkbox}
        />
      </IonItem>
    </IonList>
  );
};

export default withFilters(BasementFilter);
