import SignUp from "./component/Signup/sign-up.component";
import SignIn from "./component/Signin/sign-in.component";
import { IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import styles from "./custom-auth-container.module.scss";

const CustomAuthContainer = ({ type, setType, closeModal }) => {
  return (
    <div className={styles.container}>
      <div className={styles.closeIcon} onClick={closeModal}>
        <IonIcon icon={closeOutline} />
      </div>
      <div className={styles.rightSection}>
        {type === "register" && <SignUp redirect={() => setType("login")} />}
        {type === "login" && <SignIn redirect={() => setType("register")} />}
      </div>
    </div>
  );
};

export default CustomAuthContainer;
