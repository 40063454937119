import { VictoryContainer, VictoryPie } from "victory";
import styles from "./chart.module.scss";

const DoughnutChart = ({ label, data, centerLabel, style }) => {
  return (
    <div className={styles.doughnut}>
      <div className={styles.container}>
        {label && <div className={styles.label}>{label}</div>}
        <div className={styles.chartContainer} style={style?.container}>
          {centerLabel && (
            <div className={styles.centerLabel} style={style?.centerLabel}>
              {centerLabel}
            </div>
          )}
          <VictoryPie
            containerComponent={
              <VictoryContainer
                style={{
                  pointerEvents: "auto",
                  userSelect: "auto",
                  touchAction: "auto",
                }}
              />
            }
            data={data}
            innerRadius={100}
            labels={() => null}
            colorScale={["#7158ad", "#e9e3f2"]}
            padding={0}
          />
        </div>
      </div>
    </div>
  );
};
export default DoughnutChart;
