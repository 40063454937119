import { useState, useEffect } from "react";
import NavButton from "./nav-button.component";
import { ReactComponent as ListingIcon } from "../../../../assets/svg/map-view.svg";
import { ReactComponent as FiltersIcon } from "../../../../assets/svg/filters.svg";
import { ReactComponent as FavouritesIcon } from "../../../../assets/svg/star.svg";
import { ReactComponent as SearchesIcon } from "../../../../assets/svg/search.svg";
import { ReactComponent as EyeIcon } from "../../../../assets/svg/eye.svg";
import { ReactComponent as HomeIcon } from "../../../../assets/svg/REA103_Icons-01a_HomeEttie.svg";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUINotifications } from "../../../../redux/ui/ui.selectors";
import styles from "./buttons.module.scss";

const Buttons = ({ collapse, activeFilters, linksExpanded }) => {
  const [dimmed, setDimmed] = useState(false);
  const { pathname } = useLocation();
  const notifications = useSelector(selectUINotifications);

  useEffect(() => {
    const listener = window.addEventListener("resize", () => {
      setDimmed(window.innerHeight < 775 && linksExpanded);
    });

    setDimmed(window.innerHeight < 775 && linksExpanded);

    return () => window.removeEventListener("resize", listener);
  }, [linksExpanded]);
  return (
    <div className={`${dimmed && styles.dimmed}`}>
      <NavButton
        target="/"
        label="home"
        collapse={collapse}
        tab="/"
        dimmed={dimmed}
      >
        <HomeIcon />
      </NavButton>
      <NavButton
        target="/listings"
        label="map"
        collapse={collapse}
        tab="/listings"
        dimmed={dimmed}
      >
        <ListingIcon />
      </NavButton>
      {pathname === "/listings" && (
        <NavButton
          target="/listings"
          label="filters"
          collapse={collapse}
          updates={activeFilters}
          tab="/filters"
          isFilter
          disabled={pathname !== "/listings"}
          dimmed={dimmed}
        >
          <FiltersIcon />
        </NavButton>
      )}
      <NavButton
        target="/favourites"
        label="favourites"
        collapse={collapse}
        updates={notifications.favourites}
        isProtected={true}
        tab="/favourites"
        dimmed={dimmed}
      >
        <FavouritesIcon />
      </NavButton>
      <NavButton
        target="/searches"
        label="my searches"
        collapse={collapse}
        isProtected={true}
        tab="/searches"
        dimmed={dimmed}
      >
        <SearchesIcon />
      </NavButton>
      <NavButton
        target="/tours"
        label={"tours"}
        collapse={collapse}
        isProtected={true}
        tab="/tours"
        dimmed={dimmed}
      >
        <EyeIcon />
      </NavButton>
    </div>
  );
};

export default Buttons;
