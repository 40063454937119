import {
  IonHeader,
  IonToolbar,
  IonButton,
  IonIcon,
  IonTitle,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import styles from "./custom-modal-header.module.scss";
import { chevronBackOutline } from "ionicons/icons";

const CustomModalHeader = ({
  title,
  setShowModal,
  disable,
  hasBackbtn = false,
  saveButton,
  closeLabel = "Close",
  isPreConstruction = false,
}) => {
  const history = useHistory();
  return (
    <IonHeader className={styles.modalHeader}>
      <IonToolbar className={styles.toolbar}>
        {saveButton && (
          <IonButton
            className={`${styles.button}`}
            slot="end"
            fill="clear"
            onClick={() => setShowModal(false)}
          >
            Select
          </IonButton>
        )}
        <IonButton
          className={`${styles.button} ${!hasBackbtn && styles.hidden}`}
          slot="start"
          fill="clear"
          disabled={!hasBackbtn}
          onClick={() => {
            if (isPreConstruction) {
              history.goBack();
              setTimeout(() => {
                setShowModal(false);
              }, 500);
            } else setShowModal(false);
          }}
        >
          <IonIcon icon={chevronBackOutline} />
          Back
        </IonButton>
        {!saveButton && (
          <IonButton
            slot="end"
            fill="clear"
            className={`${styles.button} ${disable && styles.hidden}`}
            disabled={disable}
            onClick={() => setShowModal(false)}
          >
            {closeLabel}
          </IonButton>
        )}

        <IonTitle className={styles.title}>{title}</IonTitle>
      </IonToolbar>
    </IonHeader>
  );
};

export default CustomModalHeader;
