import { useState } from "react";
import { useDispatch } from "react-redux";
import { IonAlert, IonToast } from "@ionic/react";
import { updateName, updatePassword } from "../../../../amplify/auth.utils";
import { checkUserSession } from "../../../../redux/user/user.actions";
import {
  updateClient,
  updateCurrentProfile,
} from "../../../../amplify/graphql.utils";
import { fetchClientStart } from "../../../../redux/client/client.actions";
import "./change-profile-alerts.scss";

const ChangeProfileAlerts = ({
  client,
  clientProfile,
  showProfileAlerts,
  setShowProfileAlerts,
}) => {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);

  let hasError = false;

  //Change given name
  const handleUpdateGivenName = async (givenName) => {
    if (givenName.length > 2) {
      setError("");
      hasError = false;
      try {
        await updateName(givenName, client.familyName, client.id);
        dispatch(checkUserSession(true));
      } catch (error) {
        setError(error.message);
        setShowErrorToast(true);
        hasError = true;
      }
      if (error === "") {
        setShowSuccessToast(true);
        hasError = false;
      }
    } else {
      setError("This field is required and must be at least 2 characters");
      setShowErrorToast(true);
      hasError = true;
    }
  };

  //Change family name
  const handleUpdateFamilyName = async (familyName) => {
    if (familyName.length > 2) {
      setError("");
      hasError = false;
      try {
        await updateName(client.givenName, familyName, client.id);
        dispatch(checkUserSession(true));
      } catch (error) {
        setError(error.message);
        setShowErrorToast(true);
        hasError = true;
      }
      if (error === "") {
        setShowSuccessToast(true);
        hasError = false;
      }
    } else {
      setError("This field is required and must be at least 2 characters");
      setShowErrorToast(true);
      hasError = true;
    }
  };

  //Change password
  const handleUpdatePassword = async (
    current_password,
    new_password,
    repeat_new_password
  ) => {
    if (new_password) {
      const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-+_!@#$%^&*.,?]).{8,}$/;
      if (!re.test(new_password)) {
        setError(
          "Must be minimum 8 characters and contain at least 1 number, 1 capital letter, 1 special character"
        );
        setShowErrorToast(true);
        hasError = true;
      } else if (new_password !== repeat_new_password) {
        setError("Password don't match");
        setShowErrorToast(true);
        hasError = true;
      } else {
        setError("");
        hasError = false;
      }
    } else {
      setError("password required");
      setShowErrorToast(true);
      hasError = true;
    }

    if (error === "" && !hasError) {
      try {
        const res = await updatePassword(current_password, new_password);
        if (res === "SUCCESS") {
          setError("");
          if (error === "") {
            setShowSuccessToast(true);
            hasError = false;
          }
        } else {
          setError("Something went wrong. Please try again.");
          setShowErrorToast(true);
          hasError = true;
        }
      } catch (err) {
        console.log(err);
        setError(err.message);
        setShowErrorToast(true);
        hasError = true;
      }
    }
  };

  //Update profile purpose
  const handleUpdateAccountPurpose = async (options) => {
    setError("");
    const profile = {
      buyingSelling: options.includes("buy"),
      leasingRenting: options.includes("rent"),
      justLooking: options.includes("looking"),
      preConstruction: options.includes("preConstruction"),
    };
    await updateCurrentProfile({
      id: clientProfile.id,
      profile,
    });
    dispatch(fetchClientStart());

    if (error !== "") {
      setShowErrorToast(true);
      hasError = true;
    } else {
      setShowSuccessToast(true);
      hasError = false;
    }
  };

  //Update profile type
  const handleUpdateAccountType = async (option) => {
    setError("");
    const profile = {
      isRealtor: option === "client" ? false : true,
    };
    await updateCurrentProfile({
      id: client.profile.id,
      profile,
    });
    dispatch(fetchClientStart());

    if (error !== "") {
      setShowErrorToast(true);
      hasError = true;
    } else {
      setShowSuccessToast(true);
      hasError = false;
    }
  };

  return (
    <>
      <IonToast
        isOpen={showErrorToast}
        onDidDismiss={() => {
          setError("");
          setShowErrorToast(false);
        }}
        message={error}
        cssClass="errorToast"
        position="top"
        buttons={[
          {
            side: "end",
            icon: "close",
            handler: () => setError(""),
          },
        ]}
        duration={4000}
      />
      <IonToast
        isOpen={showSuccessToast}
        onDidDismiss={() => setShowSuccessToast(false)}
        message="Your account has been updated!"
        cssClass="successToast"
        position="top"
        buttons={[
          {
            side: "end",
            icon: "close",
            handler: () => setError(""),
          },
        ]}
        duration={2000}
      />
      {showProfileAlerts["id"] && (
        <IonAlert
          isOpen={showProfileAlerts.id}
          onDidDismiss={() =>
            setShowProfileAlerts({ ...showProfileAlerts, id: false })
          }
          cssClass="alert"
          header={"What is my user ID?"}
          message={
            "Did you know that to view data on sold properties in some areas, you must register and agree to certain terms of use? Your user ID is the email with which you agreed to these terms."
          }
          buttons={[
            {
              text: "Got it!",
              handler: () => {
                setShowProfileAlerts({ ...showProfileAlerts, id: false });
              },
            },
          ]}
        />
      )}
      {showProfileAlerts["password"] && (
        <IonAlert
          isOpen={showProfileAlerts.password}
          onDidDismiss={() =>
            setShowProfileAlerts({ ...showProfileAlerts, password: false })
          }
          cssClass="alert"
          header={"Change password"}
          message={
            "Please enter you existing and new password to change your password."
          }
          inputs={[
            {
              name: "existing_password",
              type: "password",
              placeholder: "existing password*",
            },
            {
              name: "new_password",
              type: "password",
              placeholder: "new password*",
            },
            {
              name: "repeat_new_password",
              type: "password",
              placeholder: "repeat new password*",
            },
          ]}
          buttons={[
            {
              text: "Cancel",
              role: "cancel",
            },
            {
              text: "Save",
              handler: (e) => {
                handleUpdatePassword(
                  e.existing_password,
                  e.new_password,
                  e.repeat_new_password
                );

                if (hasError) {
                  return false;
                }
              },
            },
          ]}
        />
      )}
      {showProfileAlerts["fName"] && (
        <IonAlert
          isOpen={showProfileAlerts.fName}
          onDidDismiss={() =>
            setShowProfileAlerts({ ...showProfileAlerts, fName: false })
          }
          cssClass="alert"
          header={"Edit profile name"}
          message={"You may change your profile name at any time."}
          inputs={[
            {
              name: "givenName",
              type: "text",
              label: "First name",
              placeholder: "First name",
            },
          ]}
          buttons={[
            {
              text: "Cancel",
              role: "cancel",
            },
            {
              text: "Save",
              handler: (e) => {
                handleUpdateGivenName(e.givenName);
                if (hasError) {
                  return false;
                }
              },
            },
          ]}
        />
      )}
      {showProfileAlerts["lName"] && (
        <IonAlert
          isOpen={showProfileAlerts.lName}
          onDidDismiss={() =>
            setShowProfileAlerts({ ...showProfileAlerts, lName: false })
          }
          cssClass="alert"
          header={"Edit profile last name"}
          message={"You may change your last name at any time."}
          inputs={[
            {
              name: "familyName",
              type: "text",
              label: "Last name",
              placeholder: "Last name",
            },
          ]}
          buttons={[
            {
              text: "Cancel",
              role: "cancel",
            },
            {
              text: "Save",
              handler: (e) => {
                handleUpdateFamilyName(e.familyName);
                if (hasError) {
                  return false;
                }
              },
            },
          ]}
        />
      )}
      {showProfileAlerts["purpose"] && (
        <IonAlert
          isOpen={showProfileAlerts.purpose}
          onDidDismiss={() =>
            setShowProfileAlerts({ ...showProfileAlerts, purpose: false })
          }
          cssClass="alert"
          header={"Edit account purpose"}
          message={
            "Help us get to know you better! Don’t worry, this won’t affect your account performance or search results!"
          }
          inputs={[
            {
              name: "buy",
              type: "checkbox",
              label: "Buying or selling",
              value: "buy",
              checked: clientProfile.buyingSelling,
            },
            {
              name: "rent",
              type: "checkbox",
              label: "Renting or leasing",
              value: "rent",
              checked: clientProfile.leasingRenting,
            },
            {
              name: "preConstruction",
              type: "checkbox",
              label: "Pre-construction",
              value: "preConstruction",
              checked: clientProfile.preConstruction,
            },
            {
              name: "looking",
              type: "checkbox",
              label: "Just looking",
              value: "looking",
              checked: clientProfile.justLooking,
            },
          ]}
          buttons={[
            {
              text: "Cancel",
              role: "cancel",
            },
            {
              text: "Save",
              handler: (e) => {
                handleUpdateAccountPurpose(e);
                if (hasError) {
                  return false;
                }
              },
            },
          ]}
        />
      )}
      {showProfileAlerts["type"] && (
        <IonAlert
          isOpen={showProfileAlerts.type}
          onDidDismiss={() =>
            setShowProfileAlerts({ ...showProfileAlerts, type: false })
          }
          cssClass="alert"
          header={"Edit account type"}
          message={"Are you a registered real estate agent?"}
          inputs={[
            {
              name: "client",
              type: "radio",
              label: "Nope!",
              value: "client",
              checked: !clientProfile.isRealtor,
            },
            {
              name: "Agent",
              type: "radio",
              label: "Yes, I am a realtor",
              value: "agent",
              checked: clientProfile.isRealtor,
            },
          ]}
          buttons={[
            {
              text: "Cancel",
              role: "cancel",
            },
            {
              text: "Save",
              handler: (e) => {
                handleUpdateAccountType(e);
                if (hasError) {
                  return false;
                }
              },
            },
          ]}
        />
      )}
    </>
  );
};

export default ChangeProfileAlerts;
