import { useState, useRef, useEffect } from "react";
import { Storage } from "aws-amplify";
import {
  IonModal,
  IonSlides,
  IonSlide,
  IonIcon,
  IonButton,
  IonLoading,
  createGesture,
  useIonViewWillEnter,
} from "@ionic/react";
import NoImage from "../../../../../../../components/Photos/component/NoImage/no-image.component";
import { closeOutline, trashOutline } from "ionicons/icons";
import styles from "./photo-section.module.scss";

const PhotosSection = ({
  isCurrentUser,
  memberData,
  photos,
  handleDelete,
  disabled,
}) => {
  const [loading, setLoading] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [s3Photos, setS3Photos] = useState([]);
  const [modal, setModal] = useState(undefined);
  const slidesModalRef = useRef();
  const slidesRef = useRef();
  const handleError = (e) => (e.target.hidden = true);
  const SLIDES_OPTIONS = {
    spaceBetween: 20,
    slidesPerView: 1.2,
    initialSlide: 0,
    centeredSlides: true,
    loop: true,
  };

  const handleDoubleTap = async (e) => {
    const swiper = await slidesRef.current.getSwiper();
    if (swiper.zoom.currentScale > 1) {
      swiper.zoom.out();
    } else {
      swiper.zoom.in();
    }
  };

  const handleLoad = async () => {
    const swiper = await slidesRef.current.getSwiper();
    swiper.slideTo(modal + 1);
  };

  useIonViewWillEnter(() => {
    setDeleteMode(false);
  });

  useEffect(() => {
    const fetchPhotos = async () => {
      setLoading(true);
      const urls = await Promise.all(
        photos.map(async (p) => {
          const img = await Storage.get(p.path, {
            download: false,
            level: "public",
            expires: 21600,
          });
          return { src: img, id: p.id, path: p.path };
        })
      );

      setS3Photos(urls);
      setLoading(false);
    };
    if (photos && photos.length > 0) fetchPhotos();
  }, [photos]);

  const handleDrag = (e) => {
    const gesture = createGesture({
      el: e.target,
      gestureName: "my-swipe",
      direction: "y",
      onMove: (event) => {
        if (event.deltaY > 125 || event.deltaY < -125) {
          e.target.style.opacity = 0.35;
          e.target.style.transform = `scale(0.85) translateY(${event.deltaY}px)`;
        } else if (event.deltaY > 100 || event.deltaY < -100) {
          e.target.style.opacity = 0.5;
          e.target.style.transform = `scale(0.90) translateY(${event.deltaY}px)`;
        } else if (event.deltaY > 50 || event.deltaY < -50) {
          e.target.style.opacity = 0.7;
          e.target.style.transform = `scale(0.95) translateY(${event.deltaY}px)`;
        } else {
          e.target.style.opacity = 0.8;
          e.target.style.transform = `scale(1) translateY(${event.deltaY}px)`;
        }

        if (event.deltaY > 150 || event.deltaY < -150) {
          setModal(undefined);
          return;
        }
      },

      onEnd: (event) => {
        if (event.deltaY < 150 || event.deltaY < -150) {
          e.target.style.transform = "";
          e.target.style.opacity = 1;
        }
      },
    });
    gesture.enable();
  };

  return (
    <>
      <div className={styles.photosSection}>
        <>
          <IonLoading isOpen={loading} />
          <IonModal
            ref={slidesModalRef}
            isOpen={modal !== undefined}
            onDidDismiss={() => {
              setModal(undefined);
            }}
            cssClass="modal"
            animated={false}
            backdropDismiss={true}
          >
            <div className={styles.container}>
              {photos && s3Photos.length > 0 ? (
                <IonSlides
                  options={SLIDES_OPTIONS}
                  className={styles.slides}
                  ref={slidesRef}
                  onIonSlideDoubleTap={handleDoubleTap}
                  onIonSlidesDidLoad={handleLoad}
                >
                  {s3Photos.map((img, i) => (
                    <IonSlide key={i} className={styles.slide}>
                      <img
                        key={i}
                        src={img.src}
                        onError={handleError}
                        alt="photos"
                        onClick={(e) => e.stopPropagation()}
                        onLoad={handleDrag}
                      />
                    </IonSlide>
                  ))}
                </IonSlides>
              ) : (
                <NoImage />
              )}

              <div className={styles.closeContainer}>
                <IonButton
                  fill="outline"
                  shape="round"
                  className={styles.btn}
                  onClick={() => setModal(undefined)}
                >
                  <IonIcon icon={closeOutline} />
                  close
                </IonButton>
              </div>
            </div>
          </IonModal>

          {isCurrentUser ? (
            <div className={styles.currentUser}>
              <div className={styles.title}>My photos</div>
              {photos && s3Photos.length > 0 && !disabled && (
                <div
                  className={styles.deleteMode}
                  onClick={() => setDeleteMode(!deleteMode)}
                >
                  {deleteMode ? "Cancel" : "Delete photos"}
                </div>
              )}
            </div>
          ) : (
            <div className={styles.others}>
              <span>{`${memberData.givenName} ${memberData.familyName}`}</span>
              <span className={styles.role}>{memberData.role}</span>
            </div>
          )}
          <div className={styles.images}>
            {photos && s3Photos.length > 0 ? (
              s3Photos.map((img, index) => (
                <span key={index}>
                  <img
                    alt="Tour"
                    src={img.src}
                    className={styles.img}
                    onClick={() => setModal(index + 1)}
                  />
                  {deleteMode && (
                    <IonIcon
                      icon={trashOutline}
                      className={styles.deleteIcon}
                      onClick={() =>
                        handleDelete({ id: img.id, path: img.path })
                      }
                    />
                  )}
                </span>
              ))
            ) : (
              <NoImage />
            )}
          </div>
        </>
      </div>
    </>
  );
};

export default PhotosSection;
