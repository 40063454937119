import { useState, useEffect } from "react";
import { IonSkeletonText } from "@ionic/react";
import { useDispatch } from "react-redux";
import CardContainer from "../../../Tours/component/CardContainer/card-container.component";
import styles from "./showing-details.module.scss";
import { buildAddress, capitalize } from "../../../../utils/functions";
import Rating from "../../../../components/Rating/rating.component";
import withClient from "../../../../HOC/withClient/with-client";
import {
  submitReview,
  updateClientReview,
} from "../../../../amplify/graphql.utils";
import { updateToast } from "../../../../redux/ui/ui.actions";

const ShowingDetails = ({ tour, listing, currentClient }) => {
  const dispatch = useDispatch();
  const [review, setReview] = useState();

  useEffect(() => {
    if (tour && currentClient) {
      setReview(tour.reviews.items.find((r) => r.owner === currentClient.id));
    }
  }, [currentClient, tour]);

  const handleReview = async (rate) => {
    try {
      if (!review) {
        const r = await submitReview({ tourItemId: tour.id, rate });
        setReview(r);
      } else {
        const r = await updateClientReview({
          id: review.id,
          tourItemId: tour.id,
          rate,
        });
        setReview(r);
      }

      dispatch(
        updateToast({
          open: true,
          message: "Review submitted successfully.",
          type: "success",
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(
        updateToast({
          open: true,
          message: "Something went wrong!",
          type: "error",
        })
      );
    }
  };

  return (
    <CardContainer>
      <div className={styles.showingDetails}>
        <div className={styles.title}>Showing details</div>
        <div className={styles.address}>
          <span className={styles.bold}>
            {listing ? (
              buildAddress(listing.address)
            ) : (
              <IonSkeletonText animated />
            )}
          </span>
          <span>
            {listing ? (
              `${listing.address.zip}, ${listing.address.city}`
            ) : (
              <IonSkeletonText animated />
            )}
          </span>
        </div>
        <div className={styles.details}>
          <div>
            <span>Showing time</span>
            <span className={styles.bold}>
              {tour ? (
                `${tour.startTime} - ${tour.endTime}`
              ) : (
                <IonSkeletonText animated />
              )}
            </span>
          </div>
          <div>
            <span>Showing status</span>
            <span className={styles.bold}>
              {tour ? capitalize(tour.status) : <IonSkeletonText animated />}
            </span>
          </div>
        </div>
        <div>
          <div className={styles.agentTitle}>Agent note</div>
          <div className={styles.agentNotes}>
            {tour && tour.showingNote ? (
              tour.showingNote
            ) : (
              <div style={{ textAlign: "center" }}>---</div>
            )}
          </div>
        </div>
        <div className={styles.ratingContainer}>
          <span className={styles.label}>My review</span>

          <Rating review={review} size="lg" handleReview={handleReview} />
        </div>
      </div>
    </CardContainer>
  );
};

export default withClient(ShowingDetails);
