import styles from "./errors.module.scss";
import { useDispatch } from "react-redux";
import Button from "../Form/Button/button.component";
import { IonIcon } from "@ionic/react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ReactComponent as NotFoundSVG } from "../../assets/svg/not-found.svg";
import { ReactComponent as ServerSVG } from "../../assets/svg/server-error.svg";
import { ReactComponent as AccessDeniedSVG } from "../../assets/svg/restricted.svg";
import { arrowBackOutline } from "ionicons/icons";
import ProfileDropdown from "../ProfileDropdown/profile-dropdown.component";
import withClient from "../../HOC/withClient/with-client";
import { openAuthModal } from "../../redux/ui/ui.actions";
import AppHeader from "../../app/components/Header/header.component";

const ErrorPage = ({
  from,
  user,
  status,
  onClick,
  hideHeader = false,
  isApp = false,
  onReload,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const returnHandler = () => history.goBack();

  const signInHandler = () => {
    dispatch(openAuthModal({ current: "login" }));
  };
  const signUpHandler = () => {
    dispatch(openAuthModal({ current: "register" }));
  };

  const reloadHandler = () => history.go(0);

  return isApp ? (
    <div className={styles.appError}>
      <Helmet>
        <title>
          Ettie -{" "}
          {status === "400"
            ? "Something went wrong"
            : status === "404"
            ? "Property not found"
            : status === "401"
            ? "Access denied"
            : ""}
        </title>
      </Helmet>
      <AppHeader
        title={
          status
            ? status === "400"
              ? "Server error"
              : status === "404"
              ? "Property not found"
              : status === "401"
              ? "Not authorized"
              : "Error"
            : "Error"
        }
        hasRightButton={false}
      />
      <div className={styles.content}>
        {status === "401" && (
          <>
            <AccessDeniedSVG className={styles.svg} />
            <div className={styles.title}>Yikes!</div>
            <div className={styles.subtitle}>
              Looks like you aren't signed in to an Ettie account.
            </div>
            <div className={styles.subtitle}>
              Inactive properties like this one can only viewed after you've
              signed in.
            </div>
            <div className={styles.links}>
              <span
                className={styles.link}
                onClick={() => history.push("/signin")}
              >
                Sign in
              </span>{" "}
              or{" "}
              <span
                className={styles.link}
                onClick={() => history.push("/signup")}
              >
                register
              </span>{" "}
              for an account.
            </div>
          </>
        )}
        {status === "404" && (
          <>
            <NotFoundSVG className={styles.svg} />
            <div className={styles.title}>Uh oh!</div>
            <div className={styles.subtitle}>Something's gone missing!</div>
            <div className={styles.subtitle}>
              The page you were looking for isn't here.
            </div>
            <Button type="purple" title="Go back!" onClick={returnHandler} />
          </>
        )}
        {status === "400" && (
          <>
            <ServerSVG className={styles.svg} />
            <div className={styles.title}>Oops!</div>
            <div className={styles.subtitle}>
              Something went wrong with the server.
            </div>
            <div className={styles.subtitle}>
              We apologize and are trying to fix the problem.
            </div>
            <Button
              type="purple"
              title="Try again"
              onClick={onReload ? onReload : reloadHandler}
            />
          </>
        )}
        {status === "offline" && (
          <>
            <ServerSVG className={styles.svg} />
            <div className={styles.title}>Whoops!</div>
            <div className={styles.subtitle}>
              Looks like there is an issue with the internet connection.
            </div>
            <div className={styles.subtitle}>
              Try again when there is a stronger connection. We'll be waiting!
            </div>
            <Button type="purple" title="Try again" onClick={reloadHandler} />
          </>
        )}
      </div>
    </div>
  ) : (
    <div className={styles.error}>
      <Helmet>
        <title>
          Ettie -{" "}
          {status === "400"
            ? "Something went wrong"
            : status === "404"
            ? "Property not found"
            : status === "401"
            ? "Access denied"
            : ""}
        </title>
      </Helmet>

      <div className={styles.header}>
        <div className={styles.btn} onClick={returnHandler}>
          {from && (
            <>
              <IonIcon icon={arrowBackOutline} className={styles.icon} />
              <div>Back</div>
            </>
          )}
        </div>

        <div className={styles.profile}>
          {!hideHeader ? (
            !user ? (
              <div className={styles.btns}>
                <Button
                  onClick={signInHandler}
                  title="Sign in"
                  type="nav"
                  style={{
                    backgroundColor: "transparent",
                    fontWeight: "500",
                    width: "10.8rem",
                    height: "4rem",
                  }}
                />

                <Button
                  onClick={signUpHandler}
                  title="Register"
                  type="nav"
                  style={{
                    border: "1px solid #7158AD",
                    fontWeight: "500",
                    width: "10.8rem",
                    height: "4rem",
                  }}
                />
              </div>
            ) : (
              <ProfileDropdown
                givenName={user.attributes["given_name"]}
                familyName={user.attributes["family_name"]}
              />
            )
          ) : (
            <></>
          )}
        </div>
      </div>

      <div className={`${styles.content} ${hideHeader && styles.fullSize}`}>
        {status === "404" && (
          <>
            <NotFoundSVG className={styles.svg} />
            <div className={styles.title}>Uh oh!</div>
            <div className={styles.subtitle}>Something's gone missing!</div>
            <div className={styles.subtitle}>
              The page you were looking for isn't here.
            </div>
            <Button type="purple" title="Go back!" onClick={returnHandler} />
          </>
        )}

        {status === "400" && (
          <>
            <ServerSVG className={styles.svg} />
            <div className={styles.title}>Oops!</div>
            <div className={styles.subtitle}>
              Something went wrong with the server.
            </div>
            <div className={styles.subtitle}>
              We apologize and are trying to fix the problem.
            </div>
            <Button type="purple" title="Try again" onClick={onClick} />
          </>
        )}

        {status === "401" && (
          <>
            <AccessDeniedSVG className={styles.svg} />
            <div className={styles.title}>Yikes!</div>
            <div className={styles.subtitle}>
              Looks like you aren't signed in to an Ettie account.
            </div>
            <div className={styles.subtitle}>
              Inactive properties like this one can only viewed after you've
              signed in.
            </div>
            <div className={styles.links}>
              <span
                className={styles.link}
                onClick={() => dispatch(openAuthModal({ current: "login" }))}
              >
                Sign in
              </span>{" "}
              or{" "}
              <span
                className={styles.link}
                onClick={() => dispatch(openAuthModal({ current: "register" }))}
              >
                register
              </span>{" "}
              for an account.
            </div>
          </>
        )}

        {status === "offline" && (
          <>
            <ServerSVG className={styles.svg} />
            <div className={styles.title}>Whoops!</div>
            <div className={styles.subtitle}>
              Looks like there is an issue with the internet connection.
            </div>
            <div className={styles.subtitle}>
              Try again when there is a stronger connection. We'll be waiting!
            </div>
            <Button type="purple" title="Try again" onClick={onClick} />
          </>
        )}
      </div>
    </div>
  );
};

export default withClient(ErrorPage);
