import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./listing-card.module.scss";
import {
  IonCard,
  IonCardHeader,
  IonImg,
  IonCardContent,
  IonSpinner,
  IonIcon,
} from "@ionic/react";
import Fade from "react-reveal/Fade";
import withClient from "../../../HOC/withClient/with-client";
import DefaultImage from "../../../assets/img/no-photo.png";
import millify from "millify";
import {
  buildAddress,
  copyToClipboard,
  diffDate,
  getPropertyType,
  getTagTitle,
  isActiveTag,
  numberWithCommas,
  renderCurrencySign,
} from "../../../utils/functions";
import { selectCurrencyRates } from "../../../redux/currency/currency.selectors";
import FavoriteIcon from "../../../components/ListingCard/components/FavoriteIcon/favorite-icon.component";
import ListingIcon from "../../../assets/svg/home.svg";
import LocationIcon from "../../../assets/svg/location-pin.svg";
import ListingTag from "../../../components/ListingCard/components/ListingTag/listing-tag.component";
import {
  arrowDownOutline,
  arrowUpOutline,
  bed,
  checkmarkOutline,
} from "ionicons/icons";
import { fadeAnimation } from "../../../utils/animations";
import { updateToast } from "../../../redux/ui/ui.actions";
import TypeTag from "../../../components/TypeTag/type-tag.component";

const IMAGE_API = "https://cdn.repliers.io";

const ListingCard = ({
  listing,
  currentClient,
  isActiveComparables = false,
  updates,
  route,
  expand = false,
  animated = false,
  reloadFav,
}) => {
  const dispatch = useDispatch();
  const {
    mlsNumber,
    listDate,
    soldDate,
    updatedOn,
    address,
    images,
    type,
    status,
    lastStatus,
    listPrice,
    soldPrice,
    details: {
      propertyType,
      numBedrooms,
      numBedroomsPlus,
      numBathrooms,
      numBathroomsPlus,
      numParkingSpaces,
      numGarageSpaces,
    },
  } = listing;

  const [imgLoading, setImgLoading] = useState(true);
  const now = new Date();
  const rates = useSelector(selectCurrencyRates);

  let currency;
  if (currentClient && rates) {
    if (currentClient.currency !== "cad")
      currency = currentClient.currency.toUpperCase();
  }

  const handleCopyToClipboard = async (e) => {
    console.log(e);
    e.preventDefault();
    e.stopPropagation();

    const res = await copyToClipboard(listing.mlsNumber);
    res &&
      dispatch(
        updateToast({
          open: true,
          type: "success",
          message: "Property MLS number has been copied to clipboard.",
        })
      );
  };

  const renderPrice = () => {
    return currency
      ? soldPrice > 0
        ? `${renderCurrencySign(currency, false)}${millify(
            (+soldPrice / +rates.CAD.S) * +rates[currency].S
          )}`
        : `${renderCurrencySign(currency, false)}${millify(
            (+listPrice / +rates.CAD.S) * +rates[currency].S
          )}`
      : soldPrice > 0
      ? `${renderCurrencySign(currency, false)}${numberWithCommas(soldPrice)}`
      : `${renderCurrencySign(currency, false)}${numberWithCommas(listPrice)}`;
  };

  const renderPriceDiff = () => {
    let diff = soldPrice - listPrice;
    if (currency) diff = (+diff / +rates.CAD.S) * +rates[currency].S;

    if (diff > 0) {
      return (
        <span className={styles.increased}>
          <IonIcon icon={arrowUpOutline} />
          <span className={styles.value}>
            {renderCurrencySign(currency)}
            {millify(diff, { precision: 2 })}
          </span>
          <span className={styles.text}>above asking</span>
        </span>
      );
    }

    if (diff < 0) {
      return (
        <span className={styles.decreased}>
          <IonIcon icon={arrowDownOutline} />
          <span className={styles.value}>
            {renderCurrencySign(currency)}
            {millify(Math.abs(diff), { precision: 2 })}
          </span>
          <span className={styles.text}>below asking</span>
        </span>
      );
    }

    return (
      <span className={styles.asking}>
        <IonIcon icon={checkmarkOutline} />
        <span className={styles.text}>
          {type.toLowerCase() === "sale" ? "sold" : "leased"} at asking
        </span>
      </span>
    );
  };

  const card = (
    <IonCard
      className={expand ? styles.expandedCard : styles.card}
      routerDirection="forward"
      //onClick={() => dispatch(updateSelectedListing(null))}
      routerLink={`/listings/${listing.mlsNumber}${
        route ? `?route=${route}` : ""
      }`}
      routerAnimation={fadeAnimation}
    >
      <IonCardHeader className={styles.header}>
        {updates && <ListingTag updates={updates} isApp={true} />}
        {imgLoading && (
          <IonSpinner name="crescent" className={styles.spinner} />
        )}
        {images[0] ? (
          <IonImg
            src={`${IMAGE_API}/${images[0]}`}
            onIonError={(e) => (e.target.src = DefaultImage)}
            onIonImgDidLoad={() => setImgLoading(false)}
            className={styles.image}
          />
        ) : (
          <IonImg
            src={DefaultImage}
            onIonImgDidLoad={() => setImgLoading(false)}
            className={styles.image}
          />
        )}

        {!expand && (
          <div className={styles.tag}>
            <TypeTag lastStatus={lastStatus} status={status} type={type} />
            <span className={styles.date}>
              {status === "A" || isActiveComparables
                ? diffDate(
                    status,
                    listDate,
                    soldDate ? soldDate : updatedOn,
                    isActiveComparables
                  ).listedValue
                : diffDate(
                    status,
                    listDate,
                    soldDate ? soldDate : updatedOn,
                    isActiveComparables
                  ).soldDate}
            </span>
          </div>
        )}
      </IonCardHeader>
      <IonCardContent className={styles.content}>
        <div className={styles.icons}>
          <FavoriteIcon
            reload={reloadFav}
            listing={listing}
            isApp={true}
            isActiveComparables={isActiveComparables}
          />
        </div>
        <div className={styles.price}>{renderPrice()}</div>
        {["Sld", "Lsd"].includes(lastStatus) && (
          <div className={styles.priceDiff}>{renderPriceDiff()}</div>
        )}
        <div className={styles.address}>
          {expand && (
            <IonIcon src={LocationIcon} className={styles.locationIcon} />
          )}
          {buildAddress(listing.address)}
        </div>
        {expand && (
          <div className={styles.address}>
            <IonIcon src={ListingIcon} className={styles.propertyIcon} />
            {getPropertyType(propertyType)}
          </div>
        )}

        {expand ? (
          <div className={styles.info}>
            <IonIcon icon={bed} className={styles.bedIcon} />
            {numBedrooms && (
              <span className={styles.beds}>
                {numBedrooms}
                {numBedroomsPlus ? ` + ${numBedroomsPlus}` : ""} bed
              </span>
            )}
            {numBathrooms && (
              <span className={styles.baths}>
                {numBathrooms}
                {numBathroomsPlus ? ` + ${numBathroomsPlus}` : ""} bath
              </span>
            )}
            {numParkingSpaces ? (
              <span className={styles.baths}>
                {numParkingSpaces.includes(".")
                  ? numParkingSpaces.split(".")[0]
                  : numParkingSpaces}{" "}
                parking
              </span>
            ) : numGarageSpaces ? (
              <span className={styles.baths}>
                {numGarageSpaces.includes(".")
                  ? numGarageSpaces.split(".")[0]
                  : numGarageSpaces}{" "}
                garage
              </span>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className={styles.info}>
            <span className={styles.type}>{getPropertyType(propertyType)}</span>
            {numBedrooms && (
              <span className={styles.beds}>
                {numBedrooms}
                {numBedroomsPlus ? ` + ${numBedroomsPlus}` : ""} bed
              </span>
            )}
            {numBathrooms && (
              <span className={styles.baths}>
                {numBathrooms}
                {numBathroomsPlus ? ` + ${numBathroomsPlus}` : ""} bath
              </span>
            )}
          </div>
        )}

        {expand && (
          <div className={styles.tag}>
            <span className={styles[isActiveTag(lastStatus)]}>
              {["sale", "lease"].includes(
                getTagTitle(lastStatus, type).toLowerCase()
              )
                ? `for ${getTagTitle(lastStatus, type)}`
                : getTagTitle(lastStatus, type)}
            </span>
            <span className={styles.date}>
              {status === "A"
                ? diffDate(
                    status,
                    listDate,
                    soldDate ? soldDate : updatedOn,
                    isActiveComparables
                  ).label
                : diffDate(
                    status,
                    listDate,
                    soldDate ? soldDate : updatedOn,
                    isActiveComparables
                  ).soldDate}
            </span>
          </div>
        )}

        <div className={styles.mlsContainer}>
          MLS&reg; number:{" "}
          <span onClick={handleCopyToClipboard}>{mlsNumber}</span>
        </div>
      </IonCardContent>
    </IonCard>
  );

  return animated ? (
    <div className={styles.animated}>
      <Fade>{card}</Fade>
    </div>
  ) : (
    card
  );
};

export default withClient(ListingCard);
