import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  IonIcon,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonButton,
} from "@ionic/react";
import IsVisibleTag from "../../../../../components/IsVisibleTag/is-visible-tag.component";
import styles from "./tour-attendees.module.scss";
import { closeCircleOutline, personAddOutline } from "ionicons/icons";
import { selectCurrentClient } from "../../../../../redux/client/client.selectors";
import {
  addClients,
  getAgents,
  getClients,
  updateTour,
} from "../../../../../amplify/graphql.utils";
import {
  clearCurrentTour,
  fetchToursStart,
} from "../../../../../redux/tour/tour.actions";
import { updateToast } from "../../../../../redux/ui/ui.actions";
import SearchableSelect from "../../../Form/SearchableSelect/searchable-select.component";
import { useHistory } from "react-router-dom";

const TourAttendees = ({ tour, setIsOpen, pageRef, onComplete }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [agents, setAgents] = useState();
  const [client, setClient] = useState();
  const [guests, setGuests] = useState([]);
  const agent = useSelector(selectCurrentClient);
  const [clientsList, setClientsList] = useState();
  const [showingAgent, setShowingAgent] = useState();

  useEffect(() => {
    const fetchAgents = async () => setAgents(await getAgents());
    const fetchClinets = async () =>
      setClientsList(await getClients(agent.brokerageId));
    fetchAgents();
    fetchClinets();
  }, [agent]);

  const handleSubmit = async () => {
    try {
      const members = [client.id];
      if (guests.length) {
        guests.forEach((g) => members.push(g.id));
      }

      const membersData = [
        ...JSON.parse(tour.membersData),
        {
          id: client.id,
          givenName: client.givenName,
          familyName: client.familyName,
          email: client.email,
          phone: client.phone,
          role: "Client",
        },
      ];

      if (showingAgent)
        membersData.push({
          id: showingAgent.id,
          givenName: showingAgent.givenName,
          familyName: showingAgent.familyName,
          email: showingAgent.email,
          phone: showingAgent.phone,
          role: "Showing Agent",
        });

      guests.forEach((g) =>
        membersData.push({
          id: g.id,
          givenName: g.givenName,
          familyName: g.familyName,
          email: g.email,
          phone: g.phone,
          role: "Additional Guest",
        })
      );

      await updateTour({
        id: tour.id,
        showingAgentId: showingAgent.id,
        members,
        membersData: JSON.stringify(membersData),
        status: "pending",
      });

      await addClients({
        tourId: tour.id,
        agentId: tour.agent.id,
        clients: members,
        owner: agent.id,
      });
      dispatch(fetchToursStart());
      dispatch(clearCurrentTour());
      onComplete();
      setIsOpen(false);
      history.push(`/agenttours/${tour.id}`);
    } catch (err) {
      dispatch(
        updateToast({
          open: true,
          message: "Something went wrong!",
          type: "error",
        })
      );
    }
  };

  const handleChange = async (val) => {
    setClientsList(await getClients(agent.brokerageId, val));
  };

  return (
    <div className={styles.tourAttendees}>
      <div className={styles.subtitle}>
        Enter your tour information – you can always edit it later. Note that
        some content will only be visible by you, the agent. Once you create a
        tour, you can add and edit showings.
      </div>

      <div className={styles.label}>
        <div className={styles.title}>Tour attendees</div>
        <IsVisibleTag isVisible={true} />
      </div>

      <div className={styles.inputs}>
        <SearchableSelect
          title="Select the main client"
          type="radio"
          parentRef={pageRef}
          items={clientsList && clientsList.filter((c) => !c.deactivated)}
          onChange={handleChange}
          selected={client}
          setSelected={setClient}
          required
          label="Client"
          onSave={(client) => setClient(client)}
        />
        {agent && client && (
          <SearchableSelect
            title="Select additional guests"
            type="checkbox"
            parentRef={pageRef}
            items={clientsList
              .filter((c) => !c.deactivated)
              .filter((c) => c.id !== client.id)}
            selected={guests}
            setSelected={setGuests}
            onChange={handleChange}
            label="Additional guests"
            icon={personAddOutline}
          />
        )}

        {guests.length > 0 && (
          <div className={styles.guests}>
            {guests
              .filter((g) => g !== null)
              .map((g, i) => (
                <div className={styles.chip} key={i}>
                  {g.name}
                  <IonIcon
                    className={styles.icon}
                    icon={closeCircleOutline}
                    onClick={() =>
                      setGuests(guests.filter((guest) => guest.id !== g.id))
                    }
                  />
                </div>
              ))}
          </div>
        )}

        <IonItem className={styles.item} lines="none">
          <IonLabel>Showing agent*</IonLabel>
          <IonSelect
            className={styles.options}
            value={showingAgent}
            title={showingAgent ? showingAgent.name : ""}
            okText="Save"
            cancelText="Cancel"
            onIonChange={(e) => setShowingAgent(e.detail.value)}
          >
            {agents &&
              agents.map((a) => (
                <IonSelectOption key={a.id} value={a}>
                  {a.name}
                </IonSelectOption>
              ))}
          </IonSelect>
        </IonItem>
      </div>

      <IonButton
        shape="round"
        expand="block"
        className={styles.button}
        onClick={handleSubmit}
        disabled={!client || !showingAgent}
      >
        Complete tour setup
      </IonButton>
    </div>
  );
};

export default TourAttendees;
