import ClientActionTypes from "./client.types";

const INITIAL_STATE = {
  currentClient: null,
  isAgent: false,
  loading: { action: null, key: null },
  error: null,
};

const clientReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ClientActionTypes.ADD_FAVOURITE_START:
      return {
        ...state,
        loading: { action: action.type, key: action.payload.mlsNumber },
      };
    case ClientActionTypes.UPDATE_FAVOURITE_START:
      return {
        ...state,
        loading: {
          action: action.type,
          key: action.payload.favourite.mlsNumber,
        },
      };
    case ClientActionTypes.UPDATE_SEARCH_START:
      return {
        ...state,
        loading: { action: action.type, key: action.payload.id },
      };
    case ClientActionTypes.ADD_SEARCH_START:
      return {
        ...state,
        loading: { action: action.type, key: null },
      };
    case ClientActionTypes.FETCH_CLIENT_START:
      return {
        ...state,
        loading: { action: action.type, key: null },
      };
    case ClientActionTypes.REMOVE_FAVOURITE_START:
      return {
        ...state,
        loading: { action: action.type, key: action.payload.mlsNumber },
      };
    case ClientActionTypes.REMOVE_SEARCH_START:
      return {
        ...state,
        loading: { action: action.type, key: action.payload.id },
      };
    case ClientActionTypes.FETCH_CLIENT_SUCCESS:
      return {
        currentClient: action.payload,
        error: null,
        loading: INITIAL_STATE.loading,
      };
    case ClientActionTypes.CLEAR_CLIENT:
      return {
        currentClient: null,
        error: null,
        loading: INITIAL_STATE.loading,
      };
    case ClientActionTypes.FETCH_CLIENT_FAILURE:
    case ClientActionTypes.ADD_FAVOURITE_FAILURE:
    case ClientActionTypes.REMOVE_FAVOURITE_FAILURE:
    case ClientActionTypes.UPDATE_FAVOURITE_FAILURE:
    case ClientActionTypes.ADD_SEARCH_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: INITIAL_STATE.loading,
      };
    default:
      return state;
  }
};

export default clientReducer;
