import { IonRow, IonCol } from "@ionic/react";

import {
  buildAddress,
  numberWithCommas,
} from "../../../../../../utils/functions";
import styles from "./listing-details.module.scss";

const ListingDetails = ({
  listing: {
    mlsNumber,
    address,
    listPrice,
    office: { brokerageName },
    agents,
    details: {
      numBedrooms,
      numBathrooms,
      propertyType,
      numParkingSpaces,
      numGarageSpaces,
    },
    map: { latitude, longitude },
  },
}) => {
  return (
    <div className={styles.details}>
      <IonRow>
        <IonCol>Address</IonCol>
        <IonCol className={styles.bold}>{buildAddress(address)}</IonCol>
      </IonRow>
      <IonRow>
        <IonCol>MLS #</IonCol>

        <IonCol className={`${styles.bold} ${styles.underline}`}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {mlsNumber}
          </div>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>Price</IonCol>
        <IonCol className={styles.bold}>${numberWithCommas(listPrice)}</IonCol>
      </IonRow>
      <IonRow>
        <IonCol>Beds</IonCol>
        <IonCol className={styles.bold}>
          {numBedrooms ? numBedrooms : "-"}
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>Baths</IonCol>
        <IonCol className={styles.bold}>
          {numBathrooms ? numBathrooms : "-"}
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>Parking</IonCol>
        <IonCol className={styles.bold}>
          {numParkingSpaces
            ? numParkingSpaces
            : numGarageSpaces
            ? numGarageSpaces
            : "-"}
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>Property type</IonCol>
        <IonCol className={styles.bold}>{propertyType}</IonCol>
      </IonRow>
      <IonRow>
        <IonCol>Listing brokerage</IonCol>
        <IonCol className={styles.bold}>
          {brokerageName ? brokerageName : "-"}
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>Listing agent</IonCol>
        <IonCol className={styles.bold}>
          {agents ? (agents.length > 0 ? agents[0] : "-") : "-"}
        </IonCol>
      </IonRow>
    </div>
  );
};

export default ListingDetails;
