import { SchoolsActionTypes } from "./schools.types";

const schoolColor = {
  strokeColor: "#dff7f0",
  fillColor: "#1f7a60",
  hoverStrokeColor: "#c24100",
  hoverFillColor: "#ffeee5",
  hoverPinStrokeColor: "white",
  hoverPinFillColor: "#c24100",
};

const INITIAL_STATE = {
  active: false,
  selected: null,
  filterListings: { active: false, schools: [] },
  schools: null,
  loading: false,
  error: undefined,
  filters: {
    elementary: false,
    middle: false,
    high: false,
    public: false,
    catholic: false,
    private: false,
    frenchImmersion: false,
    ib: false,
    ap: false,
    gifted: false,
    sports: false,
    arts: false,
    rate: null,
    showUnrated: false,
    showListingsWithin: false,
  },
};

const schoolsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SchoolsActionTypes.FETCH_SCHOOLS_START:
      return { ...state, loading: true, error: undefined };
    case SchoolsActionTypes.FETCH_SCHOOLS_SUCCESS:
      return {
        ...state,
        schools: action.payload,
        loading: false,
        error: undefined,
      };
    case SchoolsActionTypes.FETCH_SCHOOLS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SchoolsActionTypes.TOGGLE_SCHOOLS:
      return { ...INITIAL_STATE, active: !state.active };
    case SchoolsActionTypes.UPDATE_SELECTED_SCHOOL:
      return { ...state, selected: action.payload };
    case SchoolsActionTypes.TOGGLE_FILTER_LISTINGS:
      if (action.payload) {
        return {
          ...state,
          filterListings: {
            active: true,
            schools: [
              {
                ...action.payload,
                ...schoolColor,
              },
            ],
          },
        };
      }
      return {
        ...state,
        filterListings: { active: false, schools: [] },
      };
    case SchoolsActionTypes.ADD_SCHOOL_BOUNDARY:
      return {
        ...state,
        filterListings: {
          active: true,
          schools: [
            ...state.filterListings.schools,
            {
              ...action.payload,
              ...schoolColor,
            },
          ],
        },
      };
    case SchoolsActionTypes.REMOVE_SCHOOL_BOUNDARY:
      return {
        ...state,
        filterListings: {
          active: true,
          schools: state.filterListings.schools.filter(
            (s) => s.id !== action.payload
          ),
        },
      };
    case SchoolsActionTypes.UPDATE_SCHOOLS_FILTERS:
      return { ...state, filters: { ...state.filters, ...action.payload } };
    default:
      return state;
  }
};

export default schoolsReducer;
