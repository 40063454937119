import { useDispatch, useSelector } from "react-redux";
import { IonIcon } from "@ionic/react";
import { mapOutline, addCircleOutline } from "ionicons/icons";
import { useHistory } from "react-router-dom";
import styles from "./edit-bar.module.scss";
import SortBy from "../SortBy/sort-by.component";
import { toggleFavourites } from "../../redux/map/map.actions";
import { selectDrawMode } from "../../redux/ui/ui.selectors";
import { updateDrawMode } from "../../redux/ui/ui.actions";
import searchIcon from "../../assets/svg/search.svg";
import { selectMapRef } from "../../redux/map/map.selectors";

const EditBar = ({ sortBy, setSortBy, mode, disabled }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const drawMode = useSelector(selectDrawMode);
  const mapRef = useSelector(selectMapRef);

  const handleClick = () => {
    if (disabled) return;
    if (mode === "favourites") {
      if (drawMode.active)
        dispatch(updateDrawMode({ active: false, isSchool: false, ref: null }));
      dispatch(toggleFavourites(true));
    }

    history.push("/listings");
  };
  return (
    <div className={styles.favouriteheader}>
      <div className={styles.add}>
        <div className={styles.addIcon}>
          <IonIcon
            className={styles.icon}
            icon={
              mode === "favourites" && mapRef
                ? mapOutline
                : mode === "savedSearches"
                ? searchIcon
                : null
            }
            style={
              mode === "savedSearches"
                ? {
                    stroke: "#7158ad",
                    strokeWidth: "1.5px",
                  }
                : {}
            }
            onClick={handleClick}
          ></IonIcon>
        </div>
        <div
          onClick={handleClick}
          className={`${styles.label} ${disabled && styles.disabled}`}
        >
          {mode === "favourites" && mapRef
            ? "view favourites on map"
            : mode === "savedSearches"
            ? "add a new saved search"
            : null}
        </div>
      </div>
      <div className={styles.sort}>
        <SortBy sortBy={sortBy} setSortBy={setSortBy} mode={mode} />
      </div>
    </div>
  );
};

export default EditBar;
