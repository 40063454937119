import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import styles from "./left-nav.module.scss";
import { IonIcon } from "@ionic/react";
import { addCircleOutline, personCircleOutline } from "ionicons/icons";
import withFilters from "../../../HOC/withFilters/with-filters";
import Links from "./components/links.component";
import { openModal } from "../../../redux/ui/ui.actions";
import needAgentIcon from "../../../assets/svg/REA103_Icons-01a_NeedAnAgent.svg";
import Buttons from "./components/buttons.component";

const LeftNav = ({ collapse, activeFilters }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [linksExpand, setLinksExpand] = useState(false);
  const [dimmed, setDimmed] = useState(false);
  const InviteHandler = () => {
    dispatch(
      openModal({
        current: "inviteOthers",
      })
    );
  };

  useEffect(() => {
    const listener = window.addEventListener("resize", () => {
      setDimmed(window.innerHeight < 775 && linksExpand);
    });

    setDimmed(window.innerHeight < 775 && linksExpand);

    return () => window.removeEventListener("resize", listener);
  }, [linksExpand]);

  return (
    <div className={styles.nav}>
      <div className={styles.buttons}>
        <Buttons
          collapse={collapse}
          activeFilters={activeFilters}
          linksExpanded={linksExpand}
        />
      </div>

      <div className={styles.footer}>
        <span
          className={`${styles.agentButton} ${
            collapse ? styles.collapse : ""
          } ${dimmed && styles.hide}`}
          onClick={() => history.push("/contact-us")}
        >
          <span className={styles.container}>
            <IonIcon
              src={needAgentIcon}
              className={styles.agentIcon}
              style={collapse ? { width: "2.5rem", height: "2.5rem" } : {}}
            />
            {!collapse && (
              <span className={styles.agentLabel}>get an agent</span>
            )}
          </span>
        </span>

        <span className={styles.links}>
          <Links
            collapse={collapse}
            expand={linksExpand}
            setExpand={setLinksExpand}
          />
        </span>

        <span
          className={`${styles.inviteButton} ${
            collapse ? styles.collapse : ""
          }`}
          onClick={InviteHandler}
        >
          <IonIcon icon={addCircleOutline} className={styles.inviteIcon} />
          {!collapse && (
            <span className={styles.inviteLabel}>invite others</span>
          )}
        </span>
      </div>
    </div>
  );
};

export default withFilters(LeftNav);
