import { IonIcon } from "@ionic/react";
import { lockClosedOutline } from "ionicons/icons";
import styles from "./blur-message.module.scss";

const BlurMessage = ({ signInHandler, signUpHandler }) => {
  return (
    <div className={styles.blurMsg}>
      <div className={styles.icon}>
        <IonIcon icon={lockClosedOutline} />
      </div>
      <div className={styles.msg}>
        Please <span onClick={signUpHandler}>register</span> or{" "}
        <span onClick={signInHandler}> sign in</span> to view this information.
      </div>
    </div>
  );
};

export default BlurMessage;
