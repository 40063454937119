import styles from "./radio.module.scss";

const Radio = ({ selected, ...rest }) => {
  return (
    <div className={styles.container} {...rest}>
      <span className={styles.outerCircle} />
      <span
        className={`${styles.innerCircle} ${selected && styles.selected}`}
      />
    </div>
  );
};

export default Radio;
