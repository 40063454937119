export const userId = {
  en: {
    title: "What is my user ID?",
    info: {
      view: "Did you know that to view data on sold properties in some areas, you must register and agree to certain",
      term: " terms of use",
      email:
        "? Your user ID is the email with which you agreed to these terms.",
    },
    button: "Got it!",
  },
};

export const editName = {
  en: {
    header: "Edit profile name",
    subtitle: "You may change your profile name at any time.",
    fName: "first name",
    lName: "last name",
    button: "Submit",
  },
};

export const editProfileType = {
  en: {
    header: "Edit profile type",
    subtitle:
      "Help us get to know you better! Don’t worry, this won’t affect your account performance or search results!",
    label_1: "I am interested in:",
    buyORsale: "Buying or Selling",
    leaseORrent: "Renting or Leasing",
    preConstruction: "Pre-construction",
    looking: "Just looking",
    label_2: "Are you a registered real estate agent?",
    notAgent: "Nope!",
    isAgent: "Yes, I am a realtor",
    button: "Submit",
  },
};

export const forgotPassword = {
  en: {
    forgotHeader: "Forgot password?",
    checkEmailHeader: "Check your email",
    newPassHeader: "New password",
    enterEmailNote:
      "Enter the email associated with your Ettie account and we will send you instructions.",
    confirmNote: "Enter the code you received in your inbox.",
    emailInput: "email",
    codeInput: "code",
    passwordPolicy:
      " Your new password must contain a mix of at least 8 upper and lowercase characters, a mix of letters and numbers, and a special character.",
    passwordInput: "new password",
    confirmPassInput: "confirm new password",
    submitbtn: "Submit",
    resendbtn: "Resend code",
  },
};

export const changePassword = {
  en: {
    header: "Change Password",
    subtitle_1: "Confirm existing password",
    oldPassword: "current password",
    subtitle_2: "Create new password",
    newPassword: "new password",
    confirmNewPassword: "repeat new password",
    button: "Submit",
  },
};

export const changeCurrency = {
  en: {
    header: "Edit currency",
    subtitle: "Set your currency. This can be changed at any time.",
    currency: "Currency",
    button: "Submit",
  },
};

export const changeUnits = {
  en: {
    header: "Edit units of measure",
    subtitle: "Set your units. This can be changed at any time.",
    units: "Units of measure",
    button: "Submit",
  },
};

export const changeEmail = {
  en: {
    header: "Change your email",
    subtitle: "  Set the email address where you will receive alerts.",
    label: "Current email: ",
    input: "new email",
    button: "Send verification code",
  },
};

export const confirmEmail = {
  en: {
    header: "Check your email",
    subtitle: "Enter the code you received in your inbox.",
    code: "code",
    submitbtn: "Submit",
    resendbtn: "Resend code",
    cancel: "Cancel registration",
  },
};

export const confirmRegistration = {
  en: {
    header: "Almost there",
    subtitle: "Submit this form to get your account ready.",
    label_1: "Terms and conditions",
    agreement: "I agree to the Terms of Use/Privacy Policy*",
    label_2: "I am interested in:",
    buyORsale: "Buying or Selling",
    leaseORrent: "Renting or Leasing",
    preConstruction: "Pre-construction",
    looking: "Just looking",
    label_3: "Are you a registered real estate agent:",
    notAgent: "Nope!",
    isAgent: "Yes, I am a realtor",
    errorMsg:
      " Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy.",
    button: "Submit",
    cancel: "Sign out and continue as a guest",
  },
};

export const welcome = {
  en: {
    header: "Welcome to Ettie!",
    subtitle: "It's wonderful to meet you.",
    note: "Thank you for confirming your account! <br/> By registering, you've unlocked the ability to:",
    home: " see all property details and request a showing",
    favorite:
      "save <a href='/favourites'>favourites</a> and recieve update alerts",
    search: "<a href='/searches'>save your search</a> for quick access",
    watch: "request a <a href='/tours'>tour</a> from an Ettie agent",
    button: "What are we waiting for?",
  },
};

export const join = {
  en: {
    subtitle: "Join the club!",
    note: "Register for a free account to unlock to all features, including sold prices and property histories.",
    home: "see all property details and request a showing",
    favorite: "save favourites and recieve update alerts",
    search: "save your search for quick access",
    watch: "schedule a showing tour with an Ettie agent",
    button: "Let's do it!",
    subButtonLabel: "Already have an account?",
    subButton: "Sign in",
  },
};

export const changePhone = {
  en: {
    header: "Change your phone number",
    subtitle: " Set the phone number where you will receive alerts.",
    label: "Current phone number: ",
    input: "new phone number",
    button: "Send verification code",
  },
};

export const confirmPhone = {
  en: {
    header: "Check your phone",
    subtitle: "Enter the code you received at this number.",
    codeInput: "code",
    nextbtn: "Next",
    resendbtn: "Resend code",
  },
};

export const editNotification = {
  en: {
    header: "Manage alerts",
    subtitle:
      "Setting applies to all favourites and saved searches. <a href='/user'>Add a phone number</a> to receive SMS and push notifications.",
    title_1: "Notify me of changes:",
    instantly: "instantly",
    daily: "daily",
    weekly: "weekly",
    never: "never",
    title_2: "Notify me by:",
    email: "email",
    sms: "SMS",
    push: "app notifications",
    button: "Update alert settings",
  },
};

export const deleteAccountData = {
  en: {
    header: "Are you sure?",
    subtitle:
      "We hate to see you leave! But if you must, enter your password below to permanently delete your account.",
    federatedSubtitle:
      "We hate to see you leave! But if you must, type DELETE below to permanently delete your account.",
    input: "password",
    button_1: "Keep account active",
    button_2: "Delete account",
  },
};

export const deletedAccount = {
  en: {
    header: "Account deleted",
    subtitle:
      'If you want to give us any <a href="/feedback">feedback</a> on how we can do better, please <a href="/contact-us">contact us</a>! We hope to see you again.',
    button: "Make a new account",
    close: "close",
  },
};

export const successfulChange = {
  en: {
    updatedPassword: "Password has been updated",
    updatedName: "Your name has been updated",
    updatedProfileType: "Profile type has been updated",
    updatedCurrency: "Currency has been updated",
    updatedUnits: "Units have been updated",
    updatedEmail: "Email address has been updated",
    updatedPhone: "Phone number has been updated",
    updatedNotification: "Alert settings have been updated",
    savedSearch: "Search has been saved",
    updatedSavedSearch: "Search has been updated",
    requestSent: {
      title: "Thank you!",
      subtitle: "We will get back to you within 24 hours.",
    },
  },
};

export const saveSearch = {
  en: {
    header: "Save search",
    subtitle:
      "Quickly re-load your search parameters later, or set up alerts and receive up tp 100 relevant listings a day per saved search.",
    label_1: "Name your search:",
    nameInput: "name your search",
    label_2: "Alerts for this search",
    notify_1: "Notify me of changes: ",
    notify_2: "Notify me by: ",
    label_3: "Parameters saved for applicable area: ",
    button: "Save search",
    note: "Manage alert style from <a href='/user'>account settings</a>, <a href='/searches'>saved search</a> or <a href='/favourites'>favourites</a> pages.",
  },
};

export const editSavedSearch = {
  en: {
    header: "Edit saved sarch",
    label_1: "Re-name:",
    label_2: "Current alert style",
    notify_1: "Notify me of changes: ",
    notify_2: "Notify me by: ",
    button: "Update saved search",
    note: "Manage alert style from <a href='/user'>account settings</a>, <a href='/searches'>saved search</a> or <a href='/favourites'>favourites</a> pages.",
  },
};

export const requestForm = {
  en: {
    title: "Request form",
    subtitle: "Complete this form to contact the agent or schedule a viewing.",
    contactInfo: "Contact information",
    fName: "First name",
    lName: "Last name",
    phone: "Phone",
    email: "Email",
    reason: "I want to:*",
    inPersonViewing: "request in-person viewing",
    virtualViewing: "request virtual viewing",
    information: "request information",
    message: "Message",
    messagePlaceholder: "I’d like to book an appointment to ...",
    date: "Dates and times I am available:*",
    add: {
      add: "Add ",
      another: "another",
      a: "a",
      time: "time slot",
    },
    submit: "Submit request",
    error: "Something went wrong! Please try again.",
    declare:
      " By submitting this form, I understand Ettie will share my information with registered real estate professionals.",
  },
};

export const shareListing = {
  en: {
    title: "Share this property",
    shared: "Property has been shared",
  },
};
