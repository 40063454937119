import { useState, useEffect, useRef, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "../../../../Form/Button/button.component";
import Input from "../../../../Form/Input/input.component";
import FederatedBtn from "../Signin/component/FederatedBtn/federated-btn.component";
import {
  clearErrors,
  confirmSignUpStart,
  signUpStart,
} from "../../../../../redux/user/user.actions";
import {
  selectUserError,
  selectUserState,
} from "../../../../../redux/user/user.selectors";
import Modal from "../../../../Modal/modal.component";
import { resendConfirmationCode } from "../../../../../amplify/auth.utils";
import Checkbox from "../../../../Form/Checkbox/checkbox-component";
import { Link } from "react-router-dom";
import styles from "./sign-up.module.scss";

const SignUp = ({ redirect }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const error = useSelector(selectUserError);
  const state = useSelector(selectUserState);
  const [passwordError, setPasswordError] = useState("");

  const inputRefs = useRef({
    email: createRef(),
    given_name: createRef(),
    family_name: createRef(),
    password: createRef(),
    confirm_password: createRef(),
  });

  const [formData, setFormData] = useState({
    email: "",
    given_name: "",
    family_name: "",
    password: "",
    confirm_password: "",
    subscribed: true,
  });

  const handleChange = (value, name) => {
    inputRefs.current[name].current.validate();
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubscribe = () => {
    setFormData((prev) => ({ ...prev, subscribed: !prev.subscribed }));
  };

  const submitSignUp = () => {
    let isValid = true;
    for (const field of Object.values(inputRefs.current)) {
      const validationResult = field.current.validate(true);
      if (!validationResult) isValid = false;
    }
    if (isValid && passwordError === "") {
      setLoading(true);
      const { email, given_name, family_name, password, subscribed } = formData;

      dispatch(
        signUpStart({
          username: email,
          given_name,
          family_name,
          password,
          subscribed,
        })
      );
    } else {
      return;
    }
  };

  useEffect(() => {
    const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-+_!@#$%^&*.,?]).{8,}$/;
    if (formData.password !== "" && !re.test(formData.password)) {
      setPasswordError(
        "Must be minimum 8 characters, contain a mix of upper and lower case letters, at least 1 number, and 1 special character."
      );
    } else if (formData.password !== "" && re.test(formData.password)) {
      setPasswordError("");
    }

    if (formData.confirm_password === "" && formData.password === "") {
      setPasswordError("");
    } else if (
      formData.password !== "" &&
      formData.confirm_password !== "" &&
      re.test(formData.password)
    ) {
      if (formData.password !== formData.confirm_password) {
        setPasswordError("Passwords don't match");
      } else {
        setPasswordError("");
      }
    }
  }, [formData.password, formData.confirm_password]);

  useEffect(() => {
    setLoading(false);
  }, [error, state]);

  useEffect(() => {
    dispatch(clearErrors());
  }, [dispatch]);

  return (
    <div className={styles.registerForm}>
      {state === "signedUp" && (
        <Modal
          isOpen={true}
          type="confirmEmail"
          closable={false}
          data={{
            user: { username: formData.email, password: formData.password },
            onSubmit: (code) =>
              dispatch(
                confirmSignUpStart({
                  username: formData.email,
                  password: formData.password,
                  code,
                })
              ),
            onResend: () => resendConfirmationCode(formData.email),
          }}
        />
      )}

      <form onSubmit={(e) => e.preventDefault()}>
        <div className={styles.title}>
          Welcome to Ettie, <span>let’s get you set up.</span>
        </div>
        <FederatedBtn />
        <div>
          <div className={styles.option}>or sign up with your email</div>
          <div className={styles.line}></div>
        </div>
        <div className={styles.inputs}>
          <div className={styles.dual}>
            <Input
              name="given_name"
              label="given name"
              placeholder="given name"
              required
              ref={inputRefs.current.given_name}
              value={formData["given_name"]}
              onChange={(value, name) => handleChange(value, name)}
              validation="required|min:2|regex:^[a-zA-Z\s]*$"
            />
            <Input
              name="family_name"
              label="family name"
              placeholder="family name"
              required
              ref={inputRefs.current.family_name}
              value={formData["family_name"]}
              onChange={(value, name) => handleChange(value, name)}
              validation="required|min:2|regex:^[a-zA-Z\s]*$"
            />
          </div>
          <Input
            name="email"
            label="email"
            placeholder="email"
            mode="email"
            required
            ref={inputRefs.current.email}
            value={formData["email"]}
            onChange={(value, name) => handleChange(value.toLowerCase(), name)}
            validation="required|email"
            autoComplete=""
          />
          <div className={styles.dual}>
            <Input
              name="password"
              label="password"
              placeholder="password"
              required
              type="password"
              ref={inputRefs.current.password}
              value={formData["password"]}
              onChange={(value, name) => handleChange(value, name)}
              // validation={`required|password|confirmPassword:${formData.confirm_password}`}
              validation={`required`}
              autoComplete="new-password"
            />
            <Input
              name="confirm_password"
              label="confirm password"
              placeholder="confirm password"
              required
              type="password"
              ref={inputRefs.current.confirm_password}
              value={formData["confirm_password"]}
              onChange={(value, name) => handleChange(value, name)}
              // validation={`required|confirmPassword:${formData.password}`}
              validation={`required`}
            />
          </div>
        </div>
        {error && (
          <div className={styles.error}>
            <span>{error.message}</span>
          </div>
        )}
        {passwordError && (
          <div className={styles.error}>
            <span>{passwordError}</span>
          </div>
        )}
        <div className={styles.consent}>
          <Checkbox
            checked={formData.subscribed}
            onClick={handleSubscribe}
            value="consent"
            label="I consent to receive communications by email"
          />
          {/* <IonIcon icon={helpCircleOutline} /> */}
        </div>
        <div>
          <Button
            buttonType="submit"
            title="Create account"
            type="purple"
            style={{
              border: "1px solid #7158AD",
              width: "100%",
              height: "5rem",
            }}
            onClick={submitSignUp}
            loading={loading}
          />
        </div>
      </form>
      <div className={styles.login}>
        Already have an account?
        <span className={styles.href} onClick={redirect}>
          Sign in
        </span>
      </div>
      <div className={styles.policy}>
        By creating an account, you are agreeing to our{" "}
        <Link to="/terms">Terms of Service </Link>
        and
        <Link to="/privacy"> Privacy Policy</Link>
      </div>
    </div>
  );
};

export default SignUp;
