import { useState, useEffect, memo, useRef } from "react";
import { useSelector } from "react-redux";
import {
  IonPage,
  IonContent,
  IonModal,
  IonLoading,
  IonRouterLink,
} from "@ionic/react";
import AppHeader from "../../components/Header/header.component";
import { todayOutline, documentTextOutline } from "ionicons/icons";
import styles from "./client-tours.module.scss";
import TourOptionsSegment from "../../components/TourOptionsSegment/tour-options-segment.component";
import TourElement from "../AgentTour/component/TourElement/tour-element.component";
import GeneralNotes from "./component/GeneralNotes/general-notes.component";
import moment from "moment";
import { fetchClientTours } from "../../../amplify/graphql.utils";
import Button from "../../../components/Form/Button/button.component";
import { ReactComponent as Message } from "../../../assets/svg/no-tours.svg";
import RequestForm from "../../components/CustomModals/RequestForm/request-form-component";
import { API, graphqlOperation } from "aws-amplify";
import { onUpdateClientTour } from "../../../graphql/subscriptions";
import { isBeforeOrAfter } from "../../../utils/functions";
import Refresher from "../../components/Refresher/refresher.component";
import { selectCurrentUser } from "../../../redux/user/user.selectors";
import JoinContent from "../../components/CustomModals/Join/join-content.component";
import { selectCurrentClient } from "../../../redux/client/client.selectors";

const itemStyles = {
  style: {
    fontWeight: "600",
    padding: "1.5rem 1rem",
    color: "#363636",
    fontSize: "1.8rem",
  },
  iconStyle: {
    fontWeight: "600",
    height: "3rem",
    width: "3rem",
    color: "#363636",
  },
};

const AppClientTours = () => {
  const pageRef = useRef();
  const client = useSelector(selectCurrentClient);
  const user = useSelector(selectCurrentUser);
  const [showGuide, setShowGuide] = useState();
  const [tours, setTours] = useState();
  const [loading, setLoading] = useState();
  const [tab, setTab] = useState("upcoming");
  const [showGeneralNotes, setShowGeneralNotes] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const fetchTours = async (isBackground) => {
    !isBackground && setLoading(true);
    try {
      const tours = await fetchClientTours();
      setTours(tours.items);
    } catch (err) {}
    !isBackground && setLoading(false);
  };

  useEffect(() => {
    let subscription;
    const subscribe = async () => {
      subscription = await API.graphql(
        graphqlOperation(onUpdateClientTour, {
          clientId: user.username,
        })
      ).subscribe({ next: () => fetchTours() });
    };
    if (user) {
      subscribe();
      fetchTours();
    }

    return () => {
      if (subscription) subscription.unsubscribe();
    };
  }, [user]);

  useEffect(() => {
    if (tours && tours.length === 0) setShowGuide(true);
    if (tours && tours.length > 0) setShowGuide(false);
  }, [client, tours]);

  const handleRefresh = async (ref) => {
    await fetchTours(true);
    ref.complete();
  };

  const getToursByType = () => {
    if (loading) return;
    if (tours && tours.length === 0) {
      return (
        <div className={styles.card}>
          <p>
            You have no active tours right now. When you get a tour started, it
            will appear here.
          </p>
        </div>
      );
    } else if (tours && tours.length > 0) {
      if (tab === "upcoming") {
        const results = tours.filter(
          (t) =>
            !["archived"].includes(t.status) &&
            (!t.date || isBeforeOrAfter(t.date) >= 0)
        );
        return results.length > 0 ? (
          results.map((tour) => (
            <IonRouterLink routerLink={`/clienttours/${tour.id}`} key={tour.id}>
              <TourElement
                icon={todayOutline}
                title={
                  tour.date
                    ? moment(tour.date).format("MMMM Do, YYYY")
                    : tour.title
                }
                name={tour.title}
                style={itemStyles.style}
                iconStyle={itemStyles.iconStyle}
                status={tour.status}
              />
            </IonRouterLink>
          ))
        ) : (
          <div className={styles.card}>
            <p>
              You have no upcoming tours right now. You can ask your agent to
              get a tour started, and it will appear here.
            </p>
          </div>
        );
      } else if (tab === "past") {
        const results = tours.filter((t) => isBeforeOrAfter(t.date) < 0);
        return results.length > 0 ? (
          results.map((tour) => (
            <IonRouterLink routerLink={`/clienttours/${tour.id}`} key={tour.id}>
              <TourElement
                icon={todayOutline}
                title={
                  tour.date
                    ? moment(tour.date).format("MMMM Do, YYYY")
                    : tour.title
                }
                name={tour.title}
                style={itemStyles.style}
                iconStyle={itemStyles.iconStyle}
                status={tour.status}
              />
            </IonRouterLink>
          ))
        ) : (
          <div className={styles.card}>
            <p>
              You have no past tours right now. Only past tours will appear
              here.
            </p>
          </div>
        );
      } else if (tab === "archived") {
        const results = tours.filter((t) => ["archived"].includes(t.status));
        return results.length > 0 ? (
          <>
            <div className={styles.archiveSubtitle}>
              Archived tours are view-only. They can no longer be edited by
              client or agent.
            </div>
            {results.map((tour) => (
              <IonRouterLink
                routerLink={`/clienttours/${tour.id}`}
                key={tour.id}
              >
                <TourElement
                  icon={todayOutline}
                  title={
                    tour.date
                      ? moment(tour.date).format("MMMM Do, YYYY")
                      : tour.title
                  }
                  name={tour.title}
                  style={itemStyles.style}
                  iconStyle={itemStyles.iconStyle}
                  status={tour.status}
                />
              </IonRouterLink>
            ))}
          </>
        ) : (
          <div className={styles.card}>
            <p>
              You have no archived tours right now. Only tours that your lead
              agent has archived will appear here.
            </p>
          </div>
        );
      }
    }
  };

  return (
    <IonPage ref={pageRef}>
      <AppHeader title={user ? "My tours" : "Join"} hasRightButton={false} />
      <IonModal
        swipeToClose={true}
        isOpen={showGeneralNotes}
        presentingElement={pageRef.current}
      >
        <GeneralNotes isApp setIsOpen={setShowGeneralNotes} />
      </IonModal>
      {showModal && (
        <RequestForm
          showModal={showModal}
          setShowModal={setShowModal}
          tourBooking
        />
      )}
      <IonLoading isOpen={loading} />
      <IonContent className={styles.appClientTour}>
        {user ? (
          <>
            <Refresher onRefresh={handleRefresh} />
            {showGuide ? (
              <div className={styles.cardContainer}>
                <div className={styles.message}>
                  <Message />
                  <div className={styles.title}>Oh hello!</div>
                  <div className={styles.text}>
                    As a registered user, you can work together seamlessly with
                    an Ettie agent to build custom showing tours. You and each
                    tour guest can access Ettie's collaborative showing tools to
                    take photos, recordings and notes at each location, as well
                    as rate and compare for review later.
                  </div>
                  {client && !client.requestedTour && (
                    <Button
                      type="purple"
                      border
                      title="Let's get a tour started!"
                      style={{ width: "25rem", fontWeight: "500" }}
                      onClick={() => setShowModal(true)}
                    />
                  )}
                </div>
              </div>
            ) : (
              <>
                <div className={styles.segment}>
                  <TourOptionsSegment tab={tab} setTab={setTab} />
                </div>
                <div className={styles.body}>
                  {getToursByType()}
                  <TourElement
                    icon={documentTextOutline}
                    title="My general notes"
                    style={itemStyles.style}
                    iconStyle={itemStyles.iconStyle}
                    onClick={() => setShowGeneralNotes(true)}
                  />
                </div>
              </>
            )}
          </>
        ) : (
          <JoinContent />
        )}
      </IonContent>
    </IonPage>
  );
};

export default memo(AppClientTours);
