/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onFavouriteUpdateByClientId = /* GraphQL */ `
  subscription OnFavouriteUpdateByClientId($clientId: ID!) {
    onFavouriteUpdateByClientId(clientId: $clientId) {
      id
      repliersID
      mlsNumber
      listing
      listingUpdate
      clientId
      notification
      createdAt
      updatedAt
      owner
      client {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        profilePicture
        agentId
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        subscribed
        currency
        measurement
        notifications
        deviceToken
        profileId
        requestedTour
        tours {
          nextToken
        }
        toursNote
        deactivated
        createdAt
        updatedAt
        profile {
          id
          clientId
          buyingSelling
          leasingRenting
          justLooking
          preConstruction
          isRealtor
          createdAt
          updatedAt
          owner
        }
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        agent {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          brokerageId
          currency
          measurement
          notifications
          deviceToken
          createdAt
          updatedAt
        }
      }
      agent {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        website
        profilePicture
        licenseNumber
        areas
        availability
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        currency
        measurement
        notifications
        deviceToken
        createdAt
        updatedAt
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        clients {
          nextToken
        }
        tours {
          nextToken
        }
      }
    }
  }
`;
export const onFavouriteUpdateByMls = /* GraphQL */ `
  subscription OnFavouriteUpdateByMls($mlsNumber: String!) {
    onFavouriteUpdateByMls(mlsNumber: $mlsNumber) {
      id
      repliersID
      mlsNumber
      listing
      listingUpdate
      clientId
      notification
      createdAt
      updatedAt
      owner
      client {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        profilePicture
        agentId
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        subscribed
        currency
        measurement
        notifications
        deviceToken
        profileId
        requestedTour
        tours {
          nextToken
        }
        toursNote
        deactivated
        createdAt
        updatedAt
        profile {
          id
          clientId
          buyingSelling
          leasingRenting
          justLooking
          preConstruction
          isRealtor
          createdAt
          updatedAt
          owner
        }
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        agent {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          brokerageId
          currency
          measurement
          notifications
          deviceToken
          createdAt
          updatedAt
        }
      }
      agent {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        website
        profilePicture
        licenseNumber
        areas
        availability
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        currency
        measurement
        notifications
        deviceToken
        createdAt
        updatedAt
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        clients {
          nextToken
        }
        tours {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateTourByTourId = /* GraphQL */ `
  subscription OnUpdateTourByTourId($id: ID!) {
    onUpdateTourByTourId(id: $id) {
      id
      title
      creator
      agentId
      showingAgentId
      members
      membersData
      clients {
        items {
          id
          creator
          agentId
          clientId
          tourId
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      privateNote
      generalNote
      tourItems {
        items {
          id
          creator
          agentId
          showingAgentId
          members
          membersData
          tourId
          mlsNumber
          status
          startTime
          endTime
          order
          showingNote
          entryInfo
          createdAt
          updatedAt
        }
        nextToken
      }
      meetupLocation
      meetupDescription
      meetupTime
      startTime
      endTime
      date
      shared
      createdAt
      updatedAt
      agent {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        website
        profilePicture
        licenseNumber
        areas
        availability
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        currency
        measurement
        notifications
        deviceToken
        createdAt
        updatedAt
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        clients {
          nextToken
        }
        tours {
          nextToken
        }
      }
      showingAgent {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        website
        profilePicture
        licenseNumber
        areas
        availability
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        currency
        measurement
        notifications
        deviceToken
        createdAt
        updatedAt
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        clients {
          nextToken
        }
        tours {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateTourItemById = /* GraphQL */ `
  subscription OnUpdateTourItemById($id: ID!) {
    onUpdateTourItemById(id: $id) {
      id
      creator
      agentId
      showingAgentId
      members
      membersData
      tourId
      mlsNumber
      status
      startTime
      endTime
      order
      showingNote
      entryInfo
      reviews {
        items {
          id
          tourItemId
          rate
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      notes {
        items {
          id
          content
          tourItemId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      photos {
        items {
          id
          path
          createdAt
          tourItemId
          updatedAt
          owner
        }
        nextToken
      }
      recordings {
        items {
          id
          path
          createdAt
          tourItemId
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTourItemByTourId = /* GraphQL */ `
  subscription OnUpdateTourItemByTourId($tourId: ID!) {
    onUpdateTourItemByTourId(tourId: $tourId) {
      id
      creator
      agentId
      showingAgentId
      members
      membersData
      tourId
      mlsNumber
      status
      startTime
      endTime
      order
      showingNote
      entryInfo
      reviews {
        items {
          id
          tourItemId
          rate
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      notes {
        items {
          id
          content
          tourItemId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      photos {
        items {
          id
          path
          createdAt
          tourItemId
          updatedAt
          owner
        }
        nextToken
      }
      recordings {
        items {
          id
          path
          createdAt
          tourItemId
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTourItemByTourId = /* GraphQL */ `
  subscription OnCreateTourItemByTourId($tourId: ID!) {
    onCreateTourItemByTourId(tourId: $tourId) {
      id
      creator
      agentId
      showingAgentId
      members
      membersData
      tourId
      mlsNumber
      status
      startTime
      endTime
      order
      showingNote
      entryInfo
      reviews {
        items {
          id
          tourItemId
          rate
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      notes {
        items {
          id
          content
          tourItemId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      photos {
        items {
          id
          path
          createdAt
          tourItemId
          updatedAt
          owner
        }
        nextToken
      }
      recordings {
        items {
          id
          path
          createdAt
          tourItemId
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateClientTourByClientId = /* GraphQL */ `
  subscription OnUpdateClientTourByClientId($clientId: ID!) {
    onUpdateClientTourByClientId(clientId: $clientId) {
      id
      creator
      agentId
      clientId
      tourId
      createdAt
      updatedAt
      client {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        profilePicture
        agentId
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        subscribed
        currency
        measurement
        notifications
        deviceToken
        profileId
        requestedTour
        tours {
          nextToken
        }
        toursNote
        deactivated
        createdAt
        updatedAt
        profile {
          id
          clientId
          buyingSelling
          leasingRenting
          justLooking
          preConstruction
          isRealtor
          createdAt
          updatedAt
          owner
        }
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        agent {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          brokerageId
          currency
          measurement
          notifications
          deviceToken
          createdAt
          updatedAt
        }
      }
      tour {
        id
        title
        creator
        agentId
        showingAgentId
        members
        membersData
        clients {
          nextToken
        }
        status
        privateNote
        generalNote
        tourItems {
          nextToken
        }
        meetupLocation
        meetupDescription
        meetupTime
        startTime
        endTime
        date
        shared
        createdAt
        updatedAt
        agent {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          brokerageId
          currency
          measurement
          notifications
          deviceToken
          createdAt
          updatedAt
        }
        showingAgent {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          brokerageId
          currency
          measurement
          notifications
          deviceToken
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onCreatePhotoByTourItemId = /* GraphQL */ `
  subscription OnCreatePhotoByTourItemId($tourItemId: ID!) {
    onCreatePhotoByTourItemId(tourItemId: $tourItemId) {
      id
      file {
        bucket
        region
        key
      }
      path
      createdAt
      tourItemId
      updatedAt
      owner
    }
  }
`;
export const onCreateRecordingByTourItemId = /* GraphQL */ `
  subscription OnCreateRecordingByTourItemId($tourItemId: ID!) {
    onCreateRecordingByTourItemId(tourItemId: $tourItemId) {
      id
      file {
        bucket
        region
        key
      }
      path
      createdAt
      tourItemId
      updatedAt
      owner
    }
  }
`;
export const onCreateNoteByTourItemId = /* GraphQL */ `
  subscription OnCreateNoteByTourItemId($tourItemId: ID!) {
    onCreateNoteByTourItemId(tourItemId: $tourItemId) {
      id
      content
      tourItemId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeletePhotoByTourItemId = /* GraphQL */ `
  subscription OnDeletePhotoByTourItemId($tourItemId: ID!) {
    onDeletePhotoByTourItemId(tourItemId: $tourItemId) {
      id
      file {
        bucket
        region
        key
      }
      path
      createdAt
      tourItemId
      updatedAt
      owner
    }
  }
`;
export const onDeleteRecordingByTourItemId = /* GraphQL */ `
  subscription OnDeleteRecordingByTourItemId($tourItemId: ID!) {
    onDeleteRecordingByTourItemId(tourItemId: $tourItemId) {
      id
      file {
        bucket
        region
        key
      }
      path
      createdAt
      tourItemId
      updatedAt
      owner
    }
  }
`;
export const onUpdateNoteByTourItemId = /* GraphQL */ `
  subscription OnUpdateNoteByTourItemId($tourItemId: ID!) {
    onUpdateNoteByTourItemId(tourItemId: $tourItemId) {
      id
      content
      tourItemId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateReviewById = /* GraphQL */ `
  subscription OnUpdateReviewById($id: ID!) {
    onUpdateReviewById(id: $id) {
      id
      tourItemId
      rate
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateClientTour = /* GraphQL */ `
  subscription OnCreateClientTour(
    $creator: String
    $agentId: String
    $clientId: String
  ) {
    onCreateClientTour(
      creator: $creator
      agentId: $agentId
      clientId: $clientId
    ) {
      id
      creator
      agentId
      clientId
      tourId
      createdAt
      updatedAt
      client {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        profilePicture
        agentId
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        subscribed
        currency
        measurement
        notifications
        deviceToken
        profileId
        requestedTour
        tours {
          nextToken
        }
        toursNote
        deactivated
        createdAt
        updatedAt
        profile {
          id
          clientId
          buyingSelling
          leasingRenting
          justLooking
          preConstruction
          isRealtor
          createdAt
          updatedAt
          owner
        }
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        agent {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          brokerageId
          currency
          measurement
          notifications
          deviceToken
          createdAt
          updatedAt
        }
      }
      tour {
        id
        title
        creator
        agentId
        showingAgentId
        members
        membersData
        clients {
          nextToken
        }
        status
        privateNote
        generalNote
        tourItems {
          nextToken
        }
        meetupLocation
        meetupDescription
        meetupTime
        startTime
        endTime
        date
        shared
        createdAt
        updatedAt
        agent {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          brokerageId
          currency
          measurement
          notifications
          deviceToken
          createdAt
          updatedAt
        }
        showingAgent {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          brokerageId
          currency
          measurement
          notifications
          deviceToken
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onUpdateClientTour = /* GraphQL */ `
  subscription OnUpdateClientTour(
    $creator: String
    $agentId: String
    $clientId: String
  ) {
    onUpdateClientTour(
      creator: $creator
      agentId: $agentId
      clientId: $clientId
    ) {
      id
      creator
      agentId
      clientId
      tourId
      createdAt
      updatedAt
      client {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        profilePicture
        agentId
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        subscribed
        currency
        measurement
        notifications
        deviceToken
        profileId
        requestedTour
        tours {
          nextToken
        }
        toursNote
        deactivated
        createdAt
        updatedAt
        profile {
          id
          clientId
          buyingSelling
          leasingRenting
          justLooking
          preConstruction
          isRealtor
          createdAt
          updatedAt
          owner
        }
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        agent {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          brokerageId
          currency
          measurement
          notifications
          deviceToken
          createdAt
          updatedAt
        }
      }
      tour {
        id
        title
        creator
        agentId
        showingAgentId
        members
        membersData
        clients {
          nextToken
        }
        status
        privateNote
        generalNote
        tourItems {
          nextToken
        }
        meetupLocation
        meetupDescription
        meetupTime
        startTime
        endTime
        date
        shared
        createdAt
        updatedAt
        agent {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          brokerageId
          currency
          measurement
          notifications
          deviceToken
          createdAt
          updatedAt
        }
        showingAgent {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          brokerageId
          currency
          measurement
          notifications
          deviceToken
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onDeleteClientTour = /* GraphQL */ `
  subscription OnDeleteClientTour(
    $creator: String
    $agentId: String
    $clientId: String
  ) {
    onDeleteClientTour(
      creator: $creator
      agentId: $agentId
      clientId: $clientId
    ) {
      id
      creator
      agentId
      clientId
      tourId
      createdAt
      updatedAt
      client {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        profilePicture
        agentId
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        subscribed
        currency
        measurement
        notifications
        deviceToken
        profileId
        requestedTour
        tours {
          nextToken
        }
        toursNote
        deactivated
        createdAt
        updatedAt
        profile {
          id
          clientId
          buyingSelling
          leasingRenting
          justLooking
          preConstruction
          isRealtor
          createdAt
          updatedAt
          owner
        }
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        agent {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          brokerageId
          currency
          measurement
          notifications
          deviceToken
          createdAt
          updatedAt
        }
      }
      tour {
        id
        title
        creator
        agentId
        showingAgentId
        members
        membersData
        clients {
          nextToken
        }
        status
        privateNote
        generalNote
        tourItems {
          nextToken
        }
        meetupLocation
        meetupDescription
        meetupTime
        startTime
        endTime
        date
        shared
        createdAt
        updatedAt
        agent {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          brokerageId
          currency
          measurement
          notifications
          deviceToken
          createdAt
          updatedAt
        }
        showingAgent {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          brokerageId
          currency
          measurement
          notifications
          deviceToken
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onCreateBrokerage = /* GraphQL */ `
  subscription OnCreateBrokerage {
    onCreateBrokerage {
      id
      name
      createdAt
      updatedAt
      clients {
        items {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          profilePicture
          agentId
          brokerageId
          subscribed
          currency
          measurement
          notifications
          deviceToken
          profileId
          requestedTour
          toursNote
          deactivated
          createdAt
          updatedAt
        }
        nextToken
      }
      owner
      agents {
        items {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          brokerageId
          currency
          measurement
          notifications
          deviceToken
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateBrokerage = /* GraphQL */ `
  subscription OnUpdateBrokerage {
    onUpdateBrokerage {
      id
      name
      createdAt
      updatedAt
      clients {
        items {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          profilePicture
          agentId
          brokerageId
          subscribed
          currency
          measurement
          notifications
          deviceToken
          profileId
          requestedTour
          toursNote
          deactivated
          createdAt
          updatedAt
        }
        nextToken
      }
      owner
      agents {
        items {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          brokerageId
          currency
          measurement
          notifications
          deviceToken
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteBrokerage = /* GraphQL */ `
  subscription OnDeleteBrokerage {
    onDeleteBrokerage {
      id
      name
      createdAt
      updatedAt
      clients {
        items {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          profilePicture
          agentId
          brokerageId
          subscribed
          currency
          measurement
          notifications
          deviceToken
          profileId
          requestedTour
          toursNote
          deactivated
          createdAt
          updatedAt
        }
        nextToken
      }
      owner
      agents {
        items {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          brokerageId
          currency
          measurement
          notifications
          deviceToken
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateTourItem = /* GraphQL */ `
  subscription OnCreateTourItem(
    $creator: String!
    $agentId: String!
    $showingAgentId: String!
    $members: String!
  ) {
    onCreateTourItem(
      creator: $creator
      agentId: $agentId
      showingAgentId: $showingAgentId
      members: $members
    ) {
      id
      creator
      agentId
      showingAgentId
      members
      membersData
      tourId
      mlsNumber
      status
      startTime
      endTime
      order
      showingNote
      entryInfo
      reviews {
        items {
          id
          tourItemId
          rate
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      notes {
        items {
          id
          content
          tourItemId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      photos {
        items {
          id
          path
          createdAt
          tourItemId
          updatedAt
          owner
        }
        nextToken
      }
      recordings {
        items {
          id
          path
          createdAt
          tourItemId
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTourItem = /* GraphQL */ `
  subscription OnUpdateTourItem(
    $creator: String!
    $agentId: String!
    $showingAgentId: String!
    $members: String!
  ) {
    onUpdateTourItem(
      creator: $creator
      agentId: $agentId
      showingAgentId: $showingAgentId
      members: $members
    ) {
      id
      creator
      agentId
      showingAgentId
      members
      membersData
      tourId
      mlsNumber
      status
      startTime
      endTime
      order
      showingNote
      entryInfo
      reviews {
        items {
          id
          tourItemId
          rate
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      notes {
        items {
          id
          content
          tourItemId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      photos {
        items {
          id
          path
          createdAt
          tourItemId
          updatedAt
          owner
        }
        nextToken
      }
      recordings {
        items {
          id
          path
          createdAt
          tourItemId
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTourItem = /* GraphQL */ `
  subscription OnDeleteTourItem(
    $creator: String!
    $agentId: String!
    $showingAgentId: String!
    $members: String!
  ) {
    onDeleteTourItem(
      creator: $creator
      agentId: $agentId
      showingAgentId: $showingAgentId
      members: $members
    ) {
      id
      creator
      agentId
      showingAgentId
      members
      membersData
      tourId
      mlsNumber
      status
      startTime
      endTime
      order
      showingNote
      entryInfo
      reviews {
        items {
          id
          tourItemId
          rate
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      notes {
        items {
          id
          content
          tourItemId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      photos {
        items {
          id
          path
          createdAt
          tourItemId
          updatedAt
          owner
        }
        nextToken
      }
      recordings {
        items {
          id
          path
          createdAt
          tourItemId
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateReview = /* GraphQL */ `
  subscription OnCreateReview {
    onCreateReview {
      id
      tourItemId
      rate
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateReview = /* GraphQL */ `
  subscription OnUpdateReview {
    onUpdateReview {
      id
      tourItemId
      rate
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteReview = /* GraphQL */ `
  subscription OnDeleteReview {
    onDeleteReview {
      id
      tourItemId
      rate
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateNote = /* GraphQL */ `
  subscription OnCreateNote {
    onCreateNote {
      id
      content
      tourItemId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateNote = /* GraphQL */ `
  subscription OnUpdateNote {
    onUpdateNote {
      id
      content
      tourItemId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteNote = /* GraphQL */ `
  subscription OnDeleteNote {
    onDeleteNote {
      id
      content
      tourItemId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreatePhoto = /* GraphQL */ `
  subscription OnCreatePhoto {
    onCreatePhoto {
      id
      file {
        bucket
        region
        key
      }
      path
      createdAt
      tourItemId
      updatedAt
      owner
    }
  }
`;
export const onUpdatePhoto = /* GraphQL */ `
  subscription OnUpdatePhoto {
    onUpdatePhoto {
      id
      file {
        bucket
        region
        key
      }
      path
      createdAt
      tourItemId
      updatedAt
      owner
    }
  }
`;
export const onDeletePhoto = /* GraphQL */ `
  subscription OnDeletePhoto {
    onDeletePhoto {
      id
      file {
        bucket
        region
        key
      }
      path
      createdAt
      tourItemId
      updatedAt
      owner
    }
  }
`;
export const onCreateRecording = /* GraphQL */ `
  subscription OnCreateRecording {
    onCreateRecording {
      id
      file {
        bucket
        region
        key
      }
      path
      createdAt
      tourItemId
      updatedAt
      owner
    }
  }
`;
export const onUpdateRecording = /* GraphQL */ `
  subscription OnUpdateRecording {
    onUpdateRecording {
      id
      file {
        bucket
        region
        key
      }
      path
      createdAt
      tourItemId
      updatedAt
      owner
    }
  }
`;
export const onDeleteRecording = /* GraphQL */ `
  subscription OnDeleteRecording {
    onDeleteRecording {
      id
      file {
        bucket
        region
        key
      }
      path
      createdAt
      tourItemId
      updatedAt
      owner
    }
  }
`;
export const onCreateFeatured = /* GraphQL */ `
  subscription OnCreateFeatured {
    onCreateFeatured {
      id
      title
      subtitle
      filters
      isNearby
      isFavourite
      isSchool
      order
      status
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFeatured = /* GraphQL */ `
  subscription OnUpdateFeatured {
    onUpdateFeatured {
      id
      title
      subtitle
      filters
      isNearby
      isFavourite
      isSchool
      order
      status
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFeatured = /* GraphQL */ `
  subscription OnDeleteFeatured {
    onDeleteFeatured {
      id
      title
      subtitle
      filters
      isNearby
      isFavourite
      isSchool
      order
      status
      createdAt
      updatedAt
    }
  }
`;
export const onCreateHomeCard = /* GraphQL */ `
  subscription OnCreateHomeCard {
    onCreateHomeCard {
      id
      title
      text
      image
      actionLabel
      actionLink
      inAppAction
      order
      status
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateHomeCard = /* GraphQL */ `
  subscription OnUpdateHomeCard {
    onUpdateHomeCard {
      id
      title
      text
      image
      actionLabel
      actionLink
      inAppAction
      order
      status
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteHomeCard = /* GraphQL */ `
  subscription OnDeleteHomeCard {
    onDeleteHomeCard {
      id
      title
      text
      image
      actionLabel
      actionLink
      inAppAction
      order
      status
      createdAt
      updatedAt
    }
  }
`;
export const onCreateProfile = /* GraphQL */ `
  subscription OnCreateProfile($owner: String) {
    onCreateProfile(owner: $owner) {
      id
      clientId
      buyingSelling
      leasingRenting
      justLooking
      preConstruction
      isRealtor
      createdAt
      updatedAt
      owner
      client {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        profilePicture
        agentId
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        subscribed
        currency
        measurement
        notifications
        deviceToken
        profileId
        requestedTour
        tours {
          nextToken
        }
        toursNote
        deactivated
        createdAt
        updatedAt
        profile {
          id
          clientId
          buyingSelling
          leasingRenting
          justLooking
          preConstruction
          isRealtor
          createdAt
          updatedAt
          owner
        }
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        agent {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          brokerageId
          currency
          measurement
          notifications
          deviceToken
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onUpdateProfile = /* GraphQL */ `
  subscription OnUpdateProfile($owner: String) {
    onUpdateProfile(owner: $owner) {
      id
      clientId
      buyingSelling
      leasingRenting
      justLooking
      preConstruction
      isRealtor
      createdAt
      updatedAt
      owner
      client {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        profilePicture
        agentId
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        subscribed
        currency
        measurement
        notifications
        deviceToken
        profileId
        requestedTour
        tours {
          nextToken
        }
        toursNote
        deactivated
        createdAt
        updatedAt
        profile {
          id
          clientId
          buyingSelling
          leasingRenting
          justLooking
          preConstruction
          isRealtor
          createdAt
          updatedAt
          owner
        }
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        agent {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          brokerageId
          currency
          measurement
          notifications
          deviceToken
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onDeleteProfile = /* GraphQL */ `
  subscription OnDeleteProfile($owner: String) {
    onDeleteProfile(owner: $owner) {
      id
      clientId
      buyingSelling
      leasingRenting
      justLooking
      preConstruction
      isRealtor
      createdAt
      updatedAt
      owner
      client {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        profilePicture
        agentId
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        subscribed
        currency
        measurement
        notifications
        deviceToken
        profileId
        requestedTour
        tours {
          nextToken
        }
        toursNote
        deactivated
        createdAt
        updatedAt
        profile {
          id
          clientId
          buyingSelling
          leasingRenting
          justLooking
          preConstruction
          isRealtor
          createdAt
          updatedAt
          owner
        }
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        agent {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          brokerageId
          currency
          measurement
          notifications
          deviceToken
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onCreateFavourite = /* GraphQL */ `
  subscription OnCreateFavourite($owner: String) {
    onCreateFavourite(owner: $owner) {
      id
      repliersID
      mlsNumber
      listing
      listingUpdate
      clientId
      notification
      createdAt
      updatedAt
      owner
      client {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        profilePicture
        agentId
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        subscribed
        currency
        measurement
        notifications
        deviceToken
        profileId
        requestedTour
        tours {
          nextToken
        }
        toursNote
        deactivated
        createdAt
        updatedAt
        profile {
          id
          clientId
          buyingSelling
          leasingRenting
          justLooking
          preConstruction
          isRealtor
          createdAt
          updatedAt
          owner
        }
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        agent {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          brokerageId
          currency
          measurement
          notifications
          deviceToken
          createdAt
          updatedAt
        }
      }
      agent {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        website
        profilePicture
        licenseNumber
        areas
        availability
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        currency
        measurement
        notifications
        deviceToken
        createdAt
        updatedAt
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        clients {
          nextToken
        }
        tours {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateFavourite = /* GraphQL */ `
  subscription OnUpdateFavourite($owner: String) {
    onUpdateFavourite(owner: $owner) {
      id
      repliersID
      mlsNumber
      listing
      listingUpdate
      clientId
      notification
      createdAt
      updatedAt
      owner
      client {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        profilePicture
        agentId
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        subscribed
        currency
        measurement
        notifications
        deviceToken
        profileId
        requestedTour
        tours {
          nextToken
        }
        toursNote
        deactivated
        createdAt
        updatedAt
        profile {
          id
          clientId
          buyingSelling
          leasingRenting
          justLooking
          preConstruction
          isRealtor
          createdAt
          updatedAt
          owner
        }
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        agent {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          brokerageId
          currency
          measurement
          notifications
          deviceToken
          createdAt
          updatedAt
        }
      }
      agent {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        website
        profilePicture
        licenseNumber
        areas
        availability
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        currency
        measurement
        notifications
        deviceToken
        createdAt
        updatedAt
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        clients {
          nextToken
        }
        tours {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteFavourite = /* GraphQL */ `
  subscription OnDeleteFavourite($owner: String) {
    onDeleteFavourite(owner: $owner) {
      id
      repliersID
      mlsNumber
      listing
      listingUpdate
      clientId
      notification
      createdAt
      updatedAt
      owner
      client {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        profilePicture
        agentId
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        subscribed
        currency
        measurement
        notifications
        deviceToken
        profileId
        requestedTour
        tours {
          nextToken
        }
        toursNote
        deactivated
        createdAt
        updatedAt
        profile {
          id
          clientId
          buyingSelling
          leasingRenting
          justLooking
          preConstruction
          isRealtor
          createdAt
          updatedAt
          owner
        }
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        agent {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          brokerageId
          currency
          measurement
          notifications
          deviceToken
          createdAt
          updatedAt
        }
      }
      agent {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        website
        profilePicture
        licenseNumber
        areas
        availability
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        currency
        measurement
        notifications
        deviceToken
        createdAt
        updatedAt
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        clients {
          nextToken
        }
        tours {
          nextToken
        }
      }
    }
  }
`;
export const onCreateSearch = /* GraphQL */ `
  subscription OnCreateSearch($owner: String) {
    onCreateSearch(owner: $owner) {
      id
      name
      repliersID
      value
      clientId
      notification
      createdAt
      updatedAt
      owner
      client {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        profilePicture
        agentId
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        subscribed
        currency
        measurement
        notifications
        deviceToken
        profileId
        requestedTour
        tours {
          nextToken
        }
        toursNote
        deactivated
        createdAt
        updatedAt
        profile {
          id
          clientId
          buyingSelling
          leasingRenting
          justLooking
          preConstruction
          isRealtor
          createdAt
          updatedAt
          owner
        }
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        agent {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          brokerageId
          currency
          measurement
          notifications
          deviceToken
          createdAt
          updatedAt
        }
      }
      agent {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        website
        profilePicture
        licenseNumber
        areas
        availability
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        currency
        measurement
        notifications
        deviceToken
        createdAt
        updatedAt
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        clients {
          nextToken
        }
        tours {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateSearch = /* GraphQL */ `
  subscription OnUpdateSearch($owner: String) {
    onUpdateSearch(owner: $owner) {
      id
      name
      repliersID
      value
      clientId
      notification
      createdAt
      updatedAt
      owner
      client {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        profilePicture
        agentId
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        subscribed
        currency
        measurement
        notifications
        deviceToken
        profileId
        requestedTour
        tours {
          nextToken
        }
        toursNote
        deactivated
        createdAt
        updatedAt
        profile {
          id
          clientId
          buyingSelling
          leasingRenting
          justLooking
          preConstruction
          isRealtor
          createdAt
          updatedAt
          owner
        }
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        agent {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          brokerageId
          currency
          measurement
          notifications
          deviceToken
          createdAt
          updatedAt
        }
      }
      agent {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        website
        profilePicture
        licenseNumber
        areas
        availability
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        currency
        measurement
        notifications
        deviceToken
        createdAt
        updatedAt
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        clients {
          nextToken
        }
        tours {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteSearch = /* GraphQL */ `
  subscription OnDeleteSearch($owner: String) {
    onDeleteSearch(owner: $owner) {
      id
      name
      repliersID
      value
      clientId
      notification
      createdAt
      updatedAt
      owner
      client {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        profilePicture
        agentId
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        subscribed
        currency
        measurement
        notifications
        deviceToken
        profileId
        requestedTour
        tours {
          nextToken
        }
        toursNote
        deactivated
        createdAt
        updatedAt
        profile {
          id
          clientId
          buyingSelling
          leasingRenting
          justLooking
          preConstruction
          isRealtor
          createdAt
          updatedAt
          owner
        }
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        agent {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          brokerageId
          currency
          measurement
          notifications
          deviceToken
          createdAt
          updatedAt
        }
      }
      agent {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        website
        profilePicture
        licenseNumber
        areas
        availability
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        currency
        measurement
        notifications
        deviceToken
        createdAt
        updatedAt
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        clients {
          nextToken
        }
        tours {
          nextToken
        }
      }
    }
  }
`;
export const onCreateClient = /* GraphQL */ `
  subscription OnCreateClient($id: String) {
    onCreateClient(id: $id) {
      id
      repliersID
      givenName
      familyName
      name
      email
      phone
      profilePicture
      agentId
      brokerage {
        id
        name
        createdAt
        updatedAt
        clients {
          nextToken
        }
        owner
        agents {
          nextToken
        }
      }
      brokerageId
      subscribed
      currency
      measurement
      notifications
      deviceToken
      profileId
      requestedTour
      tours {
        items {
          id
          creator
          agentId
          clientId
          tourId
          createdAt
          updatedAt
        }
        nextToken
      }
      toursNote
      deactivated
      createdAt
      updatedAt
      profile {
        id
        clientId
        buyingSelling
        leasingRenting
        justLooking
        preConstruction
        isRealtor
        createdAt
        updatedAt
        owner
        client {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          profilePicture
          agentId
          brokerageId
          subscribed
          currency
          measurement
          notifications
          deviceToken
          profileId
          requestedTour
          toursNote
          deactivated
          createdAt
          updatedAt
        }
      }
      favourites {
        items {
          id
          repliersID
          mlsNumber
          listing
          listingUpdate
          clientId
          notification
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      searches {
        items {
          id
          name
          repliersID
          value
          clientId
          notification
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      agent {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        website
        profilePicture
        licenseNumber
        areas
        availability
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        currency
        measurement
        notifications
        deviceToken
        createdAt
        updatedAt
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        clients {
          nextToken
        }
        tours {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateClient = /* GraphQL */ `
  subscription OnUpdateClient($id: String) {
    onUpdateClient(id: $id) {
      id
      repliersID
      givenName
      familyName
      name
      email
      phone
      profilePicture
      agentId
      brokerage {
        id
        name
        createdAt
        updatedAt
        clients {
          nextToken
        }
        owner
        agents {
          nextToken
        }
      }
      brokerageId
      subscribed
      currency
      measurement
      notifications
      deviceToken
      profileId
      requestedTour
      tours {
        items {
          id
          creator
          agentId
          clientId
          tourId
          createdAt
          updatedAt
        }
        nextToken
      }
      toursNote
      deactivated
      createdAt
      updatedAt
      profile {
        id
        clientId
        buyingSelling
        leasingRenting
        justLooking
        preConstruction
        isRealtor
        createdAt
        updatedAt
        owner
        client {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          profilePicture
          agentId
          brokerageId
          subscribed
          currency
          measurement
          notifications
          deviceToken
          profileId
          requestedTour
          toursNote
          deactivated
          createdAt
          updatedAt
        }
      }
      favourites {
        items {
          id
          repliersID
          mlsNumber
          listing
          listingUpdate
          clientId
          notification
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      searches {
        items {
          id
          name
          repliersID
          value
          clientId
          notification
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      agent {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        website
        profilePicture
        licenseNumber
        areas
        availability
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        currency
        measurement
        notifications
        deviceToken
        createdAt
        updatedAt
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        clients {
          nextToken
        }
        tours {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteClient = /* GraphQL */ `
  subscription OnDeleteClient($id: String) {
    onDeleteClient(id: $id) {
      id
      repliersID
      givenName
      familyName
      name
      email
      phone
      profilePicture
      agentId
      brokerage {
        id
        name
        createdAt
        updatedAt
        clients {
          nextToken
        }
        owner
        agents {
          nextToken
        }
      }
      brokerageId
      subscribed
      currency
      measurement
      notifications
      deviceToken
      profileId
      requestedTour
      tours {
        items {
          id
          creator
          agentId
          clientId
          tourId
          createdAt
          updatedAt
        }
        nextToken
      }
      toursNote
      deactivated
      createdAt
      updatedAt
      profile {
        id
        clientId
        buyingSelling
        leasingRenting
        justLooking
        preConstruction
        isRealtor
        createdAt
        updatedAt
        owner
        client {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          profilePicture
          agentId
          brokerageId
          subscribed
          currency
          measurement
          notifications
          deviceToken
          profileId
          requestedTour
          toursNote
          deactivated
          createdAt
          updatedAt
        }
      }
      favourites {
        items {
          id
          repliersID
          mlsNumber
          listing
          listingUpdate
          clientId
          notification
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      searches {
        items {
          id
          name
          repliersID
          value
          clientId
          notification
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      agent {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        website
        profilePicture
        licenseNumber
        areas
        availability
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        currency
        measurement
        notifications
        deviceToken
        createdAt
        updatedAt
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        clients {
          nextToken
        }
        tours {
          nextToken
        }
      }
    }
  }
`;
export const onCreateAgent = /* GraphQL */ `
  subscription OnCreateAgent($id: String) {
    onCreateAgent(id: $id) {
      id
      repliersID
      givenName
      familyName
      name
      email
      phone
      website
      profilePicture
      licenseNumber
      areas
      availability
      brokerage {
        id
        name
        createdAt
        updatedAt
        clients {
          nextToken
        }
        owner
        agents {
          nextToken
        }
      }
      brokerageId
      currency
      measurement
      notifications
      deviceToken
      createdAt
      updatedAt
      favourites {
        items {
          id
          repliersID
          mlsNumber
          listing
          listingUpdate
          clientId
          notification
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      searches {
        items {
          id
          name
          repliersID
          value
          clientId
          notification
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      clients {
        items {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          profilePicture
          agentId
          brokerageId
          subscribed
          currency
          measurement
          notifications
          deviceToken
          profileId
          requestedTour
          toursNote
          deactivated
          createdAt
          updatedAt
        }
        nextToken
      }
      tours {
        items {
          id
          title
          creator
          agentId
          showingAgentId
          members
          membersData
          status
          privateNote
          generalNote
          meetupLocation
          meetupDescription
          meetupTime
          startTime
          endTime
          date
          shared
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateAgent = /* GraphQL */ `
  subscription OnUpdateAgent($id: String) {
    onUpdateAgent(id: $id) {
      id
      repliersID
      givenName
      familyName
      name
      email
      phone
      website
      profilePicture
      licenseNumber
      areas
      availability
      brokerage {
        id
        name
        createdAt
        updatedAt
        clients {
          nextToken
        }
        owner
        agents {
          nextToken
        }
      }
      brokerageId
      currency
      measurement
      notifications
      deviceToken
      createdAt
      updatedAt
      favourites {
        items {
          id
          repliersID
          mlsNumber
          listing
          listingUpdate
          clientId
          notification
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      searches {
        items {
          id
          name
          repliersID
          value
          clientId
          notification
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      clients {
        items {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          profilePicture
          agentId
          brokerageId
          subscribed
          currency
          measurement
          notifications
          deviceToken
          profileId
          requestedTour
          toursNote
          deactivated
          createdAt
          updatedAt
        }
        nextToken
      }
      tours {
        items {
          id
          title
          creator
          agentId
          showingAgentId
          members
          membersData
          status
          privateNote
          generalNote
          meetupLocation
          meetupDescription
          meetupTime
          startTime
          endTime
          date
          shared
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteAgent = /* GraphQL */ `
  subscription OnDeleteAgent($id: String) {
    onDeleteAgent(id: $id) {
      id
      repliersID
      givenName
      familyName
      name
      email
      phone
      website
      profilePicture
      licenseNumber
      areas
      availability
      brokerage {
        id
        name
        createdAt
        updatedAt
        clients {
          nextToken
        }
        owner
        agents {
          nextToken
        }
      }
      brokerageId
      currency
      measurement
      notifications
      deviceToken
      createdAt
      updatedAt
      favourites {
        items {
          id
          repliersID
          mlsNumber
          listing
          listingUpdate
          clientId
          notification
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      searches {
        items {
          id
          name
          repliersID
          value
          clientId
          notification
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      clients {
        items {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          profilePicture
          agentId
          brokerageId
          subscribed
          currency
          measurement
          notifications
          deviceToken
          profileId
          requestedTour
          toursNote
          deactivated
          createdAt
          updatedAt
        }
        nextToken
      }
      tours {
        items {
          id
          title
          creator
          agentId
          showingAgentId
          members
          membersData
          status
          privateNote
          generalNote
          meetupLocation
          meetupDescription
          meetupTime
          startTime
          endTime
          date
          shared
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateTour = /* GraphQL */ `
  subscription OnCreateTour(
    $creator: String
    $agentId: String
    $showingAgentId: String
    $members: String
  ) {
    onCreateTour(
      creator: $creator
      agentId: $agentId
      showingAgentId: $showingAgentId
      members: $members
    ) {
      id
      title
      creator
      agentId
      showingAgentId
      members
      membersData
      clients {
        items {
          id
          creator
          agentId
          clientId
          tourId
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      privateNote
      generalNote
      tourItems {
        items {
          id
          creator
          agentId
          showingAgentId
          members
          membersData
          tourId
          mlsNumber
          status
          startTime
          endTime
          order
          showingNote
          entryInfo
          createdAt
          updatedAt
        }
        nextToken
      }
      meetupLocation
      meetupDescription
      meetupTime
      startTime
      endTime
      date
      shared
      createdAt
      updatedAt
      agent {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        website
        profilePicture
        licenseNumber
        areas
        availability
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        currency
        measurement
        notifications
        deviceToken
        createdAt
        updatedAt
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        clients {
          nextToken
        }
        tours {
          nextToken
        }
      }
      showingAgent {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        website
        profilePicture
        licenseNumber
        areas
        availability
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        currency
        measurement
        notifications
        deviceToken
        createdAt
        updatedAt
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        clients {
          nextToken
        }
        tours {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateTour = /* GraphQL */ `
  subscription OnUpdateTour(
    $creator: String
    $agentId: String
    $showingAgentId: String
    $members: String
  ) {
    onUpdateTour(
      creator: $creator
      agentId: $agentId
      showingAgentId: $showingAgentId
      members: $members
    ) {
      id
      title
      creator
      agentId
      showingAgentId
      members
      membersData
      clients {
        items {
          id
          creator
          agentId
          clientId
          tourId
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      privateNote
      generalNote
      tourItems {
        items {
          id
          creator
          agentId
          showingAgentId
          members
          membersData
          tourId
          mlsNumber
          status
          startTime
          endTime
          order
          showingNote
          entryInfo
          createdAt
          updatedAt
        }
        nextToken
      }
      meetupLocation
      meetupDescription
      meetupTime
      startTime
      endTime
      date
      shared
      createdAt
      updatedAt
      agent {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        website
        profilePicture
        licenseNumber
        areas
        availability
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        currency
        measurement
        notifications
        deviceToken
        createdAt
        updatedAt
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        clients {
          nextToken
        }
        tours {
          nextToken
        }
      }
      showingAgent {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        website
        profilePicture
        licenseNumber
        areas
        availability
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        currency
        measurement
        notifications
        deviceToken
        createdAt
        updatedAt
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        clients {
          nextToken
        }
        tours {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteTour = /* GraphQL */ `
  subscription OnDeleteTour(
    $creator: String
    $agentId: String
    $showingAgentId: String
    $members: String
  ) {
    onDeleteTour(
      creator: $creator
      agentId: $agentId
      showingAgentId: $showingAgentId
      members: $members
    ) {
      id
      title
      creator
      agentId
      showingAgentId
      members
      membersData
      clients {
        items {
          id
          creator
          agentId
          clientId
          tourId
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      privateNote
      generalNote
      tourItems {
        items {
          id
          creator
          agentId
          showingAgentId
          members
          membersData
          tourId
          mlsNumber
          status
          startTime
          endTime
          order
          showingNote
          entryInfo
          createdAt
          updatedAt
        }
        nextToken
      }
      meetupLocation
      meetupDescription
      meetupTime
      startTime
      endTime
      date
      shared
      createdAt
      updatedAt
      agent {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        website
        profilePicture
        licenseNumber
        areas
        availability
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        currency
        measurement
        notifications
        deviceToken
        createdAt
        updatedAt
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        clients {
          nextToken
        }
        tours {
          nextToken
        }
      }
      showingAgent {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        website
        profilePicture
        licenseNumber
        areas
        availability
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        currency
        measurement
        notifications
        deviceToken
        createdAt
        updatedAt
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        clients {
          nextToken
        }
        tours {
          nextToken
        }
      }
    }
  }
`;
export const onCreateSchool = /* GraphQL */ `
  subscription OnCreateSchool {
    onCreateSchool {
      id
      objVer
      name
      isCatholic
      isPublic
      isUseSchool
      gradeFrom
      gradeEnd
      isElementary
      isMiddle
      isHigh
      isEnglish
      isFrenchImmersion
      isExtendedFrench
      isAP
      isArts
      isGifted
      isIB
      isSport
      g3Avg
      g3WeightedAvg
      g6Avg
      g6WeightedAvg
      g9Avg
      g9APMWeightedAvg
      g9ACMWeightedAvg
      g10Avg
      ettieOverallAvg
      g3Rank
      g3RankTotal
      g6Rank
      g6RankTotal
      g9Rank
      g9RankTotal
      g9ACRank
      g9ACTotalRank
      g9APRank
      g9APTotalRank
      g10Rank
      g10RankTotal
      EQAOScore
      yearMark
      province
      nation
      url
      schoolBoardId
      address
      city
      postalCode
      phone
      fax
      latitude
      longitude
      comments
      boundaryArray
      schoolsAssocationArray
      lastReviewDate
      createdAt
      updatedAt
      schoolBoard {
        id
        objVer
        name
        url
        boardType
        province
        nation
        address
        city
        postalCode
        phone
        fax
        createdAt
        updatedAt
        schools {
          nextToken
        }
        scores {
          nextToken
        }
      }
      ratings {
        items {
          id
          objVer
          schoolId
          yearMark
          year
          G3STU
          G3AR
          G3AW
          G3AM
          G3Rank
          G3Total
          G3FindSchoolRating
          G6STU
          G6AR
          G6AW
          G6AM
          G6Rank
          G6Total
          G6FindSchoolRating
          G9ACSTU
          G9ACM
          G9ACRank
          G9ACTotal
          G9APSTU
          G9APM
          G9APRank
          G9APTotal
          G9Rank
          G9Total
          G9FindSchoolRating
          G10OSSLTFSTUA
          G10OSSLTFSTUP
          G10OSSLTFSUCCESS
          G3Last5Avg
          G6Last5Avg
          G9Last5Avg
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateSchool = /* GraphQL */ `
  subscription OnUpdateSchool {
    onUpdateSchool {
      id
      objVer
      name
      isCatholic
      isPublic
      isUseSchool
      gradeFrom
      gradeEnd
      isElementary
      isMiddle
      isHigh
      isEnglish
      isFrenchImmersion
      isExtendedFrench
      isAP
      isArts
      isGifted
      isIB
      isSport
      g3Avg
      g3WeightedAvg
      g6Avg
      g6WeightedAvg
      g9Avg
      g9APMWeightedAvg
      g9ACMWeightedAvg
      g10Avg
      ettieOverallAvg
      g3Rank
      g3RankTotal
      g6Rank
      g6RankTotal
      g9Rank
      g9RankTotal
      g9ACRank
      g9ACTotalRank
      g9APRank
      g9APTotalRank
      g10Rank
      g10RankTotal
      EQAOScore
      yearMark
      province
      nation
      url
      schoolBoardId
      address
      city
      postalCode
      phone
      fax
      latitude
      longitude
      comments
      boundaryArray
      schoolsAssocationArray
      lastReviewDate
      createdAt
      updatedAt
      schoolBoard {
        id
        objVer
        name
        url
        boardType
        province
        nation
        address
        city
        postalCode
        phone
        fax
        createdAt
        updatedAt
        schools {
          nextToken
        }
        scores {
          nextToken
        }
      }
      ratings {
        items {
          id
          objVer
          schoolId
          yearMark
          year
          G3STU
          G3AR
          G3AW
          G3AM
          G3Rank
          G3Total
          G3FindSchoolRating
          G6STU
          G6AR
          G6AW
          G6AM
          G6Rank
          G6Total
          G6FindSchoolRating
          G9ACSTU
          G9ACM
          G9ACRank
          G9ACTotal
          G9APSTU
          G9APM
          G9APRank
          G9APTotal
          G9Rank
          G9Total
          G9FindSchoolRating
          G10OSSLTFSTUA
          G10OSSLTFSTUP
          G10OSSLTFSUCCESS
          G3Last5Avg
          G6Last5Avg
          G9Last5Avg
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteSchool = /* GraphQL */ `
  subscription OnDeleteSchool {
    onDeleteSchool {
      id
      objVer
      name
      isCatholic
      isPublic
      isUseSchool
      gradeFrom
      gradeEnd
      isElementary
      isMiddle
      isHigh
      isEnglish
      isFrenchImmersion
      isExtendedFrench
      isAP
      isArts
      isGifted
      isIB
      isSport
      g3Avg
      g3WeightedAvg
      g6Avg
      g6WeightedAvg
      g9Avg
      g9APMWeightedAvg
      g9ACMWeightedAvg
      g10Avg
      ettieOverallAvg
      g3Rank
      g3RankTotal
      g6Rank
      g6RankTotal
      g9Rank
      g9RankTotal
      g9ACRank
      g9ACTotalRank
      g9APRank
      g9APTotalRank
      g10Rank
      g10RankTotal
      EQAOScore
      yearMark
      province
      nation
      url
      schoolBoardId
      address
      city
      postalCode
      phone
      fax
      latitude
      longitude
      comments
      boundaryArray
      schoolsAssocationArray
      lastReviewDate
      createdAt
      updatedAt
      schoolBoard {
        id
        objVer
        name
        url
        boardType
        province
        nation
        address
        city
        postalCode
        phone
        fax
        createdAt
        updatedAt
        schools {
          nextToken
        }
        scores {
          nextToken
        }
      }
      ratings {
        items {
          id
          objVer
          schoolId
          yearMark
          year
          G3STU
          G3AR
          G3AW
          G3AM
          G3Rank
          G3Total
          G3FindSchoolRating
          G6STU
          G6AR
          G6AW
          G6AM
          G6Rank
          G6Total
          G6FindSchoolRating
          G9ACSTU
          G9ACM
          G9ACRank
          G9ACTotal
          G9APSTU
          G9APM
          G9APRank
          G9APTotal
          G9Rank
          G9Total
          G9FindSchoolRating
          G10OSSLTFSTUA
          G10OSSLTFSTUP
          G10OSSLTFSUCCESS
          G3Last5Avg
          G6Last5Avg
          G9Last5Avg
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateSchoolBoard = /* GraphQL */ `
  subscription OnCreateSchoolBoard {
    onCreateSchoolBoard {
      id
      objVer
      name
      url
      boardType
      province
      nation
      address
      city
      postalCode
      phone
      fax
      createdAt
      updatedAt
      schools {
        items {
          id
          objVer
          name
          isCatholic
          isPublic
          isUseSchool
          gradeFrom
          gradeEnd
          isElementary
          isMiddle
          isHigh
          isEnglish
          isFrenchImmersion
          isExtendedFrench
          isAP
          isArts
          isGifted
          isIB
          isSport
          g3Avg
          g3WeightedAvg
          g6Avg
          g6WeightedAvg
          g9Avg
          g9APMWeightedAvg
          g9ACMWeightedAvg
          g10Avg
          ettieOverallAvg
          g3Rank
          g3RankTotal
          g6Rank
          g6RankTotal
          g9Rank
          g9RankTotal
          g9ACRank
          g9ACTotalRank
          g9APRank
          g9APTotalRank
          g10Rank
          g10RankTotal
          EQAOScore
          yearMark
          province
          nation
          url
          schoolBoardId
          address
          city
          postalCode
          phone
          fax
          latitude
          longitude
          comments
          boundaryArray
          schoolsAssocationArray
          lastReviewDate
          createdAt
          updatedAt
        }
        nextToken
      }
      scores {
        items {
          id
          schoolBoardId
          yearMark
          year
          totalAvg
          g3STU
          g6STU
          g9ACSTU
          g9APSTU
          g3TS
          g6TS
          g9TS
          g10OSSLTFSTUA
          g10OSSLTFSTUP
          g10OSSLTFSUCCESS
          g3AR
          g3AW
          g3AM
          g6AR
          g6AW
          g6AM
          g9APM
          g9ACM
          g3Last5Avg
          g6Last5Avg
          g9Last5Avg
          g3FindSchoolRating
          g6FindSchoolRating
          g9FindSchoolRating
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateSchoolBoard = /* GraphQL */ `
  subscription OnUpdateSchoolBoard {
    onUpdateSchoolBoard {
      id
      objVer
      name
      url
      boardType
      province
      nation
      address
      city
      postalCode
      phone
      fax
      createdAt
      updatedAt
      schools {
        items {
          id
          objVer
          name
          isCatholic
          isPublic
          isUseSchool
          gradeFrom
          gradeEnd
          isElementary
          isMiddle
          isHigh
          isEnglish
          isFrenchImmersion
          isExtendedFrench
          isAP
          isArts
          isGifted
          isIB
          isSport
          g3Avg
          g3WeightedAvg
          g6Avg
          g6WeightedAvg
          g9Avg
          g9APMWeightedAvg
          g9ACMWeightedAvg
          g10Avg
          ettieOverallAvg
          g3Rank
          g3RankTotal
          g6Rank
          g6RankTotal
          g9Rank
          g9RankTotal
          g9ACRank
          g9ACTotalRank
          g9APRank
          g9APTotalRank
          g10Rank
          g10RankTotal
          EQAOScore
          yearMark
          province
          nation
          url
          schoolBoardId
          address
          city
          postalCode
          phone
          fax
          latitude
          longitude
          comments
          boundaryArray
          schoolsAssocationArray
          lastReviewDate
          createdAt
          updatedAt
        }
        nextToken
      }
      scores {
        items {
          id
          schoolBoardId
          yearMark
          year
          totalAvg
          g3STU
          g6STU
          g9ACSTU
          g9APSTU
          g3TS
          g6TS
          g9TS
          g10OSSLTFSTUA
          g10OSSLTFSTUP
          g10OSSLTFSUCCESS
          g3AR
          g3AW
          g3AM
          g6AR
          g6AW
          g6AM
          g9APM
          g9ACM
          g3Last5Avg
          g6Last5Avg
          g9Last5Avg
          g3FindSchoolRating
          g6FindSchoolRating
          g9FindSchoolRating
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteSchoolBoard = /* GraphQL */ `
  subscription OnDeleteSchoolBoard {
    onDeleteSchoolBoard {
      id
      objVer
      name
      url
      boardType
      province
      nation
      address
      city
      postalCode
      phone
      fax
      createdAt
      updatedAt
      schools {
        items {
          id
          objVer
          name
          isCatholic
          isPublic
          isUseSchool
          gradeFrom
          gradeEnd
          isElementary
          isMiddle
          isHigh
          isEnglish
          isFrenchImmersion
          isExtendedFrench
          isAP
          isArts
          isGifted
          isIB
          isSport
          g3Avg
          g3WeightedAvg
          g6Avg
          g6WeightedAvg
          g9Avg
          g9APMWeightedAvg
          g9ACMWeightedAvg
          g10Avg
          ettieOverallAvg
          g3Rank
          g3RankTotal
          g6Rank
          g6RankTotal
          g9Rank
          g9RankTotal
          g9ACRank
          g9ACTotalRank
          g9APRank
          g9APTotalRank
          g10Rank
          g10RankTotal
          EQAOScore
          yearMark
          province
          nation
          url
          schoolBoardId
          address
          city
          postalCode
          phone
          fax
          latitude
          longitude
          comments
          boundaryArray
          schoolsAssocationArray
          lastReviewDate
          createdAt
          updatedAt
        }
        nextToken
      }
      scores {
        items {
          id
          schoolBoardId
          yearMark
          year
          totalAvg
          g3STU
          g6STU
          g9ACSTU
          g9APSTU
          g3TS
          g6TS
          g9TS
          g10OSSLTFSTUA
          g10OSSLTFSTUP
          g10OSSLTFSUCCESS
          g3AR
          g3AW
          g3AM
          g6AR
          g6AW
          g6AM
          g9APM
          g9ACM
          g3Last5Avg
          g6Last5Avg
          g9Last5Avg
          g3FindSchoolRating
          g6FindSchoolRating
          g9FindSchoolRating
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateSchoolBoardScore = /* GraphQL */ `
  subscription OnCreateSchoolBoardScore {
    onCreateSchoolBoardScore {
      id
      schoolBoardId
      yearMark
      year
      totalAvg
      g3STU
      g6STU
      g9ACSTU
      g9APSTU
      g3TS
      g6TS
      g9TS
      g10OSSLTFSTUA
      g10OSSLTFSTUP
      g10OSSLTFSUCCESS
      g3AR
      g3AW
      g3AM
      g6AR
      g6AW
      g6AM
      g9APM
      g9ACM
      g3Last5Avg
      g6Last5Avg
      g9Last5Avg
      g3FindSchoolRating
      g6FindSchoolRating
      g9FindSchoolRating
      createdAt
      updatedAt
      schoolBoard {
        id
        objVer
        name
        url
        boardType
        province
        nation
        address
        city
        postalCode
        phone
        fax
        createdAt
        updatedAt
        schools {
          nextToken
        }
        scores {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateSchoolBoardScore = /* GraphQL */ `
  subscription OnUpdateSchoolBoardScore {
    onUpdateSchoolBoardScore {
      id
      schoolBoardId
      yearMark
      year
      totalAvg
      g3STU
      g6STU
      g9ACSTU
      g9APSTU
      g3TS
      g6TS
      g9TS
      g10OSSLTFSTUA
      g10OSSLTFSTUP
      g10OSSLTFSUCCESS
      g3AR
      g3AW
      g3AM
      g6AR
      g6AW
      g6AM
      g9APM
      g9ACM
      g3Last5Avg
      g6Last5Avg
      g9Last5Avg
      g3FindSchoolRating
      g6FindSchoolRating
      g9FindSchoolRating
      createdAt
      updatedAt
      schoolBoard {
        id
        objVer
        name
        url
        boardType
        province
        nation
        address
        city
        postalCode
        phone
        fax
        createdAt
        updatedAt
        schools {
          nextToken
        }
        scores {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteSchoolBoardScore = /* GraphQL */ `
  subscription OnDeleteSchoolBoardScore {
    onDeleteSchoolBoardScore {
      id
      schoolBoardId
      yearMark
      year
      totalAvg
      g3STU
      g6STU
      g9ACSTU
      g9APSTU
      g3TS
      g6TS
      g9TS
      g10OSSLTFSTUA
      g10OSSLTFSTUP
      g10OSSLTFSUCCESS
      g3AR
      g3AW
      g3AM
      g6AR
      g6AW
      g6AM
      g9APM
      g9ACM
      g3Last5Avg
      g6Last5Avg
      g9Last5Avg
      g3FindSchoolRating
      g6FindSchoolRating
      g9FindSchoolRating
      createdAt
      updatedAt
      schoolBoard {
        id
        objVer
        name
        url
        boardType
        province
        nation
        address
        city
        postalCode
        phone
        fax
        createdAt
        updatedAt
        schools {
          nextToken
        }
        scores {
          nextToken
        }
      }
    }
  }
`;
export const onCreateSchoolRating = /* GraphQL */ `
  subscription OnCreateSchoolRating {
    onCreateSchoolRating {
      id
      objVer
      schoolId
      yearMark
      year
      G3STU
      G3AR
      G3AW
      G3AM
      G3Rank
      G3Total
      G3FindSchoolRating
      G6STU
      G6AR
      G6AW
      G6AM
      G6Rank
      G6Total
      G6FindSchoolRating
      G9ACSTU
      G9ACM
      G9ACRank
      G9ACTotal
      G9APSTU
      G9APM
      G9APRank
      G9APTotal
      G9Rank
      G9Total
      G9FindSchoolRating
      G10OSSLTFSTUA
      G10OSSLTFSTUP
      G10OSSLTFSUCCESS
      G3Last5Avg
      G6Last5Avg
      G9Last5Avg
      createdAt
      updatedAt
      school {
        id
        objVer
        name
        isCatholic
        isPublic
        isUseSchool
        gradeFrom
        gradeEnd
        isElementary
        isMiddle
        isHigh
        isEnglish
        isFrenchImmersion
        isExtendedFrench
        isAP
        isArts
        isGifted
        isIB
        isSport
        g3Avg
        g3WeightedAvg
        g6Avg
        g6WeightedAvg
        g9Avg
        g9APMWeightedAvg
        g9ACMWeightedAvg
        g10Avg
        ettieOverallAvg
        g3Rank
        g3RankTotal
        g6Rank
        g6RankTotal
        g9Rank
        g9RankTotal
        g9ACRank
        g9ACTotalRank
        g9APRank
        g9APTotalRank
        g10Rank
        g10RankTotal
        EQAOScore
        yearMark
        province
        nation
        url
        schoolBoardId
        address
        city
        postalCode
        phone
        fax
        latitude
        longitude
        comments
        boundaryArray
        schoolsAssocationArray
        lastReviewDate
        createdAt
        updatedAt
        schoolBoard {
          id
          objVer
          name
          url
          boardType
          province
          nation
          address
          city
          postalCode
          phone
          fax
          createdAt
          updatedAt
        }
        ratings {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateSchoolRating = /* GraphQL */ `
  subscription OnUpdateSchoolRating {
    onUpdateSchoolRating {
      id
      objVer
      schoolId
      yearMark
      year
      G3STU
      G3AR
      G3AW
      G3AM
      G3Rank
      G3Total
      G3FindSchoolRating
      G6STU
      G6AR
      G6AW
      G6AM
      G6Rank
      G6Total
      G6FindSchoolRating
      G9ACSTU
      G9ACM
      G9ACRank
      G9ACTotal
      G9APSTU
      G9APM
      G9APRank
      G9APTotal
      G9Rank
      G9Total
      G9FindSchoolRating
      G10OSSLTFSTUA
      G10OSSLTFSTUP
      G10OSSLTFSUCCESS
      G3Last5Avg
      G6Last5Avg
      G9Last5Avg
      createdAt
      updatedAt
      school {
        id
        objVer
        name
        isCatholic
        isPublic
        isUseSchool
        gradeFrom
        gradeEnd
        isElementary
        isMiddle
        isHigh
        isEnglish
        isFrenchImmersion
        isExtendedFrench
        isAP
        isArts
        isGifted
        isIB
        isSport
        g3Avg
        g3WeightedAvg
        g6Avg
        g6WeightedAvg
        g9Avg
        g9APMWeightedAvg
        g9ACMWeightedAvg
        g10Avg
        ettieOverallAvg
        g3Rank
        g3RankTotal
        g6Rank
        g6RankTotal
        g9Rank
        g9RankTotal
        g9ACRank
        g9ACTotalRank
        g9APRank
        g9APTotalRank
        g10Rank
        g10RankTotal
        EQAOScore
        yearMark
        province
        nation
        url
        schoolBoardId
        address
        city
        postalCode
        phone
        fax
        latitude
        longitude
        comments
        boundaryArray
        schoolsAssocationArray
        lastReviewDate
        createdAt
        updatedAt
        schoolBoard {
          id
          objVer
          name
          url
          boardType
          province
          nation
          address
          city
          postalCode
          phone
          fax
          createdAt
          updatedAt
        }
        ratings {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteSchoolRating = /* GraphQL */ `
  subscription OnDeleteSchoolRating {
    onDeleteSchoolRating {
      id
      objVer
      schoolId
      yearMark
      year
      G3STU
      G3AR
      G3AW
      G3AM
      G3Rank
      G3Total
      G3FindSchoolRating
      G6STU
      G6AR
      G6AW
      G6AM
      G6Rank
      G6Total
      G6FindSchoolRating
      G9ACSTU
      G9ACM
      G9ACRank
      G9ACTotal
      G9APSTU
      G9APM
      G9APRank
      G9APTotal
      G9Rank
      G9Total
      G9FindSchoolRating
      G10OSSLTFSTUA
      G10OSSLTFSTUP
      G10OSSLTFSUCCESS
      G3Last5Avg
      G6Last5Avg
      G9Last5Avg
      createdAt
      updatedAt
      school {
        id
        objVer
        name
        isCatholic
        isPublic
        isUseSchool
        gradeFrom
        gradeEnd
        isElementary
        isMiddle
        isHigh
        isEnglish
        isFrenchImmersion
        isExtendedFrench
        isAP
        isArts
        isGifted
        isIB
        isSport
        g3Avg
        g3WeightedAvg
        g6Avg
        g6WeightedAvg
        g9Avg
        g9APMWeightedAvg
        g9ACMWeightedAvg
        g10Avg
        ettieOverallAvg
        g3Rank
        g3RankTotal
        g6Rank
        g6RankTotal
        g9Rank
        g9RankTotal
        g9ACRank
        g9ACTotalRank
        g9APRank
        g9APTotalRank
        g10Rank
        g10RankTotal
        EQAOScore
        yearMark
        province
        nation
        url
        schoolBoardId
        address
        city
        postalCode
        phone
        fax
        latitude
        longitude
        comments
        boundaryArray
        schoolsAssocationArray
        lastReviewDate
        createdAt
        updatedAt
        schoolBoard {
          id
          objVer
          name
          url
          boardType
          province
          nation
          address
          city
          postalCode
          phone
          fax
          createdAt
          updatedAt
        }
        ratings {
          nextToken
        }
      }
    }
  }
`;
