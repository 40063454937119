import { useState } from "react";
import { useDispatch } from "react-redux";
import { IonAlert, IonToast } from "@ionic/react";
import { updatePhone } from "../../../../amplify/auth.utils";
import {
  verifyUserAttribute,
  verifyUserAttributeConfirm,
} from "../../../../amplify/auth.utils";
import { updateClient } from "../../../../amplify/graphql.utils";
import { checkUserSession } from "../../../../redux/user/user.actions";
import styles from "./alert-settings-alerts.module.scss";
import { PushNotifications } from "@capacitor/push-notifications";
import { getPlatforms } from "@ionic/core";

const AlertSettingsAlerts = ({
  client,
  notifications,
  showAlertSettings,
  setShowAlertSettings,
}) => {
  const dispatch = useDispatch();
  const { schedule, types } = notifications;
  const [error, setError] = useState("");
  const [phone, setPhone] = useState();
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showConfirmAlert, setShowConfirmAlert] = useState(false);
  let hasError = false;

  //Update the phone number
  const handleSubmitPhone = async (phone) => {
    setError("");
    if (phone === "") {
      setError("Phone is required");
      setShowErrorToast(true);
      hasError = true;
      setShowConfirmAlert(false);
    } else if (phone !== "") {
      const re = /^\(?([0-9]{3})\)?([0-9]{3})([0-9]{4})$/;
      if (!re.test(phone)) {
        setError(
          "Please enter a valid phone number using numbers only and no spaces."
        );
        setShowErrorToast(true);
        hasError = true;
      } else {
        hasError = false;
        phone = "+1" + phone;
      }
    }

    if (!hasError) {
      if (phone === notifications.phone) {
        setError("Please provide a new phone number.");
        setShowErrorToast(true);
        hasError = true;
      } else {
        setError("");
        hasError = false;
        try {
          setPhone(phone);
          await updatePhone(phone);
          setShowConfirmAlert(true);
        } catch (error) {
          setError(error.message);
          setShowErrorToast(true);
          hasError = true;
        }
      }
    }
  };

  const resendHandler = () => {
    verifyUserAttribute("phone_number");
  };

  //Verify the phone number
  const confirmCodeHandler = async (code) => {
    if (code !== "") {
      setError("");
      hasError = true;
      notifications.phone = phone;
      try {
        await verifyUserAttributeConfirm(
          "phone_number",
          code,
          client.id,
          notifications
        );
        dispatch(checkUserSession(true));
        setShowConfirmAlert(false);
        setShowSuccessToast(true);
        hasError = false;
      } catch (error) {
        setError(error.message);
        setShowErrorToast(true);
        hasError = true;
      }
    } else {
      setError("Code is required");
      setShowErrorToast(true);
      hasError = true;
    }
  };

  //Update the notification schedule
  const handleUpdateStyles = async (selectedSchedule) => {
    const updatedNotifications = {
      ...notifications,
      schedule: selectedSchedule,
    };

    await updateClient({
      id: client.id,
      updates: { notifications: JSON.stringify(updatedNotifications) },
    });

    setShowAlertSettings({ ...showAlertSettings, style: false });
    setShowSuccessToast(true);
  };

  //Update the notification type
  const handleUpdateType = async (type) => {
    let selectedTypes = {
      email: type.includes("email"),
      phone: type.includes("phone"),
      push: type.includes("push"),
    };

    if (selectedTypes.push) {
      PushNotifications.checkPermissions().then((permission) => {
        if (permission.receive !== "granted") {
          PushNotifications.requestPermissions().then((permission) => {
            if (permission.receive === "granted") {
              PushNotifications.register();
            } else {
              // No permission granted
              selectedTypes.push = false;
            }
          });
        } else {
          PushNotifications.register();
        }
      });
    }

    const updatedNotifications = {
      ...notifications,
      types: selectedTypes,
    };

    await updateClient({
      id: client.id,
      updates: { notifications: JSON.stringify(updatedNotifications) },
    });

    setShowAlertSettings({ ...showAlertSettings, type: false });
    setShowSuccessToast(true);
  };

  return (
    <>
      <IonToast
        isOpen={showErrorToast}
        onDidDismiss={() => {
          setError("");
          setShowErrorToast(false);
        }}
        message={error}
        cssClass={styles.errorToast}
        position="top"
        duration={4000}
        buttons={[
          {
            side: "end",
            icon: "close",
            handler: () => setShowErrorToast(false),
          },
        ]}
      />
      <IonToast
        isOpen={showSuccessToast}
        onDidDismiss={() => setShowSuccessToast(false)}
        message="Your account has been updated!"
        cssClass={styles.successToast}
        position="top"
        duration={2000}
        buttons={[
          {
            side: "end",
            icon: "close",
            handler: () => setShowSuccessToast(false),
          },
        ]}
      />
      {showAlertSettings["phone"] && (
        <IonAlert
          isOpen={showAlertSettings.phone}
          onDidDismiss={() =>
            setShowAlertSettings({ ...showAlertSettings, phone: false })
          }
          cssClass={styles.alert}
          header={"Update your phone number"}
          message={
            "The phone number at which you will receive alerts. Please format with no spaces or dashes"
          }
          inputs={[
            {
              name: "phone",
              type: "number",
              label: "Phone number",
              placeholder: "Phone number",
            },
          ]}
          buttons={[
            {
              text: "Cancel",
              role: "cancel",
            },
            {
              text: "Next",
              handler: (e) => {
                handleSubmitPhone(e.phone);
                if (hasError) {
                  return false;
                }
              },
            },
          ]}
        />
      )}
      <IonAlert
        isOpen={showConfirmAlert}
        onDidDismiss={() => setShowConfirmAlert(false)}
        cssClass={styles.alert}
        header={"Check your phone"}
        message={"Enter the code you received."}
        inputs={[
          {
            name: "code",
            type: "text",
            label: "code",
            placeholder: "code",
          },
        ]}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            handler: () => setShowConfirmAlert(false),
          },
          {
            text: "Resend code",
            handler: (e) => {
              resendHandler();
              return false;
            },
          },
          {
            text: "Confirm",
            handler: (e) => {
              confirmCodeHandler(e.code);
              if (hasError) {
                return false;
              }
            },
          },
        ]}
      />
      {showAlertSettings["style"] && (
        <IonAlert
          isOpen={showAlertSettings.style}
          onDidDismiss={() =>
            setShowAlertSettings({ ...showAlertSettings, style: false })
          }
          cssClass={styles.alert}
          header={"Notify me of changes"}
          message={"Setting applies to all favourites and saved searches."}
          inputs={[
            {
              name: "instantly",
              type: "radio",
              label: "instantly",
              value: "instantly",
              checked: schedule === "instantly",
            },
            {
              name: "daily",
              type: "radio",
              label: "daily",
              value: "daily",
              checked: schedule === "daily",
            },
            {
              name: "weekly",
              type: "radio",
              label: "weekly",
              value: "weekly",
              checked: schedule === "weekly",
            },
            {
              name: "never",
              type: "radio",
              label: "never",
              value: "never",
              checked: schedule === "never",
            },
          ]}
          buttons={[
            {
              text: "Cancel",
              role: "cancel",
            },
            {
              text: "Save",
              handler: (e) => {
                handleUpdateStyles(e);
              },
            },
          ]}
        />
      )}
      {showAlertSettings["type"] && (
        <IonAlert
          isOpen={showAlertSettings.type}
          onDidDismiss={() =>
            setShowAlertSettings({ ...showAlertSettings, type: false })
          }
          cssClass={styles.alert}
          header={"Notify me by"}
          message={"Update your notification preferences."}
          inputs={[
            {
              name: "email",
              type: "checkbox",
              label: "email",
              value: "email",
              checked: types.email,
            },
            {
              name: "phone",
              type: "checkbox",
              label: "SMS",
              value: "phone",
              checked: types.phone,
              disabled: !client.phone,
            },
            {
              name: "push",
              type: "checkbox",
              label: "Push notifictation",
              value: "push",
              checked: types.push,
              disabled: !getPlatforms().includes("capacitor"),
            },
          ]}
          buttons={[
            {
              text: "Cancel",
              role: "cancel",
            },
            {
              text: "Save",
              handler: (e) => {
                handleUpdateType(e);
              },
            },
          ]}
        />
      )}
    </>
  );
};

export default AlertSettingsAlerts;
