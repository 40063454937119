import styles from "./skinny-footer.module.scss";
import { IonIcon } from "@ionic/react";
import {
  logoFacebook,
  logoInstagram,
  logoLinkedin,
  logoTwitter,
} from "ionicons/icons";
import { Link } from "react-router-dom";
import { InAppBrowser } from "@ionic-native/in-app-browser";
import jsonObj from "../../../package.json";

const date = new Date();

const SkinnyFooter = () => (
  <div className={styles.container}>
    <div className={styles.left}>
      <div className={styles.stores}>
        <span className={styles.label}>Download:</span>
        <span className={styles.link}>
          <a
            href="https://apps.apple.com/ca/app/ettie/id1578020676"
            target="_blank"
          >
            App Store
          </a>
        </span>
        <span className={styles.link}>
          <a
            href="https://play.google.com/store/apps/details?id=com.ettie.app"
            target="_blank"
          >
            Google Play
          </a>
        </span>
      </div>
      <div className={styles.icons}>
        <span>Follow the action!</span>
        <IonIcon
          icon={logoFacebook}
          className={styles.icon}
          onClick={() =>
            InAppBrowser.create("https://www.facebook.com/AskEttie", "_system")
          }
        />
        <IonIcon
          icon={logoInstagram}
          className={styles.icon}
          onClick={() =>
            InAppBrowser.create(
              "https://www.instagram.com/ettie.ai/",
              "_system"
            )
          }
        />
        <IonIcon
          icon={logoLinkedin}
          className={styles.icon}
          onClick={() =>
            InAppBrowser.create(
              "https://www.linkedin.com/company/ettie-ai",
              "_system"
            )
          }
        />
        <IonIcon
          icon={logoTwitter}
          className={styles.icon}
          onClick={() =>
            InAppBrowser.create("https://twitter.com/ettie_ai", "_system")
          }
        />
      </div>
    </div>
    <div className={styles.right}>
      <div className={styles.links}>
        <span className={styles.link}>
          <Link to="/terms">Terms of Use</Link>
        </span>
        <span className={styles.link}>
          <Link to="/privacy">Privacy Policy</Link>
        </span>
      </div>
      <div className={styles.copyright}>
        &copy;{date.getFullYear()} Ettie Realty Inc. All rights reserved.
      </div>
      <div className={styles.version}>App version: {jsonObj.version}</div>
    </div>
  </div>
);

export default SkinnyFooter;
