import { IonSlides, IonSlide } from "@ionic/react";
import { useRef } from "react";
import styles from "./comparable-listings.module.scss";
import ListingCard from "../../../../../../components/ListingCard/listing-card.component";
import AppBlurCard from "./component/blur-card.component";

const ComparableListings = ({
  title,
  listings,
  isActive = false,
  user,
  signInHandler,
  signUpHandler,
}) => {
  const ref = useRef();
  const SLIDES_OPTIONS = {
    spaceBetween: 0,
    slidesPerView: 1.1,
    initialSlide: 0,
    centeredSlides: false,
    loop: true,
  };

  const handleLoad = () => {
    if (ref.current) {
      ref.current.options = SLIDES_OPTIONS;
    }
  };

  const renderSingleComparable = () => {
    return (
      <div className={styles.single}>
        {isActive ? (
          <ListingCard isActiveComparables={isActive} listing={listings[0]} />
        ) : user ? (
          <ListingCard isActiveComparables={isActive} listing={listings[0]} />
        ) : (
          <AppBlurCard
            listing={listings[0]}
            signInHandler={signInHandler}
            signUpHandler={signUpHandler}
          />
        )}
      </div>
    );
  };

  return (
    <div className={styles.comparables}>
      <div className={styles.title}>{title}</div>
      {listings ? (
        <IonSlides onIonSlidesDidLoad={handleLoad} ref={ref}>
          {listings.map((listing) => (
            <IonSlide key={listing.mlsNumber}>
              {isActive ? (
                <ListingCard isActiveComparables={isActive} listing={listing} />
              ) : user ? (
                <ListingCard isActiveComparables={isActive} listing={listing} />
              ) : (
                <AppBlurCard
                  listing={listing}
                  signInHandler={signInHandler}
                  signUpHandler={signUpHandler}
                />
              )}
            </IonSlide>
          ))}
        </IonSlides>
      ) : null}
    </div>
  );
};

export default ComparableListings;
