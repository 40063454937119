import { LocationActionTypes } from "./locations.types";

export const fetchLocationsStart = () => ({
  type: LocationActionTypes.FETCH_LOCATIONS_START,
});

export const fetchLocationsSuccess = (locations) => ({
  type: LocationActionTypes.FETCH_LOCATIONS_SUCCESS,
  payload: locations,
});

export const fetchLocationsFailure = (error) => ({
  type: LocationActionTypes.FETCH_LOCATIONS_FAILURE,
  payload: error,
});
