import { takeLatest, put, all, call } from "redux-saga/effects";
import { store } from "../store";
import {
  fetchClient,
  addFavourite,
  removeFavourite,
  addSearch,
  removeSearch,
  updateFavouriteNotification,
  updateSavedSearch,
  updateFavouriteListing,
} from "../../amplify/graphql.utils";
import {
  addFavouriteFailure,
  addFavouriteSuccess,
  fetchClientFailure,
  fetchClientSuccess,
  removeFavouriteFailure,
  removeFavouriteSuccess,
  addSearchFailure,
  addSearchSuccess,
  removeSearchSuccess,
  removeSearchFailure,
  updateFavouriteFailure,
  updateFavouriteSuccess,
  updateSearchFailure,
  updateSearchSuccess,
  clearClient,
} from "./client.actions";
import ClientActionTypes from "./client.types";
import { checkFavouritesUpdates } from "../../api/repliers";
import { resetNotification, updateNotification } from "../ui/ui.actions";
import { resetFilters } from "../filters/filters.actions";
import { signOut } from "../../amplify/auth.utils";
import { signOutFailure, signOutSuccess } from "../user/user.actions";

export function* checkUpdates() {
  const favouritesUpdates = yield checkFavouritesUpdates();

  yield put(
    updateNotification({ type: "favourites", value: favouritesUpdates })
  );
}

export function* fetchClientAsync() {
  try {
    const client = yield fetchClient();
    if (!client) {
      yield put(fetchClientFailure("Something went wrong!"));
    } else {
      yield put(fetchClientSuccess(client));
    }
  } catch (error) {
    yield put(fetchClientFailure(error));
  }
}

export function* addFavouriteAsync({ payload }) {
  try {
    const client = store.getState().client.currentClient;
    yield addFavourite(client.id, client.repliersID, payload);
    yield put(addFavouriteSuccess());
  } catch (error) {
    yield put(addFavouriteFailure(error));
  }
}

export function* removeFavouriteAsync({ payload }) {
  try {
    yield removeFavourite(payload);
    const favourites = store.getState().ui.notifications.favourites;
    yield put(
      updateNotification({
        type: "favourites",
        value: favourites.filter((fav) => fav.mlsNumber !== payload.mlsNumber),
      })
    );
    yield put(removeFavouriteSuccess());
  } catch (error) {
    yield put(removeFavouriteFailure(error));
  }
}

export function* updateFavouriteAsync({ payload }) {
  try {
    const { type, favourite } = payload;
    if (type === "notification") yield updateFavouriteNotification(favourite);
    if (type === "listing") {
      const favourites = store.getState().ui.notifications.favourites;
      yield updateFavouriteListing(favourite);
      yield put(
        updateNotification({
          type: "favourites",
          value: favourites.filter(
            (fav) => fav.mlsNumber !== favourite.mlsNumber
          ),
        })
      );
    }
    yield put(updateFavouriteSuccess());
  } catch (error) {
    yield put(updateFavouriteFailure(error));
  }
}

export function* addSearchAsync({ payload }) {
  try {
    const client = store.getState().client.currentClient;
    yield addSearch(client.id, client.repliersID, payload);
    yield put(addSearchSuccess());
  } catch (error) {
    yield put(addSearchFailure(error));
  }
}

export function* removeSearchAsync({ payload }) {
  try {
    yield removeSearch(payload);
    yield put(removeSearchSuccess());
  } catch (error) {
    yield put(removeSearchFailure(error));
  }
}

export function* updateSearchAsync({ payload }) {
  try {
    yield updateSavedSearch(payload);
    yield put(updateSearchSuccess());
  } catch (error) {
    yield put(updateSearchFailure(error));
  }
}

export function* signOutAfterFailure() {
  try {
    yield put(resetFilters());
    yield put(clearClient());
    yield put(resetNotification());
    yield signOut();
    yield put(signOutSuccess());
  } catch (error) {
    yield put(signOutFailure(error));
  }
}

export function* onFetchClientStart() {
  yield takeLatest(ClientActionTypes.FETCH_CLIENT_START, fetchClientAsync);
}

export function* onAddFavouriteStart() {
  yield takeLatest(ClientActionTypes.ADD_FAVOURITE_START, addFavouriteAsync);
}

// export function* onAddFavouriteSuccess() {
//   yield takeLatest(ClientActionTypes.ADD_FAVOURITE_SUCCESS, fetchClientAsync);
// }

export function* onRemoveFavouriteStart() {
  yield takeLatest(
    ClientActionTypes.REMOVE_FAVOURITE_START,
    removeFavouriteAsync
  );
}

// export function* onRemoveFavouriteSuccess() {
//   yield takeLatest(
//     ClientActionTypes.REMOVE_FAVOURITE_SUCCESS,
//     fetchClientAsync
//   );
// }

export function* onUpdateFavouriteStart() {
  yield takeLatest(
    ClientActionTypes.UPDATE_FAVOURITE_START,
    updateFavouriteAsync
  );
}

// export function* onUpdateFavouriteSuccess() {
//   yield takeLatest(
//     ClientActionTypes.UPDATE_FAVOURITE_SUCCESS,
//     fetchClientAsync
//   );
// }

export function* onAddSearchStart() {
  yield takeLatest(ClientActionTypes.ADD_SEARCH_START, addSearchAsync);
}

export function* onRemoveSearchStart() {
  yield takeLatest(ClientActionTypes.REMOVE_SEARCH_START, removeSearchAsync);
}

export function* onUpdateSearchStart() {
  yield takeLatest(ClientActionTypes.UPDATE_SEARCH_START, updateSearchAsync);
}

// export function* onAddSearchSuccess() {
//   yield takeLatest(ClientActionTypes.ADD_SEARCH_SUCCESS, fetchClientAsync);
// }

// export function* onRemoveSearchSuccess() {
//   yield takeLatest(ClientActionTypes.REMOVE_SEARCH_SUCCESS, fetchClientAsync);
// }

// export function* onUpdateSearchSuccess() {
//   yield takeLatest(ClientActionTypes.UPDATE_SEARCH_SUCCESS, fetchClientAsync);
// }

export function* onFetchClientSuccess() {
  yield takeLatest(ClientActionTypes.FETCH_CLIENT_SUCCESS, checkUpdates);
}

export function* onFetchClientFailure() {
  yield takeLatest(ClientActionTypes.FETCH_CLIENT_FAILURE, signOutAfterFailure);
}

export function* clientSagas() {
  yield all([
    call(onFetchClientStart),
    call(onFetchClientSuccess),
    call(onAddFavouriteStart),
    //call(onAddFavouriteSuccess),
    call(onRemoveFavouriteStart),
    //call(onRemoveFavouriteSuccess),
    call(onUpdateFavouriteStart),
    //call(onUpdateFavouriteSuccess),
    call(onAddSearchStart),
    //call(onAddSearchSuccess),
    call(onRemoveSearchStart),
    //call(onRemoveSearchSuccess),
    call(onUpdateSearchStart),
    // call(onUpdateSearchSuccess),
    call(onFetchClientFailure),
  ]);
}
