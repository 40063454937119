import { useState, useEffect } from "react";
import styles from "./lot-depth-filter.module.scss";
import { IonRange } from "@ionic/react";
import withFilters from "../../../../../HOC/withFilters/with-filters";

const LotDepthFilter = ({
  extraFilters: {
    lotDepth: { min, max },
  },
  updateExtraFilters,
}) => {
  const [depth, setDepth] = useState({
    lower: min ? min : 0,
    upper: max === "max" || !max ? 200 : max,
  });

  const handleOnChange = ({
    detail: {
      value: { lower, upper },
    },
  }) => {
    if (lower !== depth.lower || upper !== depth.upper)
      setDepth({ lower, upper });
  };

  useEffect(() => {
    updateExtraFilters({
      lotDepth: {
        min: depth.lower,
        max: depth.upper === 200 ? "max" : depth.upper,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [depth]);

  return (
    <div className={styles.item}>
      <div className={styles.input}>
        <IonRange
          value={depth}
          min={0}
          max={200}
          step={5}
          snaps={true}
          ticks={true}
          dualKnobs
          onIonChange={handleOnChange}
          className={styles.range}
        />
        <div className={styles.pins}>
          <span className={styles.pin}>
            <span>{depth.lower} ft</span>
          </span>
          <span className={styles.pin}>
            {depth.upper === 200 ? "max" : <span>{depth.upper} ft</span>}
          </span>
        </div>
      </div>
    </div>
  );
};

export default withFilters(LotDepthFilter);
