import { useCallback } from "react";
import Radio from "../Form/Radio/radio.component";
import styles from "./tree-select.module.scss";
import TreeItem from "./components/tree-item.component";

const TreeSelect = ({
  selected,
  locations,
  open,
  update,
  updateLocations,
  mapRef,
}) => {
  const onAreaHandler = useCallback(
    ({ name, center }) => {
      if (!selected.area || !selected.area.includes(name)) {
        update({ area: [name], city: null, neighborhood: null });
      } else {
        update({
          area: null,
          city: null,
          neighborhood: null,
        });
      }
      if (mapRef) {
        mapRef.panTo(center);
        mapRef.setZoom(10);
      }
      updateLocations({ reset: true });
    },
    [mapRef, selected.area, update, updateLocations]
  );

  const onCityHandler = ({ city }) => {
    const { name, areaIndex, cityIndex, location } = city;

    if (selected.city) {
      if (selected.city.includes(name)) {
        const updatedCities = selected.city.filter((c) => c !== name);
        update({
          city: updatedCities.length ? updatedCities : null,
        });
      } else {
        const updatedNeighborhoods = selected.neighborhood
          ? selected.neighborhood.filter((n) => {
              const item = locations[areaIndex].value[0].cities[
                cityIndex
              ].neighborhoods.find((item) => item.name === n);
              if (item) return false;
              return true;
            })
          : null;

        update({
          city: [...selected.city, name],
          neighborhood: updatedNeighborhoods
            ? updatedNeighborhoods.length
              ? updatedNeighborhoods
              : null
            : null,
        });
        updateLocations({ center: location, city: name });
      }
    } else {
      const updatedNeighborhoods = selected.neighborhood
        ? selected.neighborhood.filter((n) => {
            const neighborhoods =
              locations[areaIndex].value[0].cities[cityIndex].neighborhoods;
            const index = neighborhoods.findIndex((item) => item.name === n);
            if (index >= 0) return false;
            return true;
          })
        : null;

      update({
        city: [name],
        neighborhood: updatedNeighborhoods
          ? updatedNeighborhoods.length
            ? updatedNeighborhoods
            : null
          : null,
      });
      updateLocations({ center: location, city: name });
    }
  };

  const onNeighbourhoodHandler = ({ city, name, coordinates, location }) => {
    const updatedCities = selected.city
      ? selected.city.includes(city)
        ? selected.city.filter((c) => c !== city)
        : selected.city
      : null;
    if (selected.neighborhood) {
      if (selected.neighborhood.includes(name)) {
        const updatedNeighborhood = selected.neighborhood.filter(
          (n) => n !== name
        );
        update({
          neighborhood: updatedNeighborhood.length ? updatedNeighborhood : null,
        });

        updateLocations({ coordinates, neighborhood: name });
      } else {
        update({
          neighborhood: [...selected.neighborhood, name],
          city: updatedCities
            ? updatedCities.length
              ? updatedCities
              : null
            : null,
        });

        updateLocations({
          center: location,
          coordinates,
          neighborhood: name,
          city,
        });
      }
    } else {
      update({
        neighborhood: [name],
        city: updatedCities
          ? updatedCities.length
            ? updatedCities
            : null
          : null,
      });
      updateLocations({
        center: location,
        coordinates,
        neighborhood: name,
        city,
      });
      //updateLocations({ reset: true });
    }
  };

  const availableLocations = () =>
    locations.map((area, index) => (
      <TreeItem
        key={area.name}
        item={area}
        locationIndex={index}
        selected={selected}
        onAreaClick={onAreaHandler}
        onCityClick={onCityHandler}
        onNeighbourhoodClick={onNeighbourhoodHandler}
      />
    ));

  const resetAreas = () => {
    updateLocations({ reset: true });
    update({
      area: null,
      city: null,
      neighborhood: null,
    });
  };

  return open ? (
    <>
      {availableLocations()}
      <div className={styles.any}>
        <Radio selected={!selected.area} onClick={resetAreas} />
        <span className={styles.label}>Any</span>
      </div>
    </>
  ) : null;
};

export default TreeSelect;
