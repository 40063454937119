import { useDispatch } from "react-redux";
import numeral from "numeral";
import RightNavbar from "./components/RightNavbar/right-navbar.component";
import { openAuthModal, openModal } from "../../../../redux/ui/ui.actions";
import styles from "./right-section.module.scss";

const RightSection = ({ user, property, language, isFavourite }) => {
  const dispatch = useDispatch();
  const data =
    property.details.numBedrooms +
    " bed " +
    property.details.propertyType +
    " at " +
    property.address.unitNumber +
    " - " +
    property.address.streetNumber +
    " " +
    property.address.streetName +
    " " +
    property.address.streetSuffix +
    ", " +
    property.address.area +
    " for " +
    numeral(property.listPrice).format("$1,000,000");

  const shareListingHandler = () => {
    dispatch(
      openModal({
        current: "shareListing",
        data: {
          mlsNumber: property.mlsNumber,
          text: data,
          listing: property,
          isFavourite,
        },
      })
    );
  };
  const requestFormHandler = () => {
    dispatch(
      openModal({
        current: "requestForm",
        overflow: true,
        data: {
          address: property.address,
          mlsNumber: property.mlsNumber,
          status: property.type,
          active: property.status === "A" ? true : false,
        },
      })
    );
  };

  const offerFormHandler = () => {
    dispatch(
      openModal({
        current: "requestForm",
        overflow: true,
        data: {
          offer: true,
          address: property.address,
          mlsNumber: property.mlsNumber,
          status: property.type,
          active: property.status === "A" ? true : false,
          price: property.listPrice,
        },
      })
    );
  };

  return (
    <div className={styles.container}>
      <RightNavbar
        listing={property}
        shareListingHandler={shareListingHandler}
        requestFormHandler={requestFormHandler}
        offerFormHandler={offerFormHandler}
        language={language}
      />
    </div>
  );
};

export default RightSection;
