import { useDispatch } from "react-redux";
import { ReactComponent as GoogleIcon } from "../../../../../../../assets/svg/google-icon.svg";
import { ReactComponent as AppleIcon } from "../../../../../../../assets/svg/apple.svg";
import { ReactComponent as FacebookIcon } from "../../../../../../../assets/svg/facebook.svg";

import styles from "./federated-btn.module.scss";
import { federatedSignInStart } from "../../../../../../../redux/user/user.actions";

const FederatedBtn = ({ signUp }) => {
  const dispatch = useDispatch();

  return (
    <div className={styles.federatedRow}>
      <div
        className={styles.button}
        onClick={() => dispatch(federatedSignInStart("Google"))}
      >
        <GoogleIcon className={styles.icon} />
        <span className={styles.label}>
          {signUp ? "Sign up " : "Sign in "}with Google
        </span>
      </div>
      <div
        className={styles.button}
        onClick={() => dispatch(federatedSignInStart("SignInWithApple"))}
      >
        <AppleIcon className={styles.icon} />
        <span className={styles.label}>
          {signUp ? "Sign up " : "Sign in "}with Apple
        </span>
      </div>
      <div
        className={styles.button}
        onClick={() => dispatch(federatedSignInStart("Facebook"))}
      >
        <FacebookIcon className={styles.icon} />
        <span className={styles.label}>
          {signUp ? "Sign up " : "Sign in "}with Facebook
        </span>
      </div>
    </div>
  );
};

export default FederatedBtn;
