import { useState, useEffect } from "react";
import {
  IonItem,
  IonList,
  IonRadioGroup,
  IonLabel,
  IonRadio,
} from "@ionic/react";
import withFilters from "../../../../../HOC/withFilters/with-filters";
import styles from "./locker-filter.module.scss";

const LockerFilter = ({ extraFilters: { locker }, updateExtraFilters }) => {
  const [selected, setSelected] = useState(locker ? locker : "any");

  useEffect(() => {
    updateExtraFilters({
      locker: selected === "any" ? null : selected,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);
  return (
    <IonList>
      <IonRadioGroup
        value={selected}
        onIonChange={(e) => setSelected(e.detail.value)}
      >
        <IonItem lines="none">
          <IonLabel
            className={`${styles.label} ${
              selected === "any" && styles.selected
            }`}
          >
            Any
          </IonLabel>
          <IonRadio slot="end" value="any" className={styles.radio} />
        </IonItem>
        <IonItem lines="none">
          <IonLabel
            className={`${styles.label} ${
              selected === "yes" && styles.selected
            }`}
          >
            Yes
          </IonLabel>
          <IonRadio slot="end" value="yes" className={styles.radio} />
        </IonItem>
        <IonItem lines="none">
          <IonLabel
            className={`${styles.label} ${
              selected === "no" && styles.selected
            }`}
          >
            No
          </IonLabel>
          <IonRadio slot="end" value="no" className={styles.radio} />
        </IonItem>
      </IonRadioGroup>
    </IonList>
  );
};

export default withFilters(LockerFilter);
