import PhotosSection from "./component/PhotoSection/photo-section.component";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./photos.module.scss";
import _ from "lodash";
import { selectCurrentClient } from "../../../../../redux/client/client.selectors";
import { buildAddress } from "../../../../../utils/functions";

const Photos = ({ photoItems, tourItem, listing, handleDelete, disabled }) => {
  const client = useSelector(selectCurrentClient);
  const [photos, setPhotos] = useState();

  useEffect(() => {
    const groupPhotos = () =>
      _.mapValues(_.groupBy(photoItems, (photo) => photo.owner));
    if (photoItems && client) {
      setPhotos(groupPhotos());
    }
  }, [client, photoItems]);

  return (
    <>
      <div className={styles.photoDetails}>
        <div className={styles.subtitle}>
          {tourItem.agentId === client.id ||
          tourItem.showingAgentId === client.id ||
          tourItem.members.includes(client.id)
            ? `All photos taken for ${buildAddress(
                listing.address
              )}. by all tour
          attendees.`
            : "Only lead/showing agents can post a photo."}
        </div>

        {(tourItem.agentId === client.id ||
          tourItem.showingAgentId === client.id ||
          tourItem.members.includes(client.id)) && (
          <PhotosSection
            disabled={disabled}
            handleDelete={handleDelete}
            isCurrentUser={true}
            photos={photos ? photos[client.id] : []}
            memberData={JSON.parse(tourItem.membersData).find(
              (d) => d.id === client.id
            )}
            isApp={true}
          />
        )}

        {photos &&
          Object.keys(photos).map((key) => {
            if (photos[key][0].owner === client.id) {
              return null;
            } else {
              return (
                <PhotosSection
                  key={key}
                  isCurrentUser={false}
                  photos={photos[key]}
                  memberData={JSON.parse(tourItem.membersData).find(
                    (d) => d.id === photos[key][0].owner
                  )}
                  isApp={true}
                />
              );
            }
          })}
      </div>
    </>
  );
};

export default Photos;
