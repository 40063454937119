import withFilters from "../../../../../HOC/withFilters/with-filters";
import styles from "./type-filter.module.scss";
import { residentialTypes, commercialTypes } from "../../data/filters.data";
import { IonCheckbox, IonItem, IonLabel, IonList } from "@ionic/react";

const TypeFilter = ({
  filters: { propertyType, class: selectedClass },
  updateSingleFilter,
}) => {
  const types = selectedClass.includes("residential")
    ? residentialTypes
    : commercialTypes;

  const handleOnChange = (value) => {
    if (!propertyType) {
      updateSingleFilter({
        propertyType: [value],
      });
    } else {
      updateSingleFilter({
        propertyType: propertyType.includes(value)
          ? propertyType.filter((p) => p !== value).length
            ? propertyType.filter((p) => p !== value)
            : null
          : [...propertyType, value],
      });
    }
  };

  const handleAllChange = () => {
    updateSingleFilter({ propertyType: null });
  };

  return (
    <IonList>
      <IonItem lines="none">
        <IonLabel className={styles.label}>Any type</IonLabel>
        <IonCheckbox
          disabled={!propertyType}
          onClick={handleAllChange}
          indeterminate={
            propertyType
              ? propertyType.length === types.length
                ? false
                : false
              : false
          }
          className={styles.checkbox}
          checked={
            propertyType
              ? propertyType.length === types.length
                ? true
                : false
              : true
          }
        />
      </IonItem>
      {types.map(({ value, name }, i) => (
        <IonItem key={i} lines="none">
          <IonLabel
            className={`${styles.label} ${
              propertyType && propertyType.includes(value) && styles.selected
            }`}
          >
            {name}
          </IonLabel>
          <IonCheckbox
            onClick={() => handleOnChange(value)}
            slot="end"
            value={value}
            checked={
              propertyType
                ? propertyType.includes(value)
                  ? true
                  : false
                : false
            }
            className={styles.checkbox}
          />
        </IonItem>
      ))}
    </IonList>
  );
};

export default withFilters(TypeFilter);
