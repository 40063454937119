import { useState, useEffect, useRef, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { IonIcon, IonSpinner } from "@ionic/react";
import {
  chatbubblesOutline,
  eyeOutline,
  peopleOutline,
  todayOutline,
} from "ionicons/icons";
import styles from "./tour-schedule-container.module.scss";
import DetailsHeader from "../../../../components/TourDetailsHeader/tour-details-header.module";
import CardContainer from "../../../Tours/component/CardContainer/card-container.component";
import TourSchedule from "./component/TourSchedule/tour-schedule.component";
import TourDetails from "./component/TourDetails/tour-details.component";
import TourAttendees from "./component/TourAttendees/tour-attendees.component";
import QuickContact from "./component/QuickContact/quick-contact.component";
import {
  getClientSingleTour,
  getSingleTourItem,
} from "../../../../amplify/graphql.utils";
import { getListing } from "../../../../api/repliers";
import { API, graphqlOperation } from "aws-amplify";
import { onUpdateTourItemByTourId } from "../../../../graphql/subscriptions";
import moment from "moment";

const TourScheduleContainer = ({ tour }) => {
  const history = useHistory();
  const [showDetails, setShowDetails] = useState(false);
  const [tourItems, setTourItems] = useState();
  const [loading, setLoading] = useState(false);
  const subscriptionRef = useRef([]);

  const compareItems = (a, b) => a.order - b.order;

  const fetchSingleItem = useCallback(
    async (i) => {
      try {
        let item = {};
        item.tourItem = await getSingleTourItem(i.id);
        item.order = item.tourItem.order;
        item.listing = await getListing(i.mlsNumber);
        return item;
      } catch (err) {
        history.replace("/tours");
      }
    },
    [history]
  );

  const fetchItems = useCallback(
    async (inBackground = false) => {
      !inBackground && setLoading(true);
      try {
        const tourDetails = await getClientSingleTour(tour.id);
        const promises = [];
        for (let i = 0; i < tourDetails.tourItems.items.length; i++) {
          promises.push(fetchSingleItem(tourDetails.tourItems.items[i]));
        }
        const items = await Promise.all(promises);
        setTourItems(items.sort(compareItems));
      } catch (err) {}
      !inBackground && setLoading(false);
    },
    [fetchSingleItem, tour.id]
  );

  const subscribe = useCallback(async () => {
    subscriptionRef.current.forEach((s) => s.unsubscribe());
    const subscriptions = [];
    const s1 = await API.graphql(
      graphqlOperation(onUpdateTourItemByTourId, { tourId: tour.id })
    ).subscribe({
      next: async () => {
        fetchItems(true);
      },
    });
    subscriptions.push(s1);
    subscriptionRef.current = subscriptions;
  }, [fetchItems, tour.id]);

  useEffect(() => {
    if (showDetails) {
      fetchItems();
    }
  }, [fetchItems, history, showDetails, tour.id]);

  useEffect(() => {
    subscribe();
    return () => {
      subscriptionRef.current.forEach((s) => s.unsubscribe());
    };
  }, [subscribe]);

  return (
    <CardContainer>
      <DetailsHeader
        title={tour.title}
        icon={todayOutline}
        hasTag={false}
        isTourSchedule={true}
        isOpen={showDetails}
        setIsOpen={setShowDetails}
        scheduleLabel={
          tour && tour.date && moment(tour.date).format("MMM D, YYYY")
        }
        status={tour.status}
      />

      {showDetails ? (
        loading ? (
          <div className={styles.loading}>
            <IonSpinner name="dots" className={styles.spinner} />
          </div>
        ) : (
          <div className={styles.details}>
            <TourSchedule tourItems={tourItems} />
            <div className={styles.tourSection}>
              <div className={styles.tourSectionHeader}>
                <div>
                  <IonIcon icon={eyeOutline} />
                  <span>Tour details</span>
                </div>
                <div>
                  <IonIcon icon={peopleOutline} />
                  <span>Tour attendees</span>
                </div>
                <div>
                  <IonIcon icon={chatbubblesOutline} />
                  <span>Quick contact</span>
                </div>
              </div>
              <div className={styles.tourSectionBody}>
                <TourDetails tourDetails={tour} tourItems={tourItems} />

                <TourAttendees tourAttendees={JSON.parse(tour.membersData)} />

                <QuickContact
                  quickContact={{
                    leadingAgent: tour.agent,
                    showingAgent: tour.showingAgent,
                  }}
                />
              </div>
            </div>
          </div>
        )
      ) : null}
    </CardContainer>
  );
};

export default TourScheduleContainer;
