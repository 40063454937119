import { useState } from "react";
import Select from "../Form/Select/select.component";
import RadioFilter from "../RadioFilter/radio-filter.component";
import styles from "./sort-by.module.scss";
import { ReactComponent as SortIcon } from "../../assets/svg/sort.svg";
import useComponentVisible from "../../hooks/useComponentVisible";
import withFilters from "../../HOC/withFilters/with-filters";
import { IonItem, IonSelect, IonSelectOption } from "@ionic/react";
import { chevronDownOutline } from "ionicons/icons";
import { capitalize } from "../../utils/functions";

const sortOptions = {
  tours: [
    { label: "date", value: "date", checked: true },
    { label: "status", value: "status", checked: false },
    { label: "title", value: "title", checked: false },
    { label: "client", value: "client", checked: false },
    { label: "showing agent", value: "agent", checked: false },
    { label: "number of stops", value: "stops", checked: false },
  ],
  search: [
    { label: "date", value: "date", checked: true },
    { label: "price", value: "price", checked: false },
    { label: "beds", value: "beds", checked: false },
    { label: "baths", value: "baths", checked: false },
    { label: "sqft", value: "sqft", checked: false },
  ],
  favourites: [
    { label: "price", value: "price", checked: false },
    { label: "date", value: "date", checked: true },
    { label: "status", value: "status", checked: false },
    { label: "beds", value: "beds", checked: false },
    { label: "baths", value: "baths", checked: false },
    //{ label: "sqft", value: "sqft", checked: false },
  ],
  savedSearches: [
    { label: "date", value: "date", checked: true },
    { label: "name", value: "name", checked: false },
  ],
};

const SortBy = ({
  sortBy,
  setSortBy,
  filters: { type, status, lastStatus },
  mode,
  view,
  isApp = false,
}) => {
  const { ref, isOpen, setIsOpen } = useComponentVisible(false);
  const [options, setOptions] = useState(sortOptions[mode]);

  const changeDirection = () => {
    if (sortBy.direction === "asc") {
      setSortBy({ ...sortBy, direction: "desc" });
    } else {
      setSortBy({ ...sortBy, direction: "asc" });
    }
  };

  const changeSort = ({ label }) => {
    const tempOptions = options.map((option) => {
      if (option.label !== label) {
        option.checked = false;
      } else {
        option.checked = true;
      }
      return option;
    });

    setOptions(tempOptions);

    setSortBy({ ...sortBy, value: label });
    setIsOpen(false);
  };

  return isApp ? (
    <div
      ref={ref}
      className={`${styles.container} ${
        mode === "search" && view === "map" && styles.searchMode
      } ${mode === "tours" && styles.toursMode}`}
    >
      <IonItem
        className={styles.sortContainer}
        lines="none"
        detail={true}
        detailIcon={chevronDownOutline}
      >
        <span className={styles.title}>Sort by</span>
        <span className={styles.value}>{sortBy.value}</span>
        <IonSelect
          className={styles.select}
          value={sortBy.value}
          cancelText="Cancel"
          okText="Ok"
          onIonChange={(e) => setSortBy({ ...sortBy, value: e.detail.value })}
          interface="alert"
          interfaceOptions={{ cssClass: "alert" }}
        >
          {options.map((opt, i) => (
            <IonSelectOption
              key={i}
              className={styles.option}
              value={opt.value}
            >
              {capitalize(opt.label)}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
      <SortIcon
        onClick={changeDirection}
        className={`${styles.icon} ${
          sortBy.direction === "desc" ? "" : styles.reversedIcon
        }`}
      />
    </div>
  ) : (
    <div
      ref={ref}
      className={`${styles.container} ${
        mode === "search" && view === "map" && styles.searchMode
      }`}
    >
      <span className={styles.text}>Sort by</span>
      <span className={styles.button}>
        <Select
          title={sortBy.value}
          buttonStyle={{
            background: "transparent",
            border: "none",
          }}
          open={isOpen}
          dropdown={true}
          onClick={() => setIsOpen(!isOpen)}
        />
        <RadioFilter
          open={isOpen}
          value={sortBy.value}
          options={options}
          onClick={(option) => changeSort(option)}
        />
      </span>
      <SortIcon
        onClick={changeDirection}
        className={`${styles.icon} ${
          sortBy.direction === "desc" ? "" : styles.reversedIcon
        }`}
      />
    </div>
  );
};

export default withFilters(SortBy);
