import { useState, useRef, createRef } from "react";
import { useSelector } from "react-redux";
import { selectCurrentClient } from "../../../redux/client/client.selectors";
import Button from "../../Form/Button/button.component";
import Input from "../../Form/Input/input.component";
import { changeEmail } from "../../../data/customModal";
import styles from "./change-email-modal.module.scss";

const ChangeEmailModal = ({ setType, language }) => {
  const client = useSelector(selectCurrentClient);
  const [loading, setLoading] = useState(false);
  const inputRefs = useRef({
    new_email: createRef(),
  });
  const [formData, setFormData] = useState({
    new_email: "",
  });
  const handleChange = (value, name) => {
    inputRefs.current[name].current.validate();
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const notifications = JSON.parse(JSON.parse(client.notifications));

  const handleConfirm = () => {
    setLoading(true);
    setLoading(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>{changeEmail[language].header}</div>
      <div className={styles.subtitle}>{changeEmail[language].subtitle}</div>

      <div className={styles.content}>
        <div className={styles.label}>
          {changeEmail[language].label}
          <span>{notifications.email}</span>
        </div>
        <div>
          <Input
            required
            name="new_email"
            label={changeEmail[language].input}
            placeholder={changeEmail[language].input}
            type="email"
            mode="email"
            ref={inputRefs.current.new_email}
            value={formData["new_email"]}
            onChange={(value, name) => handleChange(value, name)}
            validation="required|email"
          />
        </div>
      </div>
      <Button
        title={changeEmail[language].button}
        type="purple"
        style={{ height: "5rem", width: "100%", border: "1px solid #7158ad" }}
        onClick={handleConfirm}
        loading={loading}
      />
    </div>
  );
};

export default ChangeEmailModal;
