import { createSelector } from "reselect";

export const selectUI = (state) => state.ui;

export const selectUIModal = createSelector([selectUI], (ui) => ui.modal);

export const selectUIAuthModal = createSelector(
  [selectUI],
  (ui) => ui.authModal
);

export const selectUIBadge = createSelector([selectUI], (ui) => ui.badge);

export const selectUINotifications = createSelector(
  [selectUI],
  (ui) => ui.notifications
);

export const selectUIMenu = createSelector([selectUI], (ui) => ui.menu);

export const selectUIMenuTab = createSelector(
  [selectUIMenu],
  (menu) => menu.tab
);

export const selectUIToast = createSelector([selectUI], (ui) => ui.toast);

export const selectDrawMode = createSelector([selectUI], (ui) => ui.drawMode);

export const selectUpdatedFavourite = createSelector(
  [selectUI],
  (ui) => ui.updatedFavourite
);
