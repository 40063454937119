import { graphqlOperation, API, Auth, Storage } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import awsExports from "../aws-exports-custom";
import {
  getAgent,
  getClient,
  getTour,
  listAgents,
  listClients,
  listSearchs,
  getTourItem,
  getClientByBrokerage,
  getSearch,
  getFavouriteByMlsNumber,
  listFavourites,
  listFeatureds as listFeaturedItems,
  listHomeCards as listHomeCardItems,
} from "../graphql/queries";
import {
  listTours,
  getTourSummary,
  getClientTour,
  getClientToursByShareStatus,
  getTourShareStatus,
} from "../graphql/custom-queries";
import {
  updateClient as update,
  updateAgent,
  createFavourite,
  deleteFavourite,
  updateFavourite,
  createSearch,
  deleteSearch,
  updateSearch,
  createTour,
  updateTour as updateTourItem,
  updateTourItem as updateTourListing,
  createClientTour,
  createTourItem,
  deleteClientTour,
  deleteTourItem,
  createNote,
  updateNote,
  updateClientTour,
  createReview,
  updateReview,
  createPhoto,
  deletePhoto,
  createRecording,
  deleteRecording,
  createProfile,
  updateProfile,
} from "../graphql/mutations";
import { store } from "../redux/store";
import { fetchClientStart } from "../redux/client/client.actions";
import {
  createFavourite as createRepliersFavourite,
  removeFavourite as removeRepliersFavourite,
  createSearch as createRepliersSearch,
  removeSearch as removeRepliersSearch,
} from "../api/repliers";
import { listTourItems } from "../graphql/queries";
import { shareTourWithClient } from "../api/ettie";
import gql from "graphql-tag";
import { addUserToDynamoDB } from "./auth.utils";

export const getHomeItems = async () => {
  try {
    const guest = store.getState().user.guest;

    if (guest) {
      const {
        data: { listFeatureds },
      } = await guest.query({ query: gql(listFeaturedItems) });
      const {
        data: { listHomeCards },
      } = await guest.query({ query: gql(listHomeCardItems) });

      return { cards: listHomeCards.items, featureds: listFeatureds.items };
    }
  } catch (err) {
    return;
  }
};

export const fetchClient = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();

    const isAgent =
      user.signInUserSession.accessToken.payload["cognito:groups"] &&
      user.signInUserSession.accessToken.payload["cognito:groups"].includes(
        "Agents"
      );

    if (isAgent) {
      const { data } = await API.graphql(
        graphqlOperation(getAgent, {
          id: user.username,
        })
      );

      return data.getAgent ? { ...data.getAgent, isAgent: true } : null;
    } else {
      const { data } = await API.graphql(
        graphqlOperation(getClient, {
          id: user.username,
        })
      );
      if (!data.getClient) {
        const client = await addUserToDynamoDB(user);
        return client;
      }

      return data.getClient;
    }
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const createNewProfile = async ({ clientId, profile }) => {
  const {
    data: { createProfile: newProfile },
  } = await API.graphql(
    graphqlOperation(createProfile, {
      input: { clientId, ...profile },
    })
  );
  if (newProfile && newProfile.id) {
    await updateClient({ id: clientId, updates: { profileId: newProfile.id } });
  } else {
    throw new Error("Something went wrong!");
  }
};

export const updateCurrentProfile = async ({ id, profile }) => {
  await API.graphql(
    graphqlOperation(updateProfile, { input: { id, ...profile } })
  );
};

export const updateClient = async ({ id, updates }) => {
  const user = await Auth.currentAuthenticatedUser();

  const isAgent =
    user.signInUserSession.accessToken.payload["cognito:groups"].includes(
      "Agents"
    );
  try {
    if (isAgent) {
      await API.graphql(
        graphqlOperation(updateAgent, {
          input: { id: id, ...updates },
        })
      );
    } else {
      await API.graphql(
        graphqlOperation(update, {
          input: { id: id, ...updates },
        })
      );
    }

    store.dispatch(fetchClientStart());
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getSearchItem = async (id) => {
  const { data } = await API.graphql(graphqlOperation(getSearch, { id }));

  return data.getSearch;
};

export const getSearchItems = async () => {
  const { data } = await API.graphql(graphqlOperation(listSearchs));

  return data.listSearchs.items;
};

export const getFavouriteItems = async () => {
  const { data } = await API.graphql(graphqlOperation(listFavourites));

  return data.listFavourites.items;
};

export const getFavouriteItem = async (mlsNumber) => {
  const { data } = await API.graphql(
    graphqlOperation(getFavouriteByMlsNumber, { mlsNumber })
  );

  return data.getFavouriteByMlsNumber.items[0];
};

export const addFavourite = async (clientId, clientRepliersId, listing) => {
  const { mlsNumber } = listing;

  const fav = await getFavouriteItem(listing.mlsNumber);
  if (!fav) {
    const { data } = await API.graphql(
      graphqlOperation(createFavourite, {
        input: {
          clientId,
          listing: JSON.stringify(listing),
          mlsNumber,
          //repliersID,
          notification: false,
        },
      })
    );

    return data?.createFavourite;
  } else {
    return fav;
  }
};

export const removeFavourite = async (favourite) => {
  if (favourite.repliersID) {
    await removeRepliersFavourite(favourite.repliersID);
  }

  const { data } = await API.graphql(
    graphqlOperation(deleteFavourite, {
      input: { id: favourite.id },
    })
  );

  return data.deleteFavourite;
};

export const updateFavouriteNotification = async (favourite) => {
  const { data } = await API.graphql(
    graphqlOperation(updateFavourite, {
      input: { id: favourite.id, notification: !favourite.notification },
    })
  );

  return data.updateFavourite;
};

export const updateFavouriteListing = async (listing) => {
  const favourite = await getFavouriteItem(listing.mlsNumber);
  if (favourite) {
    const { data } = await API.graphql(
      graphqlOperation(updateFavourite, {
        input: {
          id: favourite.id,
          listing: JSON.stringify(listing),
          notification:
            listing.status === "U" && favourite.notification
              ? false
              : favourite.notification,
        },
      })
    );

    return data;
  }
  return;
};

export const addSearch = async (clientId, clientRepliersId, search) => {
  const repliersID = await createRepliersSearch(
    clientRepliersId,
    search.filters
  );

  if (!repliersID) throw new Error("Search is too big.");

  const { data } = await API.graphql(
    graphqlOperation(createSearch, {
      input: {
        name: search.searchName,
        clientId,
        value: JSON.stringify({
          filters: search.filters,
          extra: search.extraFilters,
          locations: search.mapLocations,
          drawMode: search.drawMode.active,
          schoolMode: search.schoolMode.active,
          schoolName:
            search.schoolMode.active && search.schoolMode.schools.length
              ? search.schoolMode.schools.length > 1
                ? "Multiple"
                : search.schoolMode.schools[0].name
              : undefined,
          schoolId:
            search.schoolMode.active && search.schoolMode.schools.length
              ? search.schoolMode.schools.length > 1
                ? undefined
                : search.schoolMode.schools[0].id
              : undefined,
          schools: search.schoolMode.active
            ? search.schoolMode.schools?.map((s) => {
                return { id: s.id, name: s.name };
              })
            : null,
        }),
        repliersID,
        notification: search.alert,
      },
    })
  );

  return data;
};

export const updateSavedSearch = async ({ id, notification, name }) => {
  const { data } = await API.graphql(
    graphqlOperation(updateSearch, {
      input: { id, name, notification },
    })
  );

  return data;
};

export const removeSearch = async (search) => {
  await removeRepliersSearch(search.repliersID);

  const { data } = await API.graphql(
    graphqlOperation(deleteSearch, {
      input: { id: search.id },
    })
  );

  return data;
};

export const fetchTours = async () => {
  let data = [];
  let token;
  try {
    const {
      data: { listTours: tours },
    } = await API.graphql(graphqlOperation(listTours));
    data = [...tours.items];
    token = tours.nextToken;

    while (token) {
      const {
        data: { listTours: tours },
      } = await API.graphql(graphqlOperation(listTours));

      data = [...data, ...tours.items];
      if (tours.nextToken === token) {
        token = undefined;
      } else {
        token = tours.nextToken;
      }
    }

    return data;
  } catch (error) {
    console.log(error);
    throw Error(error);
  }
};

export const getSingleTour = async (tourId) => {
  const { data } = await API.graphql(graphqlOperation(getTour, { id: tourId }));
  return data.getTour;
};

export const getSingleTourItem = async (tourItemId) => {
  const { data } = await API.graphql(
    graphqlOperation(getTourItem, { id: tourItemId })
  );
  return data.getTourItem;
};

export const fetchTourItems = async (tourId) => {
  const filter = {
    tourId: {
      eq: tourId,
    },
  };

  const { data } = await API.graphql(
    graphqlOperation(listTourItems, { filter })
  );

  return data.listTourItems.items;
};

export const addTour = async (tour) => {
  const creator = store.getState().client.currentClient.id;
  try {
    const { data } = await API.graphql(
      graphqlOperation(createTour, {
        input: {
          creator,
          status: "draft",
          showingAgentId: null,
          shared: "unshared",
          ...tour,
        },
      })
    );
    return data.createTour;
  } catch (error) {
    console.log(error);
    throw Error(error);
  }
};

export const updateTour = async (updates) => {
  try {
    const { data } = await API.graphql(
      graphqlOperation(updateTourItem, { input: { ...updates } })
    );
    return data.updateTour;
  } catch (err) {
    console.log(err);
    throw Error(err);
  }
};

export const addClients = async ({ tourId, clients, agentId }) => {
  const creator = store.getState().client.currentClient.id;

  clients.forEach(
    async (client) =>
      await API.graphql(
        graphqlOperation(createClientTour, {
          input: { creator, tourId, clientId: client, agentId },
        })
      )
  );
};

export const removeClients = async (items) => {
  items.forEach(
    async (item) =>
      await API.graphql(
        graphqlOperation(deleteClientTour, {
          input: { id: item.id },
        })
      )
  );
};

export const addListingToTour = async ({
  tourId,
  agentId,
  showingAgentId,
  members,
  membersData,
  mlsNumber,
  status,
  entryInfo,
  startTime,
  endTime,
  order,
}) => {
  const creator = store.getState().client.currentClient.id;
  const { data } = await API.graphql(
    graphqlOperation(createTourItem, {
      input: {
        creator,
        tourId,
        agentId,
        showingAgentId,
        members,
        membersData,
        status,
        entryInfo,
        mlsNumber,
        startTime,
        endTime,
        order,
      },
    })
  );

  return data.createTourItem;
};

export const updateTourListingOrder = async ({ id, order }) => {
  try {
    const { data } = await API.graphql(
      graphqlOperation(updateTourListing, { input: { id, order } })
    );

    return data.updateTourItem;
  } catch (err) {
    console.log(err);
  }
};

export const updateTourListingItem = async (updates) => {
  const { data } = await API.graphql(
    graphqlOperation(updateTourListing, { input: { ...updates } })
  );

  return data.updateTourItem;
};

export const updateTourClientItem = async (updates) => {
  const { data } = await API.graphql(
    graphqlOperation(updateClientTour, { input: { ...updates } })
  );

  return data.updateClientTour;
};

export const removeTourListingItem = async (id) => {
  await API.graphql(graphqlOperation(deleteTourItem, { input: { id } }));
};

export const getAgents = async () => {
  const { data } = await API.graphql(graphqlOperation(listAgents));
  return data.listAgents.items;
};

export const getClients = async (brokerageId, search) => {
  let filter = {
    or: [],
  };

  if (search)
    filter.or.push(
      { givenName: { contains: search } },
      { familyName: { contains: search } },
      { email: { contains: search } }
    );

  const { data } = await API.graphql(
    graphqlOperation(getClientByBrokerage, {
      brokerageId,
      filter: search ? filter : null,
    })
  );

  return data.getClientByBrokerage.items;
};

export const getTourClients = async ({ clients }) => {
  if (!clients) return;
  let filter = {
    or: [],
  };

  clients.forEach((c) => filter.or.push({ id: { eq: c } }));

  const { data } = await API.graphql(
    graphqlOperation(listClients, { filter: filter })
  );

  return data.listClients.items;
};

export const getSummaryForTour = async (id) => {
  const { data } = await API.graphql(graphqlOperation(getTourSummary, { id }));
  return data.getTour;
};

export const createShowingNote = async ({ content, tourItemId }) => {
  const { data } = await API.graphql(
    graphqlOperation(createNote, { input: { content, tourItemId } })
  );
  return data.createNote;
};

export const updateShowingNote = async (updates) => {
  const { data } = await API.graphql(
    graphqlOperation(updateNote, { input: { ...updates } })
  );
  return data.updateNote;
};

export const fetchClientTours = async () => {
  const { data } = await API.graphql(
    graphqlOperation(getClientToursByShareStatus, { shared: "shared" })
  );

  return data.getToursByShareStatus;
};

export const getShareStatus = async (id) => {
  const { data } = await API.graphql(
    graphqlOperation(getTourShareStatus, { id })
  );

  return data.getTour.shared === "shared";
};

export const shareTour = async (id) => {
  const res = await API.graphql(graphqlOperation(getTour, { id: id }));
  const tour = res.data.getTour;
  const hasClient = tour.clients.items.length > 0;
  if (hasClient) {
    const client = JSON.parse(tour.membersData).find(
      (c) => c.role === "Client"
    );
    if (client) {
      const { data } = await API.graphql(
        graphqlOperation(updateTourItem, { input: { id, shared: "shared" } })
      );

      await shareTourWithClient({
        name: client.givenName,
        client: { email: client.email, id: client.id },
        tourId: id,
      });
      return data.updateTour.shared;
    } else {
      throw Error("C");
    }
  } else {
    throw Error("C");
  }
};

export const getClientSingleTour = async (id) => {
  const { data } = await API.graphql(graphqlOperation(getClientTour, { id }));
  return data.getTour;
};

export const submitReview = async ({ tourItemId, rate }) => {
  const { data } = await API.graphql(
    graphqlOperation(createReview, { input: { tourItemId, rate } })
  );

  return data.createReview;
};

export const updateClientReview = async ({ id, tourItemId, rate }) => {
  const { data } = await API.graphql(
    graphqlOperation(updateReview, { input: { id, tourItemId, rate } })
  );

  return data.updateReview;
};

export const uploadPhoto = async ({ file, tourItemId }) => {
  const addImageToDB = async (image) => {
    await API.graphql(graphqlOperation(createPhoto, { input: image }));
  };
  const name = uuidv4();

  const buf = Buffer.from(file.base64String, "base64");

  try {
    await Storage.put(name, buf, { contentType: "image/jpeg" })
      .then(async (res) => {
        const image = {
          tourItemId,
          path: res.key,
          file: {
            bucket: awsExports.aws_user_files_s3_bucket,
            region: awsExports.aws_user_files_s3_bucket_region,
            key: "public/" + name,
          },
        };

        await addImageToDB(image);
      })
      .catch((err) => console.log(err));
  } catch (err) {
    console.log(err);
  }
};

export const removePhoto = async ({ id, path }) => {
  await Storage.remove(path).then(async (result) => {
    await API.graphql(graphqlOperation(deletePhoto, { input: { id } }));
  });
};

export const uploadRecording = async ({ file, tourItemId }) => {
  const addRecordingToDB = async (recording) => {
    await API.graphql(graphqlOperation(createRecording, { input: recording }));
  };
  const name = uuidv4();

  try {
    await Storage.put(name, file, { contentType: "audio/wav" })
      .then(async (res) => {
        const recording = {
          tourItemId,
          path: res.key,
          file: {
            bucket: awsExports.aws_user_files_s3_bucket,
            region: awsExports.aws_user_files_s3_bucket_region,
            key: "public/" + name,
          },
        };

        await addRecordingToDB(recording);
      })
      .catch((err) => console.log(err));
  } catch (err) {
    console.log(err);
  }
};

export const removeRecording = async ({ id, path }) => {
  await Storage.remove(path).then(async (result) => {
    await API.graphql(graphqlOperation(deleteRecording, { input: { id } }));
  });
};
