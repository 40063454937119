import { useState } from "react";
import { IonCard, IonCardHeader, IonImg, IonCardContent } from "@ionic/react";
import DefaultImage from "../../../../../../../../assets/img/no-photo.png";
import styles from "./blur-card.module.scss";
import BlurMessage from "../../../../../../../../pages/Detail/Sections/ComparableSection/BlurCard/BlurMessage/blur-message.component";
import FavoriteIcon from "../../../../../../../../components/ListingCard/components/FavoriteIcon/favorite-icon.component";

const IMAGE_API = "https://cdn.repliers.io";

const AppBlurCard = ({ listing, signInHandler, signUpHandler }) => {
  const [imgLoading, setImgLoading] = useState(true);
  return (
    <>
      <div className={styles.blurMsg}>
        <BlurMessage
          signInHandler={signInHandler}
          signUpHandler={signUpHandler}
        />
      </div>
      <div className={styles.listingCard}>
        <IonCard className={styles.card}>
          <IonCardHeader className={styles.header}>
            <IonImg
              src={`${IMAGE_API}/${listing.images[0]}`}
              onIonError={(e) => (e.target.src = DefaultImage)}
              onIonImgDidLoad={() => setImgLoading(false)}
              className={styles.image}
            />
            <div className={styles.tag}>
              <span className={styles.inactive}>for sale</span>
              <span className={styles.date}>2 days</span>
            </div>
          </IonCardHeader>
          <IonCardContent className={styles.content}>
            <div className={styles.icons}>
              <FavoriteIcon
                listing={listing}
                isApp={true}
                isActiveComparables={false}
              />
            </div>
            <div className={styles.price}>$1,500,000</div>
            <div className={styles.address}>Suite 2230 - 2030 Yonge St.</div>
            <div className={styles.info}>
              <span className={styles.type}>Detached</span>
              <span className={styles.beds}>3 bed</span>
              <span className={styles.baths}>4 bath</span>
            </div>
          </IonCardContent>
        </IonCard>
      </div>
    </>
  );
};

export default AppBlurCard;
