import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectCurrentTour } from "../../../../redux/tour/tour.selectors";
import styles from "./new-tour.module.scss";
import AgentDetails from "./component/agent-details.component";
import TourDetails from "./component/tour-details.component";
import TourAttendees from "./component/tour-attendees.component";

const NewTour = () => {
  const tour = useSelector(selectCurrentTour);
  const [step, setStep] = useState("agentDetails");

  useEffect(() => {
    if (tour) {
      switch (step) {
        case "agentDetails":
          setStep("tourDetails");
          break;
        case "tourDetails":
          setStep("tourAttendees");
          break;
        default:
          return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tour]);

  return (
    <div className={styles.newTour} id="new_tour">
      <div className={styles.title}>Create a new tour</div>
      <div className={styles.subtitle}>
        Enter your tour information – you can always edit it later. Note that
        some content will only be visible by you, the agent. Once you create a
        tour, you can add and edit showings.
      </div>
      {step === "agentDetails" && (
        <AgentDetails tour={tour} next={() => setStep("tourDetails")} />
      )}
      {step === "tourDetails" && (
        <TourDetails tour={tour} back={() => setStep("agentDetails")} />
      )}

      {step === "tourAttendees" && (
        <TourAttendees
          tour={tour}
          back={() => setStep("tourDetails")}
          reset={() => setStep("agentDetails")}
        />
      )}
    </div>
  );
};

export default NewTour;
