import { useState, useEffect, useCallback, useRef, createRef } from "react";
import { useDispatch } from "react-redux";
import {
  IonModal,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonButton,
  IonLoading,
  IonItem,
  IonCheckbox,
  IonLabel,
  IonDatetime,
} from "@ionic/react";
import moment from "moment";
import CustomModalHeader from "../../CustomModalHeader/custom-modal-header.component";
import withClient from "../../../../HOC/withClient/with-client";
import { sendRequestToAgent } from "../../../../api/ettie";
import styles from "./request-form.module.scss";
import Input from "../../../../components/Form/Input/input.component";
import RadioButton from "../../../../components/Form/RadioButton/radio-button.component";
import { addOutline } from "ionicons/icons";
import DateTimePicker from "./components/date-time-picker.component";
import { updateToast } from "../../../../redux/ui/ui.actions";
import { buildAddress, numberWithCommas } from "../../../../utils/functions";
import { updateClient } from "../../../../amplify/graphql.utils";

const RequestForm = ({
  currentClient,
  listing,
  showModal,
  setShowModal,
  tourBooking = false,
  isOffer = false,
  isCashback = false,
  isSale,
  parentRef,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [text, setText] = useState("");
  const [value, setValue] = useState("");
  const [showTime, setShowTime] = useState(false);
  const [addDate, setAddDate] = useState([]);
  const [offer, setOffer] = useState({
    initialValue: true,
    discuss: false,
    value: listing ? "$" + listing.listPrice : "",
    date: null,
    disableDate: false,
  });
  let mlsNumber, status, address, active, type;
  if (!tourBooking && !isCashback) {
    mlsNumber = listing.mlsNumber;
    status = listing.status;
    type = listing.type;
    address = listing.address;
    active = listing.status === "A" ? true : false;
  }

  const createMsg = useCallback(() => {
    if (value.includes("information")) {
      setShowTime(false);
      setAddDate([]);
    }
    let msg = value.includes("information")
      ? active
        ? "I'd like to get more information on "
        : "I'd like to find listings similar to "
      : `I'd like to book ${
          value.includes("virtual") ? "a virtual" : "an in-person"
        } appointment to view `;
    if (address.streetNumber) msg += address.streetNumber + " ";
    if (address.streetName) msg += address.streetName + " ";
    if (address.streetSuffix) msg += address.streetSuffix + ", ";
    if (address.city) msg += address.city;
    msg += ` (${mlsNumber}).`;
    return msg;
  }, [address, mlsNumber, value, active]);

  useEffect(() => {
    if (value) setText(createMsg());
  }, [createMsg, value]);

  const inputRefs = useRef({
    given_name: createRef(),
    family_name: createRef(),
    email: createRef(),
    phone_number: createRef(),
  });

  const [formData, setFormData] = useState({
    given_name: "",
    family_name: "",
    email: "",
    phone_number: "",
  });

  useEffect(() => {
    if (currentClient) {
      setFormData({
        email: currentClient.email,
        given_name: currentClient.givenName,
        family_name: currentClient.familyName,
        phone_number: currentClient.phone
          ? currentClient.phone.substring(2)
          : "",
      });
    }
  }, [currentClient]);

  const handleChange = (value, name) => {
    inputRefs.current[name].current.validate();
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    let isValid = true;
    for (const field of Object.values(inputRefs.current)) {
      const validationResult = field.current.validate(true);
      if (!validationResult) isValid = false;
    }

    if (isValid) {
      setLoading(true);
      setError("");
      const res = await sendRequestToAgent({
        name: `${formData.given_name} ${formData.family_name}`,
        givenName: formData.given_name,
        mlsNumber,
        address: buildAddress(address),
        status,
        email: formData.email,
        phone: formData.phone_number,
        dates: addDate.map((d) => {
          return {
            date: d.date,
            from: moment(d.from).format("hh:mm a"),
            to: moment(d.to).format("hh:mm a"),
          };
        }),
        inPerson: value.includes("in-person"),
        virtual: value.includes("virtual"),
        information: value.includes("information"),
        message: text,
      });
      if (res.success) {
        setLoading(false);
        dispatch(
          updateToast({
            open: true,
            type: "success",
            message: "Request sent successfully.",
          })
        );
        setShowModal(false);
      } else {
        setLoading(false);
        dispatch(
          updateToast({
            open: true,
            type: "error",
            message: "Something went wrong! Please try again.",
          })
        );
      }
    }
  };

  const handleCashbackSubmit = async () => {
    let isValid = true;
    for (const field of Object.values(inputRefs.current)) {
      const validationResult = field.current.validate(true);
      if (!validationResult) isValid = false;
    }

    if (isValid) {
      setLoading(true);
      setError("");
      const res = await sendRequestToAgent({
        name: `${formData.given_name} ${formData.family_name}`,
        givenName: formData.given_name,
        email: formData.email,
        phone: formData.phone_number,
        message: text,
        cashback: true,
      });
      if (res.success) {
        setLoading(false);
        dispatch(
          updateToast({
            open: true,
            type: "success",
            message: "Request sent successfully.",
          })
        );
        setShowModal(false);
      } else {
        setLoading(false);
        dispatch(
          updateToast({
            open: true,
            type: "error",
            message: "Something went wrong! Please try again.",
          })
        );
      }
    }
  };

  const handleTourSubmit = async () => {
    let isValid = true;
    for (const field of Object.values(inputRefs.current)) {
      const validationResult = field.current.validate(true);
      if (!validationResult) isValid = false;
    }

    if (isValid) {
      setLoading(true);
      setError("");
      const res = await sendRequestToAgent({
        name: `${formData.given_name} ${formData.family_name}`,
        givenName: formData.given_name,
        email: formData.email,
        phone: formData.phone_number,
        tourBooking: true,
        message: text,
      });
      if (res.success) {
        await updateClient({
          id: currentClient.id,
          updates: { requestedTour: true },
        });
        setLoading(false);
        dispatch(
          updateToast({
            open: true,
            type: "success",
            message: `${
              tourBooking
                ? "Thanks! You will receive a response within 24 hours."
                : "Request sent successfully."
            }`,
          })
        );
        setShowModal(false);
      } else {
        setLoading(false);
        dispatch(
          updateToast({
            open: true,
            type: "error",
            message: "Something went wrong! Please try again.",
          })
        );
      }
    }
  };

  const handleOfferSubmit = async () => {
    let isValid = true;
    for (const field of Object.values(inputRefs.current)) {
      const validationResult = field.current.validate(true);
      if (!validationResult) isValid = false;
    }

    if (isValid) {
      setLoading(true);
      setError("");
      const res = await sendRequestToAgent({
        name: `${formData.given_name} ${formData.family_name}`,
        givenName: formData.given_name,
        email: formData.email,
        phone: formData.phone_number,
        mlsNumber,
        address: buildAddress(address),
        status,
        offer: {
          ...offer,
          date: offer.disableDate
            ? "Not sure yet"
            : offer.date
            ? moment(offer.date).format("YYYY-MM-DD")
            : "---",
        },
      });
      if (res.success) {
        setLoading(false);
        dispatch(
          updateToast({
            open: true,
            type: "success",
            message: "Request sent successfully.",
          })
        );
        setShowModal(false);
      } else {
        setLoading(false);
        dispatch(
          updateToast({
            open: true,
            type: "error",
            message: "Something went wrong! Please try again.",
          })
        );
      }
    }
  };

  const handleOfferChange = (value) => {
    if (value) {
      let cleanedValue = 0;
      if (typeof value === "string" && value.includes("$"))
        value = value.split("$").pop();

      if (typeof value === "number") {
        cleanedValue = value;
      } else {
        if (!isNaN(+value.replace(/,/g, "")))
          cleanedValue = +value.replace(/,/g, "");
      }

      if (cleanedValue <= 0) cleanedValue = "";

      setOffer({
        ...offer,
        value: offer.initialValue
          ? "$" + numberWithCommas(cleanedValue)
          : cleanedValue,
        initialValue: offer.initialValue && false,
      });
    }
  };

  const handleOfferBlur = () => {
    setOffer({
      ...offer,
      value: offer.value.toString().includes("$")
        ? numberWithCommas(offer.value)
        : "$" + numberWithCommas(offer.value),
    });
  };

  const radioButtonHandler = (label) => {
    setValue(label);
    if (!label.includes("information")) {
      setShowTime(true);
      if (!addDate.length) {
        setAddDate([{ index: 1, date: moment().format(), from: "", to: "" }]);
      }
    }
  };

  const handleMessage = (event) => {
    setText(event.target.value);
  };

  const addDateHandler = () => {
    if (!addDate.length) {
      setAddDate([{ index: 1, date: moment().format(), from: "", to: "" }]);
    } else {
      setAddDate((addDate) => [
        ...addDate,
        {
          index: addDate[addDate.length - 1].index + 1,
          date: moment().format(),
          from: "",
          to: "",
        },
      ]);
    }
  };

  const updateDateHandler = (update) => {
    const dates = [...addDate];
    dates[update.index - 1] = update;
    setAddDate(dates);
  };

  const deleteDateHandler = (index) => {
    const dates = addDate.filter((d) => d.index !== index);
    setAddDate(dates);
  };

  return (
    <IonModal
      isOpen={showModal}
      swipeToClose={true}
      onDidDismiss={() => setShowModal(false)}
      presentingElement={parentRef}
    >
      <CustomModalHeader
        title={
          tourBooking
            ? "Book a tour"
            : isOffer
            ? "Offer form"
            : isCashback
            ? "Ettie à la carte"
            : "Request form"
        }
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <IonContent>
        <IonLoading isOpen={loading} />

        {tourBooking ? (
          <IonGrid className={styles.requestContainer}>
            <IonRow>
              <IonCol className={styles.subtitle}>
                We're excited to work with you! Submit this form and we will be
                in touch soon!
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className={styles.label}>Contact information</IonCol>
            </IonRow>
            <div className={styles.formFields}>
              <IonRow>
                <IonCol>
                  <Input
                    required
                    name="given_name"
                    label="First name"
                    placeholder="First name"
                    type="text"
                    ref={inputRefs.current.given_name}
                    value={formData["given_name"]}
                    defaultValue={currentClient && currentClient.givenName}
                    onChange={(value, name) => handleChange(value, name)}
                    validation="required|min:2"
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <Input
                    required
                    name="family_name"
                    label="Last name"
                    placeholder="Last name"
                    type="text"
                    ref={inputRefs.current.family_name}
                    value={formData["family_name"]}
                    defaultValue={currentClient && currentClient.familyName}
                    onChange={(value, name) => handleChange(value, name)}
                    validation="required|min:2"
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <Input
                    required
                    name="phone_number"
                    label="Phone"
                    placeholder="Phone"
                    type="phone"
                    mode="tel"
                    ref={inputRefs.current.phone_number}
                    value={formData["phone_number"]}
                    defaultValue={
                      currentClient && currentClient.phone
                        ? currentClient.phone.substring(2)
                        : ""
                    }
                    onChange={(value, name) => handleChange(value, name)}
                    validation="required|phone"
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <Input
                    required
                    name="email"
                    label="Email"
                    placeholder="Email"
                    type="email"
                    mode="email"
                    ref={inputRefs.current.email}
                    value={formData["email"]}
                    defaultValue={currentClient && currentClient.email}
                    onChange={(value, name) =>
                      handleChange(value.toLowerCase(), name)
                    }
                    validation="required|email"
                  />
                </IonCol>
              </IonRow>
            </div>
            <IonRow>
              <IonCol>
                <span className={styles.textarealabel}>Message</span>
                <textarea
                  className={styles.textarea}
                  placeholder="I’d like to start a showing tour of some properties I'm interested in."
                  name="textarea"
                  value={text}
                  onChange={handleMessage}
                ></textarea>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonButton
                  shape="round"
                  expand="block"
                  className={styles.button}
                  onClick={handleTourSubmit}
                >
                  Submit
                </IonButton>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className={styles.consent}>
                By submitting this form, I understand Ettie will share my
                information with registered real estate professionals.
              </IonCol>
            </IonRow>
          </IonGrid>
        ) : isOffer ? (
          <IonGrid className={styles.requestContainer}>
            <IonRow>
              <IonCol className={styles.address}>
                {buildAddress(address)}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className={styles.subtitle}>
                Submit this form and an Ettie agent will contact you in the next
                24 hours to assist you in putting in this potential offer.
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className={styles.label}>Contact information</IonCol>
            </IonRow>
            <div className={styles.formFields}>
              <IonRow>
                <IonCol>
                  <Input
                    required
                    name="given_name"
                    label="First name"
                    placeholder="First name"
                    type="text"
                    ref={inputRefs.current.given_name}
                    value={formData["given_name"]}
                    defaultValue={currentClient && currentClient.givenName}
                    onChange={(value, name) => handleChange(value, name)}
                    validation="required|min:2"
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <Input
                    required
                    name="family_name"
                    label="Last name"
                    placeholder="Last name"
                    type="text"
                    ref={inputRefs.current.family_name}
                    value={formData["family_name"]}
                    defaultValue={currentClient && currentClient.familyName}
                    onChange={(value, name) => handleChange(value, name)}
                    validation="required|min:2"
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <Input
                    required
                    name="phone_number"
                    label="Phone"
                    placeholder="Phone"
                    type="phone"
                    mode="tel"
                    ref={inputRefs.current.phone_number}
                    value={formData["phone_number"]}
                    defaultValue={
                      currentClient && currentClient.phone
                        ? currentClient.phone.substring(2)
                        : ""
                    }
                    onChange={(value, name) => handleChange(value, name)}
                    validation="required|phone"
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <Input
                    required
                    name="email"
                    label="Email"
                    placeholder="Email"
                    type="email"
                    mode="email"
                    ref={inputRefs.current.email}
                    value={formData["email"]}
                    defaultValue={currentClient && currentClient.email}
                    onChange={(value, name) =>
                      handleChange(value.toLowerCase(), name)
                    }
                    validation="required|email"
                  />
                </IonCol>
              </IonRow>
            </div>

            <IonRow>
              <IonCol className={styles.label}>Offer details</IonCol>
            </IonRow>

            <IonRow className={styles.row}>
              <span className={styles.inputLabel}>Preliminary offer</span>
              <Input
                required
                name="offer_value"
                type="text"
                mode="numeric"
                defaultValue={offer.value}
                value={offer.value}
                onFocus={() => setOffer({ ...offer, value: "" })}
                onChange={(value, name) => handleOfferChange(value)}
                onBlur={handleOfferBlur}
                disabled={offer.discuss}
              />
            </IonRow>
            <IonRow>
              {type.toLowerCase() === "sale" && (
                <IonItem lines="none" className={styles.checkboxContainer}>
                  <IonLabel className={`${styles.label} ion-text-wrap`}>
                    Discuss how much I should offer with an Ettie agent
                  </IonLabel>
                  <IonCheckbox
                    checked={offer.discuss}
                    className={styles.checkbox}
                    onIonChange={({ detail: { checked } }) =>
                      setOffer({ ...offer, discuss: checked })
                    }
                    slot="start"
                  />
                </IonItem>
              )}
            </IonRow>

            <IonRow className={styles.row}>
              <span className={styles.inputLabel}>
                {type.toLowerCase() === "sale"
                  ? "Preferred closing date"
                  : "Lease start date"}
              </span>
              <IonDatetime
                disabled={offer.disableDate}
                className={styles.offerDate}
                value={offer.date}
                min={moment().format("YYYY-MM-DD")}
                max={new Date().getFullYear() + 1}
                displayFormat="MMM DD YYYY"
                placeholder={
                  type.toLowerCase() === "sale"
                    ? "Preferred closing date"
                    : "Lease start date"
                }
                onIonChange={({ detail: { value } }) =>
                  setOffer({ ...offer, date: value })
                }
              >
                <span className={styles.label}>
                  {status.toLowerCase() === "sale"
                    ? "Preferred closing date"
                    : "Lease start date"}
                </span>
              </IonDatetime>
            </IonRow>
            <IonRow className={styles.row}>
              <IonItem lines="none" className={styles.checkboxContainer}>
                <IonLabel className={`${styles.label} ion-text-wrap`}>
                  Not sure yet
                </IonLabel>
                <IonCheckbox
                  checked={offer.disableDate}
                  className={styles.checkbox}
                  onIonChange={({ detail: { checked } }) =>
                    setOffer({ ...offer, disableDate: checked })
                  }
                  slot="start"
                />
              </IonItem>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonButton
                  shape="round"
                  expand="block"
                  className={styles.button}
                  onClick={handleOfferSubmit}
                >
                  Submit
                </IonButton>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className={styles.consent}>
                By submitting this form, I understand Ettie will share my
                information with registered real estate professionals.
              </IonCol>
            </IonRow>
          </IonGrid>
        ) : isCashback ? (
          <IonGrid className={styles.requestContainer}>
            <IonRow>
              <IonCol className={styles.subtitle}>
                {isSale
                  ? `Ettie's cashback system makes the entire the 2.5% commission
                available to the buyer. Pay for only the services you actually
                use and receive cashback for the rest!`
                  : `Ettie's cashback system makes the entire lease commission available to the buyer. Pay for only the services you actually use and receive cashback for the rest!`}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className={styles.label}>Contact information</IonCol>
            </IonRow>
            <div className={styles.formFields}>
              <IonRow>
                <IonCol>
                  <Input
                    required
                    name="given_name"
                    label="First name"
                    placeholder="First name"
                    type="text"
                    ref={inputRefs.current.given_name}
                    value={formData["given_name"]}
                    defaultValue={currentClient && currentClient.givenName}
                    onChange={(value, name) => handleChange(value, name)}
                    validation="required|min:2"
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <Input
                    required
                    name="family_name"
                    label="Last name"
                    placeholder="Last name"
                    type="text"
                    ref={inputRefs.current.family_name}
                    value={formData["family_name"]}
                    defaultValue={currentClient && currentClient.familyName}
                    onChange={(value, name) => handleChange(value, name)}
                    validation="required|min:2"
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <Input
                    required
                    name="phone_number"
                    label="Phone"
                    placeholder="Phone"
                    type="phone"
                    mode="tel"
                    ref={inputRefs.current.phone_number}
                    value={formData["phone_number"]}
                    defaultValue={
                      currentClient && currentClient.phone
                        ? currentClient.phone.substring(2)
                        : ""
                    }
                    onChange={(value, name) => handleChange(value, name)}
                    validation="required|phone"
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <Input
                    required
                    name="email"
                    label="Email"
                    placeholder="Email"
                    type="email"
                    mode="email"
                    ref={inputRefs.current.email}
                    value={formData["email"]}
                    defaultValue={currentClient && currentClient.email}
                    onChange={(value, name) =>
                      handleChange(value.toLowerCase(), name)
                    }
                    validation="required|email"
                  />
                </IonCol>
              </IonRow>
            </div>
            <IonRow>
              <IonCol>
                <span className={styles.textarealabel}>Message</span>
                <textarea
                  className={styles.textarea}
                  placeholder="I’d like to talk about how to get cashback through the Ettie à la carte system."
                  name="textarea"
                  value={text}
                  onChange={handleMessage}
                ></textarea>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonButton
                  shape="round"
                  expand="block"
                  className={styles.button}
                  onClick={handleCashbackSubmit}
                >
                  Submit
                </IonButton>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className={styles.consent}>
                By submitting this form, I understand Ettie will share my
                information with registered real estate professionals.
              </IonCol>
            </IonRow>
          </IonGrid>
        ) : (
          <IonGrid className={styles.requestContainer}>
            <IonRow>
              <IonCol className={styles.subtitle}>
                Complete this form to contact the agent or schedule a viewing.
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className={styles.label}>Contact information</IonCol>
            </IonRow>
            <div className={styles.formFields}>
              <IonRow>
                <IonCol>
                  <Input
                    required
                    name="given_name"
                    label="First name"
                    placeholder="First name"
                    type="text"
                    ref={inputRefs.current.given_name}
                    value={formData["given_name"]}
                    defaultValue={currentClient && currentClient.givenName}
                    onChange={(value, name) => handleChange(value, name)}
                    validation="required|min:2"
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <Input
                    required
                    name="family_name"
                    label="Last name"
                    placeholder="Last name"
                    type="text"
                    ref={inputRefs.current.family_name}
                    value={formData["family_name"]}
                    defaultValue={currentClient && currentClient.familyName}
                    onChange={(value, name) => handleChange(value, name)}
                    validation="required|min:2"
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <Input
                    required
                    name="phone_number"
                    label="Phone"
                    placeholder="Phone"
                    type="phone"
                    mode="tel"
                    ref={inputRefs.current.phone_number}
                    value={formData["phone_number"]}
                    defaultValue={
                      currentClient && currentClient.phone
                        ? currentClient.phone.substring(2)
                        : ""
                    }
                    onChange={(value, name) => handleChange(value, name)}
                    validation="required|phone"
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <Input
                    required
                    name="email"
                    label="Email"
                    placeholder="Email"
                    type="email"
                    mode="email"
                    ref={inputRefs.current.email}
                    value={formData["email"]}
                    defaultValue={currentClient && currentClient.email}
                    onChange={(value, name) =>
                      handleChange(value && value.toLowerCase(), name)
                    }
                    validation="required|email"
                  />
                </IonCol>
              </IonRow>
            </div>
            <IonRow className={styles.borderBottom}>
              <IonCol className={styles.label}>I want to:*</IonCol>
            </IonRow>
            <IonRow className={styles.borderBottom}>
              <IonCol>
                {active && (
                  <RadioButton
                    label="request in-person viewing"
                    onClick={radioButtonHandler}
                    value={value}
                    labelStyle={{
                      fontSize: "1.5rem",
                      textTransform: "initial",
                    }}
                  />
                )}
              </IonCol>
            </IonRow>
            <IonRow className={styles.borderBottom}>
              <IonCol>
                {active && (
                  <RadioButton
                    label="request virtual viewing"
                    onClick={radioButtonHandler}
                    value={value}
                    labelStyle={{
                      fontSize: "1.5rem",
                      textTransform: "initial",
                    }}
                  />
                )}
              </IonCol>
            </IonRow>
            <IonRow className={styles.borderBottom}>
              <IonCol>
                <RadioButton
                  label="request information"
                  onClick={radioButtonHandler}
                  value={value}
                  labelStyle={{ fontSize: "1.5rem", textTransform: "initial" }}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                {value && (
                  <>
                    <span className={styles.textarealabel}>Message</span>
                    <textarea
                      className={styles.textarea}
                      placeholder="I’d like to book an appointment to ..."
                      name="textarea"
                      value={text}
                      onChange={handleMessage}
                    ></textarea>
                  </>
                )}
              </IonCol>
            </IonRow>
            {showTime && (
              <>
                <IonRow>
                  <IonCol className={styles.label}>
                    Dates and times I am available:*
                  </IonCol>
                </IonRow>
                {addDate.map((date, i) => (
                  <DateTimePicker
                    key={i}
                    onDelete={deleteDateHandler}
                    value={date}
                    deletable={i > 0}
                    onChange={updateDateHandler}
                  />
                ))}
                <IonRow>
                  <IonCol>
                    <div className={styles.addTime} onClick={addDateHandler}>
                      <div className={styles.addIcon}>
                        <IonIcon icon={addOutline} />
                      </div>
                      <div className={styles.text}>
                        Add {addDate.length ? "another" : "a"} time slot
                      </div>
                    </div>
                  </IonCol>
                </IonRow>
              </>
            )}
            <IonRow>
              <IonCol>
                <IonButton
                  shape="round"
                  expand="block"
                  className={styles.button}
                  onClick={handleSubmit}
                >
                  Submit
                </IonButton>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className={styles.consent}>
                By submitting this form, I understand Ettie will share my
                information with registered real estate professionals.
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
      </IonContent>
    </IonModal>
  );
};

export default withClient(RequestForm);
