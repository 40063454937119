import { FiltersActionTypes } from "./filters.types";

const INITIAL_STATE = {
  value: {
    area: null,
    city: null,
    neighborhood: null,
    type: "sale",
    class: ["residential", "condo"],
    status: "A",
    lastStatus: null,
    style: null,
    propertyType: null,
    date: null,
    minListDate: null,
    maxListDate: null,
    minSoldDate: null,
    maxSoldDate: null,
    listDate: null,
    displayPublic: "Y",
    displayAddressOnInternet: "Y",
    map: null,
    minPrice: "0",
    maxPrice: "max",
    minBeds: null,
    maxBeds: null,
    minBaths: null,
    maxBaths: null,
    minParkingSpaces: null,
    minSqft: null,
    maxSqft: null,
    keywords: null,
    hasImages: null,
    sortBy: "createdOnDesc",
    resultsPerPage: 2500,
    pageNum: 1,
  },
  extra: {
    lotWidth: { min: null, max: null },
    lotDepth: { min: null, max: null },
    locker: null,
    basement: {
      finished: false,
      walkOut: false,
      sepEntrance: false,
      any: true,
    },
    maintenance: null,
  },
};

const filtersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FiltersActionTypes.UPDATE_FILTERS:
      return {
        ...state,
        value: { ...state.value, ...action.payload },
      };
    case FiltersActionTypes.UPDATE_MULTIPLE_FILTERS:
      return {
        ...state,
        value: { ...state.value, ...action.payload },
      };
    case FiltersActionTypes.UPDATE_EXTRA_FILTERS:
      return { ...state, extra: { ...state.extra, ...action.payload } };
    case FiltersActionTypes.RESET_FILTERS:
      return {
        value: {
          ...INITIAL_STATE.value,
          type: state.value.type,
          map: state.value.map,
        },
        extra: { ...INITIAL_STATE.extra },
      };
    default:
      return state;
  }
};

export default filtersReducer;
