import { IonCol, IonGrid, IonRow } from "@ionic/react";
import CardContainer from "../../../../Tours/component/CardContainer/card-container.component";
import AgentsPhoto from "../../../../../assets/img/About - agents@4x.png";
import BrokeragePhoto from "../../../../../assets/img/About - brokerage@4x.png";
import SupportPhoto from "../../../../../assets/img/About - support@4x.png";
import styles from "./team.module.scss";

const Team = () => {
  return (
    <CardContainer>
      <IonGrid className={styles.team}>
        <IonRow>
          <IonCol className={styles.title}>The Ettie team</IonCol>
        </IonRow>
        <IonRow className={styles.row}>
          <IonCol>
            <img alt="Ettie team" src={AgentsPhoto} className={styles.img} />
          </IonCol>
          <IonCol>
            <div className={styles.label}>Agents</div>
            <div>
              Ettie’s team of hyper-localized, specialized real estate sales
              representatives are ready to help you achieve your buying,
              selling, leasing or investing goals. Our realtors have lived
              and/or worked in the areas they show for years — so you can trust
              they know the neighbourhood like the back of their hand. Get all
              details about all the ins and outs, best hidden gems, local
              festivities and more! If you would like to be connected with a
              hyper-localized agent, please send us a message and we’ll be happy
              to get you matched with an agent within 24 hours!
            </div>
          </IonCol>
        </IonRow>
        <IonRow className={styles.row}>
          <IonCol>
            <img
              src={BrokeragePhoto}
              className={styles.img}
              alt="Brokerage team"
            />
          </IonCol>
          <IonCol>
            <div className={styles.label}>Brokerage team</div>
            <div>
              Our brokerage is lead by a team of well-established professionals
              who aim to provide the best value-for-service in the real estate
              industry. Whether you’re a client looking for a new home or hoping
              to join our team at Ettie, we’re happy to talk about your goals
              and discuss the best ways for you to achieve them.
            </div>
          </IonCol>
        </IonRow>
        <IonRow className={`${styles.row} ${styles.lastRow}`}>
          <IonCol>
            <img src={SupportPhoto} className={styles.img} alt="Support team" />
          </IonCol>
          <IonCol>
            <div className={styles.label}>Business, marketing & technology</div>
            <div>
              This team of developers, UX designers, business leaders and
              digital marketers build the analytics and structure of the Ettie
              web platform, app and realty services to create the most effective
              user experience possible.
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </CardContainer>
  );
};

export default Team;
