import { useState, useRef, createRef } from "react";
import { useDispatch } from "react-redux";
import { IonIcon } from "@ionic/react";
import { checkmarkOutline, closeOutline } from "ionicons/icons";
import Button from "../../Form/Button/button.component";
import Input from "../../Form/Input/input.component";
import favouriteIcon from "../../../assets/icon/Favourites@4x.png";
import { ReactComponent as FavoriteIcon } from "../../../assets/svg/star.svg";
import styles from "./share-listing-email-modal.module.scss";
import { shareListing } from "../../../api/ettie";
import withClient from "../../../HOC/withClient/with-client";
import { addFavouriteStart } from "../../../redux/client/client.actions";

const ShareListingEmailModal = ({
  currentClient,
  data: { mlsNumber, text, isFavourite, listing },
  close,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [sentEmail, setSentEmail] = useState(false);
  const [addFavorite, setAddFavorite] = useState(false);
  const [message, setMessage] = useState(
    `Check this listing out on Ettie!\n${text}\n${window.location.href}`
  );

  const inputRefs = useRef({
    receiver: createRef(),
    sender: createRef(),
    name: createRef(),
  });

  const [formData, setFormData] = useState({
    receiver: "",
    sender: currentClient ? currentClient.email : "",
    name: currentClient ? currentClient.name : "",
  });

  const handleChange = (value, name) => {
    inputRefs.current[name].current.validate();
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    let isValid = true;
    if (currentClient) {
      const validationResult =
        inputRefs.current.receiver.current.validate(true);
      if (!validationResult) isValid = false;
    } else {
      for (const field of Object.values(inputRefs.current)) {
        const validationResult = field.current.validate(true);
        if (!validationResult) isValid = false;
      }
    }

    if (isValid) {
      setLoading(true);
      const res = await shareListing({
        name: formData.name,
        receiver: formData.receiver,
        sender: formData.sender,
        message,
        url: window.location.href,
      });
      if (res.success) {
        setSentEmail(true);
        setLoading(false);
      } else {
        setLoading(false);
        setError("Something went wrong! Please try again.");
      }
    }
  };

  const handleFavourite = () => {
    setLoading(true);
    dispatch(addFavouriteStart(listing));
    setTimeout(() => {
      setLoading(false);
      setAddFavorite(true);
    }, 1500);
  };

  return (
    <>
      {!sentEmail ? (
        <div className={styles.listingEmail}>
          <div className={styles.title}>Share this property</div>
          <div className={styles.info}>{text}</div>
          <div className={styles.inputs}>
            {!currentClient && (
              <>
                <Input
                  required
                  name="name"
                  label="your name"
                  placeholder="your name"
                  type="text"
                  ref={inputRefs.current.name}
                  value={formData.name}
                  onChange={(value, name) => handleChange(value, name)}
                  validation="required"
                />
                <Input
                  required
                  name="sender"
                  label="your email"
                  mode="email"
                  placeholder="your email"
                  type="email"
                  ref={inputRefs.current.sender}
                  value={formData.sender}
                  onChange={(value, name) => handleChange(value, name)}
                  validation="required|email"
                />
              </>
            )}
            <Input
              required
              name="receiver"
              label="recipient’s email"
              placeholder="recipient’s email"
              type="email"
              mode="email"
              ref={inputRefs.current.receiver}
              value={formData.receiver}
              onChange={(value, name) => handleChange(value, name)}
              validation="required|email"
            />
          </div>

          <textarea
            className={styles.textarea}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
          {error && <div className={styles.error}>{error}</div>}
          <Button
            title="Send"
            type="purple"
            style={{
              height: "5rem",
              width: "100%",
              border: "1px solid #7158ad",
            }}
            disabled={
              currentClient
                ? !formData.receiver || !message
                : !formData.receiver ||
                  !formData.name ||
                  !formData.sender ||
                  !message
            }
            onClick={handleSubmit}
            loading={loading}
          />
        </div>
      ) : !addFavorite ? (
        <div className={styles.container}>
          <div className={styles.pic}>
            <IonIcon icon={checkmarkOutline} />
          </div>
          <div className={styles.title}>Property has been shared</div>
          {!isFavourite && currentClient && (
            <>
              <div className={styles.info}>
                Did you want to save this property to your favourites and
                receive updates?
              </div>
              <Button
                title="Save to favourites"
                type="purple"
                style={{
                  height: "5rem",
                  width: "100%",
                  border: "1px solid #7158ad",
                }}
                onClick={handleFavourite}
                loading={loading}
              >
                <FavoriteIcon style={{ margin: "0 1rem .5rem 0" }} />
              </Button>
            </>
          )}

          <div className={styles.btn}>
            <Button
              title="close"
              type="clear"
              style={{
                color: "#707070",
                marginTop: "0.5rem",
                width: "100%",
              }}
              onClick={close}
            >
              <IonIcon
                icon={closeOutline}
                style={{
                  height: "2rem",
                  width: "2rem",
                  color: "#707070",
                  alignSelf: "self-start",
                }}
              />
            </Button>
          </div>
        </div>
      ) : (
        <div className={styles.container}>
          <div className={styles.pic}>
            <img src={favouriteIcon} alt="favourite" />
          </div>
          <div className={styles.title}>Property has been saved!</div>
          <div className={styles.btn}>
            <Button
              title="close"
              type="clear"
              style={{
                color: "#707070",
                marginTop: "0.5rem",
                width: "100%",
              }}
              onClick={close}
            >
              <IonIcon
                icon={closeOutline}
                style={{
                  height: "2rem",
                  width: "2rem",
                  color: "#707070",
                  alignSelf: "self-start",
                }}
              />
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default withClient(ShareListingEmailModal);
