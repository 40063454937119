import { useState, useEffect } from "react";
import styles from "./loading.module.scss";
import { ReactComponent as Loader } from "../../assets/svg/EttieLoaderAnimated.svg";
import Button from "../Form/Button/button.component";
import { useHistory } from "react-router-dom";
import { isApp } from "../../utils/functions";

const LoadingFullPage = ({ details, onReload, style }) => {
  const [showBtn, setShowBtn] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const to = setTimeout(() => {
      setShowBtn(true);
    }, 10000);

    return () => {
      if (to) clearTimeout(to);
    };
  }, []);

  const handleReload = () => {
    if (onReload) {
      onReload();
    } else {
      history.go(0);
    }
  };

  return (
    <div
      className={`${styles.fullPage} ${details && styles.details}`}
      style={style}
    >
      <Loader className={styles.loader} />
      {showBtn && (
        <>
          <Button
            style={{ marginTop: "5rem" }}
            type="purple"
            border
            title="Back"
            onClick={() =>
              history.push(isApp() ? "/tabs/listings" : "/listings", {
                direction: "back",
              })
            }
          />
          <div className={styles.msg}>
            Loading seems to be taking longer than usual.
            <br />
            <div onClick={handleReload} className={styles.reload}>
              Reload
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default LoadingFullPage;
