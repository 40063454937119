import { useState, useRef, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentClient } from "../../../redux/client/client.selectors";
import { updateName } from "../../../amplify/auth.utils";
import Input from "../../Form/Input/input.component";
import Button from "../../Form/Button/button.component";
import { editName } from "../../../data/customModal";

import styles from "./change-name-modal.module.scss";
import { checkUserSession } from "../../../redux/user/user.actions";

const ChangeNameModal = ({ setType, language }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const client = useSelector(selectCurrentClient);

  const inputRefs = useRef({
    given_name: createRef(),
    family_name: createRef(),
  });

  const [formData, setFormData] = useState({
    given_name: "",
    family_name: "",
  });

  const handleChange = (value, name) => {
    inputRefs.current[name].current.validate();
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdateName = async () => {
    if (
      inputRefs.current.given_name.current.validate(true) &&
      inputRefs.current.family_name.current.validate(true)
    ) {
      setLoading(true);
      setError("");
      try {
        await updateName(formData.given_name, formData.family_name, client.id);
        dispatch(checkUserSession(true));
        setLoading(false);
        setType("updatedName");
      } catch (error) {
        setLoading(false);
        setError(error.message);
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>{editName[language].header}</div>
      <div className={styles.subtitle}>{editName[language].subtitle}</div>
      <div className={styles.content}>
        <Input
          required
          name="given_name"
          label={editName[language].fName}
          placeholder={editName[language].fName}
          ref={inputRefs.current.given_name}
          value={formData["given_name"]}
          onChange={(value, name) => handleChange(value, name)}
          validation="required|min:2"
        />
        <Input
          required
          name="family_name"
          label={editName[language].lName}
          placeholder={editName[language].lName}
          ref={inputRefs.current.family_name}
          value={formData["family_name"]}
          onChange={(value, name) => handleChange(value, name)}
          validation={"required|min:2"}
        />
      </div>
      {error && (
        <div className={styles.error}>
          <span>{error}</span>
        </div>
      )}
      <div className={styles.button}>
        <Button
          title={editName[language].button}
          type="purple"
          style={{ height: "5rem", width: "100%", border: "1px solid #7158ad" }}
          onClick={handleUpdateName}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default ChangeNameModal;
