import { FiltersActionTypes } from "./filters.types";

export const updateFilters = (filter) => ({
  type: FiltersActionTypes.UPDATE_FILTERS,
  payload: filter,
});

export const updateMultipleFilters = (filters) => ({
  type: FiltersActionTypes.UPDATE_MULTIPLE_FILTERS,
  payload: filters,
});

export const updateExtraFilters = (filter) => ({
  type: FiltersActionTypes.UPDATE_EXTRA_FILTERS,
  payload: filter,
});

export const resetFilters = () => ({
  type: FiltersActionTypes.RESET_FILTERS,
});
