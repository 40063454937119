export default {
  maptiler: {
    url: "https://api.maptiler.com/maps/streets/256/{z}/{x}/{y}.png?key=uTNICuDoXQB34kFIyCQ0",
    attribution: "",
  },
  google: {
    url: "http://mt0.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
    attribution: "google",
  },
};
