import { useDispatch, useSelector } from "react-redux";
import styles from "./map-controls.module.scss";
import { ReactComponent as NearbyIcon } from "../../../../assets/svg/nearby.svg";
import { ReactComponent as DrawIcon } from "../../../../assets/svg/draw.svg";
import { ReactComponent as SchoolIcon } from "../../../../assets/svg/schoolMap.svg";
import { ReactComponent as MapIcon } from "../../../../assets/svg/map-view.svg";
import { ReactComponent as SatelliteIcon } from "../../../../assets/svg/satellite-view.svg";
import { ReactComponent as SearchIcon } from "../../../../assets/svg/search.svg";
import Button from "../../../Form/Button/button.component";
import { openModal } from "../../../../redux/ui/ui.actions";
import { toggleFavourites } from "../../../../redux/map/map.actions";
import { selectSchools } from "../../../../redux/schools/schools.selectors";

const MapControls = ({
  mapType,
  mapTypeHandler,
  drawMode,
  drawHandler,
  schoolsHandler,
  schoolsMode,
  currentLocationHandler,
  favouritesOnly,
  status,
  disableDrawing,
  isCentered,
}) => {
  const dispatch = useDispatch();
  const schools = useSelector(selectSchools);
  const { active, ref } = drawMode;
  const drawBtnHandler = () => {
    if (disableDrawing) return;
    if (favouritesOnly) dispatch(toggleFavourites(false));
    drawHandler();
  };

  const saveSearchHandler = () => {
    dispatch(
      openModal({
        current: "saveSearch",
        data: {
          drawMode,
          schoolMode: {
            active: schools.filterListings.active,
            schools: schools.filterListings.schools,
          },
        },
        // data: active && ref ? true : false,
        isProtected: true,
      })
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.buttonsContainer}>
        <div
          className={`${styles.button} ${schoolsMode && styles.enabledButton}`}
          onClick={schoolsHandler}
        >
          <SchoolIcon
            className={schoolsMode ? styles.enabledIcon : styles.icon}
          />
        </div>
        <div
          className={`${styles.button} ${isCentered && styles.enabledButton}`}
          onClick={currentLocationHandler}
        >
          <NearbyIcon className={styles.icon} />
        </div>
        <div
          className={`${styles.button} ${active && styles.enabledButton}`}
          onClick={drawBtnHandler}
        >
          <DrawIcon
            className={`${
              disableDrawing
                ? styles.disabledIcon
                : active
                ? styles.enabledIcon
                : styles.icon
            }`}
          />
          {/* {active && (
            <span className={styles.label}>
              clear <span className={styles.clearIcon}>&#10006;</span>
            </span>
          )} */}
        </div>
        <div className={styles.button} onClick={mapTypeHandler}>
          {mapType === "roadmap" ? (
            <SatelliteIcon className={styles.icon} />
          ) : (
            <MapIcon className={styles.icon} />
          )}
        </div>
      </div>
      <div className={styles.saveSearch} id="saveSearchWeb">
        <Button
          type="green"
          title="save search"
          border={true}
          style={{ boxShadow: "1px 1px 1.5rem #00000027" }}
          onClick={saveSearchHandler}
          disabled={favouritesOnly || status === "U"}
        >
          <SearchIcon />
        </Button>
      </div>
    </div>
  );
};

export default MapControls;
