import { useDispatch } from "react-redux";
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonTitle,
} from "@ionic/react";
import styles from "./tour-elements-content.module.scss";
import { chevronBackOutline } from "ionicons/icons";
import TourDetails from "./component/TourDetails/tour-details.component";
import TourAttendees from "./component/TourAttendees/tour-attendees.component";
import AgentDetails from "./component/AgentDetails/agent-details.component";
import QuickContact from "./component/QuickContact/quick-contact.component";
import { updateTour } from "../../../../../amplify/graphql.utils";
import { updateToast } from "../../../../../redux/ui/ui.actions";

const TourElementsContent = ({
  tour,
  setIsOpen,
  type,
  reload,
  pageRef,
  selfRef,
}) => {
  const dispatch = useDispatch();
  const updateDetails = async (updates) => {
    try {
      await updateTour({ id: tour.id, ...updates });
      dispatch(
        updateToast({
          open: true,
          message: "Tour saved successfully.",
          type: "success",
        })
      );
      await reload();
      setIsOpen(false);
    } catch (err) {
      dispatch(
        updateToast({
          open: true,
          message: "Something went wrong!",
          type: "error",
        })
      );
    }
  };

  return (
    <>
      <IonHeader className={styles.appHeader}>
        <IonToolbar className={styles.toolbar}>
          <IonButtons slot="start">
            <IonButton
              className={styles.backButton}
              slot="start"
              text="Back"
              onClick={() => setIsOpen(false)}
            >
              <IonIcon icon={chevronBackOutline} />
              Back
            </IonButton>
          </IonButtons>
          <IonTitle className={styles.title}>
            {type === "tourDetails" && "Tour details"}
            {type === "tourAttendees" && "Tour attendees"}
            {type === "agentDetails" && "Agent details"}
            {type === "quickContact" && "Quick contact"}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={styles.tourElementsContent}>
        {type === "tourDetails" && (
          <TourDetails
            tour={tour}
            setIsOpen={setIsOpen}
            update={updateDetails}
            reload={reload}
            pageRef={pageRef}
          />
        )}
        {type === "tourAttendees" && (
          <TourAttendees
            setIsOpen={setIsOpen}
            tour={tour}
            reload={reload}
            pageRef={pageRef}
            selfRef={selfRef}
          />
        )}
        {type === "agentDetails" && (
          <AgentDetails setIsOpen={setIsOpen} tour={tour} reload={reload} />
        )}
        {type === "quickContact" && (
          <QuickContact contacts={JSON.parse(tour.membersData)} />
        )}
      </IonContent>
    </>
  );
};

export default TourElementsContent;
