import styles from "./segment.module.scss";

const Segment = ({ segments, selected, disabled }) => {
  return (
    <div className={styles.container}>
      {segments.map((seg, index) => (
        <div
          key={index}
          className={`${styles.segment} ${
            selected === seg.title && styles.active
          }`}
          onClick={seg.onClick}
        >
          <span className={styles.title}>{seg.title}</span>
        </div>
      ))}
    </div>
  );
};

export default Segment;
