import { IonCheckbox } from "@ionic/react";
import styles from "./checkbox.module.scss";

const Checkbox = ({
  onClick,
  checked,
  value,
  label,
  disabled = false,
  color = "purple",
}) => {
  return (
    <label className={`${styles.checkbox} ${styles[color]}`}>
      {/* <input
        value={value}
        type="checkbox"
        onChange={() => onClick(value)}
        checked={checked}
        disabled={disabled}
      /> */}
      <IonCheckbox
        mode="md"
        value={value}
        onIonChange={() => onClick(value)}
        checked={checked}
        disabled={disabled}
      />
      <span className={`${styles.item} ${disabled && styles.disabled}`}>
        {label}
      </span>
    </label>
  );
};

export default Checkbox;
