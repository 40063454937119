import { useState, useEffect, useRef } from "react";
import styles from "./collapsable-text.module.scss";

import { IonIcon } from "@ionic/react";
import { chevronDownOutline, chevronUpOutline } from "ionicons/icons";

const CollapsableText = ({ text, height = "24rem" }) => {
  const ref = useRef();
  const [showMore, setShowMore] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const handleShowMore = () => setExpanded(!expanded);

  useEffect(() => {
    if (ref.current) {
      const el = ref.current;
      if (el.scrollHeight > el.clientHeight) setShowMore(true);
    }
  }, [ref.current]);

  return (
    <div
      className={`${styles.container} ${
        expanded ? styles.expanded : styles.collapsed
      }`}
      style={{
        maxHeight: expanded ? "100rem" : height,
        paddingBottom: expanded && "6.5rem",
      }}
    >
      <p ref={ref} className={styles.text}>
        {text.split("\n").map((str, i) => (
          <span key={i}>
            {str}
            <br />
          </span>
        ))}
      </p>
      {showMore && (
        <div
          className={styles.button}
          onClick={handleShowMore}
          style={{ flexDirection: expanded ? "column-reverse" : "column" }}
        >
          <div className={styles.background} />
          <span className={styles.label}>
            {expanded ? "Show less" : "Show more"}
          </span>
          <IonIcon
            className={styles.icon}
            icon={expanded ? chevronUpOutline : chevronDownOutline}
          />
        </div>
      )}
    </div>
  );
};

export default CollapsableText;
