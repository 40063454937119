import { getPlatforms, IonCol, IonFooter, IonIcon } from "@ionic/react";
import { cameraOutline, micOutline } from "ionicons/icons";
import styles from "./media-nav.module.scss";
import greenEditIcon from "../../../../../assets/svg/REA103_Icons-01a_Edit saved search-green.svg";
import withClient from "../../../../../HOC/withClient/with-client";

const MediaNav = ({
  disabled,
  tourItem,
  currentClient,
  handlePhoto,
  handleNote,
  handleRecording,
}) => {
  const isAuthorized = () => {
    if (disabled) return false;
    if (!getPlatforms().includes("capacitor")) return false;
    const { members, showingAgentId, agentId } = tourItem;
    const { id: clientId } = currentClient;
    let allowed = [agentId, showingAgentId];
    if (members) allowed = [...allowed, ...members];
    return !allowed.includes(clientId) ? false : true;
  };

  return (
    <IonFooter className={styles.footer}>
      <div className={styles.icons}>
        <IonCol
          className={`${styles.icon} ${
            !isAuthorized() ? styles.disabled : styles.camera
          }`}
          onClick={!isAuthorized() ? undefined : handlePhoto}
        >
          <IonIcon icon={cameraOutline} />
          <span>New photo</span>
        </IonCol>
        <IonCol
          className={`${styles.icon} ${styles.note} ${
            disabled && styles.disabled
          }`}
          onClick={disabled ? undefined : handleNote}
        >
          <IonIcon
            src={greenEditIcon}
            className={disabled && styles.disabledNoteIcon}
          />
          <span>Add note</span>
        </IonCol>
        <IonCol
          className={`${styles.icon} ${
            !isAuthorized() ? styles.disabled : styles.mic
          }`}
          onClick={!isAuthorized() ? undefined : handleRecording}
        >
          <IonIcon icon={micOutline} />
          <span>Record voice</span>
        </IonCol>
      </div>
    </IonFooter>
  );
};

export default withClient(MediaNav);
