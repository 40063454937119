import { useState } from "react";
import moment from "moment";
import { eyeOutline } from "ionicons/icons";
import CardContainer from "../../../Tours/component/CardContainer/card-container.component";
import DetailsHeader from "../../../../components/TourDetailsHeader/tour-details-header.module";
import { KeyboardDatePicker } from "@material-ui/pickers";
import useComponentVisible from "../../../../hooks/useComponentVisible";
import styles from "./tour-details.module.scss";
import Select from "../../../../components/Form/Select/select.component";
import DropdownContainer from "../../../../components/Form/DropdownContainer/dropdown-container.component";
import { ranges } from "../../../Tours/component/NewTour/component/utils";
import Input from "../../../../components/Form/Input/input.component";
import Button from "../../../../components/Form/Button/button.component";
import { capitalize } from "lodash";
import { TourStatus } from "../../../../utils/constants";
import TextArea from "../../../../components/Form/TextArea/textarea.component";

const TourDetails = ({ tour, isOpen, setIsOpen, update }) => {
  const [title, setTitle] = useState(tour.title);
  const [date, setDate] = useState(tour.date ? tour.date : null);
  const [status, setStatus] = useState(tour.status);
  // const [date, setDate] = useState(
  //   tour.date ? tour.date : moment().format("L")
  // );

  const [meetup, setMeetup] = useState({
    address: tour.meetupLocation ? tour.meetupLocation : "",
    time: tour.meetupTime,
    description: tour.meetupDescription ? tour.meetupDescription : "",
  });

  const [time, setTime] = useState({
    start: tour.startTime,
    end: tour.endTime,
  });
  const handleDateChange = (date) => {
    if (date && date._d && date._isValid)
      setDate(moment(date).toISOString().substring(0, 10));
  };

  const [note, setNote] = useState(tour.generalNote ? tour.generalNote : "");

  const {
    ref: refStatus,
    isOpen: isOpenStatus,
    setIsOpen: setIsOpenStatus,
  } = useComponentVisible(false);

  const {
    ref: refMeetupTime,
    isOpen: isOpenMeetupTime,
    setIsOpen: setIsOpenMeetupTime,
  } = useComponentVisible(false);

  const {
    ref: refFrom,
    isOpen: isOpenFrom,
    setIsOpen: setIsOpenFrom,
  } = useComponentVisible(false);

  const {
    ref: refTo,
    isOpen: isOpenTo,
    setIsOpen: setIsOpenTo,
  } = useComponentVisible(false);

  return (
    <CardContainer>
      <DetailsHeader
        title="Tour details"
        icon={eyeOutline}
        isVisible={true}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />

      {isOpen && (
        <div className={styles.inputs}>
          <div className={styles.container}>
            <Input
              name="tour_title"
              label="Tour title"
              placeholder="Tour title"
              style={{ width: "100%" }}
              value={title}
              defaultValue={title}
              onChange={(value, name) => setTitle(value)}
            />
          </div>

          <div ref={refStatus} className={styles.container}>
            <Select
              label="Status of tour"
              title={capitalize(status)}
              style={{ height: "4rem", width: "100%" }}
              buttonStyle={{ height: "100%" }}
              open={isOpenStatus}
              onClick={() => setIsOpenStatus(!isOpenStatus)}
            />
            <DropdownContainer
              open={isOpenStatus}
              style={{
                position: "absolute",
                top: "auto",
                left: "auto",
                // width: "24rem",
                height: "15rem",
              }}
            >
              <div className={styles.statusDropdown}>
                {TourStatus.map((s, i) => (
                  <div
                    key={i}
                    onClick={() => {
                      setStatus(s.title.toLowerCase());
                      setIsOpenStatus(false);
                    }}
                    className={styles.range}
                  >
                    {s.title}
                  </div>
                ))}
              </div>
            </DropdownContainer>
          </div>

          <div className={styles.container}>
            <KeyboardDatePicker
              disablePast={true}
              autoOk
              disableToolbar
              variant="inline"
              inputVariant="outlined"
              required
              // format="MM/d/yyyy"
              value={date}
              InputAdornmentProps={{ position: "start" }}
              onChange={handleDateChange}
              style={{ padding: "0", width: "100%" }}
            />
          </div>

          <div className={styles.times}>
            <div ref={refFrom} className={styles.container}>
              <Select
                label="Tour start"
                title={time.start ? time.start : "Tour start"}
                style={{ height: "4rem", width: "100%" }}
                buttonStyle={{ height: "100%" }}
                open={isOpenFrom}
                onClick={() => setIsOpenFrom(!isOpenFrom)}
              />
              <DropdownContainer
                open={isOpenFrom}
                style={{
                  position: "absolute",
                  top: "auto",
                  left: "auto",
                  // width: "20rem",
                  height: "15rem",
                }}
              >
                {ranges.map((range, index) => (
                  <div
                    key={index}
                    className={styles.range}
                    onClick={() => {
                      setTime({
                        ...time,
                        start: moment(range, "HH:mm:ss a").format("HH:mm"),
                      });
                      setIsOpenFrom(false);
                    }}
                  >
                    {range}
                  </div>
                ))}
              </DropdownContainer>
            </div>
            <div className={styles.hyphen}>-</div>
            <div ref={refTo} className={styles.container}>
              <Select
                label="Tour end"
                title={time.end ? time.end : "Tour end"}
                style={{ height: "4rem", width: "100%" }}
                buttonStyle={{ height: "100%" }}
                open={isOpenTo}
                onClick={() => setIsOpenTo(!isOpenTo)}
              />
              <DropdownContainer
                open={isOpenTo}
                style={{
                  position: "absolute",
                  top: "auto",
                  left: "auto",
                  // width: "20rem",
                  height: "15rem",
                }}
              >
                {ranges.map((range, index) => (
                  <div
                    key={index}
                    className={styles.range}
                    onClick={() => {
                      setTime({
                        ...time,
                        end: moment(range, "HH:mm:ss a").format("HH:mm"),
                      });
                      setIsOpenTo(false);
                    }}
                  >
                    {range}
                  </div>
                ))}
              </DropdownContainer>
            </div>
          </div>

          <div className={styles.meetup}>
            <div className={styles.container}>
              <Input
                name="address"
                label="Meetup address"
                placeholder="Meetup address"
                mode="search"
                style={{ width: "100%" }}
                value={meetup.address}
                onChange={(value, name) =>
                  setMeetup({ ...meetup, address: value })
                }
              />
            </div>
            <div></div>
            <div ref={refMeetupTime} className={styles.container}>
              <Select
                label="Meetup time"
                title={meetup.time ? meetup.time : "Meetup time"}
                style={{ height: "4rem", width: "100%" }}
                buttonStyle={{ height: "100%" }}
                open={isOpenMeetupTime}
                onClick={() => setIsOpenMeetupTime(!isOpenMeetupTime)}
              />
              <DropdownContainer
                open={isOpenMeetupTime}
                style={{
                  position: "absolute",
                  top: "auto",
                  left: "auto",
                  // width: "24rem",
                  height: "15rem",
                }}
              >
                {ranges.map((range, index) => (
                  <div
                    key={index}
                    className={styles.range}
                    onClick={() => {
                      setMeetup({
                        ...meetup,
                        time: moment(range, "HH:mm:ss a").format("HH:mm"),
                      });
                      setIsOpenMeetupTime(false);
                    }}
                  >
                    {range}
                  </div>
                ))}
              </DropdownContainer>
            </div>
          </div>

          <div className={styles.container}>
            <Input
              name="description"
              label="Meetup description"
              placeholder="Meetup description"
              value={meetup.description}
              defaultValue={meetup.description}
              style={{ width: "100%" }}
              onChange={(value, name) =>
                setMeetup({ ...meetup, description: value })
              }
            />
          </div>

          <TextArea
            value={note}
            onChange={(val) => {
              setNote(val);
            }}
            label="Tour notes for client"
            placeholder="Tour notes for client"
          />

          <Button
            title="Save changes"
            type="green"
            style={{
              border: "1px solid #1F7A60",
              width: "100%",
              height: "5rem",
              marginTop: "2rem",
            }}
            disabled={
              (time.start !== undefined && time.end !== undefined
                ? false
                : true) ||
              !title ||
              !tour ||
              !tour.status ||
              tour.status === "archived"
            }
            onClick={() =>
              update({
                title,
                status,
                startTime: time.start,
                endTime: time.end,
                date,
                meetupTime: meetup.time,
                meetupLocation: meetup.address,
                meetupDescription: meetup.description,
                id: tour.id,
                generalNote: note,
              })
            }
          />

          <div className={styles.note}>
            These saved changes will be visible to the client.
          </div>
        </div>
      )}
    </CardContainer>
  );
};

export default TourDetails;
