import { IonCol, IonGrid, IonRow } from "@ionic/react";
import CardContainer from "../../../../Tours/component/CardContainer/card-container.component";
import styles from "./career.module.scss";

const Career = () => {
  const sendEmailHandler = async () => {
    try {
      let Link = "mailto:recruitment@ettie.ai?subject=Hiring%20agents";
      window.open(Link, "_system");
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <CardContainer>
      <IonGrid className={styles.career}>
        <IonRow>
          <IonCol className={styles.title}>Careers</IonCol>
        </IonRow>
        <IonRow className={styles.row}>
          <IonCol>
            <div className={styles.label}>Hiring agents</div>
            <div>
              Ettie is eagerly looking for both new and experienced agents to
              join the team and be an integral part of our innovative real
              estate platform. Please send your inquiries and resume to{" "}
              <span className={styles.link} onClick={sendEmailHandler}>
                recruitment@ettie.ai
              </span>
              .
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </CardContainer>
  );
};

export default Career;
