import { MapContainer, TileLayer, Marker, LayersControl } from "react-leaflet";
import { useEffect, useState } from "react";
import CustomModalHeader from "../../CustomModalHeader/custom-modal-header.component";
import styles from "./mapview.module.scss";
import "leaflet/dist/leaflet.css";
import providers from "./providers";
import L from "leaflet";

const MapViewModal = ({ location: { latitude, longitude }, close }) => {
  const [showMap, setShowMap] = useState(false);
  const position = [+latitude, +longitude];
  let DefaultIcon = L.icon({
    iconUrl:
      "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-violet.png",

    iconSize: [24, 36],
    iconAnchor: [12, 36],
  });

  L.Marker.prototype.options.icon = DefaultIcon;
  L.control.layers({
    Sat: new L.TileLayer("http://mt0.google.com/vt/lyrs=s&x={x}&y={y}&z={z}", {
      maxZoom: 18,
      attribution: "google",
    }),
  });

  useEffect(() => {
    setTimeout(() => {
      setShowMap(true);
    }, 250);
  });

  return (
    <div className={styles.container}>
      <CustomModalHeader title="Map view" setShowModal={close} />
      <div className={styles.map}>
        {showMap && (
          <MapContainer
            trackResize={false}
            id="my-map"
            center={position}
            zoom="18"
            minZoom="12"
            className={styles.leaflet}
            whenCreated={(map) => map.invalidateSize(true)}
          >
            <Marker position={position} />
            <LayersControl>
              <LayersControl.BaseLayer checked name="Default">
                <TileLayer
                  url={providers.maptiler.url}
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer name="Satellite">
                <TileLayer
                  url={providers.google.url}
                  attribution='&copy; <a href="http://maps.google.com">Google Maps</a>'
                />
              </LayersControl.BaseLayer>
            </LayersControl>
          </MapContainer>
        )}
      </div>
    </div>
  );
};

export default MapViewModal;
