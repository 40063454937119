import axios from "axios";

export const MapAPI = axios.create({
  baseURL: "https://api.repliers.io/listings",
  headers: { "REPLIERS-API-KEY": process.env.REACT_APP_REPLIERS_API_KEY },
});

export const ListingAPI = axios.create({
  baseURL: "https://api.repliers.io/listings",
  headers: { "REPLIERS-API-KEY": process.env.REACT_APP_REPLIERS_API_KEY },
});

export const LocationsAPI = axios.create({
  baseURL: "https://api.repliers.io/listings/locations",
  headers: { "REPLIERS-API-KEY": process.env.REACT_APP_REPLIERS_API_KEY },
});

export const ClientsAPI = axios.create({
  baseURL: "https://api.repliers.io/clients",
  headers: { "REPLIERS-API-KEY": process.env.REACT_APP_REPLIERS_API_KEY },
});

export const AgentsAPI = axios.create({
  baseURL: "https://api.repliers.io/agents",
  headers: { "REPLIERS-API-KEY": process.env.REACT_APP_REPLIERS_API_KEY },
});

export const FavouritesAPI = axios.create({
  baseURL: "https://api.repliers.io/favorites",
  headers: { "REPLIERS-API-KEY": process.env.REACT_APP_REPLIERS_API_KEY },
});

export const SearchAPI = axios.create({
  baseURL: "https://api.repliers.io/searches",
  headers: { "REPLIERS-API-KEY": process.env.REACT_APP_REPLIERS_API_KEY },
});

export const EttieAPI = axios.create({
  baseURL: "https://m65e5ksqz3.execute-api.ca-central-1.amazonaws.com/prod",
});
