import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  IonContent,
  IonButton,
  IonIcon,
  getPlatforms,
  IonLoading,
} from "@ionic/react";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { cameraOutline, micOutline } from "ionicons/icons";
import styles from "./medias.module.scss";
import Photos from "./component/Photos/photos.component";
import Notes from "./component/Notes/notes.component";
import Recordings from "./component/Recordings/recordings.component";
import {
  removePhoto,
  removeRecording,
  uploadPhoto,
  uploadRecording,
} from "../../../amplify/graphql.utils";
import { updateToast } from "../../../redux/ui/ui.actions";
import { MediaCapture } from "@ionic-native/media-capture";
import { File } from "@ionic-native/file";
import withClient from "../../../HOC/withClient/with-client";
import CustomModalHeader from "../CustomModalHeader/custom-modal-header.component";
import { hapticsImpact } from "../../../utils/functions";

const Medias = ({
  disabled,
  currentClient,
  tourItem,
  listing,
  type,
  setIsOpen,
  refresh,
  tour,
}) => {
  const dispatch = useDispatch();
  const [uploading, setUploading] = useState(false);
  const [playing, setPlaying] = useState();

  const handleTakePhoto = async () => {
    const takePicture = async () => {
      const image = await Camera.getPhoto({
        quality: 75,
        allowEditing: false,
        source: CameraSource.Camera,
        resultType: CameraResultType.Base64,
        height: 1280,
        width: 1280,
      });
      if (image) {
        setUploading(true);
        try {
          await uploadPhoto({ file: image, tourItemId: tourItem.id });
          dispatch(
            updateToast({
              open: true,
              message: "Photo uploaded successfully.",
              type: "success",
            })
          );
          refresh(tourItem.id);
        } catch (err) {
          dispatch(
            updateToast({
              open: true,
              message: "Something went wrong!",
              type: "error",
            })
          );
        }
        setUploading(false);
      }
    };
    await Camera.checkPermissions().then(async (res) => {
      if (res.camera === "granted") {
        await takePicture();
      }
      if (res.camera === "denied") {
        return;
      }
      if (res.camera === "prompt") {
        await Camera.requestPermissions()
          .then(async (res) => {
            if (res.camera === "granted") {
              await takePicture();
            }
          })
          .catch((err) => {
            return;
          });
      }
    });
  };

  const handleDelete = async ({ id, path, type }) => {
    setUploading(true);
    await hapticsImpact();
    try {
      if (type === "photo") {
        await removePhoto({ id, path });
      }
      if (type === "recording") {
        await removeRecording({ id, path });
      }
      refresh(tourItem.id);
    } catch (err) {
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message: "Something went wrong!",
        })
      );
    }
    setUploading(false);
  };

  const handleRecordingAudio = async () => {
    try {
      const recording = await MediaCapture.captureAudio({ limit: 1 });
      let name = recording[0].name;
      let path = recording[0].fullPath.substr(
        0,
        recording[0].fullPath.lastIndexOf("/")
      );
      let resolvedPath;
      if (getPlatforms().includes("android")) {
        resolvedPath = await File.resolveDirectoryUrl(path);
      } else {
        resolvedPath = await File.resolveDirectoryUrl("file://" + path);
      }
      const buffer = await File.readAsArrayBuffer(resolvedPath.nativeURL, name);
      let blob = new Blob([buffer], { type: "audio/wav" });
      setUploading(true);
      try {
        await uploadRecording({ file: blob, tourItemId: tourItem.id });

        dispatch(
          updateToast({
            open: true,
            message: "Recording uploaded successfully.",
            type: "success",
          })
        );
        refresh(tourItem.id);
      } catch (err) {
        dispatch(
          updateToast({
            open: true,
            message: "Something went wrong!",
            type: "error",
          })
        );
      }
      setUploading(false);
    } catch (err) {}
  };

  const isAuthorized = () => {
    if (disabled) return false;
    if (!getPlatforms().includes("capacitor")) return false;
    const { members, showingAgentId, agentId } = tourItem;
    const { id: clientId } = currentClient;
    let allowed = [agentId, showingAgentId];
    if (members) allowed = [...allowed, ...members];
    return !allowed.includes(clientId) ? false : true;
  };

  return (
    <>
      <CustomModalHeader
        title={
          type === "photos"
            ? "All photos"
            : type === "notes"
            ? "All notes"
            : "All recordings"
        }
        setShowModal={() => setIsOpen(false)}
        hasBackbtn={true}
        disable={true}
      />
      <IonLoading isOpen={uploading} />
      <IonContent
        className={`${styles.medias} ${
          type === "notes" && styles.greyBackground
        }`}
      >
        {type === "photos" && (
          <Photos
            disabled={disabled}
            tourItem={tourItem}
            photoItems={tourItem.photos.items}
            listing={listing}
            handleDelete={({ id, path }) =>
              handleDelete({ id, path, type: "photo" })
            }
            tour={tour}
          />
        )}

        {type === "notes" && (
          <Notes
            disabled={disabled}
            tourItem={tourItem}
            noteItems={tourItem.notes.items}
            listing={listing}
            refresh={() => refresh(tourItem.id)}
            tour={tour}
          />
        )}
        {type === "recordings" && (
          <Recordings
            disabled={disabled}
            tourItem={tourItem}
            recordingItems={tourItem.recordings.items}
            listing={listing}
            handleDelete={({ id, path }) =>
              handleDelete({ id, path, type: "recording" })
            }
            playing={playing}
            setPlaying={setPlaying}
            tour={tour}
          />
        )}
      </IonContent>

      {type === "recordings" && (
        <div
          className={`${styles.addRecording} ${
            playing ? styles.hideBtn : styles.showBtn
          }`}
        >
          <IonButton
            shape="round"
            expand="block"
            className={styles.recordingBtn}
            disabled={!isAuthorized()}
            onClick={handleRecordingAudio}
          >
            <IonIcon icon={micOutline} />
            Record voice
          </IonButton>
        </div>
      )}

      {type === "photos" && (
        <div className={styles.addPhoto}>
          <IonButton
            shape="round"
            expand="block"
            className={styles.photoBtn}
            onClick={handleTakePhoto}
            disabled={!isAuthorized()}
          >
            <IonIcon icon={cameraOutline} />
            New photo
          </IonButton>
        </div>
      )}
    </>
  );
};

export default withClient(Medias);
