import { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { updatePhone } from "../../../amplify/auth.utils";
import { selectCurrentClient } from "../../../redux/client/client.selectors";
import Button from "../../Form/Button/button.component";
import Input from "../../Form/Input/input.component";
import { changePhone } from "../../../data/customModal";
import styles from "./change-phone-modal.module.scss";

const ChangePhoneModal = ({ setType, setData, language }) => {
  const client = useSelector(selectCurrentClient);
  const [phone, setPhone] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const phoneRef = useRef();

  const handleChange = (value) => {
    phoneRef.current.validate();
    setPhone(value);
    setData(value);
  };

  const handleSubmit = async () => {
    if (phoneRef.current.validate(true)) {
      if (phone === notifications.phone) {
        setError("Please provide a new phone number.");
      } else {
        setLoading(true);
        setError("");
        try {
          await updatePhone("+1" + phone);
          setLoading(false);
          setType("confirmPhone");
        } catch (error) {
          setLoading(false);
          setError(error.message);
        }
      }
    }
  };

  const notifications = JSON.parse(client.notifications);

  return (
    <div className={styles.container}>
      <div className={styles.header}>{changePhone[language].header}</div>
      <div className={styles.subtitle}>{changePhone[language].subtitle}</div>

      <div className={styles.content}>
        <div className={styles.label}>
          {changePhone[language].label}
          <span>{notifications.phone ? notifications.phone : "---"}</span>
        </div>
        <div>
          <Input
            name="new_phone"
            mode="tel"
            label={changePhone[language].input}
            placeholder={changePhone[language].input}
            ref={phoneRef}
            value={phone}
            onChange={(value, name) => handleChange(value)}
            validation="required|phone"
          />
          {error && (
            <div className={styles.error}>
              <span>{error}</span>
            </div>
          )}
        </div>
      </div>
      <Button
        title={changePhone[language].button}
        type="purple"
        style={{ height: "5rem", width: "100%", border: "1px solid #7158ad" }}
        onClick={handleSubmit}
        loading={loading}
      />
    </div>
  );
};

export default ChangePhoneModal;
