import { useState, useEffect } from "react";
import styles from "./sqft-filter.module.scss";
import { IonRange } from "@ionic/react";
import withFilters from "../../../../../HOC/withFilters/with-filters";

const SQFTFilter = ({
  filters: { minSqft, maxSqft },

  updateMultipleFilters,
}) => {
  const [sqft, setSqft] = useState({
    lower: minSqft ? minSqft : 0,
    upper: maxSqft === "max" || !maxSqft ? 5000 : maxSqft,
  });

  const handleOnChange = ({
    detail: {
      value: { lower, upper },
    },
  }) => {
    if (lower !== sqft.lower || upper !== sqft.upper) setSqft({ lower, upper });
  };

  useEffect(() => {
    updateMultipleFilters({
      minSqft: sqft.lower,
      maxSqft: sqft.upper === 5000 ? "max" : sqft.upper,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sqft]);

  return (
    <div className={styles.item}>
      <div className={styles.input}>
        <span className={styles.note}>
          Not all listings provide sqft details
        </span>

        <IonRange
          value={sqft}
          min={0}
          max={5000}
          step={100}
          snaps={true}
          ticks={true}
          dualKnobs
          onIonChange={handleOnChange}
          className={styles.range}
        />
        <div className={styles.pins}>
          <span className={styles.pin}>
            <span>
              {sqft.lower} ft<sup>2</sup>
            </span>
          </span>
          <span className={styles.pin}>
            {sqft.upper === 5000 ? (
              "max"
            ) : (
              <span>
                {sqft.upper} ft<sup>2</sup>
              </span>
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default withFilters(SQFTFilter);
