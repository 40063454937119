import { useDispatch } from "react-redux";
import Settings from "./Settings/settings.component";
import moment from "moment";
import { checkIsAgent, verifyUserAttribute } from "../../amplify/auth.utils";
import { userAccount } from "../../data/userAccount";
import styles from "./user-account.module.scss";
import withAuthentication from "../../HOC/withAuthentication/with-authentication";
import { openModal } from "../../redux/ui/ui.actions";
import Footer from "../../components/Footer/footer.component";
import { capitalize } from "lodash";
import { signOutStart } from "../../redux/user/user.actions";

const UserAccount = ({ language = "en", user, client }) => {
  const dispatch = useDispatch();

  const handleSignOut = () => {
    dispatch(signOutStart());
  };

  const notifications = client && JSON.parse(client.notifications);

  const buildNotificationStyle = () => {
    const types = Object.entries(notifications.types)
      .map(([key, value]) => {
        if (value) {
          return key;
        } else {
          return null;
        }
      })
      .filter((val) => val !== null)
      .reduce((acc, current) => current + ", " + acc, "");

    return types
      .substring(0, types.length - 2)
      .concat(notifications.schedule ? ` (${notifications.schedule})` : "");
  };

  const userIdHandler = () => dispatch(openModal({ current: "userId" }));

  let generalSettingsValues = [
    {
      label: `${userAccount[language].currency}`,
      value: client && client.currency.toUpperCase(),
      clickHandler: () => dispatch(openModal({ current: "editCurrency" })),
      type: "currency",
      verified: true,
    },
    {
      label: `${userAccount[language].measure}`,
      value: client && capitalize(client.measurement),
      clickHandler: () => dispatch(openModal({ current: "editUnits" })),
      verified: true,
    },
  ];

  let alertSettingsValues = [
    {
      label: `${userAccount[language].phoneNotification}`,
      value: client && notifications["phone"] ? notifications["phone"] : "---",
      clickHandler: () => dispatch(openModal({ current: "changePhone" })),
      type: "phone",
      verified:
        client && notifications["phone"]
          ? user.attributes["phone_number_verified"]
          : true,
      verifyHandler: async (phone) => {
        await verifyUserAttribute("phone_number");
        dispatch(openModal({ current: "confirmPhone", data: phone }));
      },
    },
    {
      label: `${userAccount[language].notificationStyle}`,
      value: client && buildNotificationStyle(),
      clickHandler: () => dispatch(openModal({ current: "editNotification" })),
      type: "types",
      verified: true,
    },
  ];

  let profileValues = [
    {
      label: `${userAccount[language].fullName}`,
      value:
        user &&
        user.attributes["given_name"] + " " + user.attributes["family_name"],
      clickHandler: () => dispatch(openModal({ current: "changeName" })),
      verified: true,
    },
  ];

  if (!checkIsAgent(user)) {
    profileValues.push({
      label: `${userAccount[language].profileType}`,
      value: client && client.profile?.isRealtor ? "Agent" : "Client",
      clickHandler: () =>
        client.profile
          ? dispatch(openModal({ current: "editProfileType" }))
          : null,
      verified: true,
    });
  }

  if (user && !user.username.includes("_"))
    profileValues.push({
      label: `${userAccount[language].password}`,
      value: "**********",
      clickHandler: () => dispatch(openModal({ current: "changePassword" })),
      verified: true,
    });

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.userAccount}>
          <div className={styles.sections}>
            <div className={styles.leftSection}>
              <Settings
                profile={true}
                userIdHandler={userIdHandler}
                heading={userAccount[language].heading}
                subHeading={`${userAccount[language].member} ${
                  client && moment(client.createdAt).format("ll")
                }`}
                user={user}
                client={client}
                values={profileValues}
                setModal={(type) => dispatch(openModal({ current: type }))}
                logoutHandler={handleSignOut}
                language={language}
                style={{ height: "76rem" }}
              />
            </div>

            <div className={styles.rightSection}>
              <Settings
                profile={false}
                heading={userAccount[language].general}
                subHeading={userAccount[language].generalSubHeading}
                values={generalSettingsValues}
                language={language}
                style={{ height: "36rem" }}
              />
              <Settings
                profile={false}
                heading={userAccount[language].alert}
                subHeading={userAccount[language].alertSubHeading}
                values={alertSettingsValues}
                language={language}
                style={{ height: "36rem" }}
              />
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default withAuthentication(UserAccount);
