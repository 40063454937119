import { useState, useEffect } from "react";
import { hapticsImpact } from "../../../utils/functions";
import styles from "./toggle.module.scss";

const Toggle = ({
  active,
  onToggle,
  hasLabel = true,
  isSize = false,
  isYesNo = false,
  circleStyle,
  labelStyle,
  color = "orange",
  disabled = false,
  ...rest
}) => {
  const [toggleStatus, setToggleStatus] = useState(active);

  const handleToggle = async () => {
    await hapticsImpact();
    setToggleStatus(!toggleStatus);
    onToggle();
  };

  useEffect(() => {
    setToggleStatus(active);
  }, [active]);
  return (
    <div
      className={`${styles.toggle} ${toggleStatus && styles[color]} ${
        disabled && styles.disabled
      }`}
      onClick={!disabled ? handleToggle : null}
      {...rest}
    >
      <span
        className={`${styles.circle} ${toggleStatus && styles.activeCircle}`}
        style={
          circleStyle
            ? toggleStatus
              ? circleStyle.activeCircle
              : circleStyle.circle
            : null
        }
      />
      <span
        className={`${styles.label} ${toggleStatus && styles.activeLabel}`}
        style={labelStyle}
      >
        {hasLabel
          ? isSize
            ? toggleStatus
              ? "Feet"
              : "Meters"
            : isYesNo
            ? toggleStatus
              ? "Yes"
              : "No"
            : toggleStatus
            ? "On"
            : "Off"
          : null}
      </span>
    </div>
  );
};

export default Toggle;
