import { useSelector } from "react-redux";
import moment from "moment";
import millify from "millify";
import numeral from "numeral";
import { IonIcon } from "@ionic/react";
import {
  arrowDownOutline,
  arrowUpOutline,
  checkmarkOutline,
} from "ionicons/icons";
import styles from "./right-navbar.module.scss";
import NewStatus from "./components/NewStatus/new-status.component";
import SoldStatus from "./components/soldStatus/sold-status.component";
import { selectCurrentClient } from "../../../../../../redux/client/client.selectors";
import { selectCurrencyRates } from "../../../../../../redux/currency/currency.selectors";
import { diffDate, getTagTitle } from "../../../../../../utils/functions";

const RightNavbar = ({
  listing,
  shareListingHandler,
  requestFormHandler,
  offerFormHandler,
  language,
}) => {
  const client = useSelector(selectCurrentClient);
  const rates = useSelector(selectCurrencyRates);
  const {
    listDate,
    soldDate,
    type,
    status,
    lastStatus,
    listPrice,
    soldPrice,
    updatedOn,
  } = listing;

  let currency;
  if (client && rates) {
    if (client.currency !== "cad") currency = client.currency.toUpperCase();
  }

  const renderPriceDiff = () => {
    const renderListPrice = () => (
      <span className={styles.listPrice}>
        List Price: ${millify(listPrice, { precision: 2 })}
      </span>
    );
    let diff = soldPrice - listPrice;
    if (currency) diff = (+diff / +rates.CAD.S) * +rates[currency].S;

    if (diff > 0) {
      return (
        <div className={styles.priceContainer}>
          <span className={styles.increased}>
            <IonIcon icon={arrowUpOutline} />
            <span className={styles.value}>
              {millify(diff, { precision: 2 })} above asking
            </span>
          </span>
          {renderListPrice()}
        </div>
      );
    }

    if (diff < 0) {
      return (
        <div className={styles.priceContainer}>
          <span className={styles.decreased}>
            <IonIcon icon={arrowDownOutline} />
            <span className={styles.value}>
              {millify(Math.abs(diff), { precision: 2 })} below asking
            </span>
          </span>
          {renderListPrice()}
        </div>
      );
    }

    return (
      <div className={styles.priceContainer}>
        <span className={styles.asking}>
          <IonIcon icon={checkmarkOutline} />
          <span className={styles.text}>
            {type.toLowerCase() === "sale" ? "sold" : "leased"} at asking
          </span>
        </span>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      {status === "A" ? (
        <NewStatus
          shareListingHandler={shareListingHandler}
          requestFormHandler={requestFormHandler}
          offerFormHandler={offerFormHandler}
          language={language}
          type={type}
          lastStatus={lastStatus}
          listDate={diffDate(status, listDate, soldDate).label}
          listPrice={
            currency
              ? `${currency} ${millify(
                  (+listPrice / +rates.CAD.S) * +rates[currency].S
                )}`
              : numeral(listPrice).format("$1,000,000")
          }
        />
      ) : (
        <SoldStatus
          listDate={moment.utc(soldDate ? soldDate : updatedOn)}
          soldPrice={
            currency
              ? `${currency} ${millify(
                  (soldPrice ? +soldPrice : +listPrice / +rates.CAD.S) *
                    +rates[currency].S
                )}`
              : numeral(soldPrice ? soldPrice : listPrice).format("$1,000,000")
          }
          lastStatus={lastStatus}
          status={getTagTitle(lastStatus, type)}
          diffDate={
            diffDate(status, moment.utc(listDate), moment.utc(soldDate))
              .soldNumber
          }
          renderPriceDiff={renderPriceDiff}
          requestFormHandler={requestFormHandler}
          language={language}
        />
      )}
    </div>
  );
};

export default RightNavbar;
