import { useState } from "react";
import { TextToSpeech } from "@capacitor-community/text-to-speech";
import { IonIcon } from "@ionic/react";
import { play, stop } from "ionicons/icons";
import styles from "./note-player.module.scss";
import { hapticsImpact } from "../../../utils/functions";

const NotePlayer = ({ name, content, isAgent }) => {
  const [playing, setPlaying] = useState(false);

  const buildText = () => {
    if (isAgent) {
      return content;
    } else {
      return `Sent by: ${name}. ` + content + ". END OF NOTE";
    }
  };

  const handleTextToSpeech = async ({ text }) => {
    await hapticsImpact();
    if (!playing) {
      try {
        setPlaying(true);
        const lang = await TextToSpeech.getSupportedLanguages();
        await TextToSpeech.speak({
          text,
          locale: lang[0],
          rate: 1,
          category: "ambient",
        });
        setPlaying(false);
      } catch (err) {
        setPlaying(false);
      }
    } else {
      try {
        setPlaying(false);
        await TextToSpeech.stop();
      } catch (err) {
        setPlaying(false);
      }
    }
  };

  return (
    <span className={styles.speech}>
      <IonIcon
        icon={playing ? stop : play}
        onClick={() =>
          handleTextToSpeech({
            text: buildText(),
          })
        }
      />
    </span>
  );
};

export default NotePlayer;
