import { useState, useEffect } from "react";
import useComponentVisible from "../../../../hooks/useComponentVisible";
import RadioFilter from "../../../RadioFilter/radio-filter.component";
import Select from "../../../Form/Select/select.component";
import { dates as datesOptions } from "../../data/filters.data";
import moment from "moment";

const DateFilter = ({
  filters: { type, status, minSoldDate, minListDate, maxSoldDate },
  updateMultipleFilters,
  disabled,
}) => {
  const [options, setOptions] = useState(datesOptions);
  const { ref, isOpen, setIsOpen } = useComponentVisible(false);

  const getDateTitle = () => {
    if (!minSoldDate && !minListDate) return "any";
    if (minSoldDate && maxSoldDate)
      return `Year ${minSoldDate.substring(0, 4)}`;
    return (
      moment(new Date()).diff(minSoldDate ? minSoldDate : minListDate, "days") +
      "d"
    );
  };

  const onChangeHandler = ({ value }) => {
    if (status === "A") {
      updateMultipleFilters({
        minListDate: value
          ? moment().subtract(value, "days").format("YYYY-MM-DD")
          : null,
        minSoldDate: null,
        maxSoldDate: null,
      });
    } else {
      updateMultipleFilters({
        minListDate: null,
        maxListDate: null,
        minSoldDate: value
          ? value.toString().startsWith("y")
            ? `${value.replace("y", "")}-01-01`
            : moment().subtract(value, "days").format("YYYY-MM-DD")
          : null,
        maxSoldDate: value?.toString().startsWith("y")
          ? `${+value.replace("y", "")}-12-31`
          : null,
      });
    }
  };

  const getDateValue = () => {
    if (status === "A") {
      if (!minListDate) return "Any time";
      return options.find(
        (opt) => opt.value === moment(new Date()).diff(minListDate, "days")
      )?.label;
    } else {
      if (minSoldDate && maxSoldDate) {
        return options.find(
          (opt) => opt.value === `y${minSoldDate.substring(0, 4)}`
        )?.label;
      }
      if (!minSoldDate) return "Any time";
      return options.find(
        (opt) => opt.value === moment(new Date()).diff(minSoldDate, "days")
      ).label;
    }
  };

  useEffect(() => {
    if (status === "A") {
      setOptions(datesOptions);
    } else {
      setOptions(datesOptions.filter((opt) => opt.value));
    }
  }, [status]);
  return (
    <div ref={ref}>
      <Select
        label="when"
        title={getDateTitle()}
        open={isOpen}
        onClick={() => setIsOpen((open) => !open)}
        disabled={disabled}
      />

      <RadioFilter
        open={isOpen}
        value={getDateValue()}
        options={options.filter((opt) => {
          if (!opt.value) return true;
          if (status === "U") return true;
          if (typeof opt.value !== "string") return true;
          return false;
        })}
        onClick={onChangeHandler}
        size={2}
        showScroll
      />
    </div>
  );
};

export default DateFilter;
