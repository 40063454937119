import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { signOutStart } from "../../../redux/user/user.actions";

const Logout = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(signOutStart());
  }, [dispatch]);
  return <Redirect to="/" />;
};

export default Logout;
