import { useState, useEffect, useCallback } from "react";
import styles from "./maintenance-filter.module.scss";
import { IonRange } from "@ionic/react";
import withFilters from "../../../../../HOC/withFilters/with-filters";
import millify from "millify";
import { scalePow } from "d3-scale";

const MaintenanceFilter = ({
  extraFilters: { maintenance },
  updateExtraFilters,
}) => {
  const reverse = useCallback(
    (value) =>
      scalePow().exponent(2).domain([0, 100]).range([0, 4000]).invert(value),
    []
  );

  const [maintenanceFee, setMaintenanceFee] = useState(
    maintenance ? Math.round(reverse(+maintenance)) : 100
  );

  const handleOnChange = ({ detail: { value } }) => {
    setMaintenanceFee(value);
  };

  useEffect(() => {
    const scale = scalePow().exponent(2).domain([0, 100]).range([0, 4000]);

    updateExtraFilters({
      maintenance: maintenanceFee === 100 ? null : scale(maintenanceFee),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maintenanceFee]);

  return (
    <div className={styles.item}>
      <div className={styles.input}>
        <IonRange
          value={maintenanceFee}
          min={0}
          max={100}
          onIonChange={handleOnChange}
          className={styles.range}
        />
        <div className={styles.pins}>
          <span className={styles.pin}>
            {maintenance === 4000 || maintenance === null
              ? "max"
              : `$${millify(maintenance, {
                  precision: maintenance > 1000 ? 1 : 0,
                })}`}
          </span>
        </div>
      </div>
    </div>
  );
};

export default withFilters(MaintenanceFilter);
