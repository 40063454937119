import { createSelector } from "reselect";

export const selectSchools = (state) => state.schools;

export const selectMapSchools = createSelector(
  [selectSchools],
  (val) => val.schools
);

export const selectSelectedSchool = createSelector(
  [selectSchools],
  (val) => val.selected
);

export const selectIsSchoolsActive = createSelector(
  [selectSchools],
  (val) => val.active
);

export const selectSchoolsFilters = createSelector(
  [selectSchools],
  (val) => val.filters
);
