import { useDispatch } from "react-redux";
import { IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import Button from "../../Form/Button/button.component";
import { deletedAccount } from "../../../data/customModal";
import styles from "./deleted-account-modal.module.scss";
import { signOutStart } from "../../../redux/user/user.actions";

const DeletedAccountModal = ({ setType, close, language }) => {
  const dispatch = useDispatch();

  const registerHandler = () => {
    dispatch(signOutStart(true));
    close();
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>{deletedAccount[language].header}</div>
      <div className={styles.subtitle}>
        <span
          dangerouslySetInnerHTML={{
            __html: deletedAccount[language].subtitle,
          }}
        ></span>
      </div>

      <div className={styles.btns}>
        {/* <Button
          title={deletedAccount[language].button}
          type="purple"
          style={{ height: "5rem", width: "100%", border: "1px solid #7158AD" }}
          onClick={registerHandler}
        /> */}
        <Button
          title={deletedAccount[language].close}
          type="clear"
          style={{
            color: "#707070",
            marginTop: "0.5rem",
            width: "100%",
          }}
          onClick={() => {
            dispatch(signOutStart());
            close();
          }}
        >
          <IonIcon icon={closeOutline} />
        </Button>
      </div>
    </div>
  );
};
export default DeletedAccountModal;
