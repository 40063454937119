import {
  IonPage,
  IonContent,
  IonIcon,
  IonButton,
  IonLabel,
  IonModal,
} from "@ionic/react";
import { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./home.module.scss";
import EttieLogo from "../../../assets/svg/homepage/logo.svg";
import { key, personOutline, pricetag } from "ionicons/icons";
import chevron from "../../../assets/svg/homepage/chevron.svg";
import soldIcon from "../../../assets/svg/homepage/sold.svg";
import preconIcon from "../../../assets/svg/homepage/precon.svg";
import {
  selectCurrentUser,
  selectGuest,
} from "../../../redux/user/user.selectors";
import { updateMultipleFilters } from "../../../redux/filters/filters.actions";
import moment from "moment";
import Featured from "./components/featured.component";
import app from "../../../assets/svg/homepage/app.png";
import signup from "../../../assets/svg/homepage/signup.png";
import cart from "../../../assets/svg/homepage/cart.png";
import tag from "../../../assets/svg/homepage/tag.png";
import cheque from "../../../assets/svg/homepage/cheque.png";
import HomeCard from "./components/home-card.component";
import { isApp } from "../../../utils/functions";
import { InAppBrowser } from "@ionic-native/in-app-browser";
import { getHomeItems } from "../../../amplify/graphql.utils";
import { useHistory } from "react-router-dom";
import ContactAgentForm from "./components/ContactAgentForm/contact-agent-form.component";
import { selectCurrentClient } from "../../../redux/client/client.selectors";

const AppHomePage = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [featureds, setFeatureds] = useState([]);
  const [cards, setCards] = useState([]);
  const history = useHistory();
  const contentRef = useRef();
  const sectionsRef = useRef();
  const user = useSelector(selectCurrentUser);
  const client = useSelector(selectCurrentClient);
  const guest = useSelector(selectGuest);
  const [showForm, setShowForm] = useState(false);

  const handleBtn = (type) => {
    switch (type) {
      case "sale":
        dispatch(
          updateMultipleFilters({
            type: "sale",
            status: "A",
            lastStatus: null,
            displayPublic: "Y",
            displayAddressOnInternet: "Y",
            minListDate: null,
            minSoldDate: null,
            maxSoldDate: null,
          })
        );
        history.replace("/tabs/listings", { direction: "forward" });
        break;
      case "sold":
        dispatch(
          updateMultipleFilters({
            type: "sale",
            status: "U",
            lastStatus: "Sld",
            displayPublic: null,
            displayAddressOnInternet: null,
            minListDate: null,
            maxSoldDate: null,
            minSoldDate: moment().subtract(30, "days").format("YYYY-MM-DD"),
          })
        );
        history.replace("/tabs/listings", { direction: "forward" });
        break;
      case "lease":
        dispatch(
          updateMultipleFilters({
            type: "lease",
            status: "A",
            lastStatus: null,
            displayPublic: "Y",
            displayAddressOnInternet: "Y",
            minListDate: null,
            minSoldDate: null,
            maxSoldDate: null,
          })
        );
        history.replace("/tabs/listings", { direction: "forward" });
        break;
      case "pre-construction":
        dispatch(
          updateMultipleFilters({
            type: "pre-construction",
          })
        );
        history.push("/tabs/listings", { direction: "forward" });
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const { cards, featureds } = await getHomeItems();
        setFeatureds(featureds);
        setCards(cards);
      } catch (err) {}
      setLoading(false);
    };

    getData();
  }, [user, guest]);

  const handleScroll = async () => {
    await contentRef.current.scrollToPoint(
      0,
      sectionsRef.current.offsetTop,
      1000
    );
  };

  const handleLink = (link) => {
    if (isApp()) {
      InAppBrowser.create(link, "_blank", {
        hideurlbar: true,
        hidenavigationbutton: true,
        toolbar: "yes",
        toolbarcolor: "#110d1b",
        closebuttoncolor: "#f4f4f7",
        closebuttoncaption: "Back",
        toolbarposition: "top",
      });
    } else {
      window.open(link, "_blank");
    }
  };

  return (
    <IonPage className={styles.page}>
      <IonContent className={styles.home} scrollEvents ref={contentRef}>
        <IonModal isOpen={showForm} onDidDismiss={() => setShowForm(false)}>
          <ContactAgentForm setShowModal={setShowForm} />
        </IonModal>

        <div className={styles.top}>
          <div className={styles.header}>
            <div className={styles.logo}>
              <IonIcon src={EttieLogo} />
            </div>
            {client && user ? (
              <span
                className={styles.welcome}
                onClick={() =>
                  history.push("/tabs/more", { direction: "forward" })
                }
              >
                Hi {client.givenName}!
              </span>
            ) : (
              <button
                className={`${styles.btn} ${styles.transparent}`}
                onClick={() =>
                  history.push("/signin", { direction: "forward" })
                }
              >
                <IonIcon icon={personOutline} />
                Sign in
              </button>
            )}
          </div>
          <div className={styles.content}>
            <div className={styles.title}>Welcome to Ettie!</div>
            <div className={styles.subtitle}>
              What are you looking for today?
            </div>
            <div className={styles.btns}>
              <button
                className={`${styles.btn} ${styles.white}`}
                onClick={() => handleBtn("sale")}
              >
                <IonIcon icon={pricetag} />
                <div className={styles.homeSale}>Homes for sale</div>
              </button>
              <button
                className={`${styles.btn} ${styles.white}`}
                onClick={() => handleBtn("sold")}
              >
                <IonIcon icon={soldIcon} />
                <div style={{ display: "grid" }}>Sold prices</div>
              </button>
              <button
                className={`${styles.btn} ${styles.white}`}
                onClick={() => handleBtn("lease")}
              >
                <IonIcon icon={key} />
                <div style={{ display: "grid" }}>Homes for lease</div>
              </button>
              <button
                className={`${styles.btn} ${styles.white}`}
                onClick={() => handleBtn("pre-construction")}
              >
                <IonIcon icon={preconIcon} />
                <div style={{ display: "grid" }}>Pre-construction</div>
              </button>

              {!loading ? (
                <div className={styles.scroll} onClick={handleScroll}>
                  <IonIcon className={styles.chevron} icon={chevron} />
                </div>
              ) : (
                <div className={styles.scrollContainer} />
              )}
            </div>
          </div>
        </div>
        {!loading && (
          <>
            <div className={styles.sections} ref={sectionsRef}>
              {featureds.length > 0 &&
                featureds
                  .sort((a, b) => {
                    if (a.order > b.order) return 1;
                    if (a.order < b.order) return -1;
                    return 0;
                  })
                  .map((featured, i) => <Featured item={featured} key={i} />)}
            </div>
            <div className={styles.note}>
              <div className={styles.title}>
                Ettie is a full-service brokerage that can guide you through the
                entire process – door to door.
              </div>
              <div className={styles.subtitle}>
                That’s why we’ve developed a search platform with specialized
                features available exclusively through Ettie.
              </div>
              <IonButton
                className={styles.clearBtn}
                fill="outline"
                onClick={() => history.push("/about-us")}
              >
                <IonLabel>What makes Ettie different?</IonLabel>
              </IonButton>
            </div>
            <div className={styles.cards}>
              {cards.length > 0 &&
                cards
                  .sort((a, b) => {
                    if (a.order > b.order) return 1;
                    if (a.order < b.order) return -1;
                    return 0;
                  })
                  .map((c, i) => <HomeCard key={i} item={c} />)}
              <div className={styles.textCard}>
                <div className={styles.title}>No commission fees</div>
                <div className={styles.text}>
                  Ettie uses a revolutionary à la carte service structure where
                  you put the entire 2.5%* commission fee towards paying for
                  only the services you need. When the deal is done, you get
                  thousands back in your pocket.
                </div>
              </div>
            </div>
            <div className={styles.tags}>
              <div className={styles.tag}>
                <img src={signup} alt="Sign up" />
                <span className={styles.title}>
                  Sign up for free with an Ettie agent.
                </span>
              </div>
              <div className={styles.tag}>
                <img src={app} alt="Find properties" />
                <span className={styles.title}>
                  Find properties and go on showings.
                </span>
              </div>
              <div className={styles.tag}>
                <img src={cart} alt="Book" />
                <span className={styles.title}>
                  Book with Ettie’s inspectors, stagers, contractors and other
                  services you need.
                </span>
              </div>
              <div className={styles.tag}>
                <img src={tag} alt="Work with agent" />
                <span className={styles.title}>
                  Work with your agent to put in an offer and close the deal.
                </span>
              </div>
              <div className={styles.tag}>
                <img src={cheque} alt="Cashback" />
                <span className={styles.title}>
                  Get a cheque for thousands of dollars you didn’t spend on
                  commission.
                </span>
              </div>
              <IonButton
                className={styles.agentBtn}
                fill="outline"
                onClick={() => setShowForm(true)}
              >
                <IonLabel>Connect with an Ettie agent</IonLabel>
              </IonButton>

              <div className={styles.commission}>
                *Based on the standard 2.5% co-operating agent commission.
                commissions may vary.
              </div>
            </div>
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default AppHomePage;
