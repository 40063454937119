import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryContainer,
  VictoryGroup,
} from "victory";
import styles from "./chart.module.scss";

const BarChart = ({ data, label, domain, scale, hideY = false, style }) => {
  return (
    <div className={styles.bar} style={style?.container}>
      <div className={styles.container}>
        {label && <div className={styles.label}>{label}</div>}
        <div className={styles.chartContainer} style={style?.chart}>
          <VictoryChart
            animate={{
              duration: 1000,
              onLoad: { duration: 1000 },
            }}
            domain={{ y: domain ? [0, domain] : null }}
            scale={{ y: scale ? scale : "linear" }}
            containerComponent={
              <VictoryContainer
                style={{
                  pointerEvents: "auto",
                  userSelect: "auto",
                  touchAction: "auto",
                }}
              />
            }
          >
            <VictoryAxis label="Years" style={{ axisLabel: { padding: 30 } }} />
            <VictoryAxis
              dependentAxis
              style={{
                axisLabel: { padding: 30 },
                tickLabels: { fill: hideY && "none" },
                axis: { strokeOpacity: hideY && 0 },
              }}
            />

            <VictoryGroup offset={25} colorScale={["#7158ad", "#33244c"]}>
              <VictoryBar data={data.school} padding={0} />
              <VictoryBar data={data.board} padding={0} />
            </VictoryGroup>
          </VictoryChart>
        </div>
      </div>
    </div>
  );
};

export default BarChart;
