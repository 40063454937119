import { useState, useEffect } from "react";
import {
  IonChip,
  IonInput,
  IonItem,
  IonLabel,
  IonIcon,
  IonRow,
  IonCol,
  IonGrid,
} from "@ionic/react";
import withFilters from "../../../../../HOC/withFilters/with-filters";
import styles from "./keywords-filter.module.scss";
import { closeCircleOutline } from "ionicons/icons";

const KeywordsFilter = ({ filters: { keywords }, updateSingleFilter }) => {
  const [value, setValue] = useState();
  const [tags, setTags] = useState(keywords ? keywords.split(",") : []);

  const handleChange = ({ detail: { value } }) => {
    if (value && (value.endsWith(",") || value.endsWith(" "))) {
      const term = value.substring(0, value.length - 1);
      if (term?.trim().length > 0) {
        setTags([...tags, value.substring(0, value.length - 1)]);
        setValue("");
      }
    } else {
      setValue(value);
    }
  };

  const handleBlur = () => {
    if (value) {
      setTags([...tags, value]);
      setValue("");
    }
  };
  const handleKeyPress = ({ key }) => {
    if (key === "Enter") {
      setTags([...tags, value]);
      setValue("");
    }
  };

  const removeTag = (index) => {
    setTags(tags.filter((tag, i) => i !== index));
  };

  useEffect(() => {
    updateSingleFilter({ keywords: tags.length ? tags.join(",") : null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags]);

  useEffect(() => {
    if (!keywords && tags && tags.length) setTags([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keywords]);

  return (
    <>
      <IonItem lines="none" className={styles.item}>
        <IonGrid>
          <IonRow className={styles.row}>
            <IonCol size="4">
              <IonLabel className={styles.label}>Keywords</IonLabel>
            </IonCol>
            <IonCol size="8">
              <IonInput
                value={value}
                className={styles.input}
                clearInput={true}
                onIonChange={handleChange}
                placeholder="pool, fireplace, etc."
                enterkeyhint="enter"
                onIonBlur={handleBlur}
                onKeyPress={handleKeyPress}
                onIonFocus={() =>
                  window.scrollTo(0, document.body.scrollHeight)
                }
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonItem>
      <IonItem lines="none" className={styles.item} style={{ padding: 0 }}>
        <IonGrid>
          <IonRow className={styles.row}>
            <IonCol size="4" />
            <IonCol size="8">
              {tags && tags.length > 0 && (
                <div className={styles.tags}>
                  {tags.map((tag, i) => (
                    <span key={i} className={styles.chipContainer}>
                      <IonChip className={styles.chip}>
                        <IonLabel className={styles.chipLabel}>{tag}</IonLabel>
                        <IonIcon
                          icon={closeCircleOutline}
                          color="white"
                          onClick={() => removeTag(i)}
                          className={styles.chipIcon}
                        />
                      </IonChip>
                    </span>
                  ))}
                </div>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonItem>
    </>
  );
};

export default withFilters(KeywordsFilter);
