import { useIonViewWillEnter } from "@ionic/react";
import { useRef, useEffect, useState } from "react";
import styles from "./slider.module.scss";

const Slider = ({ isApp, children, hasScrollBar = false }) => {
  const ref = useRef();
  const [active, setActive] = useState(false);
  let isDown = false;
  let startX;
  let scrollLeft;

  useIonViewWillEnter(() => {
    if (isApp && ref.current) {
      ref.current.scrollLeft = 0;
    }
  });

  const mouseDownListener = (e) => {
    isDown = true;
    startX = e.pageX - ref.current.offsetLeft;
    scrollLeft = ref.current.scrollLeft;
    setActive(true);
  };

  const mouseUpListener = (e) => {
    isDown = false;
    setActive(false);
  };

  const mouseLeaveListener = (e) => {
    isDown = false;
    setActive(false);
  };

  const mouseMoveListener = (e) => {
    if (!isDown) return;
    e.preventDefault();

    const x = e.pageX - ref.current.offsetLeft;
    const walk = (x - startX) * 3;
    ref.current.scrollLeft = scrollLeft - walk;
  };

  useEffect(() => {
    let current;
    if (ref.current) {
      current = ref.current;
      current.isDown = false;
      current.startX = undefined;
      current.scrollLeft = undefined;
      current.addEventListener("mousedown", mouseDownListener);
      current.addEventListener("mouseup", mouseUpListener);
      current.addEventListener("mouseleave", mouseLeaveListener);
      current.addEventListener("mousemove", mouseMoveListener);
      current.addEventListener("touchend", mouseUpListener);
    }

    return () => {
      if (current) {
        current.removeEventListener("mousedown", mouseDownListener);
        current.removeEventListener("mouseup", mouseUpListener);
        current.removeEventListener("mouseleave", mouseLeaveListener);
        current.removeEventListener("mousemove", mouseMoveListener);
        current.removeEventListener("touchend", mouseUpListener);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);
  return (
    <div
      ref={ref}
      className={`${styles.slider} ${active && styles.active} ${
        isApp && styles.app
      } ${hasScrollBar && styles.scrollbar}`}
    >
      {children}
    </div>
  );
};

export default Slider;
