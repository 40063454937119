import { useState, useRef, createRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  IonCol,
  IonGrid,
  IonRow,
  IonButton,
  IonIcon,
  IonAlert,
} from "@ionic/react";

import IsVisibleTag from "../../../../../../../components/IsVisibleTag/is-visible-tag.component";
import styles from "./agent-details.module.scss";
import { closeOutline } from "ionicons/icons";

import {
  addTour,
  updateTour,
} from "../../../../../../../amplify/graphql.utils";
import { updateToast } from "../../../../../../../redux/ui/ui.actions";

import { cancelTour } from "../../../../../../../api/ettie";

const AgentDetails = ({ tour, reload }) => {
  const [note, setNote] = useState("");
  const [archive, setArchive] = useState(false);
  const [cancel, setCancel] = useState(false);
  const dispatch = useDispatch();
  const inputRefs = useRef({
    tour_title: createRef(),
  });

  const handleNoteChange = ({ target: { value } }) => {
    setNote(value);
  };

  const handleClick = async () => {
    if (tour) {
      await updateTour({ id: tour.id, privateNote: note });
    } else {
      await addTour({ privateNote: note });
    }
    await reload();
  };

  useEffect(() => {
    if (tour) {
      setNote(tour.privateNote ? tour.privateNote : "");
    }
  }, [tour]);

  const handleArchive = async () => {
    try {
      await updateTour({ id: tour.id, status: "archived" });
      setArchive(false);

      dispatch(
        updateToast({
          open: true,
          type: "success",
          message: "Tour has been archived successfully.",
        })
      );
      await reload();
    } catch (err) {
      setArchive(false);
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message: "Something went wrong!",
        })
      );
    }
  };

  const sendCancelEmails = async () => {
    const clients = [];
    JSON.parse(tour.membersData)
      .filter((c) => c.role === "Client")
      .forEach((c) => clients.push({ name: c.givenName, email: c.email }));

    await updateTour({
      id: tour.id,

      status: "cancelled",
      privateNote: note,
    });

    if (tour.shared === "shared") {
      await cancelTour({ clients, date: tour.date });
    }

    await reload();
  };

  return (
    <IonGrid className={styles.agentDetails}>
      <IonAlert
        isOpen={archive}
        backdropDismiss={true}
        onDidDismiss={() => setArchive(false)}
        header="Archive tour"
        message="Are you sure? By archiving a tour, tour attendants will no longer be able to update its content."
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            handler: () => setArchive(false),
          },
          {
            text: "Archive",
            role: "submit",
            handler: () => handleArchive(),
          },
        ]}
      />
      <IonAlert
        isOpen={cancel}
        backdropDismiss={true}
        onDidDismiss={() => setCancel(false)}
        header="Cancel tour"
        subHeader="Do you want to inform attendees that this tour has been cancelled?"
        buttons={[
          {
            text: "Keep tour active",
            role: "cancel",
            handler: () => setCancel(false),
            cssClass: styles.btn,
          },
          {
            text: "Cancel the tour only",
            cssClass: styles.btn,
            handler: async () => {
              await updateTour({
                id: tour.id,

                status: "cancelled",
                privateNote: note,
              });
              await reload();
            },
          },
          {
            text: "Cancel and update attendees",
            cssClass: styles.btn,
            handler: sendCancelEmails,
          },
        ]}
      />
      <IonRow>
        <IonCol>
          <IsVisibleTag isVisible={false} expand="full" />
        </IonCol>
      </IonRow>

      <IonRow>
        <IonCol>
          <textarea
            className={styles.textarea}
            name="agent_note"
            placeholder="Private agent tour notes"
            ref={inputRefs.current.agent_note}
            value={note}
            onChange={handleNoteChange}
          ></textarea>
        </IonCol>
      </IonRow>

      <IonRow>
        <IonCol>
          <IonButton
            shape="round"
            expand="block"
            className={styles.button}
            disabled={!tour || !tour.status || tour.status === "archived"}
            onClick={handleClick}
          >
            Save changes
          </IonButton>
        </IonCol>
      </IonRow>

      <IonRow>
        <IonCol className={styles.note}>
          These fields will not be shared with the client.
        </IonCol>
      </IonRow>

      <IonRow>
        <IonCol>
          <IonButton
            fill="clear"
            shape="round"
            expand="block"
            className={styles.archiveButton}
            onClick={() => setArchive(true)}
            disabled={tour.status === "archived"}
          >
            {tour.status !== "archived" ? (
              <>
                <IonIcon icon={closeOutline} />
                Archive tour
              </>
            ) : (
              "This tour is archived"
            )}
          </IonButton>
        </IonCol>
        {!["archived", "cancelled", "completed"].includes(tour.status) && (
          <IonCol>
            <IonButton
              fill="clear"
              shape="round"
              expand="block"
              className={styles.cancelButton}
              onClick={() => setCancel(true)}
            >
              <IonIcon icon={closeOutline} />
              Cancel tour
            </IonButton>
          </IonCol>
        )}
      </IonRow>
    </IonGrid>
  );
};

export default AgentDetails;
