import { useState, useEffect } from "react";
import styles from "../filters-menu.module.scss";
import withFilters from "../../../HOC/withFilters/with-filters";
import { IonInput, IonChip, IonLabel, IonIcon } from "@ionic/react";
import { closeCircleOutline } from "ionicons/icons";

const KeywordsFilterItem = ({ filters: { keywords }, updateSingleFilter }) => {
  const [value, setValue] = useState();
  const [tags, setTags] = useState(keywords ? keywords.split(",") : []);

  const handleChange = ({ detail: { value } }) => {
    if (value && (value.endsWith(",") || value.endsWith(" "))) {
      const term = value.substring(0, value.length - 1);
      if (term?.trim().length > 0) {
        setTags([...tags, value.substring(0, value.length - 1)]);
        setValue("");
      }
    } else {
      setValue(value);
    }
  };

  const handleBlur = () => {
    if (value) {
      setTags([...tags, value]);
      setValue("");
    }
  };
  const handleKeyPress = ({ key }) => {
    if (key === "Enter") {
      setTags([...tags, value]);
      setValue("");
    }
  };

  const removeTag = (index) => {
    setTags(tags.filter((tag, i) => i !== index));
  };

  useEffect(() => {
    updateSingleFilter({ keywords: tags.length ? tags.join(",") : null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags]);

  useEffect(() => {
    if (!keywords && tags && tags.length) setTags([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keywords]);

  return (
    <div className={styles.item}>
      <div className={styles.keywords}>
        <div className={styles.label}>Keywords</div>
        <div className={styles.input} style={{ marginLeft: "1rem" }}>
          <IonInput
            value={value}
            className={styles.keywordInput}
            clearInput={true}
            onIonChange={handleChange}
            placeholder="pool, fireplace, etc."
            enterkeyhint="enter"
            onIonBlur={handleBlur}
            onKeyPress={handleKeyPress}
            onIonFocus={() => window.scrollTo(0, document.body.scrollHeight)}
          />
        </div>
      </div>
      <div className={styles.tags}>
        {tags && tags.length > 0 && (
          <div className={styles.tags}>
            {tags.map((tag, i) => (
              <span key={i} className={styles.chipContainer}>
                <IonChip className={styles.chip}>
                  <IonLabel className={styles.chipLabel}>{tag}</IonLabel>
                  <IonIcon
                    icon={closeCircleOutline}
                    color="white"
                    onClick={() => removeTag(i)}
                    className={styles.chipIcon}
                  />
                </IonChip>
              </span>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default withFilters(KeywordsFilterItem);
