import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { micOutline } from "ionicons/icons";
import CardContainer from "../../pages/Tours/component/CardContainer/card-container.component";
import DetailsHeader from "../TourDetailsHeader/tour-details-header.module";
import RecordingsSection from "./component/RecordingsSection/recordings-section.component";
import styles from "./recordings.module.scss";
import { buildAddress } from "../../utils/functions";
import { selectCurrentClient } from "../../redux/client/client.selectors";
import _ from "lodash";

const Recordings = ({
  recordingItems,
  isOpen,
  setIsOpen,
  tourItem,
  listing,
  handleDelete,
  playing,
  setPlaying,
  tour,
}) => {
  const client = useSelector(selectCurrentClient);
  const [recordings, setRecordings] = useState();

  useEffect(() => {
    const groupRecordings = () =>
      _.mapValues(_.groupBy(recordingItems, (recording) => recording.owner));
    if (recordingItems && client) setRecordings(groupRecordings());
  }, [client, recordingItems]);

  return client && tourItem ? (
    <CardContainer>
      <DetailsHeader
        title="All recordings"
        icon={micOutline}
        hasTag={false}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      {isOpen && (
        <div className={styles.recordingDetails}>
          <div className={styles.subtitle}>
            {tourItem.agentId === client.id ||
            tourItem.showingAgentId === client.id ||
            tourItem.members.includes(client.id)
              ? `All photos taken for ${buildAddress(
                  listing.address
                )}. by all tour
          attendees.`
              : "Only lead/showing agents can post a photo."}
          </div>

          {(tourItem.agentId === client.id ||
            tourItem.showingAgentId === client.id ||
            tourItem.members.includes(client.id)) && (
            <RecordingsSection
              handleDelete={handleDelete}
              isCurrentUser={true}
              recordings={recordings ? recordings[client.id] : []}
              memberData={JSON.parse(tourItem.membersData).find(
                (d) => d.id === client.id
              )}
              playing={playing}
              setPlaying={setPlaying}
              tour={tour}
              isApp={false}
            />
          )}
          {recordings &&
            Object.keys(recordings).map((key) => {
              if (recordings[key][0].owner === client.id) {
                return null;
              } else {
                return (
                  <RecordingsSection
                    handleDelete={handleDelete}
                    key={key}
                    isCurrentUser={false}
                    recordings={recordings[key]}
                    memberData={JSON.parse(tourItem.membersData).find(
                      (d) => d.id === recordings[key][0].owner
                    )}
                    playing={playing}
                    setPlaying={setPlaying}
                    tour={tour}
                    isApp={false}
                  />
                );
              }
            })}
        </div>
      )}
    </CardContainer>
  ) : null;
};

export default Recordings;
