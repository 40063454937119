import useSupercluster from "use-supercluster";
import { Geolocation } from "@ionic-native/geolocation";
import { useState, useEffect } from "react";

export const useCluster = (points, bounds, zoom) => {
  const [clusters, setClusters] = useState();
  const [supercluster, setSupercluster] = useState();

  const res = useSupercluster({
    points,
    bounds: bounds
      ? [bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat]
      : null,
    zoom,
    options: { radius: 120, maxZoom: 20 },
  });

  useEffect(() => {
    if (res) {
      setClusters(res.clusters);
      setSupercluster(res.supercluster);
    }
  }, [res]);
  if (
    clusters &&
    supercluster &&
    supercluster.points.length > 0 &&
    clusters.length === 0
  )
    return [undefined, undefined];
  return [clusters, supercluster];
};

export const findPoints = (listings) => {
  if (!listings || !listings.length) return;

  return listings.map((listing) => ({
    type: "Feature",
    properties: {
      cluster: false,
      item: listing,
      listingId: listing.mlsNumber,
      listingPrice:
        listing.status === "U" && ["Sld", "Lsd"].includes(listing.lastStatus)
          ? listing.soldPrice
          : listing.listPrice,
      status: listing.status,
      category: listing.type,
    },
    geometry: {
      type: "Point",
      coordinates: [
        parseFloat(listing.map.longitude),
        parseFloat(listing.map.latitude),
      ],
    },
  }));
};

export const getCurrentLocation = async () => {
  try {
    const position = await Geolocation.getCurrentPosition({
      timeout: 30000,
      enableHighAccuracy: true,
    });
    localStorage.setItem("location", "active");
    return position.coords;
  } catch (error) {
    return null;
  }
};
