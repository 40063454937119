import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  IonRow,
  IonCol,
  IonIcon,
  IonDatetime,
  IonItem,
  IonToast,
} from "@ionic/react";
import styles from "./date-time-picker.module.scss";
import { closeOutline } from "ionicons/icons";

const DateTimePicker = ({ value, onChange, onDelete, deletable }) => {
  const [error, setError] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);
  const { index, date, from, to } = value;
  const minDate = moment().format("YYYY-MM-DD");
  const currentTime = moment().format();

  useEffect(() => {
    if (from !== "") {
      if (currentTime > date) {
        if (from < currentTime) {
          setError("Please choose a valid time");
          setShowErrorToast(true);
          handleFromChange("");
        }
      }
    }

    if (to !== "") {
      if (from === "") {
        setError("Please choose a start time");
        setShowErrorToast(true);
        handleToChange("");
      } else {
        if (to < from) {
          setError("Please choose a valid end time");
          setShowErrorToast(true);
          handleToChange("");
        }
      }
    }
  }, [from, to]);

  const handleDateChange = (date) => {
    if (date) onChange({ ...value, date });
  };

  const handleFromChange = (from) => {
    onChange({ ...value, from });
  };

  const handleToChange = (to) => {
    onChange({ ...value, to });
  };

  return (
    <>
      <IonToast
        isOpen={showErrorToast}
        onDidDismiss={() => {
          setError("");
          setShowErrorToast(false);
        }}
        message={error}
        cssClass={styles.errorToast}
        position="top"
        buttons={[
          {
            side: "end",
            icon: "close",
            handler: () => setError(""),
          },
        ]}
        duration={4000}
      />
      <IonRow className={styles.dateTimePicker}>
        <IonCol className={styles.icon}>
          {deletable && (
            <IonIcon icon={closeOutline} onClick={() => onDelete(index)} />
          )}
        </IonCol>

        <IonCol>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonDatetime
                  displayFormat="MMM DD YYYY"
                  min={minDate}
                  placeholder="Select Date"
                  value={date}
                  onIonChange={(e) => handleDateChange(e.detail.value)}
                ></IonDatetime>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonDatetime
                  placeholder="From"
                  displayFormat="h:mm A"
                  minuteValues="0,15,30,45"
                  value={from}
                  onIonChange={(e) => handleFromChange(e.detail.value)}
                ></IonDatetime>
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem>
                <IonDatetime
                  placeholder="To"
                  displayFormat="h:mm A"
                  minuteValues="0,15,30,45"
                  value={to}
                  onIonChange={(e) => handleToChange(e.detail.value)}
                ></IonDatetime>
              </IonItem>
            </IonCol>
          </IonRow>
        </IonCol>
      </IonRow>
    </>
  );
};

export default DateTimePicker;
