import styles from "./modal-header.module.scss";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonTitle,
} from "@ionic/react";

const ModalHeader = ({ title, close }) => (
  <IonHeader className={styles.header}>
    <IonToolbar className={styles.toolbar}>
      <IonButtons slot="end">
        <IonButton onClick={close} className={styles.btn} fill="clear">
          Cancel
        </IonButton>
      </IonButtons>
      <IonTitle className={styles.title}>{title}</IonTitle>
    </IonToolbar>
  </IonHeader>
);

export default ModalHeader;
