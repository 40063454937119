import React from "react";
import { IonIcon } from "@ionic/react";
import { eyeOutline, eyeOffOutline } from "ionicons/icons";
import styles from "./is-visible-tag.module.scss";

const IsVisibleTag = ({ isVisible, expand = "block" }) => {
  return (
    <div
      className={`${styles.tag} ${
        isVisible ? styles.visible : styles.invisible
      } ${expand === "full" && styles.full}`}
    >
      {isVisible ? (
        <>
          <IonIcon icon={eyeOutline} className={styles.icon} />
          <span>visible to client</span>
        </>
      ) : (
        <>
          <IonIcon icon={eyeOffOutline} className={styles.icon} />
          <span>not visible to client</span>
        </>
      )}
    </div>
  );
};

export default IsVisibleTag;
