import { useRef } from "react";
import { IonRefresher, IonRefresherContent } from "@ionic/react";
import { chevronDownOutline } from "ionicons/icons";
import styles from "./refresher.module.scss";

const Refresher = ({ onRefresh, background }) => {
  const ref = useRef();
  return (
    <IonRefresher
      ref={ref}
      className={background && styles[background]}
      slot="fixed"
      onIonRefresh={() => onRefresh(ref.current)}
      pullFactor={0.5}
      pullMin={50}
      pullMax={125}
    >
      <IonRefresherContent
        className={`${styles.content} ${background && styles[background]}`}
        pullingIcon={chevronDownOutline}
        refreshingSpinner="lines"
      ></IonRefresherContent>
    </IonRefresher>
  );
};

export default Refresher;
