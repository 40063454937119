import { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { IonIcon } from "@ionic/react";
import {
  checkmarkOutline,
  closeOutline,
  linkOutline,
  logoFacebook,
  logoLinkedin,
  logoTwitter,
  logoWhatsapp,
  mailOutline,
} from "ionicons/icons";
import { openModal } from "../../../redux/ui/ui.actions";
import DetailPageButton from "../../../pages/Detail/Sections/LeftSection/components/DetailPageButtons/DetailPageButton/detail-page-button.component";
import styles from "./share-listing-modal.module.scss";
import Button from "../../Form/Button/button.component";
import { shareListing } from "../../../data/customModal";
import { InAppBrowser } from "@ionic-native/in-app-browser";

const ShareListingModal = ({
  close,
  data: { mlsNumber, text, isFavourite, listing },
  language,
}) => {
  const [isShared, setIsShared] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const timerRef = useRef();
  const dispatch = useDispatch();
  const emailText = `Check this listing out on Ettie!%0D%0A${text}%0D%0A${window.location.href}%0D%0A`;
  const shareListingEmailHandler = () =>
    InAppBrowser.create(
      `mailto:yourfriend@email.com?Subject=Check%20this%20listing%20out%20on%20Ettie!&body=${emailText}`,
      "_blank",
      {
        hideurlbar: true,
        hidenavigationbutton: true,
        toolbar: "yes",
        toolbartranslucent: "yes",
      }
    );
  // dispatch(
  //   openModal({
  //     current: "shareListingEmail",
  //     data: { mlsNumber, text, isFavourite, listing },
  //   })
  // );

  const copyLinkHandler = () => {
    const url = window.location.href;
    navigator.clipboard.writeText(url);
    setIsCopied(true);
    const ref = setTimeout(() => {
      setIsCopied(false);
    }, 2500);

    timerRef.current = ref;
  };

  const shareHandler = (m) => {
    const url = window.location.href;
    switch (m) {
      case "facebook":
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${url}`,
          "_blank"
        );
        break;
      case "whatsapp":
        window.open(
          `whatsapp://send?text=Check this listing out!%0A${text}%0A${url}`
        );
        break;
      case "twitter":
        window.open(
          `https://twitter.com/intent/tweet?text=${encodeURI(
            "Check this listing out!"
          )}%0A${encodeURI(text)}%0A${encodeURI(url)}`
        );
        break;
      case "linkedin":
        window.open(
          `https://www.linkedin.com/sharing/share-offsite/?url=${url}`
        );
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    return timerRef.current ? clearTimeout(timerRef.current) : null;
  }, []);
  return (
    <>
      {!isShared ? (
        <div id="listingModal" className={styles.shareListing}>
          <div className={styles.title}>Share this property</div>
          <div className={styles.info}>{text}</div>
          <div className={styles.btns}>
            <DetailPageButton
              icon={linkOutline}
              isImage={false}
              label={isCopied ? "Copied" : "Copy link"}
              style={{
                width: "26rem",
                height: "5.8rem",
                border: "none",
                backgroundColor: isCopied && "#dff7f0",
                transition: "all 0.3s",
              }}
              iconStyle={{ width: "2.8rem", height: "3rem" }}
              labelStyle={{ fontSize: "1.5rem" }}
              onClick={isCopied ? null : copyLinkHandler}
            />
            <DetailPageButton
              icon={mailOutline}
              isImage={false}
              label="Email"
              style={{ width: "26rem", height: "5.8rem", border: "none" }}
              iconStyle={{ width: "2.8rem", height: "3rem" }}
              labelStyle={{ fontSize: "1.5rem" }}
              onClick={shareListingEmailHandler}
            />
          </div>
          <div className={styles.logos}>
            <div
              className={styles.logo}
              onClick={() => shareHandler("facebook")}
            >
              <IonIcon icon={logoFacebook} />
              <span>Facebook</span>
            </div>
            {/* <div
              className={styles.logo}
              onClick={() => shareHandler("instagram")}
            >
              <IonIcon icon={logoInstagram} />
              <span>Instagram</span>
            </div> */}
            <div
              className={styles.logo}
              onClick={() => shareHandler("linkedin")}
            >
              <IonIcon icon={logoLinkedin} />
              <span>Linkedin</span>
            </div>
            <div
              className={styles.logo}
              onClick={() => shareHandler("twitter")}
            >
              <IonIcon icon={logoTwitter} />
              <span>Twitter</span>
            </div>
            <div
              className={styles.logo}
              onClick={() => shareHandler("whatsapp")}
            >
              <IonIcon icon={logoWhatsapp} />
              <span>WhatsApp</span>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.container}>
          <div className={styles.pic}>
            <IonIcon icon={checkmarkOutline} />
          </div>
          <div className={styles.title}>{shareListing[language].shared}</div>
          <div className={styles.btn}>
            <Button
              title="close"
              type="clear"
              style={{
                color: "#707070",
                marginTop: "0.5rem",
                width: "100%",
              }}
              onClick={close}
            >
              <IonIcon
                icon={closeOutline}
                style={{
                  height: "2rem",
                  width: "2rem",
                  color: "#707070",
                  alignSelf: "self-start",
                }}
              />
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default ShareListingModal;
