import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import styles from "./search-modal.module.scss";
import {
  IonSearchbar,
  IonLoading,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
} from "@ionic/react";
import { getListing, getMapListings } from "../../../../../../api/repliers";
import { selectLocationsCitiesAndNeighbourhoods } from "../../../../../../redux/locations/locations.selectors";
import SearchResult from "../../../../../LocationSearch/components/search-result.component";
import { warningOutline } from "ionicons/icons";
import { Geolocation } from "@ionic-native/geolocation";
import { NativeGeocoder } from "@ionic-native/native-geocoder";
import {
  buildAddress,
  getHighlightedLength,
} from "../../../../../../utils/functions";

const regex = /^[a-zA-Z]\d+/;
const validRegex = /^[a-zA-Z]\d{7}/;

const SearchModal = ({ close, mapRef }) => {
  const history = useHistory();
  const locations = useSelector(selectLocationsCitiesAndNeighbourhoods);
  const [term, setTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState();
  const [location, setLocation] = useState({
    coords: { latitude: null, longitude: null },
    address: null,
    listings: null,
  });

  const handleChange = async ({ detail: { value: term } }) => {
    setTerm(term);

    if (!term) {
      setResults(null);
    } else {
      setLoading(true);
      if (regex.test(term)) {
        if (validRegex.test(term)) {
          try {
            const response = await getListing(term);

            setResults({ listings: [response], locations: null });
          } catch (err) {
            setResults({ listings: null, locations: null });
          }
        } else {
          setResults({ listings: null, locations: null });
        }
      } else {
        try {
          const response = term.length >= 3 ? await getMapListings(term) : [];

          const foundLocations = locations.find((location) =>
            location.name.toLowerCase().includes(term.toLowerCase())
          );

          setResults({
            listings: response.length ? [...response] : null,
            locations: foundLocations ? [foundLocations] : null,
          });
        } catch (err) {
          setResults(null);
          setLoading(false);
        }
      }
      setLoading(false);
    }
  };

  const onSearchResultClick = (item, type) => {
    if (type === "listing") {
      history.push(`/listings/${item.mlsNumber}`);
    } else if (type === "currentLocation") {
      mapRef.panTo({
        lat: item.latitude,
        lng: item.longitude,
      });
    } else {
      mapRef.panTo({
        lat: item.location.lat,
        lng: item.location.lng,
      });
    }
    close();
  };

  useEffect(() => {
    const locate = async () => {
      try {
        const c = await Geolocation.getCurrentPosition();

        if (c) {
          try {
            const addresses = await NativeGeocoder.reverseGeocode(
              c.coords.latitude,
              c.coords.longitude,
              { useLocale: true, maxResults: 1 }
            );
            const address = addresses[0];
            const response = await getMapListings(address.subLocality);
            setLocation({
              coords: {
                latitude: c.coords.latitude,
                longitude: c.coords.longitude,
              },
              address,
              listings: response.length ? [...response] : null,
            });
          } catch (err) {}
        }
      } catch (err) {}
    };
    locate();
  }, []);

  const compareListings = (a, b) => {
    const aMatch = getHighlightedLength(
      `${a.address.city} - ${buildAddress(a.address)}`,
      term
    );

    const bMatch = getHighlightedLength(
      `${b.address.city} - ${buildAddress(b.address)}`,
      term
    );

    if (aMatch > bMatch) return -1;
    if (aMatch === bMatch) return 0;
    return 1;
  };

  return (
    <div className={styles.container}>
      <div className={styles.searchbar}>
        <IonSearchbar
          placeholder="location or MLS #"
          animated={true}
          autocomplete="address-level2"
          inputMode="text"
          showCancelButton="always"
          showClearButton="never"
          className={styles.input}
          onIonCancel={close}
          cancelButtonText="Done"
          onIonChange={handleChange}
          debounce={250}
        />
      </div>
      <div className={styles.results}>
        <IonLoading
          isOpen={loading}
          keyboardClose={false}
          spinner="dots"
          translucent={true}
          cssClass={styles.loading}
        />

        {((results && results.locations) || location.address) && (
          <div className={styles.result}>
            <IonItem lines="full" className={styles.title}>
              <IonLabel>LOCATIONS</IonLabel>
            </IonItem>
            <IonList className={styles.list}>
              {location && location.address && (
                <SearchResult
                  type="current location"
                  item={{
                    name:
                      location.address.subLocality +
                      ", " +
                      location.address.subAdministrativeArea,
                  }}
                  onClick={() =>
                    onSearchResultClick(location.coords, "currentLocation")
                  }
                />
              )}

              {results &&
                results.locations &&
                results.locations.map((location) => (
                  <SearchResult
                    key={location.name}
                    item={location}
                    term={term}
                    type={location.type}
                    onClick={() => onSearchResultClick(location, "location")}
                  />
                ))}
            </IonList>
          </div>
        )}

        {results && results.listings && (
          <div className={styles.result}>
            <IonItem lines="full" className={styles.title}>
              <IonLabel>LISTINGS</IonLabel>
            </IonItem>
            <IonList className={styles.list}>
              {results &&
                results.listings &&
                results.listings
                  .sort(compareListings)
                  .map((listing) => (
                    <SearchResult
                      key={listing.mlsNumber}
                      item={listing}
                      term={term}
                      type="listing"
                      onClick={() => onSearchResultClick(listing, "listing")}
                    />
                  ))}
            </IonList>
          </div>
        )}

        {location && location.listings && !term && (
          <div className={styles.result}>
            <IonItem lines="full" className={styles.title}>
              <IonLabel>NEARBY LISTINGS</IonLabel>
            </IonItem>
            <IonList className={styles.list}>
              {location &&
                location.listings &&
                !term &&
                location.listings.map((listing) => (
                  <SearchResult
                    key={listing.mlsNumber}
                    item={listing}
                    term={location.address.subLocality}
                    type="listing"
                    onClick={() => onSearchResultClick(listing, "listing")}
                  />
                ))}
            </IonList>
          </div>
        )}

        {results && !results.listings && !results.locations && (
          <div className={styles.noResult}>
            <IonIcon icon={warningOutline} className={styles.icon} />
            <IonLabel className={styles.label}>
              No listing found. Try revising your search.
            </IonLabel>
          </div>
        )}
      </div>
    </div>
  );
};
export default SearchModal;
