import { useState, useEffect } from "react";
import styles from "./lot-width-filter.module.scss";
import { IonRange } from "@ionic/react";
import withFilters from "../../../../../HOC/withFilters/with-filters";

const LotWidthFilter = ({
  extraFilters: {
    lotWidth: { min, max },
  },
  updateExtraFilters,
}) => {
  const [width, setWidth] = useState({
    lower: min ? min : 0,
    upper: max === "max" || !max ? 100 : max,
  });

  const handleOnChange = ({
    detail: {
      value: { lower, upper },
    },
  }) => {
    if (lower !== width.lower || upper !== width.upper)
      setWidth({ lower, upper });
  };

  useEffect(() => {
    updateExtraFilters({
      lotWidth: {
        min: width.lower,
        max: width.upper === 100 ? "max" : width.upper,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  return (
    <div className={styles.item}>
      <div className={styles.input}>
        <IonRange
          value={width}
          min={0}
          max={100}
          step={5}
          snaps={true}
          ticks={true}
          dualKnobs
          onIonChange={handleOnChange}
          className={styles.range}
        />
        <div className={styles.pins}>
          <span className={styles.pin}>
            <span>{width.lower} ft</span>
          </span>
          <span className={styles.pin}>
            {width.upper === 100 ? "max" : <span>{width.upper} ft</span>}
          </span>
        </div>
      </div>
    </div>
  );
};

export default withFilters(LotWidthFilter);
