import { IonIcon } from "@ionic/react";
import { exitOutline } from "ionicons/icons";
import SectionHeader from "./component/SectionHeader/section-header.component";
import Button from "../../../components/Form/Button/button.component";
import AccountInput from "./component/AccountInput/account-input.component";
import { userAccount } from "../../../data/userAccount";
import styles from "./settings.module.scss";
import { checkIsAgent } from "../../../amplify/auth.utils";

const Settings = ({
  profile,
  userIdHandler,
  heading,
  subHeading,
  values,
  user,
  client,
  setModal,
  logoutHandler,
  language,
  ...rest
}) => {
  return (
    <div className={styles.setting} {...rest}>
      <div className={`${styles.rows} ${styles.border}`}>
        <SectionHeader
          profile={profile}
          heading={heading}
          subHeading={subHeading}
          givenName={user && user.attributes["given_name"]}
          familyName={user && user.attributes["family_name"]}
        />
      </div>
      {profile && (
        <AccountInput
          border={true}
          icon={true}
          label={userAccount[language].userId}
          value={user && user.attributes.email}
          verified={true}
          clickHandler={userIdHandler}
          language={language}
        />
      )}

      {values.map((value, index) => {
        return profile ? (
          <AccountInput
            key={index}
            border={true}
            language={language}
            {...value}
          />
        ) : values.length !== ++index ? (
          <AccountInput
            key={index}
            border={true}
            language={language}
            {...value}
          />
        ) : (
          <AccountInput
            key={index}
            border={false}
            language={language}
            {...value}
          />
        );
      })}
      {profile && (
        <div className={styles.btns}>
          <div className={styles.delete}>
            {!checkIsAgent(user) && (
              <Button
                title={userAccount[language].deletebtn}
                type="orange"
                onClick={() => setModal("deleteAccount")}
              />
            )}
          </div>
          <div className={styles.logout}>
            <Button
              title={userAccount[language].logoutbtn}
              type="purple"
              style={{ border: "1px solid #7158AD" }}
              onClick={logoutHandler}
            >
              <IonIcon icon={exitOutline} />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Settings;
