import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  copyToClipboard,
  diffDate,
  getPropertyType,
  getTagTitle,
  numberWithCommas,
  renderCurrencySign,
} from "../../utils/functions";
import styles from "./listing-card.module.scss";
import { IonIcon } from "@ionic/react";
import { ReactComponent as LocationIcon } from "../../assets/svg/location-pin.svg";
import { ReactComponent as HomeIcon } from "../../assets/svg/home.svg";
import TypeTag from "../TypeTag/type-tag.component.jsx";
import {
  arrowDownOutline,
  arrowUpOutline,
  bed,
  checkmarkOutline,
} from "ionicons/icons";
import millify from "millify";
import ListingImage from "../ListingImage/listing-image.component";
import withClient from "../../HOC/withClient/with-client";
import FavoriteIcon from "./components/FavoriteIcon/favorite-icon.component";
import ListingTag from "./components/ListingTag/listing-tag.component";
import { updateActiveListing } from "../../redux/map/map.actions";
import { selectCurrencyRates } from "../../redux/currency/currency.selectors";
import { updateToast } from "../../redux/ui/ui.actions";

const ListingCard = ({
  listing,
  map,
  currentClient,
  clientError,
  clientLoading,
  updates,
  isActiveComparables = false,
  addShowing,
  webAddShowing,
  handleAddShowing,
  pageHook,
  ...rest
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const rates = useSelector(selectCurrencyRates);

  let currency;
  if (currentClient && rates) {
    if (currentClient.currency !== "cad")
      currency = currentClient.currency.toUpperCase();
  }

  const {
    mlsNumber,
    listDate,
    soldDate,
    updatedOn,
    address,
    images,
    type,
    status,
    lastStatus,
    listPrice,
    soldPrice,
    details: {
      propertyType,
      style,
      numBedrooms,
      numBedroomsPlus,
      numBathrooms,
      numBathroomsPlus,
      numParkingSpaces,
      numGarageSpaces,
    },
  } = listing;

  let comparableStatus;
  isActiveComparables && (comparableStatus = "A");

  const buildAddress = () => {
    let fullAddress = "";
    if (address.unitNumber) fullAddress += `${address.unitNumber} - `;
    if (address.streetNumber) fullAddress += `${address.streetNumber} `;
    if (address.streetName) fullAddress += `${address.streetName} `;
    if (address.streetSuffix) fullAddress += `${address.streetSuffix} `;
    if (address.streetDirection) fullAddress += `${address.streetDirection} `;

    return fullAddress.trim();
  };

  const renderPrice = () => {
    return currency
      ? soldPrice > 0
        ? `${renderCurrencySign(currency, false)}${millify(
            (+soldPrice / +rates.CAD.S) * +rates[currency].S
          )}`
        : `${renderCurrencySign(currency, false)}${millify(
            (+listPrice / +rates.CAD.S) * +rates[currency].S
          )}`
      : soldPrice > 0
      ? `${renderCurrencySign(currency, false)}${numberWithCommas(soldPrice)}`
      : `${renderCurrencySign(currency, false)}${numberWithCommas(listPrice)}`;
  };

  const renderPriceDiff = () => {
    let diff = soldPrice - listPrice;
    if (currency) diff = (+diff / +rates.CAD.S) * +rates[currency].S;

    if (diff > 0) {
      return (
        <span className={styles.increased}>
          <IonIcon icon={arrowUpOutline} />
          <span className={styles.value}>
            {renderCurrencySign(currency)}
            {millify(diff, { precision: 2 })}
          </span>
          <span className={styles.text}>above asking</span>
        </span>
      );
    }

    if (diff < 0) {
      return (
        <span className={styles.decreased}>
          <IonIcon icon={arrowDownOutline} />
          <span className={styles.value}>
            {renderCurrencySign(currency)}
            {millify(Math.abs(diff), { precision: 2 })}
          </span>
          <span className={styles.text}>below asking</span>
        </span>
      );
    }

    return (
      <span className={styles.asking}>
        <IonIcon icon={checkmarkOutline} />
        <span className={styles.text}>
          {type.toLowerCase() === "sale" ? "sold" : "leased"} at asking
        </span>
      </span>
    );
  };

  const clickHandler = (e) => {
    e.stopPropagation();
    if (addShowing) {
      return;
    } else {
      history.push(`/listings/${listing.mlsNumber}`, {
        from:
          location.state && location.state.from
            ? [...location.state.from, location.pathname]
            : [location.pathname],
      });
    }
  };

  const handleMouseEnter = () => {
    !addShowing && dispatch(updateActiveListing(mlsNumber));
  };

  const handleMouseLeave = () => {
    !addShowing && dispatch(updateActiveListing(null));
  };

  const handleCopyToClipboard = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const res = await copyToClipboard(listing.mlsNumber);
    res &&
      dispatch(
        updateToast({
          open: true,
          type: "success",
          message: "Property MLS number has been copied to clipboard.",
        })
      );
  };

  return (
    <div
      className={`${map ? styles.map : styles.listingCard}`}
      {...rest}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {updates && <ListingTag updates={updates} />}

      <ListingImage
        img={images[0] ? images[0] : "default"}
        onClick={clickHandler}
      />
      <div className={styles.details}>
        <span className={styles.priceContainer}>
          <div className={styles.price} onClick={clickHandler}>
            <div className={styles.listPrice}>{renderPrice()}</div>
            {["Sld", "Lsd"].includes(lastStatus) && !map && (
              <div className={styles.priceDiff}>{renderPriceDiff()}</div>
            )}
          </div>
          {!addShowing && (
            <div className={styles.icons}>
              <FavoriteIcon
                map={map}
                listing={listing}
                isActiveComparables={isActiveComparables}
              />
            </div>
          )}
        </span>
        <span className={styles.addressContainer} onClick={clickHandler}>
          {!map && (
            <span className={styles.icon}>
              <LocationIcon />
            </span>
          )}

          <span className={styles.address}>{buildAddress()}</span>
        </span>
        {propertyType && (
          <span className={styles.typeContainer} onClick={clickHandler}>
            {!map && (
              <span className={styles.icon}>
                <HomeIcon />
              </span>
            )}

            <span className={styles.type}>{getPropertyType(propertyType)}</span>
          </span>
        )}

        <span className={styles.rooms} onClick={clickHandler}>
          {!map && <IonIcon icon={bed} className={styles.bedIcon} />}
          {numBedrooms && (
            <span className={styles.beds}>
              {numBedrooms}
              {numBedroomsPlus ? ` + ${numBedroomsPlus}` : ""} bed
            </span>
          )}
          {numBathrooms && (
            <span className={styles.baths}>
              <span className={styles.baths}>
                {numBathrooms}
                {numBathroomsPlus ? ` + ${numBathroomsPlus}` : ""} bath
              </span>
            </span>
          )}

          {!map && (numParkingSpaces || numGarageSpaces) && (
            <span className={styles.parkings}>
              {numParkingSpaces
                ? `${
                    numParkingSpaces.includes(".")
                      ? numParkingSpaces.split(".")[0]
                      : numParkingSpaces
                  } parking`
                : numGarageSpaces
                ? `${
                    numGarageSpaces.includes(".")
                      ? numGarageSpaces.split(".")[0]
                      : numGarageSpaces
                  } garage`
                : ""}
            </span>
          )}
        </span>
        {addShowing ? (
          <span
            className={`${styles.detailsBtn} ${
              webAddShowing && styles.webDetailsBtn
            }`}
            onClick={handleAddShowing}
          >
            Add to tour
          </span>
        ) : (
          <span className={styles.info} onClick={clickHandler}>
            <TypeTag
              type={isActiveComparables ? type : getTagTitle(lastStatus, type)}
              status={isActiveComparables ? comparableStatus : status}
              lastStatus={lastStatus}
            />

            <div className={styles.dateContainer}>
              {status === "A" || isActiveComparables ? (
                <div className={styles.date}>
                  {map
                    ? diffDate(
                        status,
                        listDate,
                        soldDate ? soldDate : updatedOn,
                        isActiveComparables
                      ).listedValue
                    : diffDate(
                        status,
                        listDate,
                        soldDate ? soldDate : updatedOn,
                        isActiveComparables
                      ).label}
                </div>
              ) : map ? (
                <div className={styles.date}>
                  {
                    diffDate(
                      status,
                      listDate,
                      soldDate ? soldDate : updatedOn,
                      isActiveComparables
                    ).soldDate
                  }
                </div>
              ) : (
                <div className={styles.onMarket}>
                  {
                    diffDate(
                      status,
                      listDate,
                      soldDate ? soldDate : updatedOn,
                      isActiveComparables
                    ).soldDate
                  }
                </div>
              )}
            </div>
          </span>
        )}
        {map && !addShowing && (
          <span className={styles.mapMlsContainer}>
            MLS&reg; number: <u onClick={handleCopyToClipboard}>{mlsNumber}</u>
          </span>
        )}
        {!map && !addShowing && (
          <div className={styles.mlsContainer}>
            MLS&reg; number: <u onClick={handleCopyToClipboard}>{mlsNumber}</u>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(withClient(ListingCard));
