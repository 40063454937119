import { useState, useRef, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonCol,
  IonIcon,
  IonButton,
  IonLoading,
} from "@ionic/react";
import {
  logoFacebook,
  logoInstagram,
  logoLinkedin,
  logoTwitter,
} from "ionicons/icons";
import { selectCurrentClient } from "../../../redux/client/client.selectors";
import Input from "../../../components/Form/Input/input.component";
import { InAppBrowser } from "@ionic-native/in-app-browser";
import styles from "./contact-us.module.scss";
import AppHeader from "../../components/Header/header.component";
import TextArea from "../../../components/Form/TextArea/textarea.component";
import { updateToast } from "../../../redux/ui/ui.actions";
import { contactUs } from "../../../api/ettie";
import { useHistory } from "react-router-dom";

const AppContactUs = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const client = useSelector(selectCurrentClient);

  const inputRefs = useRef({
    email: createRef(),
    given_name: createRef(),
    family_name: createRef(),
    phone: createRef(),
  });

  const [formData, setFormData] = useState({
    email: client ? client.email : "",
    given_name: client ? client.givenName : "",
    family_name: client ? client.familyName : "",
    phone: client ? client.phone : "",
    message: "",
  });

  const handleChange = (value, name) => {
    inputRefs.current[name].current.validate();
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const sendEmailHandler = async (platform) => {
    try {
      let Link = "mailto:info@ettie.ai?subject=Contact%20us";
      window.open(Link, "_system");
    } catch (error) {
      try {
        InAppBrowser.create(
          `mailto:info@ettie.ai?Subject=Contact%20us`,
          "_blank",
          {
            hideurlbar: true,
            hidenavigationbutton: true,
            toolbar: "yes",
            toolbarcolor: "#110d1b",
            closebuttoncolor: "#f4f4f7",
            closebuttoncaption: "Back",
            toolbarposition: "top",
          }
        );
      } catch (error) {
        console.log("Something went wrong!");
      }
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await contactUs({
        name: `${formData.given_name} ${formData.family_name}`,
        givenName: formData.given_name,
        email: formData.email,
        phone: formData.phone,
        message: formData.message,
      });
      dispatch(
        updateToast({
          open: true,
          type: "success",
          message: "Message sent successfully.",
        })
      );
      history.goBack();
    } catch (err) {
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message: "Something went wrong!",
        })
      );
    }
    setLoading(false);
  };

  return (
    <IonPage>
      <AppHeader title="Contact us" hasRightButton={false} />
      <IonLoading isOpen={loading} />
      <IonContent>
        <IonGrid className={styles.contact}>
          <IonRow>
            <IonCol className={styles.title}>
              Hello <span>{client ? client["givenName"] : "there"}!</span>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className={styles.subtitle}>
              We would love to hear from you! Whatever you need, feel free to
              shoot us a message right here, or through your email. You can also
              follow us on social media to see what Ettie is up to day-to-day.
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <div className={styles.socialmedia}>
                <div className={styles.label}>Social media</div>
                <div className={styles.icons}>
                  <IonIcon
                    icon={logoFacebook}
                    onClick={() =>
                      InAppBrowser.create(
                        "https://www.facebook.com/AskEttie",
                        "_system"
                      )
                    }
                  />
                  <IonIcon
                    icon={logoInstagram}
                    onClick={() =>
                      InAppBrowser.create(
                        "https://www.instagram.com/ettie.ai/",
                        "_system"
                      )
                    }
                  />
                  <IonIcon
                    icon={logoLinkedin}
                    onClick={() =>
                      InAppBrowser.create(
                        "https://www.linkedin.com/company/ettie-ai",
                        "_system"
                      )
                    }
                  />
                  <IonIcon
                    icon={logoTwitter}
                    onClick={() =>
                      InAppBrowser.create(
                        "https://twitter.com/ettie_ai",
                        "_system"
                      )
                    }
                  />
                </div>
              </div>
            </IonCol>
          </IonRow>
          <IonRow className={styles.email}>
            <IonCol>Email</IonCol>
            <IonCol className={styles.emailAddress} onClick={sendEmailHandler}>
              info@ettie.ai
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className={styles.owned}>
              <span className={styles.bold}> Owned and operated by</span>
              <div>Ettie Realty Inc.</div>
              <div>20 Bay St., 11th Floor Toronto, ON M5J 2N8</div>
            </IonCol>
          </IonRow>

          <IonRow className={styles.form}>
            <IonCol>
              <div className={styles.formlabel}>Talk to us</div>
              <div className={styles.formsubtitle}>
                Send us a message or an email, and we will get back to you
                within 24 hours.
              </div>
              <div className={styles.inputs}>
                <IonRow>
                  <IonCol>
                    <Input
                      name="given_name"
                      label="given name"
                      placeholder="given name"
                      required
                      ref={inputRefs.current.given_name}
                      value={formData["given_name"]}
                      defaultValue={client && client.givenName}
                      onChange={(value, name) => handleChange(value, name)}
                      validation="required|min:2"
                      isApp
                      enterkeyhint="next"
                      submit={() =>
                        inputRefs.current.family_name.current.next()
                      }
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <Input
                      name="family_name"
                      label="family name"
                      placeholder="family name"
                      required
                      ref={inputRefs.current.family_name}
                      value={formData["family_name"]}
                      defaultValue={client && client.familyName}
                      onChange={(value, name) => handleChange(value, name)}
                      validation="required|min:2"
                      isApp
                      enterkeyhint="next"
                      submit={() => inputRefs.current.email.current.next()}
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <Input
                      name="email"
                      label="email"
                      placeholder="email"
                      mode="email"
                      required
                      ref={inputRefs.current.email}
                      value={formData["email"]}
                      defaultValue={client && client.email}
                      onChange={(value, name) => handleChange(value, name)}
                      validation="required|email"
                      isApp
                      enterkeyhint="next"
                      submit={() => inputRefs.current.phone.current.next()}
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <Input
                      name="phone"
                      label="phone"
                      placeholder="phone"
                      mode="tel"
                      required
                      ref={inputRefs.current.phone}
                      value={formData["phone"]}
                      defaultValue={
                        client && client.phone && client.phone.substring(2)
                      }
                      onChange={(value, name) => handleChange(value, name)}
                      validation="required|phone"
                      isApp
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <TextArea
                      value={formData.message}
                      onChange={(val) =>
                        setFormData({ ...formData, message: val })
                      }
                      label="message"
                      placeholder="message"
                      required
                    />
                  </IonCol>
                </IonRow>
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonButton
                expand="block"
                shape="round"
                className={styles.button}
                disabled={
                  !formData.email ||
                  !formData.given_name ||
                  !formData.family_name ||
                  !formData.message
                }
                onClick={handleSubmit}
              >
                Submit
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default AppContactUs;
