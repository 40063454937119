import { useState } from "react";
import { useSelector } from "react-redux";
import { getPlatforms, IonIcon } from "@ionic/react";
import { selectCurrentClient } from "../../../redux/client/client.selectors";
import { selectCurrentUser } from "../../../redux/user/user.selectors";
import Button from "../../Form/Button/button.component";
import Checkbox from "../../Form/Checkbox/checkbox-component";
import styles from "./edit-notification-modal.module.scss";
import { editNotification } from "../../../data/customModal";
import { updateClient } from "../../../amplify/graphql.utils";
import { helpCircleOutline } from "ionicons/icons";
import ReactTooltip from "react-tooltip";

const EditNotificationModal = ({ setType, language }) => {
  const client = useSelector(selectCurrentClient);
  const user = useSelector(selectCurrentUser);
  const notifications = JSON.parse(client.notifications);
  const { schedule, types, phone } = notifications;
  const [loading, setLoading] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState(schedule);
  const [selectedTypes, setSelectedTypes] = useState({
    email: types.email,
    phone: types.phone,
    push: types.push,
  });

  const handleUpdate = async () => {
    setLoading("true");
    const updatedNotifications = {
      ...notifications,
      schedule: selectedSchedule,
      types: selectedTypes,
    };

    await updateClient({
      id: client.id,
      updates: { notifications: JSON.stringify(updatedNotifications) },
    });
    setLoading(false);
    setType("updatedNotification");
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>{editNotification[language].header}</div>
      <div className={styles.subtitle}>
        <span
          dangerouslySetInnerHTML={{
            __html: editNotification[language].subtitle,
          }}
        ></span>
      </div>

      <div className={styles.content}>
        <div className={styles.firstRow}>
          <div className={styles.title}>
            {editNotification[language].title_1}
          </div>
          <div className={styles.options}>
            <label
              className={styles.option}
              onClick={() => setSelectedSchedule("instantly")}
            >
              <span className={styles.radio}>
                <input
                  type="radio"
                  name="radio"
                  checked={selectedSchedule === "instantly"}
                  onChange={() => setSelectedSchedule("instantly")}
                />
                <span className={styles.radioControl} />
              </span>
              <span className={styles.label}>
                {editNotification[language].instantly}
              </span>
            </label>

            <label
              className={styles.option}
              onClick={() => setSelectedSchedule("daily")}
            >
              <span className={styles.radio}>
                <input
                  type="radio"
                  name="radio"
                  checked={selectedSchedule === "daily"}
                  onChange={() => setSelectedSchedule("daily")}
                />
                <span className={styles.radioControl} />
              </span>
              <span className={styles.label}>
                {editNotification[language].daily}
              </span>
            </label>

            <label
              className={styles.option}
              onClick={() => setSelectedSchedule("weekly")}
            >
              <span className={styles.radio}>
                <input
                  type="radio"
                  name="radio"
                  checked={selectedSchedule === "weekly"}
                  onChange={() => setSelectedSchedule("weekly")}
                />
                <span className={styles.radioControl} />
              </span>
              <span className={styles.label}>
                {editNotification[language].weekly}
              </span>
            </label>
            <label
              className={styles.option}
              onClick={() => setSelectedSchedule("never")}
            >
              <span className={styles.radio}>
                <input
                  type="radio"
                  name="radio"
                  checked={selectedSchedule === "never"}
                  onChange={() => setSelectedSchedule("never")}
                />
                <span className={styles.radioControl} />
              </span>
              <span className={styles.label}>
                {editNotification[language].never}
              </span>
            </label>
          </div>
        </div>
        <div className={styles.firstRow}>
          <div className={styles.title}>
            {editNotification[language].title_2}
          </div>
          <div className={styles.options}>
            <div className={styles.type}>
              <Checkbox
                checked={selectedTypes.email}
                onClick={() =>
                  setSelectedTypes({
                    ...selectedTypes,
                    email: !selectedTypes.email,
                  })
                }
                value={selectedTypes.email}
                label={editNotification[language].email}
              />
            </div>
            <div className={styles.type}>
              <Checkbox
                checked={selectedTypes.phone}
                onClick={() =>
                  setSelectedTypes({
                    ...selectedTypes,
                    phone: !selectedTypes.phone,
                  })
                }
                value={selectedTypes.phone}
                label={editNotification[language].sms}
                disabled={
                  phone
                    ? user.attributes["phone_number_verified"]
                      ? false
                      : true
                    : true
                }
              />
              <span data-tip data-for="phone" style={{ width: "max-content" }}>
                <IonIcon icon={helpCircleOutline} />
              </span>
              <ReactTooltip id="phone" backgroundColor="#7158AD">
                <span style={{ width: "30rem", overflowY: "scroll" }}>
                  Please add a phone number to your profile to <br /> ensable
                  SMS or app notifications.
                </span>
              </ReactTooltip>
            </div>

            <div className={styles.type}>
              <Checkbox
                checked={selectedTypes.push}
                onClick={() =>
                  setSelectedTypes({
                    ...selectedTypes,
                    push: !selectedTypes.push,
                  })
                }
                value={selectedTypes.push}
                disabled={getPlatforms().includes("capacitor") ? false : true}
                label={editNotification[language].push}
              />
              <span data-tip data-for="push" style={{ width: "max-content" }}>
                <IonIcon icon={helpCircleOutline} />
              </span>
              <ReactTooltip id="push" backgroundColor="#7158AD">
                <span style={{ width: "22rem", overflowY: "scroll" }}>
                  {
                    <span>
                      App notifications are not available through the website.
                      <br />
                      Please download the app from the <u>App Store</u> or{" "}
                      <u>Google Play</u> to enable app notifications.
                    </span>
                  }
                </span>
              </ReactTooltip>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Button
          title={editNotification[language].button}
          type="purple"
          style={{ height: "5rem", width: "100%", border: "1px solid #7158ad" }}
          onClick={handleUpdate}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default EditNotificationModal;
