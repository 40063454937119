import { useHistory } from "react-router";
import { IonRouterLink } from "@ionic/react";
import Card from "../../../Card/card.component";
import Toggle from "../../../Form/Toggle/toggle-component";
import styles from "./map-school-card.module.scss";

const MapSchoolCard = ({ school, active, onToggle, isDisabled }) => {
  const history = useHistory();
  const renderLanguages = () => {
    let str = "";
    if (school.isEnglish) str += "English\\";
    if (school.isFrenchImmersion) str += "French\\";
    return str.substring(0, str.length - 1);
  };

  const hasBoundary = () => {
    if (!school.boundaryArray || school.boundaryArray === "[]") return false;
    return true;
  };

  const renderScore = () => {
    if (
      !school.ettieOverallAvg ||
      school.ettieOverallAvg === 0 ||
      school.ettieOverallAvg === -1
    )
      return (
        <span className={styles.score} style={{ fontSize: "2.4rem" }}>
          N/A
        </span>
      );

    return (
      <>
        <span className={styles.score}>
          {(school.ettieOverallAvg / 10).toFixed(1)}
        </span>
        <span className={styles.scoreOut}>out of 10</span>
      </>
    );
  };
  return (
    <div className={styles.card}>
      <Card
        style={{
          padding: "1.5rem",
          width: "32rem",
        }}
        mapCard={true}
      >
        <div className={styles.header}>
          <div className={styles.name}>
            <IonRouterLink
              className={styles.link}
              routerLink={`/schools/${school.id}`}
            >
              {school.name}
            </IonRouterLink>
          </div>
          <div className={styles.info}>
            <span>
              {school.gradeFrom === -1 ? "JK" : school.gradeFrom}-
              {school.gradeEnd}
            </span>
            <span>{renderLanguages()}</span>
            <span>
              {school.isPublic
                ? "Public"
                : school.isPrivate
                ? "Private"
                : school.isCatholic
                ? "Catholic"
                : ""}
            </span>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.row}>
            <div className={styles.scoreContainer}>
              {/* <span className={styles.score}>2.4</span>
              <span className={styles.scoreOut}>out of 4</span>
               */}

              {renderScore()}
            </div>
            <div className={styles.details}>
              <span className={styles.title}>EQAO GTA School Ranking</span>
              <span className={styles.year}>2018-2019 G9: 94/261</span>
              <span className={styles.year}>5 year avg: 52</span>
            </div>
          </div>
          <div className={styles.row}>
            {hasBoundary() ? (
              <div className={styles.toggleContainer}>
                <span className={styles.label}>
                  {isDisabled && !active ? (
                    <span>You've selected 10 schools.</span>
                  ) : (
                    <span>
                      Select to restrict listings to this school's boundaries.
                      Select up to 10 schools at a time.
                    </span>
                  )}
                </span>
                <Toggle
                  active={active}
                  onToggle={onToggle}
                  disabled={isDisabled && !active}
                />
              </div>
            ) : (
              <div className={styles.noBoundaryContainer}>
                <span className={styles.label}>
                  School boundary is not available.
                </span>
              </div>
            )}
          </div>
          <div className={styles.row}>
            <div className={styles.note}>
              <span>Confirm boundary before purchasing.</span>
              <span>
                Information supplied by{" "}
                <u onClick={() => history.push("/school-terms")}>EQAO</u>
              </span>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default MapSchoolCard;
