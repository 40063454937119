import DetailPageButton from "./DetailPageButton/detail-page-button.component";
import VirtualTour from "../../../../../../assets/svg/REA103_Icons-01a_VirtualTour.svg";
import MapView from "../../../../../../assets/svg/map-details.svg";
import ImageView from "../../../../../../assets/svg/image-view.svg";
import { detailPageButtons } from "../../../../../../data/detailsPage";
import NavigateIcon from "../../../../../../assets/svg/navigate-outline.svg";
import styles from "./detail-page-buttons.module.scss";

const DetailPageButtons = ({
  showMap,
  virtualTourHandler,
  mapViewHandler,
  hasVirtualTour,
  navigateHandler,
  language,
}) => {
  return (
    <div className={styles.btns}>
      <DetailPageButton
        icon={VirtualTour}
        label={detailPageButtons[language].virtualTour}
        imgheight="42px"
        imgwidth="30.72px"
        onClick={virtualTourHandler}
        disable={!hasVirtualTour}
        isImage={false}
      />
      <DetailPageButton
        icon={showMap ? ImageView : MapView}
        label={
          showMap
            ? detailPageButtons[language].gallery
            : detailPageButtons[language].mapView
        }
        imgheight="37.69px"
        imgwidth="42.13px"
        onClick={mapViewHandler}
        isImage={false}
      />
      <DetailPageButton
        icon={NavigateIcon}
        label={detailPageButtons[language].navigate}
        imgheight="37.69px"
        imgwidth="42.13px"
        onClick={navigateHandler}
        isImage={false}
      />
    </div>
  );
};

export default DetailPageButtons;
