import { useState, useEffect, memo, useRef, useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  IonCol,
  IonContent,
  IonGrid,
  IonButton,
  IonIcon,
  IonRow,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonPage,
  IonDatetime,
  IonAlert,
  IonModal,
  IonLoading,
  IonRouterLink,
} from "@ionic/react";
import {
  cameraOutline,
  micOutline,
  arrowForwardCircleOutline,
  arrowBackCircleOutline,
  chevronForwardOutline,
  navigateOutline,
} from "ionicons/icons";
import editIcon from "../../../assets/svg/REA103_Icons-01a_Edit saved search.svg";
import styles from "./agent-tour-details.module.scss";
import TourElement from "../AgentTour/component/TourElement/tour-element.component";
import {
  fetchTourItems,
  getSingleTour,
  getSingleTourItem,
  removeTourListingItem,
  updateTourListingItem,
  uploadPhoto,
  uploadRecording,
} from "../../../amplify/graphql.utils";
import { getListing } from "../../../api/repliers";
import LoadingFullPage from "../../../components/Loading/loading-full-page.component";
import {
  buildAddress,
  copyToClipboard,
  handleAppNavigation,
  numberWithCommas,
} from "../../../utils/functions";
import Medias from "../../components/Medias/medias.component";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { updateToast } from "../../../redux/ui/ui.actions";
import withAuthentication from "../../../HOC/withAuthentication/with-authentication";
import Rating from "../../../components/Rating/rating.component";
import { MediaCapture } from "@ionic-native/media-capture";
import { File } from "@ionic-native/file";
import AppHeader from "../../components/Header/header.component";
import { API, graphqlOperation } from "aws-amplify";
import {
  onCreateNoteByTourItemId,
  onCreatePhotoByTourItemId,
  onCreateRecordingByTourItemId,
  onDeletePhotoByTourItemId,
  onDeleteRecordingByTourItemId,
  onUpdateNoteByTourItemId,
} from "../../../graphql/subscriptions";
import MediaNav from "../../components/Medias/component/StickyNav/media-nav.component";
import Refresher from "../../components/Refresher/refresher.component";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import { getPlatforms } from "@ionic/core";

const AppAgentTourDetails = ({
  match: {
    params: { tourId, tourItemId },
  },
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const pageRef = useRef();
  const [uploading, setUploading] = useState(false);
  const [tour, setTour] = useState();
  const [tourItems, setTourItems] = useState();
  const [tourItem, setTourItem] = useState();
  const [listing, setListing] = useState();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [entryInfoAlert, setEntryInfoAlert] = useState(false);
  const [status, setStatus] = useState("");
  const [time, setTime] = useState({ start: undefined, end: undefined });
  const [entryInfo, setEntryInfo] = useState("");
  const [note, setNote] = useState("");
  const [reviews, setReviews] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const handleNoteChange = ({ target: { value } }) => {
    setNote(value);
  };
  const [showMedia, setShowMedia] = useState(false);
  const [type, setType] = useState("");

  const refreshMedia = async () => {
    const item = await getSingleTourItem(tourItemId);
    setTourItem({ ...item });
  };

  const fetchData = useCallback(
    async (inBackground = false) => {
      if (!inBackground) setLoading(true);
      try {
        if (!inBackground) {
          const tour = await getSingleTour(tourId);
          setTour(tour);
        }

        const items = await fetchTourItems(tourId);
        if (!items) {
          history.replace("/tabs/agenttours", { direction: "root" });
          return;
        }
        setTourItems(items);
        const item = await getSingleTourItem(tourItemId);
        if (!item) {
          history.replace("/tabs/agenttours", { direction: "root" });
          return;
        }
        setTourItem(item);
        setListing(await getListing(item.mlsNumber));
      } catch (err) {
        history.replace("/tabs/agenttours", { direction: "root" });
      }
      if (!inBackground) setLoading(false);
    },

    [history, tourId, tourItemId]
  );

  useEffect(() => {
    const promises = [];
    const subscribeMedia = async () => {
      promises.push(
        // API.graphql(
        //   graphqlOperation(onUpdateTourItemById, {
        //     id: tourItemId,
        //   })
        // ).subscribe({
        //   next: (res) => {
        //     fetchData(true);
        //   },
        // }),
        API.graphql(
          graphqlOperation(onCreatePhotoByTourItemId, {
            tourItemId: tourItemId,
          })
        ).subscribe({
          next: (res) => {
            fetchData(true);
          },
        }),
        API.graphql(
          graphqlOperation(onCreateRecordingByTourItemId, {
            tourItemId: tourItemId,
          })
        ).subscribe({
          next: (res) => {
            fetchData(true);
          },
        }),
        API.graphql(
          graphqlOperation(onCreateNoteByTourItemId, { tourItemId: tourItemId })
        ).subscribe({
          next: (res) => {
            fetchData(true);
          },
        }),
        API.graphql(
          graphqlOperation(onDeletePhotoByTourItemId, {
            tourItemId: tourItemId,
          })
        ).subscribe({
          next: (res) => {
            fetchData(true);
          },
        }),
        API.graphql(
          graphqlOperation(onDeleteRecordingByTourItemId, {
            tourItemId: tourItemId,
          })
        ).subscribe({
          next: (res) => {
            fetchData(true);
          },
        }),
        API.graphql(
          graphqlOperation(onUpdateNoteByTourItemId, { tourItemId: tourItemId })
        ).subscribe({
          next: (res) => {
            fetchData(true);
          },
        })
      );
    };

    subscribeMedia();
    fetchData();

    return () => {
      if (promises) {
        promises.forEach((p) => p.unsubscribe());
      }
    };
  }, [fetchData, tourId, tourItemId]);

  useEffect(() => {
    if (tourItem) {
      const membersData = JSON.parse(tourItem.membersData).filter(
        (m) => m.role === "Client" || m.role === "Additional Guest"
      );
      const allReviews = [];
      membersData.forEach((m) => {
        const review = tourItem.reviews.items.find((r) => r.owner === m.id);
        allReviews.push({
          name: m.givenName,
          rate: review ? review.rate : undefined,
        });
      });
      setReviews([...allReviews]);
      setStatus(tourItem.status);
      setTime({ start: tourItem.startTime, end: tourItem.endTime });
      setEntryInfo(tourItem.entryInfo);
    }
  }, [tourItem]);

  useEffect(() => {
    setNote(tourItem && tourItem.showingNote ? tourItem.showingNote : "");
  }, [tourItem]);

  useEffect(() => {
    const update = async () => {
      await updateTourListingItem({
        id: tourItem.id,
        startTime: time.start
          ? moment(time.start).isValid()
            ? moment(time.start).format("HH:mm")
            : time.start
          : null,
        endTime: time.end
          ? moment(time.end).isValid()
            ? moment(time.end).format("HH:mm")
            : time.end
          : null,
        entryInfo,
        status,
      });
    };

    if (
      tourItem &&
      !(
        tourItem.startTime === time.start &&
        tourItem.endTime === time.end &&
        tourItem.showingNote === note &&
        tourItem.status === status &&
        tourItem.entryInfo === entryInfo
      )
    )
      update();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time, status, entryInfo]);

  const handleSaveNote = async () => {
    try {
      const updatedItem = await updateTourListingItem({
        id: tourItem.id,
        showingNote: note,
      });
      setTourItem(updatedItem);
      dispatch(
        updateToast({
          open: true,
          type: "success",
          message: "Note saved successfully.",
        })
      );
    } catch (err) {
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message: "Something went wrong!",
        })
      );
    }
  };

  const handleSaveNoteAndLeave = async () => {
    try {
      const updatedItem = await updateTourListingItem({
        id: tourItem.id,
        showingNote: note,
      });
      setTourItem(updatedItem);
      dispatch(
        updateToast({
          open: true,
          type: "success",
          message: "Note saved successfully.",
        })
      );
      setShowAlert(false);
      history.replace(`/agenttours/${tourId}`, { direction: "back" });
    } catch (err) {
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message: "Something went wrong!",
        })
      );
      setShowAlert(false);
    }
  };

  const handleRemove = async () => {
    try {
      await removeTourListingItem(tourItem.id);
      dispatch(
        updateToast({
          open: true,
          type: "success",
          message: "Showing removed successfully.",
        })
      );
      history.replace(`/agenttours/${tourId}?reload`, { direction: "root" });
    } catch (err) {
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message: "Something went wrong!",
        })
      );
    }
  };

  const nextRoute = () => {
    const nextId = tourItems.find((i) => i.order === tourItem.order + 1).id;
    return `/agenttours/${tourId}/${nextId}`;
  };

  const previousRoute = () => {
    const previousId = tourItems.find((i) => i.order === tourItem.order - 1).id;
    return `/agenttours/${tourId}/${previousId}`;
  };

  const handleNewPhoto = async () => {
    const takePicture = async () => {
      const image = await Camera.getPhoto({
        quality: 75,
        allowEditing: false,
        source: CameraSource.Camera,
        resultType: CameraResultType.Base64,
        height: 1280,
        width: 1280,
      });
      if (image) {
        try {
          setUploading(true);
          await uploadPhoto({ file: image, tourItemId: tourItem.id });
          setUploading(false);
          dispatch(
            updateToast({
              open: true,
              message: "Photo uploaded successfully.",
              type: "success",
            })
          );
          refreshMedia();
        } catch (err) {
          setUploading(false);
          dispatch(
            updateToast({
              open: true,
              message: "Something went wrong!",
              type: "error",
            })
          );
        }
      }
    };
    await Camera.checkPermissions().then(async (res) => {
      if (res.camera === "granted") {
        await takePicture();
      }
      if (res.camera === "denied") {
        return;
      }
      if (res.camera === "prompt") {
        await Camera.requestPermissions()
          .then(async (res) => {
            if (res.camera === "granted") {
              await takePicture();
            }
          })
          .catch((err) => {
            setUploading(false);
            return;
          });
      }
    });
  };

  const handleNewRecording = async () => {
    const recording = await MediaCapture.captureAudio({ limit: 1 });
    let name = recording[0].name;
    let path = recording[0].fullPath.substr(
      0,
      recording[0].fullPath.lastIndexOf("/")
    );
    let resolvedPath;
    if (getPlatforms().includes("android")) {
      resolvedPath = await File.resolveDirectoryUrl(path);
    } else {
      resolvedPath = await File.resolveDirectoryUrl("file://" + path);
    }

    const buffer = await File.readAsArrayBuffer(resolvedPath.nativeURL, name);
    let blob = new Blob([buffer], { type: "audio/wav" });

    setUploading(true);
    try {
      await uploadRecording({ file: blob, tourItemId: tourItem.id });

      dispatch(
        updateToast({
          open: true,
          message: "Recording uploaded successfully.",
          type: "success",
        })
      );
      refreshMedia(tourItem.id);
    } catch (err) {
      dispatch(
        updateToast({
          open: true,
          message: "Something went wrong!",
          type: "error",
        })
      );
    }
    setUploading(false);
  };

  const handleCopyToClipboard = async () => {
    const res = await copyToClipboard(
      `https://ettie.ai/listings/${listing.mlsNumber}`
    );
    res &&
      dispatch(
        updateToast({
          open: true,
          type: "success",
          message: "Property link has been copied to clipboard.",
        })
      );
  };

  const handleCloseDetailsPage = () => {
    if (
      tourItem.showingNote === note ||
      (!tourItem.showingNote && note === "")
    ) {
      history.replace(`/agenttours/${tourId}`, { direction: "back" });
    } else {
      setShowAlert(true);
    }
  };

  const handleRefresh = async (ref) => {
    await fetchData(true);
    ref.complete();
  };

  return (
    <IonPage ref={pageRef}>
      <AppHeader
        title="Details"
        hasRightButton
        hasBackButton={false}
        isTourDetails
        onClick={handleCloseDetailsPage}
      />
      <IonContent className={styles.agentTourDetails} forceOverscroll={false}>
        <Refresher onRefresh={handleRefresh} />
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          cssClass={styles.alert}
          header={"Save your note"}
          message={
            "Do you want to save your note changes before leaving this page?"
          }
          buttons={[
            {
              text: "Dismiss",
              handler: () => {
                history.replace(`/agenttours/${tourId}`, { direction: "back" });
              },
            },
            {
              text: "Save",
              handler: handleSaveNoteAndLeave,
            },
          ]}
        />
        <IonModal
          swipeToClose={true}
          isOpen={showMedia}
          onDidDismiss={() => setShowMedia(false)}
          presentingElement={pageRef.current}
        >
          <Medias
            disabled={!tour || !tour.status || tour.status === "archived"}
            tourItem={tourItem}
            tour={tour}
            listing={listing}
            type={type}
            setIsOpen={setShowMedia}
            refresh={refreshMedia}
          />
        </IonModal>

        <IonLoading isOpen={uploading} />
        {loading || !tourItem || !tour || !tourItems ? (
          <div className={styles.loading}>
            <LoadingFullPage />
          </div>
        ) : (
          <>
            <IonGrid className={styles.details}>
              <IonRow>
                <IonCol className={styles.address}>
                  <div>{buildAddress(listing.address)}</div>
                  <div className={styles.postalCode}>
                    {listing.address.zip}, {listing.address.city}
                  </div>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonButton
                    expand="block"
                    className={styles.directionBtn}
                    onClick={() =>
                      handleAppNavigation({
                        map: listing.map,
                        address: listing.address,
                        byAddress: true,
                      })
                    }
                  >
                    <IonIcon icon={navigateOutline} />
                    Directions
                  </IonButton>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol className={styles.showingDetails}>
                  <div className={styles.label}>Showing details</div>
                  <IonItem
                    className={styles.item}
                    detail
                    detailIcon={chevronForwardOutline}
                  >
                    <IonLabel>Status</IonLabel>
                    <IonSelect
                      value={status}
                      okText="Save"
                      cancelText="Cancel"
                      onIonChange={(e) => setStatus(e.detail.value)}
                      disabled={
                        !tour || !tour.status || tour.status === "archived"
                      }
                    >
                      <IonSelectOption value="requested">
                        Requested
                      </IonSelectOption>
                      <IonSelectOption value="confirmed">
                        Confirmed
                      </IonSelectOption>
                      <IonSelectOption value="completed">
                        Completed
                      </IonSelectOption>
                      <IonSelectOption value="skipped">Skipped</IonSelectOption>
                      <IonSelectOption value="cancelled">
                        Cancelled
                      </IonSelectOption>
                      <IonSelectOption value="rejected">
                        Rejected
                      </IonSelectOption>
                    </IonSelect>
                  </IonItem>
                  <IonItem
                    className={styles.item}
                    detail
                    detailIcon={chevronForwardOutline}
                  >
                    <IonLabel>Start time</IonLabel>
                    <IonDatetime
                      placeholder="Tour start time"
                      displayFormat="h:mm A"
                      minuteValues="0,15,30,45"
                      value={time.start}
                      onIonChange={(e) =>
                        setTime({ ...time, start: e.detail.value })
                      }
                      className={styles.picker}
                      disabled={
                        !tour || !tour.status || tour.status === "archived"
                      }
                    />
                  </IonItem>
                  <IonItem
                    className={styles.item}
                    detail
                    detailIcon={chevronForwardOutline}
                  >
                    <IonLabel>End time</IonLabel>
                    <IonDatetime
                      placeholder="Tour end time"
                      displayFormat="h:mm A"
                      minuteValues="0,15,30,45"
                      value={time.end}
                      onIonChange={(e) =>
                        setTime({ ...time, end: e.detail.value })
                      }
                      className={styles.picker}
                      disabled={
                        !tour || !tour.status || tour.status === "archived"
                      }
                    />
                  </IonItem>
                  <IonItem
                    className={`${styles.item} ${styles.entryInfo}`}
                    onClick={() => setEntryInfoAlert(true)}
                  >
                    <IonLabel className={styles.lable}>
                      Lockbox and entry info
                    </IonLabel>
                    <IonLabel className={styles.info}>{entryInfo}</IonLabel>
                    <IonIcon
                      icon={chevronForwardOutline}
                      className={styles.icon}
                      onClick={() => setEntryInfoAlert(true)}
                    />
                    <IonAlert
                      isOpen={entryInfoAlert}
                      onDidDismiss={() => setEntryInfoAlert(false)}
                      cssClass="my-custom-class"
                      header={"Lockbox and entry info"}
                      inputs={[
                        {
                          cssClass: styles.alertInput,
                          name: "entryInfo",
                          type: "textarea",
                          id: "entryInfo",
                          value: entryInfo,
                          placeholder: "Lockbox and entry info",
                        },
                      ]}
                      buttons={[
                        {
                          text: "Cancel",
                          role: "cancel",
                          cssClass: "secondary",
                        },
                        {
                          text: "Save",
                          handler: (e) => {
                            setEntryInfo(e.entryInfo);
                          },
                        },
                      ]}
                    />
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol className={styles.notes}>
                  <div className={styles.label}>Showing notes for client</div>
                  <textarea
                    className={styles.textarea}
                    name="agent_note"
                    placeholder="agent tour notes"
                    value={note}
                    onChange={handleNoteChange}
                  ></textarea>
                  <IonButton
                    shape="round"
                    expand="block"
                    className={styles.saveBtn}
                    disabled={
                      note === tourItem.showingNote ||
                      !tour ||
                      !tour.status ||
                      tour.status === "archived"
                    }
                    onClick={handleSaveNote}
                  >
                    Save note
                  </IonButton>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol className={styles.mediaElements}>
                  <div className={styles.label}>Media</div>
                  <div className={styles.elements}>
                    <TourElement
                      icon={cameraOutline}
                      title="All photos"
                      onClick={() => {
                        setShowMedia(true);
                        setType("photos");
                      }}
                    />
                  </div>
                  <div className={styles.elements}>
                    <TourElement
                      icon={editIcon}
                      title="All notes"
                      onClick={() => {
                        setShowMedia(true);
                        setType("notes");
                      }}
                    />
                  </div>
                  <div className={styles.elements}>
                    <TourElement
                      icon={micOutline}
                      title="All recordings"
                      onClick={() => {
                        setShowMedia(true);
                        setType("recordings");
                      }}
                    />
                  </div>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol className={styles.overview}>
                  <div className={styles.label}>Overview</div>
                  <div className={styles.item}>
                    <span>Price</span>
                    <span className={styles.bold}>
                      ${numberWithCommas(listing.listPrice)}
                    </span>
                  </div>
                  <div className={styles.item}>
                    <span>Property type</span>
                    <span className={styles.bold}>
                      {listing.details.propertyType}
                    </span>
                  </div>
                  <div className={styles.item}>
                    <span>Beds</span>
                    <span className={styles.bold}>
                      {listing.details.numBedrooms
                        ? listing.details.numBedrooms
                        : "-"}
                    </span>
                  </div>
                  <div className={styles.item}>
                    <span>Baths</span>
                    <span className={styles.bold}>
                      {listing.details.numBathrooms
                        ? listing.details.numBathrooms
                        : "-"}
                    </span>
                  </div>
                  <div className={styles.item}>
                    <span>Parking</span>
                    <span className={styles.bold}>
                      {listing.details.numParkingSpaces
                        ? listing.details.numParkingSpaces
                        : listing.details.numGarageSpaces
                        ? listing.details.numGarageSpaces
                        : "-"}
                    </span>
                  </div>
                  <div className={styles.item} onClick={handleCopyToClipboard}>
                    <span>MLS Number</span>
                    <span className={`${styles.bold} ${styles.underline}`}>
                      {listing.mlsNumber}
                    </span>
                  </div>
                  <div className={styles.item}>
                    <span>All details</span>
                    <span
                      className={`${styles.bold} ${styles.underline}`}
                      onClick={() =>
                        history.push(`/listings/${listing.mlsNumber}`, {
                          direction: "forward",
                          listing,
                          location: location.pathname,
                        })
                      }
                    >
                      View full listing
                      <IonIcon icon={arrowForwardCircleOutline} />
                    </span>
                  </div>
                  {reviews.length > 0 &&
                    reviews.map((r, i) => (
                      <div className={styles.item} key={i}>
                        <span>{`${r.name}'s Review`}</span>
                        <span>
                          {r.rate ? <Rating review={r} readOnly /> : "TBD"}
                        </span>
                      </div>
                    ))}
                </IonCol>
              </IonRow>
              <IonRow className={styles.navigation}>
                {tourItems.find((i) => i.order === tourItem.order - 1) && (
                  <IonRouterLink
                    routerLink={previousRoute()}
                    className={styles.link}
                    routerDirection="back"
                  >
                    <IonCol className={styles.previous}>
                      <IonIcon icon={arrowBackCircleOutline} />
                      <span>previous</span>
                    </IonCol>
                  </IonRouterLink>
                )}
                {tourItems.find((i) => i.order === tourItem.order + 1) && (
                  <IonRouterLink
                    routerLink={nextRoute()}
                    className={styles.link}
                    routerDirection="forward"
                  >
                    <IonCol className={styles.next}>
                      <span>next</span>
                      <IonIcon icon={arrowForwardCircleOutline} />
                    </IonCol>
                  </IonRouterLink>
                )}
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonButton
                    expand="block"
                    shape="round"
                    className={styles.removeBtn}
                    onClick={() => setAlert(true)}
                    disabled={
                      !tour || !tour.status || tour.status === "archived"
                    }
                  >
                    Remove from tour
                  </IonButton>
                </IonCol>
              </IonRow>
              <IonAlert
                isOpen={alert}
                onDidDismiss={() => setAlert(false)}
                cssClass={styles.alert}
                header={"Remove showing"}
                animated
                message="Are you sure you want to remove this showing and all of its details from the tour?"
                buttons={[
                  {
                    text: "Keep showing",
                    role: "cancel",
                    cssClass: styles.cancel,
                    handler: () => setAlert(false),
                  },
                  {
                    text: "Remove showing",
                    role: "Ok",
                    cssClass: styles.remove,
                    handler: handleRemove,
                  },
                ]}
              />
            </IonGrid>
            <MediaNav
              tourItem={tourItem}
              handlePhoto={handleNewPhoto}
              handleNote={() => {
                setShowMedia(true);
                setType("notes");
              }}
              handleRecording={handleNewRecording}
              disabled={!tour || !tour.status || tour.status === "archived"}
            />
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default memo(withAuthentication(AppAgentTourDetails));
