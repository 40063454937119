import { useState } from "react";
import Select from "../../../Form/Select/select.component";
import { beds as bedsOptions } from "../../data/filters.data";
import useComponentVisible from "../../../../hooks/useComponentVisible";
import RadioFilter from "../../../RadioFilter/radio-filter.component";

const BedsFilter = ({
  isHomepage = false,
  filters: { minBeds, maxBeds },
  updateMultipleFilters,
  disabled,
}) => {
  const [options, setOptions] = useState(bedsOptions);
  const { ref, isOpen, setIsOpen } = useComponentVisible(false);

  const onChangeHandler = ({ label }) => {
    const tempOptions = options.map((option) => {
      if (option.label !== label) {
        option.checked = false;
      } else {
        option.checked = true;
      }
      return option;
    });

    setOptions(tempOptions);
    if (label.includes("+")) {
      updateMultipleFilters({ minBeds: +label.split("+")[0], maxBeds: null });
    } else {
      if (label === "any") {
        updateMultipleFilters({ minBeds: null, maxBeds: null });
      } else {
        updateMultipleFilters({ minBeds: +label, maxBeds: +label });
      }
    }

    setIsOpen(false);
  };

  const renderTitle = () => {
    if (isHomepage) {
      if ((minBeds && minBeds !== 0) || (maxBeds && maxBeds !== "max")) {
        return maxBeds && maxBeds !== "max"
          ? maxBeds
          : minBeds
          ? `${minBeds}+`
          : "any";
      } else return "Beds";
    } else
      return maxBeds && maxBeds !== "max"
        ? maxBeds
        : minBeds
        ? `${minBeds}+`
        : "any";
  };
  return (
    <div ref={ref}>
      <Select
        label={!isHomepage && "beds"}
        title={renderTitle()}
        open={isOpen}
        onClick={() => setIsOpen((open) => !open)}
        disabled={disabled}
        buttonStyle={
          isHomepage
            ? {
                border: "none",
                fontSize: "1.5rem",
                color: "#7158ad",
                borderColor: "#7158ad",
              }
            : null
        }
      />

      <RadioFilter
        open={isOpen}
        value={maxBeds ? maxBeds.toString() : minBeds ? `${minBeds}+` : "any"}
        options={options}
        onClick={onChangeHandler}
      />
    </div>
  );
};

export default BedsFilter;
