import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import {
  IonItem,
  IonDatetime,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonLabel,
} from "@ionic/react";
import Input from "../../../../../components/Form/Input/input.component";
import IsVisibleTag from "../../../../../components/IsVisibleTag/is-visible-tag.component";
import styles from "./tour-details.module.scss";
import { chevronDownOutline, closeOutline } from "ionicons/icons";
import { updateTourStart } from "../../../../../redux/tour/tour.actions";

const TourDetails = ({ setType, tour }) => {
  const dispatch = useDispatch();
  const minDate = moment().format("YYYY-MM-DD");
  const [note, setNote] = useState("");
  const [date, setDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [meetup, setMeetup] = useState({
    address: "",
    time: undefined,
    description: "",
  });

  const handleDateChange = (date) => {
    if (date) setDate(date);
  };

  const handleSubmit = () => {
    dispatch(
      updateTourStart({
        id: tour.id,
        date: date ? moment(date).toISOString().substring(0, 10) : null,
        generalNote: note,
        meetupTime: meetup.time ? moment(meetup.time).format("HH:mm") : null,
        meetupLocation: meetup.address,
        meetupDescription: meetup.description,
        startTime: startTime ? moment(startTime).format("HH:mm") : null,
        endTime: endTime ? moment(endTime).format("HH:mm") : null,
      })
    );
  };

  useEffect(() => {
    if (tour) {
      setMeetup({
        address: tour.meetupLocation ? tour.meetupLocation : "",
        //time: tour.meetupTime ? tour.meetupTime : undefined,
        description: tour.meetupDescription ? tour.meetupDescription : "",
      });
      setNote(tour.generalNote ? tour.generalNote : "");
      //setStartTime(tour.startTime ? tour.startTime : undefined);
      //setEndTime(tour.endTime ? tour.endTime : undefined);
      setDate(tour.date ? tour.date : moment().format("L"));
      const div = document.getElementById("new_tour");
      div?.scrollIntoView();
    }
  }, [tour]);

  const handleDelete = (type) => {
    switch (type) {
      case "date":
        setDate("");
        break;
      case "startTime":
        setStartTime("");
        break;
      case "endTime":
        setEndTime("");
        break;
      case "address":
        setMeetup({ ...meetup, address: "" });
        break;
      case "time":
        setMeetup({ ...meetup, time: undefined });
        break;
      case "description":
        setMeetup({ ...meetup, description: "" });
        break;
      default:
        return;
    }
  };

  return (
    <div className={styles.tourDetails}>
      <div className={styles.subtitle}>
        Enter your tour information – you can always edit it later. Note that
        some content will only be visible by you, the agent. Once you create a
        tour, you can add and edit showings.
      </div>

      <div className={styles.label}>
        <div className={styles.title}>Tour details</div>
        <IsVisibleTag isVisible={true} />
      </div>

      <IonGrid className={styles.inputs}>
        <IonRow className={styles.row}>
          <IonCol size="11">
            <IonItem
              className={styles.item}
              detail
              detailIcon={chevronDownOutline}
              lines="none"
            >
              <IonLabel>Date</IonLabel>
              <IonDatetime
                displayFormat="MMM DD YYYY"
                min={minDate}
                placeholder="Date of tour"
                value={date}
                onIonChange={(e) => handleDateChange(e.detail.value)}
                className={styles.picker}
              ></IonDatetime>
            </IonItem>
          </IonCol>
          <IonCol size="1">
            <IonIcon icon={closeOutline} onClick={() => handleDelete("date")} />
          </IonCol>
        </IonRow>
        <IonRow className={styles.row}>
          <IonCol>
            <IonItem
              className={styles.item}
              detail
              detailIcon={chevronDownOutline}
              lines="none"
            >
              <IonLabel>Start Time</IonLabel>
              <IonDatetime
                placeholder="Tour start time"
                displayFormat="h:mm A"
                minuteValues="0,15,30,45"
                value={startTime}
                onIonChange={(e) => setStartTime(e.detail.value)}
                className={styles.picker}
              ></IonDatetime>
            </IonItem>
          </IonCol>
          <IonCol size="1">
            <IonIcon
              icon={closeOutline}
              onClick={() => handleDelete("startTime")}
            />
          </IonCol>
        </IonRow>
        <IonRow className={styles.row}>
          <IonCol>
            <IonItem
              className={styles.item}
              detail
              detailIcon={chevronDownOutline}
              lines="none"
            >
              <IonLabel>End Time</IonLabel>
              <IonDatetime
                placeholder="Tour end time"
                displayFormat="h:mm A"
                minuteValues="0,15,30,45"
                value={endTime}
                onIonChange={(e) => setEndTime(e.detail.value)}
                className={styles.picker}
              ></IonDatetime>
            </IonItem>
          </IonCol>
          <IonCol size="1">
            <IonIcon
              icon={closeOutline}
              onClick={() => handleDelete("endTime")}
            />
          </IonCol>
        </IonRow>
        <IonRow className={styles.row}>
          <IonCol>
            <Input
              name="address"
              label="Meetup address"
              showLabel={false}
              placeholder="Meetup address"
              style={{ width: "100%", boxShadow: "none" }}
              value={meetup.address}
              onChange={(value, name) =>
                setMeetup({ ...meetup, address: value })
              }
            />
          </IonCol>
          <IonCol size="1">
            <IonIcon
              icon={closeOutline}
              onClick={() => handleDelete("address")}
            />
          </IonCol>
        </IonRow>
        <IonRow className={styles.row}>
          <IonCol>
            <IonItem
              className={styles.item}
              detail
              detailIcon={chevronDownOutline}
              lines="none"
            >
              <IonLabel>Meetup Time</IonLabel>
              <IonDatetime
                placeholder="Meetup time"
                displayFormat="h:mm A"
                minuteValues="0,15,30,45"
                value={meetup.time}
                onIonChange={(e) =>
                  setMeetup({ ...meetup, time: e.detail.value })
                }
                className={styles.picker}
              ></IonDatetime>
            </IonItem>
          </IonCol>
          <IonCol size="1">
            <IonIcon icon={closeOutline} onClick={() => handleDelete("time")} />
          </IonCol>
        </IonRow>
        <IonRow className={styles.row}>
          <IonCol>
            <Input
              showLabel={false}
              name="description"
              label="Meetup description"
              placeholder="Meetup description"
              value={meetup.description}
              style={{ width: "100%" }}
              onChange={(value, name) =>
                setMeetup({ ...meetup, description: value })
              }
            />
          </IonCol>
          <IonCol size="1">
            <IonIcon
              icon={closeOutline}
              onClick={() => handleDelete("description")}
            />
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol>
            <textarea
              className={styles.textarea}
              name="note"
              placeholder="General notes for client"
              value={note}
              onChange={(event) => {
                setNote(event.target.value);
              }}
            ></textarea>
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonButton
        shape="round"
        expand="block"
        className={styles.button}
        onClick={handleSubmit}
      >
        Save and continue
      </IonButton>
    </div>
  );
};

export default TourDetails;
