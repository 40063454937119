import { useSelector, useDispatch } from "react-redux";
import Button from "../../Form/Button/button.component";
import LocationSearch from "../../LocationSearch/location-search.component";
import withFilters from "../../../HOC/withFilters/with-filters";
import BedsFilter from "../components/BedsFilter/beds-filter.component";
import PriceFilter from "../components/PriceFilter/price-filter.component";
import PropertyTypeFilter from "../components/PropertyTypeFilter/property-type-filter.component";
import PurposeFilter from "../components/PurposeFilter/purpose-filter.component";
import StatusFilter from "../components/StatusSegmentFilter/status-segment-filter.component";
import DateFilter from "../components/DateFilter/date-filter.component";
import styles from "./tab-filters.module.scss";
import { ReactComponent as FiltersIcon } from "../../../assets/svg/filters.svg";
import { selectMapFavourites } from "../../../redux/map/map.selectors";
import { openModal, updateMenuTab } from "../../../redux/ui/ui.actions";
import {
  selectDrawMode,
  selectUIMenuTab,
} from "../../../redux/ui/ui.selectors";
import { selectSchools } from "../../../redux/schools/schools.selectors";

const PurposeWithFilters = withFilters(PurposeFilter);
const PriceWithFilters = withFilters(PriceFilter);
const StatusWithFilters = withFilters(StatusFilter);
const DateWithFilters = withFilters(DateFilter);
const BedsWithFilters = withFilters(BedsFilter);
const PropertyTypesWithFilters = withFilters(PropertyTypeFilter);

const TabFilters = ({ filters: { status } }) => {
  const dispatch = useDispatch();
  const drawMode = useSelector(selectDrawMode);
  const schools = useSelector(selectSchools);
  const activeTab = useSelector(selectUIMenuTab);
  const favouritesOnly = useSelector(selectMapFavourites);

  const handleMoreClick = () => {
    if (activeTab === "/filters") {
      dispatch(updateMenuTab("/listing"));
    } else {
      dispatch(updateMenuTab("/filters"));
    }
  };

  const saveSearchHandler = () => {
    dispatch(
      openModal({
        current: "saveSearch",
        data: {
          drawMode,
          schoolMode: {
            active: schools.filterListings.active,
            schools: schools.filterListings.schools,
          },
        },
        // data: active && ref ? true : false,
        isProtected: true,
      })
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.filters}>
        <div className={`${styles.location} ${styles.item}`}>
          <LocationSearch disabled={favouritesOnly} />
        </div>
        <div className={`${styles.purpose} ${styles.item}`}>
          <PurposeWithFilters disabled={favouritesOnly} />
        </div>
        <div className={`${styles.price} ${styles.item}`}>
          <PriceWithFilters disabled={favouritesOnly} />
        </div>
        <div className={`${styles.status} ${styles.item}`}>
          <StatusWithFilters disabled={favouritesOnly} />
        </div>
        <div className={`${styles.date} ${styles.item}`}>
          <DateWithFilters disabled={false} />
        </div>
        <div className={`${styles.beds} ${styles.item}`}>
          <BedsWithFilters disabled={favouritesOnly} />
        </div>
        <div className={`${styles.types} ${styles.item}`}>
          <PropertyTypesWithFilters disabled={favouritesOnly} />
        </div>
        <div className={`${styles.more} ${styles.item}`}>
          <Button
            title="more"
            type="filter"
            onClick={handleMoreClick}
            disabled={favouritesOnly}
          >
            <FiltersIcon className={styles.icon} />
          </Button>
        </div>
        <div className={`${styles.saveSearch} ${styles.item}`}>
          <Button
            type="green"
            title="save search"
            shape="block"
            border={true}
            disabled={favouritesOnly || status === "U"}
            onClick={saveSearchHandler}
            style={{ width: "100%", lineHeight: "1" }}
          />
        </div>
      </div>
    </div>
  );
};

export default withFilters(TabFilters);
