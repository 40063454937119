export const MapActionTypes = {
  FETCH_LISTINGS_START: "FETCH_LISTINGS_START",
  FETCH_LISTINGS_SUCCESS: "FETCH_LISTINGS_SUCCESS",
  FETCH_LISTINGS_FAILURE: "FETCH_LISTINGS_FAILURE",
  UPDATE_ACTIVE_LISTING: "UPDATE_ACTIVE_LISTING",
  UPDATE_SELECTED_LOCATION: "UPDATE_SELECTED_LOCATION",
  UPDATE_MAP_REF: "UPDATE_MAP_REF",
  UPDATE_LOCATIONS: "UPDATE_LOCATIONS",
  UPDATE_BOUNDARIES: "UPDATE_BOUNDARIES",
  TOGGLE_FAVOURITES: "TOGGLE_FAVOURITES",
};
