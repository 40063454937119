import { useDispatch, useSelector } from "react-redux";
import { IonIcon } from "@ionic/react";
import { mapOutline, addCircleOutline } from "ionicons/icons";
import SortBy from "../../../components/SortBy/sort-by.component";
import { toggleFavourites } from "../../../redux/map/map.actions";
import { selectDrawMode } from "../../../redux/ui/ui.selectors";
import { updateDrawMode } from "../../../redux/ui/ui.actions";
import { useHistory } from "react-router-dom";
import styles from "./mobile-edit-bar.module.scss";

const MobileEditBar = ({ sortBy, setSortBy, mode, disabled, hideAdd }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const drawMode = useSelector(selectDrawMode);

  const handleClick = () => {
    if (disabled) return;
    if (mode === "favourites") {
      if (drawMode.active)
        dispatch(updateDrawMode({ active: false, isSchool: false, ref: null }));
      dispatch(toggleFavourites(true));
    }

    history.push("/tabs/listings");
  };

  const handleAdd = () => history.push("/tabs/listings");
  return (
    <div
      className={styles.favouriteheader}
      style={{
        backgroundColor: ["favourites", "tours"].includes(mode)
          ? "#f4f4f7"
          : mode === "savedSearches"
          ? "white"
          : "",
      }}
    >
      {mode !== "tours" && !hideAdd ? (
        <div className={styles.add}>
          <div className={styles.addIcon}>
            <IonIcon
              className={styles.icon}
              icon={
                mode === "favourites"
                  ? mapOutline
                  : mode === "savedSearches"
                  ? addCircleOutline
                  : null
              }
              onClick={handleClick}
            />
          </div>

          <div
            onClick={
              mode === "favourites"
                ? handleClick
                : mode === "savedSearches"
                ? handleAdd
                : null
            }
            className={`${styles.label} ${disabled && styles.disabled}`}
          >
            {mode === "favourites"
              ? "View on map"
              : mode === "savedSearches"
              ? "Add "
              : null}
          </div>
        </div>
      ) : (
        <div className={styles.add} />
      )}

      <div className={styles.sort}>
        <SortBy
          sortBy={sortBy}
          setSortBy={setSortBy}
          mode={mode}
          isApp={true}
        />
      </div>
    </div>
  );
};

export default MobileEditBar;
