export const SchoolsActionTypes = {
  FETCH_SCHOOLS_START: "FETCH_SCHOOLS_START",
  FETCH_SCHOOLS_SUCCESS: "FETCH_SCHOOLS_SUCCESS",
  FETCH_SCHOOLS_FAILURE: "FETCH_SCHOOLS_FAILURE",
  UPDATE_SCHOOLS_FILTERS: "UPDATE_SCHOOLS_FILTERS",
  UPDATE_SELECTED_SCHOOL: "UPDATE_SELECTED_SCHOOL",
  TOGGLE_SCHOOLS: "TOGGLE_SCHOOLS",
  TOGGLE_FILTER_LISTINGS: "TOGGLE_FILTER_LISTINGS",
  ADD_SCHOOL_BOUNDARY: "ADD_SCHOOL_BOUNDARY",
  REMOVE_SCHOOL_BOUNDARY: "REMOVE_SCHOOL_BOUNDARY",
};
