import { useState, useEffect, memo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppHeader from "../../components/Header/header.component";
import TourOptionsSegment from "../../components/TourOptionsSegment/tour-options-segment.component";
import styles from "./agent-tours.module.scss";
import TourCard from "./component/TourCard/tour-card.component";
import { IonContent, IonLoading, IonModal, IonPage } from "@ionic/react";
import NewTour from "../../components/NewTour/new-tour.component";
import { API, graphqlOperation } from "aws-amplify";
import { onUpdateTour } from "../../../graphql/subscriptions";
import {
  clearCurrentTour,
  fetchToursStart,
} from "../../../redux/tour/tour.actions";
import MobileEditBar from "../../components/MobileEditBar/mobile-edit-bar.component";
import { isBeforeOrAfter } from "../../../utils/functions";
import Refresher from "../../components/Refresher/refresher.component";
import { fetchTours } from "../../../amplify/graphql.utils";
import {
  selectCurrentUser,
  selectIsAgent,
} from "../../../redux/user/user.selectors";
import JoinContent from "../../components/CustomModals/Join/join-content.component";

const AppAgentTours = () => {
  const dispatch = useDispatch();

  const pageRef = useRef();
  const subscriptionRef = useRef([]);
  const user = useSelector(selectCurrentUser);
  const isAgent = useSelector(selectIsAgent);
  const [type, setType] = useState("agentDetails");
  const [tab, setTab] = useState("upcoming");
  const [sortBy, setSortBy] = useState({
    value: "date",
    direction: "desc",
  });
  const [tours, setTours] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [sortedTours, setSortedTours] = useState();
  const [loading, setLoading] = useState(false);

  const getTours = async (inBackground) => {
    !inBackground && setLoading(true);
    try {
      const tours = await fetchTours();
      setTours(tours);
    } catch (err) {}
    !inBackground && setLoading(false);
  };

  useEffect(() => {
    const subscriptions = subscriptionRef.current;
    const subscribe = async () => {
      subscriptionRef.current.forEach((s) => s.unsubscribe());
      const agentSubscription = await API.graphql(
        graphqlOperation(onUpdateTour, {
          agentId: user.username,
        })
      ).subscribe({
        next: (subonUpdateTour) => {
          getTours();
        },
      });
      const creatorSubscription = await API.graphql(
        graphqlOperation(onUpdateTour, {
          creator: user.username,
        })
      ).subscribe({
        next: (subonUpdateTour) => {
          getTours();
        },
      });
      const showingSubscription = await API.graphql(
        graphqlOperation(onUpdateTour, {
          showingAgentId: user.username,
        })
      ).subscribe({
        next: (subonUpdateTour) => {
          getTours();
        },
      });
      subscriptionRef.current.push(
        agentSubscription,
        creatorSubscription,
        showingSubscription
      );
    };
    if (user) {
      getTours();
      subscribe();
    }

    return () => {
      subscriptions.forEach((s) => s.unsubscribe());
    };
  }, [user]);

  useEffect(() => {
    dispatch(clearCurrentTour());
    dispatch(fetchToursStart());
  }, [dispatch]);

  useEffect(() => {
    let sorted;
    if (tours) {
      switch (sortBy.value) {
        case "date":
          sorted = tours.sort((a, b) => new Date(b.date) - new Date(a.date));
          setSortedTours(
            sortBy.direction === "desc" ? [...sorted] : [...sorted.reverse()]
          );
          break;
        case "status":
          sorted = tours.sort((a, b) => b.status - a.status);
          setSortedTours(
            sortBy.direction === "desc" ? [...sorted] : [...sorted.reverse()]
          );
          break;
        case "stops":
          sorted = tours.sort(
            (a, b) => b.tourItems.items.length - a.tourItems.items.length
          );
          setSortedTours(
            sortBy.direction === "desc" ? [...sorted] : [...sorted.reverse()]
          );
          break;
        case "title":
          sorted = tours.sort((a, b) => {
            if (a.title < b.title) {
              return -1;
            }
            if (a.title > b.title) {
              return 1;
            }
            return 0;
          });
          setSortedTours(
            sortBy.direction === "desc" ? [...sorted] : [...sorted.reverse()]
          );
          break;
        case "client":
          sorted = tours.sort((a, b) => {
            if (!a.clients.items[0] && !b.clients.items[0]) return 0;
            if (!a.clients.items[0]) return 1;
            if (!b.clients.items[0]) return -1;
            if (
              a.clients.items[0].client.name < b.clients.items[0].client.name
            ) {
              return -1;
            }
            if (
              a.clients.items[0].client.name > b.clients.items[0].client.name
            ) {
              return 1;
            }
            return 0;
          });
          setSortedTours(
            sortBy.direction === "desc" ? [...sorted] : [...sorted.reverse()]
          );
          break;
        case "agent":
          sorted = tours.sort((a, b) => {
            if (!a.showingAgent && !b.showingAgent) return 0;
            if (!a.showingAgent) return 1;
            if (!b.showingAgent) return -1;
            if (a.showingAgent.givenName < b.showingAgent.givenName) {
              return -1;
            }
            if (a.showingAgent.givenName > b.showingAgent.givenName) {
              return 1;
            }
            return 0;
          });
          setSortedTours(
            sortBy.direction === "desc" ? sorted : sorted.reverse()
          );
          break;
        default:
          setSortedTours(
            tours.sort((a, b) => new Date(b.date) - new Date(a.date))
          );
      }
    }
  }, [sortBy, tours]);

  const reload = () => dispatch(fetchToursStart());

  const getToursByType = () => {
    if (loading) return;
    if (sortedTours && sortedTours.length === 0) {
      return (
        <div className={styles.card}>
          <h3>You have no active tours right now.</h3>
          <p>Get a tour started, and it will appear here.</p>
        </div>
      );
    } else if (sortedTours && sortedTours.length > 0) {
      if (tab === "upcoming") {
        const results = sortedTours.filter(
          (t) =>
            !["archived"].includes(t.status) &&
            (!t.date || isBeforeOrAfter(t.date) >= 0)
        );
        return results.length > 0 ? (
          results.map((item, i) => <TourCard key={i} item={item} />)
        ) : (
          <div className={styles.card}>
            <p>
              You have no active tours right now. When you get a tour started,
              it will appear here.
            </p>
          </div>
        );
      } else if (tab === "past") {
        const results = sortedTours.filter((t) => isBeforeOrAfter(t.date) < 0);
        return results.length > 0 ? (
          results.map((item, i) => <TourCard key={i} item={item} />)
        ) : (
          <div className={styles.card}>
            <p>
              You have no past tours right now. Only past tours will appear
              here.
            </p>
          </div>
        );
      } else if (tab === "archived") {
        const results = sortedTours.filter((t) =>
          ["archived"].includes(t.status)
        );
        return results.length > 0 ? (
          <>
            <div className={styles.archiveSubtitle}>
              Archived tours are view-only. They can no longer be edited by
              client or agent.
            </div>
            {results.map((item, i) => (
              <TourCard key={i} item={item} />
            ))}
          </>
        ) : (
          <div className={styles.card}>
            <p>
              You have no archived tours right now. Once you have archived a
              tour, it will appear here.
            </p>
          </div>
        );
      }
    }
  };

  const handleRefresh = async (ref) => {
    await getTours(true);
    ref.complete();
  };

  return (
    <IonPage ref={pageRef} className={styles.page}>
      <AppHeader
        title="Agent tours"
        isTourPage={true}
        onClick={() => setIsOpen(true)}
      />
      <IonLoading isOpen={loading} />
      <IonContent className={user && isAgent && styles.content}>
        {user && isAgent ? (
          <>
            <Refresher onRefresh={handleRefresh} background="primary" />
            <IonModal isOpen={isOpen} presentingElement={pageRef.current}>
              <NewTour
                type={type}
                setType={setType}
                setIsOpen={setIsOpen}
                reload={reload}
              />
            </IonModal>
            <div className={styles.appAgentTours}>
              <div className={styles.body}>
                <TourOptionsSegment tab={tab} setTab={setTab} />
                <MobileEditBar
                  sortBy={sortBy}
                  setSortBy={setSortBy}
                  mode="tours"
                  disabled={!sortedTours || !sortedTours.length}
                />
                <div className={styles.cards}>{getToursByType()}</div>
              </div>
            </div>
          </>
        ) : (
          <JoinContent />
        )}
      </IonContent>
    </IonPage>
  );
};

export default memo(AppAgentTours);
