export const TourStatus = [
  { title: "Draft" },
  { title: "Pending" },
  { title: "Ready" },
  { title: "Completed" },
  { title: "Cancel" },
];

export const storeUrls = {
  apple: "https://apps.apple.com/ca/app/ettie/id1578020676",
  android:
    "https://play.google.com/store/apps/details?id=com.ettie.app&hl=en_CA&gl=US",
};

export const storeIds = {
  apple: "id1578020676",
  android: "com.ettie.app",
};
