import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import {
  IonHeader,
  IonToolbar,
  IonIcon,
  IonButtons,
  IonButton,
  IonContent,
  IonTitle,
  IonPage,
} from "@ionic/react";
import { chevronBackOutline } from "ionicons/icons";
import styles from "./new-tour.module.scss";
import AgentDetails from "./component/AgentDetails/agent-details.component";
import TourDetails from "./component/TourDetails/tour-details.component";
import TourAttendees from "./component/TourAttendees/tour-attendees.component";
import { selectCurrentTour } from "../../../redux/tour/tour.selectors";

const NewTour = ({ type, setType, setIsOpen, reload }) => {
  const pageRef = useRef();
  const tour = useSelector(selectCurrentTour);
  const clearHandler = () => {
    setType("agentDetails");
    reload();
    setIsOpen(false);
  };

  const handleBack = () => {
    switch (type) {
      case "agentDetails":
        setIsOpen(false);
        reload();
        break;
      case "tourDetails":
        setType("agentDetails");
        break;
      case "tourAttendees":
        setType("tourDetails");
        break;
      default:
        setIsOpen(false);
        reload();
    }
  };

  useEffect(() => {
    if (tour) {
      switch (type) {
        case "agentDetails":
          setType("tourDetails");
          break;
        case "tourDetails":
          setType("tourAttendees");
          break;
        default:
          return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tour]);
  return (
    <>
      <IonHeader className={styles.appHeader}>
        <IonToolbar className={styles.toolbar}>
          <IonButtons slot="start">
            <IonButton
              className={styles.backButton}
              slot="start"
              text="Back"
              onClick={handleBack}
            >
              <IonIcon icon={chevronBackOutline} />
              Back
            </IonButton>
          </IonButtons>
          <IonButtons slot="end" onClick={clearHandler}>
            <IonButton className={styles.clear}>
              <span>Cancel</span>
            </IonButton>
          </IonButtons>
          <IonTitle className={styles.title}>New tour</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent scrollY={true} style={{ overflow: "scroll" }}>
        {type === "agentDetails" && (
          <AgentDetails
            pageRef={pageRef.current}
            setType={setType}
            tour={tour}
          />
        )}
        {type === "tourDetails" && (
          <TourDetails
            pageRef={pageRef.current}
            setType={setType}
            tour={tour}
          />
        )}
        {type === "tourAttendees" && (
          <TourAttendees
            pageRef={pageRef.current}
            setIsOpen={setIsOpen}
            tour={tour}
            onComplete={() => setType("agentDetails")}
          />
        )}
      </IonContent>
    </>
  );
};

export default NewTour;
