import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { Link } from "react-router-dom";
import CardContainer from "../../../../Tours/component/CardContainer/card-container.component";
import styles from "./faq.module.scss";

const FAQ = () => {
  return (
    <CardContainer>
      <IonGrid className={styles.questions}>
        <IonRow>
          <IonCol className={styles.title}>FAQ</IonCol>
        </IonRow>

        <IonRow className={styles.row}>
          <IonCol>
            <div className={styles.question}>
              Is Ettie a brokerage or an app?
            </div>
            <div className={styles.answer}>
              Both! Ettie is a brokerage that has developed their own web search
              platform and an app available in the <a href="">App Store</a> and{" "}
              <a
                href="https://play.google.com/store/apps/details?id=com.ettie.app"
                target="_blank"
              >
                Google Play store
              </a>
              .
            </div>
          </IonCol>
        </IonRow>

        <IonRow className={styles.row}>
          <IonCol>
            <div className={styles.question}>
              Why do I need to register to see sold prices?
            </div>
            <div className={styles.answer}>
              To legally view sold property data in some geographical areas, you
              must agree to certain terms of use. Your user ID is the email with
              which you agreed to these terms.
            </div>
          </IonCol>
        </IonRow>

        <IonRow className={styles.row}>
          <IonCol>
            <div className={styles.question}>
              What is the EQAO school rating?
            </div>
            <div className={styles.answer}>
              What is the EQAO school rating? Many users still expect to see
              Fraser Institute school ratings. But due to recent copyright
              changes by the Fraser Institute, Ettie now uses the EQAO rating
              system, which ranks out of 4. Learn more at{" "}
              <a href="https://www.eqao.com/" target="_blank">
                eqao.com
              </a>
              .
            </div>
          </IonCol>
        </IonRow>

        <IonRow className={`${styles.row} ${styles.lastRow}`}>
          <IonCol>
            <div className={styles.question}>
              How much do your services cost?
            </div>
            <div className={styles.answer}>
              All our services are tailored to you individually. To find out
              what will work for you, just submit a request and an Ettie agent
              will contact you with all the details.
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </CardContainer>
  );
};

export default FAQ;
