import { useSelector, useDispatch } from "react-redux";
import {
  selectExtraFilters,
  selectFiltersValue,
} from "../../redux/filters/filters.selectors";
import {
  updateFilters,
  updateMultipleFilters,
  updateExtraFilters,
  resetFilters,
} from "../../redux/filters/filters.actions";
import { defaultFilters } from "../../components/Filters/data/filters.data";
import _ from "lodash";
import { selectMapFavourites } from "../../redux/map/map.selectors";
import { toggleFavourites } from "../../redux/map/map.actions";

const withFilters = (Component) => (props) => {
  const dispatch = useDispatch();
  const filters = useSelector(selectFiltersValue);
  const extraFilters = useSelector(selectExtraFilters);
  const favouritesOnly = useSelector(selectMapFavourites);
  // console.log(filters);
  const checkForInvalidRange = (filter, isExtra) => {
    let isInvalid = false;
    if (!isExtra) {
      const type = Object.keys(filter)[0];
      const value = filter[type];
      switch (type) {
        case "maxSqft":
          if (
            (value === "max" || value === 0) &&
            (filters.minSqft === 0 || !filters.minSqft)
          ) {
            dispatch(updateMultipleFilters({ minSqft: null, maxSqft: null }));
            isInvalid = true;
          }
          break;
        case "minSqft":
          if ((value === 0 || value === null) && filters.maxSqft === "max") {
            dispatch(updateMultipleFilters({ minSqft: null, maxSqft: null }));
            isInvalid = true;
          }
          break;
        case "maxBeds":
          if (
            (value === "any" || value === 0) &&
            (filters.minBeds === 0 || !filters.minBeds)
          ) {
            dispatch(updateMultipleFilters({ minBeds: null, maxBeds: null }));
            isInvalid = true;
          }
          break;
        case "minBeds":
          if ((value === 0 || value === null) && filters.maxBeds === "any") {
            dispatch(updateMultipleFilters({ minBeds: null, maxBeds: null }));
            isInvalid = true;
          }
          break;
        case "maxBaths":
          if (
            (value === "any" || value === 0) &&
            (filters.minBaths === 0 || !filters.minBaths)
          ) {
            dispatch(updateMultipleFilters({ minBaths: null, maxBaths: null }));
            isInvalid = true;
          }
          break;
        case "minBaths":
          if ((value === 0 || value === null) && filters.maxBaths === "any") {
            dispatch(updateMultipleFilters({ minBaths: null, maxBaths: null }));
            isInvalid = true;
          }
          break;
        case "minParkingSpaces":
          if (value === "any") {
            dispatch(updateFilters({ minParkingSpaces: null }));
            isInvalid = true;
          }
          break;
        default:
          return;
      }
    } else {
      const type = Object.keys(filter)[0];

      switch (type) {
        case "lotWidth":
          if (
            (filter[type].min === 0 || !filter[type].min) &&
            (filter[type].max === "max" || !filter[type].max)
          ) {
            dispatch(
              updateExtraFilters({ lotWidth: { min: null, max: null } })
            );
            isInvalid = true;
          }

          break;
        case "lotDepth":
          if (
            (filter[type].min === 0 || !filter[type].min) &&
            (filter[type].max === "max" || !filter[type].max)
          ) {
            dispatch(
              updateExtraFilters({ lotDepth: { min: null, max: null } })
            );
            isInvalid = true;
          }

          break;
        default:
          return;
      }
    }

    return isInvalid;
  };

  const updateSingle = (filter) => {
    const isInvalid = checkForInvalidRange(filter);
    if (!isInvalid) dispatch(updateFilters(filter));
    if (favouritesOnly && filter.map) return;
    if (favouritesOnly) dispatch(toggleFavourites(false));
  };

  const updateMultiple = (filters) => {
    if (favouritesOnly) dispatch(toggleFavourites(false));
    dispatch(updateMultipleFilters(filters));
  };

  const updateExtra = (filter) => {
    const isInvalid = checkForInvalidRange(filter, true);
    if (!isInvalid) dispatch(updateExtraFilters(filter));
  };

  const reset = () => dispatch(resetFilters());

  const activeFilters = () => {
    const filtersDiff = _.reduce(
      filters,
      function (result, value, key) {
        return _.isEqual(value, defaultFilters.value[key])
          ? result
          : result.concat(key);
      },
      []
    );

    let differences = [...filtersDiff].filter((val) => val !== "map");

    const {
      minPrice,
      maxPrice,
      minBeds,
      maxBeds,
      minBaths,
      maxBaths,
      minParkingSpaces,
      minSqft,
      maxSqft,
    } = filters;

    let total = 0;
    if (differences.some((i) => ["area", "city", "neighborhood"].includes(i)))
      total += 1;

    if (differences.some((i) => ["minPrice", "maxPrice"].includes(i)))
      total += 1;

    if (differences.some((i) => ["minBaths", "maxBaths"].includes(i)))
      total += 1;

    if (differences.some((i) => ["minBeds", "maxBeds"].includes(i))) total += 1;

    if (differences.some((i) => ["minListDate", "maxListDate"].includes(i)))
      total += 1;

    if (differences.some((i) => ["minSoldDate", "maxSoldDate"].includes(i)))
      total += 1;

    if (differences.some((i) => ["minSqft", "maxSqft"].includes(i))) total += 1;

    total = Math.round(differences.length - total / 2);

    if (differences.some((d) => ["minPrice", "maxPrice"].includes(d))) {
      if ((minPrice === "0" || minPrice === 0) && maxPrice === "max")
        differences = differences.filter(
          (d) => d !== "minPrice" && d !== "maxPrice"
        );
    }
    if (differences.some((d) => ["minBeds", "maxBeds"].includes(d))) {
      if (minBeds === 0 && maxBeds === "any")
        differences = differences.filter(
          (d) => d !== "minBeds" && d !== "maxBeds"
        );
    }
    if (differences.some((d) => ["minBaths", "maxBaths"].includes(d))) {
      if (minBaths === 0 && maxBaths === "any")
        differences = differences.filter(
          (d) => d !== "minBaths" && d !== "maxBaths"
        );
    }
    if (differences.some((d) => ["minSqft", "maxSqft"].includes(d))) {
      if (minSqft === 0 && maxSqft === "max")
        differences = differences.filter(
          (d) => d !== "minSqft" && d !== "maxSqft"
        );
    }

    if (differences.some((d) => ["minParkingSpaces"].includes(d))) {
      if (minParkingSpaces === "any")
        differences = differences.filter((d) => d !== "minParkingSpaces");
    }

    differences = differences.filter((d) => d !== "resultsPerPage");

    return { total, differences };
  };

  return (
    <Component
      {...props}
      filters={filters}
      activeFilters={activeFilters().differences}
      totalActiveFilters={activeFilters().total}
      updateSingleFilter={updateSingle}
      updateMultipleFilters={updateMultiple}
      updateExtraFilters={updateExtra}
      extraFilters={extraFilters}
      resetFilters={reset}
    />
  );
};

export default withFilters;
