import React from "react";
import Footer from "../../../components/Footer/footer.component";
import Summary from "./component/Summary/summary.component";
import Team from "./component/Team/team.component";
import Career from "./component/Career/career.component";
import FAQ from "./component/FAQ/faq.component";
import styles from "./about-us.module.scss";

const AboutUs = () => {
  return (
    <div className={styles.aboutus}>
      <div className={styles.cards}>
        <div className={styles.leftCards}>
          <Summary />
          <Team />
          <Career />
        </div>
        <div>
          <FAQ />
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default AboutUs;
