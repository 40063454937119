import { useState } from "react";
import { IonIcon } from "@ionic/react";
import { useHistory } from "react-router-dom";
import {
  bugOutline,
  chatbubbleOutline,
  chevronDownOutline,
  rocketOutline,
  statsChartOutline,
  menuOutline,
  megaphoneOutline,
} from "ionicons/icons";
import Fade from "react-reveal/Fade";
import styles from "./links.module.scss";

const Links = ({ expand, setExpand, collapse }) => {
  const history = useHistory();
  const [hover, setHover] = useState("");
  return (
    <div className={styles.container}>
      <div
        className={`${collapse ? styles.collapseSelected : styles.selected} ${
          expand ? (collapse ? styles.collapseExpanded : styles.expanded) : null
        }`}
        onClick={() => setExpand(!expand)}
      >
        <IonIcon
          icon={menuOutline}
          className={
            collapse
              ? expand
                ? styles.collapseIconExpand
                : styles.collapseIcon
              : styles.icon
          }
        />
        {!collapse && (
          <>
            <span className={styles.label}>more</span>
            <IonIcon
              icon={chevronDownOutline}
              className={`${styles.chevron} ${expand && styles.inverted}`}
            />
          </>
        )}
        {collapse && expand && (
          <IonIcon
            icon={chevronDownOutline}
            className={styles.collapseChevron}
          />
        )}
      </div>
      <Fade>
        <div
          className={`${collapse ? styles.collapseItems : styles.items} ${
            !expand && styles.collapsed
          } `}
        >
          <div
            className={styles.item}
            onMouseEnter={() => setHover("about")}
            onMouseLeave={() => setHover("")}
            onClick={() => history.push("/about-us")}
          >
            <IonIcon icon={rocketOutline} className={styles.icon} />
            <span className={styles.label}>about us</span>
            {hover === "about" && collapse && (
              <div className={styles.hoveredItem}>
                <IonIcon icon={rocketOutline} className={styles.icon} />
                <span className={styles.hoverLabel}>about us</span>
              </div>
            )}
          </div>
          <div
            className={styles.item}
            onMouseEnter={() => setHover("market")}
            onMouseLeave={() => setHover("")}
            onClick={() => history.push("/market-data")}
          >
            <IonIcon icon={statsChartOutline} className={styles.icon} />
            <span className={styles.label}>market data</span>
            {hover === "market" && collapse && (
              <div className={styles.hoveredItem}>
                <IonIcon icon={statsChartOutline} className={styles.icon} />
                <span className={styles.hoverLabel}>market data</span>
              </div>
            )}
          </div>
          {/* <div
            className={styles.item}
            onMouseEnter={() => setHover("talks")}
            onMouseLeave={() => setHover("")}
            onClick={() => history.push("/talks")}
          >
            <IonIcon icon={bulbOutline} className={styles.icon} />
            <span className={styles.label}>ettie talks</span>
            {hover === "talks" && collapse && (
              <div className={styles.hoveredItem}>
                <IonIcon icon={bulbOutline} className={styles.icon} />
                <span className={styles.hoverLabel}>ettie talks</span>
              </div>
            )}
          </div> */}
          <div
            className={styles.item}
            onMouseEnter={() => setHover("feedback")}
            onMouseLeave={() => setHover("")}
            onClick={() => history.push("/feedback")}
          >
            <IonIcon icon={megaphoneOutline} className={styles.icon} />
            <span className={styles.label}>feedback</span>
            {hover === "feedback" && collapse && (
              <div className={styles.hoveredItem}>
                <IonIcon icon={megaphoneOutline} className={styles.icon} />
                <span className={styles.hoverLabel}>feedback</span>
              </div>
            )}
          </div>
          <div
            className={styles.item}
            onMouseEnter={() => setHover("contact")}
            onMouseLeave={() => setHover("")}
            onClick={() => history.push("/contact-us")}
          >
            <IonIcon icon={chatbubbleOutline} className={styles.icon} />
            <span className={styles.label}>contact</span>
            {hover === "contact" && collapse && (
              <div className={styles.hoveredItem}>
                <IonIcon icon={chatbubbleOutline} className={styles.icon} />
                <span className={styles.hoverLabel}>contact</span>
              </div>
            )}
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default Links;
