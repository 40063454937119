import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonSpinner,
} from "@ionic/react";
import { selectUserError } from "../../../../redux/user/user.selectors";
import {
  clearErrors,
  confirmSignUpStart,
} from "../../../../redux/user/user.actions";
import styles from "./confirm-sign-up.module.scss";
import CustomModalHeader from "../../../components/CustomModalHeader/custom-modal-header.component";
import Input from "../../../../components/Form/Input/input.component";
import { resendConfirmationCode } from "../../../../amplify/auth.utils";
import { updateToast } from "../../../../redux/ui/ui.actions";

const AppConfirmSignUpModal = ({
  setShowConfirmModal,
  username,
  password,
  signIn = false,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const error = useSelector(selectUserError);

  const confirmSignup = () => {
    setLoading(true);
    dispatch(clearErrors());
    dispatch(
      confirmSignUpStart({
        username,
        password,
        code,
      })
    );
  };

  const handleResendCode = async () => {
    dispatch(clearErrors());
    try {
      await resendConfirmationCode(username);
      dispatch(
        updateToast({
          open: true,
          type: "notification",
          message: "Verification code sent.",
        })
      );
    } catch (err) {
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message: err.message,
        })
      );
    }
  };

  useEffect(() => {
    if (error && loading) setLoading(false);
  }, [error, loading]);

  return (
    <>
      <CustomModalHeader
        title={signIn ? "Verify email" : "Register"}
        setShowModal={setShowConfirmModal}
      />
      <IonContent>
        <IonGrid className={styles.confirmationCode}>
          <IonRow>
            <IonCol className={styles.title}>Check your email</IonCol>
          </IonRow>
          <IonRow>
            <IonCol className={styles.subtitle}>
              Enter the code you received in your inbox.
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class={styles.codeInput}>
              <Input
                required
                label="code"
                placeholder="code"
                mode="numeric"
                value={code}
                onChange={(value) => setCode(value)}
                submit={confirmSignup}
                isApp
                enterkeyhint="go"
              />
            </IonCol>
          </IonRow>
          {error && (
            <IonRow className={styles.error}>
              <IonCol>{error.message}</IonCol>
            </IonRow>
          )}
          <IonRow>
            <IonCol>
              <IonButton
                className={styles.button}
                shape="round"
                onClick={confirmSignup}
              >
                {loading ? (
                  <span className={styles.btntitle}>
                    <IonSpinner name="crescent" className={styles.spinner} />
                    working on it!
                  </span>
                ) : (
                  <span> Next!</span>
                )}
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton
                shape="round"
                fill="clear"
                className={styles.resend}
                onClick={handleResendCode}
              >
                Resend code
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </>
  );
};

export default AppConfirmSignUpModal;
