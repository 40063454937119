import { useState, useEffect } from "react";
import {
  IonCheckbox,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonModal,
  IonRadio,
  IonRadioGroup,
  IonSearchbar,
} from "@ionic/react";
import styles from "./searchable-select.module.scss";
import CustomModalHeader from "../../CustomModalHeader/custom-modal-header.component";

const SearchableSelect = ({
  title,
  items,
  selected,
  setSelected,
  onChange,
  type = "checkbox",
  label,
  icon,
  required,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [list, setList] = useState();

  const renderCheckbox = () => {
    const items =
      list &&
      list.map((item) => (
        <IonItem key={item.id} className={styles.listItem}>
          <div className={styles.labels}>
            <IonLabel className={styles.main}>{item.name}</IonLabel>
            <IonLabel className={styles.secondary}>{item.email}</IonLabel>
          </div>
          <IonCheckbox
            className={styles.checkbox}
            slot="start"
            value={item.id}
            checked={selected && selected.find((i) => i.id === item.id)}
            onIonChange={({ detail: { checked } }) => {
              if (checked) {
                setSelected([...selected, item]);
              } else {
                setSelected(selected.filter((i) => i.id !== item.id));
              }
            }}
          />
        </IonItem>
      ));

    return items && items.length ? (
      items
    ) : (
      <IonItem className={styles.listItem} lines="full">
        <IonLabel>No matching result found.</IonLabel>
      </IonItem>
    );
  };

  const renderRadio = () => {
    const items =
      list &&
      list.map((item) => (
        <IonItem key={item.id} className={styles.listItem} lines="full">
          <div className={styles.labels}>
            <IonLabel className={styles.main}>{item.name}</IonLabel>
            <IonLabel className={styles.secondary}>{item.email}</IonLabel>
          </div>

          <IonRadio slot="start" value={item.id} className={styles.radio} />
        </IonItem>
      ));
    return items && items.length ? (
      items
    ) : (
      <IonItem className={styles.listItem} lines="full">
        <IonLabel>No matching result found.</IonLabel>
      </IonItem>
    );
  };

  useEffect(() => {
    if (items) setList(items);
  }, [items]);

  return (
    <>
      <IonModal
        isOpen={isOpen}
        onDidDismiss={() => setIsOpen(false)}
        swipeToClose={true}
      >
        <CustomModalHeader
          setShowModal={setIsOpen}
          title={label}
          closeLabel="Done"
        />
        <IonContent className={styles.content} scrollY>
          <IonSearchbar
            placeholder={title}
            className={styles.search}
            onIonChange={({ detail: { value } }) => onChange(value)}
            onIonClear={() => onChange("value")}
          />
          <IonList>
            {type === "radio" && (
              <IonRadioGroup
                value={selected && selected.id}
                onIonChange={({ detail: { value } }) =>
                  setSelected(list.find((i) => i.id === value))
                }
              >
                <IonListHeader className={styles.header}>{title}</IonListHeader>
                {renderRadio()}
              </IonRadioGroup>
            )}

            {type === "checkbox" && (
              <>
                <IonListHeader className={styles.header}>{title}</IonListHeader>
                {renderCheckbox()}
              </>
            )}
          </IonList>
        </IonContent>
      </IonModal>
      <IonItem
        onClick={() => setIsOpen(true)}
        className={`${styles.item} ${icon && styles.addItem}`}
        lines="none"
      >
        <IonLabel>
          {icon && <IonIcon icon={icon} />}
          {label}
          {required && "*"}
        </IonLabel>
        {!icon && <IonLabel>{selected && selected.name}</IonLabel>}
      </IonItem>
    </>
  );
};

export default SearchableSelect;
