import { useState } from "react";
import { IonAlert, IonIcon } from "@ionic/react";
import styles from "./tour-element.module.scss";
import { chevronForwardOutline } from "ionicons/icons";

const TourElement = ({
  tour,
  icon,
  title,
  name,
  onClick,
  iconStyle,
  status,
  shareStatus,
  ...rest
}) => {
  const [alert, setAlert] = useState(false);

  return (
    <div
      className={styles.tourElement}
      onClick={!shareStatus ? onClick : () => {}}
      {...rest}
    >
      <IonAlert
        isOpen={alert}
        onDidDismiss={() => setAlert(false)}
        cssClass={styles.alert}
        header={"Share with client"}
        animated
        message="Are you sure you want to share this tour with client?"
        buttons={[
          {
            text: " Cancel",
            role: "cancel",
            cssClass: styles.cancel,
            handler: () => setAlert(false),
          },
          {
            text: "Share",
            role: "Ok",
            handler: onClick,
          },
        ]}
      />
      <div className={styles.icon}>
        <IonIcon icon={icon} style={iconStyle} />
      </div>
      {name ? (
        <div className={styles.title}>
          <span>{name}</span>
          <span>{title}</span>
        </div>
      ) : shareStatus ? (
        <div
          className={styles.title}
          onClick={() =>
            tour.shared === "shared" ? setAlert(false) : setAlert(true)
          }
        >
          {title}
        </div>
      ) : (
        <div className={styles.title}>{title}</div>
      )}

      {status && (
        <div className={`${styles.status} ${styles[status]}`}>{status}</div>
      )}
      {shareStatus ? (
        <div
          className={styles.arrowIcon}
          onClick={() =>
            tour.shared === "shared" ? setAlert(false) : setAlert(true)
          }
        >
          <span className={styles[tour.shared]}>
            {tour.shared === "shared" ? "Yes" : "No"}
          </span>
        </div>
      ) : (
        <div className={styles.arrowIcon}>
          <IonIcon icon={chevronForwardOutline} style={iconStyle} />
        </div>
      )}
    </div>
  );
};

export default TourElement;
