import React from "react";
import CardContainer from "../CardContainer/card-container.component";
import styles from "./no-tour-item.module.scss";

const NoTourItem = ({ type }) => (
  <CardContainer>
    {type === "upcoming" ? (
      <div className={styles.noItem}>
        <div className={styles.title}>You have no active tours right now.</div>
        <div className={styles.subtitle}>
          Get a tour started, and it will appear here.
        </div>
      </div>
    ) : type === "past" ? (
      <div className={styles.noItem}>
        <div className={styles.title}>You have no past tours right now.</div>
        <div className={styles.subtitle}>Only past tours will appear here.</div>
      </div>
    ) : (
      type === "archived" && (
        <div className={styles.noItem}>
          <div className={styles.title}>
            You have no archived tours right now.
          </div>
          <div className={styles.subtitle}>
            Once you have archived a tour, it will appear here.
          </div>
        </div>
      )
    )}
  </CardContainer>
);

export default NoTourItem;
