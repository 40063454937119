import { useState, useEffect } from "react";
import { IonAlert, IonToast } from "@ionic/react";
import {
  deleteAccount,
  deleteFederatedAccount,
} from "../../../../amplify/auth.utils";
import { useHistory } from "react-router-dom";
import styles from "./delete-account-alert.module.scss";

const DeleteAccountAlert = ({
  client,
  user,
  showDeleteAlert,
  setShowDeleteAlert,
  setProfileModal,
  handleSignOut,
}) => {
  const history = useHistory();
  const [error, setError] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  let hasError = false;

  useEffect(() => {
    const interval = setInterval(() => {
      if (showSuccessToast) {
        setProfileModal(false);
        handleSignOut();
        history.replace("/tabs/home", { direction: "root" });
      }
    }, 3000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSuccessToast]);

  const isFederated = () => {
    const { username } = user;
    if (
      username.startsWith("google") ||
      username.startsWith("facebook") ||
      username.startsWith("signinwithapple")
    )
      return true;
    return false;
  };

  const handleDelete = async (password) => {
    if (password !== "") {
      if (!isFederated()) {
        try {
          const res = await deleteAccount(password, client);
          if (res.message) {
            setError("Incorrect password.");
            setShowErrorToast(true);
            hasError = true;
          } else {
            setShowSuccessToast(true);
            hasError = false;
          }
        } catch (err) {
          setError("Something went wrong. Please try again.");
          setShowErrorToast(true);
          hasError = true;
        }
      } else {
        try {
          const res = await deleteFederatedAccount(client);
          if (res.message) {
            setError("Something went wrong. Please try again.");
            setShowErrorToast(true);
            hasError = true;
          } else {
            setShowSuccessToast(true);
            hasError = false;
          }
        } catch (err) {
          setError("Something went wrong. Please try again.");
          setShowErrorToast(true);
          hasError = false;
        }
      }
    } else {
      setError("This field is required");
      setShowErrorToast(true);
      hasError = true;
    }
  };

  return (
    <>
      <IonToast
        isOpen={showErrorToast}
        onDidDismiss={() => {
          setError("");
          setShowErrorToast(false);
        }}
        message={error}
        cssClass={styles.errorToast}
        position="top"
        buttons={[
          {
            side: "end",
            icon: "close",
            handler: () => setError(""),
          },
        ]}
        duration={4000}
      />
      <IonToast
        isOpen={showSuccessToast}
        onDidDismiss={() => setShowSuccessToast(false)}
        message="Acoount deleted ! 
                    If you want to give us any feedback on how we can do better, please contact us! We hope to see you again."
        cssClass={styles.successToast}
        position="top"
        duration={3000}
      />
      {isFederated() ? (
        <IonAlert
          isOpen={showDeleteAlert}
          onDidDismiss={() => setShowDeleteAlert(false)}
          cssClass={styles.alert}
          header={"Are you sure?"}
          message={
            "We hate to see you leave! But if you must, type DELETE below to permanently delete your account."
          }
          inputs={[
            {
              name: "password",
              type: "text",
              placeholder: "DELETE",
            },
          ]}
          buttons={[
            {
              text: "Cancel",
              handler: () => {
                setShowDeleteAlert(false);
              },
            },
            {
              text: "Delete",
              handler: (e) => {
                handleDelete(e.password);
                if (hasError) {
                  return false;
                }
              },
            },
          ]}
        />
      ) : (
        <IonAlert
          isOpen={showDeleteAlert}
          onDidDismiss={() => setShowDeleteAlert(false)}
          cssClass={styles.alert}
          header={"Are you sure?"}
          message={
            "We hate to see you leave! But if you must, enter your password below to permanently delete your account."
          }
          inputs={[
            {
              name: "password",
              type: "password",
              placeholder: "password*",
            },
          ]}
          buttons={[
            {
              text: "Cancel",
              handler: () => {
                setShowDeleteAlert(false);
              },
            },
            {
              text: "Delete",
              handler: (e) => {
                handleDelete(e.password);
                if (hasError) {
                  return false;
                }
              },
            },
          ]}
        />
      )}
    </>
  );
};

export default DeleteAccountAlert;
