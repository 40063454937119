import { InAppBrowser } from "@ionic-native/in-app-browser";
import { IonButton, IonLabel } from "@ionic/react";
import { useHistory } from "react-router-dom";
import { isApp } from "../../../../utils/functions";
import styles from "./home-card.module.scss";

const HomeCard = ({
  item: { title, text, actionLabel, actionLink, inAppAction, image },
}) => {
  const history = useHistory();
  const handleClick = () => {
    if (inAppAction) {
      history.push(actionLink, { direction: "forward" });
    } else {
      if (isApp()) {
        InAppBrowser.create(actionLink, "_blank", {
          hideurlbar: true,
          hidenavigationbutton: true,
          toolbar: "yes",
          toolbarcolor: "#110d1b",
          closebuttoncolor: "#f4f4f7",
          closebuttoncaption: "Back",
          toolbarposition: "top",
        });
      } else {
        window.open(actionLink, "_blank");
      }
    }
  };

  return (
    <div className={styles.card}>
      <div className={styles.img}>
        <img src={image} alt={title} />
      </div>
      <div className={styles.title}>{title}</div>
      <div className={styles.text}>{text}</div>

      {actionLabel && actionLink && (
        <IonButton
          onClick={handleClick}
          className={styles.actionBtn}
          fill="outline"
          expand="block"
        >
          <IonLabel>{actionLabel}</IonLabel>
        </IonButton>
      )}
    </div>
  );
};

export default HomeCard;
