import { useState } from "react";
import { useDispatch } from "react-redux";
import { IonButton, IonIcon, IonAlert } from "@ionic/react";
import { closeOutline, eyeOffOutline } from "ionicons/icons";
import CardContainer from "../../../Tours/component/CardContainer/card-container.component";
import DetailsHeader from "../../../../components/TourDetailsHeader/tour-details-header.module";
import styles from "./agent-details.module.scss";
import Button from "../../../../components/Form/Button/button.component";
import { updateTour } from "../../../../amplify/graphql.utils";
import { updateToast } from "../../../../redux/ui/ui.actions";
import TextArea from "../../../../components/Form/TextArea/textarea.component";

const AgentDetails = ({ tour, isOpen, setIsOpen, update, reload }) => {
  const [note, setNote] = useState(tour.privateNote);
  const [archive, setArchive] = useState(false);
  const dispatch = useDispatch();

  const handleArchive = async () => {
    try {
      await updateTour({ id: tour.id, status: "archived" });
      setArchive(false);
      dispatch(
        updateToast({
          open: true,
          type: "success",
          message: "Tour has been archived successfully.",
        })
      );
      reload();
    } catch (err) {
      setArchive(false);
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message: "Something went wrong!",
        })
      );
    }
  };

  return (
    <>
      <IonAlert
        isOpen={archive}
        backdropDismiss={true}
        onDidDismiss={() => setArchive(false)}
        header="Archive tour"
        message="By archiving a tour, tour attendants will no longer be able to update its content."
        subHeader="Are you sure you want to archive this tour?"
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            handler: () => setArchive(false),
          },
          {
            text: "Archive",
            role: "submit",
            handler: () => handleArchive(),
          },
        ]}
      />

      <CardContainer>
        <DetailsHeader
          title="Agent details"
          icon={eyeOffOutline}
          isVisible={false}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />

        {isOpen && (
          <div className={styles.inputs}>
            {/* <div className={styles.container}>
              <Input
                name="tour_title"
                label="Tour title"
                placeholder="Tour title"
                style={{ width: "100%" }}
                value={title}
                defaultValue={title}
                onChange={(value, name) => setTitle(value)}
              />
            </div> */}
            <TextArea
              name="note"
              value={note}
              onChange={(val) => {
                setNote(val);
              }}
              label="Private agent tour notes"
              placeholder="Private agent tour notes"
            />

            <Button
              title="Save changes"
              type="green"
              style={{
                border: "1px solid #1F7A60",
                width: "100%",
                height: "5rem",
                marginTop: "2rem",
              }}
              onClick={() => update({ privateNote: note, id: tour.id })}
              disabled={!tour || !tour.status || tour.status === "archived"}
            />

            <div className={styles.note}>
              These fields will not be shared with the client.
            </div>

            <div className={styles.remove}>
              <IonButton
                fill="clear"
                className={styles.button}
                onClick={() => setArchive(true)}
                disabled={tour.status === "archived"}
              >
                {tour.status !== "archived" ? (
                  <>
                    <IonIcon icon={closeOutline} />
                    Archive tour
                  </>
                ) : (
                  "This tour is archived"
                )}
              </IonButton>
            </div>
          </div>
        )}
      </CardContainer>
    </>
  );
};

export default AgentDetails;
