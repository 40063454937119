import { useState, useRef, memo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IonPage, IonContent, IonModal } from "@ionic/react";
import Map from "../../../components/Map/map.component";
import {
  selectSchools,
  selectSchoolsFilters,
} from "../../../redux/schools/schools.selectors";
import withMap from "../../../HOC/withMap/with-map";
import withFilters from "../../../HOC/withFilters/with-filters";
import styles from "./search.module.scss";
import pcGif from "../../../assets/gif/CraneMoving.gif";
import { updateFilters } from "../../../redux/filters/filters.actions";
import CustomModalHeader from "../../components/CustomModalHeader/custom-modal-header.component";
import { fetchSchoolsStart } from "../../../redux/schools/schools.actions";
import AppSchoolFilterModal from "../../components/CustomModals/SchoolFilter/school-filter.component";
import SearchModal from "../../../components/Map/components/MapControls/components/SearchModal/search-modal.component";
import SaveSearchModal from "../../../components/Map/components/MapControls/components/SaveSearchModal/save-search-modal.component";
import {
  selectMapBoundaries,
  selectMapRef,
} from "../../../redux/map/map.selectors";
import { selectDrawMode } from "../../../redux/ui/ui.selectors";
import {
  selectClientError,
  selectClientLoading,
  selectCurrentClient,
} from "../../../redux/client/client.selectors";
import { useHistory } from "react-router-dom";

const AppSearchPage = ({
  mapLoading,
  mapListings,
  mapError,
  areas,
  mapSelectedLocation,
  updateMapRef,
  updateMapSelectedLocation,
  mapLocations,
  updateMapLocations,
  fetchMapListings,
  filters,
  extraFilters,
}) => {
  const dispatch = useDispatch();
  const ref = useRef();
  const history = useHistory();
  const [view, setView] = useState("map");
  const [showModal, setShowModal] = useState(false);
  const [modal, setModal] = useState(false);
  const updateView = () => {
    if (view === "map" && mapSelectedLocation) {
      updateMapSelectedLocation(null);
      setView("list");
    } else {
      setView(view === "map" ? "list" : "map");
    }
  };
  const mapRef = useSelector(selectMapRef);
  const drawMode = useSelector(selectDrawMode);
  const currentClient = useSelector(selectCurrentClient);
  const clientLoading = useSelector(selectClientLoading);
  const clientError = useSelector(selectClientError);
  const schools = useSelector(selectSchools);
  const schoolsFilters = useSelector(selectSchoolsFilters);

  const { active } = schools;
  const boundaries = useSelector(selectMapBoundaries);

  useEffect(() => {
    if (active && mapRef && mapRef.zoom > 11)
      dispatch(fetchSchoolsStart({ boundary: boundaries[0] }));
  }, [active, dispatch, boundaries, mapRef, schoolsFilters]);

  const handleModals = (type) => {
    if (!currentClient && type === "savedSearch") {
      history.push("/join", { direction: "none" });
      return;
    }
    setModal(type);
  };

  useEffect(() => {
    setShowModal(modal ? true : false);
  }, [modal]);

  return (
    <IonPage ref={ref}>
      <IonModal
        id="modal"
        isOpen={showModal}
        onDidDismiss={() => setShowModal(false)}
        presentingElement={ref.current}
      >
        {modal === "search" && (
          <SearchModal mapRef={mapRef} close={() => setModal(null)} />
        )}
        {modal === "schools" && (
          <AppSchoolFilterModal close={() => setModal(null)} />
        )}
        {modal === "savedSearch" && (
          <SaveSearchModal
            close={() => setModal(null)}
            drawMode={drawMode}
            schoolMode={{
              active: schools.filterListings.active,
              schools: schools.filterListings.schools,
            }}
            filters={filters}
            extraFilters={extraFilters}
            client={currentClient}
            clientError={clientError}
            clientLoading={clientLoading}
          />
        )}
      </IonModal>

      {filters.type === "pre-construction" && (
        <CustomModalHeader
          title="Pre-construction"
          hasBackbtn={true}
          disable={true}
          setShowModal={() => dispatch(updateFilters({ type: "sale" }))}
          isPreConstruction
        />
      )}

      <IonContent scrollY={false}>
        <div className={styles.mapContainer}>
          {filters.type === "pre-construction" && (
            <div
              className={styles.comingSoon}
              onClick={() => dispatch(updateFilters({ type: "sale" }))}
            >
              <img
                className={styles.animation}
                src={pcGif}
                alt="Coming soon!"
              />
              <h2 className={styles.header}>Coming soon!</h2>
              <span className={styles.content}>
                We are very excited to be building our pre-construction program
                and search portal!
              </span>

              <span className={styles.content}>
                By registering for Ettie you will be first to hear when it’s
                ready to launch!
              </span>
            </div>
          )}
          <Map
            isApp={true}
            areas={areas}
            listings={mapListings}
            loading={mapLoading}
            error={mapError}
            selectedLocation={mapSelectedLocation}
            updateSelectedLocation={updateMapSelectedLocation}
            fetchListings={fetchMapListings}
            mapLocations={mapLocations}
            updateMapLocations={updateMapLocations}
            updateRef={updateMapRef}
            filters={filters}
            extraFilters={extraFilters}
            hide={filters.type === "pre-construction"}
            schools={schools}
            type="mobile"
            view={view}
            setView={updateView}
            handleModals={handleModals}
            pageRef={ref.current}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default memo(withMap(withFilters(AppSearchPage)));
