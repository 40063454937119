import Select from "../../Form/Select/select.component";
import RangeFilter from "../../RangeFilter/range-filter.component";
import { parkingsRange } from "../../Filters/data/filters.data";
import styles from "../filters-menu.module.scss";
import withFilters from "../../../HOC/withFilters/with-filters";

const ParkingsFilterItem = ({
  filters: { minParkingSpaces },
  updateSingleFilter,
  buttonStyle,
  tabName,
  tab,
  setTab,
  disabled,
}) => {
  const onChangeHandler = ({ value }) => {
    updateSingleFilter({
      minParkingSpaces: value === 0 || value === "Any" ? null : value,
    });
  };

  return (
    <div className={styles.item}>
      <Select
        buttonStyle={buttonStyle}
        title="Parking"
        open={tab === tabName}
        onClick={() => setTab(tabName)}
        dropdown={false}
        disabled={disabled}
      />
      <RangeFilter
        type="rooms"
        open={tab === tabName}
        range={parkingsRange}
        value={{
          min: minParkingSpaces === null ? "any" : minParkingSpaces,
          max: 100,
        }}
        onlyMin={true}
        dropdown={false}
        onChange={onChangeHandler}
        style={{ marginBottom: "1rem", width: "50%" }}
      />
    </div>
  );
};

export default withFilters(ParkingsFilterItem);
