import styles from "./details.module.scss";
import { useState, useEffect, useRef, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IonPage, IonContent } from "@ionic/react";
import LoadingFullPage from "../../../components/Loading/loading-full-page.component";
import { getListing, getSimilarListing } from "../../../api/repliers";
import ListingImages from "./components/ListingImages/listing-images.component";
import ListingHeader from "./components/ListingHeader/listing-header.component";
import ListingDetails from "./components/ListingDetails/listing-details.component";
import ListingFooter from "./components/ListingFooter/listing-footer.component";
import ErrorPage from "../../../components/Errors/error.component";
import { selectCurrentUser } from "../../../redux/user/user.selectors";
import { updateFavouriteListing } from "../../../amplify/graphql.utils";
import { markNotification } from "../../../redux/ui/ui.actions";
import { getBoundingCoords } from "../../../utils/functions";
import { findNearbySchools } from "../../../api/ettie";
import axios from "axios";

const AppDetailsPage = ({
  match: {
    params: { id },
  },
  location: { state },
}) => {
  const dispatch = useDispatch();
  const ref = useRef();
  const imagesRef = useRef();
  const [_, updateState] = useState();
  const [loading, setLoading] = useState(true);
  const [property, setProperty] = useState();
  const [similarListing, setSimilarListing] = useState();
  const [error, setError] = useState();
  const [scroll, setScroll] = useState(true);
  const isAuthenticated = useSelector(selectCurrentUser);
  const [schools, setSchools] = useState([]);

  const forceReload = () => updateState(Math.random());

  useEffect(() => {
    if (error === "401" && isAuthenticated) setError(null);
  }, [isAuthenticated, error]);

  useEffect(() => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    const getPropertyDetails = async () => {
      try {
        setLoading(true);
        setError(null);
        let listing;
        if (state && state.listing) {
          listing = state.listing;
        } else {
          listing = await getListing(id, source.token);
        }

        try {
          const similarActiveListing = await getSimilarListing(
            id,
            source.token
          );
          similarActiveListing && similarActiveListing.similar
            ? setSimilarListing(similarActiveListing.similar)
            : setSimilarListing([]);
        } catch (err) {}
        setProperty(listing);
        if (listing.status === "U" && !isAuthenticated) setError("401");
        // setLoading(false);
        if (isAuthenticated) {
          dispatch(markNotification(listing.mlsNumber));
          updateFavouriteListing(listing);
        }
      } catch (error) {
        // setLoading(false);
        setError(error.message);
      }
      setLoading(false);
    };

    getPropertyDetails();

    return () => source?.cancel();
  }, [isAuthenticated, dispatch, id, state, _]);

  useEffect(() => {
    const getSchools = async () => {
      const { latitude, longitude } = property.map;

      const { topLeft, topRight, bottomRight, bottomLeft } = getBoundingCoords(
        parseFloat(latitude),
        parseFloat(longitude),
        1500
      );

      const schools = await findNearbySchools({
        boundary: [
          [topLeft.lng, topLeft.lat],
          [topRight.lng, topRight.lat],
          [bottomRight.lng, bottomRight.lat],
          [bottomLeft.lng, bottomLeft.lat],
        ],
      });

      setSchools(schools);
    };

    if (property) getSchools();
  }, [property]);

  const renderContent = () => {
    return loading || !property || (property && property.mlsNumber !== id) ? (
      <LoadingFullPage details onReload={forceReload} />
    ) : (
      <>
        <div
          className={`${styles.headerContainer} ${
            property.images &&
            property.images.length > 0 &&
            styles.purpleBackground
          }`}
        >
          <ListingHeader previousLocation={state?.location} />
          <ListingImages images={property.images} imagesRef={imagesRef} />
        </div>
        <ListingDetails
          listing={property}
          similarListing={similarListing}
          parentRef={ref.current}
          imagesRef={imagesRef}
          scroll={scroll}
          setScroll={setScroll}
          schools={schools}
        />
        <ListingFooter listing={property} parentRef={ref.current} />
      </>
    );
  };

  return (
    <IonPage ref={ref}>
      <IonContent
        scrollY={true}
        className={styles.ioncontent}
        forceOverscroll={false}
      >
        {
          <>
            {error && <ErrorPage status={error} onReload={forceReload} isApp />}
            {!error && renderContent()}
          </>
        }
      </IonContent>
    </IonPage>
  );
};
export default memo(AppDetailsPage);
