import {
  IonItem,
  IonList,
  IonRadioGroup,
  IonLabel,
  IonRadio,
} from "@ionic/react";
import withFilters from "../../../../../HOC/withFilters/with-filters";
import styles from "./date-filter.module.scss";
import { dates as options } from "../../data/filters.data";
import moment from "moment";

const DateFilter = ({
  filters: { type, status, minSoldDate, minListDate, maxSoldDate },
  updateMultipleFilters,
}) => {
  //   const [selected, setSelected] = useState(
  //     types.includes("residential") ? "residential" : "commercial"
  //   );

  const getDateValue = () => {
    if (status === "A") {
      if (!minListDate) return null;
      return options.find(
        (opt) => opt.value === moment(new Date()).diff(minListDate, "days")
      ).value;
    } else {
      if (minSoldDate && maxSoldDate) {
        return options.find(
          (opt) => opt.value === `y${minSoldDate.substring(0, 4)}`
        )?.value;
      }
      if (!minSoldDate) return null;
      return options.find(
        (opt) => opt.value === moment(new Date()).diff(minSoldDate, "days")
      ).value;
    }
  };

  const handleOnChange = ({ detail: { value } }) => {
    if (status === "A") {
      updateMultipleFilters({
        minListDate: value
          ? moment().subtract(value, "days").format("YYYY-MM-DD")
          : null,
        minSoldDate: null,
        maxSoldDate: null,
      });
    } else {
      updateMultipleFilters({
        minListDate: null,
        minSoldDate: value
          ? value.toString().startsWith("y")
            ? `${value.replace("y", "")}-01-01`
            : moment().subtract(value, "days").format("YYYY-MM-DD")
          : null,
        maxSoldDate: value?.toString().startsWith("y")
          ? `${+value.replace("y", "")}-12-31`
          : null,
      });
    }
  };

  return (
    <IonList>
      <IonRadioGroup value={getDateValue()} onIonChange={handleOnChange}>
        {options
          .filter((opt) => {
            if (!opt.value) return true;
            if (status === "U") return true;
            if (typeof opt.value !== "string") return true;
            return false;
          })
          .map((opt, i) => (
            <IonItem lines="none" key={i}>
              <IonLabel
                className={`${styles.label} ${
                  getDateValue() === opt.value && styles.selected
                }`}
              >
                {opt.label}
              </IonLabel>
              <IonRadio slot="end" value={opt.value} className={styles.radio} />
            </IonItem>
          ))}
      </IonRadioGroup>
    </IonList>
  );
};

export default withFilters(DateFilter);
