import { useSelector } from "react-redux";
import numeral from "numeral";
import millify from "millify";
import { overview } from "../../../../../../../../data/detailsPage";
import { selectCurrentClient } from "../../../../../../../../redux/client/client.selectors";
import { selectCurrencyRates } from "../../../../../../../../redux/currency/currency.selectors";
import styles from "./overview.module.scss";

const Overview = ({ listing, language }) => {
  const client = useSelector(selectCurrentClient);
  const rates = useSelector(selectCurrencyRates);
  const price = ["Lsd,Sld"].includes(listing.lastStatus)
    ? listing.soldPrice
    : listing.listPrice;
  let currency;
  if (client && rates) {
    if (client.currency !== "cad") currency = client.currency.toUpperCase();
  }
  return (
    <div className={styles.overview}>
      <div className={styles.infos}>
        <div className={styles.details}>
          <div>
            <span className={styles.bold}>{overview[language].price}</span>
            <span>
              {currency
                ? `${currency} ${millify(
                    (+price / +rates.CAD.S) * +rates[currency].S
                  )}`
                : numeral(price).format("$1,000,000")}
            </span>
          </div>
          <div>
            <span className={styles.bold}>
              {overview[language].streetaddress}
            </span>
            <span>{`${listing.address.streetNumber} ${listing.address.streetName} ${listing.address.streetSuffix}`}</span>
          </div>
          <div>
            <span className={styles.bold}>{overview[language].unitNumber}</span>
            <span>
              {listing.address.unitNumber ? listing.address.unitNumber : "-"}
            </span>
          </div>
          <div>
            <span className={styles.bold}>{overview[language].postalCode}</span>
            <span>{listing.address.zip}</span>
          </div>
          <div>
            <span className={styles.bold}>{overview[language].status}</span>
            <span>{`For ${listing.type}`}</span>
          </div>
          {listing.class === "CondoProperty" && (
            <div>
              <span className={styles.bold}>
                {overview[language].condoType}
              </span>
              <span>{listing.details.propertyType}</span>
            </div>
          )}
          <div>
            <span className={`${styles.bold} ${styles.marginRight}`}>
              {overview[language].area}
            </span>
            <span>{listing.address.neighborhood}</span>
          </div>
          <div>
            <span className={`${styles.bold} ${styles.marginRight}`}>
              {overview[language].mainIntersection}
            </span>
            <span>{listing.address.majorIntersection}</span>
          </div>
          <div>
            <span className={styles.bold}>{overview[language].age}</span>
            <span>
              {listing.details.yearBuilt ? listing.details.yearBuilt : "-"}
            </span>
          </div>
          <div>
            <span className={styles.bold}>
              {overview[language].propertyStyle}
            </span>
            <span>{listing.details.style}</span>
          </div>
          {listing.class !== "CondoProperty" && (
            <div>
              <span className={styles.bold}>{overview[language].lotSize}</span>
              <span>
                {listing.lot.acres
                  ? listing.lot.acres + " " + listing.lot.measurement
                  : "-"}
              </span>
            </div>
          )}
          <div>
            <span className={styles.bold}>{overview[language].bedrooms}</span>
            <span>
              {listing.details.numBedroomsPlus !== null &&
              listing.details.numBedroomsPlus.length > 0
                ? `${
                    +listing.details.numBedrooms +
                    +listing.details.numBedroomsPlus
                  }`
                : listing.details.numBedrooms}
            </span>
          </div>
          <div>
            <span className={styles.bold}>{overview[language].bathrooms}</span>
            <span>
              {listing.details.numBathroomsPlus !== null &&
              listing.details.numBathroomsPlus.length > 0
                ? `${
                    +listing.details.numBathrooms +
                    +listing.details.numBathroomsPlus
                  }`
                : listing.details.numBathrooms}
            </span>
          </div>

          {listing.class === "CondoProperty" && (
            <div>
              <span className={styles.bold}>{overview[language].basement}</span>
              <span>
                {listing.details.basement1
                  ? listing.details.basement2
                    ? `${listing.details.basement1}, ${listing.details.basement2}`
                    : listing.details.basement1
                  : "-"}
              </span>
            </div>
          )}

          <div>
            <span className={styles.bold}>{overview[language].garageType}</span>
            <span>
              {listing.details.garage !== "" ? listing.details.garage : "-"}
            </span>
          </div>

          <div>
            <span className={styles.bold}>{overview[language].garage}</span>
            <span>
              {listing.details.numGarageSpaces !== ""
                ? parseInt(+listing.details.numGarageSpaces)
                : "-"}
            </span>
          </div>

          <div>
            <span className={styles.bold}>
              {overview[language].openParking}
            </span>
            <span>
              {listing.details.numGarageSpaces !== "" &&
              listing.details.numParkingSpaces !== ""
                ? parseInt(+listing.details.numParkingSpaces) -
                  parseInt(+listing.details.numGarageSpaces)
                : "-"}
            </span>
          </div>

          <div>
            <span className={styles.bold}>
              {overview[language].totalParking}
            </span>
            <span>
              {listing.details.numParkingSpaces !== ""
                ? parseInt(+listing.details.numParkingSpaces)
                : "-"}
            </span>
          </div>

          {listing.class === "CondoProperty" && (
            <div className={styles.ammenities}>
              <span className={`${styles.bold} ${styles.marginRight}`}>
                {overview[language].amenities}
              </span>
              <span>
                {listing.condominium.ammenities[0] !== null
                  ? listing.condominium.ammenities.map((item) => {
                      return item.length > 0 && item + ", ";
                    })
                  : "-"}
              </span>
            </div>
          )}

          {listing.class === "CondoProperty" && (
            <div>
              <span className={styles.bold}>{overview[language].pets}</span>
              <span>
                {listing.condominium.pets ? listing.condominium.pets : "-"}
              </span>
            </div>
          )}
          {listing.class === "ResidentialProperty" && (
            <div>
              <span className={styles.bold}>{overview[language].pool}</span>
              <span>
                {listing.details.swimmingPool
                  ? listing.details.swimmingPool
                  : "-"}
              </span>
            </div>
          )}
          <div>
            <span className={styles.bold}>{overview[language].sewers}</span>
            <span>{listing.details.sewer ? listing.details.sewer : "-"}</span>
          </div>

          {listing.class === "ResidentialProperty" && (
            <div className={styles.ammenities}>
              <span className={`${styles.bold} ${styles.marginRight}`}>
                {overview[language].amenities}
              </span>
              <span>
                {listing.condominium.ammenities[0] !== null
                  ? listing.condominium.ammenities.map((item) => {
                      return item.length > 0 && item + ", ";
                    })
                  : "-"}
              </span>
            </div>
          )}

          <div>
            <span className={styles.bold}>{overview[language].air}</span>
            <span>
              {listing.details.airConditioning
                ? listing.details.airConditioning
                : "-"}
            </span>
          </div>
          <div>
            <span className={styles.bold}>{overview[language].heat}</span>
            <span>
              {listing.details.heating ? listing.details.heating : "-"}
            </span>
          </div>
          {listing.class === "CondoProperty" && (
            <div>
              <span className={styles.bold}>
                {overview[language].heatIncluded}
              </span>
              <span>
                {listing.condominium.fees.heatIncl !== null &&
                listing.condominium.fees.heatIncl !== ""
                  ? listing.condominium.fees.heatIncl
                  : "-"}
              </span>
            </div>
          )}
          {listing.class === "CondoProperty" && (
            <div>
              <span className={styles.bold}>
                {overview[language].hydroIncluded}
              </span>
              <span>
                {listing.condominium.fees.hydroIncl !== null &&
                listing.condominium.fees.hydroIncl !== ""
                  ? listing.condominium.fees.hydroIncl
                  : "-"}
              </span>
            </div>
          )}
          {listing.class === "CondoProperty" && (
            <div>
              <span className={styles.bold}>
                {overview[language].waterIncluded}
              </span>
              <span>
                {listing.condominium.fees.waterIncl !== null &&
                listing.condominium.fees.waterIncl !== ""
                  ? listing.condominium.fees.waterIncl
                  : "-"}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Overview;
