import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import Card from "../../components/Card/card.component";
import EditBar from "../../components/EditBar/edit-bar.component";
import SavedSearchContent from "./component/SavedSearchContent/saved-search-content.component";
import withAuthentication from "../../HOC/withAuthentication/with-authentication";
import styles from "./saved-searches-listings.module.scss";
import AddSaveSearch from "./component/AddSaveSearch/add-save-search.component";
import Footer from "../../components/Footer/footer.component";
import { getSearchItems } from "../../amplify/graphql.utils";

const SavedSearchesListings = ({ isApp, clientLoading, pageHook }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [searches, setSearches] = useState();
  const [sortedSearches, setSortedSearches] = useState();
  const [sortBy, setSortBy] = useState({
    value: "date",
    direction: "desc",
  });
  const [isUpdated, setIsUpdated] = useState(false);

  const fetchSearches = async () => {
    setLoading(true);
    try {
      const items = await getSearchItems();
      setSearches(items);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isUpdated) {
      fetchSearches();
    }
    setIsUpdated(false);
  }, [searches, isUpdated]);

  pageHook(() => {
    fetchSearches();
  });

  useEffect(() => {
    fetchSearches();
  }, []);

  useEffect(() => {
    if (searches && searches.length) {
      const temp = [...searches];
      switch (sortBy.value) {
        case "date":
          temp.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1));
          break;
        case "name":
          temp.sort((a, b) => (a.name > b.name ? 1 : -1));
          break;
        default:
          return;
      }

      sortBy.direction === "desc"
        ? setSortedSearches(temp.reverse())
        : setSortedSearches(temp);
    } else {
      setSortedSearches([]);
    }
  }, [searches, sortBy]);

  const redirectHandler = () => {
    console.log("Hi");
    if (isApp) {
      history.push("/tabs/listings");
    } else {
      history.push("/listings");
    }
  };

  const renderCards = () => {
    if (sortedSearches) {
      if (sortedSearches.length) {
        const items = sortedSearches.map((search) => (
          <div className={styles.search} key={search.id}>
            <Card
              style={{ padding: "3rem 0", height: "12rem", overflow: "hidden" }}
            >
              <SavedSearchContent
                search={search}
                loading={clientLoading}
                setIsUpdated={setIsUpdated}
              />
            </Card>
          </div>
        ));

        return (
          <div className={styles.searchesContainer}>
            <div className={styles.searches}>
              {items}
              <Card add={true} style={{ padding: "0", height: "12rem" }}>
                <AddSaveSearch redirectHandler={redirectHandler} />
              </Card>
            </div>
            <div className={styles.footer}>
              <Footer />
            </div>
          </div>
        );
      } else {
        return (
          <div className={styles.searchesContainer}>
            <div className={styles.searches}>
              <Card add={true} style={{ padding: "0", height: "12rem" }}>
                <AddSaveSearch redirectHandler={redirectHandler} />
              </Card>
            </div>
            <div className={styles.footer}>
              <Footer />
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className={styles.searchesContainer}>
          <div className={styles.searches}>
            <Card add={true} style={{ padding: "0", height: "12rem" }}>
              <AddSaveSearch redirectHandler={redirectHandler} />
            </Card>
          </div>
          <div className={styles.footer}>
            <Footer />
          </div>
        </div>
      );
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.edit}>
          <EditBar sortBy={sortBy} setSortBy={setSortBy} mode="savedSearches" />
        </div>
        {renderCards()}
      </div>
    </div>
  );
};

export default withAuthentication(SavedSearchesListings);
