import {
  ClientsAPI,
  FavouritesAPI,
  ListingAPI,
  LocationsAPI,
  MapAPI,
  SearchAPI,
} from "./index";
import _ from "lodash";
import { store } from "../redux/store";
import axios from "axios";
import { getFavouriteItems } from "../amplify/graphql.utils";

const VALID_LOT_TYPES = ["Att/Row/Twnhouse", "Detached", "Semi-detached"];
const VALID_LOCKER_TYPES = ["Condo townhouse", "Condo Apt"];
const INVALID_BASEMENT_TYPES = ["Condo Apt"];

export const getTourListing = async (term) => {
  let {
    data: { listings },
  } = await ListingAPI.get(`/?keywords=${term}`);
  return listings;
};

export const getMapListings = async (term, token, isHomepage = false) => {
  const isSchoolsEnabled = store.getState().schools.active;
  const showOnlyWithinSchoolsBoundaries = store.getState().schools.filters
    .showListingsWithin;
  const schools = store.getState().schools.schools;

  const filters = store.getState().filters.value;
  const map = store.getState().map?.ref;

  const {
    lotWidth,
    lotDepth,
    locker,
    basement: { any, sepEntrance, walkOut, finished },
    maintenance,
  } = store.getState().filters.extra;

  const checkLotFilters = () => {
    if (!filters.propertyType) return false;
    const intersections = _.intersection(VALID_LOT_TYPES, filters.propertyType)
      .length;
    if (intersections === filters.propertyType.length) return true;
    return false;
  };

  const checkCondoFilters = () => {
    if (!filters.propertyType) return false;
    const intersections = _.intersection(
      VALID_LOCKER_TYPES,
      filters.propertyType
    ).length;
    if (intersections === filters.propertyType.length) return true;
    return false;
  };

  const checkBasementFilter = () => {
    if (!filters.propertyType) return false;
    const intersections = _.intersection(
      INVALID_BASEMENT_TYPES,
      filters.propertyType
    ).length;
    if (intersections === 0) return true;
    return false;
  };

  const calculateSchoolsBoundaries = () => {
    const map = [];
    schools.forEach((s) => {
      if (!s.boundaryArray || s.boundaryArray === "[]") return;
      const boundary = JSON.parse(JSON.parse(s.boundaryArray)).Points;
      let points = [];
      for (let i = 0; i < boundary.length; i += 2) {
        points.push(boundary.slice(i, i + 2).reverse());
      }
      map.push([...points]);
    });

    return map;
  };

  if (
    isHomepage ||
    (filters.type !== "pre-construction" &&
      filters.map &&
      filters.map[0][0][0] !== undefined)
  ) {
    try {
      let {
        data: { listings },
      } = await MapAPI.post(
        `?fields=map,mlsNumber,status,listPrice,listDate,lastStatus,soldPrice,soldDate,address,images,details,type,lot,condominium`,
        {
          map:
            isSchoolsEnabled && showOnlyWithinSchoolsBoundaries && schools
              ? calculateSchoolsBoundaries()
              : term || filters.area || filters.city || filters.neighborhood
              ? null
              : filters.map,
        },
        {
          cancelToken: token,
          params: {
            type: filters.type,
            status: filters.status,
            lastStatus: filters.lastStatus,
            //displayPublic: filters.displayPublic,
            class: filters.class,
            propertyType: filters.propertyType,
            sortBy: filters.sortBy,
            resultsPerPage: term
              ? 100
              : !map
              ? filters.resultsPerPage
              : map.zoom < 12
              ? 2000
              : map.zoom < 15
              ? 1500
              : map.zoom < 20
              ? 1000
              : 500,
            pageNum: filters.pageNum,
            minPrice:
              filters.status === "A"
                ? filters.minPrice === "0" || filters.minPrice === 0
                  ? null
                  : filters.minPrice
                : null,
            maxPrice:
              filters.status === "A"
                ? filters.maxPrice === "max" || filters.maxPrice === 0
                  ? null
                  : filters.maxPrice
                : null,
            minSoldPrice:
              filters.status === "U"
                ? filters.minPrice === "0" || filters.minPrice === 0
                  ? null
                  : filters.minPrice
                : null,
            maxSoldPrice:
              filters.status === "U"
                ? filters.maxPrice === "max" || filters.maxPrice === 0
                  ? null
                  : filters.maxPrice
                : null,
            minBeds: filters.minBeds === 0 ? null : filters.minBeds,
            maxBeds:
              filters.maxBeds === "any" || filters.maxBeds === "max"
                ? null
                : filters.maxBeds,
            minBaths: filters.minBaths === 0 ? null : filters.minBaths,
            maxBaths:
              filters.maxBaths === "any" || filters.maxBaths === "max"
                ? null
                : filters.maxBaths,
            minSqft: filters.minSqft === 0 ? null : filters.minSqft,
            maxSqft: filters.maxSqft === "max" ? null : filters.maxSqft,
            minParkingSpaces:
              filters.minParkingSpaces === 0 ? null : filters.minParkingSpaces,
            maxSoldDate: filters.maxSoldDate,
            minSoldDate: filters.minSoldDate,
            minListDate: filters.minListDate,
            maxListDate: filters.maxListDate,
            area: filters.city || filters.neighborhood ? null : filters.area,
            city: filters.city,
            operator: filters.city && filters.neighborhood ? "OR" : null,
            neighborhood: filters.neighborhood,
            keywords: term ? term : filters.keywords,
          },
        }
      ).catch((err) => {
        console.log(err);
        if (axios.isCancel(err)) {
        }
      });

      if ((lotWidth.min || lotWidth.max) && checkLotFilters()) {
        if (lotWidth.min && lotWidth.min > 0) {
          listings = listings.filter((l) => +l.lot.width >= lotWidth.min);
        }
        if (lotWidth.max && lotWidth.max !== "max") {
          listings = listings.filter((l) => +l.lot.width <= lotWidth.max);
        }
      }

      if ((lotDepth.min || lotDepth.max) && checkLotFilters()) {
        if (lotDepth.min) {
          listings = listings.filter((l) => +l.lot.depth >= lotDepth.min);
        }
        if (lotDepth.max && lotDepth.max !== "max") {
          listings = listings.filter((l) => +l.lot.depth <= lotDepth.max);
        }
      }

      if (locker && checkCondoFilters()) {
        listings =
          locker === "yes"
            ? listings.filter((l) => l.condominium.locker === "Owned")
            : listings.filter((l) => {
                if (!l.condominium.locker || l.condominium.locker === "None")
                  return true;
                return false;
              });
      }

      if (maintenance && checkCondoFilters()) {
        listings = listings.filter(
          (l) => l.condominium.fees.maintenance <= maintenance
        );
      }

      if (checkBasementFilter()) {
        if (!any && !sepEntrance && !finished && !walkOut) {
          listings = listings.filter(
            (l) =>
              !l.details.basement1 ||
              l.details.basement1 === "None" ||
              l.details.basement === ""
          );
        } else if (!any) {
          let basement1,
            basement2 = "";
          if (finished) basement1 = "Finished";
          if (walkOut && finished) basement1 = "Fin W/O";
          if (walkOut && !finished) basement2 = "W/O";
          if (sepEntrance && !finished) basement1 = "Sep Entrance";
          if (sepEntrance && finished) basement2 = "Sep Entrance";
          listings = listings.filter((l) =>
            l.details.basement1.includes(basement1)
          );
          if (basement2)
            listings = listings.filter((l) =>
              l.details.basement2.includes(basement2)
            );
        }
      }

      return listings;
    } catch (error) {
      return new Error(error.message);
    }
  } else {
    return;
  }
};

export const getListing = async (mlsNumber, token) => {
  let serverError = false;
  try {
    const { data } = await ListingAPI.get(`/${mlsNumber}`, {
      cancelToken: token,
    }).catch((err) => {
      if (err.response.status !== 404) serverError = true;
    });

    return data;
  } catch (err) {
    if (!serverError) {
      try {
        const { data } = await ListingAPI.get(`/archived/${mlsNumber}`);

        return data;
      } catch (err) {
        throw new Error("404");
      }
    } else {
      throw new Error("400");
    }
  }
};

export const getSimilarListing = async (mlsNumber, token) => {
  try {
    const { data } = await ListingAPI.get(`/${mlsNumber}/similar`, {
      cancelToken: token,
    }).catch((err) => {});
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const getSearchResults = async ({ term }) => {
  const filters = store.getState().filters.value;
  const query = Object.keys(filters)
    .map((key) => {
      if (filters[key] === null) return null;
      return key + "=" + JSON.stringify(filters[key]);
    })
    .filter((el) => el != null)
    .join("&")
    .replace(/["]+/g, "")
    .replace(/[']+/g, '"')
    .replace("&minPrice=0", "")
    .replace("&maxPrice=max", "")
    .replace("resultsPerPage=2500", "resultsPerPage=100");

  try {
    const {
      data: { listings },
    } = await MapAPI.post(
      `?${query}&fields=map,mlsNumber,status,listPrice,listDate,lastStatus,soldPrice,soldDate,address,images,details,type&keywords=${term}`
    );

    return listings;
  } catch (error) {
    return new Error(error.message);
  }
};

export const getLocations = async () => {
  try {
    const {
      data: { boards },
    } = await LocationsAPI.get(`/`);
    const areas = boards[0].classes[0].areas;
    const res = [
      {
        name: "Toronto",
        value: areas.filter((area) => area.name === "Toronto"),
        center: { lat: 43.6532, lng: -79.3832 },
      },
      {
        name: "Peel",
        value: areas.filter((area) => area.name === "Peel"),
        center: { lat: 43.6766, lng: -79.7848 },
      },
      {
        name: "York",
        value: areas.filter((area) => area.name === "York"),
        center: { lat: 43.89862, lng: -79.44853 },
      },
      {
        name: "Durham",
        value: areas.filter((area) => area.name === "Durham"),
        center: { lat: 43.8502, lng: -79.02803 },
      },
      {
        name: "Halton",
        value: areas.filter((area) => area.name === "Halton"),
        center: { lat: 43.5325, lng: -79.8745 },
      },
    ];
    return res;
  } catch (error) {
    return new Error(error.message);
  }
};

export const getSearchCount = async ({ filters, extra }) => {
  const {
    lotWidth,
    lotDepth,
    locker,
    basement: { any, sepEntrance, walkOut, finished },
    maintenance,
  } = extra;

  const checkLotFilters = () => {
    if (!filters.propertyType) return false;
    const intersections = _.intersection(VALID_LOT_TYPES, filters.propertyType)
      .length;
    if (intersections === filters.propertyType.length) return true;
    return false;
  };

  const checkCondoFilters = () => {
    if (!filters.propertyType) return false;
    const intersections = _.intersection(
      VALID_LOCKER_TYPES,
      filters.propertyType
    ).length;
    if (intersections === filters.propertyType.length) return true;
    return false;
  };

  const checkBasementFilter = () => {
    if (!filters.propertyType) return false;
    const intersections = _.intersection(
      INVALID_BASEMENT_TYPES,
      filters.propertyType
    ).length;
    if (intersections === 0) return true;
    return false;
  };

  if (filters.type !== "pre-construction") {
    try {
      let {
        data: { listings },
      } = await MapAPI.post(
        `?fields=lastStatus`,
        { map: filters.map },
        {
          params: {
            type: filters.type,
            status: filters.status,
            lastStatus: filters.lastStatus,
            //displayPublic: filters.displayPublic,
            class: filters.class,
            propertyType: filters.propertyType,
            sortBy: filters.sortBy,
            resultsPerPage: filters.resultsPerPage,
            pageNum: filters.pageNum,
            minPrice: filters.minPrice === "0" ? null : filters.minPrice,
            maxPrice: filters.maxPrice === "max" ? null : filters.maxPrice,
            minBeds: filters.minBeds === 0 ? null : filters.minBeds,
            maxBeds: filters.maxBeds === "any" ? null : filters.maxBeds,
            minBaths: filters.minBaths === 0 ? null : filters.minBaths,
            maxBaths: filters.maxBaths === "any" ? null : filters.maxBaths,
            minSqft: filters.minSqft === 0 ? null : filters.minSqft,
            maxSqft: filters.maxSqft === "any" ? null : filters.maxSqft,
            minParkingSpaces:
              filters.minParkingSpaces === 0 ? null : filters.minParkingSpaces,
            maxSoldDate: filters.maxSoldDate,
            minSoldDate: filters.minSoldDate,
            minListDate: filters.minListDate,
            maxListDate: filters.maxListDate,
            area: filters.city || filters.neighborhood ? null : filters.area,
            city: filters.city,
            operator: filters.city && filters.neighborhood ? "OR" : null,
            neighborhood: filters.neighborhood,
            keywords: filters.keywords,
          },
        }
      );

      if ((lotWidth.min || lotWidth.max) && checkLotFilters()) {
        if (lotWidth.min && lotWidth.min > 0) {
          listings = listings.filter((l) => +l.lot.width >= lotWidth.min);
        }
        if (lotWidth.max && lotWidth.max !== "max") {
          listings = listings.filter((l) => +l.lot.width <= lotWidth.max);
        }
      }

      if ((lotDepth.min || lotDepth.max) && checkLotFilters()) {
        if (lotDepth.min) {
          listings = listings.filter((l) => +l.lot.depth >= lotDepth.min);
        }
        if (lotDepth.max && lotDepth.max !== "max") {
          listings = listings.filter((l) => +l.lot.depth <= lotDepth.max);
        }
      }

      if (locker && checkCondoFilters()) {
        listings =
          locker === "yes"
            ? listings.filter((l) => l.condominium.locker === "Owned")
            : listings.filter((l) => {
                if (!l.condominium.locker || l.condominium.locker === "None")
                  return true;
                return false;
              });
      }

      if (maintenance && checkCondoFilters()) {
        listings = listings.filter(
          (l) => l.condominium.fees.maintenance <= maintenance
        );
      }

      if (checkBasementFilter()) {
        if (!any && !sepEntrance && !finished && !walkOut) {
          listings = listings.filter(
            (l) =>
              !l.details.basement1 ||
              l.details.basement1 === "None" ||
              l.details.basement === ""
          );
        } else if (!any) {
          let basement1,
            basement2 = "";
          if (finished) basement1 = "Finished";
          if (walkOut && finished) basement1 = "Fin W/O";
          if (walkOut && !finished) basement2 = "W/O";
          if (sepEntrance && !finished) basement1 = "Sep Entrance";
          if (sepEntrance && finished) basement2 = "Sep Entrance";
          listings = listings.filter((l) =>
            l.details.basement1.includes(basement1)
          );
          if (basement2)
            listings = listings.filter((l) =>
              l.details.basement2.includes(basement2)
            );
        }
      }

      return listings;
    } catch (error) {
      return new Error(error.message);
    }
  } else {
    return;
  }
};

export const createClient = async (user) => {
  let response;
  let repliersID;
  try {
    response = await ClientsAPI.get(`?email=${user.email}`);
  } catch (err) {
    throw new Error("Something went wrong!");
  }
  if (response) {
    if (response.data.clients.length === 0) {
      let userInfo = {
        fname: user.givenName,
        lname: user.familyName,
        email: user.email,
        agentId: 95032,
      };
      response = await ClientsAPI.post("/", userInfo);
      repliersID = response.data.clientId;
    } else {
      repliersID = response.data.clients[0].clientId;
    }
  } else {
    throw new Error("Something went wrong!");
  }
  return repliersID;
};

export const removeClient = async (clientId) => {
  try {
    await ClientsAPI.delete(`/${clientId}`);
  } catch (error) {
    return new Error(error.message);
  }
};

export const updateClient = async ({ clientId, client }) => {
  try {
    await ClientsAPI.patch(`/${clientId}`, { client });
  } catch (error) {
    return new Error(error.message);
  }
};

export const createFavourite = async (clientId, mlsNumber) => {
  const { data } = await FavouritesAPI.post("", {
    clientId,
    mlsNumber,
  });

  return data.favoriteId;
};

export const removeFavourite = async (favouriteId) => {
  await FavouritesAPI.delete(`/${favouriteId}`);
};

export const createSearch = async (clientId, search) => {
  const {
    minBeds,
    maxBeds,
    minBaths,
    maxBaths,
    area,
    city,
    neighborhood,
    minPrice,
    maxPrice,
    propertyTypes,
    style,
    map,
    type,
    class: propertyClass,
  } = search;

  const params = {};
  params.minPrice = minPrice === "0" ? 0 : minPrice;
  params.maxPrice = maxPrice === "max" ? 100000000 : maxPrice;
  if (minBeds) params.minBeds = minBeds;
  if (maxBeds && maxBeds !== "any") params.maxBeds = maxBeds;
  if (minBaths) params.minBaths = minBaths;
  if (maxBaths && maxBaths !== "any") params.maxBaths = maxBaths;
  if (map && map[0]?.length <= 5 && area && city && neighborhood) {
    params.areas = area;
    params.cities = city;
    params.neighborhoods = neighborhood;
  } else if (map) {
    params.map = map;
  }
  if (propertyTypes) params.propertyTypes = propertyTypes;
  if (style) params.style = style;

  const { data } = await SearchAPI.post("", {
    agentId: 95032,
    clientId: +clientId,
    type,
    class: propertyClass,
    ...params,
  });

  return data.searchId;
};

export const removeSearch = async (searchId) => {
  await SearchAPI.delete(`/${searchId}`);
};

export const checkFavouritesUpdates = async () => {
  const favourites = await getFavouriteItems();
  let updates = [];
  for (let i = 0; i < favourites.length; i++) {
    const listing = JSON.parse(favourites[i].listing);
    const latestUpdate = await getListing(listing.mlsNumber);
    let differences = {};
    if (latestUpdate.status !== listing.status)
      differences.status = {
        previous: listing.status,
        current: latestUpdate.status,
      };
    if (latestUpdate.lastStatus !== listing.lastStatus)
      differences.lastStatus = {
        previous: listing.lastStatus,
        current: latestUpdate.lastStatus,
      };
    if (+latestUpdate.listPrice !== +listing.listPrice)
      differences.listPrice = {
        previous: +listing.listPrice,
        current: +latestUpdate.listPrice,
      };
    if (+latestUpdate.soldPrice !== +listing.soldPrice)
      differences.soldPrice = {
        previous: +listing.soldPrice,
        current: +latestUpdate.soldPrice,
      };
    if (Object.keys(differences).length !== 0)
      updates.push({ mlsNumber: listing.mlsNumber, updates: differences });
  }
  return updates;
};
