import Select from "../../Form/Select/select.component";
import RangeFilter from "../../RangeFilter/range-filter.component";
import { bedsRange } from "../../Filters/data/filters.data";
import styles from "../filters-menu.module.scss";
import withFilters from "../../../HOC/withFilters/with-filters";

const BedsFilterItem = ({
  filters: { minBeds, maxBeds },
  updateSingleFilter,
  buttonStyle,
  tabName,
  tab,
  setTab,
  disabled,
}) => {
  const onChangeHandler = ({ type, value }) => {
    switch (type) {
      case "min":
        updateSingleFilter({ minBeds: value });
        break;
      case "max":
        updateSingleFilter({ maxBeds: value });
        break;
      default:
        return;
    }
  };

  return (
    <div className={styles.item}>
      <Select
        buttonStyle={buttonStyle}
        title="Beds"
        open={tab === tabName}
        onClick={() => setTab(tabName)}
        dropdown={false}
        disabled={disabled}
      />
      <RangeFilter
        type="rooms"
        open={tab === tabName}
        range={bedsRange}
        value={{ min: minBeds, max: maxBeds }}
        dropdown={false}
        onChange={onChangeHandler}
        style={{ marginBottom: "1rem" }}
      />
    </div>
  );
};

export default withFilters(BedsFilterItem);
