import { useHistory } from "react-router-dom";
import { IonIcon } from "@ionic/react";
import { duplicateOutline } from "ionicons/icons";
import EditIcon from "../../../../assets/svg/REA103_Icons-01a_Edit saved search.svg";
import styles from "./tour-item.module.scss";
import { getMainClient } from "../../../../utils/functions";

const TourItem = ({ item, index }) => {
  const history = useHistory();
  return (
    <div key={index} className={styles.item}>
      <div>{item.date}</div>
      <div
        className={styles.title}
        onClick={() => history.push(`/tours/${item.id}`)}
      >
        {item.title}
      </div>
      <div>
        {getMainClient(item.membersData)
          ? getMainClient(item.membersData).name
          : "---"}
      </div>
      <div>{`${item.startTime ? item.startTime : "-"} - ${
        item.endTime ? item.endTime : "-"
      }`}</div>
      <div>
        {item.showingAgent &&
          `${item.showingAgent.givenName} ${item.showingAgent.familyName}`}
      </div>
      <div className={styles.stops}>{item.tourItems.items.length}</div>
      <div
        className={`${styles.status} ${
          item.status.includes("ready") && styles.green
        }  ${item.status.includes("pending") && styles.yellow}  ${
          item.status.includes("cancelled") && styles.orange
        } ${item.status.includes("draft") && styles.brown} ${
          item.status.includes("archived") && styles.purple
        }`}
      >
        {item.status}
      </div>
      <div className={styles.tab}>
        <IonIcon
          className={styles.icon}
          icon={duplicateOutline}
          onClick={() =>
            window.open(`${history.location.pathname}/${item.id}`, "_blank")
          }
        />
      </div>
      <div>
        <IonIcon
          className={styles.icon}
          src={EditIcon}
          onClick={() => history.push(`/tours/${item.id}`)}
        />
      </div>
    </div>
  );
};

export default TourItem;
