import millify from "millify";

const IGNORE_LIST = [
  "displayPublic",
  "displayAddressOnInternet",
  "map",
  "hasImages",
  "sortBy",
  "resultsPerPage",
  "pageNum",
];

export const render = (filters, extra) => {
  let text = "";
  const {
    minPrice,
    maxPrice,
    class: propertyClass,
    propertyType,
    status,
    type,
    minBeds,
    maxBeds,
    minBaths,
    maxBaths,
    minParkingSpaces,
    area,
    city,
    neighborhood,
  } = filters;

  text += `$${millify(+minPrice)}-${
    maxPrice === "max" ? "max" : millify(+maxPrice)
  }, `;

  propertyClass.map((p) => (text += p + ", "));
  if (propertyType) propertyType.map((p) => (text += p + ", "));

  if (status === "A" && type === "sale") text += "For sale, ";
  if (status === "U" && type === "sale") text += "Sold, ";
  if (status === "A" && type === "lease") text += "For lease, ";
  if (status === "U" && type === "lease") text += "Leased, ";
  if (minBeds && maxBeds) text += `${minBeds}-${maxBeds} beds, `;
  if (minBeds && !maxBeds) text += `${minBeds}+ beds, `;
  if (!minBeds && maxBeds) text += `0-${maxBeds} beds, `;
  if (minBaths && maxBaths) text += `${minBaths}-${maxBaths} baths, `;
  if (minBaths && !maxBaths) text += `${minBaths}+ baths, `;
  if (!minBaths && maxBaths) text += `0-${maxBaths} baths, `;
  if (minParkingSpaces) text += `${minParkingSpaces}+ parkings`;
  if (area) text += `${area}, `;
  if (city) city.forEach((c) => (text += `${city}, `));
  if (neighborhood) neighborhood.forEach((n) => (text += `${n}, `));

  return text.substring(0, text.length - 2);
};
