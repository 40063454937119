import { useState, useEffect, useRef, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../Form/Button/button.component";
import FederatedBtn from "./component/FederatedBtn/federated-btn.component";
import Input from "../../../../Form/Input/input.component";
import Modal from "../../../../Modal/modal.component";
import {
  clearErrors,
  emailSignInStart,
  confirmSignUpStart,
} from "../../../../../redux/user/user.actions";
import styles from "./sign-in.module.scss";
import {
  selectUserError,
  selectUserState,
} from "../../../../../redux/user/user.selectors";
import { resendConfirmationCode } from "../../../../../amplify/auth.utils";

const SignIn = ({ redirect }) => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState({ open: false, type: null });
  const [loading, setLoading] = useState(false);
  const error = useSelector(selectUserError);
  const state = useSelector(selectUserState);
  const [signinfailure, setSignInFailure] = useState();

  const inputRefs = useRef({
    email: createRef(),
    password: createRef(),
  });

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (value, name) => {
    inputRefs.current[name].current.validate();
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const submitSignIn = () => {
    let isValid = true;
    for (const field of Object.values(inputRefs.current)) {
      const validationResult = field.current.validate(true);
      if (!validationResult) isValid = false;
    }
    if (isValid) {
      setLoading(true);
      const { email, password } = formData;
      dispatch(
        emailSignInStart({
          username: email,
          password,
        })
      );
    } else {
      return;
    }
  };

  useEffect(() => {
    setSignInFailure(error);
    setLoading(false);
  }, [error, state]);

  useEffect(() => {
    dispatch(clearErrors());
  }, [dispatch]);

  if (signinfailure && signinfailure["code"] === "UserNotConfirmedException") {
    resendConfirmationCode(formData.email);
  }

  return (
    <div className={styles.loginForm}>
      {signinfailure && signinfailure["code"] === "UserNotConfirmedException" && (
        <Modal
          isOpen={true}
          type="confirmEmail"
          closable={false}
          fromSignIn={true}
          data={{
            user: { username: formData.email, password: formData.password },
            onSubmit: (code) =>
              dispatch(
                confirmSignUpStart({
                  username: formData.email,
                  password: formData.password,
                  code,
                })
              ),
            onResend: () => resendConfirmationCode(formData.email),
          }}
        />
      )}
      {modal.open && (
        <Modal
          type={modal.type}
          close={() => setModal({ ...modal, open: false })}
          setType={(type) => setModal({ ...modal, type })}
        />
      )}

      <form onSubmit={(e) => e.preventDefault()}>
        <div className={styles.title}>
          Nice to see you! <span>Shall we jump right in?</span>
        </div>
        <FederatedBtn />
        <div>
          <div className={styles.option}>or sign in with your email</div>
          <div className={styles.line}></div>
        </div>
        <div className={styles.inputs}>
          <Input
            name="email"
            label="email"
            placeholder="email"
            mode="email"
            required
            ref={inputRefs.current.email}
            value={formData["email"]}
            onChange={(value, name) => handleChange(value.toLowerCase(), name)}
            validation="required|email"
          />
          <Input
            name="password"
            label="password"
            placeholder="password"
            type="password"
            required
            ref={inputRefs.current.password}
            value={formData["password"]}
            onChange={(value, name) => handleChange(value, name)}
            validation="required"
          />
        </div>
        {error && error !== "The user is not authenticated" && (
          <div className={styles.error}>
            <span>Invalid email or password</span>
          </div>
        )}
        <div className={styles.rememberRow}>
          <span
            className={styles.href}
            onClick={() => setModal({ open: true, type: "forgotPassword" })}
          >
            Forgot password?
          </span>
        </div>
        <div>
          <Button
            title="Sign in"
            type="purple"
            style={{
              border: "1px solid #7158AD",
              width: "100%",
              height: "5rem",
            }}
            buttonType="submit"
            onClick={submitSignIn}
            loading={loading}
          />
        </div>
      </form>
      <div className={styles.register}>
        Don’t have an account?
        <span className={styles.href} onClick={redirect}>
          Create account
        </span>
      </div>
    </div>
  );
};

export default SignIn;
