import { useState, useEffect } from "react";
import { IonIcon, IonButton } from "@ionic/react";
import {
  closeOutline,
  peopleOutline,
  personAddOutline,
  personOutline,
} from "ionicons/icons";
import CardContainer from "../../../Tours/component/CardContainer/card-container.component";
import DetailsHeader from "../../../../components/TourDetailsHeader/tour-details-header.module";
import useComponentVisible from "../../../../hooks/useComponentVisible";
import styles from "./tour-attendees.module.scss";
import Select from "../../../../components/Form/Select/select.component";
import DropdownContainer from "../../../../components/Form/DropdownContainer/dropdown-container.component";
import {
  getAgents,
  getTourClients,
  getClients,
} from "../../../../amplify/graphql.utils";
import SearchableSelect from "../../../../components/Form/SearchableSelect/searchable-search.component";

const TourAttendees = ({ isOpen, setIsOpen, tour, update }) => {
  const { members: attendees, agent, showingAgent: showing, shared } = tour;

  const [client, setClient] = useState();
  const [guests, setGuests] = useState([]);
  const [leadAgent, setLeadAgent] = useState(agent);
  const [showingAgent, setShowingAgent] = useState(showing);
  const [agents, setAgents] = useState([]);
  const [tempGuests, setTempGuests] = useState([]);
  const [clientsList, setClientsList] = useState();

  const {
    ref: refAgent,
    isOpen: isOpenAgent,
    setIsOpen: setIsOpenAgent,
  } = useComponentVisible(false);

  const {
    ref: refShowingAgent,
    isOpen: isOpenShowingAgent,
    setIsOpen: setIsOpenShowingAgent,
  } = useComponentVisible(false);

  useEffect(() => {
    const fetchAllClinets = async () =>
      setClientsList(await getClients(agent.brokerageId));

    const fetchClients = async () => {
      try {
        const clients = await getTourClients({ clients: attendees });
        if (clients && clients.length) {
          setClient(clients.filter((c) => c.id === attendees[0])[0]);
          setGuests(clients.filter((c) => c.id !== attendees[0]));
        }
      } catch (err) {
        console.log(err);
      }
    };

    const fetchAgents = async () => {
      try {
        const agents = await getAgents();
        setAgents(agents);
      } catch (err) {
        console.log(err);
      }
    };

    if (isOpen) {
      fetchAllClinets();
      fetchClients();
      fetchAgents();
    }
  }, [agent, attendees, isOpen]);

  const handleUpdate = () => {
    update({
      client,
      guests,
      tempGuests,
      showingAgent,
      leadAgent,
    });
    setTempGuests([]);
  };

  const handleChange = async (val) => {
    console.log("Hiii");
    setClientsList(await getClients(agent.brokerageId, val));
  };

  return (
    <CardContainer>
      <div className={styles.attendees}>
        <DetailsHeader
          title="Tour attendees"
          icon={peopleOutline}
          isVisible={true}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
        {isOpen && (
          <div className={styles.inputs}>
            <SearchableSelect
              title="Select the main client"
              type="radio"
              // parentRef={selfRef}
              items={clientsList && clientsList.filter((c) => !c.deactivated)}
              selected={client}
              setSelected={setClient}
              label={client ? `Client: ${client.name}` : "Select client"}
              onSave={(client) => setClient(client)}
              onChange={handleChange}
              icon={client ? personOutline : personAddOutline}
              style={{ "--background": "white" }}
            />

            {guests.length > 0 && (
              <div className={styles.guests}>
                {guests
                  .filter((g) => g !== null)
                  .map((g, i) => (
                    <div className={styles.guest} key={i}>
                      <IonIcon className={styles.icon} icon={personOutline} />
                      <span className={styles.label}>
                        Additional guest: {g.name}
                      </span>
                      <IonIcon
                        className={styles.closeIcon}
                        icon={closeOutline}
                        onClick={() =>
                          setGuests(guests.filter((guest) => guest.id !== g.id))
                        }
                      />
                    </div>
                  ))}
              </div>
            )}

            {client && (
              <SearchableSelect
                title="Select additional guests"
                type="checkbox"
                // parentRef={selfRef}
                items={
                  clientsList &&
                  clientsList
                    .filter((c) => !c.deactivated)
                    .filter((c) => c.id !== client.id)
                }
                selected={guests}
                setSelected={setGuests}
                onChange={handleChange}
                label="Add additional guests"
                icon={personAddOutline}
              />
            )}

            <div ref={refAgent} className={styles.container}>
              <Select
                label="Lead agent"
                title={leadAgent && leadAgent.name}
                style={{ height: "4rem", width: "100%" }}
                buttonStyle={{ height: "100%" }}
                open={isOpenAgent}
                onClick={() => setIsOpenAgent(!isOpenAgent)}
              />
              <DropdownContainer
                open={isOpenAgent}
                style={{
                  position: "absolute",
                  top: "auto",
                  left: "auto",
                  // width: "24rem",
                  height: "15rem",
                }}
              >
                {agents.map((agent) => (
                  <div
                    key={agent.id}
                    onClick={() => {
                      setLeadAgent(agent);
                      setIsOpenAgent(false);
                    }}
                    className={styles.agentDropdown}
                  >
                    {agent.name}
                  </div>
                ))}
              </DropdownContainer>
            </div>

            <div ref={refShowingAgent} className={styles.container}>
              <Select
                label="Showing agent"
                title={showingAgent && showingAgent.name}
                style={{ height: "4rem", width: "100%" }}
                buttonStyle={{ height: "100%" }}
                open={isOpenShowingAgent}
                onClick={() => setIsOpenShowingAgent(!isOpenShowingAgent)}
              />
              <DropdownContainer
                open={isOpenShowingAgent}
                style={{
                  position: "absolute",
                  top: "auto",
                  left: "auto",
                  // width: "24rem",
                  height: "15rem",
                }}
              >
                {agents.map((agent) => (
                  <div
                    key={agent.id}
                    onClick={() => {
                      setShowingAgent(agent);
                      setIsOpenShowingAgent(false);
                    }}
                    className={styles.agentDropdown}
                  >
                    {agent.name}
                  </div>
                ))}
              </DropdownContainer>
            </div>

            <IonButton
              shape="round"
              expand="block"
              className={styles.button}
              disabled={
                !leadAgent ||
                !client ||
                !showingAgent ||
                !tour ||
                !tour.status ||
                tour.status === "archived"
              }
              onClick={handleUpdate}
            >
              Save changes
            </IonButton>

            <div className={styles.note}>
              {shared == "shared"
                ? "These saved changes will be visible to the client."
                : "The tour will not be available to the client until you share it."}
            </div>
          </div>
        )}
      </div>
    </CardContainer>
  );
};
export default TourAttendees;
