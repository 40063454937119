import { useState, useEffect, useCallback, memo, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  IonPage,
  IonContent,
  IonButton,
  IonIcon,
  IonAlert,
  IonModal,
  IonList,
  IonReorderGroup,
  IonItem,
  IonReorder,
  IonFooter,
  IonLabel,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  IonLoading,
} from "@ionic/react";
import {
  eyeOutline,
  peopleOutline,
  eyeOffOutline,
  chatbubblesOutline,
  arrowUndoOutline,
  navigateOutline,
} from "ionicons/icons";
import AppHeader from "../../components/Header/header.component";
import styles from "./agent-tour.module.scss";
import { addOutline } from "ionicons/icons";
import TourElement from "./component/TourElement/tour-element.component";
import AddShowing from "./component/AddShowing/add-showing.component";
import TourElementsContent from "./component/TourElementsContent/tour-elements-content.component";
import {
  fetchTourItems,
  getSingleTour,
  shareTour as shareTourWithClient,
  updateTourListingItem,
  updateTourListingOrder,
} from "../../../amplify/graphql.utils";
import LoadingFullPage from "../../../components/Loading/loading-full-page.component";
import TourProperty from "../../../pages/Tour/component/TourProperty/tour-property.component";
import withAuthentication from "../../../HOC/withAuthentication/with-authentication";
import { updateToast } from "../../../redux/ui/ui.actions";
import { API, graphqlOperation } from "aws-amplify";
import { onUpdateTourItemByTourId } from "../../../graphql/subscriptions";
import { buildAddress } from "../../../utils/functions";
import { getCurrentLocation } from "../../../components/Map/map-utils";
import Refresher from "../../components/Refresher/refresher.component";
import { useHistory } from "react-router-dom";

const AppAgentTour = ({
  match: {
    params: { tourId },
  },
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const pageRef = useRef();
  const slideRef = useRef();
  const modalRef = useRef();
  const [listings, setListings] = useState([]);
  const [tour, setTour] = useState();
  const [items, setItems] = useState([]);
  const [originalSort, setOriginalSort] = useState();
  const [loading, setLoading] = useState(false);
  const [reverting, setReverting] = useState(false);
  const [mapLoading, setMapLoading] = useState(false);
  const [shareTour, setShareTour] = useState(false);
  const [addShowing, setAddShowing] = useState(false);
  const [type, setType] = useState("");
  const [showTourElements, setShowTourElements] = useState(false);
  const [alert, setAlert] = useState(false);

  const sortItems = async (updatedItems) => {
    updatedItems.forEach(async (item, i) => {
      try {
        await updateTourListingOrder({ id: item.id, order: i + 1 });
        //fetchTour();
      } catch (err) {}
      setLoading(false);
    });
  };
  const reorder = async ({ detail: { complete } }) => {
    const updatedItems = complete(items);
    setItems(updatedItems);
    sortItems(updatedItems);
  };

  const fetchTour = useCallback(
    async (inBackground = false) => {
      if (!inBackground) setLoading(true);

      try {
        const tour = await getSingleTour(tourId);

        if (!tour) {
          history.replace("/tabs/agenttours", { direction: "root" });
          return;
        }

        setTour(tour);
        setItems(
          tour.tourItems.items.sort((a, b) => {
            if (a.order > b.order) return 1;
            if (a.order < b.order) return -1;
            return 0;
          })
        );
        if (!originalSort) {
          const order = [];
          tour.tourItems.items.forEach((item) =>
            order.push({ id: item.id, order: item.order })
          );
          setOriginalSort(order);
        }
      } catch (err) {
        console.log(err);
      }
      setType(null);
      setShowTourElements(false);
      setLoading(false);
    },

    [history, originalSort, tourId]
  );
  useEffect(() => {
    setItems();
    setTour(null);
    fetchTour();
  }, [fetchTour, tourId]);

  useEffect(() => {
    if (location.search === "?reload") fetchTour();
  }, [fetchTour, location.search]);

  useEffect(() => {
    let subscription;
    const subscribe = async () => {
      subscription = await API.graphql(
        graphqlOperation(onUpdateTourItemByTourId, { tourId })
      ).subscribe({
        next: async () => {
          const items = await fetchTourItems(tourId);
          setItems(
            items.sort((a, b) => {
              if (a.order > b.order) return 1;
              if (a.order < b.order) return -1;
              return 0;
            })
          );
        },
      });
    };

    subscribe();
    return () => subscription && subscription.unsubscribe();
  }, [tourId]);

  const handleShare = async () => {
    setReverting(true);
    try {
      await shareTourWithClient(tour.id);
      setShareTour(true);
      await fetchTour();
    } catch (err) {
      dispatch(
        updateToast({
          open: true,
          message: "Something went wrong!",
          type: "error",
        })
      );
    }
    setReverting(false);
  };

  const revertOrder = async () => {
    if (originalSort && originalSort.length) {
      setReverting(true);
      for (let i = 0; i < originalSort.length; i++) {
        await updateTourListingOrder({
          id: originalSort[i].id,
          order: originalSort[i].order,
        });
      }
      await fetchTour(true);
      setReverting(false);
    }
  };

  const handleMapView = async () => {
    setMapLoading(true);
    if (listings.length) {
      const currentLocation = await getCurrentLocation();
      let locations = "";
      if (currentLocation)
        locations += `${currentLocation.latitude},${currentLocation.longitude}/`;

      if (tour.meetupLocation) locations += `${tour.meetupLocation}/`;
      listings.forEach((item) => {
        const l = items.find((i) => i.mlsNumber === item.mlsNumber);
        if (
          !["skipped", "cancelled", "completed", "rejected"].includes(l.status)
        )
          locations += `${buildAddress(item.address, true)}/`;
      });

      window.open(
        `https://www.google.com/maps/dir/${locations.slice(
          0,
          locations.length - 1
        )}`,
        "_blank"
      );
    }
    setMapLoading(false);
  };

  const handleUpdateStatus = async ({ id, status }) => {
    try {
      const item = await updateTourListingItem({ id, status });
      const temp = [...items];
      const index = temp.findIndex((i) => i.id === id);
      temp[index] = item;
      setItems(temp);
    } catch (err) {}
    if (slideRef.current) {
      await slideRef.current.closeOpened();
    }
  };

  const handleRefresh = async (ref) => {
    await fetchTour(true);
    ref.complete();
  };

  return (
    <IonPage ref={pageRef}>
      <AppHeader
        title="Tour summary"
        hasRightButton={false}
        backUrl="/agenttours"
      />
      <IonLoading
        isOpen={reverting}
        animated
        spinner="circular"
        cssClass={styles.loader}
        translucent={true}
        keyboardClose={false}
      />
      <IonContent className={styles.appAgentTour}>
        <Refresher onRefresh={handleRefresh} />
        {loading || !tour ? (
          <LoadingFullPage />
        ) : (
          <>
            <IonAlert
              isOpen={shareTour}
              onDidDismiss={() => setShareTour(false)}
              cssClass="my-custom-class"
              header={"Share with client"}
              message={
                "Tour has been shared. All further saved changes will be updated live in their tour."
              }
              buttons={["OK"]}
            />

            <IonModal
              isOpen={addShowing}
              onDidDismiss={() => setAddShowing(false)}
              presentingElement={pageRef.current}
            >
              <AddShowing
                setIsOpen={setAddShowing}
                tour={tour}
                reload={fetchTour}
                nextListingOrder={tour && tour.tourItems.items.length + 1}
              />
            </IonModal>

            <IonModal
              ref={modalRef}
              isOpen={showTourElements}
              onDidDismiss={() => setShowTourElements(false)}
              presentingElement={pageRef.current}
            >
              <TourElementsContent
                tour={tour}
                title="title"
                setIsOpen={setShowTourElements}
                type={type}
                reload={fetchTour}
                pageRef={pageRef.current}
                selfRef={modalRef.current}
              />
            </IonModal>

            <div className={styles.body}>
              <div className={styles.title}>{tour && tour.title}</div>
              <IonButton
                shape="round"
                expand="block"
                className={styles.button}
                onClick={() => setAddShowing(true)}
                disabled={!tour || !tour.status || tour.status === "archived"}
              >
                <IonIcon icon={addOutline} />
                Add a showing
              </IonButton>
              <div className={styles.label}>
                Tour schedule
                <IonButton
                  className={styles.mapBtn}
                  onClick={handleMapView}
                  disabled={
                    !items || !items.length || items.length !== listings.length
                  }
                >
                  <IonIcon icon={navigateOutline} className={styles.mapIcon} />
                  <IonLabel className={styles.mapLabel}>
                    {mapLoading ? "loading..." : "Full directions"}
                  </IonLabel>
                </IonButton>
              </div>
              <div className={styles.items}>
                <IonList className={styles.reorder}>
                  {!tour ? (
                    <LoadingFullPage />
                  ) : (
                    <IonReorderGroup
                      disabled={
                        !tour || !tour.status || tour.status === "archived"
                      }
                      onIonItemReorder={reorder}
                      style={{ paddingLeft: 0 }}
                    >
                      {items &&
                        items.length > 0 &&
                        items.map((item) => (
                          <IonItemSliding key={item.id} ref={slideRef}>
                            <IonItem className={styles.item} lines="full">
                              <TourProperty
                                listings={listings}
                                setListings={setListings}
                                item={item}
                                order={item.order}
                                reload={fetchTour}
                                app={true}
                              />
                              <IonReorder
                                slot="end"
                                className={styles.toggle}
                              />
                            </IonItem>
                            <IonItemOptions className={styles.options}>
                              <IonItemOption
                                color="success"
                                className={`${styles.option} ${styles.completed}`}
                                onClick={() =>
                                  handleUpdateStatus({
                                    id: item.id,
                                    status: "completed",
                                  })
                                }
                              >
                                Completed
                              </IonItemOption>
                              <IonItemOption
                                color="warning"
                                className={`${styles.option} ${styles.skipped}`}
                                onClick={() =>
                                  handleUpdateStatus({
                                    id: item.id,
                                    status: "skipped",
                                  })
                                }
                              >
                                Skipped
                              </IonItemOption>
                              <IonItemOption
                                color="danger"
                                className={`${styles.option} ${styles.cancelled}`}
                                onClick={() =>
                                  handleUpdateStatus({
                                    id: item.id,
                                    status: "cancelled",
                                  })
                                }
                              >
                                Cancelled
                              </IonItemOption>
                            </IonItemOptions>
                          </IonItemSliding>
                        ))}
                    </IonReorderGroup>
                  )}
                </IonList>
              </div>
              {originalSort && items && items.length > 0 && (
                <div className={styles.revert}>
                  <IonItem
                    className={styles.revertItem}
                    lines="none"
                    onClick={revertOrder}
                  >
                    <IonIcon
                      className={styles.revertIcon}
                      icon={arrowUndoOutline}
                      slot="start"
                    />
                    <IonLabel className={styles.revertLabel}>
                      Revert showings to original order
                    </IonLabel>
                  </IonItem>
                </div>
              )}

              <div className={styles.tourElement}>Tour elements</div>
              <div>
                <TourElement
                  tour={tour}
                  icon={peopleOutline}
                  title="Shared with client"
                  onClick={handleShare}
                  shareStatus
                />
                <TourElement
                  tour={tour}
                  icon={eyeOutline}
                  title="Tour details"
                  onClick={() => {
                    setType("tourDetails");
                    setShowTourElements(true);
                  }}
                />
                <TourElement
                  icon={peopleOutline}
                  title="Tour attendees"
                  onClick={() => {
                    setType("tourAttendees");
                    setShowTourElements(true);
                  }}
                />
                <TourElement
                  icon={eyeOffOutline}
                  title="Agent details"
                  onClick={() => {
                    setType("agentDetails");
                    setShowTourElements(true);
                  }}
                />
                <TourElement
                  icon={chatbubblesOutline}
                  title="Quick contact"
                  onClick={() => {
                    setType("quickContact");
                    setShowTourElements(true);
                  }}
                />
              </div>
            </div>
          </>
        )}
      </IonContent>
      {tour &&
        tour.shared === "unshared" &&
        tour.status !== "archived" &&
        tour.clients &&
        tour.clients.items.length > 0 && (
          <IonFooter className={styles.share}>
            <IonButton
              shape="round"
              expand="block"
              className={styles.shareBtn}
              onClick={() => setAlert(true)}
            >
              <IonIcon icon={peopleOutline} />
              Share with client
            </IonButton>
          </IonFooter>
        )}
      <IonAlert
        isOpen={alert}
        onDidDismiss={() => setAlert(false)}
        cssClass={styles.alert}
        header={"Share with client"}
        animated
        message="Are you sure you want to share this tour with client?"
        buttons={[
          {
            text: " Cancel",
            role: "cancel",
            cssClass: styles.cancel,
            handler: () => setAlert(false),
          },
          {
            text: "Share",
            role: "Ok",
            handler: handleShare,
          },
        ]}
      />
    </IonPage>
  );
};

export default memo(withAuthentication(AppAgentTour));
