import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { documentTextOutline } from "ionicons/icons";
import CardContainer from "../../../Tours/component/CardContainer/card-container.component";
import DetailsHeader from "../../../../components/TourDetailsHeader/tour-details-header.module";
import styles from "./general-notes.module.scss";
import Button from "../../../../components/Form/Button/button.component";
import { updateClient } from "../../../../amplify/graphql.utils";
import { updateToast } from "../../../../redux/ui/ui.actions";
import { IonAlert } from "@ionic/react";

const GeneralNotes = ({ title, client, isApp }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [note, setNote] = useState(client.toursNote ? client.toursNote : "");
  const [showAlert, setShowAlert] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await updateClient({ id: client.id, updates: { toursNote: note } });
      dispatch(
        updateToast({
          open: true,
          type: "success",
          message: "Note updated successfully.",
        })
      );
    } catch (err) {
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message: "Something went wrong!",
        })
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    if (note != client.toursNote) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  }, [showNotes]);

  useEffect(() => {
    if (note != client.toursNote && history.location.pathname !== "/tours") {
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  }, [history.location.pathname]);

  return (
    <>
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        cssClass={styles.alert}
        header={"Save your note"}
        message={
          "Do you want to save your note changes before leaving this page?"
        }
        buttons={[
          {
            text: "Dismiss",
            handler: () => {
              setNote(client.toursNote);
            },
          },
          {
            text: "Save",
            handler: handleSubmit,
          },
        ]}
      />
      <CardContainer>
        <DetailsHeader
          title={title}
          icon={documentTextOutline}
          hasTag={false}
          isOpen={showNotes}
          setIsOpen={setShowNotes}
        />
        {showNotes && (
          <div className={styles.details}>
            <div className={styles.subtitle}>
              <p>
                Keep all your tour-wide questions and thoughts in one place for
                easy reference.
              </p>
              <p>
                You can also make property-specific notes on each detail page.
              </p>
            </div>

            <textarea
              className={`${styles.textarea}`}
              name="note"
              placeholder=""
              value={note}
              onChange={(event) => {
                setNote(event.target.value);
              }}
            ></textarea>
            <Button
              title="Save changes"
              type="green"
              style={{
                border:
                  note === client.toursNote
                    ? "1px solid #e5e5e5"
                    : "1px solid #1F7A60",
                width: "100%",
                height: "5rem",
              }}
              onClick={handleSubmit}
              loading={loading}
              disabled={note === client.toursNote}
            />
          </div>
        )}
      </CardContainer>
    </>
  );
};

export default GeneralNotes;
