import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import NotesSection from "../../../../../components/Notes/component/NotesSection/notes-section.component";
import { buildAddress } from "../../../../../utils/functions";
import { selectCurrentClient } from "../../../../../redux/client/client.selectors";
import {
  createShowingNote,
  updateShowingNote,
} from "../../../../../amplify/graphql.utils";
import { updateToast } from "../../../../../redux/ui/ui.actions";
import styles from "./notes.module.scss";

const Notes = ({ tourItem, noteItems, listing, refresh, disabled, tour }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState();
  const client = useSelector(selectCurrentClient);

  useEffect(() => {
    const groupNotes = () =>
      _.mapValues(_.groupBy(noteItems, (note) => note.owner));
    if (noteItems && client) setNotes(groupNotes());
  }, [client, noteItems]);

  const createNote = async (content) => {
    setLoading(true);
    try {
      await createShowingNote({
        content,
        tourItemId: tourItem.id,
      });
      dispatch(
        updateToast({
          open: true,
          type: "success",
          message: "Showing note saved.",
        })
      );
      refresh();
    } catch (err) {
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message: "Something went wrong!",
        })
      );
      console.log(err);
    }
    setLoading(false);
  };

  const updateNote = async ({ content, id }) => {
    setLoading(true);
    try {
      await updateShowingNote({
        id,
        content,
        tourItemId: tourItem.id,
      });
      dispatch(
        updateToast({
          open: true,
          type: "success",
          message: "Showing note saved.",
        })
      );
      refresh();
    } catch (err) {
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message: "Something went wrong!",
        })
      );
      console.log(err);
    }
    setLoading(false);
  };

  return (
    <div className={styles.noteDetails}>
      <div className={styles.subtitle}>
        {tourItem.agentId === client.id ||
        tourItem.showingAgentId === client.id ||
        tourItem.members.includes(client.id)
          ? `All notes taken for ${buildAddress(listing.address)}. by all tour
          attendees. You may only add to and edit your own notes.`
          : "Only lead/showing agents can create a note item."}
      </div>

      {(tourItem.agentId === client.id ||
        tourItem.showingAgentId === client.id ||
        tourItem.members.includes(client.id)) && (
        <NotesSection
          disabled={disabled}
          isCurrentUser={true}
          note={noteItems.find((note) => note.owner === client.id)}
          create={createNote}
          update={updateNote}
          memberData={JSON.parse(tourItem.membersData).find(
            (d) => d.id === client.id
          )}
          tour={tour}
        />
      )}

      {notes &&
        Object.keys(notes).map((key) => {
          if (notes[key][0].owner === client.id) {
            return null;
          } else {
            return (
              <NotesSection
                key={key}
                isCurrentUser={false}
                note={notes[key][0]}
                memberData={JSON.parse(tourItem.membersData).find(
                  (d) => d.id === notes[key][0].owner
                )}
                tour={tour}
              />
            );
          }
        })}
    </div>
  );
};

export default Notes;
