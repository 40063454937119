import { useState, useEffect } from "react";
import { IonButton, IonIcon } from "@ionic/react";
import { chatbubblesOutline, mailOutline } from "ionicons/icons";
import CardContainer from "../../../Tours/component/CardContainer/card-container.component";
import DetailsHeader from "../../../../components/TourDetailsHeader/tour-details-header.module";
import styles from "./quick-contact.module.scss";
import { getTourClients } from "../../../../amplify/graphql.utils";
import withAuthentication from "../../../../HOC/withAuthentication/with-authentication";
import _ from "lodash";

const QuickContact = ({ tour, isOpen, setIsOpen }) => {
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    const fetchClients = async () => {
      const clients = await getTourClients({ clients: tour.members });
      const contacts = [];
      if (clients)
        clients.forEach((client) => {
          if (client.id === tour.members[0]) {
            contacts.push({
              title: "Client",
              fullName: client.name,
              phone: client.phone,
              email: client.email,
            });
          } else {
            contacts.push({
              title: "Additional Guest",
              fullName: client.name,
              phone: client.phone,
              email: client.email,
            });
          }
          contacts.push({
            title: "Lead Agent",
            fullName: tour.agent.name,
            phone: tour.agent.phone,
            email: tour.agent.email,
          });
          if (tour.showingAgent) {
            contacts.push({
              title: "Showing Agent",
              fullName: tour.showingAgent.name,
              phone: tour.showingAgent.phone,
              email: tour.showingAgent.email,
            });
          }
        });
      setContacts(contacts);
    };
    if (tour && isOpen) {
      fetchClients();
    }
  }, [contacts, isOpen, tour]);

  return (
    <CardContainer>
      <div className={styles.attendees}>
        <DetailsHeader
          title="Quick contact"
          icon={chatbubblesOutline}
          isVisible={true}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />

        {isOpen && (
          <div className={styles.inputs}>
            {_.uniqBy(contacts, (c) => [c.id, c.role].join()).map(
              (contact, index) => (
                <div key={index} className={styles.contact}>
                  <div className={styles.title}>{contact.title}</div>
                  <div className={styles.infos}>
                    <span className={styles.bold}>{contact.fullName}</span>
                    <span>{contact.phone}</span>
                    <span>{contact.email}</span>
                  </div>
                  <div className={styles.btn}>
                    <IonButton
                      expand="block"
                      className={styles.button}
                      href={`mailto:${contact.email}`}
                      target="_blank"
                    >
                      <IonIcon icon={mailOutline} />
                    </IonButton>
                  </div>
                </div>
              )
            )}
          </div>
        )}
      </div>
    </CardContainer>
  );
};

export default withAuthentication(QuickContact);
