import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Input from "../../Form/Input/input.component";
import Button from "../../Form/Button/button.component";
import { ReactComponent as MailIcon } from "../../../assets/svg/mail-open.svg";
import styles from "./confirm-email-modal.module.scss";
import {
  selectUserError,
  selectUserState,
} from "../../../redux/user/user.selectors";
import { confirmEmail } from "../../../data/customModal";

const ConfirmEmailModal = ({ setType, close, data, language, ...rest }) => {
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const error = useSelector(selectUserError);
  const state = useSelector(selectUserState);
  const { user, onSubmit, onResend } = data;

  const submitHandler = () => {
    setLoading(true);
    onSubmit(code);
  };

  useEffect(() => {
    setLoading(false);
  }, [error, state]);
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.iconContainer}>
          <MailIcon className={styles.icon} />
        </div>

        <div className={styles.subtitle}>{confirmEmail[language].header}</div>
        <div className={styles.text}>
          <div className={styles.note}>{confirmEmail[language].subtitle}</div>
        </div>
      </div>
      <div className={styles.content}>
        <Input
          required
          mode="numeric"
          label={confirmEmail[language].code}
          placeholder={confirmEmail[language].code}
          value={code}
          onChange={(value, name) => setCode(value)}
        />
        {error && (
          <div className={styles.error}>
            <span>{error.message}</span>
          </div>
        )}
      </div>
      <div className={styles.button}>
        <Button
          title={confirmEmail[language].submitbtn}
          type="purple"
          style={{
            width: "100%",
            height: "5rem",
            border: !code.length ? "1px solid #e5e5e5" : "1px solid #7158ad",
          }}
          disabled={!code.length}
          onClick={submitHandler}
          loading={loading}
        />
      </div>
      <div className={styles.btns}>
        <Button
          title={confirmEmail[language].resendbtn}
          type="clear"
          style={{
            color: "#363636",
            width: "max-content",
            borderRight: "none",
          }}
          onClick={onResend}
        />
        <Button
          title={confirmEmail[language].cancel}
          type="clear"
          style={{
            color: "#363636",

            width: "max-content",
          }}
          onClick={close}
        />
      </div>
    </div>
  );
};

export default ConfirmEmailModal;
