import {
  IonContent,
  IonPage,
  IonIcon,
  IonAlert,
  IonToast,
  IonLoading,
  IonItem,
  IonLabel,
  IonRouterLink,
} from "@ionic/react";
import Toggle from "../../../../components/Form/Toggle/toggle-component";
import { notificationsOutline, schoolOutline } from "ionicons/icons";
import millify from "millify";
import { useDispatch } from "react-redux";
import { memo, useState, useEffect, useRef } from "react";
import AppHeader from "../../../components/Header/header.component";
import styles from "./search-details.module.scss";
import Button from "../../../../components/Form/Button/button.component";
import moment from "moment";
import { updateDrawMode, updateToast } from "../../../../redux/ui/ui.actions";
import withMap from "../../../../HOC/withMap/with-map";
import withFilters from "../../../../HOC/withFilters/with-filters";
import {
  getSearchItem,
  removeSearch,
  updateSavedSearch,
} from "../../../../amplify/graphql.utils";
import { API, graphqlOperation } from "aws-amplify";
import { onUpdateSearch } from "../../../../graphql/subscriptions";
import { useHistory } from "react-router-dom";

const SearchDetails = ({
  match: {
    params: { id },
  },
  updateMultipleFilters,
  updateExtraFilters,
  updateMapLocations,
}) => {
  const dispatch = useDispatch();
  const routerLinkRef = useRef();
  const [search, setSearch] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [showRename, setShowRename] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const history = useHistory();

  useEffect(() => {
    let subscription;
    const fetchSearch = async () => {
      setLoading(true);
      try {
        const search = await getSearchItem(id);
        const value = JSON.parse(search.value);
        setSearch({
          id: search.id,
          repliersID: search.repliersID,
          name: search.name,
          filters: value.filters,
          drawMode: value.drawMode,
          schoolMode: value.schoolMode,
          schoolName: value.schoolName,
          schools: value.schools,
          schoolId: value.schoolId,
          extra: value.extra,
          locations: value.locations,
          notification: search.notification,
        });

        subscription = await API.graphql(
          graphqlOperation(onUpdateSearch, { owner: search.owner })
        ).subscribe({
          next: ({
            value: {
              data: { onUpdateSearch },
            },
          }) =>
            setSearch({
              id: onUpdateSearch.id,
              repliersID: onUpdateSearch.repliersID,
              name: onUpdateSearch.name,
              filters: JSON.parse(onUpdateSearch.value).filters,
              notification: onUpdateSearch.notification,
            }),
        });
      } catch (err) {
        history.replace("/searches");
      }
      setLoading(false);
    };
    fetchSearch();

    return () => {
      if (subscription) subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleAlert = async () => {
    setLoading(true);
    try {
      await updateSavedSearch({
        id: search.id,
        notification: !search.notification,
        name: search.name,
      });
    } catch (err) {
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message: "Something went wrong!",
        })
      );
    }
    setLoading(false);
  };

  const loadHandler = () => {
    const { filters, extra, locations, drawMode, schoolMode } = search;

    let days;
    if (filters.minSoldDate || filters.minListDate) {
      days = moment(new Date()).diff(
        moment(filters.minListDate ? filters.minListDate : filters.minSoldDate),
        "days"
      );
    }
    if (days) {
      if (filters.minListDate) {
        filters.minListDate = moment()
          .subtract(days, "days")
          .format("YYYY-MM-DD");
      }
      if (filters.minSoldDate) {
        filters.minSoldDate = moment()
          .subtract(days, "days")
          .format("YYYY-MM-DD");
      }
    }

    dispatch(
      updateDrawMode({ active: drawMode || schoolMode, isSchool: schoolMode })
    );
    updateMapLocations({ ...locations, load: true });
    updateMultipleFilters({ ...filters });
    updateExtraFilters({ ...extra });
    routerLinkRef.current.click();
  };

  const deleteHandler = async () => {
    try {
      await removeSearch({ id: search.id, repliersID: search.repliersID });
      history.push("/searches", { direction: "back" });
    } catch (err) {
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message: "Something went wrong!",
        })
      );
    }
  };

  const renameHandler = async (name) => {
    setLoading(true);
    try {
      await updateSavedSearch({
        id: search.id,
        notification: search.notification,
        name,
      });
    } catch (err) {
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message: "Something went wrong!",
        })
      );
    }
    setLoading(false);
  };

  return (
    <IonPage>
      <AppHeader
        title={search && search.name ? search.name : "Searches"}
        hasRightButton={false}
        backHref={"/searches"}
      />
      <IonContent>
        <IonLoading isOpen={loading} />
        <IonAlert
          isOpen={showDelete}
          onDidDismiss={() => setShowDelete(false)}
          cssClass={styles.deleteAlert}
          header="Delete"
          message="Are you sure you want to delete this saved search?"
          buttons={["Cancel", { text: "Delete", handler: deleteHandler }]}
        />
        <IonAlert
          isOpen={showRename}
          onDidDismiss={() => setShowRename(false)}
          cssClass={styles.deleteAlert}
          header="Rename"
          message="Enter a new name for this saved search"
          buttons={[
            "Cancel",
            {
              text: "Rename",
              handler: ({ name }) => {
                if (!name.length) {
                  setError(true);
                  return false;
                } else {
                  renameHandler(name);
                }
              },
            },
          ]}
          inputs={[
            {
              name: "name",
              type: "text",
              placeholder: "Name",
              attributes: { required: true, minLength: 1, type: "text" },
            },
          ]}
        />
        <IonToast
          isOpen={error}
          message={"Name is required."}
          duration={2000}
          onDidDismiss={() => setError("")}
          cssClass={styles.toast}
          position="top"
          buttons={[
            {
              side: "end",
              icon: "close",
              handler: () => setError(""),
            },
          ]}
        />
        {search && (
          <div className={styles.content}>
            <div className={styles.label}>{search.name}</div>
            <div className={styles.details}>
              <div className={styles.params}>
                <span className={styles.item}>
                  <span className={styles.title}>Property Type:</span>
                  <span className={styles.filter}>
                    {search.filters.propertyType
                      ? search.filters.propertyType.length > 1
                        ? "Several"
                        : search.filters.propertyType
                      : "Any"}
                  </span>
                </span>
                <span className={styles.item}>
                  <span className={styles.title}>Price:</span>
                  <span className={styles.filter}>
                    {search.filters.minPrice === "0" &&
                    search.filters.maxPrice === "max"
                      ? "Any"
                      : `$${millify(+search.filters.minPrice)}-${
                          search.filters.maxPrice === "max"
                            ? "max"
                            : `$${millify(+search.filters.maxPrice)}`
                        }`}
                  </span>
                </span>
                <span className={styles.item}>
                  <span className={styles.title}>Beds:</span>
                  <span className={styles.filter}>
                    {!search.filters.minBeds && !search.filters.maxBeds
                      ? "Any"
                      : search.filters.minBeds && !search.filters.maxBeds
                      ? `${search.filters.minBeds}+`
                      : !search.filters.minBeds &&
                        search.filters.maxBeds &&
                        search.filters.maxBeds !== "any"
                      ? `0-${search.filters.maxBeds}`
                      : search.filters.minBeds &&
                        search.filters.maxBeds === "any"
                      ? `${search.filters.minBeds}+`
                      : !search.filters.minBeds &&
                        search.filters.maxBeds === "any"
                      ? "Any"
                      : `${search.filters.minBeds}-${search.filters.maxBeds}`}
                  </span>
                </span>
                <span className={styles.item}>
                  <span className={styles.title}>Baths:</span>
                  <span className={styles.filter}>
                    {!search.filters.minBaths && !search.filters.maxBaths
                      ? "Any"
                      : search.filters.minBaths && !search.filters.maxBaths
                      ? `${search.filters.minBaths}+`
                      : !search.filters.minBaths &&
                        search.filters.maxBaths &&
                        search.filters.maxBaths !== "any"
                      ? `0-${search.filters.maxBaths}`
                      : search.filters.minBaths &&
                        search.filters.maxBaths === "any"
                      ? `${search.filters.minBaths}+`
                      : !search.filters.minBaths &&
                        search.filters.maxBaths === "any"
                      ? "Any"
                      : `${search.filters.minBaths}-${search.filters.maxBaths}`}
                  </span>
                </span>
              </div>
              {search.schoolMode && (
                <div className={styles.schools}>
                  {search.schools &&
                    search.schools.map((s, i) => (
                      <div key={i} className={styles.school}>
                        <span className={styles.iconContainer}>
                          <IonIcon
                            icon={schoolOutline}
                            className={styles.icon}
                          />
                          <span className={styles.title}>School</span>
                        </span>
                        <IonItem lines="none">
                          <IonLabel
                            className={styles.name}
                            onClick={() => {
                              history.push(`/schools/${s.id}`);
                            }}
                          >
                            {s.name}
                          </IonLabel>
                        </IonItem>
                      </div>
                    ))}
                </div>
              )}

              <div className={styles.alerts}>
                <span className={styles.iconContainer}>
                  <IonIcon
                    icon={notificationsOutline}
                    className={styles.icon}
                  />
                  <span className={styles.title}>Alerts</span>
                </span>

                <Toggle active={search.notification} onToggle={handleAlert} />
              </div>
            </div>

            <Button
              type="purple"
              border
              title="View on map"
              style={{ width: "100%", height: "5rem", marginTop: "2rem" }}
              onClick={loadHandler}
            />
            <IonRouterLink
              routerDirection="root"
              ref={routerLinkRef}
              routerLink="/tabs/listings"
              style={{ visibility: "hidden" }}
            >
              Back to map
            </IonRouterLink>
            <div className={styles.btns}>
              <Button
                type="blue"
                border
                title="Rename"
                style={{ width: "100%", height: "5rem", marginTop: "2rem" }}
                onClick={() => setShowRename(true)}
              />
              <Button
                type="orange"
                border
                title="Delete"
                style={{ width: "100%", height: "5rem", marginTop: "2rem" }}
                onClick={() => setShowDelete(true)}
              />
            </div>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default memo(withMap(withFilters(SearchDetails)));
