import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";
import fadeTransition from "./transitions/fade.module.scss";
import styles from "./modal.module.scss";
import { IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import ForgotPassword from "../CustomModal/ForgotPassword/forgot-password-modal.component";
import ConfirmEmail from "../CustomModal/ConfirmEmail/confirm-email-modal.component";
import Welcome from "../CustomModal/WelcomeModal/welcome-modal.component";
import ConfirmRegistration from "../CustomModal/ConfirmRegistration/confirm-registration-modal.component";
import ChangePassword from "../CustomModal/ChangePassword/change-password-modal.component";
import SucessfulChange from "../CustomModal/SuccessfulChange/successful-change-modal.component";
import ChangeName from "../CustomModal/ChangeName/change-name-modal.component";
import SuccessfulChange from "../CustomModal/SuccessfulChange/successful-change-modal.component";
import DeleteAccount from "../CustomModal/DeleteAccount/delete-account-modal.component";
import DeletedAccount from "../CustomModal/DeletedAccount/deleted-account-modal.component";
import EditProfileType from "../CustomModal/EditProfileType/edit-profile-type-modal.component";
import EditCurrency from "../CustomModal/EditCurrency/edit-currency-modal.component";
import ChangeEmail from "../CustomModal/ChangeEmail/change-email-modal.component";
import ChangePhone from "../CustomModal/ChangePhone/change-phone-modal.component";
import ConfirmPhone from "../CustomModal/ConfirmPhone/confirm-phone-modal.component";
import EditNotification from "../CustomModal/EditNotification/edit-notification-modal.component";
import SaveSearch from "../CustomModal/SaveSearch/save-search-modal.component";
import Schools from "../CustomModal/Schools/schools-modal.component";
import { successfulChange } from "../../data/customModal";
import EditSavedSearch from "../CustomModal/EditSavedSearch/edit-saved-search-modal.component";
import JoinModal from "../CustomModal/Join/join-modal.component";
import ShareListingModal from "../CustomModal/ShareListing/share-listing-modal.component";
import ShareListingEmailModal from "../CustomModal/ShareListingEmail/share-listing-email-modal.component";
import RequestFormModal from "../CustomModal/RequestForm/request-form-modal.component";
import UserID from "../CustomModal/UserID/user-id-modal.component";
import EditUnits from "../CustomModal/EditUnits/edit-units-modal.component";
import DeleteShowingModal from "../CustomModal/DeleteShowing/delete-showing-modal.component";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import ShareTourModal from "../CustomModal/ShareTour/share-tour-modal.component";
import InviteOthersModal from "../CustomModal/InviteOthers/invite-others.component";
import DeleteSavedSearchModal from "../CustomModal/DeleteSavedSearch/delete-saved-search-modal.component";

export const Modal = ({
  close,
  type,
  setType,
  data,
  setData,
  isProtected,
  closable = true,
  overflow = false,
  language = "en",
  ...rest
}) => {
  const [show, setShow] = useState(false);
  const isAuthenticated = useSelector(selectCurrentUser);

  useEffect(() => {
    type ? setShow(true) : setShow(false);
  }, [type]);

  const renderModal = () => {
    if (isProtected && !isAuthenticated)
      return <JoinModal close={close} language={language} />;
    switch (type) {
      case "confirmEmail":
        return (
          <ConfirmEmail
            setType={setType}
            close={() => setShow(false)}
            data={data}
            language={language}
            {...rest}
          />
        );
      case "confirmRegistration":
        return (
          <ConfirmRegistration
            setType={setType}
            language={language}
            close={close}
          />
        );
      case "welcome":
        return <Welcome close={close} language={language} />;
      case "join":
        return <JoinModal close={close} language={language} />;
      case "userId":
        return <UserID close={close} language={language} />;
      case "forgotPassword":
        return <ForgotPassword language={language} />;
      case "changePassword":
        return <ChangePassword setType={setType} language={language} />;
      case "updatedPassword":
        return (
          <SucessfulChange
            close={close}
            title={successfulChange[language].updatedPassword}
          />
        );
      case "changeName":
        return <ChangeName setType={setType} language={language} />;
      case "updatedName":
        return (
          <SuccessfulChange
            close={close}
            title={successfulChange[language].updatedName}
          />
        );
      case "deleteSavedSearch":
        return (
          <DeleteSavedSearchModal
            data={data}
            close={close}
            language={language}
          />
        );
      case "deleteAccount":
        return (
          <DeleteAccount setType={setType} close={close} language={language} />
        );
      case "deletedAccount":
        return (
          <DeletedAccount setType={setType} close={close} language={language} />
        );
      case "editProfileType":
        return <EditProfileType setType={setType} language={language} />;
      case "updatedProfileType":
        return (
          <SuccessfulChange
            close={close}
            title={successfulChange[language].updatedProfileType}
          />
        );
      case "editCurrency":
        return <EditCurrency setType={setType} language={language} />;
      case "updatedCurrency":
        return (
          <SuccessfulChange
            close={close}
            title={successfulChange[language].updatedCurrency}
          />
        );
      case "editUnits":
        return (
          <EditUnits close={close} setType={setType} language={language} />
        );
      case "updatedUnits":
        return (
          <SuccessfulChange
            close={close}
            title={successfulChange[language].updatedUnits}
          />
        );
      case "changeEmail":
        return <ChangeEmail setType={setType} language={language} />;
      case "updatedEmail":
        return (
          <SuccessfulChange
            close={close}
            title={successfulChange[language].updatedEmail}
          />
        );
      case "changePhone":
        return (
          <ChangePhone
            setType={setType}
            setData={setData}
            language={language}
          />
        );
      case "confirmPhone":
        return (
          <ConfirmPhone
            setType={setType}
            close={close}
            data={data}
            language={language}
          />
        );
      case "updatedPhone":
        return (
          <SuccessfulChange
            close={close}
            title={successfulChange[language].updatedPhone}
          />
        );
      case "editNotification":
        return <EditNotification setType={setType} language={language} />;
      case "updatedNotification":
        return (
          <SuccessfulChange
            close={close}
            title={successfulChange[language].updatedNotification}
          />
        );
      case "schools":
        return <Schools close={close} data={data} language={language} />;
      case "saveSearch":
        return (
          <SaveSearch
            setType={setType}
            language={language}
            close={close}
            data={data}
          />
        );
      case "savedSearch":
        return (
          <SuccessfulChange
            close={close}
            title={successfulChange[language].savedSearch}
            style={{
              backgroundColor: "white",
              margin: "2rem auto 0",
            }}
            isgreen={true}
          />
        );
      case "editSavedSearch":
        return (
          <EditSavedSearch
            setType={setType}
            language={language}
            data={data}
            close={close}
          />
        );
      case "updatedSavedSearch":
        return (
          <SuccessfulChange
            close={close}
            title={successfulChange[language].updatedSavedSearch}
            style={{
              backgroundColor: "white",
              margin: "2rem auto 0",
            }}
            isgreen={true}
          />
        );
      case "shareListing":
        return (
          <ShareListingModal close={close} language={language} data={data} />
        );
      case "shareListingEmail":
        return (
          <ShareListingEmailModal
            language={language}
            data={data}
            close={close}
          />
        );
      case "inviteOthers":
        return (
          <InviteOthersModal
            // language={language}
            // data={data}
            close={close}
          />
        );
      case "requestForm":
        return (
          <RequestFormModal data={data} setType={setType} language={language} />
        );
      case "requestSent":
        return (
          <SuccessfulChange
            title={successfulChange[language].requestSent.title}
            subtitle={successfulChange[language].requestSent.subtitle}
            close={close}
          />
        );
      case "deleteShowing":
        return <DeleteShowingModal close={close} data={data} />;
      case "shareTour":
        return <ShareTourModal close={close} />;
      default:
        return;
    }
  };
  return (
    <CSSTransition
      in={show}
      timeout={400}
      classNames={fadeTransition}
      unmountOnExit
    >
      <div className={styles.wrapper}>
        <div className={`${styles.container} ${overflow && styles.overflow}`}>
          {closable && (
            <div className={styles.closeIcon} onClick={close}>
              <IonIcon icon={closeOutline} />
            </div>
          )}

          <CSSTransition
            in={show}
            timeout={400}
            classNames={fadeTransition}
            unmountOnExit
          >
            <div className={styles.content}>
              {/* {type === "register" && (
                <SignUp redirect={() => setType("login")} />
              )}
              {type === "login" && (
                <SignIn redirect={() => setType("register")} />
              )} */}

              {renderModal()}
            </div>
          </CSSTransition>
        </div>
      </div>
    </CSSTransition>
  );
};

export default Modal;
