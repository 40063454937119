import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { App } from "@capacitor/app";
import { federatedSignInStart } from "../redux/user/user.actions";

const AppUrlListener = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const location = useLocation();
  useEffect(() => {
    App.addListener("appUrlOpen", (data) => {
      // const slug = data.url.split("/?")[1];
      const slug = data.url.split(".ai").pop();
      if (slug) {
        if (slug.startsWith("/?") || slug.startsWith("/oauth")) {
          const code = slug.split("/?")[1];
          window.location.href = `/?${code}`;
        } else {
          if (slug === "/") {
            history.push("/home");
          } else {
            history.push(slug);
          }
        }
      }
    });
  }, [history]);

  useEffect(() => {
    if (location?.search?.includes("Already+found+an+entry+for+user"))
      dispatch(
        federatedSignInStart(
          location.search.includes("google")
            ? "Google"
            : location.search.includes("facebook")
            ? "Facebook"
            : "SignInWithApple"
        )
      );
  }, [dispatch, location]);
  return null;
};
export default AppUrlListener;
