import millify from "millify";
import moment from "moment";

export const renderAppTag = (type, filters, extraFilters) => {
  if (!filters) return;
  const {
    minBeds,
    maxBeds,
    minBaths,
    maxBaths,
    minParkingSpaces,
    minSqft,
    maxSqft,
    minPrice,
    maxPrice,
    minListDate,
    maxListDate,
    minSoldDate,
    maxSoldDate,
    lastStatus,
    class: propertyClass,
    propertyType,
    type: typeFilter,
  } = filters;
  const { lotWidth, lotDepth, locker, maintenance, basement } = extraFilters;
  switch (type) {
    case "status":
      if (lastStatus === "Sld") return "Sold";
      if (lastStatus === "Lsd") return "Leased";
      if (lastStatus === "New" || !lastStatus) return "Active";
      return "De-listed";
    case "date":
      if (minSoldDate && maxSoldDate) {
        return `Year ${minSoldDate.substring(0, 4)}`;
      }
      const diff = moment(new Date()).diff(
        minSoldDate ? minSoldDate : minListDate,
        "days"
      );
      if (diff) return "Last " + diff + " d";
      return "Any time";
    case "beds":
      if (minBeds === 0 && maxBeds === "any") return;
      if (!minBeds && !maxBeds) return;
      if (minBeds && !maxBeds) return `${minBeds}+`;
      if (!minBeds && maxBeds) return `0 - ${maxBeds}`;
      if (minBeds && maxBeds) return `${minBeds} - ${maxBeds}`;
      break;
    case "baths":
      if (minBaths === 0 && maxBaths === "any") return;
      if (!minBaths && !maxBaths) return;
      if (minBaths && !maxBaths) return `${minBaths}+`;
      if (!minBaths && maxBaths) return `0 - ${maxBaths}`;
      if (minBaths && maxBaths) return `${minBaths} - ${maxBaths}`;
      break;
    case "parkings":
      if (!minParkingSpaces || minParkingSpaces === 0) return;
      return `${minParkingSpaces}+`;
    case "sqft":
      if (minSqft === 0 && maxSqft === "max") return;
      if (!minSqft && !maxSqft) return;
      if (minSqft && !maxSqft) return `${millify(minSqft)}+`;
      if (!minSqft && maxSqft)
        return `0 - ${maxSqft === "max" ? "max" : millify(maxSqft)}`;
      if (minSqft && maxSqft)
        return `${millify(minSqft)} - ${
          maxSqft === "max" ? "max" : millify(maxSqft)
        }`;
      break;
    case "purpose":
      return propertyClass.includes("residential")
        ? "residential"
        : "commercial";
    case "type":
      if (!propertyType) return;
      if (propertyType.length > 1) return `multiple (${propertyType.length})`;
      return propertyType[0].length > 8
        ? propertyType[0].substring(0, 8).toLowerCase() + "..."
        : propertyType[0].toLowerCase();
    case "price":
      if ((minPrice === "0" || minPrice === 0) && maxPrice === "max") return;
      if (minPrice && !maxPrice)
        return `$${millify(+minPrice, {
          precision:
            typeFilter === "sale"
              ? minPrice > 1000000
                ? 2
                : 0
              : minPrice > 1000
              ? 2
              : 1,
        })} - max`;
      if (!minPrice && maxPrice)
        return `0 - ${
          maxPrice === "max"
            ? "max"
            : `$${millify(+maxPrice, {
                precision:
                  typeFilter === "sale"
                    ? maxPrice > 1000000
                      ? 2
                      : 0
                    : maxPrice > 1000
                    ? 2
                    : 1,
              })}`
        }`;
      if (minPrice && maxPrice)
        return `$${millify(+minPrice, {
          precision:
            typeFilter === "sale"
              ? minPrice > 1000000
                ? 2
                : 0
              : minPrice > 1000
              ? 2
              : 1,
        })} - ${
          maxPrice === "max"
            ? "max"
            : `$${millify(+maxPrice, {
                precision:
                  typeFilter === "sale"
                    ? maxPrice > 1000000
                      ? 2
                      : 0
                    : maxPrice > 1000
                    ? 2
                    : 1,
              })}`
        }`;
      break;
    case "lotWidth":
      if (lotWidth.min === 0 && lotWidth.max === "max") return;
      if (!lotWidth.min && !lotWidth.max) return;
      if (lotWidth.min && !lotWidth.max) return `${lotWidth.min}+`;
      if (!lotWidth.min && lotWidth.max) return `0 - ${lotWidth.max}`;
      if (lotWidth.min && lotWidth.max)
        return `${lotWidth.min} - ${lotWidth.max}`;
      break;
    case "lotDepth":
      if (lotDepth.min === 0 && lotDepth.max === "max") return;
      if (!lotDepth.min && !lotDepth.max) return;
      if (lotDepth.min && !lotDepth.max) return `${lotDepth.min}+`;
      if (!lotDepth.min && lotDepth.max) return `0 - ${lotDepth.max}`;
      if (lotDepth.min && lotDepth.max)
        return `${lotDepth.min} - ${lotDepth.max}`;
      break;
    case "locker":
      if (locker) return locker;
      break;
    case "maintenance":
      if (maintenance) return `<$${millify(maintenance, { precision: 1 })}`;
      break;
    case "basement":
      if (
        !basement.any &&
        !basement.walkout &&
        !basement.sepEntrance &&
        !basement.finished
      )
        return "no";
      if (basement.any) return;
      return "yes";
    default:
      return;
  }
};

export const renderTag = (type, filters, extraFilters, tab) => {
  const {
    status,
    minListDate,
    minSoldDate,
    maxSoldDate,
    lastStatus,
    minBeds,
    maxBeds,
    minBaths,
    maxBaths,
    minParkingSpaces,
    minSqft,
    maxSqft,
    minPrice,
    maxPrice,
    class: propertyClass,
    propertyType,
  } = filters;
  const { lotWidth, lotDepth, locker, maintenance, basement } = extraFilters;
  switch (type) {
    case "beds":
      if (tab === "beds") return;
      if (minBeds === 0 && maxBeds === "any") return;
      if (!minBeds && !maxBeds) return;
      if (minBeds && !maxBeds) return `${minBeds}+`;
      if (!minBeds && maxBeds) return `0 - ${maxBeds}`;
      if (minBeds && maxBeds) return `${minBeds} - ${maxBeds}`;
      break;
    case "baths":
      if (tab === "baths") return;
      if (minBaths === 0 && maxBaths === "any") return;
      if (!minBaths && !maxBaths) return;
      if (minBaths && !maxBaths) return `${minBaths}+`;
      if (!minBaths && maxBaths) return `0 - ${maxBaths}`;
      if (minBaths && maxBaths) return `${minBaths} - ${maxBaths}`;
      break;
    case "parkings":
      if (tab === "parkings") return;
      if (!minParkingSpaces || minParkingSpaces === 0) return;
      return `${minParkingSpaces}+`;
    case "sqft":
      if (tab === "sqft") return;
      if (minSqft === 0 && maxSqft === "max") return;
      if (!minSqft && !maxSqft) return;
      if (minSqft && !maxSqft) return `${millify(minSqft)}+`;
      if (!minSqft && maxSqft)
        return `0 - ${maxSqft === "max" ? "max" : millify(maxSqft)}`;
      if (minSqft && maxSqft)
        return `${millify(minSqft)} - ${
          maxSqft === "max" ? "max" : millify(maxSqft)
        }`;
      break;
    case "purpose":
      if (tab === "purpose") return;
      return propertyClass.includes("residential")
        ? "residential"
        : "commercial";
    case "type":
      if (tab === "type") return;
      if (!propertyType) return;
      if (propertyType.length > 1) return `multiple (${propertyType.length})`;
      return propertyType[0].length > 8
        ? propertyType[0].substring(0, 8).toLowerCase() + "..."
        : propertyType[0].toLowerCase();
    case "status":
      if (tab === "status") return;
      return status === "A"
        ? "active"
        : lastStatus === "Sld"
        ? "sold"
        : lastStatus === "Lsd"
        ? "leased"
        : "de-listed";

    case "date":
      if (tab === "date") return;
      if (minSoldDate && maxSoldDate) {
        return `Year ${minSoldDate.substring(0, 4)}`;
      }
      const diff = moment(new Date()).diff(
        minSoldDate ? minSoldDate : minListDate,
        "days"
      );
      if (diff) return "Last " + diff + " d";
      return "Any time";

    case "price":
      if (tab === "price") return;
      if (minPrice === "0" && maxPrice === "max") return;
      if (minPrice && !maxPrice) return `$${millify(+minPrice)} - max`;
      if (!minPrice && maxPrice)
        return `0 - ${maxPrice === "max" ? "max" : `$${millify(+maxPrice)}`}`;
      if (minPrice && maxPrice)
        return `$${millify(+minPrice)} - ${
          maxPrice === "max" ? "max" : `$${millify(+maxPrice)}`
        }`;
      break;
    case "lotWidth":
      if (tab === "lotWidth") return;
      if (lotWidth.min === 0 && lotWidth.max === "max") return;
      if (!lotWidth.min && !lotWidth.max) return;
      if (lotWidth.min && !lotWidth.max) return `${lotWidth.min}+`;
      if (!lotWidth.min && lotWidth.max) return `0 - ${lotWidth.max}`;
      if (lotWidth.min && lotWidth.max)
        return `${lotWidth.min} - ${lotWidth.max}`;
      break;
    case "lotDepth":
      if (tab === "lotDepth") return;
      if (lotDepth.min === 0 && lotDepth.max === "max") return;
      if (!lotDepth.min && !lotDepth.max) return;
      if (lotDepth.min && !lotDepth.max) return `${lotDepth.min}+`;
      if (!lotDepth.min && lotDepth.max) return `0 - ${lotDepth.max}`;
      if (lotDepth.min && lotDepth.max)
        return `${lotDepth.min} - ${lotDepth.max}`;
      break;
    case "locker":
      if (tab === "locker") return;
      if (locker) return locker;
      break;
    case "maintenance":
      if (tab === "maintenance") return;
      if (maintenance) return `<$${millify(maintenance)}`;
      break;
    case "basement":
      if (tab === "basement") return;
      if (
        !basement.any &&
        !basement.walkout &&
        !basement.sepEnterance &&
        !basement.finished
      )
        return "no";
      if (basement.any) return;
      return "yes";
    default:
      return;
  }
};

export const renderLocationTag = ({ neighborhood, city, area }) => {
  let tag;
  if (neighborhood) {
    tag = neighborhood[0];
    if (neighborhood.length > 1) tag = "Multiple";
  } else if (city) {
    tag = city[0];
    if (city.length > 1) tag = "Multiple";
  } else if (area) {
    tag = area;
  }

  return tag?.length > 16 ? tag.substring(0, 15) + "..." : tag;
};

export const isActive = (type, activeFilters, extraFilters, tab) => {
  const { lotWidth, lotDepth, locker, maintenance, basement } = extraFilters;

  switch (type) {
    case "location":
      if (tab === "location") return;
      return activeFilters.filter(
        (filter) =>
          filter === "area" || filter === "city" || filter === "neighborhood"
      ).length;
    case "beds":
      if (tab === "beds") return;
      return activeFilters.filter(
        (filter) => filter === "minBeds" || filter === "maxBeds"
      ).length;
    case "baths":
      if (tab === "baths") return;
      return activeFilters.filter(
        (filter) => filter === "minBaths" || filter === "maxBaths"
      ).length;
    case "parkings":
      if (tab === "parkings") return;
      return activeFilters.filter((filter) => filter === "minParkingSpaces")
        .length;
    case "price":
      if (tab === "price") return;
      return activeFilters.filter(
        (filter) => filter === "minPrice" || filter === "maxPrice"
      ).length;
    case "purpose":
      if (tab === "purpose") return;
      return activeFilters.filter((filter) => filter === "class").length;
    case "type":
      if (tab === "type") return;
      return activeFilters.filter((filter) => filter === "propertyType").length;
    case "lotWidth":
      if (tab === "lotWidth") return;
      return lotWidth.min || lotWidth.max;
    case "lotDepth":
      if (tab === "lotDepth") return;
      return lotDepth.min || lotDepth.max;
    case "locker":
      if (tab === "locker") return;
      return locker;
    case "maintenance":
      if (tab === "maintenance") return;
      return maintenance;
    case "basement":
      if (tab === "basement") return;
      return (
        (!basement.any &&
          !basement.walkOut &&
          !basement.finished &&
          !basement.sepEnterance) ||
        basement.walkOut ||
        basement.finished ||
        basement.sepEnterance
      );
    default:
      return null;
  }
};
