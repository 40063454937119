export const taxLimit1 = 275; // 0-55,000
export const taxLimit2 = 1950; // 55,000-250,000
export const taxLimit3 = 2250; //250,000-400,000
export const taxLimit4 = 32000; //400,000-2,000,000

export const text1 =
  "Land transfer tax (LTT), typically calculated as a percentage of the purchase price of a home, is required when purchasing a home in Canada. All provinces have a LTT, and the amount varies in each province";

export const text2 =
  "Some municipalities, like Toronto, levy an additional LTT, which is similarly calculated as a percentage of the purchase price of a home.";
export const text3 =
  "If you are a first-time home buyer in Ontario, you will be eligible for LTT rebates, equal to the value of the LTT up to a maximum amount set by the province.";
