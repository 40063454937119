import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IonSpinner, IonIcon } from "@ionic/react";
import styles from "./favorite-icon.module.scss";
import {
  openModal,
  updateChangedFavourite,
  updateToast,
} from "../../../../redux/ui/ui.actions";
import favIcon from "../../../../assets/svg/favourite.svg";
import notIcon from "../../../../assets/svg/notifications.svg";
import {
  addFavourite,
  getFavouriteItem,
  removeFavourite,
  updateClient,
  updateFavouriteNotification,
} from "../../../../amplify/graphql.utils";
import { selectCurrentUser } from "../../../../redux/user/user.selectors";
import { hapticsImpact } from "../../../../utils/functions";
import { selectCurrentClient } from "../../../../redux/client/client.selectors";
import { useHistory } from "react-router-dom";
import { selectUpdatedFavourite } from "../../../../redux/ui/ui.selectors";

const FavoriteIcon = ({
  listing,
  map,
  isApp = false,
  iconStyle,
  favIconStyle,
  notifIconStyle,
  isActiveComparables,
  reload,
  ...rest
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const updatedFavourite = useSelector(selectUpdatedFavourite);
  const isAuthenticated = useSelector(selectCurrentUser);
  const [favourite, setFavourite] = useState();
  const [loading, setLoading] = useState(false);
  const currentClient = useSelector(selectCurrentClient);
  const notifications = currentClient
    ? JSON.parse(currentClient.notifications)
    : null;

  useEffect(() => {
    let isMounted = true;

    const fetchFavourite = async () => {
      isMounted && setLoading("initial");
      try {
        if (isMounted) {
          const fav = await getFavouriteItem(listing?.mlsNumber);
          setFavourite(fav);
        }
      } catch (err) {}
      isMounted && setLoading(false);
    };

    if (listing) fetchFavourite();

    return () => (isMounted = false);
  }, [listing, map, reload]);

  useEffect(() => {
    let isMounted = true;
    const fetchFavourite = async () => {
      try {
        const fav = await getFavouriteItem(listing?.mlsNumber);
        setFavourite(fav);
        dispatch(updateChangedFavourite(undefined));
      } catch (err) {}
    };
    if (updatedFavourite === listing?.mlsNumber && isMounted) {
      fetchFavourite();
    }
    return () => (isMounted = false);
  }, [dispatch, listing?.mlsNumber, updatedFavourite]);

  const unauthorizedClickHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isApp) {
      history.push("/join", { direction: "none" });
    } else {
      dispatch(openModal({ current: "join" }));
    }
  };

  const handleFavClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    await hapticsImpact();
    setLoading(true);
    try {
      if (favourite) {
        const fav = await removeFavourite(favourite);
        if (fav) setFavourite(null);
      } else {
        const fav = await addFavourite(
          currentClient.id,
          currentClient.repliersID,
          listing
        );
        setFavourite(fav);
      }
    } catch (err) {
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message: "Something went wrong!",
        })
      );
    }

    setLoading(false);
  };

  const handleNotClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    await hapticsImpact();
    if (!favourite) {
      dispatch(
        updateToast({
          open: true,
          message: "Alerts are only available for favourite listings.",
          type: "info",
        })
      );
      return;
    }
    setLoading(true);
    try {
      const {
        types: { email, phone, push },
        schedule,
      } = notifications;
      if (!email && !phone && !push) {
        await updateClient({
          id: currentClient.id,
          updates: {
            notifications: JSON.stringify({
              ...notifications,
              schedule: schedule ? schedule : "daily",
              types: { email: true, phone: false, push: false },
            }),
          },
        });
      }

      const fav = await updateFavouriteNotification(favourite);

      setFavourite(fav);
    } catch (err) {
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message: "Something went wrong!",
        })
      );
    }

    setLoading(false);
  };

  return isAuthenticated ? (
    loading ? (
      <div className={styles.spinnerContainer}>
        {loading !== "initial" && (
          <IonSpinner
            name="dots"
            className={styles.spinner}
            style={iconStyle}
          />
        )}
      </div>
    ) : (
      <div ref={ref} className={styles.container}>
        <span
          className={`${styles.favorite} ${map && styles.map}`}
          onClick={handleFavClick}
        >
          <IonIcon
            src={favIcon}
            className={`${styles.favIcon} ${
              favourite && styles.invertedFavIcon
            }`}
            style={favIconStyle}
          />
        </span>
        {(listing.status === "A" || isActiveComparables) && (
          <span
            className={`${styles.notification} ${map && styles.map}`}
            onClick={handleNotClick}
          >
            <IonIcon
              src={notIcon}
              className={`${styles.notIcon} ${
                favourite && favourite.notification && styles.invertedNotIcon
              } ${!favourite && styles.disabled}`}
              style={notifIconStyle}
            />
          </span>
        )}
      </div>
    )
  ) : (
    <div ref={ref} className={styles.container}>
      <span
        className={`${styles.favorite} ${map && styles.map}`}
        onClick={unauthorizedClickHandler}
      >
        <IonIcon
          src={favIcon}
          className={`${styles.favIcon} ${isApp && styles.app}`}
        />
      </span>
      <span
        className={`${styles.notification} ${map && styles.map}`}
        onClick={unauthorizedClickHandler}
      >
        <IonIcon
          src={notIcon}
          className={`${styles.notIcon} ${isApp && styles.app}`}
        />
      </span>
    </div>
  );
};

export default FavoriteIcon;
