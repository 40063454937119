import { useState, useRef, useEffect } from "react";
import Button from "../../Form/Button/button.component";
import Input from "../../Form/Input/input.component";
import { ReactComponent as SearchIcon } from "../../../assets/svg/search.svg";
import { editSavedSearch } from "../../../data/customModal";
import styles from "./edit-saved-search-modal.module.scss";
import { updateSavedSearch } from "../../../amplify/graphql.utils";
import { IonIcon } from "@ionic/react";
import { schoolOutline } from "ionicons/icons";
import { useHistory } from "react-router-dom";

const EditSavedSearchModal = ({ setType, language, data, close }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { search, setIsUpdated } = data;
  const { value } = search;
  const { filters: savedDetails, schoolMode, schools } = JSON.parse(value);
  const nameRef = useRef();
  const [name, setName] = useState("");

  const handleChange = (value, name) => {
    setName(value);
  };

  useEffect(() => {
    setName(search.name);
  }, [search]);

  const submitHandler = async () => {
    if (nameRef.current.validate(true)) {
      setLoading(true);
      setError("");
      try {
        await updateSavedSearch({
          id: search.id,
          notification: search.notification,
          name,
        });
        setLoading(false);
        setType("updatedSavedSearch");
        setIsUpdated(true);
      } catch (error) {
        setLoading(false);
        setError(error.message);
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>{editSavedSearch[language].header}</div>

      <div className={styles.content}>
        <div>
          <div className={styles.label}>
            {editSavedSearch[language].label_1}
          </div>
          <Input
            required
            validation="required"
            name="search_name"
            label=""
            ref={nameRef}
            value={name}
            onChange={(value, name) => handleChange(value, name)}
          />
        </div>
        <div className={styles.details}>
          <span className={styles.bold}>{savedDetails.area && "Area: "}</span>
          <span>
            {savedDetails.area &&
              savedDetails.area.length > 0 &&
              savedDetails.area.map((item) => {
                let areas = "";
                return (areas = item + ", ");
              })}
          </span>
          <span className={styles.bold}>
            {savedDetails.neighborhood && "Neighborhood: "}
          </span>
          <span>
            {savedDetails.neighborhood &&
              savedDetails.neighborhood.length > 0 &&
              savedDetails.neighborhood.map((item) => {
                let neighborhoods = "";
                return (neighborhoods = item + ", ");
              })}
          </span>
          <span className={styles.bold}>Property type: </span>
          <span>
            {savedDetails.propertyType && savedDetails.propertyType.length > 0
              ? savedDetails.propertyType.map((item) => {
                  let types = "";
                  return (types = item + ", ");
                })
              : "all, "}
          </span>
          <span className={styles.bold}>Price: </span>
          <span>{`$${savedDetails.minPrice} - ${savedDetails.maxPrice}, `}</span>
          <span className={styles.bold}>
            {savedDetails.minBeds && "Beds: "}
          </span>
          <span>
            {savedDetails.minBeds &&
              (savedDetails.maxBeds
                ? `${savedDetails.minBeds} - ${savedDetails.maxBeds}, `
                : `${savedDetails.minBeds}, `)}
          </span>
          <span className={styles.bold}>
            {savedDetails.minBaths && "Baths: "}
          </span>
          <span>
            {savedDetails.minBaths &&
              (savedDetails.maxBaths
                ? `${savedDetails.minBaths} - ${savedDetails.maxBaths}, `
                : `${savedDetails.minBaths}, `)}
          </span>
          <span className={styles.bold}>
            {savedDetails.minSqft && "Sqft: "}
          </span>
          <span>
            {savedDetails.minSqft &&
              (savedDetails.maxSqft
                ? `${savedDetails.minSqft} - ${savedDetails.maxSqft}, `
                : `${savedDetails.minSqft}, `)}
          </span>
        </div>
        {schoolMode && (
          <div className={styles.schools}>
            {schools &&
              schools.map((s, i) => (
                <div key={i} className={styles.school}>
                  <span className={styles.schoolIcon}>
                    <IonIcon icon={schoolOutline} className={styles.icon} />
                    <span className={styles.title}>School</span>
                  </span>
                  <span
                    className={styles.name}
                    onClick={() => {
                      history.push(`/schools/${s.id}`);
                      close();
                    }}
                  >
                    {s.name}
                  </span>
                </div>
              ))}
          </div>
        )}

        <div>
          <div className={styles.label}>
            {editSavedSearch[language].label_2}
          </div>
          <div>
            <div className={styles.notify}>
              {editSavedSearch[language].notify_1} <span>instantly</span>
            </div>
            <div className={styles.notify}>
              {editSavedSearch[language].notify_2} <span>email</span>
            </div>
          </div>
        </div>
      </div>
      {error && (
        <div className={styles.error}>
          <span>{error}</span>
        </div>
      )}
      <Button
        title={editSavedSearch[language].button}
        type="green"
        style={{ height: "5rem", width: "100%", border: "1px solid #1F7A60" }}
        onClick={submitHandler}
        loading={loading}
      >
        <SearchIcon />
      </Button>
      <div
        className={styles.note}
        dangerouslySetInnerHTML={{ __html: editSavedSearch[language].note }}
      ></div>
    </div>
  );
};

export default EditSavedSearchModal;
