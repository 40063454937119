import { createSelector } from "reselect";

const selectMap = (state) => state.map;

export const selectMapListings = createSelector(
  [selectMap],
  (map) => map.listings
);

export const selectMapLoading = createSelector(
  [selectMap],
  (map) => map.loading
);

export const selectMapError = createSelector([selectMap], (map) => map.error);

export const selectMapSelectedLocation = createSelector(
  [selectMap],
  (map) => map.selectedLocation
);

export const selectMapRef = createSelector([selectMap], (map) => map.ref);

export const selectMapLocations = createSelector(
  [selectMap],
  (map) => map.locations
);

export const selectMapBoundaries = createSelector(
  [selectMap],
  (map) => map.boundary
);

export const selectMapFavourites = createSelector(
  [selectMap],
  (map) => map.favouritesOnly
);

export const selectActiveListing = createSelector(
  [selectMap],
  (map) => map.activeListing
);
