export const listTours = /* GraphQL */ `
  query ListTours(
    $filter: ModelTourFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTours(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        agent {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          currency
          measurement
          notifications
          createdAt
          updatedAt
        }
        agentId
        showingAgent {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          currency
          measurement
          notifications
          createdAt
          updatedAt
        }
        showingAgentId
        members
        membersData
        clients {
          items {
            client {
              name
            }
          }
          nextToken
        }
        status
        privateNote
        generalNote
        tourItems {
          items {
            id
            creator
            agentId
            showingAgentId
            members
            membersData
            tourId
            mlsNumber
            status
            startTime
            endTime
            order
            showingNote
            reviews {
              items {
                id
                tourItemId
                rate
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            notes {
              items {
                id
                content
                tourItemId
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            photos {
              items {
                id
                createdAt
                tourItemId
                updatedAt
                owner
              }
              nextToken
            }
            recordings {
              items {
                id
                createdAt
                tourItemId
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        meetupLocation
        meetupDescription
        meetupTime
        startTime
        endTime
        date
        shared
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listClientTours = /* GraphQL */ `
  query ListTours(
    $filter: ModelTourFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTours(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        agent {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          currency
          measurement
          notifications
          createdAt
          updatedAt
        }
        agentId
        showingAgent {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          currency
          measurement
          notifications
          createdAt
          updatedAt
        }
        showingAgentId
        members
        membersData
        clients {
          items {
            client {
              name
            }
          }
          nextToken
        }
        status
        generalNote
        tourItems {
          items {
            id
            creator
            agentId
            showingAgentId
            members
            membersData
            tourId
            mlsNumber
            status
            startTime
            endTime
            order
            showingNote
            reviews {
              items {
                id
                tourItemId
                rate
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            notes {
              items {
                id
                content
                tourItemId
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            photos {
              items {
                id
                createdAt
                tourItemId
                updatedAt
                owner
              }
              nextToken
            }
            recordings {
              items {
                id
                createdAt
                tourItemId
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        meetupLocation
        meetupDescription
        meetupTime
        startTime
        endTime
        date
        shared
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getClientToursByShareStatus = /* GraphQL */ `
  query GetToursByShareStatus(
    $shared: ShareStatus
    $sortDirection: ModelSortDirection
    $filter: ModelTourFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getToursByShareStatus(
      shared: $shared
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        creator
        agent {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          brokerageId
          currency
          measurement
          notifications
          createdAt
          updatedAt
        }
        agentId
        showingAgent {
          id
          repliersID
          givenName
          familyName
          name
          email
          phone
          website
          profilePicture
          licenseNumber
          areas
          availability
          brokerageId
          currency
          measurement
          notifications
          createdAt
          updatedAt
        }
        showingAgentId
        members
        membersData
        clients {
          nextToken
        }
        status
        generalNote
        tourItems {
          nextToken
        }
        meetupLocation
        meetupDescription
        meetupTime
        startTime
        endTime
        date
        shared
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getClientTour = /* GraphQL */ `
  query GetTour($id: ID!) {
    getTour(id: $id) {
      id
      title
      creator
      agent {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        website
        profilePicture
        licenseNumber
        areas
        availability
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        clients {
          nextToken
        }
        tours {
          nextToken
        }
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        currency
        measurement
        notifications
        createdAt
        updatedAt
      }
      agentId
      showingAgent {
        id
        repliersID
        givenName
        familyName
        name
        email
        phone
        website
        profilePicture
        licenseNumber
        areas
        availability
        brokerage {
          id
          name
          createdAt
          updatedAt
          owner
        }
        brokerageId
        clients {
          nextToken
        }
        tours {
          nextToken
        }
        favourites {
          nextToken
        }
        searches {
          nextToken
        }
        currency
        measurement
        notifications
        createdAt
        updatedAt
      }
      showingAgentId
      members
      membersData
      clients {
        items {
          id
          creator
          agentId
          clientId
          tourId
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      generalNote
      tourItems {
        items {
          id
          creator
          agentId
          showingAgentId
          members
          membersData
          tourId
          mlsNumber
          status
          startTime
          endTime
          order
          showingNote
          createdAt
          updatedAt
        }
        nextToken
      }
      meetupLocation
      meetupDescription
      meetupTime
      startTime
      endTime
      date
      shared
      createdAt
      updatedAt
    }
  }
`;

export const getTourSummary = /* GraphQL */ `
  query GetTour($id: ID!) {
    getTour(id: $id) {
      id
      title
      showingAgent {
        id
        givenName
        familyName
        name
        email
        phone
        website
        profilePicture
        licenseNumber
        areas
        availability
      }
      showingAgentId
      clients {
        items {
          client {
            id
            givenName
            familyName
            name
            email
            phone
          }
        }
        nextToken
      }
    }
  }
`;

export const getTourShareStatus = /* GraphQL */ `
  query GetTour($id: ID!) {
    getTour(id: $id) {
      shared
    }
  }
`;

export const getTourItemMLS = /* GraphQL */ `
  query GetTourItem($id: ID!) {
    getTourItem(id: $id) {
      mlsNumber
    }
  }
`;

export const listSchools = /* GraphQL */ `
  query ListSchools(
    $filter: ModelSchoolFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSchools(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        objVer
        name
        isCatholic
        isPublic
        isUseSchool
        gradeFrom
        gradeEnd
        isElementary
        isMiddle
        isHigh
        isEnglish
        isFrenchImmersion
        isExtendedFrench
        isAP
        isArts
        isGifted
        isIB
        isSport
        g3Avg
        g3WeightedAvg
        g6Avg
        g6WeightedAvg
        g9Avg
        g9APMWeightedAvg
        g9ACMWeightedAvg
        g10Avg
        ettieOverallAvg
        g3Rank
        g6Rank
        g9Rank
        g9ACRank
        g9APRank
        g10Rank
        province
        nation
        url
        schoolBoardId
        address
        city
        postalCode
        phone
        fax
        latitude
        longitude
        comments
        boundaryArray
        schoolsAssocationArray
        lastReviewDate
        createdAt
        updatedAt
        schoolBoard {
          id
          objVer
          name
          url
          boardType
          province
          nation
          address
          city
          postalCode
          phone
          fax
          createdAt
          updatedAt
        }
        ratings {
          items {
            id
            objVer
            schoolId
            year
            yearMark
            G3STU
            G3AR
            G3AW
            G3AM
            G3Rank
            G3Total
            G3FindSchoolRating
            G6STU
            G6AR
            G6AW
            G6AM
            G6Rank
            G6Total
            G6FindSchoolRating
            G9ACSTU
            G9ACM
            G9ACRank
            G9ACTotal
            G9APSTU
            G9APM
            G9APRank
            G9APTotal
            G9Rank
            G9Total
            G9FindSchoolRating
            G10OSSLTFSTUA
            G10OSSLTFSTUP
            G10OSSLTFSUCCESS
            G3Last5Avg
            G6Last5Avg
            G9Last5Avg
            createdAt
            updatedAt
          }
        }
      }
      nextToken
    }
  }
`;

export const getSchool = /* GraphQL */ `
  query GetSchool($id: ID!) {
    getSchool(id: $id) {
      id
      objVer
      name
      isCatholic
      isPublic
      isUseSchool
      gradeFrom
      gradeEnd
      isElementary
      isMiddle
      isHigh
      isEnglish
      isFrenchImmersion
      isExtendedFrench
      isAP
      isArts
      isGifted
      isIB
      isSport
      g3Avg
      g3WeightedAvg
      g6Avg
      g6WeightedAvg
      g9Avg
      g9APMWeightedAvg
      g9ACMWeightedAvg
      g10Avg
      ettieOverallAvg
      g3Rank
      g6Rank
      g9Rank
      g9ACRank
      g9APRank
      g10Rank
      EQAOScore
      province
      nation
      url
      schoolBoardId
      address
      city
      postalCode
      phone
      fax
      latitude
      longitude
      comments
      boundaryArray
      schoolsAssocationArray
      lastReviewDate
      createdAt
      updatedAt
      schoolBoard {
        id
        objVer
        name
        url
        boardType
        province
        nation
        address
        city
        postalCode
        phone
        fax
        createdAt
        updatedAt
        schools {
          nextToken
        }
        scores {
          items {
            id
            schoolBoardId
            year
            yearMark
            totalAvg
            g3STU
            g6STU
            g9ACSTU
            g9APSTU
            g3TS
            g6TS
            g9TS
            g10OSSLTFSTUA
            g10OSSLTFSTUP
            g10OSSLTFSUCCESS
            g3AR
            g3AW
            g3AM
            g6AR
            g6AW
            g6AM
            g9APM
            g9ACM
            g3Last5Avg
            g6Last5Avg
            g9Last5Avg
            g3FindSchoolRating
            g6FindSchoolRating
            g9FindSchoolRating
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      ratings {
        items {
          id
          objVer
          schoolId
          year
          yearMark
          G3STU
          G3AR
          G3AW
          G3AM
          G3Rank
          G3Total
          G3FindSchoolRating
          G6STU
          G6AR
          G6AW
          G6AM
          G6Rank
          G6Total
          G6FindSchoolRating
          G9ACSTU
          G9ACM
          G9ACRank
          G9ACTotal
          G9APSTU
          G9APM
          G9APRank
          G9APTotal
          G9Rank
          G9Total
          G9FindSchoolRating
          G10OSSLTFSTUA
          G10OSSLTFSTUP
          G10OSSLTFSUCCESS
          G3Last5Avg
          G6Last5Avg
          G9Last5Avg
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
