import { IonGrid, IonRow, IonCol, IonIcon } from "@ionic/react";
import styles from "./quick-contact.module.scss";
import {
  callOutline,
  chatboxEllipsesOutline,
  mailOutline,
} from "ionicons/icons";
import _ from "lodash";

const QuickContact = ({ contacts }) => {
  return (
    <IonGrid className={styles.quickContact}>
      {contacts &&
        contacts.length > 0 &&
        _.uniqBy(contacts, (c) => [c.id, c.role].join()).map(
          (contact, index) => (
            <IonRow className={styles.contact} key={index}>
              <IonCol>
                <div>
                  <span
                    className={styles.name}
                  >{`${contact.givenName} ${contact.familyName}`}</span>
                  <span className={styles.role}>{contact.role}</span>
                </div>
                <div className={styles.phone}>{contact.phone}</div>
                <div className={styles.email}>{contact.email}</div>
                <div className={styles.icons}>
                  <div
                    className={`${styles.mailIcon} ${
                      !contact.email && styles.disabled
                    }`}
                    onClick={() =>
                      contact.email
                        ? window.open(`mailto:${contact.email}`)
                        : null
                    }
                  >
                    <IonIcon icon={mailOutline} />
                  </div>
                  <div
                    className={`${styles.smsIcon} ${
                      !contact.phone && styles.disabled
                    }`}
                    onClick={() =>
                      contact.phone
                        ? window.open(`sms://${contact.phone}`)
                        : null
                    }
                  >
                    <IonIcon icon={chatboxEllipsesOutline} />
                  </div>
                  <div
                    className={`${styles.callIcon} ${
                      !contact.phone && styles.disabled
                    }`}
                    onClick={() =>
                      contact.phone
                        ? window.open(`tel://${contact.phone}`)
                        : null
                    }
                  >
                    <IonIcon icon={callOutline} />
                  </div>
                </div>
              </IonCol>
            </IonRow>
          )
        )}
    </IonGrid>
  );
};

export default QuickContact;
