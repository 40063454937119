import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonLoading,
  IonTitle,
} from "@ionic/react";
import styles from "./general-notes.module.scss";
import { chevronBackOutline } from "ionicons/icons";
import { selectCurrentClient } from "../../../../../redux/client/client.selectors";
import { updateClient } from "../../../../../amplify/graphql.utils";
import { updateToast } from "../../../../../redux/ui/ui.actions";

const GeneralNotes = ({ setIsOpen }) => {
  const dispatch = useDispatch();
  const client = useSelector(selectCurrentClient);
  const [loading, setLoading] = useState(false);
  const [note, setNote] = useState(
    client && client.toursNote ? client.toursNote : ""
  );
  const handleNoteChange = ({ target: { value } }) => {
    setNote(value);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await updateClient({ id: client.id, updates: { toursNote: note } });
      dispatch(
        updateToast({
          open: true,
          type: "success",
          message: "Note updated successfully.",
        })
      );
    } catch (err) {
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message: "Something went wrong!",
        })
      );
    }
    setLoading(false);
  };

  return (
    <>
      <IonHeader className={styles.appHeader}>
        <IonLoading isOpen={loading} />
        <IonToolbar className={styles.toolbar}>
          <IonButtons slot="start">
            <IonButton
              className={styles.backButton}
              slot="start"
              onClick={() => setIsOpen(false)}
            >
              <IonIcon icon={chevronBackOutline} />
              Back
            </IonButton>
          </IonButtons>
          <IonTitle className={styles.title}>My general notes</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={styles.generalNotes}>
        <IonGrid className={styles.body}>
          <IonRow>
            <IonCol className={styles.subtitle}>
              Keep all your tour-wide questions and thoughts in one place for
              easy reference. You can also make property-specific notes on each
              detail page.
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <textarea
                className={styles.textarea}
                name="general_note"
                value={note}
                onChange={handleNoteChange}
              ></textarea>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonButton
                shape="round"
                expand="block"
                className={styles.button}
                onClick={handleSubmit}
                disabled={client && note === client.toursNote}
              >
                Save changes
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </>
  );
};

export default GeneralNotes;
