import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Card from "../../../components/Card/card.component";
import ListingCard from "../../../components/ListingCard/listing-card.component";
import { getListing } from "../../../api/repliers";
import { selectUINotifications } from "../../../redux/ui/ui.selectors";
import MobileListingCard from "../../../app/components/ListingCard/listing-card.component";

const FavoriteItem = ({ item, isApp = false }) => {
  const [latestUpdate, setLatestUpdate] = useState();
  const notification = useSelector(selectUINotifications);

  useEffect(() => {
    let isFetching = true;
    const fetchListing = async () => {
      const { mlsNumber } = item;
      try {
        const listing = await getListing(mlsNumber);
        isFetching && setLatestUpdate(listing);
      } catch (error) {}
    };
    if (!latestUpdate) fetchListing();

    return () => (isFetching = false);
  }, [item, latestUpdate]);

  const getUpdates = () => {
    if (item) {
      const { favourites } = notification;
      const foundItem = favourites.find(
        (fav) => fav.mlsNumber === JSON.parse(item.listing).mlsNumber
      );
      if (foundItem) return foundItem.updates;
      return null;
    }
  };

  return isApp ? (
    <MobileListingCard
      listing={latestUpdate ? latestUpdate : JSON.parse(item.listing)}
      updates={getUpdates()}
      expand
    />
  ) : (
    <Card animated={true}>
      <ListingCard
        listing={latestUpdate ? latestUpdate : JSON.parse(item.listing)}
        updates={getUpdates()}
      />
    </Card>
  );
};
export default FavoriteItem;
