import styles from "./map.module.scss";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import "react-gallery-carousel/dist/index.css";
import "leaflet/dist/leaflet.css";
import providers from "./providers";
import L from "leaflet";

const Map = ({ location }) => {
  const position = [+location.latitude, +location.longitude];
  let DefaultIcon = L.icon({
    iconUrl:
      "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-violet.png",

    iconSize: [24, 36],
    iconAnchor: [12, 36],
  });

  L.Marker.prototype.options.icon = DefaultIcon;

  return (
    <div className={styles.map}>
      <MapContainer
        center={position}
        zoom="18"
        minZoom="12"
        className={styles.leaflet}
      >
        <TileLayer
          url={providers.maptiler.url}
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker position={position} />
      </MapContainer>
    </div>
  );
};

export default Map;
