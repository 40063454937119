import { CurrencyActionTypes } from "./currency.types";

export const fetchCurrencyStart = () => ({
  type: CurrencyActionTypes.FETCH_CURRENCY_START,
});

export const fetchCurrencySuccess = (rates) => ({
  type: CurrencyActionTypes.FETCH_CURRENCY_SUCCESS,
  payload: rates,
});

export const fetchCurrencyFailure = (error) => ({
  type: CurrencyActionTypes.FETCH_CURRENCY_FAILURE,
  payload: error,
});
