import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import styles from "./nav-logo.module.scss";
import { ReactComponent as LogoWithText } from "../../assets/svg/logo/logo-text.svg";
import { ReactComponent as Logo } from "../../assets/svg/logo/logo.svg";
import { ReactComponent as CollapseIn } from "../../assets/svg/collapseIn.svg";
import { ReactComponent as CollapseOut } from "../../assets/svg/collapseOut.svg";
import { selectUIMenuTab } from "../../redux/ui/ui.selectors";

const NavLogo = ({ collapse, setCollapse, hideCollapseButton }) => {
  const currentTab = useSelector(selectUIMenuTab);

  return (
    <div className={styles.navLogo}>
      {!hideCollapseButton && currentTab !== "/filters" && (
        <div className={styles.collapse}>
          {collapse ? (
            <CollapseOut
              className={styles.collapseOut}
              onClick={() => setCollapse(false)}
            />
          ) : (
            <CollapseIn
              className={styles.collapseIn}
              onClick={() => setCollapse(true)}
            />
          )}
        </div>
      )}

      <Link to="/" className={styles.logoContainer}>
        <div className={styles.logo}>
          {collapse ? <Logo /> : <LogoWithText />}
        </div>
      </Link>
    </div>
  );
};

export default NavLogo;
