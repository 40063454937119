import { IonIcon } from "@ionic/react";
import Card from "../../card.component";
import styles from "./not-found-card.module.scss";
import { telescopeOutline } from "ionicons/icons";

export const NotFoundCard = () => (
  <Card notFound={true} style={{ maxHeight: "42rem" }}>
    <div className={styles.container}>
      <div className={styles.iconContainer}>
        <IonIcon icon={telescopeOutline} className={styles.icon} />
      </div>
      <div className={styles.textContainer}>
        <h3 className={styles.header}>Adjust your search</h3>
        <p className={styles.text}>
          Uh oh! There are no matching results for your current search. Try
          zooming out or adjusting your filters.
        </p>
      </div>
    </div>
  </Card>
);

export default NotFoundCard;
