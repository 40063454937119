import { memo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Card from "../Card/card.component.jsx";
import PrimaryFilters from "../Filters/PrimaryFilters/primary-filters.component.jsx";
import Map from "../Map/map.component.jsx";
import styles from "./client-search-map.module.scss";
import withMap from "../../HOC/withMap/with-map.jsx";
import withFilters from "../../HOC/withFilters/with-filters";
import pcGif from "../../assets/gif/CraneMoving.gif";
import {
  selectSchools,
  selectSchoolsFilters,
} from "../../redux/schools/schools.selectors.js";
import { fetchSchoolsStart } from "../../redux/schools/schools.actions.js";
import { selectMapBoundaries } from "../../redux/map/map.selectors.js";

const ClientSearchMap = ({
  mapLoading,
  mapListings,
  mapError,
  mapRef,
  areas,
  updateSingleFilter,
  updateMultipleFilters,
  mapSelectedLocation,
  updateMapRef,
  updateMapSelectedLocation,
  mapLocations,
  updateMapLocations,
  fetchMapListings,
  filters,
  extraFilters,
  view,
  setView,
  hide,
  tabs,
}) => {
  const schools = useSelector(selectSchools);
  const boundaries = useSelector(selectMapBoundaries);
  const schoolsFilters = useSelector(selectSchoolsFilters);
  const dispatch = useDispatch();
  const { active } = schools;

  useEffect(() => {
    if (active && mapRef && mapRef.zoom > 11)
      dispatch(fetchSchoolsStart({ boundary: boundaries[0] }));
  }, [active, dispatch, boundaries, mapRef, schoolsFilters]);
  return (
    <div
      className={`${styles.search} ${view === "list" && styles.listView} ${
        hide && styles.hide
      }`}
    >
      <Card
        tabs={tabs}
        selectedTab={filters.type}
        setSelectedTab={(val) => {
          if (filters.status === "A" || val === "Pre-construction") {
            updateSingleFilter({ type: val.toLowerCase() });
          } else {
            updateMultipleFilters({
              type: val,
              lastStatus: val === "sale" ? "Sld" : "Lsd",
            });
          }
        }}
      >
        <PrimaryFilters />
        {filters.type === "pre-construction" && (
          <Card add={true} style={{ marginBottom: 0 }}>
            <div className={styles.comingSoon}>
              <img
                className={styles.animation}
                src={pcGif}
                alt="Coming soon!"
              />
              <h2 className={styles.header}>Coming soon!</h2>
              <span className={styles.content}>
                We are very excited to be building our pre-construction program
                and search portal! Check back soon to get in on the action!
              </span>
              {/* <span className={styles.content}>
                Check back soon to get in on the action!
              </span> */}
            </div>
          </Card>
        )}
        <Map
          areas={areas}
          listings={mapListings}
          loading={mapLoading}
          error={mapError}
          selectedLocation={mapSelectedLocation}
          updateSelectedLocation={updateMapSelectedLocation}
          fetchListings={fetchMapListings}
          mapLocations={mapLocations}
          updateMapLocations={updateMapLocations}
          updateRef={updateMapRef}
          filters={filters}
          extraFilters={extraFilters}
          hide={filters.type === "pre-construction"}
          schools={schools}
        />
      </Card>
    </div>
  );
};

export default memo(withMap(withFilters(ClientSearchMap)));
