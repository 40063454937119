import { IonIcon, IonGrid, IonRow, IonCol } from "@ionic/react";
import { arrowForwardCircleOutline } from "ionicons/icons";
import moment from "moment";
import { handleAppNavigation } from "../../../../../../../utils/functions";
import styles from "./tour-details.module.scss";

const TourDetails = ({ tour }) => {
  return (
    <IonGrid className={styles.tourDetails}>
      <IonRow>
        <IonCol className={styles.title}>Overview</IonCol>
      </IonRow>
      <IonRow className={styles.details}>
        <IonCol>Tour name</IonCol>
        <IonCol className={styles.bold}>{tour.title ? tour.title : "-"}</IonCol>
      </IonRow>
      <IonRow className={styles.details}>
        <IonCol>Tour date</IonCol>
        <IonCol className={styles.bold}>
          {tour.date ? moment(tour.date).format("MMM Do YYYY") : "-"}
        </IonCol>
      </IonRow>
      <IonRow className={styles.details}>
        <IonCol>Tour time</IonCol>
        <IonCol className={styles.bold}>
          {tour.startTime
            ? moment(tour.startTime, "HH:mm").format("h:mm A")
            : "-"}
          -{tour.endTime ? moment(tour.endTime, "HH:mm").format("h:mm A") : "-"}
        </IonCol>
      </IonRow>

      <IonRow>
        <IonCol className={styles.title}>Meetup details</IonCol>
      </IonRow>
      <IonRow className={styles.details}>
        <IonCol>Address</IonCol>
        <IonCol
          className={`${styles.bold} ${tour.meetupDescription && styles.link}`}
        >
          {tour.meetupLocation ? (
            <span
              style={{ display: "flex", alignItems: "center" }}
              onClick={() =>
                handleAppNavigation({
                  address: tour.meetupLocation,
                  byAddress: true,
                })
              }
            >
              {tour.meetupLocation} <IonIcon icon={arrowForwardCircleOutline} />
            </span>
          ) : (
            "-"
          )}
        </IonCol>
      </IonRow>
      <IonRow className={styles.details}>
        <IonCol>Time</IonCol>
        <IonCol className={styles.bold}>
          {tour.meetupTime
            ? moment(tour.meetupTime, "HH:mm").format("h:mm A")
            : "-"}
        </IonCol>
      </IonRow>
      <IonRow className={styles.description}>
        <IonCol>Description</IonCol>
        <IonCol className={styles.bold}>
          {tour.meetupDescription ? tour.meetupDescription : "-"}
        </IonCol>
      </IonRow>

      <IonRow>
        <IonCol className={styles.title}>Tour note from agent</IonCol>
      </IonRow>
      <IonRow>
        <IonCol className={styles.note}>
          {tour.generalNote ? tour.generalNote : "-"}
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default TourDetails;
