import { memo } from "react";
import styles from "./card.module.scss";
import Fade from "react-reveal/Fade";
import Tabs from "./components/Tabs/tabs.component";

const Card = ({
  children,
  add = false,
  notFound = false,
  mapCard = false,
  animated = false,
  view,
  tabs,
  selectedTab,
  setSelectedTab,
  ...rest
}) => {
  const renderTabs = () => {
    return <Tabs tabs={tabs} active={selectedTab} setActive={setSelectedTab} />;
  };

  if (animated) {
    return (
      <Fade>
        <div
          className={`${styles.card} ${add ? styles.add : ""} ${
            notFound ? styles.notFound : ""
          } ${mapCard ? styles.mapCard : ""}`}
          {...rest}
        >
          {tabs && renderTabs()}
          {children}
        </div>
      </Fade>
    );
  } else {
    return (
      <div
        className={`${styles.card} ${add ? styles.add : ""} ${
          notFound ? styles.notFound : ""
        } ${mapCard ? styles.mapCard : ""}`}
        {...rest}
      >
        {tabs && renderTabs()}
        {children}
      </div>
    );
  }
};

export default memo(Card);
