import { IonCol, IonGrid, IonRow } from "@ionic/react";
import EttieLogo from "../../../../../assets/img/Artboard 48@4x.png";
import styles from "./custom-auth-header.module.scss";

const CustomAuthHeader = ({ disable = false }) => {
  return (
    <>
      {!disable && (
        <IonGrid className={styles.authGrid}>
          <IonRow>
            <IonCol className={styles.img}>
              <img src={EttieLogo} alt="Ettie" />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <div className={styles.greeting}>Nice to see you!</div>
              <div className={styles.question}>Shall we jump right in?</div>
            </IonCol>
          </IonRow>
        </IonGrid>
      )}
    </>
  );
};

export default CustomAuthHeader;
