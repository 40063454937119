import styles from "./area.module.scss";
import { useSelector } from "react-redux";
import {
  IonList,
  IonItem,
  IonRadio,
  IonLabel,
  IonRadioGroup,
} from "@ionic/react";
import { chevronForwardOutline } from "ionicons/icons";
import withFilters from "../../../../../HOC/withFilters/with-filters";
import { selectLocationsValue } from "../../../../../redux/locations/locations.selectors";
import withMap from "../../../../../HOC/withMap/with-map";
import { useHistory } from "react-router-dom";

const AreaPage = ({
  filters: { area },
  updateMultipleFilters,
  mapRef,
  updateMapLocations,
}) => {
  const history = useHistory();
  const locations = useSelector(selectLocationsValue);
  const handleClick = ({ name, center }) => {
    if (name === "any") {
      updateMultipleFilters({ area: null, city: null, neighborhood: null });
      updateMapLocations({ reset: true });
      return;
    }

    if (area === name) {
      if (mapRef) {
        mapRef.panTo(center);
        mapRef.setZoom(10);
      }
      history.push(`/filters/areas/${name}`, { direction: "forward" });
      return;
    }

    history.push(`/filters/areas/${name}`, { direction: "forward" });
    if (mapRef) {
      mapRef.panTo(center);
      mapRef.setZoom(10);
    }
    updateMultipleFilters({ area: name, city: null, neighborhood: null });
    updateMapLocations({ reset: true });
  };
  return (
    <IonList>
      <IonRadioGroup value={area ? area : "any"}>
        <IonItem lines="full" onClick={() => handleClick({ name: "any" })}>
          <IonRadio
            value="any"
            className={styles.radio}
            slot="start"
            mode="md"
          />
          <IonLabel className={`${styles.label} ${!area && styles.selected}`}>
            Any
          </IonLabel>
        </IonItem>
        {locations.map((area) => (
          <IonItem
            key={area.name}
            lines="full"
            detail={true}
            detailIcon={chevronForwardOutline}
            onClick={() => handleClick(area)}
          >
            <IonRadio
              value={area.name}
              className={styles.radio}
              slot="start"
              mode="md"
            />
            <IonLabel
              className={`${styles.label} ${
                area === area.name && styles.selected
              }`}
            >
              {area.name}
            </IonLabel>
          </IonItem>
        ))}
      </IonRadioGroup>
    </IonList>
  );
};

export default withMap(withFilters(AreaPage));
