import { MapActionTypes } from "./map.types";

const INITIAL_STATE = {
  ref: null,
  listings: null,
  selectedLocation: null,
  activeListing: null,
  favouritesOnly: false,
  locations: {
    center: null,
    coordinates: null,
  },
  boundary: null,
  loading: false,
  error: undefined,
};

const mapReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MapActionTypes.FETCH_LISTINGS_START:
      return { ...state, error: undefined, loading: true };
    case MapActionTypes.FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        listings: action.payload ? action.payload : state.listings,
        error: undefined,
        loading: false,
      };
    case MapActionTypes.FETCH_LISTINGS_FAILURE:
      return { ...state, error: action.payload, loading: false };
    case MapActionTypes.UPDATE_SELECTED_LOCATION:
      return { ...state, selectedLocation: action.payload };
    case MapActionTypes.UPDATE_ACTIVE_LISTING:
      return { ...state, activeListing: action.payload };
    case MapActionTypes.UPDATE_MAP_REF:
      return { ...state, ref: action.payload };
    case MapActionTypes.UPDATE_LOCATIONS:
      return { ...state, locations: action.payload };
    case MapActionTypes.TOGGLE_FAVOURITES:
      if (action.payload) return { ...state, favouritesOnly: action.payload };
      return { ...state, favouritesOnly: !state.favouritesOnly };
    case MapActionTypes.UPDATE_BOUNDARIES:
      return { ...state, boundary: action.payload };
    default:
      return state;
  }
};

export default mapReducer;
