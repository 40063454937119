import { useState } from "react";
import { useSelector } from "react-redux";
import { updateClient } from "../../../amplify/graphql.utils";
import useComponentVisible from "../../../hooks/useComponentVisible";
import { selectCurrentClient } from "../../../redux/client/client.selectors";
import Button from "../../Form/Button/button.component";
import DropdownContainer from "../../Form/DropdownContainer/dropdown-container.component";
import Select from "../../Form/Select/select.component";
import { changeCurrency } from "../../../data/customModal";
import styles from "./edit-currency-modal.module.scss";

const EditCurrencyModal = ({ setType, language }) => {
  const [loading, setLoading] = useState(false);
  const client = useSelector(selectCurrentClient);
  let currencies = [
    "CAD - Canadian Dollar",
    "USD - US Dollar",
    "AUD - Australian Dollar",
    "CNY - Chinese Renminbi",
    "EUR - Euro (European)",
    "GBP - UK Pound Sterling",
    "INR - Indian Rupee",
    "JPY - Japanese Yen",
    "IRR - Iranian Rial",
    "MXN - Mexican Peso",
  ];
  const { isOpen, setIsOpen } = useComponentVisible(false);
  const [currency, setCurrency] = useState(client.currency.toUpperCase());
  const onTypeChange = () => {
    return currency.substring(0, 3);
  };

  const updateHandler = async () => {
    setLoading(true);
    await updateClient({
      id: client.id,
      updates: { currency: currency.substring(0, 3).toLowerCase() },
    });
    setLoading(false);
    setType("updatedCurrency");
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>{changeCurrency[language].header}</div>
      <div className={styles.subtitle}>{changeCurrency[language].subtitle}</div>

      <div className={styles.content}>
        <div className={styles.label}>{changeCurrency[language].currency}</div>
        <div>
          <Select
            label=""
            title={onTypeChange()}
            open={isOpen}
            onClick={() => setIsOpen((isOpen) => !isOpen)}
            style={{ width: "9rem" }}
          />
          <div className={styles.dropdown}>
            <DropdownContainer open={isOpen}>
              <div className={styles.items}>
                {currencies.map((currency, index) => (
                  <div
                    key={index}
                    className={styles.currency}
                    onClick={() => {
                      setIsOpen(false);
                      setCurrency(currency);
                    }}
                  >
                    {currency}
                  </div>
                ))}
              </div>
            </DropdownContainer>
          </div>
        </div>
      </div>
      <Button
        title={changeCurrency[language].button}
        type="purple"
        style={{ height: "5rem", width: "100%", border: "1px solid #7158ad" }}
        onClick={updateHandler}
        loading={loading}
      />
      <div className={styles.disclaimer}>
        For estimation purposes only and rates may not reflect actual exchange
        rates. Exchange rates are based on posted Central Bank rates. Rates are
        updated every 4-5 hours.
      </div>
    </div>
  );
};

export default EditCurrencyModal;
