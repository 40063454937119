import { Auth } from "aws-amplify";
import { InAppBrowser } from "@ionic-native/in-app-browser";
import { getPlatforms } from "@ionic/core";

const getIdToken = async () => ({
  Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
});

const awsmobile = {
  aws_project_region: "ca-central-1",
  aws_cognito_identity_pool_id:
    "ca-central-1:ed2a91e4-eaba-4fed-a9b9-92bf7d4a284c",
  aws_cognito_region: "ca-central-1",
  aws_user_pools_id: "ca-central-1_zKcP707dS",
  aws_user_pools_web_client_id: "56igl16e5vkf5aa4qq742g4ruj",
  aws_user_files_s3_bucket: "ettieusermedia114145-prod",
  aws_user_files_s3_bucket_region: "ca-central-1",

  API: {
    graphql_headers: getIdToken,
  },

  oauth: {
    domain: "auth.ettie.ai",
    scope: ["email", "openid", "profile", "aws.cognito.signin.user.admin"],
    redirectSignIn: getPlatforms().includes("capacitor")
      ? "ettie://ettie.ai/"
      : "https://www.ettie.ai/",

    redirectSignOut: getPlatforms().includes("capacitor")
      ? "ettie://ettie.ai/"
      : "https://www.ettie.ai/",

    responseType: "code",
  },
  federationTarget: "COGNITO_USER_AND_IDENTITY_POOLS",
  aws_cloud_logic_custom: [
    {
      name: "AdminQueries",
      endpoint:
        "https://fyak2y41c6.execute-api.ca-central-1.amazonaws.com/prod",
      region: "ca-central-1",
    },
    {
      name: "ettieREST",
      endpoint:
        "https://m65e5ksqz3.execute-api.ca-central-1.amazonaws.com/prod",
      region: "ca-central-1",
    },
  ],
  aws_appsync_graphqlEndpoint:
    "https://bc3lhpbv2negjmyhdigigafx3i.appsync-api.ca-central-1.amazonaws.com/graphql",
  aws_appsync_region: "ca-central-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
};

// if (getPlatforms().includes("capacitor")) {
//   awsmobile.oauth.urlOpener = async (url, redirectUrl) => {
//     try {
//       const browser = InAppBrowser.create(url, redirectUrl, {
//         clearcache: true,
//         hideurlbar: true,
//         hidenavigationbutton: true,
//         toolbar: "yes",
//         toolbarcolor: "#110D1B",
//         closebuttoncolor: "#F4F4F7",
//         closebuttoncaption: "Back",
//         toolbarposition: "top",
//       });
//       browser.on("loadstart").subscribe((e) => {
//         if (e.url.startsWith("https://ettie.ai/?")) {
//           window.location.href = e.url.split("https://ettie.ai")[1];
//           browser.close();
//         }
//       });
//     } catch (error) {
//       console.log(error);
//     }
//   };
// }

export default awsmobile;
