import { useEffect } from "react";
import { MapContainer, TileLayer, Marker, LayersControl } from "react-leaflet";
import { IonIcon } from "@ionic/react";
import { chevronBackOutline, chevronForwardOutline } from "ionicons/icons";
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";
import "leaflet/dist/leaflet.css";
import styles from "./detail-page-carousel.module.scss";
import providers from "./providers";
import L from "leaflet";

const DetailPageCarousel = ({
  images,
  location: { latitude, longitude },
  showMap,
}) => {
  const position = [+latitude, +longitude];
  let DefaultIcon = L.icon({
    iconUrl:
      "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-violet.png",

    iconSize: [24, 36],
    iconAnchor: [12, 36],
  });

  L.Marker.prototype.options.icon = DefaultIcon;

  const leftIcon = () => {
    return (
      <IonIcon icon={chevronBackOutline} className={styles.carouselIcon} />
    );
  };
  const rightIcon = () => {
    return (
      <IonIcon icon={chevronForwardOutline} className={styles.carouselIcon} />
    );
  };

  useEffect(() => {
    let carouselNode = document.querySelector(".carousel");
    let thumbnailNode = carouselNode.childNodes[1];
    let indexBoard = carouselNode.childNodes[0].childNodes[1];
    indexBoard.className += ` ${styles.indexBoard}`;
    thumbnailNode.className += ` ${styles.thumbnail}`;
  }, []);

  return (
    <div className={styles.container}>
      <Carousel
        id="carousel"
        className={`${styles.carousel} carousel`}
        images={images}
        style={{
          display: "grid",
          rowGap: "2rem",
          margin: "2rem auto 4rem",
          height: "55rem",
          width: "95%",
          borderRadius: "10px",
          backgroundColor: "white",
        }}
        hasMediaButton={false}
        shouldLazyLoad={true}
        // hasIndexBoard={false}
        canAutoPlay={false}
        leftIcon={leftIcon()}
        rightIcon={rightIcon()}
        thumbnailHeight="81.8px"
        thumbnailWidth="135.52px"
        shouldMaximizeOnClick={true}
        shouldMinimizeOnClick={true}
      />
      {showMap && (
        <div className={styles.map}>
          <MapContainer
            center={position}
            zoom="18"
            minZoom="12"
            className={styles.leaflet}
          >
            <LayersControl>
              <LayersControl.BaseLayer checked name="Default">
                <TileLayer
                  url={providers.maptiler.url}
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer name="Satellite">
                <TileLayer
                  url={providers.google.url}
                  attribution='&copy; <a href="http://maps.google.com">Google Maps</a>'
                />
              </LayersControl.BaseLayer>
            </LayersControl>

            <Marker position={position} />
          </MapContainer>
        </div>
      )}
    </div>
  );
};

export default DetailPageCarousel;
