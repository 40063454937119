import { ReactComponent as GooglePlay } from "../../assets/svg/stores/google.svg";
import { ReactComponent as AppleStore } from "../../assets/svg/stores/apple.svg";
import { IonIcon } from "@ionic/react";
import {
  logoFacebook,
  logoInstagram,
  logoLinkedin,
  logoTwitter,
} from "ionicons/icons";
import styles from "./footer.module.scss";
import { Link } from "react-router-dom";
import { InAppBrowser } from "@ionic-native/in-app-browser";
import jsonObj from "../../../package.json";
import GooglePlayStore from "../../assets/svg/stores/google.svg";

const date = new Date();

const Footer = () => {
  const sendEmailHandler = () =>
    InAppBrowser.create(
      `mailto:info@ettie.ai?Subject=Connect%20with%20us!`,
      "_blank",
      {
        hideurlbar: true,
        hidenavigationbutton: true,
        toolbar: "yes",
        toolbartranslucent: "yes",
      }
    );

  return (
    <div className={styles.footer}>
      <div className={styles.stores}>
        <AppleStore
          className={styles.applestore}
          onClick={() =>
            window.open(
              "https://apps.apple.com/ca/app/ettie/id1578020676",
              "_blank"
            )
          }
        />
        {/* <GooglePlay
          className={styles.store}
          onClick={() =>
            window.open(
              "https://play.google.com/store/apps/details?id=com.ettie.app",
              "_blank"
            )
          }
        /> */}
        <img
          src={GooglePlayStore}
          className={styles.googlestore}
          onClick={() =>
            window.open(
              "https://play.google.com/store/apps/details?id=com.ettie.app",
              "_blank"
            )
          }
        />
      </div>

      <div className={styles.contact}>
        <div className={styles.question}>
          Questions, comments or suggestions?
          <br />
          <span className={styles.email}>
            Email us at{" "}
            <span className={styles.link} onClick={sendEmailHandler}>
              {" "}
              info@ettie.ai
            </span>
          </span>
        </div>
        <div className={styles.social}>
          <span className={styles.label}>Follow the action!</span>
          <div className={styles.icons}>
            <IonIcon
              icon={logoFacebook}
              className={styles.icon}
              onClick={() =>
                InAppBrowser.create(
                  "https://www.facebook.com/AskEttie",
                  "_system"
                )
              }
            />
            <IonIcon
              icon={logoInstagram}
              onClick={() =>
                InAppBrowser.create(
                  "https://www.instagram.com/ettie.ai",
                  "_system"
                )
              }
            />
            <IonIcon
              icon={logoLinkedin}
              onClick={() =>
                InAppBrowser.create(
                  "https://www.linkedin.com/company/ettie-ai",
                  "_system"
                )
              }
            />
            <IonIcon
              icon={logoTwitter}
              onClick={() =>
                InAppBrowser.create("https://twitter.com/ettie_ai", "_system")
              }
            />
          </div>
        </div>
      </div>

      <div className={styles.details}>
        <div className={styles.title}> Owned and operated by</div>
        <div className={styles.address}>Ettie Realty Inc.</div>
        <div className={styles.address}>
          20 Bay St., 11th Floor Toronto, ON M5J 2N8
        </div>
        <div className={styles.copyRight}>
          &copy;{date.getFullYear()} Ettie Realty Inc. All rights reserved.
        </div>
        <div className={styles.termsContainer}>
          <div className={styles.terms}>
            <span className={styles.link}>
              <Link to="/terms">Terms of use</Link>
            </span>
            <span className={styles.link}>
              <Link to="/privacy">Privacy Policy</Link>
            </span>
          </div>

          <div className={styles.version}>App version: {jsonObj.version}</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
