import { IonIcon } from "@ionic/react";
import LocationIcon from "../../../../../../assets/svg/location-pin.svg";
import PropertyTypeIcon from "../../../../../../assets/svg/REA103_Icons-01a_PropertyType.svg";
import BathIcon from "../../../../../../assets/svg/REA103_Icons-01a_Bath.svg";
import ParkingIcon from "../../../../../../assets/svg/REA103_Icons-01a_Parking.svg";
import SqftIcon from "../../../../../../assets/svg/REA103_Icons-01a_SquareFeet.svg";
import { detailPageDescription } from "../../../../../../data/detailsPage";
import {
  buildPropertyInfo,
  buildParkingInfo,
} from "../../../../../../utils/functions";
import styles from "./detail-page-description.module.scss";
import Button from "../../../../../../components/Form/Button/button.component";
import { bedOutline } from "ionicons/icons";

const DetailPageDescription = ({ listing, offerFormHandler, language }) => {
  const { address, details } = listing;

  return (
    <div className={styles.descriptionContainer}>
      <div className={styles.title}>
        {detailPageDescription[language].description}
      </div>
      <div className={styles.location}>
        <div>
          <IonIcon src={LocationIcon} className={styles.icon} />
          <span>
            {address.unitNumber && address.unitNumber + " - "}
            {address.streetNumber +
              " " +
              address.streetName +
              " " +
              address.streetSuffix +
              " " +
              `${address.streetDirection && address.streetDirection + " "}` +
              address.city +
              ", " +
              address.zip}
          </span>
        </div>
        <div>
          <IonIcon src={PropertyTypeIcon} className={styles.icon} />
          <span>
            {" "}
            {buildPropertyInfo(
              listing.class,
              details.propertyType,
              details.style
            )}
          </span>
        </div>
      </div>
      <div className={styles.details}>
        <div>
          <IonIcon icon={bedOutline} className={styles.icon} />
          <span>
            {`${details.numBedrooms ? details.numBedrooms : "-"} ${
              details.numBedroomsPlus !== "" && details.numBedroomsPlus !== null
                ? " + " + details.numBedroomsPlus
                : ""
            }`}{" "}
            {detailPageDescription[language].bed}
          </span>
        </div>
        <div>
          <IonIcon src={BathIcon} className={styles.icon} />
          <span>
            {details.numBathrooms} {detailPageDescription[language].bath}
          </span>
        </div>
        <div>
          <IonIcon src={ParkingIcon} className={styles.icon} />
          <span>
            {buildParkingInfo(
              details.numGarageSpaces,
              details.numParkingSpaces
            )}
          </span>
        </div>
        {details.sqft && details.sqft.length > 0 && (
          <div>
            <IonIcon src={SqftIcon} className={styles.icon} />
            <span>{details.sqft} sqft</span>
          </div>
        )}
      </div>
      <div className={styles.description}>
        {`${details.description}\n${details.extras}`}
      </div>
      <div className={styles.offerForm}>
        <Button
          title={detailPageDescription[language].offer}
          type="blue"
          border={true}
          style={{
            height: "5rem",
            width: "100%",
          }}
          onClick={offerFormHandler}
        />
      </div>
    </div>
  );
};

export default DetailPageDescription;
