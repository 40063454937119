import { IonIcon } from "@ionic/react";
import { checkmarkOutline, closeOutline } from "ionicons/icons";
import Button from "../../Form/Button/button.component";

import styles from "./successful-change-modal.module.scss";

const SuccessfulChangeModal = ({
  close,
  title,
  subtitle,
  isgreen = false,
  ...rest
}) => (
  <div className={styles.container}>
    <div className={styles.pic} {...rest}>
      <IonIcon
        icon={checkmarkOutline}
        className={isgreen ? styles.green : ""}
      />
    </div>
    <div className={styles.title}>{title}</div>
    <div className={styles.subtitle}>{subtitle}</div>
    <div className={styles.btn}>
      <Button
        title="close"
        type="clear"
        style={{
          color: "#707070",
          marginTop: "0.5rem",
          width: "100%",
        }}
        onClick={close}
      >
        <IonIcon icon={closeOutline} />
      </Button>
    </div>
  </div>
);

export default SuccessfulChangeModal;
