import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { IonIcon, IonButton } from "@ionic/react";
import { useHistory } from "react-router-dom";
import {
  closeOutline,
  arrowBackOutline,
  closeCircleOutline,
  personAddOutline,
  personOutline,
} from "ionicons/icons";
import styles from "./tour-attendees.module.scss";
import useComponentVisible from "../../../../../hooks/useComponentVisible";
import Select from "../../../../../components/Form/Select/select.component";
import DropdownContainer from "../../../../../components/Form/DropdownContainer/dropdown-container.component";
import Button from "../../../../../components/Form/Button/button.component";
import { useSelector } from "react-redux";
import { selectCurrentClient } from "../../../../../redux/client/client.selectors";
import { getAgents, getClients } from "../../../../../amplify/graphql.utils";
import {
  addClientsToTourStart,
  clearCurrentTour,
  fetchToursStart,
  updateTourStart,
} from "../../../../../redux/tour/tour.actions";
import IsVisibleTag from "../../../../../components/IsVisibleTag/is-visible-tag.component";
import { updateToast } from "../../../../../redux/ui/ui.actions";
import SearchableSelect from "../../../../../components/Form/SearchableSelect/searchable-search.component";

const TourAttendees = ({ tour, back, reset }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [agents, setAgents] = useState();
  const [client, setClient] = useState();
  const [guests, setGuests] = useState([]);
  const agent = useSelector(selectCurrentClient);
  const [clientsList, setClientsList] = useState();
  const [allClients, setAllClients] = useState();
  const [showingAgent, setShowingAgent] = useState();

  useEffect(() => {
    const fetchAgents = async () => setAgents(await getAgents());
    const fetchClinets = async () => {
      const clients = await getClients(agent.brokerageId);
      setAllClients(clients);
      setClientsList(clients);
    };

    fetchAgents();
    fetchClinets();
  }, [agent]);

  const {
    ref: refShowingAgent,
    isOpen: isOpenShowingAgent,
    setIsOpen: setIsOpenShowingAgent,
  } = useComponentVisible(false);

  const handleClear = () => {
    setClient();
    setGuests([]);
    setShowingAgent();
  };

  const handleSubmit = () => {
    try {
      const members = [client.id];
      if (guests.length) {
        guests.forEach((g) => members.push(g.id));
      }

      const membersData = [
        ...JSON.parse(tour.membersData),
        {
          id: client.id,
          givenName: client.givenName,
          familyName: client.familyName,
          email: client.email,
          phone: client.phone,
          role: "Client",
        },
      ];

      if (showingAgent)
        membersData.push({
          id: showingAgent.id,
          givenName: showingAgent.givenName,
          familyName: showingAgent.familyName,
          email: showingAgent.email,
          phone: showingAgent.phone,
          role: "Showing Agent",
        });

      guests.forEach((g) =>
        membersData.push({
          id: g.id,
          givenName: g.givenName,
          familyName: g.familyName,
          email: g.email,
          phone: g.phone,
          role: "Additional Guest",
        })
      );

      dispatch(
        updateTourStart({
          id: tour.id,
          showingAgentId: showingAgent.id,
          members,
          membersData: JSON.stringify(membersData),
          status: "pending",
        })
      );
      dispatch(
        addClientsToTourStart({
          tourId: tour.id,
          agentId: tour.agent.id,
          clients: members,
          owner: agent.id,
        })
      );
    } catch (err) {
      dispatch(
        updateToast({
          open: true,
          message: "Something went wrong!",
          type: "error",
        })
      );
    }
  };

  useEffect(() => {
    if (
      tour &&
      tour.clients &&
      tour.clients.items &&
      tour.clients.items.length
    ) {
      dispatch(fetchToursStart());
      dispatch(clearCurrentTour());
      reset();
      history.push(`/tours/${tour.id}`);
    }
  }, [dispatch, history, reset, tour]);

  const handleChange = async (val) => {
    setClientsList(await getClients(agent.brokerageId, val));
  };

  return (
    <div className={styles.tourAttendees}>
      <div className={styles.details}>
        <div className={styles.label}>Tour attendees</div>
        <IsVisibleTag isVisible={true} />
      </div>
      <div className={styles.attendees}>
        <div className={styles.rows}>
          <SearchableSelect
            title="Select the main client"
            type="radio"
            // parentRef={selfRef}
            items={clientsList && clientsList.filter((c) => !c.deactivated)}
            selected={client}
            setSelected={setClient}
            label={client ? `Client: ${client.name}` : "Select client"}
            onSave={(client) => setClient(client)}
            onChange={handleChange}
            icon={client ? personOutline : personAddOutline}
            style={{ "--background": "white" }}
          />

          {client && (
            <SearchableSelect
              title="Select additional guests"
              type="checkbox"
              // parentRef={selfRef}
              items={
                clientsList &&
                clientsList
                  .filter((c) => !c.deactivated)
                  .filter((c) => c.id !== client.id)
              }
              selected={guests}
              setSelected={setGuests}
              onChange={handleChange}
              label="Add additional guests"
              icon={personAddOutline}
            />
          )}
        </div>
        {guests.length > 0 && (
          <div className={styles.guests}>
            {guests
              .filter((g) => g !== null)
              .map((g, i) => (
                <div className={styles.guest} key={i}>
                  <IonIcon className={styles.icon} icon={personOutline} />
                  <span className={styles.label}>
                    Additional guest: {g.name}
                  </span>
                  <IonIcon
                    className={styles.closeIcon}
                    icon={closeOutline}
                    onClick={() =>
                      setGuests(guests.filter((guest) => guest.id !== g.id))
                    }
                  />
                </div>
              ))}
          </div>
        )}

        <div className={styles.rows}>
          <div ref={refShowingAgent} className={styles.container}>
            <Select
              label=""
              title={
                showingAgent
                  ? `${showingAgent.givenName} ${showingAgent.familyName}`
                  : "Showing agent"
              }
              style={{ height: "4rem", width: "100%" }}
              buttonStyle={{ height: "100%" }}
              open={isOpenShowingAgent}
              onClick={() => setIsOpenShowingAgent(!isOpenShowingAgent)}
            />
            <DropdownContainer
              open={isOpenShowingAgent}
              style={{
                position: "absolute",
                top: "auto",
                left: "auto",
                height: "15rem",
              }}
            >
              {agents &&
                agents.map((a) => (
                  <div
                    key={a.id}
                    className={styles.item}
                    onClick={() => {
                      setShowingAgent(a);
                      setIsOpenShowingAgent(false);
                    }}
                  >{`${a.givenName} ${a.familyName}`}</div>
                ))}
            </DropdownContainer>
          </div>
        </div>
      </div>
      <Button
        title="Complete tour set up"
        type="purple"
        style={{
          border:
            client && showingAgent ? "1px solid #7158AD" : "1px solid #e5e5e5",
          width: "100%",
          height: "5rem",
          marginTop: "2rem",
        }}
        disabled={!client || !showingAgent}
        onClick={handleSubmit}
      />
      <div className={styles.btns}>
        <IonButton
          fill="clear"
          className={`${styles.btn} ${styles.back}`}
          onClick={back}
        >
          <IonIcon icon={arrowBackOutline} className={styles.icon} />
          Back
        </IonButton>

        <IonButton
          fill="clear"
          className={`${styles.btn} ${styles.close}`}
          onClick={handleClear}
        >
          <IonIcon icon={closeOutline} className={styles.icon} />
          Clear
        </IonButton>
      </div>
    </div>
  );
};

export default TourAttendees;
