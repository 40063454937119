import { useRef } from "react";
import styles from "./join.module.scss";
import { ReactComponent as HomeIcon } from "../../../../assets/svg/listing.svg";
import { ReactComponent as FavoriteIcon } from "../../../../assets/svg/star.svg";
import { ReactComponent as SearchIcon } from "../../../../assets/svg/search.svg";
import { ReactComponent as WatchedIcon } from "../../../../assets/svg/eye.svg";
import Button from "../../../../components/Form/Button/button.component";
import { IonPage, IonContent } from "@ionic/react";
import AppHeader from "../../Header/header.component";
import { useHistory } from "react-router-dom";

const Join = () => {
  const history = useHistory();
  const ref = useRef();

  return (
    <IonPage ref={ref}>
      <AppHeader title="Join the club!" hasRightButton={false} />
      <IonContent>
        <div className={styles.content}>
          <div className={styles.msg}>
            Register for a free account to unlock to all features, including
            sold prices and property histories.
          </div>
          <div className={styles.item}>
            <span className={styles.icon}>
              <HomeIcon className={styles.svg} />
            </span>
            <span className={styles.label}>
              see all property details and request a showing
            </span>
          </div>
          <div className={styles.item}>
            <span className={styles.icon}>
              <SearchIcon className={styles.svg} />
            </span>
            <span className={styles.label}>
              save your searches for quick access
            </span>
          </div>
          <div className={styles.item}>
            <span className={styles.icon}>
              <FavoriteIcon className={styles.svg} />
            </span>
            <span className={styles.label}>
              save favourites and receive update alerts
            </span>
          </div>
          <div className={styles.item}>
            <span className={styles.icon}>
              <WatchedIcon className={styles.svg} />
            </span>
            <span className={styles.label}>
              schedule a showing tour with an Ettie agent
            </span>
          </div>
          <div className={styles.btn}>
            <Button
              type="purple"
              title="Let's do it!"
              border
              style={{
                flex: 1,
                fontWeight: 500,
                fontSize: "1.5rem",
                padding: "2.5rem 1rem",
              }}
              onClick={() => history.replace("/signup")}
            />
          </div>
          <div className={styles.signIn}>
            Already a member?{" "}
            <span
              className={styles.link}
              onClick={() => history.replace("/signin")}
            >
              Sign in
            </span>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Join;
