import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, Link, useRouteMatch, useHistory } from "react-router-dom";
import { selectUINotifications } from "../../redux/ui/ui.selectors";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import { getGreetingTime, handleAppNavigation } from "../../utils/functions";
import { IonIcon, IonAlert } from "@ionic/react";
import Button from "../Form/Button/button.component";
import ProfileDropdown from "../ProfileDropdown/profile-dropdown.component.jsx";
import HeaderBadge from "./components/Badge/badge-component";
import styles from "./header.module.scss";
import {
  arrowBackOutline,
  duplicateOutline,
  navigateOutline,
  notificationsOutline,
  peopleOutline,
} from "ionicons/icons";
import {
  openAuthModal,
  openModal,
  updateToast,
} from "../../redux/ui/ui.actions";
import { isAgent } from "../../amplify/auth.utils";
import { API, graphqlOperation } from "aws-amplify";
import { getTourItemMLS } from "../../graphql/custom-queries";
import { getListing } from "../../api/repliers";
import {
  getShareStatus,
  getSingleTour,
  shareTour,
} from "../../amplify/graphql.utils";

const Header = ({ isAuthenticated }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const notifications = useSelector(selectUINotifications);
  const history = useHistory();
  const location = useLocation();
  const [agent, setAgent] = useState(undefined);
  const match = useRouteMatch();
  const [tour, setTour] = useState();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);

  const handleDirection = async () => {
    setLoading(true);
    try {
      const id = match.params.tourItemId || match.params.tourId;
      const {
        data: {
          getTourItem: { mlsNumber },
        },
      } = await API.graphql(graphqlOperation(getTourItemMLS, { id }));

      if (mlsNumber) {
        const listing = await getListing(mlsNumber);
        if (listing) {
          handleAppNavigation({
            map: listing.map,
            address: listing.address,
            byAddress: true,
          });
        }
      }
    } catch (err) {}
    setLoading(false);
  };

  const checkShareDisabled = async () => {
    try {
      const isShared = await getShareStatus(match.params.tourId);
      return isShared;
    } catch (err) {
      return false;
    }
  };

  const handleShare = async () => {
    setLoading(true);

    try {
      const res = await shareTour(match.params.tourId);
      if (res === "shared") {
        dispatch(openModal({ current: "shareTour", isProtected: true }));
        setTour(undefined);
      }
    } catch (err) {
      if (err.message === "C") {
        dispatch(
          updateToast({
            open: true,
            type: "error",
            message: "Tour is missing client or showing agent.",
          })
        );
      } else {
        dispatch(
          updateToast({
            open: true,
            type: "error",
            message: "Something went wrong!",
          })
        );
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    const checkIsAgent = async () => {
      const status = await isAgent();
      setAgent(status);
    };
    checkIsAgent();
  }, []);

  useEffect(() => {
    const fetchTour = async () => {
      const tour = await getSingleTour(match.params.tourId);
      // if (tour&& tour.shared === "unshared") setTour(tour);
      setTour(tour);
    };
    if (agent && ["/tours/:tourId"].includes(match.path)) {
      fetchTour();
    }
  }, [agent, match.params.tourId, match.path, tour && tour.status]);

  const returnHandler = () => {
    if (location.state?.from?.length) {
      const loc = location.state.from.pop();

      history.push(loc, {
        from: location.state.from.filter((l) => l !== loc),
      });
    } else {
      history.push("/listings");
    }
  };

  const renderMessage = () => {
    if (!isAuthenticated) {
      return (
        <div className={styles.greeting}>
          <h1 className={styles.greetingText}>{getGreetingTime()}!</h1>
        </div>
      );
    }

    switch (match.path) {
      case "/user":
        return (
          <div className={styles.greeting}>
            <h1 className={styles.greetingText}>Account settings</h1>
          </div>
        );

      case "/favourites":
        return (
          <div className={styles.greeting}>
            <h1 className={styles.greetingText}>Favourites</h1>

            {notifications.favourites.length > 0 && (
              <HeaderBadge
                value={`${notifications.favourites.length} ${
                  notifications.favourites.length > 1 ? "updates" : "update"
                }`}
              />
            )}
          </div>
        );

      case "/searches":
        return (
          <div className={styles.greeting}>
            <h1 className={styles.greetingText}>Saved searches</h1>
          </div>
        );

      case "/tours":
        return (
          <>
            {agent ? (
              <div className={styles.greeting}>
                <h1 className={styles.greetingText}>All tours</h1>
              </div>
            ) : (
              <div className={styles.greeting}>
                <h1 className={styles.greetingText}>My tours</h1>
              </div>
            )}
          </>
        );

      case "/tours/:tourId":
        return (
          <>
            {agent ? (
              <div
                className={styles.greeting}
                style={{ flexDirection: "row", alignItems: "center" }}
              >
                <h1 className={styles.greetingText}>Tour summary</h1>
                <div className={styles.shareStatus}>
                  {agent &&
                  tour &&
                  tour.shared === "unshared" &&
                  tour.status !== "archived" ? (
                    <div className={styles.notShared}>
                      not shared with client
                    </div>
                  ) : (
                    agent &&
                    tour &&
                    tour.shared === "shared" &&
                    tour.status !== "archived" && (
                      <div className={styles.shared}>shared with client</div>
                    )
                  )}
                </div>
              </div>
            ) : (
              <div className={styles.greeting}>
                <h1 className={styles.greetingText}>Details</h1>
              </div>
            )}
          </>
        );

      case "/tours/details/:tourItemId":
        return (
          <div className={styles.greeting}>
            <h1 className={styles.greetingText}>Details</h1>
          </div>
        );

      case "/schools/:id":
        return (
          <div className={styles.schools}>
            <div
              className={styles.return}
              onClick={() =>
                history.length > 2
                  ? history.goBack()
                  : history.replace("/listings")
              }
            >
              <IonIcon icon={arrowBackOutline} />
              <div>Back</div>
            </div>
          </div>
        );

      case "/school-terms":
        return (
          <div className={styles.schools}>
            <div
              className={styles.return}
              onClick={() =>
                history.length > 2
                  ? history.goBack()
                  : history.replace("/listings")
              }
            >
              <IonIcon icon={arrowBackOutline} />
              <div>Back</div>
            </div>
          </div>
        );

      case "/listings/:mlsNumber":
        return (
          <div className={styles.listings}>
            <div className={styles.return} onClick={returnHandler}>
              <IonIcon icon={arrowBackOutline} />
              <div>
                {location.state?.from
                  ? location.state.from[
                      location.state.from.length - 1
                    ]?.includes("listing")
                    ? "Back"
                    : location.state.from[
                        location.state.from.length - 1
                      ]?.includes("favourites")
                    ? "Return to favourites"
                    : "Return to listings"
                  : "Return to listings"}
              </div>
            </div>
            <div className={styles.open}>
              <a href={window.location.href} target="_blank" rel="noreferrer">
                <IonIcon icon={duplicateOutline} />
                <div>Open page in a new tab</div>
              </a>
            </div>
          </div>
        );

      default:
        return (
          <div className={styles.greeting}>
            <h1 className={styles.greetingText}>
              {getGreetingTime()}, {user.attributes["given_name"]}!
            </h1>
            <span className={styles.notification}>
              {notifications.favourites.length > 0 && (
                <>
                  You've got{" "}
                  <u>
                    <Link className={styles.link} to="/favourites">
                      {notifications.favourites.length} favourites{" "}
                      {notifications.favourites.length > 1
                        ? "updates"
                        : "update"}
                    </Link>
                  </u>
                </>
              )}
            </span>
          </div>
        );
    }
  };

  const handleCreateNewTour = () => {
    const div = document.getElementById("new_tour");
    div?.scrollIntoView();
  };

  return (
    <div className={styles.header}>
      {renderMessage()}
      {!isAuthenticated ? (
        <div className={styles.btns}>
          <Button
            onClick={() => dispatch(openAuthModal({ current: "login" }))}
            title="Sign in"
            type="nav"
            style={{
              backgroundColor: "transparent",
              fontWeight: "500",
              width: "10.8rem",
              height: "4rem",
            }}
          />

          <Button
            onClick={() => dispatch(openAuthModal({ current: "register" }))}
            title="Register"
            type="nav"
            style={{
              border: "1px solid #7158AD",
              fontWeight: "500",
              width: "10.8rem",
              height: "4rem",
            }}
          />
        </div>
      ) : (
        <div className={styles.authenticatedBtns}>
          {["/favourites", "/searches"].includes(location.pathname) && (
            <span className={styles.notifBtnContainer}>
              <Button
                onClick={() =>
                  dispatch(openModal({ current: "editNotification" }))
                }
                title="Manage alerts"
                type="clear"
                style={{ width: "auto", fontWeight: 500, color: "#363636" }}
              >
                <IonIcon
                  icon={notificationsOutline}
                  className={styles.notifIcon}
                />
              </Button>
            </span>
          )}

          {["/tours"].includes(match.path) && agent && (
            <span className={styles.btnContainer}>
              <Button
                title="create a new tour"
                type="purple"
                style={{
                  width: "18rem",
                  fontSize: "1.5rem",
                  border: ".1rem solid #7158AD",
                }}
                onClick={handleCreateNewTour}
              />
            </span>
          )}

          {["/tours/:tourId"].includes(match.path) &&
            agent &&
            tour &&
            tour.shared === "unshared" &&
            tour.status !== "archived" && (
              <>
                <span className={styles.btnContainer}>
                  <Button
                    onClick={() => setAlert(true)}
                    title="Share with client"
                    type="green"
                    style={{
                      width: "20rem",
                      fontSize: "1.5rem",
                      border: ".1rem solid #1F7A60",
                    }}
                    loading={loading}
                  >
                    <IonIcon icon={peopleOutline} className={styles.icon} />
                  </Button>
                </span>

                <IonAlert
                  isOpen={alert}
                  onDidDismiss={() => setAlert(false)}
                  cssClass={styles.alert}
                  header={"Share with client"}
                  animated
                  message="Are you sure you want to share this tour with client?"
                  buttons={[
                    {
                      text: " Cancel",
                      role: "cancel",
                      cssClass: styles.cancel,
                      handler: () => setAlert(false),
                    },
                    {
                      text: "Share",
                      role: "Ok",
                      handler: handleShare,
                    },
                  ]}
                />
              </>
            )}

          {((["/tours/:tourId"].includes(match.path) && !agent) ||
            (["/tours/details/:tourItemId"].includes(match.path) && agent)) && (
            <span className={styles.btnContainer}>
              <Button
                title="Directions"
                type="blue"
                style={{
                  width: "15rem",
                  fontSize: loading ? "0" : "1.5rem",
                  border: ".1rem solid #007B94",
                }}
                onClick={handleDirection}
                loading={loading}
              >
                <IonIcon icon={navigateOutline} className={styles.icon} />
              </Button>
            </span>
          )}

          <ProfileDropdown
            givenName={user.attributes["given_name"]}
            familyName={user.attributes["family_name"]}
          />
        </div>
      )}
    </div>
  );
};

export default Header;
