import { useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Input from "../Form/Input/input.component.jsx";
import DropdownContainer from "../Form/DropdownContainer/dropdown-container.component";
import { getListing, getMapListings } from "../../api/repliers";
import { selectMapRef } from "../../redux/map/map.selectors";
import { IonIcon } from "@ionic/react";
import debounce from "lodash/debounce";
import styles from "./location-search.module.scss";
import SearchResult from "./components/search-result.component";

import useComponentVisible from "../../hooks/useComponentVisible.js";
import { selectLocationsCitiesAndNeighbourhoods } from "../../redux/locations/locations.selectors.js";
import withFilters from "../../HOC/withFilters/with-filters";
import { warningOutline } from "ionicons/icons";
import { buildAddress, getHighlightedLength } from "../../utils/functions.js";

const regex = /^[a-zA-Z]\d+/;
const validRegex = /^[a-zA-Z]\d{7}/;

const LocationSearch = ({ filters, isHomepage = false, disabled }) => {
  const { ref, isOpen, setIsOpen } = useComponentVisible(false);
  const history = useHistory();
  const location = useLocation();
  const [term, setTerm] = useState("");
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const locations = useSelector(selectLocationsCitiesAndNeighbourhoods);
  const mapRef = useSelector(selectMapRef);

  const { type, status } = filters;

  const onSearchResultClick = (item, type) => {
    setIsOpen(false);

    if (type === "listing") {
      history.push(`/listings/${item.mlsNumber}`);
      // if (isHomepage) {
      //   history.push(`/listings/${item.mlsNumber}`);
      // } else {
      //   mapRef.panTo({
      //     lat: +item.map.latitude,
      //     lng: +item.map.longitude,
      //   });

      //   dispatch(
      //     updateSelectedListing({
      //       coords: { lat: item.map.latitude, lng: item.map.longitude },
      //       points: [{ properties: { item } }],
      //     })
      //   );
      // }
    } else {
      if (mapRef) {
        mapRef.panTo({
          lat: item.location.lat,
          lng: item.location.lng,
        });
        location.pathname !== "/listings" && history.push("/listings");
      } else {
        location.pathname === "/listings"
          ? history.replace(
              `/listings?lat=${item.location.lat}&lng=${item.location.lng}`
            )
          : history.push(
              `/listings?lat=${item.location.lat}&lng=${item.location.lng}`
            );
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetch = useCallback(
    debounce((term) => fetchResults(term), 500),
    []
  );

  const fetchResults = async (term) => {
    setLoading(true);

    if (regex.test(term)) {
      if (validRegex.test(term)) {
        try {
          const response = await getListing(term);

          setResults({ listings: [response], locations: null });
        } catch (err) {
          setResults({ listings: null, locations: null });
        }
      } else {
        setResults({ listings: null, locations: null });
      }
    } else {
      try {
        const response =
          term.length >= 3 ? await getMapListings(term, null, isHomepage) : [];

        const foundLocations = locations.find((location) =>
          location.name.toLowerCase().includes(term.toLowerCase())
        );

        setResults({
          listings: response.length ? [...response] : null,
          locations: foundLocations ? [foundLocations] : null,
        });
      } catch (err) {
        setResults(null);
        setLoading(false);
      }
    }

    setLoading(false);
  };

  const handleTermChange = (value, name) => {
    setTerm(value);
  };

  useEffect(() => {
    if (term.length) {
      setLoading(true);
      debouncedFetch(term);
    }
  }, [term, debouncedFetch]);

  useEffect(() => {
    if (term && !loading) setIsOpen(true);
  }, [term, loading, setIsOpen]);

  useEffect(() => {
    setTerm("");
    setResults(null);
  }, [type, status]);

  const compareListings = (a, b) => {
    const aMatch = getHighlightedLength(
      `${a.address.city} - ${buildAddress(a.address)}`,
      term
    );

    const bMatch = getHighlightedLength(
      `${b.address.city} - ${buildAddress(b.address)}`,
      term
    );

    if (aMatch > bMatch) return -1;
    if (aMatch === bMatch) return 0;
    return 1;
  };

  return (
    <div ref={ref}>
      <Input
        label={!isHomepage && "location or MLS #"}
        placeholder={
          isHomepage ? "Search by location or MLS#" : "location or MLS #"
        }
        showPlaceholder={isHomepage}
        isHomepage={isHomepage}
        value={term}
        mode="search"
        search
        clear={() => setTerm("")}
        onChange={handleTermChange}
        loading={loading}
        onClick={() => {
          if (term && results) setIsOpen(true);
        }}
        disabled={disabled}
        autoComplete="off"
        style={
          isHomepage
            ? {
                flex: 1,
                color: "#7158ad",
                border: "none",
                fontSize: "1.8rem",
                fontWeight: 500,
              }
            : null
        }
        searchIconStyle={
          isHomepage
            ? {
                fontSize: "3.5rem",
                color: "#7158ad",
              }
            : null
        }
      />

      {isOpen && (
        <DropdownContainer
          style={{ width: isHomepage ? "100%" : "150%" }}
          open={results && term && !loading}
        >
          {results &&
            results.locations &&
            results.locations.map((location) => (
              <SearchResult
                key={location.name}
                item={location}
                term={term}
                type={location.type}
                onClick={() => onSearchResultClick(location, "location")}
              />
            ))}
          {results &&
            results.listings &&
            results.listings
              .sort(compareListings)
              .map((listing) => (
                <SearchResult
                  key={listing.mlsNumber}
                  item={listing}
                  term={term}
                  type="listing"
                  onClick={() => onSearchResultClick(listing, "listing")}
                />
              ))}
          {results && !results.listings && !results.locations && (
            <div className={styles.noResult}>
              <IonIcon icon={warningOutline} className={styles.icon} />
              No listing found. Try revising your search.
            </div>
          )}
        </DropdownContainer>
      )}
    </div>
  );
};

export default withFilters(LocationSearch);
