import { API } from "aws-amplify";
import gql from "graphql-tag";
import {
  listSchools as listSchoolItems,
  getSchool,
} from "../graphql/custom-queries";
import { store } from "../redux/store";

export const getHomeCards = async () => {
  const res = await API.post("ettieREST", "/listhomecards", { body: {} });
  return res;
};

export const getHomeFeatureds = async () => {
  const res = await API.post("ettieREST", "/featured", { body: {} });
  return res;
};

export const sendFeedback = async (request) => {
  const data = {
    body: request,
  };
  const res = await API.post("ettieREST", "/feedback", data);
  return res;
};

export const contactUs = async (request) => {
  const data = {
    body: request,
  };
  const res = await API.post("ettieREST", "/contact/general", data);
  return res;
};

export const sendRequestToAgent = async (request) => {
  const data = {
    body: request,
  };
  const res = await API.post("ettieREST", "/contact/agent", data);
  return res;
};

export const shareListing = async (request) => {
  const data = {
    body: request,
  };

  const res = await API.post("ettieREST", "/contact/share", data);
  return res;
};

export const shareTourWithClient = async (request) => {
  const data = {
    body: request,
  };
  const res = await API.post("ettieREST", "/sharetour", data);
  return res;
};

export const cancelTour = async (request) => {
  const data = { body: request };
  const res = await API.post("ettieREST", "/canceltour", data);
  return res;
};

export const getSingleSchool = async (id) => {
  const guest = store.getState().user.guest;

  if (guest) {
    const {
      data: { getSchool: school },
    } = await guest.query({
      query: gql(getSchool),
      variables: {
        id,
      },
    });

    return school;
  }
  return null;
};

export const fetchSchools = async ({ boundary }) => {
  try {
    let data = [];
    let token = "";
    const guest = store.getState().user.guest;
    const {
      elementary,
      middle,
      high,
      frenchImmersion,
      ib,
      ap,
      sports,
      arts,
      gifted,
      public: isPublic,
      private: isPrivate,
      catholic,
      rate,
      showUnrated,
    } = store.getState().schools.filters;
    const selectedSchools = store.getState().schools.filterListings.schools;

    const filter = {
      and: [
        { longitude: { gt: boundary[0][0] } },
        { longitude: { lt: boundary[1][0] } },
        { latitude: { gt: boundary[2][1] } },
        { latitude: { lt: boundary[0][1] } },
      ],
    };

    if (rate && !showUnrated) {
      filter.and.push({ ettieOverallAvg: { ge: rate * 10 } });
    }
    if (rate && showUnrated) {
      filter.and.push({
        and: [
          {
            or: [
              { ettieOverallAvg: { ge: rate * 10 } },
              { ettieOverallAvg: { eq: 0 } },
            ],
          },
        ],
      });
    }

    if (!rate && !showUnrated) {
      filter.and.push({ ettieOverallAvg: { gt: 0 } });
    }

    if (elementary || middle || high)
      filter.and.push({
        and: [
          {
            or: [
              elementary ? { isElementary: { eq: elementary } } : null,
              middle ? { isMiddle: { eq: middle } } : null,
              high ? { isHigh: { eq: high } } : null,
            ].filter((item) => item !== null),
          },
        ],
      });

    if (isPublic || catholic)
      filter.and.push({
        and: [
          {
            or: [
              isPublic ? { isPublic: { eq: isPublic } } : null,
              catholic ? { isCatholic: { eq: catholic } } : null,
            ].filter((item) => item !== null),
          },
        ],
      });

    if (frenchImmersion || gifted || ap || ib || arts || sports)
      filter.and.push({
        and: [
          {
            or: [
              frenchImmersion
                ? { isFrenchImmersion: { eq: frenchImmersion } }
                : null,
              gifted ? { isGifted: { eq: gifted } } : null,
              ap ? { isAP: { eq: ap } } : null,
              ib ? { isIB: { eq: ib } } : null,
              sports ? { isSport: { eq: sports } } : null,
              arts ? { isArts: { eq: arts } } : null,
            ].filter((item) => item !== null),
          },
        ],
      });

    if (guest) {
      const {
        data: { listSchools },
      } = await guest.query({
        query: gql(listSchoolItems),
        variables: {
          filter,
          limit: 2000,
        },
      });

      data = [...listSchools.items];
      token = listSchools.nextToken;

      do {
        const {
          data: { listSchools },
        } = await guest.query({
          query: gql(listSchoolItems),
          variables: {
            nextToken: token,
            filter,
            limit: 2000,
          },
        });

        data = [...data, ...listSchools.items];
        if (listSchools.nextToken === token) {
          token = "";
        } else {
          token = listSchools.nextToken;
        }
      } while (token);

      return data
        .filter((s) => !selectedSchools.find((school) => school.id === s.id))
        .concat(selectedSchools)
        .reverse();
    }
  } catch (err) {
    console.log(err);
    return;
  }
};

export const findNearbySchools = async ({ boundary }) => {
  try {
    let data = [];
    let token = "";
    const guest = store.getState().user.guest;
    if (guest) {
      const filter = {
        and: [
          { longitude: { gt: boundary[0][0] } },
          { longitude: { lt: boundary[1][0] } },
          { latitude: { gt: boundary[2][1] } },
          { latitude: { lt: boundary[0][1] } },
        ],
      };

      const {
        data: { listSchools },
      } = await guest.query({
        query: gql(listSchoolItems),
        variables: {
          filter,
          limit: 5000,
        },
      });

      data = [...listSchools.items];
      token = listSchools.nextToken;

      do {
        const {
          data: { listSchools },
        } = await guest.query({
          query: gql(listSchoolItems),
          variables: {
            nextToken: token,
            filter,
            limit: 2000,
          },
        });

        data = [...data, ...listSchools.items];
        if (listSchools.nextToken === token) {
          token = "";
        } else {
          token = listSchools.nextToken;
        }
      } while (token);

      return data ? data : null;
    }
  } catch (err) {
    console.log(err);
    return;
  }
};
