import { getTagTitle } from "../../utils/functions";
import styles from "./type-tag.module.scss";
const ACTIVE_TYPES = ["New", "Pc", "Ext"];
const TypeTag = ({ type, status, lastStatus }) => {
  if (!status) status = "U";
  return (
    <div
      className={`${styles.tag} ${
        ACTIVE_TYPES.includes(lastStatus) ? styles.active : styles.inactive
      }`}
    >
      {status === "U" || lastStatus !== "New"
        ? lastStatus
          ? lastStatus === "Pc"
            ? `for ${type.toLowerCase()}`
            : getTagTitle(lastStatus, type).toLowerCase()
          : type.toLowerCase()
        : `for ${type.toLowerCase()}`}
    </div>
  );
};

export default TypeTag;
