import ClientTourItem from "../ClientTourItem/client-tour-item.component";
import styles from "./tour-schedule.module.scss";

const TourSchedule = ({ tourItems }) => {
  return (
    <div className={styles.tourSchedule}>
      {tourItems && tourItems.length > 0 ? (
        <>
          <div className={styles.header}>
            <span className={styles.order}>Order</span>
            <span>Time slot</span>
            <span className={styles.map}>Directions</span>
            <span>Address</span>
            <span>Price</span>
            <span className={styles.status}>Showing status</span>
            <span className={styles.review}>My review</span>
            <span className={styles.details}>Details</span>
          </div>

          {tourItems.map((item, index) => (
            <ClientTourItem key={index} item={item} />
          ))}
        </>
      ) : (
        <div className={styles.noItem}>No showings added yet</div>
      )}
    </div>
  );
};

export default TourSchedule;
