import { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../Form/Input/input.component";
import Button from "../../Form/Button/button.component";
import { ReactComponent as PhoneIcon } from "../../../assets/svg/REA103_Icons-01a_TextConfirm - purple.svg";
import {
  verifyUserAttribute,
  verifyUserAttributeConfirm,
} from "../../../amplify/auth.utils";
import { checkUserSession } from "../../../redux/user/user.actions";
import { selectCurrentClient } from "../../../redux/client/client.selectors";
import { updateToast } from "../../../redux/ui/ui.actions";
import { confirmPhone } from "../../../data/customModal";
import styles from "./confirm-phone-modal.module.scss";

const ConfirmPhoneModal = ({ setType, close, data, language, ...rest }) => {
  const dispatch = useDispatch();
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const codeRef = useRef();

  const client = useSelector(selectCurrentClient);

  let notification = JSON.parse(client.notifications);
  notification.phone = data;

  const resendHandler = async () => {
    try {
      await verifyUserAttribute("phone_number");
      dispatch(
        updateToast({
          open: true,
          type: "success",
          message: "Code sent successfully.",
        })
      );
    } catch (err) {
      dispatch(
        updateToast({ open: true, type: "error", message: err.message })
      );
    }
  };

  const confirmHandler = async () => {
    if (codeRef.current.validate(true)) {
      setError("");
      setLoading(true);

      try {
        await verifyUserAttributeConfirm(
          "phone_number",
          code,
          client.id,
          notification
        );
        dispatch(checkUserSession(true));
        setLoading(false);
        setType("updatedPhone");
      } catch (error) {
        console.log(error);
        setLoading(false);
        setError(error.message);
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.iconContainer}>
          <PhoneIcon className={styles.icon} />
        </div>

        <div className={styles.subtitle}>{confirmPhone[language].header}</div>
        <div className={styles.text}>
          <div className={styles.note}>{confirmPhone[language].subtitle}</div>
        </div>
      </div>
      <div className={styles.content}>
        <Input
          ref={codeRef}
          required
          mode="numeric"
          label={confirmPhone[language].codeInput}
          placeholder={confirmPhone[language].codeInput}
          value={code}
          onChange={(value, name) => setCode(value)}
          validation="required"
        />
        {error && (
          <div className={styles.error}>
            <span>{error}</span>
          </div>
        )}
      </div>
      <div className={styles.button}>
        <>
          <Button
            title={confirmPhone[language].nextbtn}
            type="purple"
            style={{
              height: "5rem",
              width: "100%",
              border: "1px solid #7158ad",
            }}
            onClick={confirmHandler}
            loading={loading}
          />
          <Button
            title={confirmPhone[language].resendbtn}
            type="clear"
            style={{ color: "#707070", marginTop: "1rem", minWidth: "15rem" }}
            onClick={resendHandler}
          />
        </>
      </div>
    </div>
  );
};

export default ConfirmPhoneModal;
