import { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IonToast } from "@ionic/react";
import Footer from "../../components/Footer/footer.component";
import {
  clearCurrentTour,
  fetchToursStart,
} from "../../redux/tour/tour.actions";
import {
  selectAllTours,
  selectToursError,
  selectToursLoading,
} from "../../redux/tour/tour.selectors";
import CardContainer from "./component/CardContainer/card-container.component";
import NewTour from "./component/NewTour/new-tour.component";
import Topbar from "./component/Topbar/topbar.component";
import TourItem from "./component/TourItem/tour-item.component";
import styles from "./tours.module.scss";
import withAuthentication from "../../HOC/withAuthentication/with-authentication";
import { API, graphqlOperation } from "aws-amplify";
import {
  onUpdateTourByTourId,
  onUpdateTourItemByTourId,
} from "../../graphql/subscriptions";
import NoTourItem from "./component/NoTourItem/no-tour-item.component";
import { isBeforeOrAfter } from "../../utils/functions";
import Pagination from "./component/Pagination/pagination.component";
const PER_PAGE = 5;

const Tours = () => {
  const dispatch = useDispatch();
  const [showError, setShowError] = useState(false);
  const [sortedTours, setSortedTours] = useState({
    upcoming: [],
    past: [],
    archived: [],
  });
  const [page, setPage] = useState(1);
  const [type, setType] = useState("upcoming");
  const tours = useSelector(selectAllTours);
  const loading = useSelector(selectToursLoading);
  const error = useSelector(selectToursError);
  const [sortBy, setSortBy] = useState({
    value: "date",
    direction: "desc",
  });
  const subscriptionRef = useRef([]);

  const subscribeChanges = useCallback(async () => {
    subscriptionRef.current?.forEach((s) => s.unsubscribe());
    const subscription = [];
    if (sortedTours[type]?.length > 0 && page >= 1) {
      for (let i = (page - 1) * PER_PAGE; i < page * PER_PAGE; i++) {
        const item = sortedTours[type][i];
        if (item) {
          const s1 = await API.graphql(
            graphqlOperation(onUpdateTourByTourId, {
              id: item.id,
            })
          ).subscribe({
            next: () => {
              dispatch(clearCurrentTour());
              dispatch(fetchToursStart());
            },
          });
          const s2 = await API.graphql(
            graphqlOperation(onUpdateTourItemByTourId, {
              tourId: item.id,
            })
          ).subscribe({
            next: () => {
              dispatch(clearCurrentTour());
              dispatch(fetchToursStart());
            },
          });

          subscription.push(s1, s2);
        }
      }
    }

    subscriptionRef.current = subscription;
  }, [dispatch, page, type, sortedTours]);

  useEffect(() => {
    subscribeChanges();
    return () => {
      subscriptionRef.current.forEach((s) => s.unsubscribe());
    };
  }, [subscribeChanges]);

  useEffect(() => {
    dispatch(clearCurrentTour());
    dispatch(fetchToursStart());
  }, [dispatch]);

  useEffect(() => {
    setShowError(error ? true : false);
  }, [error]);

  const handleReverse = (items, direction) => {
    if (!items || !items.length) return;
    if (direction !== "desc") return items?.reverse();
    return items;
  };

  useEffect(() => {
    const sort = (sorted) => {
      setSortedTours({
        upcoming: handleReverse(
          sorted.filter(
            (t) =>
              !["archived"].includes(t.status) &&
              (!t.date || isBeforeOrAfter(t.date) >= 0)
          ),
          sortBy.direction
        ),
        past: handleReverse(
          sorted.filter((t) => isBeforeOrAfter(t.date) < 0),
          sortBy.direction
        ),
        archived: handleReverse(
          sorted.filter((t) => ["archived"].includes(t.status)),
          sortBy.direction
        ),
      });
    };
    let sorted;
    if (tours) {
      switch (sortBy.value) {
        case "date":
          sorted = tours.sort((a, b) => new Date(b.date) - new Date(a.date));
          sort(sorted);
          break;
        case "status":
          sorted = tours.sort((a, b) => (b.status > a.status ? 1 : -1));
          sort(sorted);
          break;
        case "stops":
          sorted = tours.sort(
            (a, b) => b.tourItems.items.length - a.tourItems.items.length
          );
          sort(sorted);
          break;
        case "title":
          sorted = tours.sort((a, b) =>
            b.title.toLowerCase() > a.title.toLowerCase() ? 1 : -1
          );
          sort(sorted);
          break;
        case "client":
          sorted = tours.sort((a, b) => {
            if (!a.clients.items[0] && !b.clients.items[0]) return 0;
            if (!a.clients.items[0]) return 1;
            if (!b.clients.items[0]) return -1;
            const clientA = JSON.parse(a.membersData).find(
              (c) => c.role === "Client"
            );
            const clientB = JSON.parse(b.membersData).find(
              (c) => c.role === "Client"
            );

            return clientB.givenName.toLowerCase() >
              clientA.givenName.toLowerCase()
              ? 1
              : -1;
          });
          sort(sorted);
          break;
        case "showing agent":
          sorted = tours.sort((a, b) => {
            if (!a.showingAgent && !b.showingAgent) return 0;
            if (!a.showingAgent) return 1;
            if (!b.showingAgent) return -1;
            return b.showingAgent.givenName.toLowerCase() >
              a.showingAgent.givenName.toLowerCase()
              ? 1
              : -1;
          });
          sort(sorted);
          break;
        default:
          sorted = tours.sort((a, b) => new Date(b.date) - new Date(a.date));
          sort(sorted);
      }
    }
  }, [sortBy, tours]);

  useEffect(() => {
    setPage(1);
  }, [type]);

  const getToursByType = () => {
    if (loading) return;
    if (!sortedTours.archived && !sortedTours.past && !sortedTours.upcoming) {
      return <NoTourItem type="upcoming" />;
    } else {
      return sortedTours[type] && sortedTours[type].length > 0 ? (
        <div className={styles.cardsContainer}>
          <CardContainer>
            <div className={styles.header}>
              <div>D/M/Y</div>
              <div>Title</div>
              <div>Client</div>
              <div>Total time</div>
              <div>Showing agent</div>
              <div># of stops</div>
              <div>Status of tour</div>
              <div>Open in tab</div>
              <div>Edit</div>
            </div>
            {sortedTours[type]
              .slice((page - 1) * PER_PAGE, page * PER_PAGE)
              .map((item, index) => (
                <TourItem item={item} index={index} key={index} />
              ))}
          </CardContainer>

          <div className={styles.pagination}>
            <Pagination
              current={page}
              total={sortedTours[type].length}
              perPage={PER_PAGE}
              onChange={(page) => setPage(page)}
            />
          </div>
        </div>
      ) : (
        <NoTourItem type={type} />
      );
    }
  };

  return (
    <div className={styles.tours}>
      <IonToast
        color="danger"
        duration={2000}
        message={error}
        isOpen={showError}
        position="top"
      />
      <Topbar
        type={type}
        setType={setType}
        sortBy={sortBy}
        setSortBy={setSortBy}
      />
      <div>{getToursByType()}</div>
      <CardContainer>
        <NewTour />
      </CardContainer>
      <Footer />
    </div>
  );
};

export default withAuthentication(Tours);
