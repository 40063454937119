import { useState } from "react";
import useComponentVisible from "../../../../hooks/useComponentVisible";
import Select from "../../../Form/Select/select.component";
import RadioFilter from "../../../RadioFilter/radio-filter.component";
import { propertyClass as purposeOptions } from "../../data/filters.data";

const PurposeFilter = ({
  filters: { class: propertyClass },
  updateMultipleFilters,
  disabled,
}) => {
  const [options, setOptions] = useState(purposeOptions);
  const { ref, isOpen, setIsOpen } = useComponentVisible(false);

  const onChangeHandler = ({ label }) => {
    const tempOptions = options.map((option) => {
      if (option.label !== label) {
        option.checked = false;
      } else {
        option.checked = true;
      }
      return option;
    });

    setOptions(tempOptions);
    if (label === "residential") {
      updateMultipleFilters({
        class: ["residential", "condo"],
        propertyType: null,
      });
    } else {
      updateMultipleFilters({ class: ["commercial"], propertyType: null });
    }
    setIsOpen(false);
  };

  return (
    <div ref={ref}>
      <Select
        label="purpose"
        title={
          propertyClass.includes("residential") ? "residential" : "commercial"
        }
        open={isOpen}
        onClick={() => setIsOpen((open) => !open)}
        disabled={disabled}
      />
      <RadioFilter
        value={
          propertyClass.includes("residential") ? "residential" : "commercial"
        }
        options={options}
        open={isOpen}
        onClick={onChangeHandler}
      />
    </div>
  );
};

export default PurposeFilter;
