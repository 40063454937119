import { useState } from "react";
import Input from "../Input/input.component";
import { IonIcon } from "@ionic/react";
import { chevronDownOutline } from "ionicons/icons";
import styles from "./select.module.scss";

const regex = /^[0-9\b]+$/;

const Select = ({
  label,
  title,
  subtitle,
  open,
  dropdown = true,
  invert = false,
  embedded = false,
  hasIcon = false,
  iconImg = "",
  buttonStyle,
  disabled,
  editable,
  onChange,
  onSubmit,
  ...rest
}) => {
  const [value, setValue] = useState("");

  const handleChange = (val) => {
    setValue(val.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ","));
  };

  const handleSubmit = ({ key, target }) => {
    const { value } = target;

    if (key === "Enter") {
      //const input = +value.split("$")[1].replaceAll(",", "");
      const input = +value.replaceAll(",", "");
      if (regex.test(input)) {
        target.blur();
        onSubmit({ value: input, source: editable });
        setValue("");
      } else {
        setValue("");
      }
    }
  };

  const handleBlur = (e) => {
    if (value) {
      const input = +value.replaceAll(",", "");
      if (regex.test(input)) {
        onSubmit({ value: input, source: editable });
        setValue("");
      } else {
        setValue("");
      }
    }
  };

  return (
    <div className={styles.selectContainer} {...rest}>
      {hasIcon && (
        <img
          src={iconImg}
          alt="icon"
          style={{
            position: "absolute",
            marginLeft: "2rem",
            height: "2rem",
            widht: "2rem",
          }}
        />
      )}
      <label className={`${styles.label} ${disabled && styles.disabledLabel}`}>
        {label}
      </label>

      {editable ? (
        open ? (
          <Input
            disabled={disabled}
            className={`${styles.select} ${
              open && !dropdown && styles.removeBorder
            } ${invert && styles.inverted} ${
              embedded && open && styles.embedded
            }`}
            autoFocus={true}
            placeholder={title}
            value={value}
            onChange={handleChange}
            onKeyDown={handleSubmit}
            onBlur={handleBlur}
            isCurrency
          />
        ) : (
          <button
            type="button"
            disabled={disabled}
            className={`${styles.select} ${
              open && !dropdown && styles.removeBorder
            } ${invert && styles.inverted} ${
              embedded && open && styles.embedded
            }`}
            style={buttonStyle}
          >
            <span className={styles.title}>{title}</span>
            {subtitle && (
              <span className={styles.subtitle}>{` ${subtitle}`}</span>
            )}
          </button>
        )
      ) : (
        <button
          type="button"
          disabled={disabled}
          className={`${styles.select} ${
            open && !dropdown && styles.removeBorder
          } ${invert && styles.inverted} ${
            embedded && open && styles.embedded
          }`}
          style={buttonStyle}
        >
          <span className={styles.title}>{title}</span>

          {subtitle && (
            <span className={styles.subtitle}>{` ${subtitle}`}</span>
          )}
        </button>
      )}

      <IonIcon
        className={`${styles.icon} ${open ? styles.iconReversed : ""} ${
          invert ? styles.invertedIcon : ""
        } ${disabled && styles.disabledIcon}`}
        icon={chevronDownOutline}
        style={
          buttonStyle &&
          buttonStyle.borderColor && { color: buttonStyle.borderColor }
        }
      />
    </div>
  );
};

export default Select;
