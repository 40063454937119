import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
} from "@ionic/react";
import AppHeader from "../../components/Header/header.component";
import hireAgent from "../../../assets/img/About - agents@4x.png";
import { SocialSharing } from "@ionic-native/social-sharing";
import { InAppBrowser } from "@ionic-native/in-app-browser";
import styles from "./hire-agent.module.scss";

const AppHireAgent = () => {
  const text = `I am interested in joining the team!`;

  const shareHandler = async (platform) => {
    try {
      await SocialSharing.shareViaEmail(
        text,
        "Becoming an Ettie agent.",
        "recruitment@ettie.ai"
      );
    } catch (err) {
      try {
        InAppBrowser.create(
          `mailto:recruitment@ettie.ai?Subject=Becoming%20an%20Ettie%20agent.`,
          "_blank",
          {
            hideurlbar: true,
            hidenavigationbutton: true,
            toolbar: "yes",
            toolbarcolor: "#110d1b",
            closebuttoncolor: "#f4f4f7",
            closebuttoncaption: "Back",
            toolbarposition: "top",
          }
        );
      } catch (err) {
        console.log(err);
      }
      console.log(err);
    }
  };

  return (
    <IonPage>
      <AppHeader title="Join us!" hasRightButton={false} />
      <IonContent>
        <IonGrid className={styles.hireAgent}>
          <IonRow>
            <IonCol className={styles.image}>
              <img src={hireAgent} className={styles.img} />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className={styles.title}>Become an Ettie agent</IonCol>
          </IonRow>
          <IonRow>
            <IonCol className={styles.subtitle}>
              Interested in joining the team? Fantastic! Sounds like it’s time
              to get to know each other. Please send your inquiries and resume
              to{" "}
              <span className={styles.underline} onClick={shareHandler}>
                recruitment@ettie.ai.{" "}
              </span>
              We look forward to hearing from you!
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton
                shape="round"
                className={styles.btn}
                onClick={shareHandler}
              >
                Send email
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default AppHireAgent;
