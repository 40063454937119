import { useState } from "react";
import styles from "./listing-image.module.scss";
import DefaultImage from "../../assets/img/no-photo.png";
import { IonSpinner } from "@ionic/react";

const IMAGE_API = "https://cdn.repliers.io";

const ListingImage = ({ img, onClick }) => {
  const [loading, setLoading] = useState(true);

  return (
    <div className={styles.imageContainer} onClick={onClick}>
      {loading && (
        <div className={styles.spinnerContainer}>
          <IonSpinner name="crescent" className={styles.spinner} />
        </div>
      )}
      <img
        className={loading ? styles.imageLoading : styles.image}
        src={img === "default" ? DefaultImage : `${IMAGE_API}/${img}`}
        alt="Listing"
        onLoad={() => setLoading(false)}
        onError={(e) => {
          setLoading(false);
          e.target.src = DefaultImage;
        }}
      />
    </div>
  );
};

export default ListingImage;
