import { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import Button from "../../Form/Button/button.component";
import Input from "../../Form/Input/input.component";
import { ReactComponent as SearchIcon } from "../../../assets/svg/search.svg";
import { saveSearch } from "../../../data/customModal";
import styles from "./save-search-modal.module.scss";
import withFilters from "../../../HOC/withFilters/with-filters";
import withClient from "../../../HOC/withClient/with-client";
import withMap from "../../../HOC/withMap/with-map";
import millify from "millify";
import Toggle from "../../Form/Toggle/toggle-component";
import { addSearch, updateClient } from "../../../amplify/graphql.utils";
import { updateToast } from "../../../redux/ui/ui.actions";
import { IonIcon } from "@ionic/react";
import { schoolOutline } from "ionicons/icons";

const SaveSearchModal = ({
  close,
  language,
  filters,
  extraFilters,
  mapLocations,
  currentClient,
  clientError,
  data: { drawMode, schoolMode },
}) => {
  const dispatch = useDispatch();
  const notifications = JSON.parse(currentClient.notifications);

  const {
    propertyType,
    minPrice,
    maxPrice,
    minBeds,
    maxBeds,
    minBaths,
    maxBaths,
  } = filters;

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const searchNameRef = useRef();
  const [searchName, setSearchName] = useState(
    schoolMode.active && schoolMode.schools.length
      ? schoolMode.schools.length > 1
        ? "Listing around multiple schools"
        : `Listings around ${schoolMode.schools[0]?.name}`
      : ""
  );
  const handleChange = (value, name) => {
    searchNameRef.current.validate();
    setSearchName(value);
  };

  const handleSubmit = async () => {
    if (searchNameRef.current.validate(true)) {
      setLoading(true);
      try {
        await addSearch(currentClient.id, currentClient.repliersID, {
          searchName,
          filters,
          extraFilters,
          mapLocations,
          drawMode,
          schoolMode,
          alert,
        });
        dispatch(
          updateToast({
            open: true,
            type: "success",
            message: "Search saved successfully.",
          })
        );
        close();
      } catch (err) {
        console.log(err);
        if (err.response && err.response.status === 406) {
          dispatch(
            updateToast({
              open: true,
              type: "error",
              message:
                "Your search is too big. Try setting more specific filters.",
            })
          );
        } else {
          dispatch(
            updateToast({
              open: true,
              type: "error",
              message: "Something went wrong!",
            })
          );
        }
      }
      setLoading(false);
    }
  };

  const buildNotificationStyle = () => {
    const types = Object.entries(notifications.types)
      .map(([key, value]) => {
        if (value) {
          return key;
        } else {
          return null;
        }
      })
      .filter((val) => val !== null)
      .reduce((acc, current) => current + ", " + acc, "");

    return types.substring(0, types.length - 2);
  };

  const handleAlert = async () => {
    const {
      types: { email, phone, push },
      schedule,
    } = notifications;
    if (!alert) {
      if (!email && !phone && !push) {
        await updateClient({
          id: currentClient.id,
          updates: {
            notifications: JSON.stringify({
              ...notifications,
              schedule: schedule ? schedule : "daily",
              types: { email: true, phone: false, push: false },
            }),
          },
        });
      }
    }
    setAlert(!alert);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>{saveSearch[language].header}</div>
      <div className={styles.subtitle}>{saveSearch[language].subtitle}</div>

      <div className={styles.content}>
        <div>
          <div className={styles.label}>{saveSearch[language].label_1}</div>
          <Input
            required
            name="search_name"
            ref={searchNameRef}
            value={searchName}
            defaultValue={searchName}
            validation="required"
            onChange={(value, name) => handleChange(value, name)}
            style={{ marginTop: "0.5rem" }}
          />
          {(error || clientError) && (
            <div className={styles.error}>
              <span>{error ? error : clientError}</span>
            </div>
          )}
        </div>

        <div>
          <div className={styles.label}>{saveSearch[language].label_3}</div>
          <span className={styles.title}>Property Type:</span>
          <span className={styles.filter}>
            {propertyType
              ? propertyType.length > 1
                ? "Several"
                : propertyType
              : "Any"}
          </span>
          <span className={styles.title}>Price:</span>
          <span className={styles.filter}>
            {minPrice === "0" && maxPrice === "max"
              ? "Any"
              : `$${millify(+minPrice)}-${
                  maxPrice === "max" ? "max" : `$${millify(+maxPrice)}`
                }`}
          </span>
          <span className={styles.title}>Beds:</span>
          <span className={styles.filter}>
            {!minBeds && !maxBeds
              ? "Any"
              : minBeds && !maxBeds
              ? `${minBeds}+`
              : !minBeds && maxBeds && maxBeds !== "any"
              ? `0-${maxBeds}`
              : minBeds && maxBeds === "any"
              ? `${minBeds}+`
              : !minBeds && maxBeds === "any"
              ? "Any"
              : `${minBeds}-${maxBeds}`}
          </span>
          <span className={styles.title}>Baths:</span>
          <span className={styles.filter}>
            {!minBaths && !maxBaths
              ? "Any"
              : minBaths && !maxBaths
              ? `${minBaths}+`
              : !minBaths && maxBaths && maxBaths !== "any"
              ? `0-${maxBaths}`
              : minBaths && maxBaths === "any"
              ? `${minBaths}+`
              : !minBaths && maxBaths === "any"
              ? "Any"
              : `${minBaths}-${maxBaths}`}
          </span>
        </div>
        {schoolMode?.active && schoolMode?.schools?.length && (
          <div className={styles.schools}>
            {schoolMode.schools.map((s, i) => (
              <div key={i} className={styles.school}>
                <span className={styles.schoolIcon}>
                  <IonIcon icon={schoolOutline} className={styles.icon} />
                  <span className={styles.title}>School</span>
                </span>
                <span className={styles.name}>{s.name}</span>
              </div>
            ))}
          </div>
        )}
        <div>
          <div className={styles.labelContainer}>
            <div className={styles.label}>{saveSearch[language].label_2}</div>
            <Toggle active={alert} onToggle={handleAlert} />
          </div>

          {alert && (
            <div>
              <div className={styles.notify}>
                {saveSearch[language].notify_1}{" "}
                <span>
                  {notifications.schedule ? notifications.schedule : "---"}
                </span>
              </div>
              <div className={styles.notify}>
                {saveSearch[language].notify_2}{" "}
                <span>
                  {buildNotificationStyle() ? buildNotificationStyle() : "---"}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>

      <Button
        title={saveSearch[language].button}
        type="green"
        style={{ height: "5rem", width: "100%", border: "1px solid #1F7A60" }}
        onClick={handleSubmit}
        loading={loading}
      >
        <SearchIcon />
      </Button>
      <div
        className={styles.note}
        dangerouslySetInnerHTML={{ __html: saveSearch[language].note }}
      ></div>
    </div>
  );
};

export default withClient(withFilters(withMap(SaveSearchModal)));
