import { NativeGeocoder } from "@ionic-native/native-geocoder";
import { Geolocation } from "@ionic-native/geolocation";
import { useState, useEffect, useCallback, useRef, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MapAPI } from "../../../../api/index";
import ListingCard from "../../../components/ListingCard/listing-card.component";
import styles from "./featured.module.scss";
import { getMapListings, getSimilarListing } from "../../../../api/repliers";
import { selectCurrentUser } from "../../../../redux/user/user.selectors";
import { getFavouriteItems } from "../../../../amplify/graphql.utils";
import { buildAddress } from "../../../../utils/functions";
import { updateFilters } from "../../../../redux/filters/filters.actions";
import { IonSlide, IonSlides, IonRouterLink } from "@ionic/react";
import { useHistory } from "react-router-dom";
import { v4 } from "uuid";
import axios from "axios";

const Featured = ({ item }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const { isNearby, isFavourite, isSchool, title, subtitle, filters } = item;
  const [loading, setLoading] = useState(false);
  const [similarTitle, setSimilarTitle] = useState();
  const [favourite, setFavourite] = useState();
  const [listings, setListings] = useState([]);

  const user = useSelector(selectCurrentUser);

  const fetchResults = useCallback(
    async (token) => {
      setLoading(true);
      try {
        const filtersValue = filters ? JSON.parse(filters) : null;
        const {
          data: { listings },
        } = await MapAPI.post(
          `?fields=map,mlsNumber,status,listPrice,listDate,lastStatus,soldPrice,soldDate,address,images,details,type,lot,condominium`,
          {},
          {
            cancelToken: token,
            params: {
              type: filtersValue.type,
              status: filtersValue.status,
              lastStatus: filtersValue.lastStatus,
              displayPublic: filtersValue.displayPublic,
              class: filtersValue.class,
              propertyType: filtersValue.propertyType,
              sortBy: filtersValue.sortBy,
              resultsPerPage: 10,
              pageNum: filtersValue.pageNum,
              minPrice:
                filtersValue.minPrice === "0" || filtersValue.minPrice === 0
                  ? null
                  : filtersValue.minPrice,
              maxPrice:
                filtersValue.maxPrice === "max" || filtersValue.maxPrice === 0
                  ? null
                  : filtersValue.maxPrice,
              minBeds: filtersValue.minBeds === 0 ? null : filtersValue.minBeds,
              maxBeds:
                filtersValue.maxBeds === "any" || filtersValue.maxBeds === "max"
                  ? null
                  : filtersValue.maxBeds,
              minBaths:
                filtersValue.minBaths === 0 ? null : filtersValue.minBaths,
              maxBaths:
                filtersValue.maxBaths === "any" ||
                filtersValue.maxBaths === "max"
                  ? null
                  : filtersValue.maxBaths,
              minSqft: filtersValue.minSqft === 0 ? null : filtersValue.minSqft,
              maxSqft:
                filtersValue.maxSqft === "max" ? null : filtersValue.maxSqft,
              minParkingSpaces:
                filtersValue.minParkingSpaces === 0
                  ? null
                  : filtersValue.minParkingSpaces,
              maxSoldDate: filtersValue.maxSoldDate,
              minSoldDate: filtersValue.minSoldDate,
              minListDate: filtersValue.minListDate,
              maxListDate: filtersValue.maxListDate,
              area:
                filtersValue.city || filtersValue.neighborhood
                  ? null
                  : filtersValue.area,
              city: filtersValue.city,
              operator:
                filtersValue.city && filtersValue.neighborhood ? "OR" : null,
              neighborhood: filtersValue.neighborhood,
              keywords: filters.keywords,
            },
          }
        ).catch((err) => {
          if (axios.isCancel(err)) {
          }
        });
        setListings(listings);
        setLoading(false);
      } catch (err) {}
    },
    [filters]
  );

  const fetchNearby = async (token) => {
    try {
      const c = await Geolocation.getCurrentPosition();

      if (c) {
        try {
          const addresses = await NativeGeocoder.reverseGeocode(
            c.coords.latitude,
            c.coords.longitude,
            { useLocale: true, maxResults: 1 }
          );
          const address = addresses[0];
          const response = await getMapListings(address.subLocality, token);
          if (response && response.length) setListings([...response]);
        } catch (err) {}
      }
    } catch (err) {}
  };

  const fetchFavourites = async (isSubscribed) => {
    try {
      const favs = await getFavouriteItems();
      if (favs && favs.length && isSubscribed) {
        const random = Math.floor(Math.random() * favs.length);
        setFavourite(favs[random].mlsNumber);
        const similars = await getSimilarListing(favs[random].mlsNumber);
        setListings(similars.similar);
        setSimilarTitle(buildAddress(JSON.parse(favs[random].listing).address));
      }
    } catch (err) {}
  };

  useEffect(() => {
    let isSubscribed = true;
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    if (filters) fetchResults(source.token);
    if (isNearby) fetchNearby(source.token);
    if (isFavourite && user) fetchFavourites(isSubscribed);

    return () => {
      source?.cancel();
      isSubscribed = false;
    };
  }, [fetchResults, filters, isFavourite, isNearby, user]);

  const handleClick = () => {
    if (isNearby || isFavourite) return;
    const filtersValue = filters ? JSON.parse(filters) : null;
    if (!filtersValue) return;
    dispatch(updateFilters({ ...filtersValue }));
    history.push("/tabs/listings", { direction: "forward" });
  };

  const renderFavouriteTitle = () => {
    return (
      <>
        Because you liked{" "}
        <IonRouterLink
          routerLink={`/listings/${favourite}`}
          routerDirection="forward"
          className={styles.link}
        >
          {similarTitle}
        </IonRouterLink>
      </>
    );
  };

  const calculateViews = () => {
    if (window.innerWidth < 960 && window.innerWidth > 720) return 3.1;
    if (window.innerWidth < 720 && window.innerWidth > 540) return 2.1;
    return 1.1;
  };

  const SLIDES_OPTIONS = {
    spaceBetween: 0,
    slidesPerView: calculateViews(),
    initialSlide: 0,
    centeredSlides: false,
    loop: true,
  };

  const handleLoad = () => {
    if (ref.current) {
      ref.current.options = SLIDES_OPTIONS;
    }
  };

  return !loading && listings && listings.length > 0 ? (
    !isFavourite || (isFavourite && user) ? (
      <div className={styles.featured}>
        <div className={styles.header}>
          <span className={styles.viewAll} onClick={handleClick}>
            {!isFavourite && !isNearby && "See all"}
          </span>
          <span className={styles.title}>{title}</span>
          <div className={styles.subtitleContainer}>
            <span className={styles.subtitle}>
              {!isFavourite ? subtitle : renderFavouriteTitle()}
            </span>
          </div>
        </div>

        <div className={styles.slider}>
          {listings.length > 1 ? (
            <IonSlides onIonSlidesDidLoad={handleLoad} ref={ref} key={v4()}>
              {listings.map((listing) => (
                <IonSlide key={listing.mlsNumber}>
                  <ListingCard
                    isActiveComparables={true}
                    listing={listing}
                    route="/tabs/home"
                  />
                </IonSlide>
              ))}
            </IonSlides>
          ) : (
            <ListingCard isActiveComparables={true} listing={listings[0]} />
          )}
        </div>
      </div>
    ) : null
  ) : null;
};

export default memo(Featured);
