import { useState, useEffect } from "react";
import DropdownContainer from "../Form/DropdownContainer/dropdown-container.component";
import styles from "./range-filter.module.scss";
import Range from "./components/range.component";

const RangeFilter = ({
  open,
  value,
  range,
  dropdown = true,
  onChange,
  type = "currency",
  onlyMin = false,
  onlyMax = false,
  ...rest
}) => {
  const [minOptions, setMinOptions] = useState(range);
  const [maxOptions, setMaxOptions] = useState(range);

  useEffect(() => {
    setMinOptions(
      onlyMin
        ? range
        : range
            .filter((val) => val !== "max")
            .filter((val) => val !== "any")
            .filter((val) => {
              if (val === 0) return true;
              if (value.max === "max" || value.max === "any") return true;
              if (!value.max) return true;
              if (+val < +value.max) return true;
              return false;
            })
    );

    setMaxOptions(
      range.filter((val) => {
        if (val === "max" || val === "any") return true;
        if (!value.min) return true;
        return +val > +value.min;
      })
    );
  }, [onlyMin, range, value]);

  const render = () => (
    <div className={styles.rangesContainer} {...rest}>
      {!onlyMax && (
        <Range
          type={type}
          title="min"
          style={{ marginRight: 5 }}
          value={value.min ? value.min : "0"}
          range={minOptions}
          onChange={(val) => onChange({ type: "min", value: val })}
        />
      )}

      {!onlyMin && (
        <Range
          type={type}
          title="max"
          value={value.max ? value.max : "max"}
          range={maxOptions}
          style={{ marginLeft: 5 }}
          onChange={(val) => onChange({ type: "max", value: val })}
        />
      )}
    </div>
  );

  return dropdown ? (
    <DropdownContainer open={open} style={{ minWidth: "25rem" }}>
      {render()}
    </DropdownContainer>
  ) : open ? (
    <div className={styles.menuItem}>{render()}</div>
  ) : null;
};

export default RangeFilter;
