import { useState, useRef, createRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IonButton, IonSelect, IonSelectOption } from "@ionic/react";
import styles from "./agent-details.module.scss";
import Input from "../../../../../components/Form/Input/input.component";
import IsVisibleTag from "../../../../../components/IsVisibleTag/is-visible-tag.component";
import {
  addTourStart,
  updateTourStart,
} from "../../../../../redux/tour/tour.actions";
import useComponentVisible from "../../../../../hooks/useComponentVisible";
import { selectCurrentClient } from "../../../../../redux/client/client.selectors";
import { getAgents } from "../../../../../amplify/graphql.utils";

const AgentDetails = ({ tour }) => {
  const [title, setTitle] = useState("");
  const [note, setNote] = useState("");
  const dispatch = useDispatch();
  const inputRefs = useRef({
    tour_title: createRef(),
  });

  const agent = useSelector(selectCurrentClient);
  const [agents, setAgents] = useState();
  const [leadAgent, setLeadAgent] = useState();
  const {
    ref: refAgent,
    isOpen: isOpenAgent,
    setIsOpen: setIsOpenAgent,
  } = useComponentVisible(false);

  useEffect(() => {
    const fetchAgents = async () => setAgents(await getAgents());
    fetchAgents();
  }, [agent]);

  const handleNoteChange = ({ target: { value } }) => {
    setNote(value);
  };

  const handleClick = () => {
    if (tour) {
      const membersData = JSON.parse(tour.membersData);
      const filtered = membersData.filter((m) => m.role !== "Lead Agent");
      filtered.push({
        id: leadAgent.id,
        givenName: leadAgent.givenName,
        familyName: leadAgent.familyName,
        email: leadAgent.email,
        phone: leadAgent.phone,
        role: "Lead Agent",
      });

      dispatch(
        updateTourStart({
          id: tour.id,
          title,
          privateNote: note,
          agentId: leadAgent.id,
          membersData: JSON.stringify(filtered),
        })
      );
    } else {
      dispatch(
        addTourStart({
          title,
          privateNote: note,
          agentId: leadAgent.id,
          membersData: JSON.stringify([
            {
              id: leadAgent.id,
              givenName: leadAgent.givenName,
              familyName: leadAgent.familyName,
              email: leadAgent.email,
              phone: leadAgent.phone,
              role: "Lead Agent",
            },
          ]),
        })
      );
    }
  };

  useEffect(() => {
    if (tour) {
      setTitle(tour.title);
      setNote(tour.privateNote ? tour.privateNote : "");
      setLeadAgent(tour.agent);
      const div = document.getElementById("new_tour");
      div?.scrollIntoView();
    }
  }, [tour]);
  return (
    <div className={styles.agentDetails}>
      <div className={styles.subtitle}>
        Enter your tour information – you can always edit it later. Note that
        some content will only be visible by you, the agent. Once you create a
        tour, you can add and edit showings.
      </div>

      <div className={styles.label}>
        <div className={styles.title}>Agent details</div>
        <IsVisibleTag isVisible={true} />
      </div>
      <div className={styles.inputs}>
        <Input
          name="tour_title"
          label="Tour title"
          placeholder="Tour title"
          required
          ref={inputRefs.current.tour_title}
          value={title}
          onChange={(value, name) => setTitle(value)}
          validation="required"
        />
        <div ref={refAgent} className={styles.container}>
          <IonSelect
            className={styles.select}
            value={leadAgent}
            placeholder="Lead agent*"
            onIonChange={({ detail: { value } }) => setLeadAgent(value)}
            interface="alert"
            interfaceOptions={{ header: "Lead Agent" }}
          >
            {agents &&
              agents.map((a) => (
                <IonSelectOption key={a.id} value={a}>
                  {a.givenName} {a.familyName}
                </IonSelectOption>
              ))}
          </IonSelect>
        </div>
        <br />
        <div className={styles.label}>
          <div className={styles.title}>Private notes</div>
          <IsVisibleTag isVisible={false} />
        </div>
        <textarea
          className={styles.textarea}
          name="agent_note"
          placeholder="Private agent tour notes"
          ref={inputRefs.current.agent_note}
          value={note}
          onChange={handleNoteChange}
        ></textarea>
      </div>

      <IonButton
        shape="round"
        expand="block"
        className={styles.button}
        onClick={handleClick}
        disabled={!title || !leadAgent}
      >
        Save and continue
      </IonButton>
      <div className={styles.whitespace}></div>
    </div>
  );
};

export default AgentDetails;
