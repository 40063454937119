import { useState, useEffect } from "react";
import Select from "../../Form/Select/select.component";
import styles from "./range.module.scss";
import millify from "millify";
import { numberWithCommas } from "../../../utils/functions";

const regex = /^[0-9\b]+$/;

const Range = ({ title, value, range, onChange, type, ...rest }) => {
  const [options, setOptions] = useState(range);
  const [open, setOpen] = useState(false);
  const handleChange = (obj) => {
    const {
      value,
      source: { label, type },
    } = obj;

    if (type === "currency") {
      if (regex.test(value)) setOptions(range.filter((opt) => opt >= value));
    }
  };

  const handleSubmit = (obj) => {
    const {
      value,
      source: { label, type },
    } = obj;
    if (type === "currency") {
      if (regex.test(value)) {
        onChange(value);
        setOpen(false);
      }
    }
  };

  useEffect(() => {
    setOptions(range);
  }, [range]);

  return (
    <div className={styles.range} {...rest}>
      <div className={styles.title}>{title}</div>
      <Select
        title={
          value === "max" || value === "any"
            ? value
            : value
            ? type === "currency"
              ? `$${millify(value)}`
              : value
            : null
        }
        open={open}
        onClick={() => setOpen((open) => !open)}
        embedded={true}
        editable={
          type === "currency" ? { type: "currency", label: title } : false
        }
        onChange={handleChange}
        onSubmit={handleSubmit}
      />
      {open && (
        <div className={styles.options}>
          {options.map((option) => (
            <span
              key={option}
              className={`${styles.option} ${
                value === option ? styles.selected : ""
              }`}
              onClick={() => onChange(option)}
            >
              {option === "max" || option === "any"
                ? option
                : type === "currency"
                ? `$${numberWithCommas(option)}`
                : option}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default Range;
