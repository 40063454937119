export const UIActionTypes = {
  UPDATE_BADGE: "UPDATE_BADGE",
  UPDATE_NOTIFICATION: "UPDATE_NOTIFICATION",
  MARK_NOTIFICATION: "MARK_NOTIFICATION",
  RESET_NOTIFICATION: "RESET_NOTIFICATION",
  OPEN_MODAL: "OPEN_MODAL",
  CLOSE_MODAL: "CLOSE_MODAL",
  OPEN_AUTH_MODAL: "OPEN_AUTH_MODAL",
  CLOSE_AUTH_MODAL: "CLOSE_AUTH_MODAL",
  COLLAPSE_MENU: "COLLAPSE_MENU",
  UPDATE_MENU_TAB: "UPDATE_MENU_TAB",
  UPDATE_DRAW_MODE: "UPDATE_DRAW_MODE",
  UPDATE_TOAST: "UPDATE_TOAST",
  UPDATE_CHANGED_FAVOURITE: "UPDATE_CHANGED_FAVOURITE",
};
