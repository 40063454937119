import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  IonLoading,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonDatetime,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonSlides,
  IonSlide,
  IonSkeletonText,
} from "@ionic/react";
import useComponentVisible from "../../../../../hooks/useComponentVisible";
import styles from "./add-showing.module.scss";
import {
  addOutline,
  chevronBackOutline,
  chevronDownOutline,
  closeOutline,
  starOutline,
  warningOutline,
} from "ionicons/icons";
import Input from "../../../../../components/Form/Input/input.component";
import DropdownContainer from "../../../../../components/Form/DropdownContainer/dropdown-container.component";
import ListingDetails from "./component/listing-details.component";
import { selectCurrentUser } from "../../../../../redux/user/user.selectors";
import { debounce } from "lodash";
import { getListing, getTourListing } from "../../../../../api/repliers";
import SearchResult from "../../../../../components/LocationSearch/components/search-result.component";
import Map from "./component/map.component";
import {
  addListingToTour,
  getFavouriteItems,
} from "../../../../../amplify/graphql.utils";
import moment from "moment";
import MapListingCard from "../../../../../components/Map/components/MapListingCard/map-listing.card.component";
import {
  buildAddress,
  getHighlightedLength,
} from "../../../../../utils/functions";

const regex = /^[a-zA-Z]\d+/;
const validRegex = /^[a-zA-Z]\d{7}/;
const AddShowing = ({ tour, reload, setIsOpen, nextListingOrder }) => {
  const user = useSelector(selectCurrentUser);
  const [listing, setListing] = useState();
  const [toggleFavourites, setToggleFavourites] = useState(false);
  const [favourites, setFavourites] = useState();
  const [favLoading, setFavLoading] = useState(false);
  const [term, setTerm] = useState("");
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [time, setTime] = useState({ start: undefined, end: undefined });
  const [status, setStatus] = useState();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [entryInfo, setEntryInfo] = useState("");
  const {
    ref: refInput,
    isOpen: isOpenInput,
    setIsOpen: setIsOpenInput,
  } = useComponentVisible(false);
  const handleTermChange = (value, name) => {
    setTerm(value);
  };
  const handleEntryInfoChange = (value, name) => {
    setEntryInfo(value);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetch = useCallback(
    debounce((term) => fetchResults(term), 500),
    []
  );
  const fetchResults = async (term) => {
    setLoading(true);
    if (regex.test(term)) {
      if (validRegex.test(term)) {
        try {
          const response = await getListing(term);
          setResults([response]);
        } catch (err) {
          setResults(null);
        }
      } else {
        setResults(null);
      }
    } else {
      try {
        const response = await getTourListing(term);
        setResults(response.length ? response : null);
      } catch (err) {
        setResults(null);
        setLoading(false);
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    if (term.length && !listing) {
      setLoading(true);
      debouncedFetch(term);
    }
  }, [term, debouncedFetch, listing]);
  useEffect(() => {
    if (term && !loading && !listing) setIsOpenInput(true);
  }, [term, loading, setIsOpenInput, listing]);

  useEffect(() => {
    setTerm("");
    setListing(null);
  }, [tour]);

  useEffect(() => {
    const fetchFavs = async () => {
      setFavLoading(true);
      const favs = await getFavouriteItems();
      setFavourites(favs);
      setFavLoading(false);
    };
    fetchFavs();
  }, []);

  const reset = () => {
    setListing(null);
    setTerm("");
    setTime({ start: undefined, end: undefined });
    setStatus(null);
    setEntryInfo("");
  };
  const handleSubmit = async () => {
    setSubmitLoading(true);
    try {
      await addListingToTour({
        owner: user.username,
        tourId: tour.id,
        agentId: tour.agentId,
        showingAgentId: tour.showingAgentId,
        members: tour.members,
        membersData: JSON.stringify(JSON.parse(tour.membersData)),
        mlsNumber: listing.mlsNumber,
        status,
        entryInfo,
        startTime: time.start && moment(time.start).format("HH:mm"),
        endTime: time.end && moment(time.end).format("HH:mm"),
        order: nextListingOrder,
      });
      reload();
      reset();
    } catch (err) {
      console.log(err);
    }
    setSubmitLoading(false);
    setIsOpen(false);
  };

  const addFromFavourites = async (mls) => {
    setTerm("");
    setListing(null);
    setEntryInfo("");
    setStatus(null);
    setTime({ start: undefined, end: undefined });
    const listing = await getListing(mls);
    if (listing) {
      setListing(listing);
      setTerm(mls);
    }
  };

  const compareListings = (a, b) => {
    const aMatch = getHighlightedLength(
      `${a.address.city} - ${buildAddress(a.address)}`,
      term
    );

    const bMatch = getHighlightedLength(
      `${b.address.city} - ${buildAddress(b.address)}`,
      term
    );

    if (aMatch > bMatch) return -1;
    if (aMatch === bMatch) return 0;
    return 1;
  };

  const slideOpts = {
    spaceBetween: 20,
    slidesPerView: 1.2,
    initialSlide: 0,
    centeredSlides: true,
    loop: true,
  };

  return (
    <>
      <IonHeader className={styles.appHeader}>
        <IonToolbar className={styles.toolbar}>
          <IonButtons slot="start">
            <IonButton
              className={styles.backButton}
              slot="start"
              text="Back"
              onClick={() => setIsOpen(false)}
            >
              <IonIcon icon={chevronBackOutline} />
              Back
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton className={styles.clearButton} onClick={reset}>
              Clear
            </IonButton>
          </IonButtons>
          <IonTitle className={styles.title}>Add showing</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={styles.addShowing}>
        <IonLoading isOpen={submitLoading} />
        <IonGrid className={styles.inputs}>
          <IonRow>
            <IonCol>
              <div ref={refInput} className={styles.container}>
                <Input
                  label="location or MLS #"
                  placeholder="location or MLS #"
                  mode="search"
                  value={term}
                  search
                  clear={() => {
                    setTerm("");
                    setListing(null);
                  }}
                  onChange={handleTermChange}
                  loading={loading}
                  onClick={() => {
                    if (term && results) setIsOpenInput(true);
                  }}
                  disabled={listing}
                />
                {isOpenInput && (
                  <DropdownContainer
                    open={term && !loading}
                    style={{
                      maxHeight: "22rem",
                    }}
                  >
                    {!results && (
                      <div className={styles.noResult}>
                        <IonIcon
                          icon={warningOutline}
                          className={styles.icon}
                        />
                        No listing found. Try revising your search.
                      </div>
                    )}
                    {results &&
                      results.sort(compareListings).map((listing) => (
                        <SearchResult
                          key={listing.mlsNumber}
                          item={listing}
                          term={term}
                          type="listing"
                          onClick={() => {
                            setListing(listing);
                            setTerm(listing.mlsNumber);
                            setIsOpenInput(false);
                          }}
                        />
                      ))}
                  </DropdownContainer>
                )}
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem
                className={`${styles.item} ${styles.favourite}`}
                detail
                detailIcon={toggleFavourites ? closeOutline : starOutline}
                lines="none"
                onClick={() =>
                  setToggleFavourites((toggleFavourites) => !toggleFavourites)
                }
              >
                <IonLabel>
                  {toggleFavourites
                    ? "Close favourites"
                    : "Or choose from your favourites"}
                </IonLabel>
              </IonItem>
              {toggleFavourites && (
                <>
                  {favLoading ? (
                    <IonSkeletonText
                      animated
                      style={{ height: "8rem", padding: "0 1.5rem" }}
                    />
                  ) : favourites && favourites.length > 0 ? (
                    <IonSlides options={slideOpts}>
                      {favourites.map((fav) => (
                        <IonSlide key={fav.id}>
                          <MapListingCard
                            listing={JSON.parse(fav.listing)}
                            addShowing
                            handleAddShowing={() =>
                              addFromFavourites(fav.mlsNumber)
                            }
                          />
                        </IonSlide>
                      ))}
                    </IonSlides>
                  ) : (
                    <div className={styles.noFav}>
                      Search the listings or review your saved searches to find
                      new favourites.
                    </div>
                  )}
                </>
              )}
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem
                className={styles.item}
                detail
                detailIcon={chevronDownOutline}
                lines="none"
              >
                <IonDatetime
                  placeholder="Tour start time"
                  displayFormat="h:mm A"
                  minuteValues="0,15,30,45"
                  value={time.start}
                  onIonChange={(e) =>
                    setTime({ ...time, start: e.detail.value })
                  }
                  className={styles.picker}
                ></IonDatetime>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem
                className={styles.item}
                detail
                detailIcon={chevronDownOutline}
                lines="none"
              >
                <IonDatetime
                  placeholder="Tour end time"
                  displayFormat="h:mm A"
                  minuteValues="0,15,30,45"
                  value={time.end}
                  onIonChange={(e) => setTime({ ...time, end: e.detail.value })}
                  className={styles.picker}
                ></IonDatetime>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem className={styles.item} lines="none">
                <IonLabel>Showing status*</IonLabel>
                <IonSelect
                  value={status}
                  okText="Save"
                  cancelText="Cancel"
                  onIonChange={(e) => setStatus(e.detail.value)}
                >
                  <IonSelectOption value="requested">Requested</IonSelectOption>
                  <IonSelectOption value="confirmed">Confirmed</IonSelectOption>
                  <IonSelectOption value="completed">Completed</IonSelectOption>
                  <IonSelectOption value="skipped">Skipped</IonSelectOption>
                  <IonSelectOption value="cancelled">Cancelled</IonSelectOption>
                  <IonSelectOption value="rejected">Rejected</IonSelectOption>
                </IonSelect>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <div className={styles.container}>
                <Input
                  name="entryInfo"
                  label="Lockbox and entry info"
                  showLabel={false}
                  placeholder="Lockbox and entry info"
                  value={entryInfo}
                  onChange={(value, name) => setEntryInfo(value)}
                />
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <div
                className={`${styles.map} ${listing && styles.mapTransparent}`}
              >
                {listing && <Map location={listing.map} />}
              </div>
            </IonCol>
          </IonRow>
          {listing && <ListingDetails listing={listing} />}
          <IonButton
            shape="round"
            expand="block"
            className={styles.button}
            disabled={!status}
            onClick={handleSubmit}
          >
            <IonIcon icon={addOutline} />
            Add showing to tour
          </IonButton>
        </IonGrid>
      </IonContent>
    </>
  );
};
export default AddShowing;
