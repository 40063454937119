import { useState } from "react";
import NotePlayer from "../../../../app/components/NotePlayer/note-player.component";
import Button from "../../../Form/Button/button.component";
import styles from "./notes-section.module.scss";

const NotesSection = ({
  isCurrentUser,
  note,
  create,
  update,
  memberData,
  disabled,
  tour,
}) => {
  const [content, setContent] = useState(note ? note.content : "");

  const handleSubmit = () => {
    if (!note) {
      create(content);
    } else {
      update({ content, id: note.id });
    }
  };

  const isDisabled = () =>
    (note && note.content === content) ||
    disabled ||
    !tour ||
    // !tour.status ||
    tour.status === "archived";

  return (
    <div className={styles.notesSection}>
      {isCurrentUser ? (
        <div className={styles.currentUser}>
          <span>My notes</span>
        </div>
      ) : (
        <div className={styles.others}>
          <span>{`${memberData.givenName} ${memberData.familyName}`}</span>
          <span className={styles.role}>{memberData.role}</span>
        </div>
      )}

      {isCurrentUser ? (
        <textarea
          className={styles.textarea}
          name="note"
          placeholder="Notes"
          value={content}
          onChange={(event) => {
            setContent(event.target.value);
          }}
          disabled={!isCurrentUser}
        ></textarea>
      ) : (
        <div className={styles.othersNote}>
          {content}
          <NotePlayer
            content={content}
            name={`${memberData.givenName} ${memberData.familyName}`}
          />
        </div>
      )}

      {isCurrentUser && (
        <Button
          title="Save changes"
          type="green"
          style={{
            border: !isDisabled() && "1px solid #1F7A60",
            width: "100%",
            height: "5rem",
            marginBottom: "4rem",
          }}
          onClick={handleSubmit}
          disabled={isDisabled()}
        />
      )}
    </div>
  );
};

export default NotesSection;
