import styles from "./section-header.module.scss";

const SectionHeader = ({
  profile,
  heading,
  subHeading,
  givenName,
  familyName,
}) =>
  profile ? (
    <div className={styles.profileRow}>
      <div className={styles.firstColumTitle}>
        <div className={styles.header}>{heading}</div>
        <div className={styles.subheader}>{subHeading}</div>
      </div>
      {givenName && familyName && (
        <div className={styles.imageContainer}>
          <span className={styles.initials}>
            {givenName.charAt(0).toUpperCase() +
              familyName.charAt(0).toUpperCase()}
          </span>
        </div>
      )}
    </div>
  ) : (
    <div className={styles.firstColumTitle}>
      <div className={styles.header}>{heading}</div>
      <div className={styles.subheader}>{subHeading}</div>
    </div>
  );

export default SectionHeader;
