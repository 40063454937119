import { createSelector } from "reselect";

const selectLocations = (state) => state.locations;

export const selectLocationsValue = createSelector(
  [selectLocations],
  (locations) => locations.locations
);

export const selectLocationsCitiesAndNeighbourhoods = createSelector(
  [selectLocationsValue],
  (locations) => {
    const res = [];
    if (!locations) return res;
    locations.forEach((location) => {
      location.value[0].cities.forEach((city) => {
        res.push({
          type: "City",
          name: city.name,
          location: city.location,
        });
        city.neighborhoods.forEach((n) =>
          res.push({
            type: "Neighbourhood",
            name: n.name,
            location: n.location,
            coords: n.coordinates,
          })
        );
      });
    });

    return res;
  }
);
