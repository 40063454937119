import { useState } from "react";
import DropdownContainer from "../Form/DropdownContainer/dropdown-container.component";
import Select from "../Form/Select/select.component";
import styles from "./radio-filter.module.scss";

const RadioFilter = ({
  open,
  value,
  options,
  onClick,
  subValue,
  subTitle,
  subOptions,
  subCondition,
  onSubClick,
  dropdown = true,
  color = "purple",
  size = 1,
  showScroll = false,
  ...rest
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const render = () => (
    <div
      className={`${styles.radioContainer} ${showScroll && styles.showScroll}`}
      {...rest}
    >
      {options.map((option) => (
        <div key={option.label}>
          <label
            key={option.label}
            className={styles.option}
            onClick={() => onClick(option)}
          >
            <span className={`${styles.radio} ${styles[color]}`}>
              <input
                type="checkbox"
                name="radio"
                checked={option.label === value}
                onChange={() => onClick(option)}
              />
              <span className={styles.radioControl} />
            </span>
            <span className={styles.label}>{option.label}</span>
          </label>
          {subOptions && option.checked && subCondition.includes(option.label) && (
            <>
              <Select
                title={subTitle}
                onClick={() => setDropdownOpen(!dropdownOpen)}
                dropdown={false}
                embedded={true}
                open={dropdownOpen}
              />
              {dropdownOpen && (
                <div className={styles.subItems}>
                  {subOptions.map((option) => (
                    <span
                      key={option.label}
                      onClick={() => onSubClick(option.value)}
                      className={`${styles.subItem} ${
                        option.value === subValue && styles.subItemSelected
                      }`}
                    >
                      {option.label}
                    </span>
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      ))}
    </div>
  );

  return dropdown ? (
    <DropdownContainer open={open} style={{ minWidth: `${10 * size}rem` }}>
      {render()}
    </DropdownContainer>
  ) : open ? (
    <div className={styles.menuItem}>{render()}</div>
  ) : null;
};

export default RadioFilter;
