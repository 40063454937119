import { useState, useRef, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IonGrid, IonRow, IonCol, IonButton, IonContent } from "@ionic/react";
import styles from "./contact-agent.module.scss";
import Input from "../../../../../components/Form/Input/input.component";
import TextArea from "../../../../../components/Form/TextArea/textarea.component";
import { updateToast } from "../../../../../redux/ui/ui.actions";
import { contactUs } from "../../../../../api/ettie";
import AppHeader from "../../../../components/Header/header.component";

const ContactAgentForm = ({ setShowModal }) => {
  const dispatch = useDispatch();

  const inputRefs = useRef({
    email: createRef(),
    given_name: createRef(),
    family_name: createRef(),
    phone: createRef(),
  });

  const [formData, setFormData] = useState({
    email: "",
    given_name: "",
    family_name: "",
    phone: "",
    message: "",
  });

  const handleChange = (value, name) => {
    inputRefs.current[name].current.validate();
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      await contactUs({
        name: `${formData.given_name} ${formData.family_name}`,
        givenName: formData.given_name,
        email: formData.email,
        phone: formData.phone,
        message: formData.message,
      });
      dispatch(
        updateToast({
          open: true,
          type: "success",
          message: "Message sent successfully.",
        })
      );
    } catch (err) {
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message: "Something went wrong!",
        })
      );
    }
  };

  return (
    <>
      <AppHeader
        isModal={true}
        title="Connect today"
        onClick={() => setShowModal(false)}
      />
      <IonContent>
        <IonGrid className={styles.contactAgent}>
          <IonRow className={styles.form}>
            <IonCol>
              <div className={styles.formsubtitle}>
                Send us a message or an email, and we will get back to you
                within 24 hours.
              </div>
              <div className={styles.inputs}>
                <IonRow>
                  <IonCol>
                    <Input
                      name="given_name"
                      label="given name"
                      placeholder="given name"
                      required
                      ref={inputRefs.current.given_name}
                      value={formData["given_name"]}
                      //   defaultValue={client && client.givenName}
                      onChange={(value, name) => handleChange(value, name)}
                      validation="required|min:2"
                      isApp
                      enterkeyhint="next"
                      submit={() =>
                        inputRefs.current.family_name.current.next()
                      }
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <Input
                      name="family_name"
                      label="family name"
                      placeholder="family name"
                      required
                      ref={inputRefs.current.family_name}
                      value={formData["family_name"]}
                      //   defaultValue={client && client.familyName}
                      onChange={(value, name) => handleChange(value, name)}
                      validation="required|min:2"
                      isApp
                      enterkeyhint="next"
                      submit={() => inputRefs.current.email.current.next()}
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <Input
                      name="email"
                      label="email"
                      placeholder="email"
                      mode="email"
                      required
                      ref={inputRefs.current.email}
                      value={formData["email"]}
                      //   defaultValue={client && client.email}
                      onChange={(value, name) => handleChange(value, name)}
                      validation="required|email"
                      isApp
                      enterkeyhint="next"
                      submit={() => inputRefs.current.phone.current.next()}
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <Input
                      name="phone"
                      label="phone"
                      placeholder="phone"
                      mode="tel"
                      required
                      ref={inputRefs.current.phone}
                      value={formData["phone"]}
                      //   defaultValue={
                      // client && client.phone && client.phone.substring(2)
                      //   }
                      onChange={(value, name) => handleChange(value, name)}
                      validation="required|phone"
                      isApp
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <TextArea
                      value={formData.message}
                      onChange={(val) =>
                        setFormData({ ...formData, message: val })
                      }
                      label="message"
                      placeholder="message"
                      required
                    />
                  </IonCol>
                </IonRow>
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonButton
                expand="block"
                shape="round"
                className={styles.button}
                disabled={
                  !formData.email ||
                  !formData.given_name ||
                  !formData.family_name ||
                  !formData.message
                }
                onClick={handleSubmit}
              >
                Submit
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </>
  );
};

export default ContactAgentForm;
