import { takeLatest, call, put, all } from "redux-saga/effects";
import { CurrencyActionTypes } from "./currency.types";
import { fetchCurrencyFailure, fetchCurrencySuccess } from "./currency.actions";
import { API } from "aws-amplify";

const getRates = async () => {
  try {
    const { rates } = await API.get("ettieREST", "/currency", "");
    return rates;
  } catch (err) {
    throw Error("Something went wrong!");
  }
};

export function* fetchCurrencyAsync() {
  try {
    const rates = yield call(getRates);
    yield put(fetchCurrencySuccess(rates));
  } catch (error) {
    yield put(fetchCurrencyFailure("Something went wrong!"));
  }
}
export function* fetchCurrencyStart() {
  yield takeLatest(
    CurrencyActionTypes.FETCH_CURRENCY_START,
    fetchCurrencyAsync
  );
}
export function* currencySagas() {
  yield all([call(fetchCurrencyStart)]);
}
