import { Helmet } from "react-helmet";
import { IonIcon, useIonViewDidEnter } from "@ionic/react";
import { useState, useEffect, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./search.module.scss";
import ClientSearchMap from "../../components/ClientSearchMap/client-search-map.component.jsx";
import Listings from "../../components/Listings/listings.component.jsx";
import SearchConfig from "../../components/SearchConfig/search-config.component";
import { selectMapFavourites } from "../../redux/map/map.selectors";
import SkinnyFooter from "../../components/Footer/skinny-footer.component";
import { keyOutline, pricetagOutline } from "ionicons/icons";
import preConstruction from "../../assets/svg/preconstruction.svg";
import queryString from "query-string";
import { selectMapRef } from "../../redux/map/map.selectors";
import { updateFilters } from "../../redux/filters/filters.actions";
import {
  VALID_FILTERS,
  VALID_PROPERTY_TYPES,
} from "../../app/pages/Filters/data/filters.data";
import withFilters from "../../HOC/withFilters/with-filters";

const SearchPage = (props) => {
  const tabs = [
    {
      label: props.filters.status === "A" ? "Sale" : "Sold",
      value: "sale",
      icon: <IonIcon icon={pricetagOutline} />,
      order: 3,
      disabled: false,
      size: "small",
    },
    {
      label: props.filters.status === "A" ? "Lease" : "Leased",
      value: "lease",
      icon: <IonIcon icon={keyOutline} />,
      order: 2,
      disabled: false,
      size: "small",
    },
    {
      label: "Pre-construction",
      value: "Pre-construction",
      icon: <IonIcon src={preConstruction} />,
      order: 1,
      disabled: false,
      size: "small",
    },
  ];

  const dispatch = useDispatch();
  const { pathname } = props;
  const params = queryString.parse(props.location.search);
  const favouritesOnly = useSelector(selectMapFavourites);
  const mapRef = useSelector(selectMapRef);

  const [sortBy, setSortBy] = useState({
    value: "date",
    direction: "desc",
  });
  const [view, setView] = useState("map");

  useEffect(() => {
    if (mapRef && params.lat && params.lng) {
      mapRef.panTo({
        lat: +params.lat,
        lng: +params.lng,
      });
    }
  }, [mapRef, params.lat, params.lng]);

  useIonViewDidEnter(() => {
    if (params) {
      const filters = Object.keys(params)
        .filter((p) => p !== "lat" && p !== "lng" && VALID_FILTERS.includes(p))
        .reduce((obj, key) => {
          if (key === "propertyType") {
            const types = params[key]
              .split(",")
              .filter((t) => VALID_PROPERTY_TYPES.includes(t));
            if (types && types.length) obj[key] = types;
          } else if (
            ["minPrice", "maxPrice", "minBeds", "maxBeds", "minSqft"].includes(
              key
            )
          ) {
            if (typeof +params[key] === "number" && params[key] >= 0)
              obj[key] = params[key];
          } else {
            obj[key] = params[key];
          }

          return obj;
        }, {});

      if (filters && Object.keys(filters).length) {
        dispatch(updateFilters({ ...props.filters, ...filters }));
      }
    }
  }, [params]);

  const isHidden = () => {
    return !["/listings", "/filters"].includes(pathname);
  };

  useEffect(() => {
    if (favouritesOnly && view !== "map") setView("map");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favouritesOnly]);

  return (
    <div className={styles.search}>
      <Helmet>
        <title>Ettie - Properties in GTA</title>
        <meta name="description" content="Search for properties in GTA" />
      </Helmet>

      <>
        <SearchConfig
          view={view}
          setView={setView}
          sortBy={sortBy}
          setSortBy={setSortBy}
          hide={isHidden()}
          tabs={tabs}
        />
        <Listings
          sortBy={sortBy}
          view={view}
          hide={isHidden()}
          isPreConstruction={
            props.filters.type.toLowerCase() === "pre-construction"
          }
        />
        <div className={styles.footer}>
          <SkinnyFooter />
        </div>
      </>

      <ClientSearchMap
        view={view}
        setView={() => (view === "map" ? setView("list") : setView("map"))}
        hide={isHidden()}
        tabs={tabs}
      />
    </div>
  );
};

export default memo(withFilters(SearchPage));
