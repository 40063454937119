import { useSelector } from "react-redux";
import styles from "./listing-tag.module.scss";
import millify from "millify";
import { selectCurrentClient } from "../../../../redux/client/client.selectors";
import { selectCurrencyRates } from "../../../../redux/currency/currency.selectors";
const ListingTag = ({ updates, isApp = false }) => {
  let title;
  let value;

  const client = useSelector(selectCurrentClient);
  const rates = useSelector(selectCurrencyRates);

  let currency;
  if (client) {
    if (client.currency !== "cad") currency = client.currency.toUpperCase();
  }

  if (updates.listPrice) {
    const { current, previous } = updates.listPrice;
    if (current > previous) {
      title = "Price rise:";
      value = currency
        ? millify(
            (+current / +rates.CAD.S) * +rates[currency].S -
              (+previous / +rates.CAD.S) * +rates[currency].S
          )
        : millify(+current - +previous);
    } else if (current < previous) {
      title = "Price drop:";
      value = currency
        ? millify(
            (+previous / +rates.CAD.S) * +rates[currency].S -
              (+current / +rates.CAD.S) * +rates[currency].S
          )
        : millify(+previous - +current);
    }
  }

  if (updates.status) {
    const { current } = updates.status;

    title = "Status change:";
    value = current === "A" ? "active" : "unavailable";
  }

  if (updates.lastStatus) {
    const { current } = updates.lastStatus;
    if (current === "Pc" && updates.listPrice) {
    } else {
      title = "Status change:";
      switch (current) {
        case "Sus":
          value = "suspended";
          break;
        case "Exp":
          value = "expired";
          break;
        case "Sld":
          value = "sold";
          break;
        case "Sc":
          value = "sold conditional";
          break;
        case "Ter":
          value = "terminated";
          break;
        case "Lsd":
          value = "leased";
          break;
        case "Lc":
          value = "leased conditional";
          break;
        case "Pc":
          value = "Price changed";
          break;
        case "New":
          value = "Recently listed";
          break;
        default:
          value = null;
      }
    }
  }

  return title && value ? (
    <div className={isApp ? styles.appTag : styles.tag}>
      <span className={styles.title}>{title}</span>
      <span className={styles.value}>{value}</span>
    </div>
  ) : null;
};

export default ListingTag;
