import React from "react";
import styles from "./radio-button.module.scss";

const RadioButton = ({ onClick, value, label, labelStyle }) => {
  return (
    <label className={styles.option} onChange={() => onClick(label)}>
      <span className={styles.radio}>
        <input
          type="checkbox"
          name="radio"
          checked={label === value}
          onChange={() => onClick(label)}
        />
        <span className={styles.radioControl} />
      </span>
      <span className={styles.label} style={labelStyle}>
        {label}
      </span>
    </label>
  );
};

export default RadioButton;
