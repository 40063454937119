import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonIcon,
  IonButton,
  IonLoading,
} from "@ionic/react";
import styles from "./tour-attendees.module.scss";
import { closeCircleOutline, personAddOutline } from "ionicons/icons";
import {
  addClients,
  getAgents,
  getClients,
  getTourClients,
  removeClients,
  updateTour,
  updateTourClientItem,
  updateTourListingItem,
} from "../../../../../../../amplify/graphql.utils";
import IsVisibleTag from "../../../../../../../components/IsVisibleTag/is-visible-tag.component";
import { updateToast } from "../../../../../../../redux/ui/ui.actions";
import _ from "lodash";
import SearchableSelect from "../../../../../../components/Form/SearchableSelect/searchable-select.component";

const TourAttendees = ({ tour, setIsOpen, reload, selfRef }) => {
  const dispatch = useDispatch();
  const { agent, showingAgent: showing, members: attendees } = tour;
  const [loading, setLoading] = useState(false);
  const [agents, setAgents] = useState();
  const [client, setClient] = useState();
  const [guests, setGuests] = useState([]);
  const [clientsList, setClientsList] = useState();
  const [leadAgent, setLeadAgent] = useState();
  const [showingAgent, setShowingAgent] = useState();

  useEffect(() => {
    const fetchClients = async () => {
      setLoading(true);
      try {
        const clients = await getTourClients({ clients: attendees });
        setClientsList(await getClients(agent.brokerageId));
        if (clients && clients.length) {
          setClient(clients.filter((c) => c.id === attendees[0])[0]);
          setGuests(clients.filter((c) => c.id !== attendees[0]));
        }

        setLeadAgent(agent);
        setShowingAgent(showing);
      } catch (err) {
        console.log(err);
      }
    };

    const fetchAgents = async () => {
      try {
        const agents = await getAgents();
        setAgents(agents);
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    };

    fetchClients();
    fetchAgents();
  }, [agent, attendees, showing]);

  const handleSubmit = async () => {
    setLoading(true);
    const members = [client.id];
    [...guests].forEach((item) => members.push(item.id));

    const toAdd = _.differenceWith(
      members,
      tour.members ? [...tour.members] : [],
      _.isEqual
    );
    const toRemove = _.differenceWith(
      tour.members ? [...tour.members] : [],
      members,
      _.isEqual
    );

    try {
      const membersData = JSON.parse(tour.membersData);
      if (!membersData.find((m) => m.id === leadAgent.id)) {
        membersData.push({
          id: leadAgent.id,
          givenName: leadAgent.givenName,
          familyName: leadAgent.familyName,
          email: leadAgent.email,
          phone: leadAgent.phone,
          role: "Lead Agent",
        });
      } else {
        const index = membersData.findIndex((m) => m.id === leadAgent.id);
        if (membersData[index].role !== "Lead Agent") {
          membersData[index].role = "Lead Agent";
        }
      }

      if (!showingAgent || !membersData.find((m) => m.id === showingAgent.id)) {
        membersData.push({
          id: showingAgent.id,
          givenName: showingAgent.givenName,
          familyName: showingAgent.familyName,
          email: showingAgent.email,
          phone: showingAgent.phone,
          role: "Showing Agent",
        });
      } else {
        const index = membersData.findIndex((m) => m.id === showingAgent.id);
        if (membersData[index].role !== "Showing Agent") {
          membersData[index].role = "Showing Agent";
        }
      }

      if (!membersData.find((m) => m.id === client.id)) {
        membersData.push({
          id: client.id,
          givenName: client.givenName,
          familyName: client.familyName,
          email: client.email,
          phone: client.phone,
          role: "Client",
        });
      } else {
        const index = membersData.findIndex((m) => m.id === client.id);
        if (membersData[index].role !== "Client") {
          membersData[index].role = "Client";
        }
      }

      [...guests].forEach((g) => {
        if (!membersData.find((m) => m.id === g.id)) {
          membersData.push({
            id: g.id,
            givenName: g.givenName,
            familyName: g.familyName,
            email: g.email,
            phone: g.phone,
            role: "Additional Guest",
          });
        } else {
          const index = membersData.findIndex((m) => m.id === g.id);
          if (membersData[index].role !== "Additional Guest") {
            membersData[index].role = "Additional Guest";
          }
        }
      });
      await updateTour({
        id: tour.id,
        members,
        showingAgentId: showingAgent.id,
        agentId: leadAgent.id,
        membersData: JSON.stringify(membersData),
      });

      await addClients({
        tourId: tour.id,
        agentId: leadAgent.id,
        clients: toAdd,
      });

      await removeClients(
        tour.clients.items.filter((item) => toRemove.includes(item.clientId))
      );
      if (tour.tourItems.items.length) {
        tour.tourItems.items.forEach(async (item) => {
          await updateTourListingItem({
            id: item.id,
            showingAgentId: showingAgent.id,
            agentId: leadAgent.id,
            members,
            membersData: JSON.stringify(membersData),
          });
        });
      }

      if (tour.clients.items.length > 0) {
        tour.clients.items
          .filter((item) => item.agentId !== leadAgent.id)
          .forEach(
            async (tourClient) =>
              await updateTourClientItem({
                id: tourClient.id,
                agentId: leadAgent.id,
              })
          );
      }

      dispatch(
        updateToast({
          open: true,
          message: "Tour saved successfully.",
          type: "success",
        })
      );

      await reload();
    } catch (err) {
      console.log(err);
      dispatch(
        updateToast({
          open: true,
          message: "Something went wrong!",
          type: "error",
        })
      );
    }
    setLoading(false);
  };

  const handleChange = async (val) => {
    setClientsList(await getClients(agent.brokerageId, val));
  };

  return (
    <div className={styles.tourAttendees}>
      <IonLoading isOpen={loading} />
      <IsVisibleTag isVisible={true} expand="full" />
      <div className={styles.inputs}>
        <SearchableSelect
          title="Select the main client"
          type="radio"
          parentRef={selfRef}
          items={clientsList && clientsList.filter((c) => !c.deactivated)}
          selected={client}
          setSelected={setClient}
          label="Client"
          onSave={(client) => setClient(client)}
          onChange={handleChange}
        />

        {client && (
          <SearchableSelect
            title="Select additional guests"
            type="checkbox"
            parentRef={selfRef}
            items={clientsList
              .filter((c) => !c.deactivated)
              .filter((c) => c.id !== client.id)}
            selected={guests}
            setSelected={setGuests}
            onChange={handleChange}
            label="Additional guests"
            icon={personAddOutline}
          />
        )}

        {guests.length > 0 && (
          <div className={styles.guests}>
            {guests
              .filter((g) => g !== null)
              .map((g, i) => (
                <div className={styles.chip} key={i}>
                  {g.name}
                  <IonIcon
                    className={styles.icon}
                    icon={closeCircleOutline}
                    onClick={() =>
                      setGuests(guests.filter((guest) => guest.id !== g.id))
                    }
                  />
                </div>
              ))}
          </div>
        )}

        <IonItem className={styles.item} lines="none">
          <IonLabel>Lead agent</IonLabel>
          <IonSelect
            className={styles.options}
            value={leadAgent ? leadAgent.id : null}
            title={leadAgent ? leadAgent.name : ""}
            okText="Save"
            cancelText="Cancel"
            onIonChange={({ detail: { value } }) =>
              value && setLeadAgent(agents.find((a) => a.id === value))
            }
          >
            {agents ? (
              agents.map((a) => (
                <IonSelectOption key={a.id} value={a.id}>
                  {a.name}
                </IonSelectOption>
              ))
            ) : (
              <IonSelectOption>No agent available.</IonSelectOption>
            )}
          </IonSelect>
        </IonItem>

        <IonItem className={styles.item} lines="none">
          <IonLabel>Showing agent</IonLabel>
          <IonSelect
            className={styles.options}
            value={showingAgent ? showingAgent.id : null}
            title={showingAgent ? showingAgent.name : ""}
            okText="Save"
            cancelText="Cancel"
            onIonChange={({ detail: { value } }) =>
              value && setShowingAgent(agents.find((a) => a.id === value))
            }
          >
            {agents ? (
              agents.map((a) => (
                <IonSelectOption key={a.id} value={a.id}>
                  {a.name}
                </IonSelectOption>
              ))
            ) : (
              <IonSelectOption>No agent available.</IonSelectOption>
            )}
          </IonSelect>
        </IonItem>
      </div>
      <IonButton
        shape="round"
        expand="block"
        className={styles.button}
        disabled={
          !client ||
          !leadAgent ||
          !showingAgent ||
          !tour ||
          !tour.status ||
          tour.status === "archived"
        }
        onClick={handleSubmit}
      >
        Save changes
      </IonButton>
      {tour.shared === "unshared" && (
        <div className={styles.note}>
          The tour will not be available to the client until you share it.
        </div>
      )}
    </div>
  );
};

export default TourAttendees;
