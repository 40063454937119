import { ReactComponent as SearchIcon } from "../../../../assets/svg/search.svg";
import { Link } from "react-router-dom";
import styles from "./add-save-search.module.scss";

const AddSaveSearch = ({ isApp, redirectHandler }) => (
  <div className={isApp ? styles.app : styles.container}>
    <div className={styles.clickable} onClick={redirectHandler}>
      <div className={styles.icon}>
        <SearchIcon className={styles.svg} />
      </div>
      <div className={styles.title}>Add a new saved search</div>
    </div>
    <div className={styles.note}>
      Save your search from the{" "}
      <Link to={isApp ? "/tabs/listings" : "/listings"}>map</Link> page.
    </div>
  </div>
);

export default AddSaveSearch;
