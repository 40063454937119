const ClientActionTypes = {
  FETCH_CLIENT_START: "FETCH_CLIENT_START",
  FETCH_CLIENT_SUCCESS: "FETCH_CLIENT_SUCCESS",
  FETCH_CLIENT_FAILURE: "FETCH_CLIENT_FAILURE",
  ADD_FAVOURITE_START: "ADD_FAVOURITE_START",
  ADD_FAVOURITE_SUCCESS: "ADD_FAVOURITE_SUCCESS",
  ADD_FAVOURITE_FAILURE: "ADD_FAVOURITE_FAILURE",
  REMOVE_FAVOURITE_START: "REMOVE_FAVOURITE_START",
  REMOVE_FAVOURITE_SUCCESS: "REMOVE_FAVOURITE_SUCCESS",
  REMOVE_FAVOURITE_FAILURE: "REMOVE_FAVOURITE_FAILURE",
  UPDATE_FAVOURITE_START: "UPDATE_FAVOURITE_START",
  UPDATE_FAVOURITE_SUCCESS: "UPDATE_FAVOURITE_SUCCESS",
  UPDATE_FAVOURITE_FAILURE: "UPDATE_FAVOURITE_FAILURE",
  ADD_SEARCH_START: "ADD_SEARCH_START",
  ADD_SEARCH_SUCCESS: "ADD_SEARCH_SUCCESS",
  ADD_SEARCH_FAILURE: "ADD_SEARCH_FAILURE",
  REMOVE_SEARCH_START: "REMOVE_SEARCH_START",
  REMOVE_SEARCH_SUCCESS: "REMOVE_SEARCH_SUCCESS",
  REMOVE_SEARCH_FAILURE: "REMOVE_SEARCH_FAILURE",
  UPDATE_SEARCH_START: "UPDATE_SEARCH_START",
  UPDATE_SEARCH_SUCCESS: "UPDATE_SEARCH_SUCCESS",
  UPDATE_SEARCH_FAILURE: "UPDATE_SEARCH_FAILURE",
  CLEAR_CLIENT: "CLEAR_CLIENT",
};

export default ClientActionTypes;
