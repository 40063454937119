import { useDispatch } from "react-redux";
import { IonIcon, IonButton, IonRow, IonCol } from "@ionic/react";
import GoogleIcon from "../../../../../assets/svg/social/google.svg";
import AppleIcon from "../../../../../assets/svg/social/apple.svg";
import FacebookIcon from "../../../../../assets/svg/social/facebook.svg";
import { federatedSignInStart } from "../../../../../redux/user/user.actions";
import styles from "./federated-buttons.module.scss";

const FederatedButtons = ({ option }) => {
  const dispatch = useDispatch();

  return (
    <>
      <IonRow>
        <IonCol>
          <IonButton
            className={styles.buttons}
            expand="block"
            shape="round"
            onClick={() => {
              dispatch(federatedSignInStart("Google"));
            }}
          >
            <IonIcon icon={GoogleIcon} slot="start" />
            {option === "signup" ? "Sign up " : "Sign in "}with Google
          </IonButton>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonButton
            className={styles.buttons}
            expand="block"
            shape="round"
            onClick={() => {
              dispatch(federatedSignInStart("SignInWithApple"));
            }}
          >
            <IonIcon icon={AppleIcon} slot="start" className={styles.apple} />
            {option === "signup" ? "Sign up " : "Sign in "}with Apple
          </IonButton>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonButton
            className={styles.buttons}
            expand="block"
            shape="round"
            onClick={() => {
              dispatch(federatedSignInStart("Facebook"));
            }}
          >
            <IonIcon icon={FacebookIcon} slot="start" />
            {option === "signup" ? "Sign up " : "Sign in "}with Facebook
          </IonButton>
        </IonCol>
      </IonRow>
    </>
  );
};

export default FederatedButtons;
