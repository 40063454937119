import { memo } from "react";
import TabFilters from "../Filters/TabFilters/tab-filters.component";
import styles from "./search-config.module.scss";
import SortBy from "../SortBy/sort-by.component";
import ViewToggle from "../ViewToggle/view-toggle.component";
import Card from "../Card/card.component";
import withFilters from "../../HOC/withFilters/with-filters";

const SearchConfig = ({
  view,
  setView,
  sortBy,
  setSortBy,
  hide,
  tabs,
  filters,
  updateSingleFilter,
  updateMultipleFilters,
}) => {
  return (
    <div
      className={`${styles.config} ${hide && styles.hide} ${
        view === "list" && styles.listView
      }`}
    >
      {view === "list" && (
        <>
          <div className={styles.listViewToggle}>
            <ViewToggle view={view} setView={setView} />
          </div>
          <div className={styles.filters}>
            <Card
              style={{ position: "relative", padding: "1rem" }}
              tabs={tabs}
              selectedTab={filters.type}
              setSelectedTab={(val) => {
                if (filters.status === "A" || val === "Pre-construction") {
                  updateSingleFilter({ type: val.toLowerCase() });
                } else {
                  updateMultipleFilters({
                    type: val,
                    lastStatus: val === "sale" ? "Sld" : "Lsd",
                  });
                }
              }}
            >
              <TabFilters />
            </Card>
          </div>
        </>
      )}
      <div className={styles.sort}>
        <SortBy
          sortBy={sortBy}
          setSortBy={setSortBy}
          mode="search"
          view={view}
        />
        {view === "map" && <ViewToggle view={view} setView={setView} />}
      </div>
    </div>
  );
};

export default memo(withFilters(SearchConfig));
