import withClient from "../../../../HOC/withClient/with-client";
import { useDispatch } from "react-redux";
import moment from "moment";
import { openModal } from "../../../../redux/ui/ui.actions";
import Segment from "../../../Form/Segment/segment.component";

const StatusSegmentFilter = ({
  user,
  filters: { type, status, lastStatus },
  updateMultipleFilters,
  disabled,
}) => {
  const dispatch = useDispatch();
  const saleOptions = [
    {
      title: "sale",
      onClick: () =>
        updateMultipleFilters({
          type: "sale",
          status: "A",
          lastStatus: null,
          displayPublic: "Y",
          displayAddressOnInternet: "Y",
          minListDate: null,
          maxListDate: null,
          minSoldDate: null,
          maxSoldDate: null,
          resultsPerPage: 2500,
          sortBy: "createdOnDesc",
        }),
    },
    {
      title: "sold",
      onClick: () =>
        user
          ? updateMultipleFilters({
              type: "sale",
              status: "U",
              lastStatus: "Sld",
              displayPublic: null,
              displayAddressOnInternet: null,
              minListDate: null,
              maxSoldDate: null,
              minSoldDate: moment().subtract(30, "days").format("YYYY-MM-DD"),
              resultsPerPage: 2500,
              sortBy: "createdOnDesc",
            })
          : dispatch(openModal({ current: "join" })),
    },
    {
      title: "de-listed",
      onClick: () =>
        user
          ? updateMultipleFilters({
              type: "sale",
              status: "U",
              lastStatus: ["Sus", "Exp", "Ter"],
              displayPublic: null,
              displayAddressOnInternet: null,
              minListDate: null,
              maxListDate: null,
              minSoldDate: null,
              maxSoldDate: null,
              resultsPerPage: 250,
              sortBy: "updatedOnDesc",
            })
          : dispatch(openModal({ current: "join" })),
    },
  ];

  const leaseOptions = [
    {
      title: "lease",
      onClick: () =>
        updateMultipleFilters({
          type: "lease",
          status: "A",
          lastStatus: null,
          displayPublic: "Y",
          displayAddressOnInternet: "Y",
          minListDate: null,
          maxListDate: null,
          minSoldDate: null,
          maxSoldDate: null,
          resultsPerPage: 2500,
          sortBy: "createdOnDesc",
        }),
    },
    {
      title: "leased",
      onClick: () =>
        user
          ? updateMultipleFilters({
              type: "lease",
              status: "U",
              lastStatus: "Lsd",
              displayPublic: null,
              displayAddressOnInternet: null,
              minListDate: null,
              maxSoldDate: moment(new Date()).format("YYYY-MM-DD"),
              minSoldDate: moment().subtract(30, "days").format("YYYY-MM-DD"),
              resultsPerPage: 2500,
              sortBy: "createdOnDesc",
            })
          : dispatch(openModal({ current: "join" })),
    },
    {
      title: "de-listed",
      onClick: () =>
        user
          ? updateMultipleFilters({
              type: "lease",
              status: "U",
              lastStatus: ["Sus", "Exp", "Ter"],
              displayPublic: null,
              displayAddressOnInternet: null,
              minListDate: null,
              maxListDate: null,
              minSoldDate: null,
              maxSoldDate: null,
              resultsPerPage: 250,
              sortBy: "updatedOnDesc",
            })
          : dispatch(openModal({ current: "join" })),
    },
  ];

  const checkSelected = () => {
    if (status === "A") return type;
    if (type === "sale" && lastStatus === "Sld") return "sold";
    if (type === "lease" && lastStatus === "Lsd") return "leased";
    return "de-listed";
  };
  return (
    <Segment
      segments={type === "sale" ? saleOptions : leaseOptions}
      selected={checkSelected()}
      disabled={disabled}
    />
  );
};

export default withClient(StatusSegmentFilter);
