import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { IonIcon, IonSpinner } from "@ionic/react";
import notificationIcon from "../../../../assets/svg/notifications.svg";
import Button from "../../../../components/Form/Button/button.component";
import styles from "./saved-search-content.module.scss";
import {
  ellipsisHorizontalOutline,
  schoolOutline,
  trashOutline,
} from "ionicons/icons";
import { openModal, updateDrawMode } from "../../../../redux/ui/ui.actions";
import moment from "moment";
import { render } from "./utils";
import withFilters from "../../../../HOC/withFilters/with-filters";
import Toggle from "../../../../components/Form/Toggle/toggle-component";
import withMap from "../../../../HOC/withMap/with-map";
import { getSearchCount } from "../../../../api/repliers";
import EllipsisText from "react-ellipsis-text";
import { updateSavedSearch } from "../../../../amplify/graphql.utils";

const SavedSearchContent = ({
  search,
  setIsUpdated,
  updateMultipleFilters,
  updateExtraFilters,
  updateMapLocations,
}) => {
  const [total, setTotal] = useState();
  const dispatch = useDispatch();
  const history = useHistory();
  const { filters, extra, schoolMode, schoolName, schoolId, schools } =
    JSON.parse(search.value);
  const { name } = search;
  const [loading, setLoading] = useState(false);

  const deleteHandler = async () => {
    dispatch(
      openModal({
        current: "deleteSavedSearch",
        data: { search, setIsUpdated },
      })
    );
  };

  const editHandler = () => {
    dispatch(
      openModal({
        current: "editSavedSearch",
        data: { search, setIsUpdated },
      })
    );
  };

  const updateHandler = async () => {
    setLoading(true);
    await updateSavedSearch({
      id: search.id,
      notification: !search.notification,
      name: search.name,
    });
    setIsUpdated(true);
    setLoading(false);
  };

  const loadHandler = () => {
    const { filters, extra, locations, drawMode, schoolMode } = JSON.parse(
      search.value
    );
    let days;
    if (filters.minSoldDate || filters.minListDate) {
      days = moment(new Date()).diff(
        moment(filters.minListDate ? filters.minListDate : filters.minSoldDate),
        "days"
      );
    }
    if (days) {
      if (filters.minListDate) {
        filters.minListDate = moment()
          .subtract(days, "days")
          .format("YYYY-MM-DD");
      }
      if (filters.minSoldDate) {
        filters.minSoldDate = moment()
          .subtract(days, "days")
          .format("YYYY-MM-DD");
      }
    }

    dispatch(
      updateDrawMode({ active: drawMode || schoolMode, isSchool: schoolMode })
    );

    updateMapLocations({ ...locations, load: true });
    updateMultipleFilters({ ...filters });
    updateExtraFilters({ ...extra });
    history.push("/listings");
  };

  const renderFilters = () => {
    let date;
    if (filters.minSoldDate || filters.minListDate) {
      date = moment(new Date()).diff(
        moment(filters.minListDate ? filters.minListDate : filters.minSoldDate),
        "days"
      );
    }
    let text = render(filters, extra);
    if (date) text = `Last ${date}d, ` + text;

    return (
      <p className={styles.filter}>
        {/* {total && <span className={styles.total}>{`${total} results`}</span>} */}
        <EllipsisText
          text={text}
          length={window.innerWidth <= 1200 ? 50 : 75}
        />
      </p>
    );
  };

  // useEffect(() => {
  //   const getCount = async () => {
  //     const listings = await getSearchCount({ filters, extra });
  //     setTotal(listings.length);
  //   };

  //   getCount();
  // }, [extra, filters]);

  return (
    <div className={styles.content}>
      <div className={`${styles.nameContainer} ${styles.column}`}>
        <span className={styles.loadBtn}>
          <Button
            shape="block"
            title="View on map"
            type="purple"
            border={true}
            style={{
              width: "100%",
              minWidth: "10rem",
              height: "100%",
              padding: "1rem",
              lineHeight: "1.5rem",
            }}
            onClick={loadHandler}
          />
        </span>
        <span className={styles.name}>
          <EllipsisText text={name} length={30} />
        </span>
      </div>
      <div className={`${styles.filters} ${styles.column}`}>
        <span className={styles.text}>{renderFilters()}</span>
      </div>
      <div className={`${styles.schools} ${styles.column}`}>
        {schoolMode ? (
          <div className={styles.school}>
            <div className={styles.tag}>
              <IonIcon icon={schoolOutline} />
              School
            </div>
            <div className={styles.name}>
              <EllipsisText
                text={
                  schools
                    ? schools.length > 1
                      ? "Multiple schools"
                      : schools[0].name
                    : schoolName
                }
                length={25}
                className={schoolId && styles.underscore}
                onClick={() =>
                  schools
                    ? schools.length === 1
                      ? history.push(`/schools/${schools[0].id}`)
                      : null
                    : schoolId && history.push(`/schools/${schoolId}`)
                }
              />
            </div>
          </div>
        ) : (
          <div className={`${styles.school} ${styles.noSchool}`}>
            <div className={styles.tag}>
              <IonIcon icon={schoolOutline} />
              School
            </div>
            <div className={styles.name}>No school selected</div>
          </div>
        )}
      </div>
      <div className={`${styles.notifications} ${styles.column}`}>
        <div className={styles.notification} onClick={updateHandler}>
          <div className={styles.notificationIcon}>
            <IonIcon
              src={notificationIcon}
              className={search.notification ? styles.active : styles.inactive}
            />
            <span className={styles.label}>Alerts</span>
          </div>

          <span className={styles.toggle}>
            <Toggle active={search.notification} onToggle={updateHandler} />
          </span>
        </div>
      </div>

      <div className={`${styles.icons} ${styles.column}`}>
        {loading ? (
          <IonSpinner
            color="#7158ad"
            className={styles.loader}
            name="crescent"
          />
        ) : (
          <>
            <div className={styles.edit} onClick={editHandler}>
              <IonIcon src={ellipsisHorizontalOutline} />
            </div>
            <div className={styles.delete} onClick={deleteHandler}>
              <IonIcon icon={trashOutline} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default withMap(withFilters(SavedSearchContent));
