import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { closeModal, openAuthModal } from "../../../../redux/ui/ui.actions";
import Card from "../../../../components/Card/card.component";
import ListingCard from "../../../../components/ListingCard/listing-card.component";
// import Carousel from "react-elastic-carousel";
import styles from "./comparables-section.module.scss";
import BlurCard from "./BlurCard/blur-card.component";
import Slider from "../../../../components/Slider/slider.component";

const ComparableSection = ({ user, activeComparables, soldComparables }) => {
  const dispatch = useDispatch();

  const signInHandler = () => {
    dispatch(openAuthModal({ current: "login" }));
  };
  const signUpHandler = () => {
    dispatch(openAuthModal({ current: "register" }));
  };

  useEffect(() => {
    user && dispatch(closeModal());
  }, [user, dispatch]);

  const breakPoints = [
    { width: 750, itemsToShow: 2.5 },
    { width: 800, itemsToShow: 3 },
    { width: 1024, itemsToShow: 3.5 },
    { width: 1700, itemsToShow: 5 },
  ];

  return (
    <>
      {activeComparables && activeComparables.length > 0 && (
        <div className={styles.comparables}>
          <div className={styles.title}>Similar active listings</div>
          <Slider>
            {activeComparables.map((listing) => (
              <Card
                animated={true}
                key={listing.mlsNumber}
                style={{
                  margin: "0 2rem 2.7rem 2rem",
                  height: "50rem",
                  width: "40rem",
                }}
              >
                <ListingCard
                  listing={listing}
                  style={{
                    height: "44rem",
                    width: activeComparables.length < 3 ? "100%" : "32rem",
                  }}
                  isActiveComparables={true}
                />
              </Card>
            ))}
          </Slider>
        </div>
      )}
      {soldComparables && soldComparables.length > 0 && (
        <div className={styles.comparables}>
          <div className={styles.title}>Similar sold listings</div>
          <Slider>
            {soldComparables.map((listing) => (
              <Card
                animated={true}
                key={listing.mlsNumber}
                style={{
                  margin: "0 2rem 2.7rem 2rem",
                  height: "50rem",
                  width: "40rem",
                }}
              >
                {user ? (
                  <ListingCard
                    listing={listing}
                    style={{
                      height: "44rem",
                      width: soldComparables.length < 3 ? "100%" : "32rem",
                    }}
                  />
                ) : (
                  <BlurCard
                    images={listing.images}
                    signInHandler={signInHandler}
                    signUpHandler={signUpHandler}
                  />
                )}
              </Card>
            ))}
          </Slider>
        </div>
      )}
    </>
  );
};

export default ComparableSection;
