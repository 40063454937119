import Marker from "../Marker/marker.component";
import styles from "./clusters.module.scss";
import { getPrice } from "../../../../utils/functions";

export const renderClusters = ({
  client,
  rates,
  clusters,
  supercluster,
  map,
  points,
  selectedLocation,
  activeListing,
  selectLocation,
  isSatellite,
  containerRef: { current: container },
  setSchool,
  isApp,
}) => {
  if (!supercluster) return null;
  let currency;
  if (client && rates) {
    if (client.currency !== "cad") currency = client.currency.toUpperCase();
  }

  const markers = clusters.map((cluster) => {
    const [longitude, latitude] = cluster.geometry.coordinates;
    const { cluster: isCluster, point_count: pointCount } = cluster.properties;
    if (isCluster) {
      let currentPoints;
      let isSeveral;
      try {
        isSeveral =
          Math.min(supercluster.getClusterExpansionZoom(cluster.id), 20) === 20;
        currentPoints = supercluster.getLeaves(cluster.id, Infinity, 0);
      } catch (err) {}
      if (!currentPoints) return null;
      let isHovered = false;
      let isActive = false;
      if (activeListing) {
        for (let i = 0; i < currentPoints.length; i++) {
          if (currentPoints[i].properties.listingId === activeListing.mls) {
            isActive = true;
            break;
          }
          if (currentPoints[i].properties.listingId === activeListing) {
            isHovered = true;
            break;
          }
        }
      }

      return (
        <Marker key={cluster.id} lat={latitude} lng={longitude}>
          <div
            className={`${styles.clusterMarker} ${
              isApp && styles.animatedClusterMarker
            } ${isSatellite ? styles.clusterMarkerLight : ""} ${
              (selectedLocation &&
                selectedLocation.coords.lat === latitude &&
                selectedLocation.coords.lng === longitude) ||
              isHovered
                ? isSatellite
                  ? styles.selectedClusterLight
                  : styles.selectedCluster
                : ""
            } ${isHovered && styles.hovered} ${
              isActive && styles.activeCluster
            }`}
            style={{
              width: `${5 + (pointCount / points.length) * 100}px`,
              height: `${5 + (pointCount / points.length) * 100}px`,
            }}
            onClick={(e) => {
              setSchool(null);
              const expansionZoom = Math.min(
                supercluster.getClusterExpansionZoom(cluster.id),
                20
              );

              if (isSeveral || pointCount <= 10) {
                selectLocation({
                  id: cluster.id,
                  points: currentPoints,
                  coords: { lat: latitude, lng: longitude },
                });
              } else {
                map.setZoom(expansionZoom);
                map.panTo({
                  lat: latitude,
                  lng: longitude,
                });
              }
              if (!isApp) {
                const mapBounds = container.getBoundingClientRect();
                const mapXStart = mapBounds.left;
                const mapXEnd = mapBounds.right;
                const mapYStart = mapBounds.top;
                const mapYEnd = mapBounds.bottom;

                // if (
                //   e.clientX + 150 > mapXEnd ||
                //   e.clientX < mapXStart + 200 ||
                //   e.clientY + 150 > mapYEnd
                // ) {
                //   map.panTo({
                //     lat: latitude,
                //     lng: longitude,
                //   });
                // }
              }
            }}
          >
            {pointCount}
          </div>
        </Marker>
      );
    } else {
      let isHovered = false;
      let isActive = false;

      if (activeListing && cluster.properties.listingId === activeListing.mls) {
        isActive = true;
      }
      if (activeListing && cluster.properties.listingId === activeListing) {
        isHovered = true;
      }

      return (
        <Marker
          key={cluster.properties.listingId}
          lat={latitude}
          lng={longitude}
        >
          <div
            className={`${styles.marker} ${isApp && styles.animatedMarker} ${
              isSatellite && !isActive ? styles.markerLight : ""
            } ${
              (selectedLocation &&
                selectedLocation.coords.lat === latitude &&
                selectedLocation.coords.lng === longitude) ||
              isHovered
                ? isSatellite
                  ? styles.selectedMarkerLight
                  : styles.selectedMarker
                : ""
            } ${isHovered && styles.hovered} ${
              getPrice(
                +cluster.properties.listingPrice,
                cluster.properties.category,
                currency,
                rates
              ).length > 5 && styles.wideMarker
            } ${isActive && styles.activeMarker}`}
            onClick={(e) => {
              setSchool(null);

              if (!isApp) {
                const mapBounds = container.getBoundingClientRect();
                const mapXStart = mapBounds.left;
                const mapXEnd = mapBounds.right;
                const mapYStart = mapBounds.top;
                const mapYEnd = mapBounds.bottom;

                // if (
                //   e.clientX + 150 > mapXEnd ||
                //   e.clientX < mapXStart + 200 ||
                //   e.clientY + 150 > mapYEnd
                // ) {
                //   map.panTo({
                //     lat: latitude,
                //     lng: longitude,
                //   });
                // }
              }

              selectLocation({
                id: cluster.id,
                points: [cluster],
                coords: { lat: latitude, lng: longitude },
              });
            }}
          >
            <span className={styles.price}>
              {getPrice(
                +cluster.properties.listingPrice,
                cluster.properties.category,
                currency,
                rates
              )}
            </span>
            <span className={styles.chevron} />
          </div>
        </Marker>
      );
    }
  });

  return markers;
};
