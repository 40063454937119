import { useState, useEffect } from "react";
import {
  IonItem,
  IonList,
  IonRadioGroup,
  IonLabel,
  IonRadio,
} from "@ionic/react";
import withFilters from "../../../../../HOC/withFilters/with-filters";
import styles from "./purpose-filter.module.scss";

const PurposeFilter = ({ filters: { class: types }, updateSingleFilter }) => {
  const [selected, setSelected] = useState(
    types.includes("residential") ? "residential" : "commercial"
  );

  useEffect(() => {
    updateSingleFilter({
      class:
        selected === "residential" ? ["residential", "condo"] : ["commercial"],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);
  return (
    <IonList>
      <IonRadioGroup
        value={selected}
        onIonChange={(e) => setSelected(e.detail.value)}
      >
        <IonItem lines="none">
          <IonLabel
            className={`${styles.label} ${
              selected === "residential" && styles.selected
            }`}
          >
            Residential
          </IonLabel>
          <IonRadio slot="end" value="residential" className={styles.radio} />
        </IonItem>
        <IonItem lines="none">
          <IonLabel
            className={`${styles.label} ${
              selected === "commercial" && styles.selected
            }`}
          >
            Commercial
          </IonLabel>
          <IonRadio slot="end" value="commercial" className={styles.radio} />
        </IonItem>
      </IonRadioGroup>
    </IonList>
  );
};

export default withFilters(PurposeFilter);
