import React, { useEffect, useState, createContext, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonRouterOutlet,
  IonToast,
  IonModal,
  IonIcon,
  IonAlert,
} from "@ionic/react";
import { App as CapApp } from "@capacitor/app";
import { AppVersion } from "@ionic-native/app-version";
import { Market } from "@ionic-native/market";
import { IonReactRouter } from "@ionic/react-router";
import "./App.scss";
import { API, graphqlOperation } from "aws-amplify";
import { LastLocationProvider } from "react-router-last-location";
import "@ionic/react/css/core.css";
import { fetchLocationsStart } from "./redux/locations/locations.actions";
import { selectLocationsValue } from "./redux/locations/locations.selectors";
import { checkUserSession, getGuestStart } from "./redux/user/user.actions";
import SearchPage from "./pages/Search/search.component";
import Layout from "./pages/Layout/layout.component";
import Home from "./pages/Home/home.component";
import FavouriteListings from "./pages/Favourites/favorite-listings.component";
import SavedSearches from "./pages/SavedSearch/saved-searches-listings.component";
import Logout from "./components/Authentication/Logout/logout-component";
import UserAccount from "./pages/User/user-account.component";
import DetailPage from "./pages/Detail/detail.component";
import { fetchCurrencyStart } from "./redux/currency/currency.actions";
import {
  isAndroid,
  isApp as checkPlatform,
  isCapacitor,
} from "./utils/functions";
import AppFiltersPage from "./app/pages/Filters/filters.component";
import AppSearchesPage from "./app/pages/Searches/searches.component";
import styles from "./App.module.scss";
import AppDetailsPage from "./app/pages/Details/details.component";
import { selectCurrentUser, selectIsAgent } from "./redux/user/user.selectors";
import Join from "./app/components/CustomModals/Join/join.component";
import { selectUIToast } from "./redux/ui/ui.selectors";
import SearchDetails from "./app/pages/Searches/pages/search-details.component";
import Tours from "./pages/Tours/tours.component";
import Tour from "./pages/Tour/tour.component";
import TourDetails from "./pages/TourDetails/tour-details.component";
import ClientTours from "./pages/ClientTours/client-tours.component";
import ClientTourDetails from "./pages/ClientTourDetails/client-tour-details";
import AppAgentTour from "./app/pages/AgentTour/agent-tour.component";
import AppAgentTourDetails from "./app/pages/AgentTourDetails/agent-tour-details.component";
import AppClientTourDetails from "./app/pages/ClientTourDetails/client-tour-details.component";
import { updateToast, updateChangedFavourite } from "./redux/ui/ui.actions";
import AppClientTour from "./app/pages/ClientTour/client-tour.component";
import ToursRoute from "./routes/ToursRoute";
import AppUrlListener from "./utils/appUrlListener";
import { selectCurrentClient } from "./redux/client/client.selectors";
import ConfirmRegistration from "./app/components/Authentication/CustomAuthContainer/components/ConfirmRegistration/confirm-registration.component";
import AppTermsOfService from "./app//pages/TermsOfService/terms-of-service.component";
import AppPrivacyPolicy from "./app/pages/PrivacyPolicy/privacy-policy.component";
import TermsOfService from "./pages/Terms/TermsofService/terms-of-service.component";
import PrivacyPolicy from "./pages/Terms/PrivacyPolicy/privacy-policy.component";
import { clearClient } from "./redux/client/client.actions";
import { ScreenOrientation } from "@ionic-native/screen-orientation";
import { StatusBar } from "@ionic-native/status-bar";
import {
  PushNotifications,
  PushNotificationSchema,
  ActionPerformed,
  Token,
} from "@capacitor/push-notifications";
import { updateClient } from "./amplify/graphql.utils";
import AppSignIn from "./app/pages/Signin/sign-in.component";
import AppSignUp from "./app/pages/Signup/sign-up.component";
import AppContactUs from "./app/pages/ContactUs/contact-us.component";
import AppFeedback from "./app/pages/Feedback/feedback.component";
import ContactUs from "./pages/MeetEttie/ContactUs/contact-us.component";
import Feedback from "./pages/MeetEttie/Feedback/feedback.component";
import AppAboutUs from "./app/pages/AboutUs/about-us.component";
import AboutUs from "./pages/MeetEttie/AboutUs/about-us.components";
import { App as CapacitorApp } from "@capacitor/app";
import AppMarketData from "./app/pages/MarketData/market-data.component";
import AppHireAgent from "./app/pages/HireAgent/hire-agent.component";
import AppTabs from "./routes/Tabs";
import AppSchoolDetails from "./app/pages/SchoolDetails/school-details.component";
import SchoolDetails from "./pages/SchoolDetails/school-details.component";
import { getPlatforms } from "@ionic/core";
import ettieIcon from "./assets/icon/browsers/ettie.png";
import { browsersOutline } from "ionicons/icons";
import { Helmet } from "react-helmet";
import MarketData from "./pages/MeetEttie/MarketData/market-data.component";
import { storeIds, storeUrls } from "./utils/constants";
import { onFavouriteUpdateByClientId } from "./graphql/subscriptions";
import SchoolDisclaimer from "./pages/SchoolDisclaimer/school-disclaimer.component";
import AppSchoolDisclaimer from "./app/pages/SchoolDisclaimer/school-disclaimer.component";

export const PlatformContext = createContext(false);

const App: React.FC = () => {
  const dispatch = useDispatch();
  const [isApp, setIsApp] = useState(false);
  const [showConfirmRegistration, setShowConfirmRegistration] = useState(false);
  const [showDownloadApp, setShowDownloadApp] = useState({
    show: false,
    os: "",
  });
  const handleUpdate = async () => {
    await Market.open(
      getPlatforms().includes("ios") ? storeIds.apple : storeIds.android
    );
  };
  const [update, setUpdate] = useState({
    isAvailable: false,
    type: "",
    value: {
      title: "",
      msg: "",
      btn: "",
      onClick: handleUpdate,
      forced: false,
    },
  });
  const locations = useSelector(selectLocationsValue);
  const user = useSelector(selectCurrentUser);
  const isAgent = useSelector(selectIsAgent);
  const client = useSelector(selectCurrentClient);
  const toast = useSelector(selectUIToast);
  const closeToast = () => {
    dispatch(
      updateToast({
        open: false,
        type: undefined,
        message: "",
        header: undefined,
        position: undefined,
      })
    );
  };

  const resizeListener = useCallback(() => {
    if (window.innerWidth < 960) setIsApp(true);
    if (
      window.innerWidth >= 960 &&
      !getPlatforms().includes("capacitor") &&
      isApp
    ) {
      setIsApp(false);
    }
  }, [isApp]);

  useEffect(() => {
    if (!locations) dispatch(fetchLocationsStart());
  }, [dispatch, locations]);

  useEffect(() => {
    const checkAppUpdates = async () => {
      if (getPlatforms().includes("capacitor")) {
        try {
          const { updates } = await API.get("ettieREST", "/checkupdates", "");
          const {
            majorMsg,
            minorMsg,
            enabled,
            current,
            mode,
            latestStableVersion,
          } = updates;

          if (enabled && !enabled.BOOL) {
            setUpdate({
              isAvailable: true,
              type: "maintenance",
              value: {
                title: "App Maintenance",
                msg: "We are currently improving your app experience. Please try again later...",
                btn: getPlatforms().includes("android") ? "Exit" : "Try again",
                onClick: async () =>
                  getPlatforms().includes("android")
                    ? CapApp.exitApp()
                    : await checkConnection(),
                forced: true,
              },
            });
            return;
          }

          const version = await AppVersion.getVersionNumber();

          const splittedVersion = version.split(".");
          const intVersion = +version.split(".").join("");

          const serverVersion = current.S.split(".");
          const stableIntVersion = +latestStableVersion.S.split(".").join("");

          const showMajorMsg = () =>
            setUpdate({
              isAvailable: true,
              type: "major",
              value: {
                title: majorMsg.M.title.S,
                msg: majorMsg.M.msg.S,
                btn: majorMsg.M.btn.S,
                onClick: handleUpdate,
                forced: true,
              },
            });

          const showMinorMsg = () =>
            setUpdate({
              isAvailable: true,
              type: "minor",
              value: {
                title: minorMsg.M.title.S,
                msg: minorMsg.M.msg.S,
                btn: minorMsg.M.btn.S,
                onClick: handleUpdate,
                forced: false,
              },
            });

          if (stableIntVersion > intVersion) {
            showMajorMsg();
            return;
          }

          switch (mode.S) {
            case "major":
              if (+splittedVersion[0] < +serverVersion[0]) {
                showMajorMsg();
                return;
              }
              if (
                (+splittedVersion[0] === +serverVersion[0] &&
                  +splittedVersion[1] < +serverVersion[1]) ||
                (+splittedVersion[0] === +serverVersion[0] &&
                  +splittedVersion[1] === +serverVersion[1] &&
                  +splittedVersion[2] < +serverVersion[2])
              ) {
                showMinorMsg();
                return;
              }

              return;
            case "minor":
              if (
                (+splittedVersion[0] === +serverVersion[0] &&
                  +splittedVersion[1] < +serverVersion[1]) ||
                +splittedVersion[0] < +serverVersion[0]
              ) {
                showMajorMsg();
                return;
              }
              if (
                +splittedVersion[0] === +serverVersion[0] &&
                +splittedVersion[1] === +serverVersion[1] &&
                +splittedVersion[2] < +serverVersion[2]
              ) {
                showMinorMsg();
                return;
              }
              return;
            case "patch":
              if (
                (+splittedVersion[0] === +serverVersion[0] &&
                  +splittedVersion[1] === +serverVersion[1] &&
                  +splittedVersion[2] < +serverVersion[2]) ||
                (+splittedVersion[0] === +serverVersion[0] &&
                  +splittedVersion[1] < +serverVersion[1]) ||
                +splittedVersion[0] < +serverVersion[0]
              ) {
                showMajorMsg();
                return;
              }
              return;
            default:
              return;
          }
        } catch (err) {
          setUpdate({
            isAvailable: true,
            type: "disconnected",
            value: {
              title: "No Internet Connection",
              msg: "To continue using Ettie, make sure you are connected to the Internet.",
              btn: "Try again",
              onClick: checkConnection,
              forced: true,
            },
          });
        }
      }
    };

    const checkConnection = async () => {
      setTimeout(async () => {
        await checkAppUpdates();
      }, 1000);
    };
    CapApp.addListener("appStateChange", (state) => {
      if (state.isActive) checkAppUpdates();
    });
    checkAppUpdates();
    dispatch(getGuestStart());
    dispatch(fetchCurrencyStart());
    dispatch(checkUserSession(true));
  }, [dispatch]);

  useEffect(() => {
    const registerPush = () => {
      PushNotifications.checkPermissions().then((permission) => {
        if (permission.receive !== "granted") {
          PushNotifications.requestPermissions().then((permission) => {
            if (permission.receive === "granted") {
              PushNotifications.register();
            } else {
              // No permission granted
            }
          });
        } else {
          PushNotifications.register();
        }
      });

      PushNotifications.addListener("registration", async (token: Token) => {
        if (user) {
          updateClient({
            id: user.username,
            updates: { deviceToken: token.value },
          });
        }
      });

      PushNotifications.addListener("registrationError", (error: any) => {
        console.log("Error: " + JSON.stringify(error));
      });

      PushNotifications.addListener(
        "pushNotificationReceived",
        async (notification: PushNotificationSchema) => {
          user &&
            dispatch(
              updateToast({
                open: true,
                type: "notification",
                message: notification.body,
                act: notification.data.id
                  ? () =>
                      (window.location.href = `/${notification.data.category}/${notification.data.id}`)
                  : notification.data.category
                  ? () =>
                      (window.location.href = `/${notification.data.category}`)
                  : undefined,
              })
            );
        }
      );

      PushNotifications.addListener(
        "pushNotificationActionPerformed",
        async (notification: ActionPerformed) => {
          const data = notification.notification.data;

          if (data.id) {
            window.location.href = `/${data.category}/${data.id}`;
          } else if (data.caterogy) {
            window.location.href = `/${data.category}`;
          }
        }
      );
    };
    if (checkPlatform()) {
      setIsApp(true);
    }

    if (isCapacitor()) {
      ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT_PRIMARY);
      if (isAndroid()) {
        StatusBar.overlaysWebView(false);
        StatusBar.show();
      }
      if (user) {
        registerPush();
      } else {
        PushNotifications.removeAllListeners();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, user]);

  useEffect(() => {
    window.addEventListener("resize", resizeListener);
    return () => window.removeEventListener("resize", resizeListener);
  }, [resizeListener]);

  useEffect(() => {
    if (
      isApp &&
      client &&
      !client.profile &&
      user &&
      !user.signInUserSession.accessToken.payload["cognito:groups"].includes(
        "Agents"
      )
    ) {
      setShowConfirmRegistration(true);
    }
  }, [client, isApp, user]);

  useEffect(() => {
    if (!user) {
      dispatch(clearClient());
    }
  }, [dispatch, user]);

  useEffect(() => {
    CapacitorApp.addListener("backButton", (e) => {
      const confirmRegistrationModal = document.getElementById(
        "confirmRegistration"
      );

      if (confirmRegistrationModal) {
        setShowConfirmRegistration(true);
      }

      if (
        window.location.pathname === "/home" ||
        window.location.pathname === "/listings"
      )
        CapacitorApp.exitApp();
    });
  }, []);

  useEffect(() => {
    if (getPlatforms().includes("mobileweb")) {
      const os = getPlatforms().includes("ios")
        ? "ios"
        : getPlatforms().includes("android")
        ? "android"
        : null;

      if (os === "ios" || os === "android") {
        setShowDownloadApp({
          show: true,
          os: os,
        });
      }
    }
  }, []);

  const handleOpenApp = (os: String) => {
    if (os === "android") {
      window.location.href = storeUrls.android;
    }
    if (os === "ios") {
      window.location.href = storeUrls.apple;
    }
  };

  ///CHECK FOR FAVOURITE UPDATES
  useEffect(() => {
    let subscription: { unsubscribe: () => any };
    const subscribe = async () => {
      subscription = await (
        API.graphql(
          graphqlOperation(onFavouriteUpdateByClientId, { clientId: client.id })
        ) as any
      ).subscribe({
        next: async (data: any) => {
          const item = data.value.data.onFavouriteUpdateByClientId;
          dispatch(updateChangedFavourite(item.mlsNumber));
        },
      });
    };
    if (client) subscribe();

    return () => subscription && subscription.unsubscribe();
  }, [client, dispatch]);

  return (
    <IonApp className={isApp ? styles.app : styles.web}>
      <IonReactRouter>
        <Helmet>
          <title>Ettie - Properties in GTA</title>
          <meta
            name="description"
            content="Find real estate listings for sale in GTA. Search MLS real estate listings for homes, condos and properties."
          />
          <meta
            name="keywords"
            content="mls listings, house for sale, mls canada, homes for rent, mls map, mls real estate, homes for sale, condos for sale, homes for sale near me, townhouse for sale, property for sale, apartments for sale, property search, mls online, house prices, duplex for sale, real estate listings, real estate companies, cheap houses for sale, mls house listings, best real estate websites canada, single family homes for sale, mls residential map, detached house for sale, home listings, sold home prices, property listings, sold mls listings, house sale prices"
          />
        </Helmet>
        <IonAlert
          isOpen={update.isAvailable}
          header={update.value.title}
          message={update.value.msg}
          backdropDismiss={false}
          onDidDismiss={() =>
            setUpdate({
              isAvailable: false,
              type: "",
              value: {
                btn: "",
                title: "",
                forced: false,
                msg: "",
                onClick: handleUpdate,
              },
            })
          }
          buttons={
            update.value.forced
              ? [{ text: update.value.btn, handler: update.value.onClick }]
              : [
                  { text: update.value.btn, handler: update.value.onClick },
                  { text: "Close", role: "cancel" },
                ]
          }
        />
        <AppUrlListener />
        <IonModal
          id="confirmRegistration"
          isOpen={showConfirmRegistration}
          onDidDismiss={() => setShowConfirmRegistration(false)}
        >
          <ConfirmRegistration
            client={client}
            confirmRegistration={showConfirmRegistration}
            setConfirmRegistration={setShowConfirmRegistration}
            close={() => setShowConfirmRegistration(false)}
          />
        </IonModal>
        <IonToast isOpen={true} cssClass="connection" />
        <IonToast
          position={toast.position ? toast.position : "top"}
          cssClass={styles[toast.type]}
          isOpen={toast.open}
          onDidDismiss={closeToast}
          header={toast.header}
          message={toast.message}
          buttons={[
            {
              side: "end",
              icon: toast.act && "chevron-forward",
              handler: toast.act && toast.act,
            },
            {
              side: "end",
              icon: "close",
              handler: closeToast,
            },
          ]}
          duration={4000}
        />

        {isApp ? (
          <>
            {showDownloadApp.show && (
              <>
                <div className={styles.bottomCard}>
                  <div className={styles.header}>See Ettie in...</div>
                  <div className={styles.content}>
                    <div className={styles.item}>
                      <div className={styles.logo}>
                        <span className={styles.appLogo}>
                          <img
                            alt="Open app"
                            className={styles.image}
                            src={ettieIcon}
                          />
                        </span>
                        <span className={styles.title}>Ettie</span>
                      </div>

                      <span
                        className={`${styles.label} ${styles.appLabel}`}
                        onClick={() => handleOpenApp(showDownloadApp.os)}
                      >
                        Download Ettie
                      </span>
                    </div>
                    <div className={styles.item}>
                      <div className={styles.logo}>
                        <span className={styles.browserLogo}>
                          <IonIcon
                            icon={browsersOutline}
                            className={styles.icon}
                          />
                        </span>
                        <span className={styles.title}>Browser</span>
                      </div>
                      <span
                        className={`${styles.label} ${styles.browserLabel}`}
                        onClick={() =>
                          setShowDownloadApp({
                            ...showDownloadApp,
                            show: false,
                          })
                        }
                      >
                        Continue
                      </span>
                    </div>
                  </div>
                </div>
                <div className={styles.backdrop} />
              </>
            )}

            <IonRouterOutlet id="main" animated>
              <Route path="/terms" component={AppTermsOfService} exact />
              <Route path="/privacy" component={AppPrivacyPolicy} exact />
              <Route path="/contact-us" component={AppContactUs} />
              <Route path="/feedback" component={AppFeedback} />
              <Route path="/about-us" component={AppAboutUs} />
              <Route path="/market-data" component={AppMarketData} />
              <Route path="/school-terms" component={AppSchoolDisclaimer} />
              <Route path="/hiring-agent" component={AppHireAgent} />
              <Route path="/join" component={Join} exact />
              <Route path="/signup" component={AppSignUp} />
              <Route path="/signin" component={AppSignIn} />
              <Route path="/listings/:id" component={AppDetailsPage} exact />
              <Route
                path="/schools/:id"
                render={(props) => <AppSchoolDetails isApp {...props} />}
                exact
              />
              <Route
                path="/searches"
                render={() => (user ? <AppSearchesPage /> : <Join />)}
                exact
              />
              <Route
                path="/searches/:id"
                render={(props) =>
                  user ? (
                    <SearchDetails {...props} />
                  ) : (
                    <Redirect to="/searches" />
                  )
                }
              />
              <Route
                exact
                path="/agentTours/:tourId"
                render={(props) =>
                  user && isAgent ? (
                    <AppAgentTour {...props} />
                  ) : (
                    <Redirect to="/" />
                  )
                }
              />
              <Route
                exact
                path="/clientTours/:tourId"
                render={(props) =>
                  user ? (
                    !isAgent ? (
                      <AppClientTour {...props} />
                    ) : (
                      <Redirect to="/" />
                    )
                  ) : (
                    <Redirect to="/" />
                  )
                }
              />

              <Route
                exact
                path="/agentTours/:tourId/:tourItemId"
                render={(props) =>
                  user && isAgent ? (
                    <AppAgentTourDetails {...props} />
                  ) : (
                    <Redirect to="/" />
                  )
                }
              />
              <Route
                exact
                path="/clientTours/:tourId/:tourItemId"
                render={(props) =>
                  user ? (
                    !isAgent ? (
                      <AppClientTourDetails {...props} />
                    ) : (
                      <Redirect to="/" />
                    )
                  ) : (
                    <Join />
                  )
                }
              />
              <Route path="/filters" component={AppFiltersPage} />
              <Route path="/tabs" component={AppTabs} />
              <Route
                path="/listings"
                render={() => <Redirect to="/tabs/listings" />}
                exact={true}
              />
              <Route
                path="/favourites"
                render={() => <Redirect to="/tabs/favourites" />}
                exact={true}
              />
              <Route
                path="/clienttours"
                render={() => <Redirect to="/tabs/clienttours" />}
                exact={true}
              />
              <Route
                path="/agenttours"
                render={() => <Redirect to="/tabs/agenttours" />}
                exact={true}
              />
              <Route
                path="/tours"
                render={() => (
                  <Redirect
                    to={isAgent ? "/tabs/agenttours" : "/tabs/clienttours"}
                  />
                )}
                exact={true}
              />
              <Route
                path="/more"
                render={() => <Redirect to="/tabs/more" />}
                exact={true}
              />
              <Route
                path="/"
                render={() => <Redirect to="/tabs/home" />}
                exact={true}
              />
              <Redirect to="/" />
            </IonRouterOutlet>
          </>
        ) : (
          <LastLocationProvider>
            <IonRouterOutlet animated={false}>
              <Route exact path="/" render={() => <Home />} />
              <Route
                exact
                path="/listings"
                render={(props) => (
                  <Layout>
                    <SearchPage {...props} />
                  </Layout>
                )}
              />

              <Route
                exact
                path="/user"
                render={() => (
                  <Layout>
                    <UserAccount />
                  </Layout>
                )}
              />

              <Route
                exact
                path="/favourites"
                render={() => (
                  <Layout>
                    <FavouriteListings />
                  </Layout>
                )}
              />

              <Route
                exact
                path="/searches"
                render={() => (
                  <Layout>
                    <SavedSearches />
                  </Layout>
                )}
              />

              <Route
                exact
                path={["/tours", "/clienttours", "/agenttours"]}
                render={() => (
                  <Layout>
                    <ToursRoute
                      user={user}
                      isWeb
                      agentComponent={<Tours />}
                      clientComponent={<ClientTours />}
                    />
                  </Layout>
                )}
              />

              <Route
                exact
                path="/tours/:tourId"
                render={(props) => (
                  <Layout>
                    <ToursRoute
                      user={user}
                      isWeb
                      agentComponent={<Tour {...props} />}
                      clientComponent={<ClientTourDetails {...props} />}
                    />
                  </Layout>
                )}
              />

              <Route
                exact
                path="/tours/details/:tourItemId"
                render={(props) => (
                  <Layout>
                    <TourDetails {...props} />
                  </Layout>
                )}
              />
              <Route
                path="/schools/:id"
                render={(props) => (
                  <Layout>
                    <SchoolDetails {...props} />
                  </Layout>
                )}
                exact
              />

              <Route
                exact
                path="/listings/:mlsNumber"
                render={(props) => (
                  <Layout>
                    <DetailPage {...props} />
                  </Layout>
                )}
              />
              <Route exact path="/signout" render={() => <Logout />} />

              <Route
                exact
                path="/terms"
                render={(props) => (
                  <Layout>
                    <TermsOfService />
                  </Layout>
                )}
              />

              <Route
                exact
                path="/privacy"
                render={(props) => (
                  <Layout>
                    <PrivacyPolicy />
                  </Layout>
                )}
              />

              <Route
                exact
                path="/contact-us"
                render={(props) => (
                  <Layout>
                    <ContactUs />
                  </Layout>
                )}
              />

              <Route
                exact
                path="/feedback"
                render={(props) => (
                  <Layout>
                    <Feedback />
                  </Layout>
                )}
              />

              <Route
                exact
                path="/school-terms"
                render={(props) => (
                  <Layout>
                    <SchoolDisclaimer />
                  </Layout>
                )}
              />

              <Route
                exact
                path={["/about-us", "/hiring-agent"]}
                render={(props) => (
                  <Layout>
                    <AboutUs />
                  </Layout>
                )}
              />

              <Route
                exact
                path="/market-data"
                render={(props) => (
                  <Layout>
                    <MarketData />
                  </Layout>
                )}
              />
              <Route
                path="/tabs/listings"
                render={() => <Redirect to="/listings" />}
                exact={true}
              />
              <Route
                path="/tabs/favourites"
                render={() => <Redirect to="/favourites" />}
                exact={true}
              />
              <Route
                path="/tabs/clienttours"
                render={() => <Redirect to="/tours" />}
                exact={true}
              />
              <Route
                path="/tabs/agenttours"
                render={() => <Redirect to="/tours" />}
                exact={true}
              />
              <Route
                path="/tabs/more"
                render={() => <Redirect to="/user" />}
                exact={true}
              />
              <Route
                path="/tabs/home"
                render={() => <Redirect to="/" />}
                exact={true}
              />
              <Redirect to="/" />
            </IonRouterOutlet>
          </LastLocationProvider>
        )}
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
