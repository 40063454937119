import Select from "../../Form/Select/select.component";
import RangeFilter from "../../RangeFilter/range-filter.component";
import { lotDepthRange } from "../../Filters/data/filters.data";
import styles from "../filters-menu.module.scss";
import withFilters from "../../../HOC/withFilters/with-filters";
import _ from "lodash";
const VALID_TYPES = ["Att/Row/Twnhouse", "Detached", "Semi-detached"];

const LotDepthFilterItem = ({
  filters: { propertyType },
  extraFilters: {
    lotDepth: { min, max },
  },
  updateSingleFilter,
  updateExtraFilters,
  buttonStyle,
  tabName,
  tab,
  setTab,
  disabled,
}) => {
  const onChangeHandler = ({ type, value }) => {
    switch (type) {
      case "min":
        updateExtraFilters({ lotDepth: { min: value, max } });
        break;
      case "max":
        updateExtraFilters({ lotDepth: { min, max: value } });
        break;
      default:
        return;
    }
  };

  const show = () => {
    if (!propertyType) return false;
    const intersections = _.intersection(VALID_TYPES, propertyType).length;
    if (intersections === propertyType.length) return true;
    return false;
  };

  return show() ? (
    <div className={styles.item}>
      <Select
        buttonStyle={buttonStyle}
        title="Lot depth (feet)"
        open={tab === tabName}
        onClick={() => setTab(tabName)}
        dropdown={false}
        disabled={disabled}
      />
      <RangeFilter
        type="rooms"
        open={tab === tabName}
        range={lotDepthRange}
        value={{ min, max }}
        dropdown={false}
        onChange={onChangeHandler}
        style={{ marginBottom: "1rem" }}
      />
    </div>
  ) : null;
};

export default withFilters(LotDepthFilterItem);
