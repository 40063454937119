import { createSelector } from "reselect";

const selectTours = (state) => state.tours;

export const selectAllTours = createSelector(
  [selectTours],
  (state) => state.tours
);

export const selectCurrentTour = createSelector(
  [selectTours],
  (state) => state.currentTour
);

export const selectToursError = createSelector(
  [selectTours],
  (state) => state.error
);

export const selectToursLoading = createSelector(
  [selectTours],
  (state) => state.loading
);
