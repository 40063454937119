import Select from "../../Form/Select/select.component";
import withFilters from "../../../HOC/withFilters/with-filters";
import styles from "../filters-menu.module.scss";
import TypeFilter from "../../TypeFilter/type-filter.component";
import { basementTypes as options } from "../../Filters/data/filters.data";
import _ from "lodash";
const INVALID_TYPES = ["Condo Apt"];

const BasementFilterItem = ({
  buttonStyle,
  filters: { propertyType },
  extraFilters: { basement },
  updateExtraFilters,
  tabName,
  tab,
  setTab,
  disabled,
}) => {
  const onChangeHandler = (item) => {
    if (item === "none") {
      updateExtraFilters({
        basement: {
          any: false,
          sepEntrace: false,
          walkOut: false,
          finished: false,
        },
      });
    } else if (item === "any") {
      updateExtraFilters({
        basement: {
          any: true,
          sepEntrace: false,
          walkOut: false,
          finished: false,
        },
      });
    } else {
      updateExtraFilters({
        basement: {
          ...basement,
          any: false,
          [item]: !basement[item],
        },
      });
    }
  };

  const getValue = () => {
    const selected = Object.keys(basement).filter((key) => basement[key]);
    return selected.length ? selected : ["none"];
  };

  const show = () => {
    if (!propertyType) return false;
    const intersections = _.intersection(INVALID_TYPES, propertyType).length;
    return intersections === 0 ? true : false;
  };

  return show() ? (
    <div className={styles.item}>
      <Select
        buttonStyle={buttonStyle}
        title="Basement"
        open={tab === tabName}
        onClick={() => setTab(tabName)}
        dropdown={false}
        disabled={disabled}
      />
      <TypeFilter
        open={tab === tabName}
        items={options}
        value={getValue()}
        onClickHandler={onChangeHandler}
        dropdown={false}
      />
    </div>
  ) : null;
};

export default withFilters(BasementFilterItem);
