import { IonCol, IonGrid, IonRow, IonIcon, IonLabel } from "@ionic/react";
import { arrowForwardCircleOutline } from "ionicons/icons";
import styles from "./tour-attendees.module.scss";

const TourAttendees = ({ tour }) => {
  const membersData = JSON.parse(tour.membersData);

  const renderClient = () => {
    const client = membersData.find((m) => m.role === "Client");
    if (client)
      return (
        <IonLabel>
          {client.givenName} {client.familyName}
        </IonLabel>
      );
    return "---";
  };

  const renderGuests = () => {
    const guests = membersData.filter((m) => m.role === "Additional Guest");
    if (!guests || guests.length === 0) return;
    return guests.map((g, i) => (
      <IonRow className={styles.attendees} key={i}>
        <IonCol>Additional guest</IonCol>
        <IonCol className={`${styles.bold}`}>
          <IonLabel>{`${g.givenName} ${g.familyName}`}</IonLabel>
        </IonCol>
      </IonRow>
    ));
  };

  return (
    <IonGrid className={styles.tourAttendees}>
      <IonRow className={styles.attendees}>
        <IonCol>Lead agent</IonCol>
        <IonCol className={`${styles.bold} ${styles.link}`}>
          <IonLabel>{tour.agent.name}</IonLabel>
          {/* <IonIcon icon={arrowForwardCircleOutline} /> */}
        </IonCol>
      </IonRow>
      <IonRow className={styles.attendees}>
        <IonCol>Showing agent</IonCol>
        <IonCol className={`${styles.bold} ${styles.link}`}>
          <IonLabel>
            {tour.showingAgent ? tour.showingAgent.name : "-"}
          </IonLabel>

          {/* <IonIcon icon={arrowForwardCircleOutline} /> */}
        </IonCol>
      </IonRow>
      <IonRow className={styles.attendees}>
        <IonCol>Client</IonCol>
        <IonCol className={`${styles.bold}`}>{renderClient()}</IonCol>
      </IonRow>
      {renderGuests()}
    </IonGrid>
  );
};

export default TourAttendees;
