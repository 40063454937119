import styles from "./search-result.module.scss";
import { buildAddress, getHighlightedText } from "../../../utils/functions";
import { ReactComponent as HomeIcon } from "../../../assets/svg/home.svg";
import { ReactComponent as LocationIcon } from "../../../assets/svg/location-pin.svg";

const SearchResult = ({ item, term, onClick, type }) => {
  return (
    <div className={styles.result} onClick={onClick}>
      <div className={styles.iconContainer}>
        {type === "listing" ? (
          <HomeIcon className={styles.icon} />
        ) : (
          <LocationIcon className={styles.icon} />
        )}
      </div>
      <div className={styles.details}>
        <div className={styles.addressContainer}>
          <span className={styles.address}>
            {type === "listing"
              ? getHighlightedText(
                  `${item.address.city} - ${buildAddress(item.address)}`,
                  term
                )
              : type === "current location"
              ? item.name
              : getHighlightedText(item.name, term)}
          </span>
        </div>
        <span
          className={`${styles.type} ${
            type === "current location" && styles.purple
          }`}
        >
          {type.toLowerCase()}
        </span>
      </div>
    </div>
  );
};

export default SearchResult;
