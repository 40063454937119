import React, { useEffect, memo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  IonPage,
  IonContent,
  IonModal,
  useIonViewWillEnter,
  useIonViewDidEnter,
} from "@ionic/react";
import styles from "./layout.module.scss";
import Modal from "../../components/Modal/modal.component";
import Header from "../../components/Header/header.component";
import NavLogo from "../../components/NavLogo/nav-logo.component";
import LeftNav from "../../components/Navigation/LeftNav/left-nav.component";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import { selectCurrentClient } from "../../redux/client/client.selectors";
import CustomAuthContainer from "../../components/Authentication/CustomAuthContainer/custom-auth-container.component";
import FiltersMenu from "../../components/FiltersMenu/filters-menu.component";
import ErrorPage from "../../components/Errors/error.component";
import { useLocation, useHistory } from "react-router-dom";
import {
  selectUIAuthModal,
  selectUIMenu,
  selectUIMenuTab,
  selectUIModal,
} from "../../redux/ui/ui.selectors";
import {
  closeAuthModal,
  closeModal,
  collapseMenu,
  openAuthModal,
  openModal,
  updateMenuTab,
  updateToast,
} from "../../redux/ui/ui.actions";
import Slide from "react-reveal/Slide";

const Layout = ({ children }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [width, setWidth] = useState(window.innerWidth);
  const user = useSelector(selectCurrentUser);
  const client = useSelector(selectCurrentClient);
  const modal = useSelector(selectUIModal);
  const authModal = useSelector(selectUIAuthModal);
  const tab = useSelector(selectUIMenuTab);
  const collapsed = useSelector(selectUIMenu).collapsed;

  const useNetwork = () => {
    const [isOnline, setIsOnline] = useState(window.navigator.onLine);

    useEffect(() => {
      window.addEventListener("offline", () =>
        setIsOnline(window.navigator.onLine)
      );
      window.addEventListener("online", () =>
        setIsOnline(window.navigator.onLine)
      );
    });

    return isOnline;
  };

  useEffect(() => {
    const updateWindowWidth = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
    };
    window.addEventListener("resize", updateWindowWidth);

    return () => window.removeEventListener("resize", updateWindowWidth);
  }, []);

  useEffect(() => {
    if (user) dispatch(closeAuthModal());
  }, [dispatch, user]);

  useEffect(() => {
    if (
      client &&
      !client.profile &&
      user &&
      !user.signInUserSession.accessToken.payload["cognito:groups"].includes(
        "Agents"
      )
    )
      dispatch(openModal({ current: "confirmRegistration", closable: false }));
  }, [client, dispatch, user]);

  useEffect(() => {
    if (width <= 1200 && !collapsed) {
      dispatch(collapseMenu());
    } else if (localStorage.getItem("collapsed") && !collapsed)
      dispatch(collapseMenu());
  }, [collapsed, dispatch, width]);

  useIonViewWillEnter(() => {
    dispatch(updateMenuTab(pathname));
  });

  return (
    <IonPage>
      <IonContent>
        <div className={`${styles.container} ${collapsed && styles.collapse}`}>
          {modal.isOpen && (
            <Modal
              type={modal.current}
              setType={(type, closable) =>
                dispatch(
                  openModal({
                    ...modal,
                    current: type,
                    closable: closable ? closable : modal.closable,
                  })
                )
              }
              overflow={modal.overflow}
              closable={modal.closable}
              data={modal.data}
              isProtected={modal.isProtected}
              setData={(data) => dispatch(openModal({ ...modal, data }))}
              close={() => dispatch(closeModal())}
            />
          )}

          {useNetwork() && <Header isAuthenticated={user} />}

          <>
            <NavLogo
              collapse={collapsed}
              setCollapse={() => dispatch(collapseMenu())}
              hideCollapseButton={pathname === "/filters"}
            />
            <LeftNav collapse={collapsed} />
          </>

          {!user && (
            <IonModal
              isOpen={authModal.isOpen}
              cssClass={styles.modal}
              backdropDismiss={false}
            >
              <CustomAuthContainer
                type={authModal.current}
                setType={(current) => dispatch(openAuthModal({ current }))}
                closeModal={(current) => dispatch(closeAuthModal())}
              />
            </IonModal>
          )}

          {useNetwork() ? (
            <>
              <div
                className={`${styles.filters} ${
                  tab !== "/filters" && styles.hideFilters
                } `}
              >
                <Slide duration={200} left when={tab === "/filters"}>
                  <FiltersMenu />
                </Slide>
              </div>

              {React.cloneElement(children, {
                user,
                client,
                pathname,
                pageHook: useIonViewDidEnter,
              })}
            </>
          ) : (
            <div className={styles.error}>
              <ErrorPage
                status="offline"
                hideHeader={true}
                onClick={() => history.replace("/")}
              />
            </div>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default memo(Layout);
