import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { IonButton, IonIcon, IonLoading, IonSkeletonText } from "@ionic/react";
import { chevronForwardOutline, navigateOutline } from "ionicons/icons";
import Photos from "../../components/Photos/photos.component";
import Recordings from "../../components/Recordings/recordings.component";
import Notes from "../../components/Notes/notes.component";
import styles from "./client-tour-details.module.scss";
import ShowingDetails from "./component/ShowingDetails/showing-details.component";
import Overview from "./component/Overview/overview.component";
import {
  getClientSingleTour,
  getShareStatus,
  getSingleTourItem,
} from "../../amplify/graphql.utils";
import { updateToast } from "../../redux/ui/ui.actions";
import { getListing } from "../../api/repliers";
import LoadingFullPage from "../../components/Loading/loading-full-page.component";
import withAuthentication from "../../HOC/withAuthentication/with-authentication";
import { buildAddress } from "../../utils/functions";

const ClientTourDetails = ({
  match: {
    params: { tourId },
  },
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [tour, setTour] = useState();
  const [tourItem, setTourItem] = useState();
  const [listing, setLisitng] = useState();
  const [showPhotos, setShowPhotos] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [showRecordings, setShowRecordings] = useState(false);
  const [playing, setPlaying] = useState();

  useEffect(() => {
    const fetchTour = async () => {
      setLoading(true);
      try {
        const tourItem = await getSingleTourItem(tourId);
        const tour = await getClientSingleTour(tourItem.tourId);
        setTour(tour);
        const shareStatus = await getShareStatus(tourItem.tourId);
        if (!shareStatus) {
          history.replace("/tours");
          return;
        }
        const listingItem = await getListing(tourItem.mlsNumber);
        setLisitng(listingItem);
        setTourItem(tourItem);
      } catch (err) {
        console.log(err);
        dispatch(
          updateToast({
            open: true,
            type: "error",
            message: "Something went wrong!",
          })
        );
        history.replace("/tours");
      }
      setLoading(false);
    };

    fetchTour();
  }, [dispatch, history, tourId]);

  return (
    <div className={styles.clientTourDetails}>
      <IonLoading isOpen={loading} />
      {!tourItem || !listing ? (
        <div className={styles.loading}>
          <LoadingFullPage />
        </div>
      ) : (
        <>
          <div className={styles.nav}>
            <span onClick={() => history.push("/tours")}>All tours</span>
            <span className={styles.icon}>
              <IonIcon icon={chevronForwardOutline} />
            </span>
            <span onClick={() => history.push(`/tours`)}>
              {tour ? (
                tour.title
              ) : (
                <IonSkeletonText style={{ width: "8rem" }} animated={true} />
              )}
            </span>
            <span className={styles.icon}>
              <IonIcon icon={chevronForwardOutline} />
            </span>
            <span className={styles.bold}>
              {listing ? (
                buildAddress(listing.address)
              ) : (
                <IonSkeletonText style={{ width: "12rem" }} animated={true} />
              )}
            </span>
          </div>
          <div className={styles.details}>
            <div>
              <ShowingDetails tour={tourItem} listing={listing} />
              <Overview listing={listing} />
            </div>
            <div key={tourItem?.id}>
              <Photos
                photoItems={tourItem.photos.items}
                tourItem={tourItem}
                listing={listing}
                isOpen={showPhotos}
                setIsOpen={setShowPhotos}
              />
              <Notes
                listing={listing}
                tourItem={tourItem}
                noteItems={tourItem.notes.items}
                isOpen={showNotes}
                setIsOpen={setShowNotes}
              />
              <Recordings
                recordingItems={tourItem.recordings.items}
                isOpen={showRecordings}
                setIsOpen={setShowRecordings}
                tourItem={tourItem}
                listing={listing}
                playing={playing}
                setPlaying={setPlaying}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default withAuthentication(ClientTourDetails);
