import { useState, useEffect } from "react";
import styles from "./baths-filter.module.scss";
import { IonRange } from "@ionic/react";
import withFilters from "../../../../../HOC/withFilters/with-filters";

const BathFilters = ({
  filters: { minBaths, maxBaths },

  updateMultipleFilters,
}) => {
  const [baths, setBaths] = useState({
    lower: minBaths ? minBaths : 0,
    upper: maxBaths === "any" || !maxBaths ? 5 : maxBaths,
  });

  const handleOnChange = ({
    detail: {
      value: { lower, upper },
    },
  }) => {
    if (lower !== baths.lower || upper !== baths.upper)
      setBaths({ lower, upper });
  };

  useEffect(() => {
    updateMultipleFilters({
      minBaths: baths.lower,
      maxBaths: baths.upper === 5 ? "any" : baths.upper,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baths]);

  return (
    <div className={styles.item}>
      <div className={styles.input}>
        <IonRange
          value={baths}
          min={0}
          max={5}
          step={1}
          snaps={true}
          ticks={true}
          dualKnobs
          onIonChange={handleOnChange}
          className={styles.range}
        />
        <div className={styles.pins}>
          <span className={styles.pin}>{baths.lower}</span>
          <span className={styles.pin}>
            {baths.upper === 5 ? "any" : baths.upper}
          </span>
        </div>
      </div>
    </div>
  );
};

export default withFilters(BathFilters);
