import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import AppHeader from "../../components/Header/header.component";
import marketData from "../../../assets/img/report.png";
import styles from "./market-data.module.scss";

const AppMarketData = () => {
  const history = useHistory();

  return (
    <IonPage>
      <AppHeader title="Market data" hasRightButton={false} />
      <IonContent>
        <IonGrid className={styles.marketdata}>
          <IonRow>
            <IonCol className={styles.image}>
              <img alt="Market data" src={marketData} className={styles.img} />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className={styles.title}>Coming soon!</IonCol>
          </IonRow>
          <IonRow>
            <IonCol className={styles.subtitle}>
              We’re in the works of creating our in-depth database with all the
              real estate market data you’ll need! We’re adding our own layers
              of analyses to help you further understand exactly what’s
              happening in the market. Register for an account to get the latest
              updates!
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton
                shape="round"
                className={styles.btn}
                onClick={() => history.goBack()}
              >
                Go back!
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default AppMarketData;
