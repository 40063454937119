import { useState, useRef, useEffect } from "react";
import { createGesture } from "@ionic/core";
import { useSelector } from "react-redux";
import FavoriteIcon from "../../../../../components/ListingCard/components/FavoriteIcon/favorite-icon.component";
import withClient from "../../../../../HOC/withClient/with-client";
import { selectCurrencyRates } from "../../../../../redux/currency/currency.selectors";
import {
  diffDate,
  getTagTitle,
  isActiveTag,
  renderCurrencySign,
  renderPrice,
} from "../../../../../utils/functions";
import styles from "./listing-details.module.scss";
import ListingContent from "./components/ListingContent/listing-content.component";
import { IonIcon } from "@ionic/react";
import {
  arrowDownOutline,
  arrowUpOutline,
  checkmarkOutline,
} from "ionicons/icons";
import millify from "millify";
import moment from "moment";

const ListingDetails = ({
  listing,
  similarListing,
  currentClient,
  isActiveComparables,
  parentRef,
  imagesRef,
  schools,
}) => {
  const {
    mlsNumber,
    status,
    lastStatus,
    type,
    listDate,
    soldDate,
    updatedOn,
    soldPrice,
    listPrice,
  } = listing;
  const [expanded, setExpanded] = useState(false);
  const containerRef = useRef();
  const handleRef = useRef();
  const contentRef = useRef();
  const rates = useSelector(selectCurrencyRates);

  let currency;
  if (currentClient && rates) {
    if (currentClient.currency !== "cad")
      currency = currentClient.currency.toUpperCase();
  }

  let comparableStatus;
  isActiveComparables && (comparableStatus = "A");

  useEffect(() => {
    let h = handleRef.current;
    let c = containerRef.current;
    let content = contentRef.current;
    let images = imagesRef.current;
    let gesture;

    gesture = createGesture({
      el: h,
      gestureName: "my-swipe",
      direction: "y",
      onMove: (event) => {
        images.style.overflow = "hidden";

        if (event.deltaY < -content.clientHeight) return;

        if (event.deltaY > 100) {
          c.style.transform = "";
          c.dataset.open = "false";
          setExpanded(false);
          images.style.overflow = "scroll";
          images.focus();
          return;
        }
        c.style.transform = `translateY(${event.deltaY}px)`;
      },

      onEnd: (event) => {
        c.style.transition = "0.5s ease-out";
        if (event.deltaY < -40 && c.dataset.open !== "true") {
          c.style.transform = `translateY(-${content.clientHeight}px)`;
          c.dataset.open = "true";
          imagesRef.current.blur();
          setExpanded(true);
          c.focus();
        } else {
          c.style.transform = "";
          c.dataset.open = "false";
          setExpanded(false);
          images.style.overflow = "scroll";
          images.focus();
          return;
        }
      },
    });

    gesture.enable(true);

    return () => gesture && gesture.destroy();
  }, [expanded, imagesRef]);

  const handleClick = () => {
    let h = handleRef.current;
    let c = containerRef.current;
    let content = contentRef.current;
    if (h.dataset.open === "true") {
      c.style.transform = "";
      h.dataset.open = "false";
      content.style.height = "100%";
      if (imagesRef.current) imagesRef.current.style.overflow = "scroll";
      setExpanded(false);
    } else {
      h.dataset.open = "true";
      c.style.transform = `translateY(-${content.clientHeight}px)`;
      c.style.transition = "0.5s ease-in-out";
      if (imagesRef.current) imagesRef.current.style.overflow = "hidden";
      setExpanded(true);
    }
  };

  const renderPriceDiff = () => {
    const renderListPrice = () => (
      <span className={styles.listPrice}>
        List Price: {renderCurrencySign(currency)}
        {millify(
          currency
            ? (+listPrice / +rates.CAD.S) * +rates[currency].S
            : listPrice,
          { precision: 2 }
        )}
      </span>
    );
    let diff = soldPrice - listPrice;
    if (currency) diff = (+diff / +rates.CAD.S) * +rates[currency].S;

    if (diff > 0) {
      return (
        <div>
          <span className={styles.increased}>
            <IonIcon icon={arrowUpOutline} />
            <span className={styles.value}>
              {renderCurrencySign(currency)}
              {millify(diff, { precision: 2 })} above asking
            </span>
          </span>
          {renderListPrice()}
        </div>
      );
    }

    if (diff < 0) {
      return (
        <div>
          <span className={styles.decreased}>
            <IonIcon icon={arrowDownOutline} />
            <span className={styles.value}>
              ${millify(Math.abs(diff, { precision: 2 }))} below asking
            </span>
          </span>
          {renderListPrice()}
        </div>
      );
    }

    return (
      <div>
        <span className={styles.asking}>
          <IonIcon icon={checkmarkOutline} />
          <span className={styles.text}>
            {type.toLowerCase() === "sale" ? "sold" : "leased"} at asking
          </span>
        </span>
      </div>
    );
  };

  return (
    <div
      id="details"
      className={styles.container}
      ref={containerRef}
      onMouseEnter={() => imagesRef.current.blur()}
      onTouchMove={() => imagesRef.current.blur()}
      onTouchStart={() => {
        imagesRef.current.blur();
        imagesRef.current.style.overflow = "hidden";
      }}
      style={{
        top: `calc(100% - ${
          status === "A" ? "17rem" : expanded ? "17rem" : "20rem"
        })`,
      }}
    >
      <div
        className={styles.header}
        ref={handleRef}
        onClick={handleClick}
        style={{
          height: status === "A" ? "10rem" : expanded ? "10.5rem" : "13rem",
        }}
      >
        <div className={styles.handleContainer}>
          <span className={styles.handle} />
        </div>

        <div
          className={styles.tags}
          style={{
            opacity: !expanded ? "1" : "0",
            position: !expanded ? "relative" : "absolute",
          }}
        >
          <span className={styles[isActiveTag(lastStatus)]}>
            {["sale", "lease"].includes(
              getTagTitle(lastStatus, type).toLowerCase()
            )
              ? `for ${getTagTitle(lastStatus, type)}`
              : getTagTitle(lastStatus, type)}
          </span>
          {status === "A" ? (
            <span className={styles.date}>
              {diffDate(status, listDate, soldDate).label}
            </span>
          ) : (
            diffDate(status, listDate, soldDate).onMarket && (
              <span className={styles.onMarket}>
                <span style={{ fontWeight: 600 }}>
                  {
                    diffDate(
                      status,
                      moment.utc(listDate),
                      soldDate ? moment.utc(soldDate) : moment.utc(updatedOn)
                    ).soldDate
                  }
                </span>
              </span>
            )
          )}
        </div>

        <div className={`${styles.price} ${expanded && styles.priceExpanded}`}>
          <span className={styles.current}>
            {renderPrice(currency, soldPrice, rates, listPrice)}
          </span>

          {(lastStatus === "Sld" || lastStatus === "Lsd") && (
            <span className={styles.diff}>{renderPriceDiff()}</span>
          )}
        </div>
        <div
          className={styles.icons}
          onTouchEnd={() => {
            if (!expanded) imagesRef.current.style.overflow = "scroll";
          }}
        >
          <FavoriteIcon
            isApp={true}
            listing={listing}
            isActiveComparables={isActiveComparables}
          />
        </div>
      </div>
      <div className={styles.content} ref={contentRef} id="content">
        <ListingContent
          listing={listing}
          similarListing={similarListing}
          client={currentClient}
          containerRef={parentRef}
          schools={schools}
        />
      </div>
    </div>
  );
};

export default withClient(ListingDetails);
