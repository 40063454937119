import { useState, useEffect, useRef } from "react";
import styles from "./price-filter.module.scss";
import { IonRange } from "@ionic/react";
import withFilters from "../../../../../HOC/withFilters/with-filters";
import millify from "millify";
import {
  calculatePriceRange,
  reversePriceRange,
} from "../../../../../utils/functions";

const PriceFilter = ({
  filters: { minPrice, maxPrice, type },

  updateMultipleFilters,
}) => {
  const ref = useRef();
  const pinRef = useRef();
  const [pinValue, setPinValue] = useState("");

  const [price, setPrice] = useState({
    lower: reversePriceRange(minPrice, type),
    upper: reversePriceRange(maxPrice, type),
  });

  const handleOnChange = ({
    detail: {
      value: { lower, upper },
    },
  }) => {
    if (lower !== price.lower || upper !== price.upper)
      setPrice({ lower, upper });
  };

  useEffect(() => {
    const { lower, upper } = price;

    updateMultipleFilters({
      minPrice: calculatePriceRange(lower, type, true),
      maxPrice: calculatePriceRange(upper, type, false),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [price, type]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (ref.current) {
        let minKnob, maxKnob;
        minKnob = ref.current.shadowRoot.querySelector(".range-knob-a");
        maxKnob = ref.current.shadowRoot.querySelector(".range-knob-b");

        if (minKnob) {
          minKnob.addEventListener("pointermove", () => {
            if (pinValue !== "min") setPinValue("min");
            const { left } = minKnob.getBoundingClientRect();
            pinRef.current.style.left = `${left}px`;

            minKnob.addEventListener("pointerup", () => setPinValue(null));
          });
        }

        if (maxKnob) {
          maxKnob.addEventListener("pointermove", () => {
            if (pinValue !== "max") setPinValue("max");
            const { left } = maxKnob.getBoundingClientRect();
            pinRef.current.style.left = `${left}px`;
          });

          maxKnob.addEventListener("pointerup", () => setPinValue(null));
        }
      }
    }, 1000);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [pinValue]);

  const renderPin = () => {
    if (pinValue === "min") {
      if (minPrice) {
        return `$${millify(minPrice ? minPrice : 0, {
          precision:
            type === "sale"
              ? minPrice > 1000000
                ? 2
                : 0
              : minPrice > 1000
              ? 2
              : 1,
        })}`;
      } else {
        return "$0";
      }
    }

    if (pinValue === "max") {
      if (maxPrice) {
        if (maxPrice === "max") return "max";
        return `$${millify(maxPrice, {
          precision:
            type === "sale"
              ? maxPrice > 1000000
                ? 2
                : 0
              : maxPrice > 1000
              ? 2
              : 1,
        })}`;
      } else {
        return "max";
      }
    }
  };

  return (
    <div className={styles.item}>
      <div className={styles.input}>
        <span className={styles.floatingPin} ref={pinRef}>
          {pinValue && `${renderPin()}`}
        </span>
        <IonRange
          ref={ref}
          value={price}
          min={0}
          step={1}
          max={100}
          dualKnobs
          onIonChange={handleOnChange}
          className={styles.range}
        />
        <div className={styles.pins}>
          <span className={styles.pin}>
            $
            {minPrice
              ? millify(minPrice ? minPrice : 0, {
                  precision:
                    type === "sale"
                      ? minPrice > 1000000
                        ? 2
                        : 0
                      : minPrice > 1000
                      ? 2
                      : 1,
                })
              : "0"}
          </span>
          <span className={styles.pin}>
            {maxPrice && maxPrice !== "max"
              ? `$${millify(maxPrice, {
                  precision:
                    type === "sale"
                      ? maxPrice > 1000000
                        ? 2
                        : 0
                      : maxPrice > 1000
                      ? 2
                      : 1,
                })}`
              : "max"}
          </span>
        </div>
      </div>
    </div>
  );
};

export default withFilters(PriceFilter);
