import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import {
  IonBadge,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";
import { Route } from "react-router-dom";
import {
  ellipsisHorizontalOutline,
  eyeOutline,
  mapOutline,
  starOutline,
} from "ionicons/icons";
import AppHomePage from "../app/pages/Home/home.component";
import Search from "../app/pages/Search/search.component";
import Favourites from "../app/pages/Favourites/favourites.component";
import ClientTours from "../app/pages/ClientTours/client-tours.component";
import AgentTours from "../app/pages/AgentTours/agent-tours.component";
import UserAccount from "../app/pages/UserAccount/user-account.component";
import styles from "./styles.module.scss";
import { selectIsAgent } from "../redux/user/user.selectors";
import { selectUINotifications } from "../redux/ui/ui.selectors";
import { getPlatforms } from "@ionic/core";
import homeOutlineIcon from "../assets/svg/REA103_Icons-01a_HomeEttie.svg";
import homeIcon from "../assets/svg/REA103_Icons-01a_HomeEttieSelected.svg";

const AppTabs = () => {
  const history = useHistory();
  const notifications = useSelector(selectUINotifications);
  const isAgent = useSelector(selectIsAgent);
  const path = history.location.pathname;

  return (
    <IonTabs>
      <IonRouterOutlet animated id="tabs">
        <Route exact path="/tabs/home" component={AppHomePage} />
        <Route exact path="/tabs/listings" component={Search} />
        <Route exact path="/tabs/favourites" component={Favourites} />
        <Route exact path="/tabs/agentTours" component={AgentTours} />
        <Route exact path="/tabs/clientTours" component={ClientTours} />
        <Route exact path="/tabs/more" component={UserAccount} />
      </IonRouterOutlet>
      <IonTabBar slot="bottom">
        <IonTabButton tab="home" href="/tabs/home" className={styles.tab}>
          <IonIcon
            icon={path === "/tabs/home" ? homeOutlineIcon : homeIcon}
            className={styles.icon}
          />
          <IonLabel className={styles.label}>home</IonLabel>
        </IonTabButton>
        <IonTabButton
          tab="listings"
          href="/tabs/listings"
          className={styles.tab}
        >
          <IonIcon icon={mapOutline} className={styles.icon} />
          <IonLabel className={styles.label}>map</IonLabel>
        </IonTabButton>

        <IonTabButton
          tab="favourites"
          href="/tabs/favourites"
          className={styles.tab}
        >
          {notifications.favourites &&
            notifications.favourites.length > 0 &&
            (getPlatforms().includes("ios") ? (
              !getPlatforms().includes("tablet") ? (
                <span className={styles.iosBadge} />
              ) : null
            ) : (
              <IonBadge className={styles.badge} />
            ))}

          <IonIcon icon={starOutline} className={styles.icon} />
          <IonLabel className={styles.label}>favourites</IonLabel>
        </IonTabButton>

        <IonTabButton
          tab={isAgent ? "agentTours" : "clientTours"}
          href={isAgent ? "/tabs/agenttours" : "/tabs/clienttours"}
          className={styles.tab}
        >
          <IonIcon icon={eyeOutline} className={styles.icon} />
          <IonLabel className={styles.label}>my tours</IonLabel>
        </IonTabButton>
        <IonTabButton tab="more" href="/tabs/more" className={styles.tab}>
          <IonIcon icon={ellipsisHorizontalOutline} className={styles.icon} />
          <IonLabel className={styles.label}>more</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default AppTabs;
