import { useHistory } from "react-router-dom";
import { IonIcon } from "@ionic/react";
import { helpCircleOutline } from "ionicons/icons";
import Button from "../../Form/Button/button.component";
import { userId } from "../../../data/customModal";
import styles from "./user-id-modal.module.scss";

const UserIDModal = ({ close, language }) => {
  const history = useHistory();
  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <IonIcon icon={helpCircleOutline} />
      </div>
      <div className={styles.title}>{userId[language].title}</div>
      <div className={styles.info}>
        {userId[language].info.view}{" "}
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.push("/terms");
            close();
          }}
        >
          {userId[language].info.term}
        </span>
        {userId[language].info.email}
      </div>
      <div className={styles.btn}>
        <Button
          title={userId[language].button}
          type="purple"
          style={{
            width: "47rem",
            height: "5rem",
            border: "1px solid #7158AD",
          }}
          onClick={close}
        />
      </div>
    </div>
  );
};

export default UserIDModal;
