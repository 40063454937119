import { useSelector, useDispatch } from "react-redux";
import styles from "./schools-modal.module.scss";
import Toggle from "../../Form/Toggle/toggle-component";
import Checkbox from "../../Form/Checkbox/checkbox-component";
import useComponentVisible from "../../../hooks/useComponentVisible";
import { selectSchools } from "../../../redux/schools/schools.selectors";
import {
  toggleSchools,
  updateSchoolsFilters,
} from "../../../redux/schools/schools.actions";
import Button from "../../Form/Button/button.component";
import { IonLabel, IonRange } from "@ionic/react";

const SchoolsModal = ({ close }) => {
  const dispatch = useDispatch();
  const schools = useSelector(selectSchools);

  const {
    active,
    filters: {
      rate,
      showUnrated,
      elementary,
      middle,
      high,
      public: pub,
      catholic,
      private: pri,
      frenchImmersion,
      ib,
      ap,
      gifted,
      sports,
      arts,
      showListingsWithin,
    },
  } = schools;

  const { ref } = useComponentVisible(false);

  const handleRateUpdate = ({ detail: { value } }) => {
    dispatch(updateSchoolsFilters({ rate: value }));
  };
  return (
    <div className={styles.container}>
      <div className={styles.header}>School filter</div>
      <div className={`${styles.toggleContainer} ${active && styles.enabled}`}>
        <span className={styles.label}>Show schools</span>
        <Toggle
          active={active}
          color="green"
          onToggle={() => dispatch(toggleSchools())}
        />
      </div>

      <div className={styles.content}>
        {/* <div className={styles.boundary}>
          <Checkbox
            label="Only show listings within the following boundaries"
            checked={showListingsWithin}
            onClick={() =>
              dispatch(
                updateSchoolsFilters({
                  showListingsWithin: !showListingsWithin,
                })
              )
            }
            color="green"
            disabled={!active}
          />
        </div> */}
        <div className={styles.config}>
          <div className={styles.category}>
            <div className={`${styles.title} ${!active && styles.disabled}`}>
              Level
            </div>
            <Checkbox
              disabled={!active}
              checked={elementary}
              label="Elementary"
              onClick={() =>
                dispatch(updateSchoolsFilters({ elementary: !elementary }))
              }
              color="green"
            />
            <Checkbox
              disabled={!active}
              label="Middle School"
              checked={middle}
              onClick={() =>
                dispatch(updateSchoolsFilters({ middle: !middle }))
              }
              color="green"
            />
            <Checkbox
              disabled={!active}
              label="High School"
              checked={high}
              onClick={() => dispatch(updateSchoolsFilters({ high: !high }))}
              color="green"
            />
          </div>
          <div className={styles.category}>
            <div className={`${styles.title} ${!active && styles.disabled}`}>
              Type
            </div>
            <Checkbox
              disabled={!active}
              checked={pub}
              label="Public"
              onClick={() => dispatch(updateSchoolsFilters({ public: !pub }))}
              color="green"
            />
            <Checkbox
              disabled={!active}
              checked={catholic}
              label="Catholic"
              onClick={() =>
                dispatch(updateSchoolsFilters({ catholic: !catholic }))
              }
              color="green"
            />
            <Checkbox
              disabled={true}
              checked={pri}
              label="Private"
              onClick={() =>
                dispatch(updateSchoolsFilters({ catholic: !catholic }))
              }
              color="green"
            />
          </div>
          <div className={styles.category}>
            <div className={`${styles.title} ${!active && styles.disabled}`}>
              Special Programs
            </div>
            <Checkbox
              disabled={!active}
              checked={frenchImmersion}
              label="French Immersion"
              onClick={() =>
                dispatch(
                  updateSchoolsFilters({
                    frenchImmersion: !frenchImmersion,
                  })
                )
              }
              color="green"
            />
            <Checkbox
              disabled={!active}
              checked={ib}
              label="International Baccalaureate"
              onClick={() => dispatch(updateSchoolsFilters({ ib: !ib }))}
              color="green"
            />
            <Checkbox
              disabled={!active}
              checked={ap}
              label="Advanced Placement"
              onClick={() => dispatch(updateSchoolsFilters({ ap: !ap }))}
              color="green"
            />
            <Checkbox
              disabled={!active}
              checked={gifted}
              label="Gifted"
              onClick={() =>
                dispatch(updateSchoolsFilters({ gifted: !gifted }))
              }
              color="green"
            />
            <Checkbox
              disabled={!active}
              checked={sports}
              label="Sports"
              onClick={() =>
                dispatch(updateSchoolsFilters({ sports: !sports }))
              }
              color="green"
            />
            <Checkbox
              disabled={!active}
              checked={arts}
              label="Arts"
              onClick={() => dispatch(updateSchoolsFilters({ arts: !arts }))}
              color="green"
            />
          </div>
        </div>
        <div className={styles.rating}>
          <div className={`${styles.title} ${!active && styles.disabled}`}>
            <span>EQAO Rating</span>
            <span>{rate ? (rate !== 10 ? `${rate}+` : 10) : ""}</span>
          </div>
          <div className={styles.config}>
            <div className={styles.rate} ref={ref}>
              <IonRange
                disabled={!active}
                className={styles.range}
                value={rate ? rate : 0}
                min={0}
                max={10}
                step={0.5}
                snaps
                ticks
                onIonChange={handleRateUpdate}
              >
                <IonLabel slot="start">0</IonLabel>
                <IonLabel slot="end">10</IonLabel>
              </IonRange>
            </div>
            <div className={styles.checkbox}>
              <Checkbox
                disabled={!active}
                checked={showUnrated}
                label="Show unrated schools"
                onClick={() =>
                  dispatch(updateSchoolsFilters({ showUnrated: !showUnrated }))
                }
                color="green"
              />
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <Button
              title="Confirm"
              type="green"
              border
              style={{ flex: 1 }}
              onClick={close}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default SchoolsModal;
