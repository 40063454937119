import { useState } from "react";
import Select from "../../Form/Select/select.component";
import withFilters from "../../../HOC/withFilters/with-filters";
import styles from "../filters-menu.module.scss";
import RadioFilter from "../../RadioFilter/radio-filter.component";
import { lockerOptions } from "../../Filters/data/filters.data";
import _ from "lodash";
const VALID_TYPES = ["Condo townhouse", "Condo Apt"];

const LockerFilterItem = ({
  buttonStyle,
  filters: { propertyType },
  extraFilters: { locker },
  updateExtraFilters,
  tabName,
  tab,
  setTab,
  disabled,
}) => {
  const [options, setOptions] = useState(lockerOptions);
  const onChangeHandler = ({ label }) => {
    const tempOptions = options.map((option) => {
      if (option.label !== label) {
        option.checked = false;
      } else {
        option.checked = true;
      }
      return option;
    });
    setOptions(tempOptions);
    label === "yes"
      ? updateExtraFilters({ locker: "yes" })
      : label === "no"
      ? updateExtraFilters({ locker: "no" })
      : updateExtraFilters({ locker: null });
  };

  const show = () => {
    if (!propertyType) return false;
    const intersections = _.intersection(VALID_TYPES, propertyType).length;
    if (intersections === propertyType.length) return true;
    return false;
  };

  return show() ? (
    <div className={styles.item}>
      <Select
        buttonStyle={buttonStyle}
        title="Locker"
        open={tab === tabName}
        onClick={() => setTab(tabName)}
        dropdown={false}
        disabled={disabled}
      />
      <RadioFilter
        value={locker ? locker : "any"}
        options={options}
        open={tab === tabName}
        dropdown={false}
        onClick={onChangeHandler}
      />
    </div>
  ) : null;
};

export default withFilters(LockerFilterItem);
