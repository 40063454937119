import {
  IonHeader,
  IonIcon,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonButton,
  IonTitle,
  IonLabel,
} from "@ionic/react";
import styles from "./header.module.scss";
import { chevronBackOutline, notificationsOutline } from "ionicons/icons";
import { useHistory } from "react-router-dom";

const AppHeader = ({
  title,
  isProfilePage = false,
  isTourPage = false,
  hasRightButton = true,
  hasBackButton = true,
  backUrl,
  backHref = undefined,
  isModal = false,
  isTourDetails = false,
  isFilters = false,
  onReset,
  onClick,
  customBack,
  ...rest
}) => {
  const history = useHistory();

  const handleAlerts = () => history.push("/tabs/more?alerts");

  return (
    <IonHeader className={styles.appHeader} {...rest}>
      <IonToolbar className={styles.toolbar}>
        {!isModal && hasBackButton && !backUrl && (
          <IonButtons slot="start">
            <IonBackButton
              className={styles.btn}
              slot="start"
              defaultHref={backHref ? backHref : "/tabs/listings"}
              text="Back"
              icon={chevronBackOutline}
            />
          </IonButtons>
        )}

        {backUrl && (
          <IonButtons slot="start">
            <IonButton
              className={styles.manageAlerts}
              routerDirection="back"
              routerLink={backUrl}
            >
              <IonIcon icon={chevronBackOutline} />
              <IonLabel>Back</IonLabel>
            </IonButton>
          </IonButtons>
        )}
        {customBack && (
          <IonButtons slot="start">
            <IonButton className={styles.manageAlerts} onClick={customBack}>
              <IonIcon icon={chevronBackOutline} />
              <IonLabel>Back</IonLabel>
            </IonButton>
          </IonButtons>
        )}

        {isModal && isFilters && (
          <IonButtons slot="start" onClick={onClick}>
            <IonButton className={styles.manageAlerts}>
              <IonIcon icon={chevronBackOutline} />
              <IonLabel>Back</IonLabel>
            </IonButton>
          </IonButtons>
        )}

        {hasRightButton &&
          (isTourPage ? (
            <IonButtons slot="end" onClick={onClick}>
              <IonButton className={styles.manageAlerts}>
                <span>New tour</span>
              </IonButton>
            </IonButtons>
          ) : isModal && !isFilters ? (
            <IonButtons slot="end" onClick={onClick}>
              <IonButton className={styles.manageAlerts}>
                <span>Close</span>
              </IonButton>
            </IonButtons>
          ) : isTourDetails ? (
            <IonButtons slot="end" onClick={onClick}>
              <IonButton className={styles.manageAlerts}>
                <span>Close</span>
              </IonButton>
            </IonButtons>
          ) : isFilters ? (
            <IonButtons slot="end" onClick={onReset}>
              <IonButton className={styles.manageAlerts}>
                <span>Reset</span>
              </IonButton>
            </IonButtons>
          ) : (
            <IonButtons
              slot="end"
              className={isProfilePage && styles.hidden}
              onClick={handleAlerts}
            >
              <IonButton
                className={styles.manageAlerts}
                disabled={isProfilePage}
              >
                <IonIcon icon={notificationsOutline} />
                <span>Alerts</span>
              </IonButton>
            </IonButtons>
          ))}

        <IonTitle className={styles.title}>{title}</IonTitle>
      </IonToolbar>
    </IonHeader>
  );
};

export default AppHeader;
