import { MapActionTypes } from "./map.types";

export const fetchListingsStart = (search) => ({
  type: MapActionTypes.FETCH_LISTINGS_START,
  payload: search,
});

export const fetchListingsSuccess = (listings) => ({
  type: MapActionTypes.FETCH_LISTINGS_SUCCESS,
  payload: listings,
});

export const fetchListingsFailure = (error) => ({
  type: MapActionTypes.FETCH_LISTINGS_FAILURE,
  payload: error,
});

export const updateSelectedListing = (location) => ({
  type: MapActionTypes.UPDATE_SELECTED_LOCATION,
  payload: location,
});

export const updateMapRef = (ref) => ({
  type: MapActionTypes.UPDATE_MAP_REF,
  payload: ref,
});

export const updateLocations = (locations) => ({
  type: MapActionTypes.UPDATE_LOCATIONS,
  payload: locations,
});

export const toggleFavourites = (fromFavouritesPage) => ({
  type: MapActionTypes.TOGGLE_FAVOURITES,
  payload: fromFavouritesPage,
});

export const updateActiveListing = (mlsNumber) => ({
  type: MapActionTypes.UPDATE_ACTIVE_LISTING,
  payload: mlsNumber,
});

export const updateBoundaries = (boundary) => ({
  type: MapActionTypes.UPDATE_BOUNDARIES,
  payload: boundary,
});
