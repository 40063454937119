import { useState, useRef, createRef } from "react";
import { useDispatch } from "react-redux";
import Input from "../../Form/Input/input.component";
import Button from "../../Form/Button/button.component";
import { ReactComponent as MailIcon } from "../../../assets/svg/mail-open.svg";
import styles from "./forgot-password-modal.module.scss";
import {
  resetPassword,
  sendForgotPasswordCode,
} from "../../../amplify/auth.utils";
import { emailSignInStart } from "../../../redux/user/user.actions";
import { forgotPassword } from "../../../data/customModal";
import { updateToast } from "../../../redux/ui/ui.actions";
// import { IonToast } from "@ionic/react";
// import { updateToast } from "../../../redux/ui/ui.actions";

const ForgotPasswordModal = ({ language }) => {
  const dispatch = useDispatch();
  const [step, setStep] = useState("enterEmail");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const inputRefs = useRef({
    email: createRef(),
    code: createRef(),
    password: createRef(),
    confirm_password: createRef(),
  });

  const [formData, setFormData] = useState({
    email: "",
    code: "",
    password: "",
    confirm_password: "",
  });

  const handleChange = (value, name) => {
    formData.email === "" && setError("");
    inputRefs.current[name].current.validate();
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const sendCode = async () => {
    if (inputRefs.current.email.current.validate(true)) {
      setLoading(true);
      setError("");
      try {
        await sendForgotPasswordCode(formData.email);
        setError("");
        setLoading(false);
        setStep("confirmCode");
        dispatch(
          updateToast({
            open: true,
            type: "success",
            message: "Code sent successfully.",
          })
        );
      } catch (err) {
        setLoading(false);
        err.code === "UserNotFoundException"
          ? setError("Username / email not found")
          : setError(err.message);
      }
    }
  };

  const changePassword = async () => {
    if (
      inputRefs.current.code.current.validate(true) &&
      inputRefs.current.password.current.validate(true) &&
      inputRefs.current.confirm_password.current.validate(true)
    ) {
      setLoading(true);
      setError("");
      const res = await resetPassword(
        formData.email,
        formData.code,
        formData.password
      );
      if (res && res.message) {
        setError(res.message);
      } else {
        dispatch(
          updateToast({
            open: true,
            type: "success",
            message: "Password updated successfully.",
          })
        );
        dispatch(
          emailSignInStart({
            username: formData.email,
            password: formData.password,
          })
        );
      }

      setLoading(false);
    }
  };

  const handleResend = async () => {
    try {
      await sendForgotPasswordCode(formData.email);
      dispatch(
        updateToast({
          open: true,
          type: "success",
          message: "Code sent successfully.",
        })
      );
    } catch (err) {
      dispatch(
        updateToast({ open: true, type: "error", message: err.message })
      );
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {step === "confirmCode" && (
          <div className={styles.iconContainer}>
            <MailIcon className={styles.icon} />
          </div>
        )}

        <div className={styles.subtitle}>
          {step === "enterEmail"
            ? `${forgotPassword[language].forgotHeader}`
            : step === "confirmCode"
            ? `${forgotPassword[language].checkEmailHeader}`
            : `${forgotPassword[language].newPassHeader}`}
        </div>
        <div className={styles.text}>
          {step === "enterEmail" ? (
            <div className={styles.note}>
              {forgotPassword[language].enterEmailNote}
            </div>
          ) : step === "confirmCode" ? (
            <div className={styles.note}>
              {forgotPassword[language].confirmNote}
            </div>
          ) : null}
        </div>
      </div>
      <div className={styles.content}>
        {step === "enterEmail" ? (
          <Input
            name="email"
            label={forgotPassword[language].emailInput}
            placeholder={forgotPassword[language].emailInput}
            mode="email"
            required
            ref={inputRefs.current.email}
            value={formData["email"]}
            onChange={(value, name) => handleChange(value.toLowerCase(), name)}
            validation="required|email"
          />
        ) : step === "confirmCode" ? (
          <div className={styles.inputs}>
            <Input
              name="code"
              label={forgotPassword[language].codeInput}
              placeholder={forgotPassword[language].codeInput}
              mode="numeric"
              required
              ref={inputRefs.current.code}
              value={formData["code"]}
              onChange={(value, name) => handleChange(value, name)}
              validation="required"
              autoComplete=""
            />
            {/* <div className={styles.passwordPolicy}>
              {forgotPassword[language].passwordPolicy}
            </div> */}
            <Input
              required
              name="password"
              label={forgotPassword[language].passwordInput}
              placeholder={forgotPassword[language].passwordInput}
              type="password"
              ref={inputRefs.current.password}
              value={formData["password"]}
              onChange={(value, name) => handleChange(value, name)}
              validation="required|password"
              autoComplete="new-password"
            />
            <Input
              required
              name="confirm_password"
              label={forgotPassword[language].confirmPassInput}
              placeholder={forgotPassword[language].confirmPassInput}
              type="password"
              ref={inputRefs.current.confirm_password}
              value={formData["confirm_password"]}
              onChange={(value, name) => handleChange(value, name)}
              validation={`required|confirmPassword:${formData["password"]}`}
              autoComplete="new-password"
            />
          </div>
        ) : null}
        {error && <div className={styles.error}>{error}</div>}
      </div>
      <div className={styles.button}>
        {step === "enterEmail" ? (
          <Button
            title={forgotPassword[language].submitbtn}
            type="purple"
            style={{ width: "100%", border: "1px solid #7158ad" }}
            onClick={sendCode}
            loading={loading}
          />
        ) : step === "confirmCode" ? (
          <>
            <Button
              title={forgotPassword[language].submitbtn}
              type="purple"
              style={{
                width: "100%",
                height: "5rem",
                border: "1px solid #7158ad",
              }}
              onClick={changePassword}
              loading={loading}
            />
            <Button
              title={forgotPassword[language].resendbtn}
              type="clear"
              style={{
                color: "#363636",
                marginTop: "0.5rem",
                width: "100%",
              }}
              onClick={handleResend}
            />
          </>
        ) : null}
      </div>
    </div>
  );
};

export default ForgotPasswordModal;
