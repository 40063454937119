import { useState, useEffect } from "react";
import styles from "./parkings-filter.module.scss";
import { IonRange } from "@ionic/react";
import withFilters from "../../../../../HOC/withFilters/with-filters";

const ParkingsFilter = ({
  filters: { minParkingSpaces },

  updateMultipleFilters,
}) => {
  const [parkings, setParkings] = useState(
    minParkingSpaces ? minParkingSpaces : 0
  );

  const handleOnChange = ({ detail: { value } }) => {
    setParkings(value);
  };

  useEffect(() => {
    updateMultipleFilters({
      minParkingSpaces: parkings === 0 ? null : parkings,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parkings]);

  return (
    <div className={styles.item}>
      <div className={styles.input}>
        <IonRange
          value={parkings}
          min={0}
          max={5}
          step={1}
          snaps={true}
          ticks={true}
          onIonChange={handleOnChange}
          className={styles.range}
        />
        <div className={styles.pins}>
          <span className={styles.pin}>
            {parkings === 0 ? "any" : `${parkings}+`}
          </span>
        </div>
      </div>
    </div>
  );
};

export default withFilters(ParkingsFilter);
