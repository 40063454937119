import React from "react";
import ListingImage from "../../../../../components/ListingImage/listing-image.component";
import { ReactComponent as LocationIcon } from "../../../../../assets/svg/location-pin.svg";
import { ReactComponent as HomeIcon } from "../../../../../assets/svg/home.svg";
import { ReactComponent as RoomsIcon } from "../../../../../assets/svg/human.svg";
import FavoriteIcon from "../../../../../components/ListingCard/components/FavoriteIcon/favorite-icon.component";
import styles from "./blur-component.module.scss";
import BlurMessage from "./BlurMessage/blur-message.component";

const BlurCard = ({ images, signInHandler, signUpHandler }) => {
  return (
    <>
      <div className={styles.blurMsg}>
        <BlurMessage
          signInHandler={signInHandler}
          signUpHandler={signUpHandler}
        />
      </div>
      <div className={styles.listingCard}>
        <ListingImage img={images[0] ? images[0] : "default"} />
        <div className={styles.details}>
          <span className={styles.priceContainer}>
            <div className={styles.price}>
              <div className={styles.listPrice}>$1,500,000</div>
            </div>
            <div className={styles.icons}>
              <FavoriteIcon />
            </div>
          </span>
          <span className={styles.addressContainer}>
            <span className={styles.icon}>
              <LocationIcon />
            </span>
            <span className={styles.address}>Suite 2230 - 2030 Yonge St. </span>
          </span>
          <span className={styles.typeContainer}>
            <span className={styles.icon}>
              <HomeIcon />
            </span>
            <span className={styles.type}>Detached</span>
          </span>
          <span className={styles.rooms}>
            <span className={styles.icon}>
              <RoomsIcon />
            </span>
            <span className={styles.beds}>3 bed</span>
            <span className={styles.baths}>
              <span className={styles.baths}>4 bath</span>
            </span>

            <span className={styles.parkings}>3 parking + 1 garage</span>
          </span>
          <span className={styles.info}>
            <div className={styles.dateContainer}>
              <div className={styles.date}>sold</div>
              <div className={styles.onMarket}>4 days ago</div>
            </div>
          </span>
        </div>
      </div>
    </>
  );
};

export default BlurCard;
