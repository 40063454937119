import styles from "./listing-header.module.scss";
import { IonIcon } from "@ionic/react";
import { arrowBackOutline, closeOutline } from "ionicons/icons";
import { useHistory, useLocation } from "react-router-dom";

const ListingHeader = ({ previousLocation, modal = false, dismiss, match }) => {
  const history = useHistory();
  const params = useLocation().search;
  let route = params ? params.split("?route=")[1] : null;

  return (
    <div className={styles.header}>
      {!modal ? (
        <span
          className={`${styles.backBtn} ${styles.btn}`}
          onClick={() => history.goBack()}
        >
          <IonIcon icon={arrowBackOutline} className={styles.icon} />
        </span>
      ) : (
        <span />
      )}

      <span
        className={`${styles.closeBtn} ${styles.btn}`}
        onClick={() =>
          modal
            ? dismiss()
            : history.replace(
                previousLocation
                  ? previousLocation
                  : route
                  ? route
                  : "/tabs/listings",
                { direction: "back" }
              )
        }
      >
        <IonIcon icon={closeOutline} className={styles.icon} />
      </span>
    </div>
  );
};

export default ListingHeader;
