import styles from "./delete-showing-modal.module.scss";
import Button from "../../Form/Button/button.component";

const DeleteShowingModal = ({ close, data: { remove } }) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Remove from tour?</div>
      <div className={styles.note}>
        Are you sure you want to remove this showing and all of its details from
        the tour?
      </div>
      <div className={styles.btns}>
        <Button
          type="purple"
          border
          title="Keep showing"
          style={{ height: "5rem", width: "24rem", marginRight: "2rem" }}
          onClick={close}
        />
        <Button
          type="orange"
          border
          title="Remove showing"
          style={{ height: "5rem", width: "24rem" }}
          onClick={() => {
            close();
            remove();
          }}
        />
      </div>
    </div>
  );
};

export default DeleteShowingModal;
