import { UIActionTypes } from "./ui.types";

export const openAuthModal = (modal) => ({
  type: UIActionTypes.OPEN_AUTH_MODAL,
  payload: modal,
});

export const closeAuthModal = () => ({
  type: UIActionTypes.CLOSE_AUTH_MODAL,
});
export const openModal = (modal) => ({
  type: UIActionTypes.OPEN_MODAL,
  payload: modal,
});

export const closeModal = () => ({
  type: UIActionTypes.CLOSE_MODAL,
});

export const updateBadge = (badge) => ({
  type: UIActionTypes.UPDATE_BADGE,
  payload: badge,
});

export const updateNotification = (notification) => ({
  type: UIActionTypes.UPDATE_NOTIFICATION,
  payload: notification,
});

export const markNotification = (mlsNumber) => ({
  type: UIActionTypes.MARK_NOTIFICATION,
  payload: mlsNumber,
});

export const resetNotification = () => ({
  type: UIActionTypes.RESET_NOTIFICATION,
});

export const collapseMenu = () => ({
  type: UIActionTypes.COLLAPSE_MENU,
});

export const updateMenuTab = (tab) => ({
  type: UIActionTypes.UPDATE_MENU_TAB,
  payload: tab,
});

export const updateDrawMode = (status) => ({
  type: UIActionTypes.UPDATE_DRAW_MODE,
  payload: status,
});

export const updateToast = (toast) => ({
  type: UIActionTypes.UPDATE_TOAST,
  payload: toast,
});

export const updateChangedFavourite = (id) => ({
  type: UIActionTypes.UPDATE_CHANGED_FAVOURITE,
  payload: id,
});
