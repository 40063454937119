import { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
  IonIcon,
  IonButton,
  IonReorderGroup,
  IonItem,
  IonReorder,
  IonList,
  IonLoading,
  IonToast,
} from "@ionic/react";
import {
  addOutline,
  eyeOutline,
  chevronForwardOutline,
  todayOutline,
  navigateOutline,
  arrowUndoOutline,
} from "ionicons/icons";
import styles from "./tour.module.scss";
import CardContainer from "../Tours/component/CardContainer/card-container.component";
import TourProperty from "./component/TourProperty/tour-property.component";
import AddShowing from "./component/AddShowing/add-showing.component";
import TourAttendees from "./component/TourAttendees/tour-attendees.component";
import TourDetails from "./component/TourDetails/tour-details.component";
import AgentDetails from "./component/AgentDetails/agent-details.component";
import QuickContact from "./component/QuickContact/quick-contact.component";
import {
  addClients,
  getSingleTour,
  removeClients,
  updateTour,
  updateTourListingOrder,
  updateTourListingItem,
  updateTourClientItem,
  fetchTourItems,
} from "../../amplify/graphql.utils";
import { HashLink } from "react-router-hash-link";
import LoadingFullPage from "../../components/Loading/loading-full-page.component";
import withAuthentication from "../../HOC/withAuthentication/with-authentication";
import { API, graphqlOperation } from "aws-amplify";
import _ from "lodash";
import {
  onUpdateTourItemByTourId,
  onUpdateTourByTourId,
} from "../../graphql/subscriptions";
import { getCurrentLocation } from "../../components/Map/map-utils";
import { buildAddress } from "../../utils/functions";

const Tour = ({
  match: {
    params: { tourId },
  },
}) => {
  const history = useHistory();
  const [showAttendees, setShowAttendees] = useState(false);
  const [showTourDetails, setShowTourDetails] = useState(false);
  const [showAgentDetails, setShowAgentDetails] = useState(false);
  const [showQuickContact, setShowQuickContact] = useState(false);
  const [tour, setTour] = useState();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState({ type: undefined, text: "" });
  const [showToast, setShowToast] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [originalSort, setOriginalSort] = useState([]);
  const [reverting, setReverting] = useState(false);

  const sortItems = async (updatedItems) => {
    await updatedItems.forEach(async (item, i) => {
      try {
        await updateTourListingOrder({ id: item.id, order: i + 1 });
      } catch (err) {
        setMsg({ type: "error", text: "Something went wrong!" });
        setShowToast(true);
      }
      setLoading(false);
    });
  };
  const reorder = async ({ detail: { complete } }) => {
    const updatedItems = complete(items);
    setItems(updatedItems);
    setLoading(true);
    sortItems(updatedItems);
  };

  const fetchTour = useCallback(async () => {
    let subscription;
    try {
      const tour = await getSingleTour(tourId);
      if (!tour) {
        history.replace("/tours");
        return;
      }
      setTour(tour);
      if (subscription) subscription.unsubscribe();
      subscription = await API.graphql(
        graphqlOperation(onUpdateTourByTourId, { id: tour.id })
      ).subscribe({ next: async () => fetchTour() });
      setItems(
        tour.tourItems.items.sort((a, b) => {
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        })
      );

      if (!originalSort) {
        const order = [];
        tour.tourItems.items.forEach((item) =>
          order.push({ id: item.id, order: item.order })
        );
        setOriginalSort(order);
      }
    } catch (err) {
      setMsg({ type: "error", text: "Something went wrong!" });
      setShowToast(true);
    }

    return () => subscription && subscription.unsubscribe();
  }, [history, tourId]);

  useEffect(() => {
    setItems([]);
    setAddresses([]);
    setTour(null);
    fetchTour();
  }, [fetchTour, tourId]);

  const updateAttendees = async ({
    client,
    guests,
    tempGuests,
    showingAgent,
    leadAgent,
  }) => {
    setLoading(true);
    const members = [client.id];
    [...guests, ...tempGuests].forEach((item) => members.push(item.id));

    const toAdd = _.differenceWith(
      members,
      tour.members ? [...tour.members] : [],
      _.isEqual
    );
    const toRemove = _.differenceWith(
      tour.members ? [...tour.members] : [],
      members,
      _.isEqual
    );

    try {
      const membersData = JSON.parse(tour.membersData);
      if (!membersData.find((m) => m.id === leadAgent.id)) {
        membersData.push({
          id: leadAgent.id,
          givenName: leadAgent.givenName,
          familyName: leadAgent.familyName,
          email: leadAgent.email,
          phone: leadAgent.phone,
          role: "Lead Agent",
        });
      } else {
        const index = membersData.findIndex((m) => m.id === leadAgent.id);
        if (membersData[index].role !== "Lead Agent") {
          membersData[index].role = "Lead Agent";
        }
      }

      if (!showingAgent || !membersData.find((m) => m.id === showingAgent.id)) {
        membersData.push({
          id: showingAgent.id,
          givenName: showingAgent.givenName,
          familyName: showingAgent.familyName,
          email: showingAgent.email,
          phone: showingAgent.phone,
          role: "Showing Agent",
        });
      } else {
        const index = membersData.findIndex((m) => m.id === showingAgent.id);
        if (membersData[index].role !== "Showing Agent") {
          membersData[index].role = "Showing Agent";
        }
      }

      if (!membersData.find((m) => m.id === client.id)) {
        membersData.push({
          id: client.id,
          givenName: client.givenName,
          familyName: client.familyName,
          email: client.email,
          phone: client.phone,
          role: "Client",
        });
      } else {
        const index = membersData.findIndex((m) => m.id === client.id);
        if (membersData[index].role !== "Client") {
          membersData[index].role = "Client";
        }
      }

      [...guests, ...tempGuests].forEach((g) => {
        if (!membersData.find((m) => m.id === g.id)) {
          membersData.push({
            id: g.id,
            givenName: g.givenName,
            familyName: g.familyName,
            email: g.email,
            phone: g.phone,
            role: "Additional Guest",
          });
        } else {
          const index = membersData.findIndex((m) => m.id === g.id);
          if (membersData[index].role !== "Additional Guest") {
            membersData[index].role = "Additional Guest";
          }
        }
      });
      await updateTour({
        id: tour.id,
        members,
        showingAgentId: showingAgent?.id,
        agentId: leadAgent.id,
        membersData: JSON.stringify(membersData),
      });

      await addClients({
        tourId: tour.id,
        agentId: leadAgent.id,
        clients: toAdd,
      });

      await removeClients(
        tour.clients.items.filter((item) => toRemove.includes(item.clientId))
      );
      if (tour.tourItems.items.length) {
        tour.tourItems.items.forEach(async (item) => {
          await updateTourListingItem({
            id: item.id,
            showingAgentId: showingAgent.id,
            agentId: leadAgent.id,
            members,
            membersData: JSON.stringify(membersData),
          });
        });
      }

      if (tour.clients.items.length > 0) {
        tour.clients.items
          .filter((item) => item.agentId !== leadAgent.id)
          .forEach(
            async (tourClient) =>
              await updateTourClientItem({
                id: tourClient.id,
                agentId: leadAgent.id,
              })
          );
      }
      fetchTour();
      setMsg({ type: "success", text: "Tour saved successfully." });
      setShowToast(true);
    } catch (err) {
      setMsg({ type: "error", text: "Something went wrong!" });
      setShowToast(true);
    }
    setLoading(false);
  };
  const updateTourDetails = async (updates) => {
    setLoading(true);
    try {
      await updateTour(updates);
      setMsg({ type: "success", text: "Tour saved successfully." });
      setShowToast(true);
    } catch (err) {
      setMsg({ type: "error", text: "Something went wrong!" });
      setShowToast(true);
    }
    setLoading(false);
  };

  const addAgent = async (leadAgent) => {
    try {
      const membersData = JSON.parse(tour.membersData);
      if (membersData && !membersData.find((m) => m.id === leadAgent.id))
        membersData.push({
          id: leadAgent.id,
          givenName: leadAgent.givenName,
          familyName: leadAgent.familyName,
          email: leadAgent.email,
          phone: leadAgent.phone,
          role: "Lead Agent",
        });
      await updateTour({
        id: tour.id,
        agentId: leadAgent.id,
        membersData: JSON.stringify(membersData),
      });
      setMsg({ type: "success", text: "Tour saved successfully." });
      setShowToast(true);
    } catch (err) {
      setMsg({ type: "error", text: "Something went wrong!" });
      setShowToast(true);
    }
  };

  const revertOrder = async () => {
    if (originalSort && originalSort.length) {
      setReverting(true);
      for (let i = 0; i < originalSort.length; i++) {
        await updateTourListingOrder({
          id: originalSort[i].id,
          order: originalSort[i].order,
        });
      }
      setLoading(true);
      await fetchTour(true);
      setReverting(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    let subscription;
    const subscribeItems = async () => {
      subscription = await API.graphql(
        graphqlOperation(onUpdateTourItemByTourId, { tourId })
      ).subscribe({
        next: async () => {
          const items = await fetchTourItems(tourId);
          setItems(
            items.sort((a, b) => {
              if (a.order > b.order) return 1;
              if (a.order < b.order) return -1;
              return 0;
            })
          );
        },
      });
    };

    if (items && items.length) {
      subscribeItems();
      if (originalSort.length !== items.length) {
        const order = [];
        tour.tourItems.items.forEach((item) =>
          order.push({ id: item.id, order: item.order })
        );
        setOriginalSort(order);
      }
    }

    return () => subscription && subscription.unsubscribe();
  }, [items, tourId]);

  const handleMapView = async () => {
    if (addresses.length) {
      const currentLocation = await getCurrentLocation();
      let locations = "";
      if (currentLocation)
        locations += `${currentLocation.latitude},${currentLocation.longitude}/`;

      if (tour.meetupLocation) locations += `${tour.meetupLocation}/`;
      addresses.forEach((item) => {
        locations += `${buildAddress(item.address, true)}/`;
      });

      window.open(
        `https://www.google.com/maps/dir/${locations.slice(
          0,
          locations.length - 1
        )}`,
        "_blank"
      );
    }
  };

  const addAddress = ({ address, mlsNumber }) => {
    if (!addresses.find((a) => a.mlsNumber === mlsNumber))
      setAddresses((old) => [...old, { mlsNumber, address }]);
  };

  return (
    <div className={styles.tour}>
      <IonLoading isOpen={loading} />
      <IonToast
        position="top"
        cssClass={msg.type === "error" ? styles.error : styles.success}
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={msg.text}
        buttons={[
          {
            side: "end",
            icon: "close",
            handler: () => setShowToast(false),
          },
        ]}
        duration={4000}
      />
      <div className={styles.nav}>
        <div onClick={() => history.push("/tours")} className={styles.btn}>
          All tours
        </div>
        <div className={styles.icon}>
          <IonIcon icon={chevronForwardOutline} />
        </div>
        <div className={styles.bold}>{tour && tour.title}</div>
      </div>
      <div>
        {tour ? (
          tour.clients && tour.showingAgent ? (
            <>
              <CardContainer>
                <div className={styles.header}>
                  <div className={styles.firstColumn}>
                    <div className={styles.icon}>
                      <IonIcon icon={todayOutline} />
                    </div>
                    <div className={styles.title}>Tour schedule</div>
                    <div className={styles.tag}>
                      <IonIcon icon={eyeOutline} className={styles.icon} />
                      <span>visible to client</span>
                    </div>
                  </div>
                  <div className={styles.secondColumn}>
                    <IonButton
                      className={`${styles.button} ${styles.directionsBtn}`}
                      disabled={!addresses.length}
                      onClick={handleMapView}
                    >
                      <IonIcon icon={navigateOutline} />
                      Full directions
                    </IonButton>
                    <HashLink to={`/tours/${tourId}#add-showing`}>
                      <IonButton
                        className={`${styles.button} ${styles.showingBtn}`}
                        disabled={
                          !tour || !tour.status || tour.status === "archived"
                        }
                      >
                        <IonIcon icon={addOutline} />
                        Add a showing
                      </IonButton>
                    </HashLink>
                  </div>
                </div>
                <div className={styles.items}>
                  <IonList className={styles.reorder}>
                    {!tour ? (
                      <LoadingFullPage />
                    ) : (
                      <IonReorderGroup
                        disabled={
                          !tour || !tour.status || tour.status === "archived"
                        }
                        onIonItemReorder={reorder}
                      >
                        {items.length > 0 &&
                          items.map((item, i) => (
                            <IonItem
                              key={item.id}
                              className={styles.item}
                              lines="none"
                            >
                              <TourProperty
                                item={item}
                                order={item.order}
                                reload={fetchTour}
                                addAddress={addAddress}
                              />
                              <IonReorder slot="start" />
                            </IonItem>
                          ))}
                      </IonReorderGroup>
                    )}
                  </IonList>
                </div>
                <div>
                  {originalSort && items && items.length > 0 && (
                    <div
                      className={styles.revertItem}
                      lines="none"
                      onClick={revertOrder}
                    >
                      <IonIcon
                        className={styles.revertIcon}
                        icon={arrowUndoOutline}
                        slot="start"
                      />
                      <div className={styles.revertLabel}>
                        Revert showings to original order
                      </div>
                    </div>
                  )}
                </div>
              </CardContainer>
              <div className={styles.options}>
                <div className={styles.left}>
                  <AddShowing
                    tour={{
                      id: tourId,
                      agentId: tour.agentId,
                      showingAgentId: tour.showingAgentId,
                      members: tour.members,
                      membersData: tour.membersData,
                      status: tour.status ? tour.status : "",
                    }}
                    reload={fetchTour}
                    nextListingOrder={tour && tour.tourItems.items.length + 1}
                  />
                </div>
                <div className={styles.right}>
                  <TourAttendees
                    isOpen={showAttendees}
                    tour={tour}
                    setIsOpen={setShowAttendees}
                    update={updateAttendees}
                    addAgent={addAgent}
                  />
                  <TourDetails
                    isOpen={showTourDetails}
                    setIsOpen={setShowTourDetails}
                    tour={tour}
                    update={updateTourDetails}
                  />
                  <AgentDetails
                    isOpen={showAgentDetails}
                    setIsOpen={setShowAgentDetails}
                    tour={tour}
                    update={updateTourDetails}
                    reload={fetchTour}
                  />
                  <QuickContact
                    isOpen={showQuickContact}
                    setIsOpen={setShowQuickContact}
                    tour={tour}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className={styles.incompleteTour}>
              <TourAttendees
                isOpen={true}
                tour={tour}
                setIsOpen={setShowAttendees}
                update={updateAttendees}
                addAgent={addAgent}
              />
              <div className={styles.title}>
                Select tour attendees to continue
              </div>
            </div>
          )
        ) : null}
      </div>
    </div>
  );
};
export default withAuthentication(Tour);
