import { useState } from "react";
import styles from "./rating.module.scss";
import { IonIcon } from "@ionic/react";
import { star } from "ionicons/icons";
import { hapticsImpact } from "../../utils/functions";

const Rating = ({
  review,
  handleReview,
  size = "md",
  disabled = false,
  readOnly = false,
}) => {
  const [rate, setRate] = useState();

  const handleMouse = (rate) => !disabled && setRate(rate);

  const handleSubmit = async (rate) => {
    await hapticsImpact();
    handleReview(rate);
  };

  return readOnly ? (
    <div className={`${styles.readOnly} ${styles[size + "readOnly"]}`}>
      <IonIcon
        icon={star}
        className={
          rate
            ? rate >= 1 && styles.filledStar
            : review && review.rate >= 1 && styles.filledStar
        }
      />
      <IonIcon
        icon={star}
        className={review && review.rate >= 2 && styles.filledStar}
      />
      <IonIcon
        icon={star}
        className={review && review.rate >= 3 && styles.filledStar}
      />
      <IonIcon
        icon={star}
        className={review && review.rate >= 4 && styles.filledStar}
      />
      <IonIcon
        icon={star}
        className={review && review.rate >= 5 && styles.filledStar}
      />
    </div>
  ) : (
    <div className={`${styles[size]}`}>
      <IonIcon
        icon={star}
        className={
          rate
            ? rate >= 1 && styles.filledStar
            : review && review.rate >= 1 && styles.filledStar
        }
        onClick={!disabled ? () => handleSubmit(1) : undefined}
        onMouseOver={!disabled ? () => handleMouse(1) : undefined}
        onMouseLeave={!disabled ? () => handleMouse() : undefined}
      />
      <IonIcon
        icon={star}
        className={
          rate
            ? rate >= 2 && styles.filledStar
            : review && review.rate >= 2 && styles.filledStar
        }
        onClick={!disabled ? () => handleSubmit(2) : undefined}
        onMouseOver={!disabled ? () => handleMouse(2) : undefined}
        onMouseLeave={!disabled ? () => handleMouse() : undefined}
      />
      <IonIcon
        icon={star}
        className={
          rate
            ? rate >= 3 && styles.filledStar
            : review && review.rate >= 3 && styles.filledStar
        }
        onClick={!disabled ? () => handleSubmit(3) : undefined}
        onMouseOver={!disabled ? () => handleMouse(3) : undefined}
        onMouseLeave={!disabled ? () => handleMouse() : undefined}
      />
      <IonIcon
        icon={star}
        className={
          rate
            ? rate >= 4 && styles.filledStar
            : review && review.rate >= 4 && styles.filledStar
        }
        onClick={!disabled ? () => handleSubmit(4) : undefined}
        onMouseOver={!disabled ? () => handleMouse(4) : undefined}
        onMouseLeave={!disabled ? () => handleMouse() : undefined}
      />
      <IonIcon
        icon={star}
        className={
          rate
            ? rate >= 5 && styles.filledStar
            : review && review.rate >= 5 && styles.filledStar
        }
        onClick={!disabled ? () => handleSubmit(5) : undefined}
        onMouseOver={!disabled ? () => handleMouse(5) : undefined}
        onMouseLeave={!disabled ? () => handleMouse() : undefined}
      />
    </div>
  );
};

export default Rating;
