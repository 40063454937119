import { useSelector } from "react-redux";
import {
  selectCurrentClient,
  selectClientError,
  selectClientLoading,
} from "../../redux/client/client.selectors";
import { selectCurrentUser } from "../../redux/user/user.selectors";

const withClient = (Component) => (props) => {
  const user = useSelector(selectCurrentUser);
  const client = useSelector(selectCurrentClient);
  const error = useSelector(selectClientError);
  const loading = useSelector(selectClientLoading);

  return (
    <Component
      user={user}
      currentClient={client}
      clientError={error}
      clientLoading={loading}
      {...props}
    />
  );
};

export default withClient;
