import { useState, useEffect } from "react";
import styles from "./beds-filter.module.scss";
import { IonRange } from "@ionic/react";
import withFilters from "../../../../../HOC/withFilters/with-filters";

const BedsFilter = ({
  filters: { minBeds, maxBeds },

  updateMultipleFilters,
}) => {
  const [beds, setBeds] = useState({
    lower: minBeds ? minBeds : 0,
    upper: maxBeds === "any" || !maxBeds ? 5 : maxBeds,
  });

  const handleOnChange = ({
    detail: {
      value: { lower, upper },
    },
  }) => {
    if (lower !== beds.lower || upper !== beds.upper) setBeds({ lower, upper });
  };

  useEffect(() => {
    updateMultipleFilters({
      minBeds: beds.lower,
      maxBeds: beds.upper === 5 ? "any" : beds.upper,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [beds]);

  return (
    <div className={styles.item}>
      <div className={styles.input}>
        <IonRange
          value={beds}
          min={0}
          max={5}
          step={1}
          snaps={true}
          ticks={true}
          dualKnobs
          onIonChange={handleOnChange}
          className={styles.range}
        />
        <div className={styles.pins}>
          <span className={styles.pin}>{beds.lower}</span>
          <span className={styles.pin}>
            {beds.upper === 5 ? "any" : beds.upper}
          </span>
        </div>
      </div>
    </div>
  );
};

export default withFilters(BedsFilter);
