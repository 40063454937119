import { useState } from "react";
import {
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonItem,
  IonButton,
  useIonPicker,
  IonToast,
  IonLabel,
} from "@ionic/react";
import moment from "moment";
import { capitalize } from "lodash";
import styles from "./profile.module.scss";
import "./profile-ionic.scss";
import CustomModalHeader from "../CustomModalHeader/custom-modal-header.component";
import ChangeProfileAlerts from "./components/change-profile-alerts.component";
import { helpCircleOutline } from "ionicons/icons";
import AlertSettingsAlerts from "./components/alert-settings-alerts.component";
import DeleteAccountAlert from "./components/delete-account-alert.component";
import { updateClient } from "../../../amplify/graphql.utils";
import { checkIsAgent } from "../../../amplify/auth.utils";

const Profile = ({ setShowModal, client, user, handleSignOut }) => {
  const [present] = useIonPicker();
  const { givenName, familyName, email, currency, measurement } = client;

  const notifications = JSON.parse(client.notifications);

  const profile = client.isAgent ? null : client.profile;
  const [showProfileAlerts, setShowProfileAlerts] = useState({
    id: false,
    password: false,
    fName: false,
    lName: false,
    purpose: false,
    type: false,
  });

  const [showAlertSettings, setShowAlertSettings] = useState({
    phone: false,
    style: false,
    type: false,
  });

  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  const renderInterest = () => {
    if (profile) {
      const interests = [
        profile.buyingSelling,
        profile.leasingRenting,
        profile.justLooking,
        profile.preConstruction,
      ];
      if (interests.filter((val) => val).length > 1) return "Multiple";

      if (profile.buyingSelling) {
        return "Buying or selling";
      } else if (profile.leasingRenting) {
        return "Renting or leasing";
      } else if (profile.preConstruction) {
        return "Pre-construction";
      } else if (profile.justLooking) {
        return "Just looking";
      } else {
        return "----";
      }
    }
  };

  const [error, setError] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);

  const updateCurrencyHandler = async (value) => {
    await updateClient({
      id: client.id,
      updates: { currency: value.toLowerCase() },
    });
    setShowSuccessToast(true);
  };

  const updateUnitsHandler = async (value) => {
    await updateClient({
      id: client.id,
      updates: { measurement: value.toLowerCase() },
    });
    setShowSuccessToast(true);
  };

  const renderTypes = () => {
    const type = {
      email: notifications.types.email,
      phone: notifications.types.phone,
      push: notifications.types.push,
    };
    if (!type.email && !type.phone && !type.email) return "---";
    let types = "";
    if (type.email) types += "Email ";
    if (type.phone) types += "Phone ";
    if (type.push) types += "Push ";
    return types;
  };

  return (
    <>
      <CustomModalHeader
        disable={true}
        hasBackbtn={true}
        title="Account"
        setShowModal={setShowModal}
      />
      <IonContent>
        <IonToast
          isOpen={showErrorToast}
          onDidDismiss={() => setShowErrorToast(false)}
          message={error}
          cssClass={styles.errorToast}
          position="top"
          buttons={[
            {
              side: "end",
              icon: "close",
              handler: () => setError(""),
            },
          ]}
          duration={4000}
        />
        <IonToast
          isOpen={showSuccessToast}
          onDidDismiss={() => setShowSuccessToast(false)}
          message="Your account has been updated!"
          cssClass={styles.successToast}
          position="top"
          buttons={[
            {
              side: "end",
              icon: "close",
              handler: () => setError(""),
            },
          ]}
          duration={3000}
        />

        <ChangeProfileAlerts
          client={client}
          clientProfile={profile}
          showProfileAlerts={showProfileAlerts}
          setShowProfileAlerts={setShowProfileAlerts}
        />

        <AlertSettingsAlerts
          client={client}
          notifications={notifications}
          showAlertSettings={showAlertSettings}
          setShowAlertSettings={setShowAlertSettings}
        />
        <DeleteAccountAlert
          client={client}
          user={user}
          showDeleteAlert={showDeleteAlert}
          setShowDeleteAlert={setShowDeleteAlert}
          setProfileModal={setShowModal}
          handleSignOut={handleSignOut}
        />

        <IonGrid className={styles.profileGrid}>
          <IonRow>
            <IonItem className={styles.items}>
              <IonCol>
                <div className={styles.title}>My profile</div>
                <div className={styles.date}>
                  Member since {moment(client.createdAt).format("MMM Do, YYYY")}
                </div>
              </IonCol>
              <IonCol className={styles.profileImg}>
                <div className={styles.imageContainer}>
                  <span className={styles.initials}>
                    {givenName.charAt(0).toUpperCase() +
                      familyName.charAt(0).toUpperCase()}
                  </span>
                </div>
                <div className={styles.name}>{givenName}</div>
              </IonCol>
            </IonItem>
          </IonRow>
          <IonRow>
            <IonItem className={styles.items}>
              <IonCol size="11">
                <div className={styles.label}>User ID</div>
                <IonLabel className={styles.value}>{email}</IonLabel>
              </IonCol>
              <IonCol className={styles.icon} size="2">
                <IonIcon
                  icon={helpCircleOutline}
                  onClick={() =>
                    setShowProfileAlerts({ ...showProfileAlerts, id: true })
                  }
                />
              </IonCol>
            </IonItem>
          </IonRow>
          <IonRow>
            <IonItem className={styles.items}>
              <IonCol>
                <div className={styles.label}>password</div>
                <div className={styles.value}>***********</div>
              </IonCol>
              <IonCol
                className={styles.edit}
                onClick={() =>
                  setShowProfileAlerts({ ...showProfileAlerts, password: true })
                }
              >
                Edit
              </IonCol>
            </IonItem>
          </IonRow>
          <IonRow>
            <IonItem className={styles.items}>
              <IonCol>
                <div className={styles.label}>Given name</div>
                <div className={styles.value}>{givenName}</div>
              </IonCol>
              <IonCol
                className={styles.edit}
                onClick={() =>
                  setShowProfileAlerts({ ...showProfileAlerts, fName: true })
                }
              >
                Edit
              </IonCol>
            </IonItem>
          </IonRow>
          <IonRow>
            <IonItem className={styles.items}>
              <IonCol>
                <div className={styles.label}>Family name</div>
                <div className={styles.value}>{familyName}</div>
              </IonCol>
              <IonCol
                className={styles.edit}
                onClick={() =>
                  setShowProfileAlerts({ ...showProfileAlerts, lName: true })
                }
              >
                Edit
              </IonCol>
            </IonItem>
          </IonRow>
          {client.profile && (
            <>
              <IonRow>
                <IonItem className={styles.items}>
                  <IonCol>
                    <div className={styles.label}>Account purpose</div>
                    <div className={styles.value}>{renderInterest()}</div>
                  </IonCol>
                  <IonCol
                    className={styles.edit}
                    onClick={() =>
                      setShowProfileAlerts({
                        ...showProfileAlerts,
                        purpose: true,
                      })
                    }
                  >
                    Edit
                  </IonCol>
                </IonItem>
              </IonRow>
              <IonRow>
                <IonItem className={`${styles.items} ${styles.lastOption}`}>
                  <IonCol>
                    <div className={styles.label}>Profile type</div>
                    <div className={styles.value}>
                      {client.profile?.isRealtor ? "Agent" : "Client"}
                    </div>
                  </IonCol>
                  <IonCol
                    className={styles.edit}
                    onClick={() =>
                      setShowProfileAlerts({ ...showProfileAlerts, type: true })
                    }
                  >
                    Edit
                  </IonCol>
                </IonItem>
              </IonRow>
            </>
          )}

          <div className={styles.line} />
          <IonRow>
            <IonItem className={styles.items}>
              <IonCol>
                <div className={styles.title}>General settings</div>
                <div className={styles.date}>
                  Customize your operational settings
                </div>
              </IonCol>
            </IonItem>
          </IonRow>
          <IonRow>
            <IonItem className={styles.items}>
              <IonCol>
                <div className={styles.label}>Currency</div>
                <div className={styles.value}>{currency.toUpperCase()}</div>
              </IonCol>
              <IonCol
                className={styles.edit}
                onClick={() =>
                  present({
                    buttons: [
                      {
                        text: "Cancel",
                      },
                      {
                        text: "Confirm",
                        handler: (selected) => {
                          updateCurrencyHandler(selected.currency.value);
                        },
                      },
                    ],
                    columns: [
                      {
                        name: "currency",
                        options: [
                          { text: "CAD - Canadian Dollar", value: "CAD" },
                          { text: "USD - US Dollar", value: "USD" },
                          { text: "AUD - Australian Dollar", value: "AUD" },
                          { text: "CNY - Chinese Renminbi", value: "CNY" },
                          { text: "EUR - Euro (European)", value: "EUR" },
                          { text: "GBP - UK Pound Sterling", value: "GBP" },
                          { text: "INR - Indian Rupee", value: "INR" },
                          { text: "JPY - Japanese Yen", value: "JPY" },
                          { text: "IRR - Iranian Rial", value: "IRR" },
                          { text: "MXN - Mexican Peso", value: "MXN" },
                        ],
                      },
                    ],
                  })
                }
              >
                Edit
              </IonCol>
            </IonItem>
          </IonRow>
          <IonRow>
            <IonItem className={`${styles.items} ${styles.lastOption}`}>
              <IonCol>
                <div className={styles.label}>Units of measure</div>
                <div className={styles.value}>{capitalize(measurement)}</div>
              </IonCol>
              <IonCol
                className={styles.edit}
                onClick={() =>
                  present({
                    buttons: [
                      {
                        text: "Cancel",
                      },
                      {
                        text: "Confirm",
                        handler: (selected) => {
                          updateUnitsHandler(selected.units.value);
                        },
                      },
                    ],
                    columns: [
                      {
                        name: "units",
                        options: [
                          { text: "Imperial", value: "Imperial" },
                          { text: "Metric", value: "Metric" },
                        ],
                      },
                    ],
                  })
                }
              >
                Edit
              </IonCol>
            </IonItem>
          </IonRow>
          <div className={styles.line} />
          <IonRow>
            <IonItem className={styles.items}>
              <IonCol>
                <div className={styles.title}>Alert settings</div>
                <div className={styles.date}>
                  Notifications for favourites and saved searches
                </div>
              </IonCol>
            </IonItem>
          </IonRow>
          <IonRow>
            <IonItem className={styles.items}>
              <IonCol>
                <div className={styles.label}>Notification phone</div>
                <div className={styles.value}>
                  {notifications.phone ? notifications.phone : "----"}
                </div>
              </IonCol>
              <IonCol
                className={styles.edit}
                onClick={() =>
                  setShowAlertSettings({ ...showAlertSettings, phone: true })
                }
              >
                Edit
              </IonCol>
            </IonItem>
          </IonRow>
          <IonRow>
            <IonItem className={styles.items}>
              <IonCol>
                <div className={styles.label}>Notification style</div>
                <div className={styles.value}>
                  {" "}
                  {notifications.schedule
                    ? capitalize(notifications.schedule)
                    : "----"}
                </div>
              </IonCol>
              <IonCol
                className={styles.edit}
                onClick={() =>
                  setShowAlertSettings({ ...showAlertSettings, style: true })
                }
              >
                Edit
              </IonCol>
            </IonItem>
          </IonRow>
          <IonRow>
            <IonItem className={styles.items}>
              <IonCol>
                <div className={styles.label}>Notification type</div>
                <div className={styles.value}>{renderTypes()}</div>
              </IonCol>
              <IonCol
                className={styles.edit}
                onClick={() =>
                  setShowAlertSettings({ ...showAlertSettings, type: true })
                }
              >
                Edit
              </IonCol>
            </IonItem>
          </IonRow>
          {!checkIsAgent(user) && (
            <IonRow>
              <IonCol>
                <IonButton
                  shape="round"
                  className={styles.button}
                  onClick={() => setShowDeleteAlert(true)}
                >
                  Delete account
                </IonButton>
              </IonCol>
            </IonRow>
          )}
        </IonGrid>
      </IonContent>
    </>
  );
};

export default Profile;
