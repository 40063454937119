import Select from "../../Form/Select/select.component";
import RangeFilter from "../../RangeFilter/range-filter.component";
import { maintenanceRange } from "../../Filters/data/filters.data";
import styles from "../filters-menu.module.scss";
import withFilters from "../../../HOC/withFilters/with-filters";
import _ from "lodash";
const VALID_TYPES = ["Condo townhouse", "Condo Apt"];

const MaintenanceFilterItem = ({
  filters: { propertyType },
  extraFilters: { maintenance },
  updateExtraFilters,
  buttonStyle,
  tabName,
  tab,
  setTab,
  disabled,
}) => {
  const onChangeHandler = ({ value }) => {
    if (value === "any") {
      updateExtraFilters({ maintenance: null });
    } else {
      updateExtraFilters({ maintenance: value });
    }
  };

  const show = () => {
    if (!propertyType) return false;
    const intersections = _.intersection(VALID_TYPES, propertyType).length;
    if (intersections === propertyType.length) return true;
    return false;
  };

  return show() ? (
    <div className={styles.item}>
      <Select
        buttonStyle={buttonStyle}
        title="Maintenance Fee"
        open={tab === tabName}
        onClick={() => setTab(tabName)}
        dropdown={false}
        disabled={disabled}
      />
      <RangeFilter
        type="currency"
        open={tab === tabName}
        range={maintenanceRange}
        value={{ min: null, max: maintenance }}
        onlyMax={true}
        dropdown={false}
        onChange={onChangeHandler}
        style={{ marginBottom: "1rem", width: "50%" }}
      />
    </div>
  ) : null;
};

export default withFilters(MaintenanceFilterItem);
