import { IonSegment, IonSegmentButton } from "@ionic/react";
import styles from "./tour-options-segment.module.scss";

const TourOptionsSegment = ({ tab, setTab }) => {
  return (
    <IonSegment
      mode="ios"
      value={tab}
      onIonChange={(e) => setTab(e.detail.value)}
      className={styles.segment}
    >
      <IonSegmentButton value="upcoming">Upcoming</IonSegmentButton>
      <IonSegmentButton value="past">Past</IonSegmentButton>
      <IonSegmentButton value="archived">Archived</IonSegmentButton>
    </IonSegment>
  );
};

export default TourOptionsSegment;
