import {
  VictoryLine,
  VictoryChart,
  VictoryTheme,
  VictoryVoronoiContainer,
  VictoryAxis,
  VictoryScatter,
  VictoryLabel,
} from "victory";
import styles from "./chart.module.scss";

const LineChart = ({ label, data, domain = 4, showY = true, style }) => {
  return domain > 0 ? (
    <div className={styles.line} style={style?.container}>
      <div className={styles.container}>
        {label && <div className={styles.label}>{label}</div>}
        <div className={styles.chartContainer} style={style?.chart}>
          <VictoryChart
            animate={
              showY && {
                duration: 1000,
                onLoad: { duration: 1000 },
              }
            }
            domainPadding={{ x: showY ? 15 : 35 }}
            theme={showY ? VictoryTheme.material : VictoryTheme.grayscale}
            domain={{ y: [0, domain] }}
            containerComponent={
              <VictoryVoronoiContainer
                mouseFollowTooltips
                voronoiDimension="x"
                disable={!showY}
                labels={({ datum }) => `  ${datum.x} score: ${datum.y}  `}
                style={{
                  touchAction: "auto",
                  userSelect: "auto",
                }}
              />
            }
          >
            {!showY && <VictoryAxis label="Years" />}
            {!showY && (
              <VictoryAxis dependentAxis invertAxis tickValues={[1, domain]} />
            )}
            <VictoryLine
              data={showY ? data : data.filter((item) => item.y > 0)}
              padding={0}
              style={{ labels: { display: "none" } }}
            />
            <VictoryScatter
              data={showY ? data : data.filter((item) => item.y > 0)}
              style={{
                data: { fill: "#7158ad" },
                labels: { fontWeight: 600, offset: 100 },
              }}
              size={5}
              labelComponent={<VictoryLabel dy={-20} />}
            />
          </VictoryChart>
        </div>
      </div>
    </div>
  ) : null;
};

export default LineChart;
