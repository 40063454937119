import { IonIcon } from "@ionic/react";
import { lockClosedOutline } from "ionicons/icons";
import { priceHistory } from "../../../../../../../../data/detailsPage";
import styles from "./price-history.module.scss";

const PriceHistory = ({
  user,
  historyDetails,
  signUpHandler,
  signInHandler,
  language,
}) => (
  <div className={styles.pricehistory}>
    {user ? (
      <div className={styles.infos}>
        <div className={styles.priceHeader}>
          <span>{priceHistory[language].price}</span>
          <span>{priceHistory[language].dateStart}</span>
          <span>{priceHistory[language].dateEnd}</span>
          <span>{priceHistory[language].soldPrice}</span>
          <span>{priceHistory[language].status}</span>
          <span>{priceHistory[language].id}</span>
        </div>
        <div className={styles.priceBody}>{historyDetails()} </div>
      </div>
    ) : (
      <div className={styles.lockHistory}>
        <div className={styles.icon}>
          <IonIcon icon={lockClosedOutline} />
        </div>
        <div className={styles.text}>
          {priceHistory[language].msg.please}{" "}
          <span onClick={signUpHandler}>
            {priceHistory[language].msg.register}
          </span>{" "}
          {priceHistory[language].msg.or}{" "}
          <span onClick={signInHandler}>
            {priceHistory[language].msg.login}
          </span>{" "}
          {priceHistory[language].msg.rest}
        </div>
      </div>
    )}
  </div>
);

export default PriceHistory;
