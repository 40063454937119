import { ReactComponent as HomeIcon } from "../../../assets/svg/listing.svg";
import { ReactComponent as FavoriteIcon } from "../../../assets/svg/star.svg";
import { ReactComponent as SearchIcon } from "../../../assets/svg/search.svg";
import { ReactComponent as WatchedIcon } from "../../../assets/svg/eye.svg";
import Button from "../../Form/Button/button.component";
import { welcome } from "../../../data/customModal";
import styles from "./welcome-modal.module.scss";

const WelcomeModal = ({ close, language }) => (
  <div className={styles.container}>
    <div className={styles.header}>
      <span className={styles.title}>{welcome[language].header}</span>
      <span className={styles.subtitle}>{welcome[language].subtitle}</span>
      <span
        className={styles.note}
        dangerouslySetInnerHTML={{ __html: welcome[language].note }}
      ></span>
    </div>
    <div className={styles.content}>
      <span className={styles.item}>
        <HomeIcon className={styles.icon} />
        <span className={styles.label}>{welcome[language].home}</span>
      </span>
      <span className={styles.item}>
        <FavoriteIcon
          className={styles.icon}
          style={{ strokeWidth: ".15rem" }}
        />
        <span
          className={styles.label}
          dangerouslySetInnerHTML={{ __html: welcome[language].favorite }}
        ></span>
      </span>
      <span className={styles.item}>
        <SearchIcon className={styles.icon} style={{ width: "3.1rem" }} />
        <span
          className={styles.label}
          dangerouslySetInnerHTML={{ __html: welcome[language].search }}
        ></span>
      </span>
      <span className={styles.item}>
        <WatchedIcon className={styles.icon} style={{ width: "3.4rem" }} />
        <span
          className={styles.label}
          dangerouslySetInnerHTML={{ __html: welcome[language].watch }}
        ></span>
      </span>
    </div>
    <div className={styles.button}>
      <Button
        title={welcome[language].button}
        type="purple"
        style={{ width: "100%", height: "5rem", border: "1px solid #7158ad" }}
        onClick={close}
      />
    </div>
  </div>
);

export default WelcomeModal;
