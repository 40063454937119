import { useState, useEffect, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  IonIcon,
  IonButton,
  IonLoading,
  IonItem,
  IonLabel,
  IonSkeletonText,
  IonSlides,
  IonSlide,
} from "@ionic/react";
import {
  addOutline,
  arrowForwardCircleOutline,
  caretBackOutline,
  caretForwardOutline,
  closeOutline,
  eyeOffOutline,
  starOutline,
  warningOutline,
} from "ionicons/icons";
import CardContainer from "../../../Tours/component/CardContainer/card-container.component";
import styles from "./add-showing.module.scss";
import Input from "../../../../components/Form/Input/input.component";
import useComponentVisible from "../../../../hooks/useComponentVisible";
import Select from "../../../../components/Form/Select/select.component";
import DropdownContainer from "../../../../components/Form/DropdownContainer/dropdown-container.component";
import { ranges } from "../../../Tours/component/NewTour/component/utils";
import debounce from "lodash/debounce";
import { getListing, getTourListing } from "../../../../api/repliers";
import SearchResult from "../../../../components/LocationSearch/components/search-result.component";
import {
  buildAddress,
  capitalize,
  getHighlightedLength,
  numberWithCommas,
} from "../../../../utils/functions";
import Map from "./components/map.component";
import {
  addListingToTour,
  getFavouriteItems,
} from "../../../../amplify/graphql.utils";
import { selectCurrentUser } from "../../../../redux/user/user.selectors";
import MapListingCard from "../../../../components/Map/components/MapListingCard/map-listing.card.component";

const regex = /^[a-zA-Z]\d+/;
const validRegex = /^[a-zA-Z]\d{7}/;

const AddShowing = ({ tour, reload, nextListingOrder }) => {
  const user = useSelector(selectCurrentUser);
  const [listing, setListing] = useState();
  const [term, setTerm] = useState("");
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [time, setTime] = useState({ start: undefined, end: undefined });
  const [status, setStatus] = useState();
  const [entryInfo, setEntryInfo] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [toggleFavourites, setToggleFavourites] = useState(false);
  const [favLoading, setFavLoading] = useState(false);
  const [favourites, setFavourites] = useState();
  const [slideOpts, setSlideOpts] = useState({
    spaceBetween:
      window.innerWidth > 20 ? 2.1 : window.innerWidth > 1250 ? 0 : 20,
    slidesPerView:
      window.innerWidth > 1500 ? 2.1 : window.innerWidth > 1250 ? 1.65 : 3.1,
    initialSlide: 0,
    centeredSlides: true,
    loop: true,
  });
  const slidesRef = useRef();
  const {
    ref: refInput,
    isOpen: isOpenInput,
    setIsOpen: setIsOpenInput,
  } = useComponentVisible(false);

  const {
    ref: refFrom,
    isOpen: isOpenFrom,
    setIsOpen: setIsOpenFrom,
  } = useComponentVisible(false);

  const {
    ref: refTo,
    isOpen: isOpenTo,
    setIsOpen: setIsOpenTo,
  } = useComponentVisible(false);

  const {
    ref: refStatus,
    isOpen: isOpenStatus,
    setIsOpen: setIsOpenStatus,
  } = useComponentVisible(false);

  const handleTermChange = (value, name) => {
    setTerm(value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetch = useCallback(
    debounce((term) => fetchResults(term), 500),
    []
  );

  const fetchResults = async (term) => {
    setLoading(true);

    if (regex.test(term)) {
      if (validRegex.test(term)) {
        try {
          const response = await getListing(term);
          setResults([response]);
        } catch (err) {
          setResults(null);
        }
      } else {
        setResults(null);
      }
    } else {
      try {
        const response = await getTourListing(term);

        setResults(response.length ? response : null);
      } catch (err) {
        setResults(null);
        setLoading(false);
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    if (term.length) {
      setLoading(true);
      debouncedFetch(term);
    }
  }, [term, debouncedFetch]);

  useEffect(() => {
    if (term && !loading) setIsOpenInput(true);
  }, [term, loading, setIsOpenInput]);

  useEffect(() => {
    let isCancelled = false;
    setTerm("");
    const fetchFavs = async () => {
      !isCancelled && setFavLoading(true);
      const favs = await getFavouriteItems();
      !isCancelled && setFavourites(favs);
      !isCancelled && setFavLoading(false);
    };
    !favourites && fetchFavs();
    return () => {
      isCancelled = true;
    };
  }, [favourites, tour]);

  const reset = () => {
    setListing(null);
    setTerm("");
    setTime({ start: undefined, end: undefined });
    setStatus(null);
    setEntryInfo("");
  };

  const handleSubmit = async () => {
    setSubmitLoading(true);

    try {
      await addListingToTour({
        owner: user.username,
        tourId: tour.id,
        agentId: tour.agentId,
        showingAgentId: tour.showingAgentId,
        members: tour.members,
        membersData: JSON.stringify(JSON.parse(tour.membersData)),
        mlsNumber: listing.mlsNumber,
        status,
        entryInfo,
        startTime: time.start,
        endTime: time.end,
        order: nextListingOrder,
      });
      reload();
      reset();
    } catch (err) {
      console.log(err);
    }
    setSubmitLoading(false);
  };

  const compareListings = (a, b) => {
    const aMatch = getHighlightedLength(
      `${a.address.city} - ${buildAddress(a.address)}`,
      term
    );

    const bMatch = getHighlightedLength(
      `${b.address.city} - ${buildAddress(b.address)}`,
      term
    );

    if (aMatch > bMatch) return -1;
    if (aMatch === bMatch) return 0;
    return 1;
  };

  const addFromFavourites = async (mls) => {
    setTerm("");
    setListing(null);
    setEntryInfo("");
    setStatus(null);
    setTime({ start: undefined, end: undefined });
    const listing = await getListing(mls);
    if (listing) {
      setListing(listing);
      setTerm(mls);
    }
  };

  const handlePrevSlide = async () => {
    const swiper = await slidesRef.current.getSwiper();
    if (swiper) await swiper.slidePrev();
  };

  const handleNextSlide = async () => {
    const swiper = await slidesRef.current.getSwiper();
    if (swiper) await swiper.slideNext();
  };

  useEffect(() => {
    const listener = window.addEventListener("resize", () => {
      if (window.innerWidth > 1500) {
        setSlideOpts({
          ...slideOpts,
          slidesPerView: 2.1,
          spaceBetween: 20,
        });
      } else if (window.innerWidth > 1225) {
        setSlideOpts({
          ...slideOpts,
          slidesPerView: 1.65,
          spaceBetween: 0,
        });
      } else {
        setSlideOpts({
          ...slideOpts,
          slidesPerView: 3.1,
          spaceBetween: 20,
        });
      }
    });

    return () => window.removeEventListener("resize", listener);
  }, []);

  return (
    <CardContainer>
      <IonLoading isOpen={submitLoading} />
      <div className={styles.showing} id="add-showing">
        <div className={styles.header}>
          <div className={styles.title}>
            <IonIcon icon={addOutline} /> <span>Add a showing</span>
          </div>
          <div className={styles.tag}>
            <IonIcon icon={eyeOffOutline} className={styles.icon} />
            <span>not visible to client</span>
          </div>
        </div>
        <div className={styles.inputs}>
          <div ref={refInput} className={styles.container}>
            {listing ? (
              <div className={styles.selected}>
                <span className={styles.label}>
                  Listing MLS #: <b>{listing.mlsNumber}</b>
                </span>
                <IonIcon
                  icon={closeOutline}
                  className={styles.icon}
                  onClick={() => {
                    setListing(null);
                    setTerm("");
                  }}
                />
              </div>
            ) : (
              <Input
                label="location or MLS #"
                placeholder="location or MLS #"
                mode="search"
                value={term}
                search
                clear={() => {
                  setTerm("");
                  setListing(null);
                }}
                onChange={handleTermChange}
                loading={loading}
                onClick={() => {
                  if (term && results) setIsOpenInput(true);
                }}
                disabled={tour.status === "archived"}
              />
            )}

            {isOpenInput && !listing && (
              <DropdownContainer
                open={term && !loading}
                style={{ maxHeight: "22rem" }}
              >
                {!results && (
                  <div className={styles.noResult}>
                    <IonIcon icon={warningOutline} className={styles.icon} />
                    No listing found. Try revising your search.
                  </div>
                )}
                {results &&
                  results.sort(compareListings).map((listing) => (
                    <SearchResult
                      key={listing.mlsNumber}
                      item={listing}
                      term={term}
                      type="listing"
                      onClick={() => {
                        setToggleFavourites(false);
                        setListing(listing);
                        setTerm(listing.mlsNumber);
                        setIsOpenInput(false);
                      }}
                    />
                  ))}
              </DropdownContainer>
            )}
          </div>
          <div className={styles.favourites}>
            <IonItem
              className={`${styles.item} ${styles.favourite}`}
              detail
              detailIcon={toggleFavourites ? closeOutline : starOutline}
              lines="none"
              onClick={() =>
                setToggleFavourites((toggleFavourites) => !toggleFavourites)
              }
            >
              <IonLabel>
                {toggleFavourites
                  ? "Close favourites"
                  : "Or choose from your favourites"}
              </IonLabel>
            </IonItem>
            {toggleFavourites && (
              <>
                {favLoading ? (
                  <IonSkeletonText
                    animated
                    style={{ height: "8rem", padding: "0 1.5rem" }}
                  />
                ) : favourites && favourites.length > 0 ? (
                  <div className={styles.slidesContainer}>
                    <IonIcon
                      icon={caretBackOutline}
                      className={styles.slidesIcon}
                      onClick={handlePrevSlide}
                    />
                    <IonSlides options={slideOpts} ref={slidesRef}>
                      {favourites.map((fav) => (
                        <IonSlide key={fav.id}>
                          <MapListingCard
                            listing={JSON.parse(fav.listing)}
                            webAddShowing
                            addShowing
                            handleAddShowing={() =>
                              addFromFavourites(fav.mlsNumber)
                            }
                          />
                        </IonSlide>
                      ))}
                    </IonSlides>
                    <IonIcon
                      icon={caretForwardOutline}
                      className={styles.slidesIcon}
                      onClick={handleNextSlide}
                    />
                  </div>
                ) : (
                  // <Slider hasScrollBar={true}>
                  //   {favourites.map((fav) => (
                  //     <div key={fav.id}>
                  //       <MapListingCard
                  //         listing={JSON.parse(fav.listing)}
                  //         webAddShowing
                  //         addShowing
                  //         handleAddShowing={() =>
                  //           addFromFavourites(fav.mlsNumber)
                  //         }
                  //       />
                  //     </div>
                  //   ))}
                  // </Slider>
                  <div className={styles.noFav}>
                    Search the listings or review your saved searches to find
                    new favourites.
                  </div>
                )}
              </>
            )}
          </div>

          {listing && (
            <div className={styles.times}>
              <div ref={refFrom} className={styles.container}>
                <Select
                  label="Start time"
                  title={time.start ? time.start : "Start time"}
                  style={{ height: "4rem", width: "100%" }}
                  buttonStyle={{ height: "100%" }}
                  open={isOpenFrom}
                  onClick={() => setIsOpenFrom(!isOpenFrom)}
                />
                <DropdownContainer
                  open={isOpenFrom}
                  style={{
                    position: "absolute",
                    top: "auto",
                    left: "auto",
                    // width: "20rem",
                    height: "15rem",
                  }}
                >
                  {ranges.map((range, index) => (
                    <div
                      key={index}
                      className={styles.range}
                      onClick={() => {
                        setTime({
                          ...time,
                          start: moment(range, "HH:mm:ss a").format("HH:mm"),
                        });
                        setIsOpenFrom(false);
                      }}
                    >
                      {range}
                    </div>
                  ))}
                </DropdownContainer>
              </div>
              <div className={styles.hyphen}>-</div>
              <div ref={refTo} className={styles.container}>
                <Select
                  label="End time"
                  title={time.end ? time.end : "End time"}
                  style={{ height: "4rem", width: "100%" }}
                  buttonStyle={{ height: "100%" }}
                  open={isOpenTo}
                  onClick={() => setIsOpenTo(!isOpenTo)}
                />
                <DropdownContainer
                  open={isOpenTo}
                  style={{
                    position: "absolute",
                    top: "auto",
                    left: "auto",
                    // width: "20rem",
                    height: "15rem",
                  }}
                >
                  {ranges.map((range, index) => (
                    <div
                      key={index}
                      className={styles.range}
                      onClick={() => {
                        setTime({
                          ...time,
                          end: moment(range, "HH:mm:ss a").format("HH:mm"),
                        });
                        setIsOpenTo(false);
                      }}
                    >
                      {range}
                    </div>
                  ))}
                </DropdownContainer>
              </div>
            </div>
          )}
          {listing && (
            <div ref={refStatus} className={styles.container}>
              <Select
                label="Showing status"
                title={status ? capitalize(status) : `Showing status`}
                style={{ height: "4rem", width: "100%" }}
                buttonStyle={{ height: "100%" }}
                open={isOpenStatus}
                onClick={() => setIsOpenStatus(!isOpenStatus)}
              />
              <DropdownContainer
                open={isOpenStatus}
                style={{
                  position: "absolute",
                  top: "auto",
                  left: "auto",
                  // width: "20rem",
                  height: "15rem",
                }}
              >
                <div>
                  <div
                    onClick={() => {
                      setStatus("requested");
                      setIsOpenStatus(false);
                    }}
                    className={styles.range}
                  >
                    Requested
                  </div>
                  <div
                    onClick={() => {
                      setStatus("confirmed");
                      setIsOpenStatus(false);
                    }}
                    className={styles.range}
                  >
                    Confirmed
                  </div>
                  <div
                    onClick={() => {
                      setStatus("completed");
                      setIsOpenStatus(false);
                    }}
                    className={styles.range}
                  >
                    Completed
                  </div>
                  <div
                    onClick={() => {
                      setStatus("skipped");
                      setIsOpenStatus(false);
                    }}
                    className={styles.range}
                  >
                    Skipped
                  </div>
                  <div
                    onClick={() => {
                      setStatus("cancelled");
                      setIsOpenStatus(false);
                    }}
                    className={styles.range}
                  >
                    Cancelled
                  </div>
                  <div
                    onClick={() => {
                      setStatus("rejected");
                      setIsOpenStatus(false);
                    }}
                    className={styles.range}
                  >
                    Rejected
                  </div>
                </div>
              </DropdownContainer>
            </div>
          )}
          {listing && (
            <div className={styles.entryContainer}>
              <Input
                name="entryInfo"
                label="Lockbox and entry info"
                // showLabel={false}
                placeholder="Lockbox and entry info"
                value={entryInfo}
                onChange={(value, name) => setEntryInfo(value)}
              />
            </div>
          )}
        </div>
        {listing && (
          <div className={`${styles.map} ${listing && styles.mapTransparent}`}>
            <Map location={listing.map} />
          </div>
        )}
        {listing && (
          <div className={styles.details}>
            <div>
              <span>Address: </span>
              <span className={styles.bold}>
                {buildAddress(listing.address)}
              </span>
            </div>
            <div>
              <span>MLS #: </span>
              <span className={styles.bold}>
                {" "}
                {listing.mlsNumber}{" "}
                <IonIcon
                  icon={arrowForwardCircleOutline}
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    window.open(`/listings/${listing.mlsNumber}`, "_blank")
                  }
                />{" "}
              </span>
            </div>
            <div>
              <span>Price: </span>
              <span className={styles.bold}>
                ${numberWithCommas(listing.listPrice)}
              </span>
            </div>
            <div>
              <span>Beds: </span>
              <span className={styles.bold}>
                {listing.details.numBedrooms}
              </span>{" "}
              <span> | </span>
              <span>Baths: </span>
              <span className={styles.bold}>
                {listing.details.numBathrooms}
              </span>{" "}
              <span> | </span>
              <span>Parking: </span>
              <span className={styles.bold}>
                {" "}
                {listing.details.numParkingSpaces !== "0.0"
                  ? listing.details.numParkingSpaces
                  : listing.details.numGarageSpaces !== "0.0"
                  ? listing.details.numGarageSpaces
                  : "-"}
              </span>
            </div>
            <div>
              <span>Property type: </span>
              <span className={styles.bold}>
                {listing.details.propertyType}
              </span>
            </div>
            <div>
              <span>Listing brokerage: </span>
              <span className={styles.bold}>
                {listing.office.brokerageName}
              </span>
            </div>
            <div>
              <span>Listing agent: </span>
              <span className={styles.bold}>{listing.agents.flat(", ")}</span>
            </div>

            <IonButton
              className={styles.button}
              shape="round"
              expand="block"
              // disabled={!time.start || !time.end || !status}
              onClick={handleSubmit}
            >
              <IonIcon icon={addOutline} />
              Add showing to tour
            </IonButton>
          </div>
        )}
      </div>
    </CardContainer>
  );
};

export default AddShowing;
