import React from "react";
import moment from "moment";
import { IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { KeyboardDatePicker } from "@material-ui/pickers";
import Select from "../Form/Select/select.component";
import DropdownContainer from "../Form/DropdownContainer/dropdown-container.component";
import styles from "./date-time-picker.module.scss";
import { ranges } from "./utils";
import useComponentVisible from "../../hooks/useComponentVisible";
import "./date-picker.scss";

const DateTimePicker = ({ value, onChange, onDelete }) => {
  const currentDay = moment().format("L");
  const { index, date, from, to } = value;
  const {
    ref: refFrom,
    isOpen: isOpenFrom,
    setIsOpen: setIsOpenFrom,
  } = useComponentVisible(false);
  const {
    ref: refTo,
    isOpen: isOpenTo,
    setIsOpen: setIsOpenTo,
  } = useComponentVisible(false);

  const handleDateChange = (date) => {
    if (date && date._d && date._isValid)
      onChange({ ...value, date: moment(date).toISOString().substring(0, 10) });
  };

  const handleFromChange = (from) => {
    onChange({ ...value, from });
  };

  const handleToChange = (to) => {
    onChange({ ...value, to });
  };

  const getRange = (type) => {
    const currentMoment = moment().format("LT");
    const currentTime = currentMoment.split(" ")[0].split(":");
    const currentHour = +currentTime[0];
    const currentMinute = +currentTime[1];

    if (type === "to") {
      if (!from) {
        if (from === "") {
          return false;
        } else {
          return ranges;
        }
      }
      const h1 = from.split(" ")[0].split(":");
      let h1Hour = +h1[0];
      let h1Minute = +h1[1];
      if (from.includes("am")) {
        return ranges.filter((r) => {
          if (r.includes("pm")) return true;
          const h2 = r.split(" ")[0].split(":");
          let h2Hour = +h2[0];
          let h2Minute = +h2[1];
          if (h2Hour > h1Hour) return true;
          if (h1Hour === h2Hour && h2Minute > h1Minute) return true;
          return false;
        });
      } else {
        return ranges.filter((r) => {
          if (r.includes("am")) return false;
          const h2 = r.split(" ")[0].split(":");
          let h2Hour = +h2[0];
          let h2Minute = +h2[1];
          if (h2Hour < 12) h2Hour += 12;
          if (h1Hour < 12) h1Hour += 12;
          if (h1Hour < h2Hour) return true;
          if (h1Hour === h2Hour && h2Minute > h1Minute) return true;
          return false;
        });
      }
    } else {
      if (!to) {
        if (currentDay !== moment(date).format("L")) {
          return ranges;
        } else {
          return ranges.filter((r) => {
            const r1 = r.split(" ")[0].split(":");
            let r1Hour = +r1[0];
            let r1Minute = +r1[1];
            if (currentMoment.includes("AM")) {
              if (currentHour < r1Hour) return true;
              if (currentHour === r1Hour && currentMinute < r1Minute)
                return true;
              if (currentHour > r1Hour && r.includes("pm")) return true;
            } else if (currentMoment.includes("PM")) {
              if (currentHour !== 12) {
                if (r1Hour === 12) return false;
                if (currentHour < r1Hour && r.includes("pm")) return true;
                if (currentHour === r1Hour && currentMinute < r1Minute)
                  return true;
              } else {
                if (currentHour < r1Hour) return true;
                if (currentHour === r1Hour && currentMinute < r1Minute)
                  return true;
                if (currentHour > r1Hour && r.includes("pm")) return true;
              }
            }
          });
        }
      }

      const h1 = to.split(" ")[0].split(":");
      let h1Hour = +h1[0];
      let h1Minute = +h1[1];
      if (to.includes("am")) {
        return ranges.filter((r) => {
          if (r.includes("pm")) return false;
          const h2 = r.split(" ")[0].split(":");
          let h2Hour = +h2[0];
          let h2Minute = +h2[1];
          if (h2Hour < h1Hour) return true;
          if (h1Hour === h2Hour && h2Minute < h1Minute) return true;
          return false;
        });
      } else {
        return ranges.filter((r) => {
          if (r.includes("am")) return true;
          const h2 = r.split(" ")[0].split(":");
          let h2Hour = +h2[0];
          let h2Minute = +h2[1];
          if (h2Hour < 12) h2Hour += 12;
          if (h1Hour < 12) h1Hour += 12;
          if (h1Hour > h2Hour) return true;
          if (h1Hour === h2Hour && h2Minute < h1Minute) return true;
          return false;
        });
      }
    }
  };

  return (
    <div className={styles.datepicker}>
      <div>
        <KeyboardDatePicker
          disablePast={true}
          autoOk
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          required
          // format="MM/d/yyyy"
          value={date}
          InputAdornmentProps={{ position: "start" }}
          onChange={handleDateChange}
          style={{ padding: "0", width: "20rem" }}
        />
      </div>
      <div ref={refFrom}>
        <Select
          label=""
          title={from}
          style={{ height: "100%", width: "12rem" }}
          buttonStyle={{ height: "100%" }}
          open={isOpenFrom}
          onClick={() => setIsOpenFrom(!isOpenFrom)}
        />
        <DropdownContainer
          open={isOpenFrom}
          style={{
            top: "auto",
            left: "auto",
            width: "auto",
            height: "15rem",
          }}
        >
          {getRange("from").length > 0 ? (
            getRange("from").map((range, index) => (
              <div
                key={index}
                className={styles.range}
                onClick={() => {
                  handleFromChange(range);
                  setIsOpenFrom(false);
                }}
              >
                {range}
              </div>
            ))
          ) : (
            <div className={styles.text}>Please choose another date.</div>
          )}
        </DropdownContainer>
      </div>
      <div className={styles.to}>to</div>
      <div ref={refTo}>
        <Select
          label=""
          title={to}
          style={{ height: "100%", width: "12rem" }}
          buttonStyle={{ height: "100%" }}
          open={isOpenTo}
          onClick={() => setIsOpenTo(!isOpenTo)}
        />
        <DropdownContainer
          open={isOpenTo}
          style={{
            top: "auto",
            left: "auto",
            width: "auto",
            height: "15rem",
          }}
        >
          {getRange("to").length > 0 ? (
            getRange("to").map((range, index) => (
              <div
                key={index}
                className={styles.range}
                onClick={() => {
                  handleToChange(range);
                  setIsOpenTo(false);
                }}
              >
                {range}
              </div>
            ))
          ) : (
            <div className={styles.text}>Please choose a start time.</div>
          )}
        </DropdownContainer>
      </div>
      <div className={styles.closeIcon} onClick={() => onDelete(index)}>
        <IonIcon icon={closeOutline} />
      </div>
    </div>
  );
};

export default DateTimePicker;
