import { LocationActionTypes } from "./locations.types";

const INITIAL_STATE = {
  locations: null,
};

const mapReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LocationActionTypes.FETCH_LOCATIONS_START:
      return { ...state };
    case LocationActionTypes.FETCH_LOCATIONS_SUCCESS:
      return {
        ...state,
        locations: action.payload,
      };
    case LocationActionTypes.FETCH_LOCATIONS_FAILURE:
      return { ...state };
    default:
      return state;
  }
};

export default mapReducer;
