import { useState } from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonButton,
  getPlatforms,
} from "@ionic/react";
import styles from "./listing-footer.module.scss";
import { shareSocialOutline } from "ionicons/icons";
import RequestForm from "../../../../components/CustomModals/RequestForm/request-form-component";
import ShareModal from "../../../../components/ShareModal/share-modal.component";
import { SocialSharing } from "@ionic-native/social-sharing";
import numeral from "numeral";
import { useLocation } from "react-router-dom";

const IMAGE_API = "https://cdn.repliers.io";

const ListingFooter = ({
  listing,
  listing: {
    listPrice,
    images,
    details: { numBedrooms, propertyType },
    address: { unitNumber, streetName, streetNumber, streetSuffix, area },
  },
  parentRef,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const url = useLocation().pathname;

  const handleShare = async () => {
    if (!getPlatforms().includes("capacitor")) {
      setShowShare(true);
    } else {
      const data =
        numBedrooms +
        " bed " +
        propertyType +
        " at " +
        unitNumber +
        " - " +
        streetNumber +
        " " +
        streetName +
        " " +
        streetSuffix +
        ", " +
        area +
        " for " +
        numeral(listPrice).format("$1,000,000");
      const text = `Check this listing out on Ettie!`;

      let options = {
        message: `${text} ${data}`,
        subject: text,
        url: `https://ettie.ai${url}`,
        chooserTitle: "Pick an app",
      };

      let optionsWithImages = { ...options };

      optionsWithImages.files = images[0] ? [`${IMAGE_API}/${images[0]}`] : [];
      SocialSharing.shareWithOptions(optionsWithImages).catch((err) =>
        SocialSharing.shareWithOptions(options)
      );
    }
  };

  return (
    <>
      {showModal && (
        <RequestForm
          showModal={showModal}
          setShowModal={setShowModal}
          listing={listing}
          parentRef={parentRef}
        />
      )}
      {showShare && (
        <ShareModal
          showModal={showShare}
          setShowModal={setShowShare}
          listing={listing}
          parentRef={parentRef}
        />
      )}
      <IonGrid className={styles.listingFooter}>
        <IonRow>
          <IonCol className={styles.icon}>
            <IonIcon icon={shareSocialOutline} onClick={handleShare} />
          </IonCol>
          <IonCol className={styles.icon}>
            <IonButton
              shape="round"
              className={styles.button}
              onClick={() => setShowModal(true)}
            >
              Contact or request viewing
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

export default ListingFooter;
