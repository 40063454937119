import React from "react";
import { IonIcon } from "@ionic/react";
import { arrowForwardCircleOutline } from "ionicons/icons";
import styles from "./quick-contact.module.scss";

const QuickContact = ({ quickContact: { leadingAgent, showingAgent } }) => {
  return (
    <div className={styles.quickContact}>
      <div>
        <span className={styles.bold}>Leading Agent</span>
        <span>{leadingAgent.name}</span>
        <span>{leadingAgent.phone}</span>
        <span
          className={styles.email}
          onClick={() => window.open(`mailto:${leadingAgent.email}`, "_blank")}
        >
          {leadingAgent.email} <IonIcon icon={arrowForwardCircleOutline} />
        </span>
      </div>
      {showingAgent && (
        <div>
          <span className={styles.bold}>Showing Agent</span>
          <span>{showingAgent.name}</span>
          <span>{showingAgent.phone}</span>
          <span
            className={styles.email}
            onClick={() =>
              window.open(`mailto:${showingAgent.email}`, "_blank")
            }
          >
            {showingAgent.email} <IonIcon icon={arrowForwardCircleOutline} />
          </span>
        </div>
      )}
    </div>
  );
};

export default QuickContact;
