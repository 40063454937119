import React from "react";
import { IonIcon } from "@ionic/react";
import { closeOutline, peopleOutline } from "ionicons/icons";
import styles from "./share-tour-modal.module.scss";
import Button from "../../Form/Button/button.component";

const ShareTourModal = ({ close }) => {
  return (
    <div className={styles.container}>
      <div className={styles.iconContainer}>
        <IonIcon icon={peopleOutline} className={styles.icon} />
      </div>
      <div className={styles.title}>Share with client</div>
      <div className={styles.subtitle}>
        This tour has been shared. All further saved changes will be updated
        live in their tour.
      </div>
      <div className={styles.btn}>
        <Button
          onClick={close}
          title="Close"
          type="clear"
          style={{ color: "#707070" }}
        >
          <IonIcon icon={closeOutline} />
        </Button>
      </div>
    </div>
  );
};

export default ShareTourModal;
