import { takeLatest, call, put, all } from "redux-saga/effects";
import { MapActionTypes } from "./map.types";
import { fetchListingsSuccess, fetchListingsFailure } from "./map.actions";
import { getMapListings } from "../../api/repliers";

export function* fetchListingsAsync({ payload }) {
  try {
    const listings = yield call(getMapListings, payload);
    yield put(fetchListingsSuccess(listings));
  } catch (error) {
    yield put(fetchListingsFailure(error.message));
  }
}
export function* fetchListingsStart() {
  yield takeLatest(MapActionTypes.FETCH_LISTINGS_START, fetchListingsAsync);
}
export function* mapSagas() {
  yield all([call(fetchListingsStart)]);
}
