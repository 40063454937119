import { Link } from "react-router-dom";
import styles from "./quick-searches.module.scss";

const QuickSearches = () => {
  return (
    <div className={styles.container}>
      <div className={styles.item}>
        <div className={styles.title}>Toronto</div>
        <span className={styles.search}>
          <Link to="/listings?area=Toronto&propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=sale">
            Toronto homes for sale
          </Link>
        </span>
        <span className={styles.search}>
          <Link to="/listings?area=Toronto&propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=sale">
            Toronto houses for sale
          </Link>
        </span>
        <span className={styles.search}>
          <Link to="/listings?area=Toronto&propertyType=Condo Apt,Condo townhouse&type=sale">
            Toronto condos for sale
          </Link>
        </span>
        <span className={styles.search}>
          <Link to="/listings?area=Toronto&propertyType=Att/Row/Twnhouse&type=sale">
            Toronto townhouses for sale
          </Link>
        </span>
        <span className={styles.search}>
          <Link to="/listings?area=Toronto&propertyType=Detached&type=sale">
            Toronto detached homes for sale
          </Link>
        </span>
        <span className={styles.search}>
          <Link to="/listings?area=Toronto&propertyType=Semi-detached&type=sale">
            Toronto semi-detached homes for sale
          </Link>
        </span>
        <span className={styles.search}>
          <Link to="/listings?area=Toronto&propertyType=Condo Apt,Condo townhouse&type=lease">
            Toronto condos for rent
          </Link>
        </span>
        <span className={styles.search}>
          <Link to="/listings?area=Toronto&propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=lease">
            Toronto houses for rent
          </Link>
        </span>
        <span className={styles.search}>
          <Link to="/listings?area=Toronto&propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=lease">
            Toronto homes for rent
          </Link>
        </span>
        <span className={styles.search}>
          <Link to="/listings?area=Toronto&propertyType=Condo Apt,Condo townhouse&type=sale&lat=43.642567&lng=-79.387054">
            Downtown Toronto condos for sale
          </Link>
        </span>
        <span className={styles.search}>
          <Link to="/listings?area=Toronto&propertyType=Condo Apt,Condo townhouse&type=lease&lat=43.642567&lng=-79.387054">
            Downtown Toronto condos for rent
          </Link>
        </span>
        <span className={styles.search}>
          <Link to="/listings?propertyType=Condo Apt,Condo townhouse&type=sale&lat=43.7615&lng=-79.4111">
            North York condos for sale
          </Link>
        </span>
        <span className={styles.search}>
          <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=sale&lat=43.7615&lng=-79.4111">
            North York houses for sale
          </Link>
        </span>
        <span className={styles.search}>
          <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=sale&lat=43.6205&lng=-79.5132">
            Etobicoke homes for sale
          </Link>
        </span>
        <span className={styles.search}>
          <Link to="/listings?propertyType=Condo Apt,Condo townhouse&type=lease&lat=43.6205&lng=-79.5132">
            Etobicoke condos for rent
          </Link>
        </span>
      </div>
      <div className={styles.item}>
        <div className={styles.title}>GTA</div>
        <span className={styles.search}>
          <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=sale&area=Durham&city=Ajax&lat=43.8509&lng=-79.0204">
            Ajax homes for sale
          </Link>
        </span>
        <span className={styles.search}>
          <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=sale&area=Peel&city=Brampton&lat=43.7315&lng=-79.7624">
            Brampton homes for sale
          </Link>
        </span>
        <span className={styles.search}>
          <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=sale&area=Halton&city=Burlington&lat=43.3255&lng=-79.7990">
            Burlington homes for sale
          </Link>
        </span>
        <span className={styles.search}>
          <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=sale&area=York&city=Markham&lat=43.8561&lng=-79.3370">
            Markham homes for sale
          </Link>
        </span>
        <span className={styles.search}>
          <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=lease&area=York&city=Markham&lat=43.8561&lng=-79.3370">
            Markham homes for rent
          </Link>
        </span>
        <span className={styles.search}>
          <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=sale&area=Peel&city=Mississauga&lat=43.5890&lng=-79.6441">
            Mississauga homes for sale
          </Link>
        </span>
        <span className={styles.search}>
          <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=lease&area=Peel&city=Mississauga&lat=43.5890&lng=-79.6441">
            Mississauga homes for rent
          </Link>
        </span>
        <span className={styles.search}>
          <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=sale&area=Halton&city=Oakville&lat=43.4675&lng=-79.6877">
            Oakville homes for sale
          </Link>
        </span>
        <span className={styles.search}>
          <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=lease&area=Halton&city=Oakville&lat=43.4675&lng=-79.6877">
            Oakville homes for rent
          </Link>
        </span>
        <span className={styles.search}>
          <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=sale&area=Durham&city=Oshawa&lat=43.8971&lng=-79.8658">
            Oshawa homes for sale
          </Link>
        </span>
        <span className={styles.search}>
          <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=sale&area=York&city=Richmond Hill&lat=43.8828&lng=-79.4403">
            Richmond Hill homes for sale
          </Link>
        </span>
        <span className={styles.search}>
          <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=lease&area=York&city=Richmond Hill&lat=43.8828&lng=-79.4403">
            Richmond Hill homes for rent
          </Link>
        </span>
        <span className={styles.search}>
          <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=sale&area=York&city=Vaughan&lat=43.837208&lng=-79.508278">
            Vaughan homes for sale
          </Link>
        </span>
        <span className={styles.search}>
          <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=lease&area=York&city=Vaughan&lat=43.837208&lng=-79.508278">
            Vaughan homes for rent
          </Link>
        </span>
        <span className={styles.search}>
          <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=sale&area=Durham&city=Whitby&lat=43.897545&lng=-78.942932">
            Whitby homes for sale
          </Link>
        </span>
      </div>
      <div className={styles.item}>
        <div className={styles.title}>Keyword</div>
        <span className={styles.search}>
          <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=sale">
            Houses for sale
          </Link>
        </span>
        <span className={styles.search}>
          <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=sale">
            Homes for sale
          </Link>
        </span>
        <span className={styles.search}>
          <Link to="/listings?propertyType=Detached&type=sale">
            Detached homes for sale
          </Link>
        </span>
        <span className={styles.search}>
          <Link to="/listings?propertyType=Semi-detached&type=sale">
            Semi-detached homes for sale
          </Link>
        </span>
        <span className={styles.search}>
          <Link to="/listings?propertyType=Condo Apt,Condo townhouse&type=sale">
            Condos for sale
          </Link>
        </span>
        <span className={styles.search}>
          <Link to="/listings?propertyType=Condo townhouse,Att/Row/Twnhouse&type=sale">
            Townhouses for sale
          </Link>
        </span>
        <span className={styles.search}>
          <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=sale&minBeds=4&maxBeds=4">
            4-bed homes
          </Link>
        </span>
        <span className={styles.search}>
          <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=sale&minBeds=3&maxBeds=3">
            3-bed homes
          </Link>
        </span>
        <span className={styles.search}>
          <Link to="/listings?propertyType=Condo Apt&type=sale&minSqft=1000">
            Penthouse condos
          </Link>
        </span>
        <span className={styles.search}>
          <Link to="/listings?propertyType=Condo Apt,Condo townhouse&type=sale&minBeds=3&maxBeds=3">
            3-bed condos
          </Link>
        </span>
        <span className={styles.search}>
          <Link to="/listings?propertyType=Condo Apt,Condo townhouse&type=sale&minBeds=2&maxBeds=2">
            2-bed condos
          </Link>
        </span>
        <span className={styles.search}>
          <Link to="/listings?propertyType=Condo Apt,Condo townhouse&type=sale&minBeds=1&maxBeds=1">
            1-bed condos
          </Link>
        </span>
      </div>
      <div className={styles.item}>
        <div className={styles.title}>Price</div>
        <span className={styles.search}>
          <Link to="/listings?propertyType=Condo Apt,Condo townhouse&type=sale&minPrice=0&maxPrice=500000">
            Condos under $500k
          </Link>
        </span>
        <span className={styles.search}>
          <Link to="/listings?propertyType=Condo Apt,Condo townhouse&type=sale&minPrice=0&maxPrice=700000&lat=43.642567&lng=-79.387054">
            Downtown Toronto condos under $700k
          </Link>
        </span>
        <span className={styles.search}>
          <Link to="/listings?propertyType=Detached&type=sale&minPrice=0&maxPrice=1000000&area=Toronto&city=Toronto">
            Toronto detached homes under $1M
          </Link>
        </span>
        <span className={styles.search}>
          <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=sale&minPrice=0&maxPrice=1000000">
            Houses under $1M
          </Link>
        </span>
        <span className={styles.search}>
          <Link to="/listings?propertyType=Condo Apt,Condo townhouse&type=sale&minPrice=0&maxPrice=1000000">
            Condos under $1M
          </Link>
        </span>
        <span className={styles.search}>
          <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=sale&minPrice=3500000">
            Most expensive houses
          </Link>
        </span>
        <span className={styles.search}>
          <Link to="/listings?propertyType=Condo Apt,Condo townhouse&type=sale&minPrice=2000000">
            Most expensive condos
          </Link>
        </span>
        <span className={styles.search}>
          <Link to="/listings?propertyType=Condo Apt,Condo townhouse&type=sale&minPrice=0&maxPrice=750000&area=Toronto&city=Toronto">
            Most affordable condos in Toronto
          </Link>
        </span>
        <span className={styles.search}>
          <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=sale&minPrice=0&maxPrice=1000000&area=Toronto&city=Toronto">
            Most affordable houses in Toronto
          </Link>
        </span>
        <span className={styles.search}>
          <Link to="/listings?propertyType=Condo Apt,Condo townhouse&type=sale&minPrice=0&maxPrice=700000">
            Most affordable condos in GTA
          </Link>
        </span>
        <span className={styles.search}>
          <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=sale&minPrice=0&maxPrice=800000">
            Most affordable houses in GTA
          </Link>
        </span>
        <span className={styles.search}>
          <Link to="/listings?propertyType=Condo Apt,Condo townhouse&type=sale&minPrice=2500000">
            Luxury condos
          </Link>
        </span>
        <span className={styles.search}>
          <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=sale&minPrice=5000000">
            Luxury houses
          </Link>
        </span>
      </div>
    </div>
  );
};

export default QuickSearches;
