import TourActionTypes from "./tour.types";

export const fetchToursStart = () => ({
  type: TourActionTypes.FETCH_TOURS_START,
});

export const fetchToursSuccess = (tours) => ({
  type: TourActionTypes.FETCH_TOURS_SUCCESS,
  payload: tours,
});

export const fetchToursFailure = (error) => ({
  type: TourActionTypes.FETCH_TOURS_FAILURE,
  payload: error,
});

export const addTourStart = (tour) => ({
  type: TourActionTypes.ADD_TOUR_START,
  payload: tour,
});

export const addTourSuccess = (tour) => ({
  type: TourActionTypes.ADD_TOUR_SUCCESS,
  payload: tour,
});

export const addTourFailure = (error) => ({
  type: TourActionTypes.ADD_TOUR_FAILURE,
  payload: error,
});

export const updateTourStart = (tour) => ({
  type: TourActionTypes.UPDATE_TOUR_START,
  payload: tour,
});

export const updateTourSuccess = (tour) => ({
  type: TourActionTypes.UPDATE_TOUR_SUCCESS,
  payload: tour,
});

export const updateTourFailure = (error) => ({
  type: TourActionTypes.UPDATE_TOUR_FAILURE,
  payload: error,
});

export const addClientsToTourStart = (payload) => ({
  type: TourActionTypes.ADD_CLIENTS_START,
  payload,
});

export const addClientsToTourSuccess = () => ({
  type: TourActionTypes.ADD_CLIENTS_SUCCESS,
});

export const addClientsToTourFailure = (error) => ({
  type: TourActionTypes.ADD_CLIENTS_FAILURE,
  payload: error,
});

export const clearCurrentTour = () => ({
  type: TourActionTypes.CLEAR_CURRENT_TOUR,
});
