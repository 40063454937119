import { useSelector } from "react-redux";
import {
  selectMapError,
  selectMapListings,
  selectMapLoading,
} from "../../redux/map/map.selectors.js";

const withListings = (Component) => (props) => {
  const listings = useSelector(selectMapListings);
  const loading = useSelector(selectMapLoading);
  const error = useSelector(selectMapError);

  return (
    <Component listings={listings} loading={loading} error={error} {...props} />
  );
};

export default withListings;
