import { useState, useRef, useCallback, useEffect } from "react";
import Card from "../Card/card.component.jsx";
import NotFoundCard from "../Card/components/NotFoundCard/not-found-card.component.jsx";
import ListingCard from "../ListingCard/listing-card.component";
import LoadingFullPage from "../Loading/loading-full-page.component.jsx";
import withListings from "../../HOC/withListings/with-listings";
import styles from "./listings.module.scss";
import MapListingCard from "../Map/components/MapListingCard/map-listing.card.component.jsx";
import pcGif from "../../assets/gif/CraneMoving.gif";
const Listings = ({
  listings,
  sortBy,
  view,
  loading,
  hide,
  isMobile,
  isPreConstruction,
}) => {
  const [sortedListings, setSortedListings] = useState([]);
  const [listingsToShow, setListingsToShow] = useState([]);
  const [page, setPage] = useState(1);
  const scrollRef = useRef();
  const totalCards = useRef();

  const loadMore = useCallback(() => {
    setPage((page) => page + 1);
    setListingsToShow(
      sortedListings.slice(0, page * (view === "list" ? 20 : 5))
    );
  }, [sortedListings, page, view]);

  const scrollListener = useCallback(
    (e) => {
      const { current: element } = scrollRef;
      if (element.scrollTop + element.clientHeight + 100 > element.scrollHeight)
        loadMore();
    },
    [loadMore, scrollRef]
  );

  useEffect(() => {
    const ref = scrollRef.current;
    if (ref) scrollRef.current.addEventListener("scroll", scrollListener);
    if (ref)
      scrollRef.current.addEventListener("touchover", () => console.log("he"));

    return () => {
      if (ref) ref.removeEventListener("scroll", scrollListener);
    };
  }, [scrollRef, scrollListener]);

  useEffect(() => {
    if (sortedListings) {
      if (!sortedListings.length) {
        setListingsToShow([]);
      } else {
        totalCards.current = sortedListings.length;
        setListingsToShow(sortedListings.slice(0, view === "list" ? 20 : 5));
      }

      if (scrollRef.current) scrollRef.current.scrollTo(0, 0);
    }
  }, [sortedListings, view]);

  useEffect(() => {
    if (listings && listings.length) {
      const temp = [...listings];
      switch (sortBy.value) {
        case "date":
          temp.sort((a, b) =>
            a.status === "A"
              ? a.listDate > b.listDate
                ? 1
                : -1
              : a.soldDate > b.soldDate
              ? 1
              : -1
          );
          setSortedListings(
            sortBy.direction === "desc" ? temp.reverse() : temp
          );
          break;
        case "price":
          temp.sort((a, b) =>
            a.status === "A"
              ? +a.listPrice > +b.listPrice
                ? 1
                : -1
              : +a.soldPrice > +b.soldPrice
              ? 1
              : -1
          );
          setSortedListings(
            sortBy.direction === "desc" ? temp.reverse() : temp
          );
          break;
        case "beds":
          temp.sort((a, b) =>
            +a.details.numBedrooms > +b.details.numBedrooms ? 1 : -1
          );
          setSortedListings(
            sortBy.direction === "desc" ? temp.reverse() : temp
          );
          break;
        case "baths":
          temp.sort((a, b) =>
            +a.details.numBathrooms > +b.details.numBathrooms ? 1 : -1
          );
          setSortedListings(
            sortBy.direction === "desc" ? temp.reverse() : temp
          );
          break;
        case "sqft":
          temp.sort((a, b) => (+a.details.sqft > +b.details.sqft ? 1 : -1));
          setSortedListings(
            sortBy.direction === "desc" ? temp.reverse() : temp
          );
          break;
        default:
          setSortedListings(listings);
      }
    } else {
      setSortedListings([]);
    }
  }, [sortBy, listings]);

  const renderCards = useCallback(() => {
    if (loading) return <LoadingFullPage />;
    if (isPreConstruction) {
      return (
        <div className={isMobile ? styles.mobileCards : styles.cards}>
          <NotFoundCard />
        </div>
      );
    }
    if (listingsToShow) {
      if (listingsToShow.length) {
        const cards = listingsToShow.map((listing) =>
          isMobile ? (
            <MapListingCard
              listing={listing}
              isMobile={true}
              key={listing.mlsNumber}
            />
          ) : (
            <Card
              animated={true}
              view={view}
              key={listing.mlsNumber}
              style={{ marginBottom: "2.7rem", maxHeight: "42rem" }}
            >
              <ListingCard listing={listing} />
            </Card>
          )
        );

        return (
          <div className={isMobile ? styles.mobileCards : styles.cards}>
            {cards}
          </div>
        );
      } else {
        return (
          <div className={styles.cards}>
            <NotFoundCard />
          </div>
        );
      }
    }
  }, [isMobile, isPreConstruction, listingsToShow, loading, view]);

  return (
    <div
      className={`${
        isMobile
          ? styles.mobileView
          : view === "list"
          ? styles.listView
          : styles.listings
      } ${hide && styles.hide}`}
      ref={scrollRef}
    >
      {isPreConstruction && view === "list" ? (
        <Card add={true} style={{ marginBottom: "3rem" }}>
          <div className={styles.comingSoon}>
            <h2 className={styles.header}>Coming soon!</h2>
            <span className={styles.content}>
              We are very excited to be building our pre-construction program
              and search portal! Check back soon to get in on the action!
            </span>
          </div>
        </Card>
      ) : (
        renderCards()
      )}
    </div>
  );
};

export default withListings(Listings);
