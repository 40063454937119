import { IonContent, IonIcon, IonLoading, IonPage } from "@ionic/react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { getSingleSchool } from "../../../api/ettie";
import AppHeader from "../../components/Header/header.component";
import styles from "./school-details.module.scss";
import { MapContainer, Marker, TileLayer, Polygon } from "react-leaflet";
import LoadingFullPage from "../../../components/Loading/loading-full-page.component";
import providers from "./providers";
import L from "leaflet";
import {
  call,
  chatbubblesOutline,
  globeOutline,
  location,
  peopleOutline,
  schoolOutline,
  school as schoolIcon,
  calendarClear,
} from "ionicons/icons";
import { handleLinkClick } from "../../../utils/functions";
import moment from "moment";
import { selectGuest } from "../../../redux/user/user.selectors";
import DoughnutChart from "../../../components/Charts/doughnut-chart.component";
import LineChart from "../../../components/Charts/line-chart.component";
import BarChart from "../../../components/Charts/bar-chart.component";
import Footer from "./footer.component";
import DoubleLineChart from "../../../components/Charts/double-line-chart.component";

const compare = (a, b) => {
  if (+a.year < +b.year) return -1;
  if (+a.year > +b.year) return 1;
  return 0;
};

const SchoolDetails = ({
  isApp = false,
  match: {
    params: { id },
  },
}) => {
  const history = useHistory();
  const mapRef = useRef();
  const guest = useSelector(selectGuest);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [school, setSchool] = useState();
  const [boundaries, setBoundaries] = useState();
  const [filterBoundaries, setFilterBoundaries] = useState();
  const [loading, setLoading] = useState(false);
  let DefaultIcon = L.icon({
    iconUrl:
      "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-green.png",

    iconSize: [24, 36],
    iconAnchor: [12, 36],
  });

  L.Marker.prototype.options.icon = DefaultIcon;

  useEffect(() => {
    const fetchSchool = async () => {
      setSchool(null);
      setLoading(true);
      try {
        const school = await getSingleSchool(id);
        if (!school) {
          history.replace("/");
        } else {
          setSchool(school);
          try {
            const boundary = JSON.parse(
              JSON.parse(school.boundaryArray)
            ).Points;

            let points = [];
            let filterPoints = [];
            for (let i = 0; i < boundary.length; i += 2) {
              points.push(boundary.slice(i, i + 2));
              filterPoints.push(boundary.slice(i, i + 2));
            }
            setFilterBoundaries(filterPoints);
            setBoundaries(points);
          } catch (err) {}
        }
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    };
    if (guest) fetchSchool();
  }, [history, id, guest]);

  useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current;
      if (boundaries) map.fitBounds(boundaries);
    }
  }, [boundaries, mapLoaded]);

  const renderLanguages = () => {
    let str = "";
    if (school.isEnglish) str += "English\\";
    if (school.isFrenchImmersion) str += "French\\";
    return str.substring(0, str.length - 1);
  };

  const getTotalStudents = () => {
    const lastYear = school.ratings.items.sort(compare)[0];

    const students = [
      lastYear.G3STU,
      lastYear.G6STU,
      lastYear.G9ACSTU,
      lastYear.G9APSTU,
    ];
    const reducer = (val1, val2) => {
      const t1 = val1 === -1 ? 0 : val1;
      const t2 = val2 === -1 ? 0 : val2;
      return t1 + t2;
    };
    return students.reduce(reducer);
  };

  const getRank = () => {
    const lastYear = school.ratings.items.sort(compare)[0];
    const ranks = [];
    if (lastYear.G3Rank && lastYear.G3Rank !== -1 && lastYear.G3Total)
      ranks.push({
        type: "G3",
        rank: lastYear.G3Rank,
        total: lastYear.G3Total,
      });

    if (lastYear.G6Rank && lastYear.G6Rank !== -1 && lastYear.G6Total)
      ranks.push({
        type: "G6",
        rank: lastYear.G6Rank,
        total: lastYear.G6Total,
      });

    if (lastYear.G9Rank && lastYear.G9Rank !== -1 && lastYear.G9Total)
      ranks.push({
        type: "G9",
        rank: lastYear.G9Rank,
        total: lastYear.G9Total,
      });

    return ranks;
  };

  const getSchoolBoardScore = () => {
    const {
      schoolBoard: { scores },
    } = school;
    if (!scores.items || !scores.items.length) return;
    const lastYear = scores.items.sort(compare)[0];

    const {
      g3FindSchoolRating,
      g6FindSchoolRating,
      g9FindSchoolRating,
      g9Last5Avg,
    } = lastYear;

    let allScores = [];

    if (school.isElementary && g3FindSchoolRating > 0) {
      allScores.push(g3FindSchoolRating);
    }
    if (school.isMiddle && g6FindSchoolRating > 0) {
      allScores.push(g6FindSchoolRating);
    }
    if (school.isHigh && g9Last5Avg > 0) {
      allScores.push(g9Last5Avg);
    }

    if (allScores.length)
      return (
        <DoughnutChart
          style={{
            container: { width: isApp ? "75%" : "100%" },
          }}
          label="Current EQAO school
board rating"
          data={[
            {
              y: +(
                allScores.reduce((p, c) => p + c, 0) / allScores.length
              ).toFixed(0),
            },
            {
              y:
                100 -
                (
                  allScores.reduce((p, c) => p + c, 0) / allScores.length
                ).toFixed(0),
            },
          ]}
          centerLabel={`${(
            allScores.reduce((p, c) => p + c, 0) / allScores.length
          ).toFixed(0)}/100`}
        />
      );
  };

  const renderGrade = () => {
    let str = "";
    if (school.isElementary) str += "elementary";
    if (school.isMiddle || school.isHigh) {
      if (str.length > 0) str += "/secondary";
      else str += "secondary";
    }
    return str;
  };

  const renderSummary = () => {
    return `${school.name} is ${
      renderLanguages().startsWith("E") ? "an" : "a"
    } ${renderLanguages()} ${renderGrade()} ${
      school.isPublic
        ? "public"
        : school.isPrivate
        ? "private"
        : school.isCatholic
        ? "catholic"
        : ""
    } school in the ${school.schoolBoard?.name}. Located in ${school.city}, ${
      school.province
    }${
      getTotalStudents() > 0
        ? ", this school has " + getTotalStudents() + " students."
        : "."
    }`;
  };

  const renderPrograms = () => {
    let str = "";
    if (school.isFrenchImmersion) str += "French Immersion, ";
    if (school.isExtendedFrench) str += "Extended French, ";
    if (school.isIB) str += "International Baccalaureate, ";
    if (school.isAP) str += "Advanced Placement, ";
    if (school.isGifted) str += "Gifted, ";
    if (school.isSport) str += "Sports, ";
    if (school.isArts) str += "Arts, ";

    if (str.length) str = str.substring(0, str.length - 2);
    if (str.length)
      return <span className={styles.text}>Special programs: {str}</span>;
    return;
  };

  const calculateChartYear = (yearMark) => {
    if (!yearMark) return;
    if (yearMark.length > 4) {
      const str =
        yearMark.split("-")[0].substring(2, 4) +
        "-" +
        yearMark.split("-")[1].substring(2, 4);

      return str;
    } else {
      const next = (yearMark + 1).toString();

      const str = `${yearMark.toString().substring(2, 4).toString()}-${next
        .substring(2, 4)
        .toString()}`;

      return str;
    }
  };

  const renderUrl = () => {
    let url =
      school.url &&
      school.url
        .replace("http://", "")
        .replace("https://", "")
        .replace("www.", "");

    if (url.charAt(url.length - 1) === "/")
      url = url.substring(0, url.length - 1);
    return url;
  };

  const getLineChartData = () => {
    const data = [];
    const years = school.ratings.items.sort(compare);

    years.forEach((y) => {
      let avgs = [y.G3FindSchoolRating, y.G6FindSchoolRating, y.G9Last5Avg];
      avgs = avgs.filter((v) => v && v !== -1);
      if (avgs.length) {
        data.push({
          x: calculateChartYear(y.yearMark),
          y: +(
            avgs.reduce((accumulator, v) => accumulator + v, 0) / avgs.length
          ).toFixed(0),
          label: +(
            avgs.reduce((accumulator, v) => accumulator + v, 0) / avgs.length
          ).toFixed(0),
        });
      }
    });

    return data;
  };

  const renderEQAOScores = () => {
    const sections = [];
    const lastYear = school.schoolBoard.scores.items.sort(compare).reverse()[0];
    if (school.isElementary) {
      sections.push({
        title: "Grade 3 EQAO Scores",
        charts: [
          {
            type: "bar",
            label: "G3 Total students",
            scale: "sqrt",
            hideY: true,
            schoolData: school.ratings.items.sort(compare).map((y) => {
              return {
                x: calculateChartYear(y.yearMark),
                y: y.G3STU > 0 ? y.G3STU : 0,
                label: y.G3STU > 0 ? y.G3STU : "NA",
              };
            }),

            boardDate: school.schoolBoard.scores.items
              .sort(compare)
              .map((y) => {
                return {
                  x: calculateChartYear(y.year),
                  y: y.g3STU > 0 ? +(y.g3STU / lastYear.g3TS).toFixed(0) : 0,
                  label:
                    y.g3STU > 0 ? +(y.g3STU / lastYear.g3TS).toFixed(0) : "NA",
                };
              }),
          },
          {
            type: "line",
            label: "G3 Average rating",
            domain: 100,
            schoolData: school.ratings.items.sort(compare).map((y) => {
              return {
                x: calculateChartYear(y.yearMark),
                y:
                  y.G3FindSchoolRating > 0
                    ? +y.G3FindSchoolRating.toFixed(0)
                    : 0,
                // label:
                //   y.G3FindSchoolRating > 0
                //     ? +y.G3FindSchoolRating.toFixed(0)
                //     : "NA",
              };
            }),
            boardDate: school.schoolBoard.scores.items
              .sort(compare)
              .map((y) => {
                return {
                  x: calculateChartYear(y.year),
                  y:
                    y.g3FindSchoolRating > 0
                      ? +y.g3FindSchoolRating.toFixed(0)
                      : 0,
                  // label:
                  //   y.g3FindSchoolRating > 0
                  //     ? +y.g3FindSchoolRating.toFixed(0)
                  //     : "NA",
                };
              }),
          },
          {
            type: "bar",
            label: "G3 Math",
            domain: 100,
            schoolData: school.ratings.items
              .sort(compare)

              .map((y) => {
                return {
                  x: calculateChartYear(y.yearMark),
                  y: y.G3AM > 0 ? +y.G3AM.toFixed(0) : 0,
                  label: y.G3AM > 0 ? +y.G3AM.toFixed(0) : "NA",
                };
              }),
            boardDate: school.schoolBoard.scores.items
              .sort(compare)

              .map((y) => {
                return {
                  x: calculateChartYear(y.year),
                  y: y.g3AM > 0 ? +y.g3AM.toFixed(0) : 0,
                  label: y.g3AM > 0 ? +y.g3AM.toFixed(0) : "NA",
                };
              }),
          },
          {
            type: "bar",
            label: "G3 Reading",
            domain: 100,
            schoolData: school.ratings.items
              .sort(compare)

              .map((y) => {
                return {
                  x: calculateChartYear(y.yearMark),
                  y: y.G3AR > 0 ? +y.G3AR.toFixed(0) : 0,
                  label: y.G3AR > 0 ? +y.G3AR.toFixed(0) : "NA",
                };
              }),
            boardDate: school.schoolBoard.scores.items
              .sort(compare)

              .map((y) => {
                return {
                  x: calculateChartYear(y.year),
                  y: y.g3AR > 0 ? +y.g3AR.toFixed(0) : 0,
                  label: y.g3AR > 0 ? +y.g3AR.toFixed(0) : "NA",
                };
              }),
          },
          {
            type: "bar",
            label: "G3 Writing",
            domain: 100,
            schoolData: school.ratings.items
              .sort(compare)

              .map((y) => {
                return {
                  x: calculateChartYear(y.yearMark),
                  y: y.G3AW > 0 ? +y.G3AW : 0,
                  label: y.G3AW > 0 ? +y.G3AW : "NA",
                };
              }),
            boardDate: school.schoolBoard.scores.items
              .sort(compare)

              .map((y) => {
                return {
                  x: calculateChartYear(y.year),
                  y: y.g3AW > 0 ? +y.g3AW.toFixed(0) : 0,
                  label: y.g3AW > 0 ? y.g3AW.toFixed(0) : "NA",
                };
              }),
          },
          {
            type: "scatter",
            label: "G3 Total rank in Ontario",
            domain: Math.max.apply(
              Math,
              school.ratings.items.map((r) => r.G3Total)
            ),

            data: school.ratings.items.sort(compare).map((y) => {
              return {
                x: calculateChartYear(y.yearMark),
                y: y.G3Rank > 0 ? y.G3Rank : 0,
                label: y.G3Rank > 0 ? `${y.G3Rank}/${y.G3Total}` : "",
              };
            }),
          },
        ],
      });
    }
    if (school.gradeFrom <= 6 && school.gradeEnd >= 6) {
      sections.push({
        title: "Grade 6 EQAO Scores",
        charts: [
          {
            type: "bar",
            label: "G6 Total students",
            scale: "sqrt",
            hideY: true,
            schoolData: school.ratings.items.sort(compare).map((y) => {
              return {
                x: calculateChartYear(y.yearMark),
                y: y.G6STU > 0 ? y.G6STU : 0,
                label: y.G6STU > 0 ? y.G6STU : "NA",
              };
            }),
            boardDate: school.schoolBoard.scores.items
              .sort(compare)
              .map((y) => {
                return {
                  x: calculateChartYear(y.year),
                  y: y.g6STU > 0 ? +(y.g6STU / lastYear.g6TS).toFixed(0) : 0,
                  label:
                    y.g6STU > 0 ? +(y.g6STU / lastYear.g6TS).toFixed(0) : "NA",
                };
              }),
          },
          {
            type: "line",
            label: "G6 Average rating",
            domain: 100,
            schoolData: school.ratings.items.sort(compare).map((y) => {
              return {
                x: calculateChartYear(y.yearMark),
                y:
                  y.G6FindSchoolRating > 0
                    ? +y.G6FindSchoolRating.toFixed(0)
                    : 0,
                // label:
                //   y.G3FindSchoolRating > 0
                //     ? +y.G3FindSchoolRating.toFixed(0)
                //     : "NA",
              };
            }),
            boardDate: school.schoolBoard.scores.items
              .sort(compare)
              .map((y) => {
                return {
                  x: calculateChartYear(y.year),
                  y:
                    y.g6FindSchoolRating > 0
                      ? +y.g6FindSchoolRating.toFixed(0)
                      : 0,
                  // label:
                  //   y.g3FindSchoolRating > 0
                  //     ? +y.g3FindSchoolRating.toFixed(0)
                  //     : "NA",
                };
              }),
          },
          {
            type: "bar",
            label: "G6 Math",
            domain: 100,
            schoolData: school.ratings.items
              .sort(compare)

              .map((y) => {
                return {
                  x: calculateChartYear(y.yearMark),
                  y: y.G6AM > 0 ? +y.G6AM.toFixed(0) : 0,
                  label: y.G6AM > 0 ? y.G6AM.toFixed(0) : "NA",
                };
              }),
            boardDate: school.schoolBoard.scores.items
              .sort(compare)

              .map((y) => {
                return {
                  x: calculateChartYear(y.year),
                  y: y.g6AM > 0 ? +y.g6AM.toFixed(0) : 0,
                  label: y.g6AM > 0 ? y.g6AM.toFixed(0) : "NA",
                };
              }),
          },
          {
            type: "bar",
            label: "G6 Reading",
            domain: 100,
            schoolData: school.ratings.items
              .sort(compare)

              .map((y) => {
                return {
                  x: calculateChartYear(y.yearMark),
                  y: y.G6AR > 0 ? +y.G6AR.toFixed(0) : 0,
                  label: y.G6AR > 0 ? y.G6AR.toFixed(0) : "NA",
                };
              }),
            boardDate: school.schoolBoard.scores.items
              .sort(compare)

              .map((y) => {
                return {
                  x: calculateChartYear(y.year),
                  y: y.g6AR > 0 ? +y.g6AR.toFixed(0) : 0,
                  label: y.g6AR > 0 ? y.g6AR.toFixed(0) : "NA",
                };
              }),
          },
          {
            type: "bar",
            label: "G6 Writing",
            domain: 100,
            schoolData: school.ratings.items
              .sort(compare)

              .map((y) => {
                return {
                  x: calculateChartYear(y.yearMark),
                  y: y.G6AW > 0 ? +y.G6AW.toFixed(0) : 0,
                  label: y.G6AW > 0 ? y.G6AW.toFixed(0) : "NA",
                };
              }),
            boardDate: school.schoolBoard.scores.items
              .sort(compare)

              .map((y) => {
                return {
                  x: calculateChartYear(y.year),
                  y: y.g6AW > 0 ? +y.g6AW.toFixed(0) : 0,
                  label: y.g6AW > 0 ? y.g6AW.toFixed(0) : "NA",
                };
              }),
          },
          {
            type: "scatter",
            label: "G6 Total rank in Ontario",
            domain: Math.max.apply(
              Math,
              school.ratings.items.map((r) => r.G6Total)
            ),

            data: school.ratings.items
              .sort(compare)

              .map((y) => {
                return {
                  x: calculateChartYear(y.yearMark),
                  y: y.G6Rank > 0 ? y.G6Rank : 0,
                  label: y.G6Rank > 0 ? `${y.G6Rank}/${y.G6Total}` : "",
                };
              }),
          },
        ],
      });
    }
    if (school.gradeFrom <= 9 && school.gradeEnd >= 9) {
      sections.push({
        title: "Grade 9 EQAO Scores",
        charts: [
          {
            type: "bar",
            label: "G9 Average rating",
            domain: 100,
            schoolData: school.ratings.items.sort(compare).map((y) => {
              return {
                x: calculateChartYear(y.yearMark),
                y: y.G9Last5Avg > 0 ? +y.G9Last5Avg.toFixed(0) : 0,
                label: y.G9Last5Avg > 0 ? y.G9Last5Avg.toFixed(0) : "NA",
              };
            }),
            boardDate: school.schoolBoard.scores.items
              .sort(compare)
              .map((y) => {
                return {
                  x: calculateChartYear(y.year),
                  y: y.g9Last5Avg > 0 ? +y.g9Last5Avg.toFixed(0) : 0,
                  label: y.g9Last5Avg > 0 ? y.g9Last5Avg.toFixed(0) : "NA",
                };
              }),
          },
          {
            type: "bar",
            label: "G9 Ac. Total students",
            scale: "sqrt",
            hideY: true,
            schoolData: school.ratings.items
              .sort(compare)

              .map((y) => {
                return {
                  x: calculateChartYear(y.yearMark),
                  y: y.G9ACSTU > 0 ? y.G9ACSTU : 0,
                  label: y.G9ACSTU > 0 ? y.G9ACSTU : "NA",
                };
              }),
            boardDate: school.schoolBoard.scores.items
              .sort(compare)

              .map((y) => {
                return {
                  x: calculateChartYear(y.year),
                  y:
                    y.g9ACSTU > 0 ? +(y.g9ACSTU / lastYear.g9TS).toFixed(0) : 0,
                  label:
                    y.g9ACSTU > 0
                      ? +(y.g9ACSTU / lastYear.g9TS).toFixed(0)
                      : "NA",
                };
              }),
          },

          {
            type: "bar",
            label: "G9 Ac. Math",
            domain: 100,
            schoolData: school.ratings.items
              .sort(compare)

              .map((y) => {
                return {
                  x: calculateChartYear(y.yearMark),
                  y: y.G9ACM > 0 ? +y.G9ACM.toFixed(0) : 0,
                  label: y.G9ACM > 0 ? y.G9ACM.toFixed(0) : "NA",
                };
              }),
            boardDate: school.schoolBoard.scores.items
              .sort(compare)

              .map((y) => {
                return {
                  x: calculateChartYear(y.year),
                  y: y.g9ACM > 0 ? +y.g9ACM.toFixed(0) : 0,
                  label: y.g9ACM > 0 ? y.g9ACM.toFixed(0) : "NA",
                };
              }),
          },

          {
            type: "scatter",
            label: "G9 Ac. Total rank in Ontario",
            domain: Math.max.apply(
              Math,
              school.ratings.items.map((r) => r.G9ACTotal)
            ),

            data: school.ratings.items
              .sort(compare)

              .map((y) => {
                return {
                  x: calculateChartYear(y.yearMark),
                  y: y.G9ACRank > 0 ? y.G9ACRank : 0,
                  label: y.G9ACRank > 0 ? `${y.G9ACRank}/${y.G9ACTotal}` : "",
                };
              }),
          },
          {
            type: "bar",
            label: "G9 Ap. Total students",
            scale: "sqrt",
            hideY: true,
            schoolData: school.ratings.items
              .sort(compare)

              .map((y) => {
                return {
                  x: calculateChartYear(y.yearMark),
                  y: y.G9APSTU > 0 ? y.G9APSTU : 0,
                  label: y.G9APSTU > 0 ? y.G9APSTU : "NA",
                };
              }),
            boardDate: school.schoolBoard.scores.items
              .sort(compare)

              .map((y) => {
                return {
                  x: calculateChartYear(y.year),
                  y:
                    y.g9APSTU > 0 ? +(y.g9APSTU / lastYear.g9TS).toFixed(0) : 0,
                  label:
                    y.g9APSTU > 0
                      ? +(y.g9APSTU / lastYear.g9TS).toFixed(0)
                      : "NA",
                };
              }),
          },

          {
            type: "bar",
            label: "G9 Ap. Math",
            domain: 100,
            schoolData: school.ratings.items
              .sort(compare)

              .map((y) => {
                return {
                  x: calculateChartYear(y.yearMark),
                  y: y.G9APM > 0 ? +y.G9APM.toFixed(0) : 0,
                  label: y.G9APM > 0 ? y.G9APM.toFixed(0) : "NA",
                };
              }),
            boardDate: school.schoolBoard.scores.items
              .sort(compare)

              .map((y) => {
                return {
                  x: calculateChartYear(y.year),
                  y: y.g9APM > 0 ? +y.g9APM.toFixed(0) : 0,
                  label: y.g9APM > 0 ? y.g9APM.toFixed(0) : "NA",
                };
              }),
          },

          {
            type: "scatter",
            label: "G9 Ap. Total rank in Ontario",
            domain: Math.max.apply(
              Math,
              school.ratings.items.map((r) => r.G9APTotal)
            ),

            data: school.ratings.items
              .sort(compare)

              .map((y) => {
                return {
                  x: calculateChartYear(y.yearMark),
                  y: y.G9APRank > 0 ? y.G9APRank : 0,
                  label: y.G9APRank > 0 ? `${y.G9APRank}/${y.G9APTotal}` : "",
                };
              }),
          },
        ],
      });
      sections.push({
        title: "Grade 10 OSSLT EQAO Test",
        charts: [
          {
            type: "bar",
            label: "Number taking the test for the first time",
            scale: "sqrt",
            hideY: true,
            schoolData: school.ratings.items
              .sort(compare)

              .map((y) => {
                return {
                  x: calculateChartYear(y.yearMark),
                  y: y.G9Last5Avg > 0 ? y.G10OSSLTFSTUA : 0,
                  label: y.G9Last5Avg > 0 ? y.G10OSSLTFSTUA : "NA",
                };
              }),
            boardDate: school.schoolBoard.scores.items
              .sort(compare)

              .map((y) => {
                return {
                  x: calculateChartYear(y.year),
                  y: y.g10OSSLTFSTUA > 0 ? y.g10OSSLTFSTUA : 0,
                  label: y.g10OSSLTFSTUA > 0 ? y.g10OSSLTFSTUA : "NA",
                };
              }),
          },
          {
            type: "bar",
            label: "Total number taking the test",
            scale: "sqrt",
            hideY: true,
            schoolData: school.ratings.items
              .sort(compare)

              .map((y) => {
                return {
                  x: calculateChartYear(y.yearMark),
                  y: y.G10OSSLTFSTUP > 0 ? y.G10OSSLTFSTUP : 0,
                  label: y.G10OSSLTFSTUP > 0 ? y.G10OSSLTFSTUP : "NA",
                };
              }),
            boardDate: school.schoolBoard.scores.items
              .sort(compare)

              .map((y) => {
                return {
                  x: calculateChartYear(y.year),
                  y: y.g10OSSLTFSTUP > 0 ? y.g10OSSLTFSTUP : 0,
                  label: y.g10OSSLTFSTUP > 0 ? y.g10OSSLTFSTUP : "NA",
                };
              }),
          },
          {
            type: "bar",
            label: "Number successful on their first test",
            scale: "sqrt",
            hideY: true,
            schoolData: school.ratings.items
              .sort(compare)

              .map((y) => {
                return {
                  x: calculateChartYear(y.yearMark),
                  y: y.G10OSSLTFSUCCESS > 0 ? y.G10OSSLTFSUCCESS : 0,
                  label: y.G10OSSLTFSUCCESS > 0 ? y.G10OSSLTFSUCCESS : "NA",
                };
              }),
            boardDate: school.schoolBoard.scores.items
              .sort(compare)

              .map((y) => {
                return {
                  x: calculateChartYear(),
                  y: y.g10OSSLTFSUCCESS > 0 ? y.g10OSSLTFSUCCESS : 0,
                  label: y.g10OSSLTFSUCCESS > 0 ? y.g10OSSLTFSUCCESS : "NA",
                };
              }),
          },
        ],
      });
    }
    return sections.map((s, i) => (
      <div key={i} className={styles.ratings}>
        <div className={styles.title}>{s.title}</div>
        <div className={styles.webChartInfo}>
          <div className={styles.chartInfo}>
            <div className={styles.item}>
              <span />
              <span>{school.name}</span>
            </div>
            <div className={styles.item}>
              <span />
              <span>{school.schoolBoard.name}</span>
            </div>
          </div>
        </div>

        <div className={styles.rates}>
          {s.charts
            .filter((c) => c.type === "bar")
            .map((c, i) => (
              <div key={i} className={styles.rate}>
                <BarChart
                  data={{ school: c.schoolData, board: c.boardDate }}
                  label={c.label}
                  domain={c.domain}
                  scale={c.scale}
                  hideY={c.hideY}
                  style={
                    !isApp && {
                      container: { width: "100%", maxWidth: "50rem" },
                      chart: { width: "100%" },
                    }
                  }
                />
                <div className={styles.chartInfo}>
                  <div className={styles.item}>
                    <span />
                    <span>{school.name}</span>
                  </div>
                  <div className={styles.item}>
                    <span />
                    <span>{school.schoolBoard.name}</span>
                  </div>
                </div>
              </div>
            ))}
          {s.charts
            .filter((c) => c.type === "line")
            .map((c, i) => (
              <div key={i} className={styles.rate}>
                <DoubleLineChart
                  data={{ school: c.schoolData, board: c.boardDate }}
                  label={c.label}
                  domain={c.domain}
                  scale={c.scale}
                  hideY={c.hideY}
                  style={
                    !isApp && {
                      container: { width: "100%", maxWidth: "50rem" },
                      chart: { width: "100%" },
                    }
                  }
                />
                <div className={styles.chartInfo}>
                  <div className={styles.item}>
                    <span />
                    <span>{school.name}</span>
                  </div>
                  <div className={styles.item}>
                    <span />
                    <span>{school.schoolBoard.name}</span>
                  </div>
                </div>
              </div>
            ))}
          {s.charts
            .filter((c) => c.type === "scatter")
            .map((c, i) => (
              <div key={i} className={styles.rate}>
                <LineChart
                  data={c.data}
                  barData={c.barData}
                  showY={false}
                  label={c.label}
                  domain={c.domain}
                  style={
                    !isApp && {
                      container: { width: "100%", maxWidth: "50rem" },
                      chart: { width: "100%" },
                    }
                  }
                />
                <div className={styles.chartInfo}>
                  <div className={styles.item}>
                    <span />
                    <span>{school.name}</span>
                  </div>
                  <div className={styles.item}>
                    <span />
                    <span>{school.schoolBoard.name}</span>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    ));
  };

  const renderEQAORating = () => {
    if (school.EQAOScore)
      return (
        <DoughnutChart
          style={{
            container: { width: isApp ? "75%" : "100%" },
          }}
          label="Current EQAO school
    rating"
          data={[
            {
              y: +school.EQAOScore.toFixed(0),
            },
            {
              y: 100 - school.EQAOScore.toFixed(0),
            },
          ]}
          centerLabel={`${school.EQAOScore.toFixed(0)}/100`}
        />
      );
  };
  return isApp ? (
    <IonPage>
      <AppHeader
        title={school ? school.name : "School Details"}
        hasRightButton={false}
      />

      <IonLoading isOpen={loading} />
      <IonContent>
        {school && (
          <div className={styles.container}>
            <div className={styles.header}>
              <div className={styles.title}>{school.name}</div>
            </div>

            <div className={styles.map}>
              {school.latitude && school.longitude && (
                <>
                  <MapContainer
                    trackResize={false}
                    id="my-map"
                    center={[+school.latitude, +school.longitude]}
                    zoom="15"
                    minZoom="12"
                    className={styles.leaflet}
                    whenCreated={(map) => {
                      map.invalidateSize(true);
                      mapRef.current = map;
                      boundaries && map.fitBounds(boundaries);
                      setMapLoaded(true);
                    }}
                  >
                    <TileLayer
                      url={providers.maptiler.url}
                      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    />
                    <Marker position={[+school.latitude, +school.longitude]} />
                    {boundaries && (
                      <Polygon positions={boundaries} color="#1f7a60" />
                    )}
                  </MapContainer>
                  <div className={styles.footer}>
                    School boundaries change often. Please confirm boundaries
                    before making any purchasing decisions.
                  </div>
                </>
              )}
            </div>
            <div className={styles.overview}>
              <div className={styles.title}>Overview</div>
              {isApp ? (
                <div className={styles.appOverview}>
                  <div className={styles.item}>
                    <span className={styles.label}>Grade</span>
                    <span className={styles.value}>
                      {school.gradeFrom === -1 ? "JK" : school.gradeFrom}-
                      {school.gradeEnd}
                    </span>
                  </div>
                  <div className={styles.item}>
                    <span className={styles.label}>Language</span>
                    <span className={styles.value}>{renderLanguages()}</span>
                  </div>
                  <div className={styles.item}>
                    <span className={styles.label}>Type</span>
                    <span className={styles.value}>
                      {school.isPublic
                        ? "Public"
                        : school.isPrivate
                        ? "Private"
                        : school.isCatholic
                        ? "Catholic"
                        : ""}
                    </span>
                  </div>
                </div>
              ) : (
                <div className={styles.icons}>
                  <div className={styles.item}>
                    <IonIcon icon={peopleOutline} />
                    <span className={styles.text}>
                      {school.gradeFrom === -1 ? "JK" : school.gradeFrom}-
                      {school.gradeEnd}
                    </span>
                  </div>
                  <div className={styles.item}>
                    <IonIcon icon={chatbubblesOutline} />
                    <span className={styles.text}>{renderLanguages()}</span>
                  </div>
                  <div className={styles.item}>
                    <IonIcon icon={schoolOutline} />
                    <span className={styles.text}>
                      {school.isPublic
                        ? "Public"
                        : school.isPrivate
                        ? "Private"
                        : school.isCatholic
                        ? "Catholic"
                        : ""}
                    </span>
                  </div>
                </div>
              )}

              <div className={styles.summary}>{renderSummary()}</div>
              <div className={styles.summary}>{renderPrograms()}</div>
              <div className={styles.info}>
                <div className={styles.item}>
                  <IonIcon icon={location} />
                  <span
                    className={styles.text}
                  >{`${school.address}, ${school.city}`}</span>
                </div>
                <div className={styles.item}>
                  <IonIcon icon={call} />
                  <span className={styles.text}>
                    {school.phone ?? "---"}{" "}
                    {school.fax && ` | Fax: ${school.fax}`}
                  </span>
                </div>
                <div className={styles.item}>
                  <IonIcon icon={globeOutline} />
                  <span
                    className={styles.text}
                    style={{ textDecoration: "underline" }}
                    onClick={() => school.url && handleLinkClick(school.url)}
                  >
                    {renderUrl()}
                  </span>
                </div>
                <div className={styles.item}>
                  <IonIcon icon={schoolIcon} />
                  <span
                    className={styles.text}
                    style={{ textDecoration: "underline" }}
                    onClick={() =>
                      school.schoolBoard?.url &&
                      handleLinkClick(school.schoolBoard.url)
                    }
                  >
                    {school.schoolBoard?.name}
                  </span>
                </div>
                <div className={styles.item}>
                  <IonIcon icon={calendarClear} />
                  <span className={styles.text}>
                    Last review date:{" "}
                    {school.lastReviewDate
                      ? moment(school.lastReviewDate).format("MMMM YYYY")
                      : ""}
                  </span>
                </div>
              </div>
            </div>
            <div className={styles.ratings}>
              <div className={styles.title}>Ettie rating out of 10*</div>
              <div className={styles.rate}>
                <DoughnutChart
                  style={{
                    container: { width: isApp ? "75%" : "100%" },
                  }}
                  data={[
                    {
                      y: +(
                        (school.isHigh
                          ? school.g9Avg
                          : school.ettieOverallAvg) / 10
                      ).toFixed(1),
                    },
                    {
                      y: +(
                        10 -
                        (
                          (school.isHigh
                            ? school.g9Avg
                            : school.ettieOverallAvg) / 10
                        ).toFixed(1)
                      ).toFixed(1),
                    },
                  ]}
                  centerLabel={(
                    (school.isHigh ? school.g9Avg : school.ettieOverallAvg) / 10
                  ).toFixed(1)}
                />
                <div className={styles.info}>
                  For optimal representation for each school, Ettie determines
                  an overall rating out of 10 based on a blended 5-year average
                  of{" "}
                  <span
                    className={styles.link}
                    onClick={() => history.push("/school-terms")}
                  >
                    EQAO ratings
                  </span>
                  , putting more weight on recent years to account for each
                  school’s rating trend.
                </div>
              </div>
            </div>
            <div className={styles.ratings}>
              <div className={styles.title}>EQAO Overview</div>
              <div className={styles.rate}>
                {renderEQAORating()}
                {getRank().map((r) => (
                  <DoughnutChart
                    key={r.type}
                    style={{
                      container: { width: isApp ? "75%" : "100%" },
                      centerLabel: { fontSize: isApp ? "1.5rem" : "2.2rem" },
                    }}
                    label={`Current EQAO school ${r.type}
              ranking in GTA`}
                    data={[
                      {
                        y: r.total - r.rank,
                      },
                      { y: r.rank - 1 },
                    ]}
                    centerLabel={`${r.rank}/${r.total}`}
                  />
                ))}

                {getSchoolBoardScore()}
              </div>
              <div className={styles.rate}>
                <LineChart
                  domain={100}
                  label="EQAO school rating trend over 5 years"
                  data={getLineChartData()}
                />
              </div>
            </div>
            {renderEQAOScores()}
            <div className={styles.footer}>
              *Ranking out of 10 is calculated by Ettie. All information is
              provided by{" "}
              <u onClick={() => handleLinkClick("https://eqao.com")}>EQAO</u>.
            </div>
            {isApp && <Footer school={school} boundary={filterBoundaries} />}
          </div>
        )}
      </IonContent>
    </IonPage>
  ) : (
    <div className={styles.webContainer}>
      {loading && <LoadingFullPage style={{ backgroundColor: "white" }} />}
      {school && (
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.title}>{school.name}</div>
          </div>

          <div className={styles.map}>
            {school.latitude && school.longitude && (
              <>
                <MapContainer
                  trackResize={false}
                  id="my-map"
                  center={[+school.latitude, +school.longitude]}
                  zoom="15"
                  minZoom="12"
                  className={styles.leaflet}
                  whenCreated={(map) => {
                    map.invalidateSize(true);
                    mapRef.current = map;
                    boundaries && map.fitBounds(boundaries);
                    setMapLoaded(true);
                  }}
                >
                  <TileLayer
                    url={providers.maptiler.url}
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  />
                  <Marker position={[+school.latitude, +school.longitude]} />
                  {boundaries && (
                    <Polygon positions={boundaries} color="#1f7a60" />
                  )}
                </MapContainer>
                <div className={styles.footer}>
                  School boundaries change often. Please confirm boundaries
                  before making any purchasing decisions.
                </div>
              </>
            )}
          </div>
          <div className={styles.overview}>
            <div className={styles.title}>Overview</div>
            {isApp ? (
              <div className={styles.appOverview}>
                <div className={styles.item}>
                  <span className={styles.label}>Grade</span>
                  <span className={styles.value}>
                    {school.gradeFrom === -1 ? "JK" : school.gradeFrom}-
                    {school.gradeEnd}
                  </span>
                </div>
                <div className={styles.item}>
                  <span className={styles.label}>Language</span>
                  <span className={styles.value}>{renderLanguages()}</span>
                </div>
                <div className={styles.item}>
                  <span className={styles.label}>Type</span>
                  <span className={styles.value}>
                    {school.isPublic
                      ? "Public"
                      : school.isPrivate
                      ? "Private"
                      : school.isCatholic
                      ? "Catholic"
                      : ""}
                  </span>
                </div>
              </div>
            ) : (
              <div className={styles.icons}>
                <div className={styles.item}>
                  <IonIcon icon={peopleOutline} />
                  <span className={styles.text}>
                    {school.gradeFrom === -1 ? "JK" : school.gradeFrom}-
                    {school.gradeEnd}
                  </span>
                </div>
                <div className={styles.item}>
                  <IonIcon icon={chatbubblesOutline} />
                  <span className={styles.text}>{renderLanguages()}</span>
                </div>
                <div className={styles.item}>
                  <IonIcon icon={schoolOutline} />
                  <span className={styles.text}>
                    {school.isPublic
                      ? "Public"
                      : school.isPrivate
                      ? "Private"
                      : school.isCatholic
                      ? "Catholic"
                      : ""}
                  </span>
                </div>
              </div>
            )}

            <div className={styles.summary}>{renderSummary()}</div>
            <div className={styles.summary}>{renderPrograms()}</div>
            <div className={styles.info}>
              <div className={styles.item}>
                <IonIcon icon={location} />
                <span
                  className={styles.text}
                >{`${school.address}, ${school.city}`}</span>
              </div>
              <div className={styles.item}>
                <IonIcon icon={call} />
                <span className={styles.text}>
                  {school.phone ?? "---"}{" "}
                  {school.fax && ` | Fax: ${school.fax}`}
                </span>
              </div>
              <div className={styles.item}>
                <IonIcon icon={globeOutline} />
                <span
                  className={styles.text}
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={() => school.url && handleLinkClick(school.url)}
                >
                  {renderUrl()}
                </span>
              </div>
              <div className={styles.item}>
                <IonIcon icon={schoolIcon} />
                <span
                  className={styles.text}
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={() =>
                    school.schoolBoard?.url &&
                    handleLinkClick(school.schoolBoard.url)
                  }
                >
                  {school.schoolBoard?.name}
                </span>
              </div>
              <div className={styles.item}>
                <IonIcon icon={calendarClear} />
                <span className={styles.text}>
                  Last review date:{" "}
                  {school.lastReviewDate
                    ? moment(school.lastReviewDate).format("MMMM YYYY")
                    : ""}
                </span>
              </div>
            </div>
          </div>
          <div className={styles.ratings}>
            <div className={styles.title}>Ettie rating out of 10*</div>
            <div className={styles.rate}>
              <DoughnutChart
                style={{
                  container: { width: isApp ? "75%" : "100%" },
                }}
                data={[
                  {
                    y: +(school.ettieOverallAvg / 10).toFixed(1),
                  },
                  {
                    y: +(10 - (school.ettieOverallAvg / 10).toFixed(1)).toFixed(
                      1
                    ),
                  },
                ]}
                centerLabel={(school.ettieOverallAvg / 10).toFixed(1)}
              />
              <div className={styles.info}>
                For optimal representation for each school, Ettie determines an
                overall rating out of 10 based on a blended 5-year average of
                <span
                  className={styles.link}
                  onClick={() => history.push("/school-terms")}
                >
                  {" "}
                  EQAO ratings
                </span>
                , putting more weight on recent years to account for each
                school’s rating trend.
              </div>
            </div>
          </div>
          <div className={styles.ratings}>
            <div className={styles.title}>EQAO Overview</div>
            <div className={styles.rate}>
              {renderEQAORating()}
              {getRank().map((r) => (
                <DoughnutChart
                  key={r.type}
                  style={{
                    container: { width: isApp ? "75%" : "100%" },
                    centerLabel: { fontSize: isApp ? "1.5rem" : "2.2rem" },
                  }}
                  label={`Current EQAO school ${r.type}
                ranking in GTA`}
                  data={[
                    {
                      y: r.total - r.rank,
                    },
                    { y: r.rank - 1 },
                  ]}
                  centerLabel={`${r.rank}/${r.total}`}
                />
              ))}

              {getSchoolBoardScore()}
            </div>
            <div className={styles.rate}>
              <LineChart
                label="EQAO school rating trend over 5 years"
                domain={100}
                data={getLineChartData()}
                style={{
                  container: { width: "50", maxWidth: "60rem" },
                  chart: { width: "100%" },
                }}
              />
            </div>
          </div>
          {renderEQAOScores()}
          <div className={styles.footer}>
            *Ranking out of 10 is calculated by Ettie. All information is
            provided by{" "}
            <u onClick={() => handleLinkClick("https://eqao.com")}>EQAO</u>.
          </div>
          {isApp && <Footer school={school} boundary={filterBoundaries} />}
        </div>
      )}
    </div>
  );
};
export default SchoolDetails;
