import { useDispatch, useSelector } from "react-redux";
import Button from "../../Form/Button/button.component";
import LocationSearch from "../../LocationSearch/location-search.component";
import PriceFilter from "../components/PriceFilter/price-filter.component";
import PropertyTypeFilter from "../components/PropertyTypeFilter/property-type-filter.component";
import StatusSegmentFilter from "../components/StatusSegmentFilter/status-segment-filter.component";
import DateFilter from "../components/DateFilter/date-filter.component";
import styles from "./primary-filters.module.scss";
import { ReactComponent as FiltersIcon } from "../../../assets/svg/filters.svg";
import withFilters from "../../../HOC/withFilters/with-filters";
import PurposeFilter from "../components/PurposeFilter/purpose-filter.component";
import BedsFilter from "../components/BedsFilter/beds-filter.component";
import { updateMenuTab } from "../../../redux/ui/ui.actions";
import { selectUIMenuTab } from "../../../redux/ui/ui.selectors";
import { selectFiltersValue } from "../../../redux/filters/filters.selectors";

const PriceWithFilters = withFilters(PriceFilter);
const StatusWithFilters = withFilters(StatusSegmentFilter);
const DateWithFilters = withFilters(DateFilter);
const PurposeWithFilters = withFilters(PurposeFilter);
const BedsWithFilters = withFilters(BedsFilter);
const PropertyTypesWithFilters = withFilters(PropertyTypeFilter);

const PrimaryFilters = () => {
  const dispatch = useDispatch();
  const activeTab = useSelector(selectUIMenuTab);
  const filters = useSelector(selectFiltersValue);

  const handleMoreClick = () => {
    if (activeTab === "/filters") {
      dispatch(updateMenuTab("/listings"));
    } else {
      dispatch(updateMenuTab("/filters"));
    }
  };

  return (
    <div className={styles.filters}>
      <div className={styles.locationSearch}>
        <LocationSearch disabled={false} />
      </div>
      <div className={styles.categoryFilter}>
        <PurposeWithFilters disabled={false} />
      </div>
      <div className={styles.priceFilter}>
        <PriceWithFilters disabled={false} />
      </div>
      <div className={styles.statusFilter}>
        <StatusWithFilters disabled={false} />
      </div>
      <div className={styles.dateFilter}>
        <DateWithFilters disabled={filters?.lastStatus?.includes("Sus")} />
      </div>
      <div className={styles.bedsFilter}>
        <BedsWithFilters disabled={false} />
      </div>
      <div className={styles.typeFilter}>
        <PropertyTypesWithFilters disabled={false} />
      </div>
      <div className={styles.moreFilters} id="filters-btn">
        <Button
          title="more"
          type="filter"
          onClick={handleMoreClick}
          disabled={false}
        >
          <FiltersIcon />
        </Button>
      </div>
    </div>
  );
};

export default PrimaryFilters;
