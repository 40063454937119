import { useState, useRef, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonLoading,
  IonPage,
  IonRow,
} from "@ionic/react";
import { selectCurrentClient } from "../../../redux/client/client.selectors";
import styles from "./feedback.module.scss";
import Input from "../../../components/Form/Input/input.component";
import jsonObj from "../../../../package.json";
import AppHeader from "../../components/Header/header.component";
import TextArea from "../../../components/Form/TextArea/textarea.component";
import { updateToast } from "../../../redux/ui/ui.actions";
import { sendFeedback } from "../../../api/ettie";
import { useHistory } from "react-router-dom";

const AppFeedback = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const client = useSelector(selectCurrentClient);

  const inputRefs = useRef({
    email: createRef(),
    given_name: createRef(),
    family_name: createRef(),
    device_used: createRef(),
    device_version: createRef(),
  });

  const [formData, setFormData] = useState({
    email: client ? client.email : "",
    given_name: client ? client.givenName : "",
    family_name: client ? client.familyName : "",
    device_used: "",
    device_version: "",
    message: "",
  });

  const handleChange = (value, name) => {
    inputRefs.current[name].current.validate();
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    let isValid = true;
    for (const field of Object.values(inputRefs.current)) {
      const validationResult = field.current.validate(true);
      if (!validationResult) isValid = false;
    }
    if (isValid) {
      setLoading(true);
      try {
        await sendFeedback({
          name: `${formData.given_name} ${formData.family_name}`,
          email: formData.email,
          message: formData.message,
          os: formData.device_version,
          device: formData.device_used,
          version: jsonObj.version,
        });
        dispatch(
          updateToast({
            open: true,
            type: "success",
            message: "Message sent successfully.",
          })
        );
        history.goBack();
      } catch (err) {
        dispatch(
          updateToast({
            open: true,
            type: "error",
            message: "Something went wrong!",
          })
        );
      }
      setLoading(false);
    } else {
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message:
            "An entry is required or has an invalid value. Please correct and try again.",
        })
      );
    }
  };

  return (
    <IonPage>
      <AppHeader title="Feedback" hasRightButton={false} />
      <IonLoading isOpen={loading} />
      <IonContent>
        <IonGrid className={styles.feedback}>
          <IonRow>
            <IonCol className={styles.title}>
              Hello <span>{client ? client["givenName"] : "there"}!</span>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol className={styles.subtitle}>
              We highly appreciate all your feeback on Ettie’s web or app
              experience. Whether you have comments, suggestions for improvement
              or have identified a bug in the software, we would love to hear
              about it. We are dedicated to continuously improving our software,
              both technically and through user experience.
            </IonCol>
          </IonRow>

          <IonRow className={styles.version}>
            <IonCol>App version</IonCol>
            <IonCol className={styles.versionNumber}>{jsonObj.version}</IonCol>
          </IonRow>

          <IonRow className={styles.form}>
            <IonCol>
              <div className={styles.formlabel}>Submit feedback</div>
              <div className={styles.formsubtitle}>
                Send us a message or an email, and we will get back to you
                within 24 hours.
              </div>
              <div className={styles.inputs}>
                <IonRow>
                  <IonCol>
                    <Input
                      name="given_name"
                      label="given name"
                      placeholder="given name"
                      required
                      ref={inputRefs.current.given_name}
                      value={formData["given_name"]}
                      defaultValue={client && client.givenName}
                      onChange={(value, name) => handleChange(value, name)}
                      validation="required|min:2"
                      isApp
                      enterkeyhint="next"
                      submit={() =>
                        inputRefs.current.family_name.current.next()
                      }
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <Input
                      name="family_name"
                      label="family name"
                      placeholder="family name"
                      required
                      ref={inputRefs.current.family_name}
                      value={formData["family_name"]}
                      defaultValue={client && client.familyName}
                      onChange={(value, name) => handleChange(value, name)}
                      validation="required|min:2"
                      isApp
                      enterkeyhint="next"
                      submit={() => inputRefs.current.email.current.next()}
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <Input
                      name="email"
                      label="email"
                      placeholder="email"
                      mode="email"
                      required
                      ref={inputRefs.current.email}
                      value={formData["email"]}
                      defaultValue={client && client.email}
                      onChange={(value, name) => handleChange(value, name)}
                      validation="required|email"
                      isApp
                      enterkeyhint="next"
                      submit={() => inputRefs.current.password.current.next()}
                    />
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol>
                    <Input
                      name="device_used"
                      label="device used"
                      placeholder="e.g. iphone or android"
                      required
                      ref={inputRefs.current.device_used}
                      value={formData["device_used"]}
                      onChange={(value, name) => handleChange(value, name)}
                      validation="required|min:2"
                      isApp
                      isDefaultFocus={true}
                      isCutOff={true}
                      enterkeyhint="next"
                      submit={() =>
                        inputRefs.current.device_version.current.next()
                      }
                    />
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol>
                    <Input
                      name="device_version"
                      label="device version"
                      placeholder="eg. iPhone 6/7/8, Galaxy S5"
                      required
                      ref={inputRefs.current.device_version}
                      value={formData["device_version"]}
                      onChange={(value, name) => handleChange(value, name)}
                      validation="required|min:2"
                      isApp
                      isDefaultFocus={true}
                      isCutOff={true}
                      enterkeyhint="go"
                    />
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol>
                    <TextArea
                      value={formData.message}
                      onChange={(val) =>
                        setFormData({ ...formData, message: val })
                      }
                      label="message"
                      placeholder="message"
                      required
                    />
                  </IonCol>
                </IonRow>
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonButton
                expand="block"
                shape="round"
                className={styles.button}
                disabled={
                  !formData.email ||
                  !formData.given_name ||
                  !formData.family_name ||
                  !formData.message ||
                  !formData.device_used ||
                  !formData.device_version
                }
                onClick={handleSubmit}
              >
                Submit
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default AppFeedback;
