import React from "react";
import { IonIcon } from "@ionic/react";
import { arrowForwardCircleOutline } from "ionicons/icons";
import styles from "./tour-attendees.module.scss";

const TourAttendees = ({ tourAttendees }) => {
  return (
    <div className={styles.tourAttendees}>
      {tourAttendees.map((item, index) => (
        <div key={index}>
          <span className={styles.bold}>{item.role}</span>
          <span
            className={`${
              item.role.toLowerCase().includes("agent") && styles.profile
            }`}
          >
            {`${item.givenName} ${item.familyName}`}
            {item.role.toLowerCase().includes("agent") && (
              <IonIcon icon={arrowForwardCircleOutline} />
            )}
          </span>
        </div>
      ))}
    </div>
  );
};

export default TourAttendees;
