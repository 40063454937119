import { useEffect, useRef } from "react";
import { useHistory } from "react-router";
import styles from "./app-school-card.module.scss";
import { ReactComponent as Chevron } from "../../../../assets/svg/chevron-down.svg";
import Slide from "react-reveal/Slide";
import { createGesture, IonRouterLink, IonToggle } from "@ionic/react";
import { handleLinkClick } from "../../../../utils/functions";

const compare = (a, b) => {
  if (a.yearMark < b.yearMark) return 1;
  if (a.yearMark > b.yearMark) return -1;
  return 0;
};

const AppSchoolCard = ({
  school,
  active,
  isDisabled,
  onToggle,
  onCollapse,
}) => {
  const history = useHistory();
  const containerRef = useRef();
  const handleRef = useRef();

  const renderLanguages = () => {
    let str = "";
    if (school.isEnglish) str += "English\\";
    if (school.isFrenchImmersion) str += "French\\";
    return str.substring(0, str.length - 1);
  };

  const hasBoundary = () => {
    if (!school.boundaryArray || school.boundaryArray === "[]") return false;
    return true;
  };

  const handleClick = () => {
    let c = containerRef.current;
    c.style.transition = ".3s ease-out";
    c.style.transform = `translateY(100%)`;
    onCollapse();
  };

  useEffect(() => {
    let c = containerRef.current;
    let gesture;

    gesture = createGesture({
      el: c,
      gestureName: "my-swipe",
      direction: "y",
      onMove: (event) => {
        if (event.deltaY < 0) return;
        c.style.transform = `translateY(${event.deltaY}px)`;
        if (event.deltaY > 100) {
          c.style.transition = ".3s ease-out";
          c.style.transform = `translateY(100%)`;
          onCollapse();
          return;
        }
      },

      onEnd: (event) => {
        c.style.transition = ".5s ease-out";
        if (event.deltaY < 100) {
          c.style.transform = "";
        }
      },
    });

    gesture.enable(true);
  }, [onCollapse]);

  const renderLastScore = () => {
    const {
      ratings: { items: ratings },
    } = school;
    const lastYear = ratings.sort(compare)[0];

    const scores = [];
    if (lastYear.G3Rank && lastYear.G3Rank !== -1 && lastYear.G3Total)
      scores.push(
        <span
          key={lastYear.id + "G3"}
        >{`${lastYear.yearMark} G3: ${lastYear.G3Rank}/${lastYear.G3Total}`}</span>
      );

    if (lastYear.G6Rank && lastYear.G6Rank !== -1 && lastYear.G6Total)
      scores.push(
        <span
          key={lastYear.id + "G6"}
        >{`${lastYear.yearMark} G6: ${lastYear.G6Rank}/${lastYear.G6Total}`}</span>
      );

    if (lastYear.G9Rank && lastYear.G9Rank !== -1 && lastYear.G9Total)
      scores.push(
        <span
          key={lastYear.id + "G9"}
        >{`${lastYear.yearMark} G9: ${lastYear.G9Rank}/${lastYear.G9Total}`}</span>
      );

    return <div className={styles.scores}>{scores.map((s) => s)}</div>;
  };

  const renderScore = () => {
    if (
      !school.ettieOverallAvg ||
      school.ettieOverallAvg === 0 ||
      school.ettieOverallAvg === -1
    )
      return (
        <span className={styles.score} style={{ fontSize: "2.4rem" }}>
          N/A
        </span>
      );

    return (
      <>
        <span className={styles.score}>
          {(school.ettieOverallAvg / 10).toFixed(1)}
        </span>
        <span className={styles.outOf}>out of 10</span>
      </>
    );
  };

  const renderAvg = () => {
    const avgs = [];
    if (school.g3Avg)
      avgs.push(
        <span key={school.id + "G3"}>
          5 year G3 avg: {Math.round(school.g3Avg)}
        </span>
      );

    if (school.g6Avg)
      avgs.push(
        <span key={school.id + "G6"}>
          5 year G6 avg: {Math.round(school.g6Avg)}
        </span>
      );

    if (school.g9Avg)
      avgs.push(
        <span key={school.id + "G9"}>
          5 year G9 avg: {Math.round(school.g9Avg)}
        </span>
      );

    return <div className={styles.averages}>{avgs.map((a) => a)}</div>;
  };

  const renderPrograms = () => {
    let str = "";
    if (school.isFrenchImmersion) str += "French Immersion, ";
    if (school.isExtendedFrench) str += "Extended French, ";
    if (school.isIB) str += "International Baccalaureate, ";
    if (school.isAP) str += "Advanced Placement, ";
    if (school.isGifted) str += "Gifted, ";
    if (school.isSport) str += "Sports, ";
    if (school.isArts) str += "Arts, ";

    if (str.length) str = str.substring(0, str.length - 2);
    if (str.length) return <span className={styles.text}>{str}</span>;
    return;
  };

  const renderUrl = () => {
    let url =
      school.url &&
      school.url
        .replace("http://", "")
        .replace("https://", "")
        .replace("www.", "");

    if (url.charAt(url.length - 1) === "/")
      url = url.substring(0, url.length - 1);
    return url;
  };

  return (
    <div className={styles.container} ref={containerRef}>
      <Slide bottom exit={true} opposite={true} duration={500}>
        <div className={styles.card}>
          <div className={styles.handle} ref={handleRef} onClick={handleClick}>
            <Chevron className={styles.chevron} />
          </div>
          <div className={styles.header}>
            <div className={styles.title}>
              <IonRouterLink
                className={styles.link}
                routerLink={`/schools/${school.id}`}
              >
                {school.name}
              </IonRouterLink>
            </div>

            <div className={styles.info}>
              <span>
                {school.gradeFrom === -1 ? "JK" : school.gradeFrom}-
                {school.gradeEnd}
              </span>
              <span>{renderLanguages()}</span>
              <span>
                {school.isPublic
                  ? "Public"
                  : school.isPrivate
                  ? "Private"
                  : school.isCatholic
                  ? "Catholic"
                  : ""}
              </span>
            </div>
            <div className={styles.programs}>{renderPrograms()}</div>
          </div>
          <div className={styles.schoolDetails}>
            <div
              className={styles.address}
            >{`${school.address}, ${school.city}`}</div>
            <div
              className={styles.website}
              onClick={() => school.url && handleLinkClick(school.url)}
            >
              {renderUrl()}
            </div>
          </div>
          <div className={styles.rating}>
            <div className={styles.rate}>{renderScore()}</div>
            <div className={styles.details}>
              <div className={styles.text}>EQAO GTA School Ranking</div>
              <div className={styles.yearly}>{renderLastScore()}</div>
              <div className={styles.average}>{renderAvg()}</div>
            </div>
          </div>
          <div className={styles.boundary}>
            {hasBoundary() ? (
              <>
                <div className={styles.text}>
                  {isDisabled && !active ? (
                    <span>You've selected 10 schools.</span>
                  ) : (
                    <span>
                      Select to restrict listings to this school's boundaries.
                      Select up to 10 schools at a time.
                    </span>
                  )}
                </div>
                <div className={styles.toggleContainer}>
                  <IonToggle
                    checked={active}
                    onIonChange={onToggle}
                    className={styles.toggle}
                    disabled={isDisabled && !active}
                  />
                </div>
              </>
            ) : (
              <div className={styles.noText}>
                School boundary is not available.
              </div>
            )}
          </div>
          <div className={styles.footer}>
            <span>Confirm boundary before purchasing.</span>
            <span>
              Information supplied by{" "}
              <u onClick={() => history.push("/school-terms")}>EQAO</u>.
            </span>
          </div>
        </div>
      </Slide>
    </div>
  );
};

export default AppSchoolCard;
