import React from "react";
import { IonIcon } from "@ionic/react";
import chevronDownOutline from "../../../../../../assets/svg/REA103_Icons-01a_ArrowDown-2pt.svg";
import Toggle from "../../../../../../components/Form/Toggle/toggle-component";
import styles from "./detail-page-dropdown.module.scss";

const DetailPageDropdown = ({
  title,
  open,
  invert = false,
  onClick,
  buttonStyle,
  hasToggle = false,
  isFeet,
  onToggle,
  ...rest
}) => {
  return (
    <div
      className={`${styles.detailPageDropdown} ${
        open ? styles.halfRound : styles.round
      }`}
      onClick={onClick}
      {...rest}
    >
      <button type="button" className={`${styles.btn}`} style={buttonStyle}>
        {title}
        {hasToggle && (
          <span className={styles.toggle} onClick={(e) => e.stopPropagation()}>
            <Toggle
              color="purple"
              active={isFeet}
              isSize={true}
              onToggle={onToggle}
              style={{ width: "8rem" }}
              labelStyle={{ fontSize: "1.2rem" }}
            />
          </span>
        )}
      </button>
      <IonIcon
        className={`${styles.icon} ${open ? styles.iconReversed : ""} ${
          invert ? styles.invertedIcon : ""
        }`}
        src={chevronDownOutline}
        onClick={hasToggle ? onClick : null}
      />
    </div>
  );
};

export default DetailPageDropdown;
