import { useState, useEffect } from "react";
import { animated, useTransition } from "react-spring";
import { IonItem, IonLabel } from "@ionic/react";
import styles from "./animated-tab.module.scss";
import { chevronDownOutline, chevronUpOutline } from "ionicons/icons";

const AnimatedTab = ({
  children,
  title,
  visible,
  disabled = false,
  onClick,
  active,
  tag,
  type,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const transition = useTransition(isVisible, {
    from: { x: 0, y: -15, opacity: 0 },
    enter: { x: 0, y: 0, opacity: 1 },
  });

  useEffect(() => {
    setIsVisible(visible);
  }, [visible]);

  return (
    <div className={!isVisible ? styles.tab : undefined}>
      <IonItem
        lines={visible ? "none" : "full"}
        detail={true}
        detailIcon={visible ? chevronUpOutline : chevronDownOutline}
        className={`${styles.item} ${!isVisible && active && styles.active}`}
        onClick={onClick}
        disabled={disabled}
      >
        <IonLabel className={styles.label}>{title}</IonLabel>
        {tag && !visible && (
          <IonLabel
            className={`${styles.tag}  ${
              type === "maintenance" && styles.maxWidth
            }`}
          >
            {tag}
          </IonLabel>
        )}
      </IonItem>
      {transition((style, item) =>
        item ? (
          <animated.div style={style} className={styles.filter}>
            {children}
          </animated.div>
        ) : (
          ""
        )
      )}
    </div>
  );
};

export default AnimatedTab;
