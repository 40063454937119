import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import DetailPageCard from "./components/DetailPageCard/detail-page-card.component";
import DetailCardHeader from "./components/DetailPageCardHeader/detail-card-header.component";
import DetailPageCarousel from "./components/DetailPageCarousel/detail-page-carousel.component";
import DetailPageButtons from "./components/DetailPageButtons/detail-page-buttons.component";
import DetailPageDescription from "./components/DetailPageDescription/detail-page-description.component";
import DetailPageInformation from "./components/DetailPageInformation/detail-page-information.component";
import noImage from "../../../../assets/img/no-photo.png";
import {
  getImageOrFallback,
  handleAppNavigation,
} from "../../../../utils/functions";
import { openModal } from "../../../../redux/ui/ui.actions";
import styles from "./left-section.module.scss";

const LeftSection = ({
  currentClient,
  clientLoading,
  user,
  listing,
  schools,
  schoolsLoading,
  language,
}) => {
  const [mapView, setMapView] = useState(false);
  const dispatch = useDispatch();

  //Display only images that actually load
  const [loadedImages, setLoadedImages] = useState([]);
  useEffect(() => {
    listing.images.length > 1 &&
      listing.images.forEach((image) => {
        getImageOrFallback(`https://cdn.repliers.io/${image}`).then(
          (result) =>
            result &&
            setLoadedImages((loadedImages) => [...loadedImages, result])
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const images =
    loadedImages.length > 1
      ? loadedImages.map((image) => {
          return {
            src: image,
          };
        })
      : [{ src: noImage }];

  const virtualTourHandler = () => {
    let newWindow;
    listing.details.virtualTourUrl.length > 0 &&
      (newWindow = window.open(
        listing.details.virtualTourUrl,
        "_blank",
        "noopener,noreferrer"
      ));
    if (newWindow) newWindow.opener = null;
  };

  const navigateHandler = () => {
    handleAppNavigation({
      map: listing.map,
      address: listing.address,
      byAddress: true,
    });
  };

  const mapViewHandler = () => {
    setMapView(!mapView);

    // dispatch(
    //   updateActiveListing({
    //     details: true,
    //     mls: listing.mlsNumber,
    //     map: listing.map,
    //   })
    // );
    // history.push("/");
  };

  const offerFormHandler = () => {
    dispatch(
      openModal({
        current: "requestForm",
        overflow: true,
        data: {
          offer: true,
          address: listing.address,
          mlsNumber: listing.mlsNumber,
          status: listing.type,
          active: listing.status === "A" ? true : false,
          price: listing.listPrice,
        },
      })
    );
  };

  return (
    <div className={styles.container}>
      <DetailPageCard>
        <DetailCardHeader
          currentClient={currentClient}
          clientLoading={clientLoading}
          listing={listing}
        />
        <DetailPageCarousel
          images={images}
          location={listing.map}
          showMap={mapView}
        />
        <DetailPageButtons
          virtualTourHandler={virtualTourHandler}
          hasVirtualTour={
            listing.details.virtualTourUrl.length > 0 ? true : false
          }
          mapViewHandler={mapViewHandler}
          showMap={mapView}
          navigateHandler={navigateHandler}
          language={language}
        />
        <DetailPageDescription
          listing={listing}
          offerFormHandler={offerFormHandler}
          language={language}
        />
        <DetailPageInformation
          schools={schools}
          schoolsLoading={schoolsLoading}
          listing={listing}
          user={user}
          language={language}
        />
      </DetailPageCard>
    </div>
  );
};

export default LeftSection;
