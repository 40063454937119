import { useState, useEffect, useCallback, memo } from "react";
import { useSelector } from "react-redux";
import {
  IonPage,
  IonContent,
  IonLoading,
  useIonViewDidEnter,
  useIonViewDidLeave,
} from "@ionic/react";
import AppHeader from "../../components/Header/header.component";
import MobileEditBar from "../../components/MobileEditBar/mobile-edit-bar.component";
import FavoriteItem from "../../../pages/Favourites/component/favorite-item.component";
import AddFavourite from "../../../pages/Favourites/component/add-favourite.component";
import Card from "../../../components/Card/card.component";
import styles from "./favourites.module.scss";
import { getFavouriteItems } from "../../../amplify/graphql.utils";
import { API, graphqlOperation } from "aws-amplify";
import {
  onCreateFavourite,
  onDeleteFavourite,
  onUpdateFavourite,
} from "../../../graphql/subscriptions";
import { selectCurrentUser } from "../../../redux/user/user.selectors";
import Refresher from "../../components/Refresher/refresher.component";
import JoinContent from "../../components/CustomModals/Join/join-content.component";

const AppFavouritesPage = () => {
  const [loading, setLoading] = useState(false);
  const [sortedFavourites, setSortedFavourites] = useState();
  const [sortBy, setSortBy] = useState({
    value: "date",
    direction: "desc",
  });
  const [isOpen, setIsOpen] = useState(false);
  const user = useSelector(selectCurrentUser);

  useEffect(() => {
    const sortItems = () => {
      const temp = [...sortedFavourites];
      switch (sortBy.value) {
        case "date":
          temp.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1));
          break;
        case "status":
          temp
            .sort((a, b) =>
              JSON.parse(a.listing).type > JSON.parse(b.listing).type ? 1 : -1
            )
            .sort((a, b) =>
              JSON.parse(a.listing).lastStatus >
              JSON.parse(b.listing).lastStatus
                ? 1
                : -1
            )
            .reverse();

          break;
        case "price":
          temp.sort((a, b) =>
            +JSON.parse(a.listing).listPrice > +JSON.parse(b.listing).listPrice
              ? 1
              : -1
          );
          break;
        case "beds":
          temp.sort((a, b) =>
            +JSON.parse(a.listing).details.numBedrooms >
            +JSON.parse(b.listing).details.numBedrooms
              ? 1
              : -1
          );
          break;
        case "baths":
          temp.sort((a, b) =>
            +JSON.parse(a.listing).details.numBathrooms >
            +JSON.parse(b.listing).details.numBathrooms
              ? 1
              : -1
          );
          break;
        case "sqft":
          temp.sort((a, b) =>
            +JSON.parse(a.listing).details.sqft >
            +JSON.parse(b.listing).details.sqft
              ? 1
              : -1
          );
          break;
        default:
          return;
      }
      sortBy.direction === "desc"
        ? setSortedFavourites([...temp.reverse()])
        : setSortedFavourites([...temp]);
    };
    if (sortedFavourites) sortItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy]);

  const fetchFavourites = async (inBackground) => {
    !inBackground && setLoading(true);
    try {
      const favs = await getFavouriteItems();

      setSortedFavourites((sortedFavourites) =>
        favs.length
          ? favs.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
          : null
      );
    } catch (err) {}
    !inBackground && setLoading(false);
  };

  const updateFavs = useCallback(
    ({ item, type }) => {
      if (type === "update") {
        if (sortedFavourites) {
          const temp = [...sortedFavourites];
          const index = temp.findIndex((f) => f.id === item.id);
          temp[index] = item;

          setSortedFavourites([...temp]);
        }
      }
      if (type === "delete") {
        if (sortedFavourites) {
          setSortedFavourites((sortedFavourites) =>
            sortedFavourites.filter((f) => f.id !== item.id)
          );
        }
      }
      if (type === "create") {
        if (sortedFavourites) {
          setSortedFavourites([...sortedFavourites, item]);
        } else {
          setSortedFavourites([item]);
        }
      }
    },
    [sortedFavourites]
  );

  useEffect(() => {
    let s1, s2, s3;

    const subscribeNew = async () => {
      const s = await API.graphql(
        graphqlOperation(onCreateFavourite, { owner: user.username })
      ).subscribe({
        next: ({
          value: {
            data: { onCreateFavourite },
          },
        }) => updateFavs({ item: onCreateFavourite, type: "create" }),
      });
      return s;
    };
    const subscribeUpdate = async () => {
      const s = await API.graphql(
        graphqlOperation(onUpdateFavourite, { owner: user.username })
      ).subscribe({
        next: ({
          value: {
            data: { onUpdateFavourite },
          },
        }) => updateFavs({ item: onUpdateFavourite, type: "update" }),
      });
      return s;
    };
    const subscribe = async () => {
      const s = await API.graphql(
        graphqlOperation(onDeleteFavourite, { owner: user.username })
      ).subscribe({
        next: ({
          value: {
            data: { onDeleteFavourite },
          },
        }) => updateFavs({ item: onDeleteFavourite, type: "delete" }),
      });
      return s;
    };

    const subscribeAll = async () => {
      if (s1) await s1.unsubscribe();
      if (s2) await s2.unsubscribe();
      if (s3) await s3.unsubscribe();
      s1 = await subscribe();
      s2 = await subscribeNew();
      s3 = await subscribeUpdate();
    };

    if (user) {
      if (!sortedFavourites) fetchFavourites();
      subscribeAll();
    }

    return () => {
      if (s1) {
        s1.unsubscribe();
      }
      if (s2) {
        s2.unsubscribe();
      }
      if (s3) {
        s3.unsubscribe();
      }
    };
  }, [sortedFavourites, updateFavs, user]);

  const handleRefresh = async (ref) => {
    fetchFavourites(true);
    ref.complete();
  };

  useEffect(() => {
    if (!user) setSortedFavourites(null);
  }, [user]);

  useIonViewDidEnter(() => setIsOpen(true));
  useIonViewDidLeave(() => setIsOpen(false));

  const renderCards = useCallback(() => {
    if (sortedFavourites) {
      if (sortedFavourites.length) {
        const cards = sortedFavourites.map((favourite) => (
          <FavoriteItem
            key={`${favourite.id}_${favourite.notification.toString()}`}
            item={favourite}
            isApp={true}
          />
        ));

        return cards;
      } else {
        return (
          <div className={styles.cardsContainer}>
            <div className={styles.cards}>
              <div className={styles.card}>
                <AddFavourite />
              </div>
            </div>
          </div>
        );
      }
    } else {
      if (!loading)
        return (
          <div className={styles.cardsContainer}>
            <div className={styles.cards}>
              <Card add={true}>
                <AddFavourite isApp={true} />
              </Card>
            </div>
          </div>
        );
    }
  }, [loading, sortedFavourites]);

  return (
    <IonPage>
      <AppHeader
        title={user ? "Favourites" : "Join"}
        hasRightButton={user}
        backHref={"/tabs/listings"}
      />
      <IonLoading isOpen={loading && isOpen} />
      <IonContent className={user && styles.content}>
        {user ? (
          <>
            <Refresher background="primary" onRefresh={handleRefresh} />
            <MobileEditBar
              sortBy={sortBy}
              setSortBy={setSortBy}
              mode="favourites"
              disabled={!sortedFavourites || !sortedFavourites.length}
            />
            <div className={styles.container}>{renderCards()}</div>
          </>
        ) : (
          <JoinContent />
        )}
      </IonContent>
    </IonPage>
  );
};

export default memo(AppFavouritesPage);
