import styles from "./textarea.module.scss";

const TextArea = ({ value, placeholder, label, onChange, required }) => {
  return (
    <div className={styles.container}>
      <textarea
        id="textarea"
        className={styles.textarea}
        value={value}
        placeholder={placeholder}
        onChange={({ target: { value } }) => onChange(value)}
        autoCorrect={"false"}
        spellCheck={"false"}
        required={required}
      ></textarea>
      <label htmlFor="textarea" className={styles.label}>
        {label}
      </label>
    </div>
  );
};

export default TextArea;
